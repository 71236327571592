import {
  Autocomplete,
  CircularProgress,
  InputProps,
  TextField,
} from '@mui/material';
import { DefaultTFuncReturn } from 'i18next';
import { ReactNode, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { optionalLabel } from '@util/string-util';

export interface WCTGenericTypeaheadFieldProps<T> {
  id: string;
  name?: string;
  label?: string | DefaultTFuncReturn;
  value?: T | Array<T>;
  error?: string;
  helperText?: string | DefaultTFuncReturn;
  placeholder?: string | DefaultTFuncReturn;
  required?: boolean;
  onChange: (value: T | Array<T>) => void;
  onBlur?: () => void;
  readonly?: boolean;
  options?: Array<T>;
  loadOptions?: (search: string) => Promise<Array<T>>;
  groupBy?: (option: T) => string;
  renderOption?: (props, option) => ReactNode;
  multiple?: boolean;
  inputProps?: Partial<InputProps>;
  forcePopupIcon?: boolean;
}

interface Props<T> extends WCTGenericTypeaheadFieldProps<T> {
  getOptionLabel: (option: T) => string;
  isOptionEqualToValue: (option: T, value: T) => boolean;
}

export default function WCTGenericTypeaheadField<T>({
  label,
  inputProps,
  id,
  name,
  value,
  error,
  helperText,
  placeholder,
  required,
  onChange,
  onBlur,
  readonly,
  options: preloadedOptions,
  loadOptions,
  groupBy,
  renderOption,
  getOptionLabel,
  isOptionEqualToValue,
  multiple,
  forcePopupIcon,
}: Props<T>) {
  const isAsync = loadOptions != null;
  const isLocalFilter = preloadedOptions != null;
  if (!isAsync && !isLocalFilter) {
    throw new Error(
      'options OR loadOptions must be provided to WCTTypeaheadField'
    );
  } else if (isAsync && isLocalFilter) {
    throw new Error(
      'only ONE of the following props may be provided, but NEVER both: -options or -loadOptions to WCTTypeaheadField'
    );
  }

  const hasLabel = !!label;
  const hasError = !!error;

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(preloadedOptions ?? []);

  const onSearch = useDebouncedCallback((search: string) => {
    if (!isAsync) {
      throw new Error(
        'onSearch should never be called when Typeahead is not async'
      );
    }

    setIsLoading(true);

    loadOptions(search)
      .then((newOptions) => {
        setOptions(newOptions);
      })
      .catch((e) => console.log(e))
      .finally(() => setIsLoading(false));
  }, 300);

  return (
    <Autocomplete
      id={id}
      forcePopupIcon={forcePopupIcon}
      loading={isLoading}
      options={options}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      filterOptions={isAsync ? (x) => x : undefined}
      value={value || null}
      readOnly={readonly}
      disabled={readonly}
      onChange={(e, value) => {
        onChange(value as T);
      }}
      onBlur={onBlur}
      groupBy={groupBy}
      renderOption={renderOption}
      multiple={multiple}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant="filled"
            hiddenLabel={!label}
            fullWidth
            required={required}
            name={name}
            error={hasError}
            helperText={hasError ? error : helperText}
            placeholder={placeholder as string}
            label={hasLabel ? optionalLabel(label, required) : undefined}
            onChange={isAsync ? (e) => onSearch(e.target.value) : undefined}
            InputProps={{
              ...params.InputProps,
              ...inputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        );
      }}
    />
  );
}

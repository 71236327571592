export enum Permission {
  //Company Management
  manageCompany = 'ManageCompany',
  manageCompanyUsers = 'ManageCompanyUsers',
  manageCompanyTasks = 'ManageCompanyTasks',
  manageCompanyLocations = 'ManageCompanyLocations',
  manageCompanyReferenceData = 'ManageCompanyReferenceData',
  manageCompanyPayments = 'ManageCompanyPayments',

  //Groups
  manageCompanyGroups = 'ManageCompanyGroups',
  manageMyGroups = 'ManageMyGroups',

  //RCA
  useCaseFunctionality = 'UseCaseFunctionality',

  //Tasks
  manageMyTasks = 'ManageMyTasks',
}

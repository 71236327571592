import { Grid, Link, Typography } from '@mui/material';
import { PrimaryButton } from '@components/buttons';
import WCTTextField from '@components/input/text-field';
import useCreatePassword from '@pages/auth/create-password-hook';
import { useNavigate } from 'react-router-dom';
import ContactSupportLink from '@pages/auth/components/contact-support-link';
import { useAuthTr } from '@i18n/use-auth-tr';
import { useCommonTr } from '@i18n/use-common-tr';
import PasswordCriteria from '@pages/auth/components/password-criteria';
import useSearchParamsTyped from '@hooks/router-dom-helper-hooks';
import { isApp } from '@util/env';
import { numberFromString } from '@util/string-util';
import { ErrorAlert, PageAlertDisplay } from '@components/alerts';
import {
  ButtonSkeleton,
  InlineBoxSkeleton,
  InputSkeleton,
} from '@components/skeletons';
import React from 'react';
import { Gap } from '@components/layout-util-components/gap';

type QueryParams = {
  token: string;
  uid?: string;
  companyId?: string;
};

export default function CreatePasswordPage() {
  const { token, uid, companyId } = useSearchParamsTyped<QueryParams>();

  const {
    password,
    confirmPassword,
    canSubmit,
    isLoading,
    isSubmitting,
    submitUserConfirm,
    submitUserInvite,
    hasValidToken,
  } = useCreatePassword(uid, token);

  const { t } = useAuthTr('createPassword');
  const { t: commonButtonTr } = useCommonTr('buttons');

  const navigate = useNavigate();

  const onSubmit = async () => {
    const isUserConfirm = uid != null;
    if (isUserConfirm) {
      if (await submitUserConfirm(token!, uid!)) {
        if (isApp) {
          navigate('../create-company', { replace: true });
        } else {
          navigate('../create-account-progress', { replace: true });
        }
      }
    }

    const isUserInvite = companyId != null;
    if (isUserInvite && isApp) {
      if (await submitUserInvite(token!, numberFromString(companyId!)!)) {
        navigate('/', { replace: true });
      }
    }
  };

  if (!isLoading && !hasValidToken) {
    return (
      <Grid container direction="column" gap={2} justifyContent="center">
        <ErrorAlert
          message={
            <span>
              Your token has expired we've resent you a new link
              <br />
              <br />
              <Link href="../forgot-password">Request another</Link>
            </span>
          }
        />
      </Grid>
    );
  }

  return (
    <Grid container direction="column" gap={2} justifyContent="center">
      <Typography variant="h4">
        {isLoading ? <InlineBoxSkeleton width={100} /> : t('title')}
      </Typography>
      <Typography variant="body1">
        {isLoading ? <InlineBoxSkeleton width={250} /> : t('message')}
      </Typography>
      <Gap size={2} />
      <PageAlertDisplay />
      {isLoading ? (
        <InputSkeleton />
      ) : (
        <WCTTextField
          label={t('form.password')}
          value={password.value}
          error={password.error}
          onChange={password.set}
          required
          obscure
        />
      )}

      {isLoading ? (
        <InlineBoxSkeleton width={100} />
      ) : (
        <PasswordCriteria password={password.value} />
      )}

      {isLoading ? (
        <InputSkeleton />
      ) : (
        <WCTTextField
          label={t('form.confirmPassword.label')}
          value={confirmPassword.value}
          error={confirmPassword.error}
          onChange={confirmPassword.set}
          required
          obscure
        />
      )}
      {isLoading ? (
        <ButtonSkeleton />
      ) : (
        <PrimaryButton
          onClick={onSubmit}
          disabled={!canSubmit}
          isBusy={isSubmitting}
        >
          {commonButtonTr('continue')}
        </PrimaryButton>
      )}
      <ContactSupportLink />
    </Grid>
  );
}

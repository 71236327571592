import { Palette } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

type MUITypography = (palette: Palette) => TypographyOptions;

export const typography: MUITypography = () => ({
  fontFamily: 'Roboto',
  htmlFontSize: 16,
  fontWeightLight: 200,
  fontWeightRegular: 'normal',
  fontWeightMedium: 500,
  fontWeightBold: 'bold',
  allVariants: {
    fontSize: 16,
    lineHeight: 1.25,
    fontWeight: 'normal',
  },
  h1: {
    fontSize: 24,
    fontWeight: '500',
  },
  h2: {
    fontSize: 20,
    fontWeight: '500',
  },
  h3: {
    fontSize: 18,
    fontWeight: '500',
  },
  h4: {
    fontSize: 16,
    fontWeight: '500',
  },
  // h5: {},
  h6: {
    fontSize: 15,
    fontWeight: '500',
  },
  body1: {
    fontWeight: 300,
  },
  body2: {
    fontSize: 14,
    fontWeight: 300,
  },
  // caption: {},
  subtitle1: {
    fontSize: 20,
    fontWeight: 400,
  },
  subtitle2: {
    fontSize: 18,
  },
  overline: {
    fontSize: 13,
    lineHeight: '13px',
    verticalAlign: 'baseline',
  },
  // button: {},
});

import useField from '@hooks/use-field-hook';
import { required } from '@util/validators';
import { useAuthTr } from '@i18n/use-auth-tr';
import { useGetCountryNamesQuery } from '@api/endpoints/country.api';
import { useGetBusinessAreaOptionsQuery } from '@api/endpoints/business-area.api';
import { useGetSectorOptionsQuery } from '@api/endpoints/sector.api';
import { ApiError } from '@api/types/api-error';
import { CreateCompanyRequest } from '@api/types/company/create-company.request';
import { useCreateCompanyMutation } from '@api/endpoints/company/company.api';
import { usePageAlertVariants } from '@components/alerts';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { useDefaultIfSingleOption } from '@hooks/use-default-if-single-option-hook';

export default function useCreateCompany(packageId: number) {
  const { t } = useAuthTr('createCompany');
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();

  const { isLoading: loadingCountries, data: countries } =
    useGetCountryNamesQuery(true);
  const { isLoading: loadingBusinessArea, data: businessAreas } =
    useGetBusinessAreaOptionsQuery();
  const { isLoading: loadingSectors, data: sectors } =
    useGetSectorOptionsQuery();

  const [createCompany, { isLoading: isSubmitting }] =
    useCreateCompanyMutation();

  const companyName = useField<string>([required()]);
  const sector = useField<number>([required()]);
  const businessArea = useField<number>([required()]);
  const jobTitle = useField<string>([required()]);
  const country = useField<number>(
    [required()],
    useDefaultIfSingleOption(countries)
  );

  const { isValid, isDirty, validateAll } = useFieldsWatcher([
    companyName,
    sector,
    businessArea,
    jobTitle,
    country,
  ]);

  const isLoading = loadingCountries || loadingBusinessArea || loadingSectors;
  const canSubmit = isValid && isDirty && !isSubmitting && !isLoading;

  const submit = async (): Promise<boolean> => {
    if (!validateAll()) {
      return false;
    }

    return createCompany({
      jobTitle: jobTitle.value,
      businessAreaId: businessArea.value,
      countryId: country.value,
      name: companyName.value,
      sectorId: sector.value,
      packageId: packageId,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage(t('toasts.success'));
        return true;
      })
      .catch(({ message, errors }: ApiError<CreateCompanyRequest>) => {
        showErrorMessage(message);

        jobTitle.setError(errors?.jobTitle);
        businessArea.setError(errors?.businessAreaId);
        country.setError(errors?.countryId);
        companyName.setError(errors?.name);
        sector.setError(errors?.sectorId);

        return false;
      });
  };

  return {
    companyName,
    sector,
    businessArea,
    jobTitle,
    country,
    canSubmit,
    submit,
    isSubmitting,
    businessAreas,
    sectors,
    countries,
    isLoading,
  };
}

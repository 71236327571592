import useUser from '@store/user/user-hook';
import { useGetCompaniesForCurrentUserQuery } from '@api/endpoints/company-user.api';
import { isAdmin, isApp } from '@util/env';
import { useCallback, useEffect, useState } from 'react';
import usePostLoginActions from '@hooks/use-post-login-actions-hook';
import useSharedState from '@store/shared-state-hook';
import { QueryStatus } from '@reduxjs/toolkit/query';

export default function usePostLogin() {
  const [isReadyToTrigger, setReadyToTrigger] = useState<boolean | undefined>(
    undefined
  );
  const { showOnboarding } = usePostLoginActions();
  const { isLoggedInAndFullyAuthenticated } = useUser();

  const { data: userCompanies, status: companyStatus } =
    useGetCompaniesForCurrentUserQuery(undefined, {
      skip: !isLoggedInAndFullyAuthenticated || isAdmin,
    });

  const { isLoading: loadingSharedState, userPreferences } = useSharedState();

  const isLoading =
    (isApp && companyStatus !== QueryStatus.fulfilled) || loadingSharedState;

  const maybeTriggerPostLogin = async () => {
    if (isReadyToTrigger == null) {
      setReadyToTrigger(true);
    }
  };

  const doActions = useCallback(async () => {
    const willShowOnboarding = isApp && !userPreferences.getBool('FirstLogin');
    if (willShowOnboarding) {
      await showOnboarding();
    }
  }, [userPreferences, showOnboarding]);

  useEffect(() => {
    if (isReadyToTrigger == null) {
      return;
    }

    if (isReadyToTrigger) {
      doActions();
      setReadyToTrigger(false);
    }
  }, [doActions, isReadyToTrigger]);

  return {
    isLoading,
    hasCompany:
      companyStatus !== QueryStatus.fulfilled
        ? null
        : userCompanies != null && userCompanies.length > 0,
    maybeTriggerPostLogin,
  };
}

import { EvidencePanelState } from '@pages/app/rca/tabs/components/editor-panels/evidence-panel-hook';
import Column from '@components/layout-util-components/column';
import { FlatButton, LinkButton, OutlinedButton } from '@components/buttons';
import Row from '@components/layout-util-components/row';
import { Divider } from '@components/layout-util-components/divider';
import { Box, Skeleton, Typography } from '@mui/material';
import { WCTTableFilters } from '@components/table';
import useAddEvidenceOptionView from '@pages/app/rca/tabs/components/editor-panels/components/evidence-panel/add-evidence-option-view-hook';
import { LoadingIndicator } from '@components/loading-indicator';
import { ButtonSkeleton, InlineBoxSkeleton } from '@components/skeletons';
import CaseEvidenceCard from '@components/cards/case-evidence-card';
import { CaseEvidenceResource } from '@api/types/case/case-evidence/case-evidence.resource';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus } from '@fortawesome/pro-light-svg-icons';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  state: EvidencePanelState;
}

export default function AddEvidenceOptionView({ state }: Props) {
  const { createNew } = state;
  const {
    search,
    fetchingCaseEvidence,
    caseEvidence,
    loadMore,
    canLoadMore,
    isLoading,
    toggleEvidence,
    isSelected,
  } = useAddEvidenceOptionView(state);

  const renderEvidenceSelectButton = (evidence: CaseEvidenceResource) => {
    const isSelectedEvidence = isSelected(evidence.evidenceId);
    return (
      <FlatButton
        dark={isSelectedEvidence}
        onClick={() => toggleEvidence(evidence.evidenceId)}
      >
        {isSelectedEvidence ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : (
          <FontAwesomeIcon icon={faPlus} />
        )}
      </FlatButton>
    );
  };

  return (
    <Column>
      {isLoading ? (
        <ButtonSkeleton />
      ) : (
        <OutlinedButton onClick={createNew}>Add New Evidence</OutlinedButton>
      )}
      <Gap size={12} />
      <Row>
        <Box flexGrow={1} pr={2}>
          <Divider />
        </Box>
        <span>{isLoading ? <InlineBoxSkeleton width={40} /> : 'Or'}</span>
        <Box flexGrow={1} pl={2}>
          <Divider />
        </Box>
      </Row>
      <Gap size={12} />
      <Typography variant="body1">
        {isLoading ? <Skeleton /> : 'Add existing evidence'}
      </Typography>
      <Gap size={10} />
      <WCTTableFilters
        isLoading={isLoading}
        search={search.value}
        onSearch={search.set}
        searchPlaceholder="Type to search evidence"
        size="large"
        rightOfSearchContent={<LoadingIndicator show={fetchingCaseEvidence} />}
      />
      <Gap size={10} />
      {caseEvidence?.model.map((evidence) => (
        <>
          <CaseEvidenceCard
            key={evidence.evidenceId}
            evidence={evidence}
            trailing={renderEvidenceSelectButton(evidence)}
          />
          <Gap size={8} />
        </>
      ))}
      {canLoadMore ? (
        <>
          <Gap size={10} />
          <LinkButton onClick={loadMore}>Load More</LinkButton>
        </>
      ) : null}
    </Column>
  );
}

import requireAuth from '@components/hoc/with-required-auth-hoc';
import useCompanyRcaSettings from './company-rca-settings-hook';
import SettingsOptionContainer from '@components/containers/settings-option-container';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Gap } from '@components/layout-util-components/gap';

function CompanyRcaSettingsPage() {
  const navigate = useNavigate();
  const { modifiedTimes } = useCompanyRcaSettings();

  return (
    <>
      <Typography variant="h3">RCA Settings</Typography>
      <Gap size={20} />
      <SettingsOptionContainer
        title="Label Configuration"
        message="Configure the labels within the RCA module to suit your organisation"
        modifiedTime={
          modifiedTimes?.systemText
            ? new Date(modifiedTimes?.systemText)
            : undefined
        }
        onClick={() => navigate('/company-profile/rca-settings-labels')}
      />
      <SettingsOptionContainer
        title="RCA type"
        message="Configure the RCA type options available when creating an RCA"
        modifiedTime={
          modifiedTimes?.caseAnalysisType
            ? new Date(modifiedTimes?.caseAnalysisType)
            : undefined
        }
        onClick={() => navigate('/company-profile/rca-settings-types')}
      />
      <SettingsOptionContainer
        title="Impact Category Configuration"
        message="Configure the impact category options available when creating an RCA"
        modifiedTime={
          modifiedTimes?.caseImpact
            ? new Date(modifiedTimes?.caseImpact)
            : undefined
        }
        onClick={() => navigate('/company-profile/rca-settings-impacts')}
      />
      <SettingsOptionContainer
        title="Theme Configuration"
        message="Configure the theme options available when adding themes to a cause box"
        modifiedTime={
          modifiedTimes?.theme ? new Date(modifiedTimes?.theme) : undefined
        }
        onClick={() => navigate('/company-profile/rca-settings-themes')}
      />
    </>
  );
}

export default requireAuth(CompanyRcaSettingsPage);

import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch } from '@store/store';
import { hardResetRcaToInitialState } from '@store/rca-editor/rca-editor-slice';
import { hardResetSnapshotState } from '@store/rca-editor-snapshot/rca-editor-snapshot-slice';
import chainApi from '@api/endpoints/chain/chain.api';
import caseApi from '@api/endpoints/case.api';
import chainItemApi from '@api/endpoints/chain/chain-item.api';

export default function RcaResetter() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(caseApi.util.resetApiState());
      dispatch(chainApi.util.resetApiState());
      dispatch(chainItemApi.util.resetApiState());
      dispatch(hardResetSnapshotState());
      dispatch(hardResetRcaToInitialState());
    };
  }, [dispatch]);

  return <Outlet />;
}

import { Grid, Icon, Typography } from '@mui/material';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import ContentContainer from '@components/containers/content-container';
import { useState } from 'react';
import { ReactComponent as VisibleIcon } from '@assets/svgs/visible.svg';
import { ReactComponent as HiddenIcon } from '@assets/svgs/hidden.svg';
import CompanyStats from '@pages/admin/companies/components/company-stats';
import { InlineBoxSkeleton } from '@components/skeletons';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  companyCount?: number;
}

export default function CompanyHeader({ companyCount }: Props) {
  const [statsHidden, setHidden] = useState(false);

  return (
    <ContentContainer filled>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        flexWrap="nowrap"
      >
        <Grid item container direction="column">
          <Typography variant="body1">Home &gt; Companies</Typography>
          <Gap size={10} />
          <Typography variant="h5">
            Companies ({companyCount ? companyCount : <InlineBoxSkeleton />})
          </Typography>
        </Grid>
        {/*<Box flexGrow={1} minWidth={20} />*/}
        <OutlinedButton
          onClick={() => {
            setHidden(!statsHidden);
          }}
        >
          <Icon>{statsHidden ? <HiddenIcon /> : <VisibleIcon />}</Icon>
          <Gap size={8} />
          {statsHidden ? 'Show Stats' : 'Hide Stats'}
        </OutlinedButton>
        <Gap size={12} />
        <PrimaryButton onClick={() => {}}>Invite Company</PrimaryButton>
      </Grid>
      <CompanyStats show={!statsHidden} />
    </ContentContainer>
  );
}

import { Grid, Typography } from '@mui/material';
import { PrimaryButton } from '@components/buttons';
import ContentContainer from '@components/containers/content-container';
import { InlineBoxSkeleton } from '@components/skeletons';
import CreateUserForm, {
  CreateUserFormProps,
} from '@pages/admin/users/forms/create-user-form';
import { PageAlertDisplay } from '@components/alerts';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  userCount?: number;
}

export default function WctUsersHeader({ userCount }: Props) {
  const { showPopup } = useUiPopup();

  const showCreateUser = () => {
    showPopup<CreateUserFormProps, void>(CreateUserForm, {});
  };

  return (
    <ContentContainer filled>
      <PageAlertDisplay />
      <Gap size={20} />
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        flexWrap="nowrap"
      >
        <Grid item container direction="column">
          <Typography variant="body1">Home &gt; Users</Typography>
          <Gap size={10} />
          <Typography variant="h5">
            Users ({userCount ? userCount : <InlineBoxSkeleton />})
          </Typography>
        </Grid>
        <PrimaryButton onClick={showCreateUser}>Create User</PrimaryButton>
      </Grid>
    </ContentContainer>
  );
}

import { ImpactsPanelState } from '@pages/app/rca/tabs/components/editor-panels/impacts-panel-hook';
import Column from '@components/layout-util-components/column';
import { Typography } from '@mui/material';
import ImpactOption from '@pages/app/rca/tabs/components/editor-panels/components/impacts-panel/impact-option';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  state: ImpactsPanelState;
}

export default function SelectImpacts({ state }: Props) {
  const {
    actualImpacts,
    potentialImpacts,
    onCancelSelect,
    onBeginEnterValues,
    isSubmitting,
  } = state;

  const hasNoImpacts =
    actualImpacts.length === 0 && potentialImpacts.length === 0;

  return (
    <Column>
      <Typography variant="body1">
        {hasNoImpacts
          ? 'No impacts found'
          : ' Please select the impacts below that relate to this cause box'}
      </Typography>
      <Gap size={10} />
      {actualImpacts.length > 0 ? (
        <>
          <Typography>ACTUAL IMPACTS</Typography>
          <Gap size={10} />
          {actualImpacts.map((impact) => (
            <ImpactOption
              key={impact.caseImpactId}
              impact={impact}
              state={state}
            />
          ))}
        </>
      ) : null}
      {potentialImpacts.length > 0 ? (
        <>
          <Typography>POTENTIAL IMPACTS</Typography>
          <Gap size={10} />
          {potentialImpacts.map((impact) => (
            <ImpactOption
              key={impact.caseImpactId}
              impact={impact}
              state={state}
            />
          ))}
        </>
      ) : null}
      {!hasNoImpacts ? (
        <ActionsRow>
          <OutlinedButton onClick={onCancelSelect}>Cancel</OutlinedButton>
          <PrimaryButton onClick={onBeginEnterValues} isBusy={isSubmitting}>
            Continue
          </PrimaryButton>
        </ActionsRow>
      ) : null}
    </Column>
  );
}

import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from '@components/ui-popup/drawers';
import { Divider } from '@components/layout-util-components/divider';
import { Typography } from '@mui/material';
import ReportOption from '@pages/app/rca/tabs/lenses/components/report-option';
import { LensesPanelState } from '@pages/app/rca/tabs/lenses/lenses-panel-hook';
import PlaceholderIcon from '@assets/images/placeholder-45.png';
import Row from '@components/layout-util-components/row';
import Spacer from '@components/layout-util-components/spacer';
import { LinkButton, OutlinedButton, PrimaryButton } from '@components/buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import RecentLenseOption from '@pages/app/rca/tabs/lenses/components/recent-lense-option';
import { Fragment } from 'react';
import { LoadingIndicator } from '@components/loading-indicator';
import { datetimeShortFormatter } from '@i18n/formatters';
import { showReportById } from '@store/reports/reports-actions';
import { useAppDispatch } from '@store/store';
import { ReportType } from '@api/types/report-type/report-type.option';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  onClose: () => void;
  state: LensesPanelState;
}

const TitleMap = {
  [ReportType.coverage]: 'Coverage report',
  [ReportType.impact]: 'Impacts report',
};

export default function OptionSelectView({ onClose, state }: Props) {
  const dispatch = useAppDispatch();

  const {
    selectedReportType,
    selectReportType,
    showAllRecentLenses,
    isLoading,
    reportTypeOptions,
    allReports,
    goToForm,
    canMoveNext,
  } = state;

  return (
    <>
      <DrawerHeader onClose={onClose}>Lenses</DrawerHeader>
      <Divider addMargin compact negativeMargin={-24} />
      <DrawerContent>
        <Typography variant="body2">
          Select the below reports to view real-time chart lenses.
        </Typography>
        <Gap size={20} />
        {isLoading ? (
          <LoadingIndicator show />
        ) : (
          reportTypeOptions!.map((option) => (
            <Fragment key={option.reportTypeId}>
              <ReportOption
                isSelected={
                  selectedReportType?.reportTypeId === option.reportTypeId
                }
                iconUrl={PlaceholderIcon}
                title={TitleMap[option.reportType]!}
                message="Example sub-title "
                onClick={() => selectReportType(option)}
              />
              <Gap size={8} />
            </Fragment>
          ))
        )}
        <Gap size={20} />
        <Row>
          <Typography>RECENT LENSES</Typography>
          <Spacer />
          <LinkButton onClick={showAllRecentLenses}>View all</LinkButton>
          <FontAwesomeIcon icon={faChevronRight} />
        </Row>
        <Gap size={10} />
        {isLoading ? (
          <LoadingIndicator show />
        ) : (
          allReports!.model.slice(0, 2).map((report) => (
            <Fragment key={report.reportId}>
              <RecentLenseOption
                title={report.reportTypeName}
                tag={`${report.reportSubTypeName} report`}
                createdBy={report.createdByCompanyUser}
                createdAt={report.created}
                onClick={() => {
                  onClose();
                  dispatch(showReportById(report.reportId));
                }}
              />
              <Gap size={15} />
            </Fragment>
          ))
        )}
      </DrawerContent>
      <DrawerFooter>
        <OutlinedButton onClick={() => onClose()}>Cancel</OutlinedButton>
        <PrimaryButton disabled={!canMoveNext} onClick={goToForm}>
          Next
        </PrimaryButton>
      </DrawerFooter>
    </>
  );
}

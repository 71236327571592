import requireAuth from '@components/hoc/with-required-auth-hoc';
import { Box, Chip, Skeleton, Typography } from '@mui/material';
import useKudosPage from './kudos-page-hook';
import { IKudos } from '@api/types/user/kudos';
import { timespanFormatter } from '@i18n/formatters';
import { ActivityAvatar } from '../rca/tabs/components/editor-panels/components/activities/activity-avatar';
import { isKudosItemSameDayAsNext } from './utils/is-kudos-item-same-day-as-next';
import { AvatarConnectingLine } from '../rca/tabs/components/avatar-connecting-line';
import { Divider } from '@components/layout-util-components/divider';
import useUser from '@store/user/user-hook';

const mockKudos: IKudos[] = [
  {
    description: 'You have been assigned 55 xp',
    kudosReason: 'For providing evidence on RCA "Example RCA 1"',
    points: 55,
    assignedByUserName: 'Archie',
    created: '2023-11-26T23:14:45.111Z',
  },
  {
    description: 'You have been assigned 55 xp',
    kudosReason: 'For providing evidence on RCA "Example RCA 1"',
    points: 55,
    assignedByUserName: 'Archie',
    created: '2023-11-26T23:14:30.111Z',
  },
  {
    description: 'You have been assigned 55 xp',
    kudosReason: 'For providing evidence on RCA "Example RCA 1"',
    points: 0,
    assignedByUserName: 'string',
    created: '2023-11-26T23:14:45.111Z',
  },
  {
    description: 'You have been assigned 55 xp',
    kudosReason: 'For providing evidence on RCA "Example RCA 1"',
    points: 0,
    assignedByUserName: 'string',
    created: '2023-11-26T23:14:45.111Z',
  },
];

export const KudosPage = () => {
  const userCompanyId = useUser()?.companyUserId;

  const state = useKudosPage(userCompanyId);
  const { isLoading, kudos, userDetail } = state;

  return (
    <Box>
      <Typography variant="h2">Kudos Points</Typography>
      <Box mt={4} mb={4} sx={{ display: 'flex' }}>
        {isLoading ? (
          <Skeleton height="100%" width="100%" />
        ) : (
          <Box
            sx={{
              p: 3,
              mr: 3,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#757575',
            }}
          >
            <>
              <ActivityAvatar type={'Kudos'} />
              <Typography sx={{ color: '#fff', pt: 3 }}>{`Level ${
                userDetail?.kudosLevel ?? 0
              }`}</Typography>
              {userDetail?.kudosPoints && (
                <Box
                  sx={{
                    p: 2,
                    m: 2,
                    mb: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#aca9a9',
                    borderRadius: 3,
                  }}
                >
                  <Typography sx={{ color: '#fff' }}>
                    {userDetail.kudosPoints}
                  </Typography>
                  {/* <Typography sx={{ color: '#fff' }}>+ 457 to Level 22</Typography> */}
                </Box>
              )}
              <Typography sx={{ color: '#fff', mt: 2 }}>
                How it works
              </Typography>
            </>
          </Box>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          {isLoading
            ? [...new Array(6)].map((_, idx) => (
                <Skeleton
                  key={`item-loading-row-${idx}`}
                  height="4rem"
                  width="100%"
                />
              ))
            : kudos?.model.map((item: IKudos, idx: number) => {
                const isFirstItemInDay = !isKudosItemSameDayAsNext({
                  item,
                  kudos: kudos?.model,
                  idx,
                });

                const date = new Date(item.created);
                const timeOfEvent = timespanFormatter(date);

                return (
                  <Box
                    mt={isFirstItemInDay && idx !== 0 ? 3 : 0}
                    key={`item-row-${idx}`}
                  >
                    {isFirstItemInDay && (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          mb: 3,
                        }}
                      >
                        <Chip sx={{ mr: 1 }} label={timeOfEvent} />
                        <Box width={'100%'}>
                          <Divider sx={{ flexGrow: 1 }} />
                        </Box>
                      </Box>
                    )}
                    <Box sx={{ display: 'flex' }}>
                      {!isFirstItemInDay && <AvatarConnectingLine />}
                      <ActivityAvatar type={'Kudos'} />
                      <Box ml={2}>
                        <Box mb={1}>
                          <Typography variant="body1">
                            {item.description}
                          </Typography>
                        </Box>
                        <Box mb={2}>
                          <Typography variant="body2">
                            {item.kudosReason}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
        </Box>
      </Box>
    </Box>
  );
};

export default requireAuth(KudosPage);

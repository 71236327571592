import { EvidencePanelState } from '@pages/app/rca/tabs/components/editor-panels/evidence-panel-hook';
import { CaseEvidenceResource } from '@api/types/case/case-evidence/case-evidence.resource';
import { useUnlinkEvidenceMutation } from '@api/endpoints/chain/chain-item-evidence.api';
import { usePageAlertVariants } from '@components/alerts';
import { isApiError } from '@api/types/api-error';
import { LinkEvidenceRequest } from '@api/types/chain/chain-item-evidence/link-evidence.request';
import { useAppSelector } from '@store/store';
import { selectFocusedNode } from '@store/rca-editor/selectors';

export default function useEvidenceListView({
  evidence,
  editEvidence,
  chainItemId,
  chainId,
}: EvidencePanelState) {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const [unlinkEvidence, { isLoading: isUnlinking }] =
    useUnlinkEvidenceMutation();

  const focusedNode = useAppSelector(selectFocusedNode);
  const { label } = focusedNode?.data ?? {};

  const onUnlinkEvidence = async (evidence: CaseEvidenceResource) => {
    try {
      await unlinkEvidence({
        evidenceId: evidence.evidenceId,
        chainId: chainId!,
        chainItemId: chainItemId!,
      }).unwrap();
      showSuccessMessage(
        `You have successfully unlinked evidence from Cause Box “${label}”`
      );

      return true;
    } catch (e) {
      if (isApiError<LinkEvidenceRequest>(e)) {
        const { message, errors } = e;
        showErrorMessage(
          errors?.chainId ??
            errors?.chainItemId ??
            errors?.evidenceId ??
            message
        );
      }

      return false;
    }
  };

  return {
    evidenceList: evidence?.model ?? [],
    editEvidence,
    onUnlinkEvidence,
    isUnlinking,
  };
}

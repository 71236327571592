import { useFilter, useSortableHeader } from '@components/table';
import useLoadMore from '@hooks/use-load-more-hook';
import { SortDirection } from '@api/types/sort-direction';
import { SortOption } from '@components/table/hooks/use-sortable-header-hook';
import { useGetCaseEvidenceQuery } from '@api/endpoints/case.api';
import { useAppSelector } from '@store/store';
import { selectCurrentRcaCaseId } from '@store/rca-editor/selectors';
import useEvidenceHelper from '@pages/app/evidence/hooks/evidence-helper-hook';

export default function useEvidencePage() {
  const evidenceHelper = useEvidenceHelper();
  const caseId = useAppSelector(selectCurrentRcaCaseId);

  const sortByOptions: Array<SortOption> = [
    { id: 'name', label: 'A to Z', direction: SortDirection.asc },
    { id: 'name', label: 'Z to A', direction: SortDirection.desc },
  ];

  const search = useFilter<string>();
  const { skip, take, loadMore, canLoadMore } = useLoadMore();
  const sort = useSortableHeader({ initialProperty: 'name' });

  const sensitivityFilter = useFilter<Array<string>>([]);
  const qualityFilter = useFilter<Array<number>>([]);

  const {
    data: evidence,
    isLoading: loadingEvidence,
    isSuccess,
    isFetching,
  } = useGetCaseEvidenceQuery({
    caseId: caseId ?? -1,
    searchText: search.value,
    sortBy: sort.property,
    sortDirection: sort.direction,
    skip,
    take,
    sensitivity: sensitivityFilter.arrayValue,
    quality: qualityFilter.arrayValue,
  });

  const isLoading = loadingEvidence;
  const totalCount = evidence?.totalCount ?? 0;

  return {
    search,
    sort,
    sortByOptions,
    evidence,
    isLoading,
    isSuccess,
    loadMore,
    canLoadMore,
    isFetching,
    caseId,
    totalCount,
    qualityFilter,
    sensitivityFilter,
    ...evidenceHelper,
  };
}

export type EvidencePageState = ReturnType<typeof useEvidencePage>;

import { api } from '@api/api';
import { CompanyUserCaseTotalsResponse } from '@api/types/dashboard/company-user-case-totals-response';
import { ImpactsByCoverageResponse } from '@api/types/dashboard/impacts-by-coverage-response';
import { SolutionsByEffectivenessResponse } from '@api/types/dashboard/solutions-by-effectiveness-response';
import { SolutionsByTermResponse } from '@api/types/dashboard/solutions-by-term-response';
import { ThemeByCoverageResponse } from '@api/types/dashboard/themes-by-coverage-response';
import { UserCasesResponse } from '@api/types/dashboard/user-cases-response';
import { UserGroupsResponse } from '@api/types/dashboard/user-groups-response';
import { UserOutcomesResponse } from '@api/types/dashboard/user-outcomes-response';
import { UserTasksResponse } from '@api/types/dashboard/user-tasks-response';
import { DashboardFiltersRequest } from '@api/types/dashboard/dashboard-filters.request';

const dashboardApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompanyUserCaseTotals: build.query<
      CompanyUserCaseTotalsResponse,
      DashboardFiltersRequest
    >({
      query: ({ ...params }) => ({
        url: 'Case/CompanyUserCaseTotals',
        params,
      }),
    }),
    getThemesByCoverage: build.query<
      ThemeByCoverageResponse,
      DashboardFiltersRequest
    >({
      query: ({ ...params }) => ({
        url: 'Report/ThemesByCoverage',
        params,
      }),
    }),
    getImpactsByCoverage: build.query<
      ImpactsByCoverageResponse,
      DashboardFiltersRequest
    >({
      query: ({ ...params }) => ({
        url: 'Report/ImpactsByCoverage',
        params,
      }),
    }),
    getSolutionsByTerm: build.query<
      SolutionsByTermResponse,
      DashboardFiltersRequest
    >({
      query: ({ ...params }) => ({
        url: 'Report/SolutionsByTerm',
        params,
      }),
    }),
    getSolutionsByEffectiveness: build.query<
      SolutionsByEffectivenessResponse,
      DashboardFiltersRequest
    >({
      query: ({ ...params }) => ({
        url: 'Report/SolutionsByEffectiveness',
        params,
      }),
    }),
    getUserCases: build.query<UserCasesResponse, DashboardFiltersRequest>({
      query: ({ ...params }) => ({
        url: 'Case/User',
        params,
      }),
    }),
    getUserOutcomes: build.query<UserOutcomesResponse, DashboardFiltersRequest>(
      {
        query: ({ ...params }) => ({
          url: 'Outcome',
          params,
        }),
      }
    ),
    getUserTaskDetails: build.query<UserTasksResponse, DashboardFiltersRequest>(
      {
        query: ({ ...params }) => ({
          url: 'TaskDetail/User',
          params,
        }),
      }
    ),
    getUserCompanyGroups: build.query<
      UserGroupsResponse,
      DashboardFiltersRequest
    >({
      query: ({ ...params }) => ({
        url: 'CompanyGroup/User',
        params,
      }),
    }),
  }),
});

export const {
  useGetCompanyUserCaseTotalsQuery,
  useGetThemesByCoverageQuery,
  useGetImpactsByCoverageQuery,
  useGetSolutionsByTermQuery,
  useGetSolutionsByEffectivenessQuery,
  useLazyGetUserCasesQuery,
  useLazyGetUserTaskDetailsQuery,
  useLazyGetUserCompanyGroupsQuery,
  useLazyGetUserOutcomesQuery,
} = dashboardApi;

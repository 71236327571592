import { ImpactInfoModel } from '@pages/app/rca/create/steps/impact-step-models';
import { ImpactStepState } from '@pages/app/rca/create/steps/impact-step-hook';
import { Grid, styled, Typography } from '@mui/material';
import useImpactInfo from '@pages/app/rca/create/components/impact-info-hook';
import WCTSelectField from '@components/input/select-field';
import BinarySelectField from '@components/input/binary-select-field';
import WCTTextField, { TextFieldMaxSize } from '@components/input/text-field';
import { Divider } from '@components/layout-util-components/divider';
import Row from '@components/layout-util-components/row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import Spacer from '@components/layout-util-components/spacer';
import { FormEvent } from 'react';
import { CaseImpactTypeMetric } from '@api/types/case-impact-type-metric/case-impact-type-metric.resource';
import RadioSelectGroup from '@components/input/radio-select-group';
import { InputSkeleton } from '@components/skeletons';
import { numberFromString } from '@util/string-util';
import WCTCurrencyField from '@components/input/currency-field';
import { PageAlertDisplay } from '@components/alerts';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  model: ImpactInfoModel;
  createDetailsState: ImpactStepState;
}

const Container = styled('form', { target: 'impact-info-form-container' })({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  padding: 30,
  gap: 33,
  background: '#EDEDED',
});

export default function ImpactInfoForm({ model, createDetailsState }: Props) {
  const { cancel } = createDetailsState;
  const {
    track,
    trackOptions,
    typeOfImpactOptions,
    typeOfImpact,
    metric,
    metricType,
    description,
    canSubmit,
    impactTypeOptions,
    loadingMetricOptions,
    metricValueOptions,
    metricValue,
    isEdit,
    isBusy,
    submit,
    severityOptions,
    severity,
    systemText,
    currency,
  } = useImpactInfo(createDetailsState, model);

  return (
    <Container
      noValidate
      onSubmit={async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if ('submitter' in e.nativeEvent) {
          const submitter = (e.nativeEvent as SubmitEvent).submitter;
          const addAnother =
            submitter != null && submitter.id === 'createAndAdd';
          submit(addAnother);
        }
      }}
    >
      <Typography variant="h4">{isEdit ? 'Edit' : 'Add'} Impact</Typography>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            {systemText['RCA.Details.TypeOfImpact']} *
          </Typography>
          <Typography variant="body2">
            Example sub text explaining question
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <BinarySelectField
            name="typeOfImpact"
            options={typeOfImpactOptions}
            value={typeOfImpact.value}
            error={typeOfImpact.error}
            onChange={typeOfImpact.set}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            {systemText['RCA.Details.ImpactCategory']} *
          </Typography>
          <Typography variant="body2">
            Example sub text explaining question
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <WCTSelectField
            name="category"
            id="category"
            options={impactTypeOptions}
            placeholder={`${systemText['RCA.Details.ImpactCategory']} *`}
            value={metric.value}
            error={metric.error}
            onChange={metric.set}
            onBlur={metric.validate}
            required
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">Description *</Typography>
          <Typography variant="body2">
            Example sub text explaining question
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <WCTTextField
            name="description"
            placeholder="Description"
            value={description.value}
            error={description.error}
            onChange={description.set}
            onBlur={description.validate}
            required
            multiline
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">Severity *</Typography>
          <Typography variant="body2">
            Example sub text explaining question
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <RadioSelectGroup
            options={severityOptions}
            value={severity.value}
            error={severity.error}
            onChange={severity.set}
          />
        </Grid>
      </Grid>
      {metricType === CaseImpactTypeMetric.scale ? (
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              What was the actual environmental impact? *
            </Typography>
            <Typography variant="body2">
              Example sub text explaining question
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {loadingMetricOptions ? (
              <InputSkeleton maxWidth={TextFieldMaxSize.small} />
            ) : (
              <RadioSelectGroup
                value={numberFromString(metricValue.value)}
                error={metricValue.error}
                onChange={metricValue.set}
                options={metricValueOptions!}
              />
            )}
          </Grid>
        </Grid>
      ) : metricType === CaseImpactTypeMetric.currency ? (
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              What was the actual revenue impact? *
            </Typography>
            <Typography variant="body2">
              Example sub text explaining question
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {loadingMetricOptions ? (
              <InputSkeleton maxWidth={TextFieldMaxSize.small} />
            ) : (
              <WCTCurrencyField
                value={metricValue.value}
                error={metricValue.error}
                onChange={metricValue.set}
                onBlur={metricValue.validate}
                placeholder="0.00"
                name="currencyValue"
                required
                currency={currency}
              />
            )}
          </Grid>
        </Grid>
      ) : metricType === CaseImpactTypeMetric.numeric ? (
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              What was the actual value of the impact? *
            </Typography>

            <Typography variant="body2">
              Example sub text explaining question
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {loadingMetricOptions ? (
              <InputSkeleton maxWidth={TextFieldMaxSize.small} />
            ) : (
              <WCTTextField
                value={metricValue.value}
                error={metricValue.error}
                onChange={metricValue.set}
                onBlur={metricValue.validate}
                maxWidth={TextFieldMaxSize.small}
                placeholder="0"
                name="metricValue"
                required
              />
            )}
          </Grid>
        </Grid>
      ) : null}
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            Would you like to track this impact? *
          </Typography>
          <Typography variant="body2">
            Example sub text explaining question
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <BinarySelectField
            name="track"
            options={trackOptions}
            value={track.value}
            error={track.error}
            onChange={track.set}
          />
        </Grid>
      </Grid>
      <Divider />
      <PageAlertDisplay />
      <Row>
        <OutlinedButton onClick={cancel}>Cancel</OutlinedButton>
        <Spacer />
        <OutlinedButton
          id="create"
          type="submit"
          disabled={!canSubmit}
          isBusy={isBusy}
        >
          {isEdit ? 'Update' : 'Create Impact'}
        </OutlinedButton>
        {!isEdit ? (
          <>
            <Gap size={20} />
            <PrimaryButton
              id="createAndAdd"
              type="submit"
              disabled={!canSubmit}
              isBusy={isBusy}
            >
              Create and add another
            </PrimaryButton>
          </>
        ) : null}
      </Row>
    </Container>
  );
}

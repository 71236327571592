import { RcaNodeType } from '@store/rca-editor/types';
import { styled } from '@mui/material';

const NodeDefaultBody = '/svgs/node-default-body.svg';
const NodeEndStateBody = '/svgs/node-end-state-body.svg';
const NodeConnectionBody = '/svgs/node-connection-body.svg';
const NodeDefaultBorder = '/svgs/node-default-border.svg';
const NodeEndStateBorder = '/svgs/node-end-state-border.svg';
const NodeConnectionBorder = '/svgs/node-connection-border.svg';
const NodeDefaultSelectedBorder = '/svgs/node-default-selected-border.svg';
const NodeEndStateSelectedBorder = '/svgs/node-end-state-selected-border.svg';
const NodeConnectionSelectedBorder =
  '/svgs/node-connection-selected-border.svg';
const NodeDefaultHoverBorder = '/svgs/node-default-border-hover.svg';
const NodeEndStateHoverBorder = '/svgs/node-end-state-border-hover.svg';
const NodeConnectionHoverBorder = '/svgs/node-connection-border-hover.svg';

const StyleMap: Record<
  string,
  {
    body: string;
    border: string;
    selectedBorder: string;
    hoverBorder: string;
  }
> = {
  [RcaNodeType.default]: {
    body: NodeDefaultBody,
    border: NodeDefaultBorder,
    selectedBorder: NodeDefaultSelectedBorder,
    hoverBorder: NodeDefaultHoverBorder,
  },
  [RcaNodeType.endState]: {
    body: NodeEndStateBody,
    border: NodeEndStateBorder,
    selectedBorder: NodeEndStateSelectedBorder,
    hoverBorder: NodeEndStateHoverBorder,
  },
  [RcaNodeType.connection]: {
    body: NodeConnectionBody,
    border: NodeConnectionBorder,
    selectedBorder: NodeConnectionSelectedBorder,
    hoverBorder: NodeConnectionHoverBorder,
  },
};

const FallbackStyle = StyleMap[RcaNodeType.default];

interface StyledNodeProps {
  highlightColor?: string;
  outlineColor?: string;
  nodeType: RcaNodeType;
  isSelected?: boolean;
  isConnection?: boolean;
}

export const StyledNode = styled('div')<StyledNodeProps>(
  ({
    theme,
    outlineColor,
    highlightColor,
    nodeType,
    isSelected,
    isConnection,
  }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    filter: 'drop-shadow(0px 4px 16px #00000026)',

    '.node-background': {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      mask: `url(${
        StyleMap[nodeType]?.body ?? FallbackStyle.body
      }) center center no-repeat`,
      maskSize: 'contain',
      backgroundColor: highlightColor ?? 'white',
      pointerEvents: 'none',

      ...(isConnection && {
        backgroundColor: '#F0F0F0D9',
      }),
    },

    '.node-border': {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      mask: `url(${
        StyleMap[nodeType]?.border ?? FallbackStyle.border
      }) center center no-repeat`,
      maskSize: 'contain',
      backgroundColor: outlineColor ?? 'black',
      pointerEvents: 'none',

      ...(isSelected && {
        mask: `url(${
          StyleMap[nodeType]?.selectedBorder ?? FallbackStyle.selectedBorder
        }) center center no-repeat`,
      }),
    },

    '&:hover': {
      '.node-border': {
        ...(!isSelected && {
          mask: `url(${
            StyleMap[nodeType]?.hoverBorder ?? FallbackStyle.hoverBorder
          }) center center no-repeat`,
          backgroundColor: theme.palette.common.grey60,
        }),
      },
    },

    '&.faded': {
      opacity: 0.3,
    },
  })
);

import { CaseEvidenceResource } from '@api/types/case/case-evidence/case-evidence.resource';
import UpsertEvidenceFormView from '@pages/app/rca/tabs/components/editor-panels/components/evidence-panel/upsert-evidence-form-view';
import {
  DrawerContent,
  DrawerHeader,
  SideDrawerFormProps,
  withSideDrawer,
} from '@components/ui-popup/drawers';
import Row from '@components/layout-util-components/row';
import CircleContainer from '@components/containers/circle-container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/pro-light-svg-icons';
import { Grid, Typography } from '@mui/material';
import Column from '@components/layout-util-components/column';
import LabelValueDisplay from '@components/data/label-value-display';
import RelatedCauseBoxCard from '@components/cards/related-cause-box-card';
import useField from '@hooks/use-field-hook';
import { CaseFileListItemResponse } from '@api/types/case/case-file/case-file-list-item.response';
import { required } from '@util/validators';
import { useMemo } from 'react';
import { CaseFileResource } from '@api/types/case/case-file/case-file.resource';
import CaseFileManagerInput from '@pages/app/rca/tabs/components/modals/case-file-manager/case-file-manager-input';
import { dateOnlyShortFormatter, timespanFormatter } from '@i18n/formatters';
import useCaseFileMenuActions from '@pages/app/rca/tabs/hooks/case-file-menu-actions-hook';
import { Gap } from '@components/layout-util-components/gap';

export interface AddEvidenceFormProps extends SideDrawerFormProps<never> {
  evidence?: CaseEvidenceResource;
  caseId: number;
  viewMode?: boolean;
}

function EvidenceForm({
  onClose,
  evidence,
  caseId,
  viewMode,
}: AddEvidenceFormProps) {
  console.assert(
    viewMode === false || evidence,
    'Evidence is required in view mode'
  );
  const { getCaseFileMenuActions } = useCaseFileMenuActions();
  const isCreate = !viewMode && !evidence;
  const chainItemLength = evidence?.chainItems?.length ?? 0;
  const filesLength = evidence?.caseFiles?.length ?? 0;
  const files = useField<Array<CaseFileListItemResponse>>(
    [required({}, 'At least one file is required')],
    useMemo(
      () =>
        evidence?.caseFiles
          ? evidence?.caseFiles?.map(CaseFileResource.toListItemResponse)
          : [],
      [evidence?.caseFiles]
    )
  );

  return (
    <>
      <DrawerHeader onClose={onClose}>
        {`${viewMode ? 'View' : evidence ? 'Edit' : 'Create'} Evidence`}
      </DrawerHeader>
      <DrawerContent>
        {!viewMode ? (
          <Column>
            {evidence ? (
              <>
                <Grid container rowGap={2}>
                  <Grid item xs={6}>
                    <LabelValueDisplay
                      label="ADDED BY"
                      value={evidence!.createdByName}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <LabelValueDisplay
                      label="DATE CREATED"
                      value={`${dateOnlyShortFormatter(
                        evidence?.created
                      )} (${timespanFormatter(evidence?.created)})`}
                    />
                  </Grid>
                </Grid>
                <Gap size={24} />
              </>
            ) : null}
            <UpsertEvidenceFormView
              handleClose={onClose}
              handleSubmit={onClose}
              evidence={evidence}
              caseId={caseId}
              showExtra={!isCreate}
            />
          </Column>
        ) : (
          <Column>
            <Row>
              <CircleContainer size={40}>
                <FontAwesomeIcon icon={faFolder} />
              </CircleContainer>
              <Gap size={10} />
              <Typography variant="h5">{evidence!.name}</Typography>
            </Row>
            <Gap size={32} />
            <Grid container rowGap={2}>
              {evidence!.description && (
                <Grid item xs={12}>
                  <LabelValueDisplay
                    label="DESCRIPTION"
                    value={evidence!.description}
                  />
                </Grid>
              )}

              <Grid item xs={6}>
                <LabelValueDisplay
                  label="ADDED BY"
                  value={evidence!.createdByName}
                />
              </Grid>
              <Grid item xs={6}>
                <LabelValueDisplay
                  label="DATE CREATED"
                  value={`${dateOnlyShortFormatter(
                    evidence?.created
                  )} (${timespanFormatter(evidence?.created)})`}
                />
              </Grid>
              <Grid item xs={6}>
                <LabelValueDisplay
                  label="SENSITIVITY"
                  value={evidence!.sensitivity}
                />
              </Grid>
              <Grid item xs={6}>
                <LabelValueDisplay
                  label="STRENGTH"
                  value={`${evidence!.quality}/5`}
                />
              </Grid>
            </Grid>
            {chainItemLength > 0 && (
              <>
                <Gap size={32} />
                <Typography>{`Related cause boxes (${chainItemLength})`}</Typography>
                <Gap size={20} />
                {evidence!.chainItems!.map((chainItem) => (
                  <>
                    <RelatedCauseBoxCard
                      chainItem={chainItem}
                      caseId={caseId}
                      key={chainItem.chainItemId}
                    />
                    <Gap size={8} />
                  </>
                ))}
              </>
            )}
            {filesLength > 0 && (
              <>
                <Gap size={32} />
                <Typography>{`Attached Files (${filesLength})`}</Typography>
                <CaseFileManagerInput
                  value={files.value}
                  onChange={files.set}
                  readonly
                  elipsisActions={getCaseFileMenuActions}
                />
              </>
            )}
          </Column>
        )}
      </DrawerContent>
    </>
  );
}

export default withSideDrawer(EvidenceForm);

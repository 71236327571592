import { ComponentType, useEffect, useMemo } from 'react';
import { Navigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import useUser from '@store/user/user-hook';
import { FullScreenLoadingIndicator } from '@components/loading-indicator';
import { isApp } from '@util/env';
import { Permission } from '@api/types/permission';
import { Typography } from '@mui/material';
import usePostLogin from '@hooks/use-post-login-hook';
import { useIsPath } from '@util/path-util';

interface Args {
  mfaRequired?: boolean;
  permissions?: Array<Permission>;
}

export default function requireAuth<P>(
  Component: ComponentType<P>,
  { mfaRequired = true, permissions = [] }: Args = {}
) {
  return (props: P) => {
    const { pathname, search } = useLocation();

    const isLogoutPath = useIsPath('/auth/logout');

    const {
      isLoggedIn,
      userRequires2fa,
      hasPermission,
      isLoggedInAndFullyAuthenticated,
      logout,
    } = useUser();

    useEffect(() => {
      // Monitor login cookie and call logout when it expires
      const interval = setInterval(() => {
        if (!isLoggedIn) {
          clearInterval(interval);
          return;
        }

        const cookie = document.cookie
          .split(';')
          .find((c) => c.trim().startsWith('X-Token-Header-Payload'));

        if (cookie == null) {
          logout();
        }
        // Decode jwt cookie
        const payload = JSON.parse(atob(cookie!.split('.')[1]));
        const expires = new Date(payload.exp * 1000);
        const now = new Date();
        if (now > expires) {
          // delete cookie
          try {
            document.cookie =
              'X-Token-Header-Payload=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
          } finally {
            logout();
          }
        }
      }, 5000);

      return () => clearInterval(interval);
    }, [isLoggedIn, logout]);

    const {
      isLoading: loadingPostLoginActions,
      hasCompany,
      maybeTriggerPostLogin,
    } = usePostLogin();

    const isPermissionGranted = useMemo(() => {
      if (!isLoggedIn) {
        return false;
      }

      if (permissions == null || permissions.length === 0) {
        return true;
      }

      for (const permission of permissions) {
        if (hasPermission(permission)) {
          return true;
        }
      }

      return false;
    }, [hasPermission, isLoggedIn]);

    if (isLogoutPath) {
      return <Component {...(props as any)} />;
    }

    if (!isLoggedIn) {
      return (
        <Navigate
          to={`/auth/login/${search}`}
          replace={true}
          state={{ returnTo: pathname }}
        />
      );
    }

    if (isLoggedInAndFullyAuthenticated) {
      if (loadingPostLoginActions || (isApp && hasCompany == null)) {
        return <FullScreenLoadingIndicator />;
      }

      if (isApp && !hasCompany) {
        if (!pathname.startsWith('/auth/create-company')) {
          return (
            <Navigate
              to={`/auth/create-company/${search}`}
              replace={true}
              state={{ returnTo: pathname }}
            />
          );
        } else {
          return <Component {...(props as any)} />;
        }
      }

      if (!isPermissionGranted) {
        return (
          <Typography variant="body1">
            This page does not seem to exist
          </Typography>
        );
      }

      if (!pathname.startsWith('/auth')) {
        maybeTriggerPostLogin();
      }

      return <Component {...(props as any)} />;
    }

    if (mfaRequired && userRequires2fa) {
      return (
        <Navigate
          to={`/auth/mfa/${search}`}
          replace={true}
          state={{ returnTo: pathname }}
        />
      );
    }

    return <Component {...(props as any)} />;
  };
}

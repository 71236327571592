import { useFilter } from '@components/table';
import { SortDirection } from '@api/types/sort-direction';
import useLoadMore from '@hooks/use-load-more-hook';
import { useGetCompanyUsersOptionsQuery } from '@api/endpoints/company-user.api';
import { useGetTasksQuery } from '@api/endpoints/company/company-task-detail.api';
import useCompleteTask from '@pages/app/tasks/hooks/complete-task-took';
import { useGetTaskStatusOptionsQuery } from '@api/endpoints/task-detail.api';
import { TaskStatus } from '@api/types/task-detail/task-status';
import useSortableHeader, {
  SortOption,
} from '@components/table/hooks/use-sortable-header-hook';

export default function useTasks(caseId?: number) {
  const { data: userOptions, isLoading: loadingUsers } =
    useGetCompanyUsersOptionsQuery({});
  const { data: taskStatusOptions, isLoading: loadingTaskStatusOptions } =
    useGetTaskStatusOptionsQuery();

  const sortByOptions: Array<SortOption> = [
    { id: 'dueDate', label: 'Due Date', direction: SortDirection.asc },
    {
      id: 'caseName',
      label: 'Linked RCA',
      direction: SortDirection.asc,
    },
  ];

  const companyUserFilter = useFilter<Array<number>>([]);
  const taskStatusFilter = useFilter<Array<TaskStatus>>([]);

  const search = useFilter<string>();
  const { skip, take, loadMore, canLoadMore } = useLoadMore();
  const sort = useSortableHeader({ initialProperty: 'dueDate' });

  const {
    data: tasks,
    isLoading: loadingTasks,
    isFetching,
  } = useGetTasksQuery({
    assignedToCompanyUserIds: companyUserFilter.arrayValue,
    status: taskStatusFilter.arrayValue,
    searchText: search.value,
    sortBy: sort.property,
    sortDirection: sort.direction,
    skip,
    take,
    caseId,
  });

  const { completeTask, openTask } = useCompleteTask();

  const isLoading = loadingTasks || loadingUsers || loadingTaskStatusOptions;

  return {
    search,
    sort,
    sortByOptions,
    isLoading,
    tasks,
    companyUserFilter,
    taskStatusFilter,
    loadMore,
    userOptions,
    taskStatusOptions,
    isFetching,
    hasNoTasks: !isLoading && (tasks?.totalCount ?? 0) === 0,
    completeTask,
    openTask,
    canLoadMore: canLoadMore(tasks?.count),
  };
}

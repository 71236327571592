import {
  useCompleteTaskForCompanyMutation,
  useOpenTaskForCompanyMutation,
} from '@api/endpoints/company/company-task-detail.api';
import { useCallback } from 'react';
import { ApiError } from '@api/types/api-error';
import { CompleteTaskRequest } from '@api/types/task-detail/complete-task.request';
import { usePageAlertVariants } from '@components/alerts';
import { useAppTr } from '@i18n/use-app-tr';

export default function useCompleteTask() {
  const { t } = useAppTr('taskForm');
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();

  const [submitCompleteTask, { isLoading: isCompletingTask }] =
    useCompleteTaskForCompanyMutation();
  const [submitOpenTask, { isLoading: isOpeningTask }] =
    useOpenTaskForCompanyMutation();

  const completeTask = useCallback(
    (taskId: number): Promise<boolean> => {
      return submitCompleteTask({
        taskDetailId: taskId,
      })
        .unwrap()
        .then(() => {
          showSuccessMessage(t('toasts.success.complete'));
          return true;
        })
        .catch(({ message, errors }: ApiError<CompleteTaskRequest>) => {
          showErrorMessage(errors?.taskDetailId ?? message);
          return false;
        });
    },
    [showErrorMessage, showSuccessMessage, submitCompleteTask, t]
  );

  const openTask = useCallback(
    (taskId: number): Promise<boolean> => {
      return submitOpenTask({
        taskDetailId: taskId,
      })
        .unwrap()
        .then(() => {
          showSuccessMessage(t('toasts.success.open'));
          return true;
        })
        .catch(({ message, errors }: ApiError<CompleteTaskRequest>) => {
          showErrorMessage(errors?.taskDetailId ?? message);
          return false;
        });
    },
    [showErrorMessage, showSuccessMessage, submitOpenTask, t]
  );

  return {
    completeTask,
    openTask,
    isLoading: isCompletingTask || isOpeningTask,
  };
}

import { ComponentType } from 'react';
import { styled } from '@mui/material';
import RcaEditorAppBar from '@pages/app/rca/tabs/components/rca-editor-app-bar';
import { useGetCaseDetailQuery } from '@api/endpoints/case.api';
import { useParams } from 'react-router-dom';
import { FullScreenLoadingIndicator } from '@components/loading-indicator';
import { EditorPathParams } from '@pages/app/rca/tabs/editor-path-params';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  height: '100vh',
  width: '100vw',
});

const InnerContainer = styled('div')({
  height: 'calc(100vh - 80px)',
  width: '100%',
  backgroundColor: '#eee',
  display: 'flex',
  alignItems: 'stretch',
});

const ContentContainer = styled('div', {
  target: 'rca-form-scaffold-content-container',
})({
  display: 'flex',
  justifyContent: 'stretch',
  flex: '1 0 75%',
});

const SidebarContainer = styled('div')({
  position: 'fixed',
  right: 20,
  top: 20,
  backgroundColor: '#E5E5E5',
  flex: '0 1 25%',
});

const withEditorScaffold =
  (Component: ComponentType, SideBarComponent?: ComponentType) => () => {
    const { caseId } = useParams<EditorPathParams>();
    const { data, isSuccess } = useGetCaseDetailQuery(
      caseId != null ? +caseId : 0,
      {
        skip: caseId == null,
      }
    );

    return (
      <Container>
        <RcaEditorAppBar caseDetail={data} />
        {isSuccess ? (
          <InnerContainer>
            <ContentContainer>
              <Component />
            </ContentContainer>
            {SideBarComponent ? (
              <SidebarContainer>
                <SideBarComponent />
              </SidebarContainer>
            ) : null}
          </InnerContainer>
        ) : (
          <FullScreenLoadingIndicator />
        )}
      </Container>
    );
  };

export default withEditorScaffold;

import useMyGroups from '@pages/app/groups/my-groups-hook';
import { Box, Typography } from '@mui/material';
import { useAppTr } from '@i18n/use-app-tr';
import { GroupCard } from '@components/cards';
import requireAuth from '@components/hoc/with-required-auth-hoc';
import { LoadingIndicator } from '@components/loading-indicator';
import { LinkButton } from '@components/buttons';
import { CompanyGroupListItemResponse } from '@api/types/company-group/company-group-list-item.response';
import GroupDetailView, {
  GroupDetailViewProps,
} from '@pages/app/profile/components/group-detail-view';
import LeaveGroupModal, {
  LeaveGroupAction,
  LeaveGroupModalProps,
} from '@pages/app/profile/components/leave-group-modal';
import GroupForm, { GroupFormProps } from '@pages/app/groups/forms/group-form';
import { CompanyGroupResource } from '@api/types/company/company-group/company-group.resource';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import useSearchParamsTyped from '@hooks/router-dom-helper-hooks';
import { useCallback, useEffect } from 'react';
import { numberFromString } from '@util/string-util';
import { CardLayout } from '@components/layout-util-components/card-layout';
import NoGroupsInfo from '../profile/components/no-groups-info';
import ContentContainer from '@components/containers/content-container';
import { Gap } from '@components/layout-util-components/gap';

type QueryParams = {
  companyGroupId?: string;
};

function MyGroupsPage() {
  const { companyGroupId } = useSearchParamsTyped<QueryParams>();

  const { t } = useAppTr('groups');
  const { showPopup } = useUiPopup();

  const { isLoading, groups, loadMore, showLoadMoreButton } = useMyGroups();

  const onViewGroup = useCallback(
    (id: number) => {
      showPopup<GroupDetailViewProps, void>(GroupDetailView, { id });
    },
    [showPopup]
  );

  const onLeaveGroup = async (group: CompanyGroupListItemResponse) => {
    const { companyGroupId, groupName } = group;

    const action = await showPopup<LeaveGroupModalProps, LeaveGroupAction>(
      LeaveGroupModal,
      {
        companyGroupId,
        companyName: groupName,
      }
    );

    if (action === LeaveGroupAction.edit) {
      return showPopup<GroupFormProps, CompanyGroupResource>(GroupForm, {
        groupId: companyGroupId,
      });
    }
  };

  useEffect(() => {
    if (!isLoading && companyGroupId != null) {
      onViewGroup(numberFromString(companyGroupId)!);
    }
  }, [companyGroupId, isLoading, onViewGroup]);

  return (
    <ContentContainer>
      {isLoading ? (
        <LoadingIndicator show />
      ) : groups!.totalCount === 0 ? (
        <NoGroupsInfo />
      ) : (
        <>
          <Typography variant="h1">
            {t('title.user', { count: groups?.totalCount ?? 0 })}
          </Typography>
          <Gap size={20} />
          <CardLayout>
            {isLoading ? (
              <LoadingIndicator show />
            ) : (
              groups!.model.map((group) => (
                <GroupCard
                  key={group.companyGroupId}
                  group={group}
                  actions={[
                    {
                      label: t('card.actions.view'),
                      onClick: () => onViewGroup(group.companyGroupId),
                    },
                    {
                      label: t('card.actions.leave'),
                      onClick: () => onLeaveGroup(group),
                    },
                  ]}
                />
              ))
            )}
          </CardLayout>
          <Gap size={15} />
          <Box alignSelf="center">
            {showLoadMoreButton ? (
              <LinkButton disabled={isLoading} onClick={loadMore}>
                {t('loadMoreButtonLabel')}
              </LinkButton>
            ) : null}
          </Box>
        </>
      )}
    </ContentContainer>
  );
}

export default requireAuth(MyGroupsPage);

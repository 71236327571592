import ProfileLayoutScaffold from '@components/layouts/app/scaffolds/profile-layout-scaffold';

export default function ProfilePageLayout() {
  return (
    <ProfileLayoutScaffold
      baseRoute="profile"
      title="Your Profile"
      tabs={[
        {
          index: true,
          path: '/',
          name: 'Personal Details',
        },
        {
          path: 'skills',
          name: 'Skills',
        },
        {
          path: 'kudos',
          name: 'Kudos Points',
        },
        {
          path: 'preferences',
          name: 'Personal Settings',
        },
        // {
        //   path: 'areas-of-interest',
        //   name: 'Areas of Interest',
        // },
        {
          path: 'settings',
          name: 'Security',
          subPaths: ['settings/password', 'settings/mfa'],
        },
      ]}
    />
  );
}

import {
  NodeEndStateType,
  RcaEdge,
  RcaEdgeType,
  RcaNode,
  RcaNodeData,
  RcaNodeType,
  StorageNode,
} from '@store/rca-editor/types';
import { v4 as uuid } from 'uuid';
import { AppDispatch, RootState } from '@store/store';

import {
  addNodesToStorage,
  clearProximityEdge,
  decrementNodeBusyTracker,
  incrementNodeBusyTracker,
  NodePanelEditorTab,
  onConnectNode,
  onEdgesChange,
  onNodesChange,
  refreshNodes,
  resetFocus,
  resetOnDragIds,
  resetToInitialState,
  selectNodePanelEditorTab,
  setEditNodeContentId,
  setHoverVisibilityNodeId,
  setIsDraggingIntoStorageContainer,
  setOnDragDescendantIds,
  setOnDragOriginalId,
  setProximityEdge,
  setSelectedNode,
  setSortedSiblings,
  setStorageGraphNode,
  setStorageNodes,
  toggleDevMode,
  updateEdge,
  updateNode,
} from '@store/rca-editor/rca-editor-slice';
import {
  EdgeResetChange,
  NodeAddChange,
  NodeRemoveChange,
} from '@reactflow/core/dist/esm/types/changes';
import { EdgeAddChange, EdgeRemoveChange } from 'reactflow';
import {
  makeSelectAlLDescendants,
  makeSelectChildCount,
  makeSelectChildNodes,
  makeSelectChildNodesWithSubMetaChildNodes,
  makeSelectConnectionChildNodes,
  makeSelectConnectionNodesTo,
  makeSelectNode,
  makeSelectNodeFromChainItemId,
  makeSelectNonMetaChildNodes,
  makeSelectOutgoingEdges,
  makeSelectParentConnectingEdge,
  makeSelectParentNode,
  selectChainId,
  selectDraggingNodeDescendants,
  selectDragHolderNode,
  selectEdges,
  selectIsDraggingIntoStorageContainer,
  selectMetaNodes,
  selectNodes,
  selectOnDragOriginalParentId,
  selectProximityEdge,
  selectStorageGraphNode,
  selectStorageNodes,
} from '@store/rca-editor/selectors';
import { stratify, tree } from 'd3-hierarchy';
import {
  getRFPosition,
  RFDirection,
  rfPositionMap,
} from '@util/react-flow-utils';
import { RcaUtil } from '@util/rca-util';
import { isProd } from '@util/env';
import chainItemApi from '@api/endpoints/chain/chain-item.api';
import { saveGraphState } from '@store/rca-editor-snapshot/rca-editor-snapshot-actions';
import { ChainItemResource } from '@api/types/chain/chain-item.resource';
import chainItemStorageApi from '@api/endpoints/chain/chain-item-storage.api';
import { setAlert } from '@store/ui/ui-slice';
import { XYPosition } from '@reactflow/core/dist/esm/types/utils';

const layout = tree<RcaNode>()
  // the node size configures the spacing between the nodes ([width, height])
  .nodeSize([RcaUtil.NODE_HEIGHT, RcaUtil.NODE_WIDTH])
  // this is needed for creating equal space between all nodes
  .separation(() => 1.5);

export const layoutNodes = (nodes: Array<RcaNode>, edges: Array<RcaEdge>) => {
  const metaNodes = nodes.filter(
    (node) => node.type === RcaNodeType.or || node.type === RcaNodeType.andOr
  );
  const filteredNodes = nodes.filter((node) => metaNodes.indexOf(node) === -1);

  const hierarchy = stratify<RcaNode>()
    .id((d) => d.id)
    // get the id of each node by searching through the edges
    // this only works if every node has one connection
    .parentId((d: RcaNode) => {
      const edge = edges.find((e: RcaEdge) => e.target === d.id);
      if (edge == null) {
        return;
      }

      const metaNode = metaNodes.find((node) => node.id === edge.source);
      // Not connected to a meta node, so return the source (parent)
      if (metaNode == null) {
        return edge.source;
      }

      // Is a meta node... so we need to return this meta nodes' parent instead
      return edges.find((e: RcaEdge) => e.target === metaNode.id)?.source;
    })(filteredNodes);

  const root = layout(hierarchy);

  const updatedFilteredNodes = filteredNodes.map((node) => {
    // find the node in the hierarchy with the same id and get its coordinates
    const { x, y } = root.find((d) => d.id === node.id) ?? {
      x: node.position.x,
      y: node.position.y,
    };

    const direction: RFDirection = 'LR';
    return {
      ...node,
      sourcePosition: rfPositionMap[direction[1]],
      targetPosition: rfPositionMap[direction[0]],
      position: getRFPosition(x, y, direction),
      selected: false,
    };
  });

  const updatedMetaNodes = metaNodes.map((node) => {
    // Get all meta node children
    const childEdges = edges.filter((edge) => edge.source === node.id);
    const childNodes = childEdges.map(
      (edge) => updatedFilteredNodes.find((node) => edge.target === node.id)!
    );
    // Average the Y co-ordinate, so it's always in the middle
    let y = 0,
      x = childNodes[0].position.x - 75;
    for (const childNode of childNodes) {
      y += childNode.position.y + RcaUtil.HALF_NODE_HEIGHT - 12.5;
    }

    y /= childNodes.length;

    return {
      ...node,
      position: { x, y },
    };
  });

  return [...updatedFilteredNodes, ...updatedMetaNodes];
};

export const layoutChart =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const edges = selectEdges(getState());
    const nodes = [...selectNodes(getState())].sort(
      (a, b) => a.data.sortOrder - b.data.sortOrder
    );

    const laidOutNodes = layoutNodes(nodes, edges);

    dispatch(refreshNodes(laidOutNodes));
  };

const removeInvalidNodes =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const nodeChangeEvents: Array<NodeRemoveChange> = [];
    const edgeChangeEvents: Array<EdgeRemoveChange | EdgeAddChange> = [];

    const metaNodes = selectMetaNodes(getState());
    for (const metaNode of metaNodes) {
      if (metaNode.type === RcaNodeType.connection) {
        continue;
      }

      const childCount = makeSelectChildCount(metaNode.id)(getState());

      // Meta node with no children... simply remove it
      if (childCount === 0) {
        const parentEdge = makeSelectParentConnectingEdge(metaNode.id)(
          getState()
        );
        nodeChangeEvents.push({ type: 'remove', id: metaNode.id });
        edgeChangeEvents.push({ type: 'remove', id: parentEdge.id });

        // Meta node with 1 child... remove the meta node and reparent the child
        // to the meta node's parent
      } else if (childCount === 1) {
        const parentEdge = makeSelectParentConnectingEdge(metaNode.id)(
          getState()
        );
        const child = makeSelectChildNodes(metaNode.id)(getState())[0];
        const childParentEdge = makeSelectParentConnectingEdge(child.id)(
          getState()
        );

        nodeChangeEvents.push({ type: 'remove', id: metaNode.id });
        edgeChangeEvents.push({ type: 'remove', id: parentEdge.id });
        edgeChangeEvents.push({
          type: 'remove',
          id: childParentEdge.id,
        });

        edgeChangeEvents.push({
          type: 'add',
          item: {
            ...childParentEdge,
            source: parentEdge.source,
          },
        });
      }
    }

    dispatch(onNodesChange(nodeChangeEvents));
    dispatch(onEdgesChange(edgeChangeEvents));
  };

const removeNodeArray =
  (nodes: Array<RcaNode>) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    if (nodes.length === 0) {
      return;
    }

    const nodeChangeEvents: Array<NodeRemoveChange> = nodes.map((node) => ({
      type: 'remove',
      id: node.id,
    }));

    // remove connecting edges
    const edgeChangeEvents: Array<EdgeRemoveChange> = nodes
      .map((node) => makeSelectParentConnectingEdge(node.id)(getState()))
      .filter((edge) => edge != null)
      .map((edge) => ({ type: 'remove', id: edge!.id }));

    dispatch(onNodesChange(nodeChangeEvents));
    dispatch(onEdgesChange(edgeChangeEvents));
  };

const sanitizeChildSortOrders =
  (parentId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    const parent = makeSelectNode(parentId)(getState());
    if (parent == null) {
      return;
    }

    let fromNodeId = parentId;
    if (RcaUtil.isMetaNode(parent)) {
      fromNodeId = makeSelectParentNode(parentId)(getState())!.id;
    }

    const children = makeSelectChildNodesWithSubMetaChildNodes(fromNodeId)(
      getState()
    );
    dispatch(setSortedSiblings(RcaUtil.setSortOrders(children)));
  };

const updateNodeData =
  (nodeId: string, data: Partial<RcaNodeData>) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const node = makeSelectNode(nodeId)(getState());
    if (node == null) {
      return;
    }

    let isEqual = true;
    for (const dataKey in data) {
      const newVal = data[dataKey];
      const oldVal = node.data[dataKey];

      if (newVal !== oldVal) {
        isEqual = false;
        break;
      }
    }

    if (isEqual) {
      return;
    }

    const connections = makeSelectConnectionNodesTo(nodeId)(getState());

    dispatch(
      updateNode({
        ...node,
        data: {
          ...node.data,
          ...data,
        },
      })
    );

    for (const connection of connections) {
      dispatch(
        updateNode({
          ...connection,
          data: {
            ...node.data,
            ...data,
          },
        })
      );
    }
  };

const createNodeAndLinkFrom =
  (
    linkFromId: string,
    sortOrder?: number,
    type?: RcaNodeType,
    initialData?: RcaNodeData
  ) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const fromNode = makeSelectNode(linkFromId)(getState());
    if (fromNode == null) {
      return;
    }

    if (sortOrder != null && sortOrder <= -1) {
      sortOrder = 0;
    }

    // We need to handle the case where the fromNode is a meta node
    // We need to get its parent so that we can reliably sort
    let sortParentId = linkFromId;
    if (RcaUtil.isMetaNode(fromNode)) {
      sortParentId = makeSelectParentNode(linkFromId)(getState())!.id;
    }

    const preSortedNodes = RcaUtil.setSortOrders(
      makeSelectChildNodesWithSubMetaChildNodes(sortParentId)(getState())
    );
    sortOrder ??=
      (preSortedNodes[preSortedNodes.length - 1]?.data?.sortOrder ?? -1) + 1;
    sortOrder = isNaN(sortOrder) ? 0 : sortOrder;

    const nodeId = uuid();
    const newNode: RcaNode = {
      id: nodeId,
      // Position doesn't matter here, it's the sort order that determines when
      // the node is above or below its siblings.
      position: {
        x: 0,
        y: 0,
      },
      type: type ?? RcaNodeType.default,
      draggable: true,
      selected: false,
      data: {
        ...(initialData ?? {
          label: '',
          isRoot: false,
          sortOrder,
          disproved: fromNode.data.disproved,
        }),
      },
    };

    let edgeType: RcaEdgeType = RcaEdgeType.default;
    if (type === RcaNodeType.connection) {
      edgeType = RcaEdgeType.connection;
    }

    const edgeId = `${linkFromId}->${nodeId}`;
    const newEdge: RcaEdge = {
      id: edgeId,
      type: edgeType,
      source: linkFromId,
      target: newNode.id,
      targetHandle: null,
      sourceHandle: null,
    };

    const sortedSiblings = RcaUtil.incrementInsertedSortOrder(
      preSortedNodes,
      sortOrder
    );

    const nodeChanges: Array<NodeAddChange | NodeRemoveChange> = [
      {
        type: 'add',
        item: newNode,
      },
    ];

    const edgeChanges: Array<EdgeAddChange | EdgeRemoveChange> = [
      {
        type: 'add',
        item: newEdge,
      },
    ];

    dispatch(onNodesChange(nodeChanges));
    dispatch(onEdgesChange(edgeChanges));
    dispatch(setSortedSiblings(sortedSiblings));
    dispatch(layoutChart());

    dispatch(setEditNodeContentId(newNode.id));

    return makeSelectNode(nodeId)(getState())!;
  };

const setDescendantsDisprovedBasedOn =
  (nodeId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    const node = makeSelectNode(nodeId)(getState());
    if (node != null) {
      const { disproved } = node.data;
      const descendants = makeSelectAlLDescendants(node.id)(getState());
      if (descendants.length > 0) {
        for (const child of descendants) {
          dispatch(updateNodeData(child.id, { ...child.data, disproved }));
        }
      }
    }
  };

export const insertSiblingAbove =
  (nodeId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    const parent = makeSelectParentNode(nodeId)(getState());
    const node = makeSelectNode(nodeId)(getState());
    if (parent == null || node == null) {
      return;
    }

    return dispatch(createNodeAndLinkFrom(parent.id, node.data.sortOrder));
  };

export const insertSiblingBelow =
  (nodeId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    const parent = makeSelectParentNode(nodeId)(getState());
    const node = makeSelectNode(nodeId)(getState());
    if (parent == null || node == null) {
      return;
    }

    return dispatch(createNodeAndLinkFrom(parent.id, node.data.sortOrder + 1));
  };

export const addChildToNode =
  (nodeId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    const children = makeSelectChildNodes(nodeId)(getState());
    const sortOrder =
      children.length > 0
        ? children[children.length - 1].data.sortOrder + 1
        : 0;

    return dispatch(createNodeAndLinkFrom(nodeId, sortOrder));
  };

export const commitCreateNode =
  (nodeId: string, content: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const node = makeSelectNode(nodeId)(getState());
    if (node == null) {
      return;
    }

    dispatch(updateNodeData(nodeId, { label: content }));
    await dispatch(saveGraphState());
  };

export const updateNodeLabel =
  (nodeId: string, label: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const node = makeSelectNode(nodeId)(getState());
    if (node == null) {
      return;
    }

    dispatch(updateNodeData(nodeId, { label }));
    dispatch(saveGraphState());
  };

export const removeNode =
  (
    nodeId: string,
    opt: { moveToStorage?: boolean; reparentChildren?: boolean } = {
      moveToStorage: false,
      reparentChildren: false,
    }
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const node = makeSelectNode(nodeId)(getState());
    if (node == null || node.data.isRoot) {
      return;
    }

    const parent = makeSelectParentNode(nodeId)(getState())!;

    const nodesToRemove: Array<NodeRemoveChange> = [];
    const edgeChanges: Array<EdgeRemoveChange | EdgeAddChange> = [];

    const parentEdge = makeSelectParentConnectingEdge(nodeId)(getState());

    nodesToRemove.push({ type: 'remove', id: nodeId });
    edgeChanges.push({ type: 'remove', id: parentEdge.id });

    if (opt.reparentChildren) {
      const children = makeSelectChildNodes(nodeId, true)(getState());
      for (const child of children) {
        const childParentEdge = makeSelectParentConnectingEdge(child.id)(
          getState()
        );
        edgeChanges.push({ type: 'remove', id: childParentEdge.id });

        edgeChanges.push({
          type: 'add',
          item: {
            id: `${parent.id}->${child.id}`,
            source: parent.id,
            target: child.id,
            targetHandle: null,
            sourceHandle: null,
          },
        });

        dispatch(
          updateNodeData(child.id, { ...child.data, sortOrder: 999999 })
        );
      }
    } else {
      const descendants = makeSelectAlLDescendants(nodeId, true)(getState());
      for (const descendant of descendants) {
        const descendantParentEdge = makeSelectParentConnectingEdge(
          descendant.id
        )(getState());
        edgeChanges.push({ type: 'remove', id: descendantParentEdge.id });
        nodesToRemove.push({ type: 'remove', id: descendant.id });
      }

      if (opt.moveToStorage) {
        dispatch(addNodesToStorage([node]));

        const nonMetaDescendants = descendants.filter(
          (x) => !RcaUtil.isMetaNode(x)
        );
        if (nonMetaDescendants.length > 0) {
          dispatch(addNodesToStorage(nonMetaDescendants));
        }
      }
    }

    // Remove any connection nodes that are now orphaned
    const connectionNodes: Array<RcaNode> = [];
    for (const nodeToRemove of nodesToRemove) {
      const connections = makeSelectConnectionNodesTo(nodeToRemove.id)(
        getState()
      );
      if (connections.length > 0) {
        connectionNodes.push(...connections);
      }
    }

    for (const connectionNode of connectionNodes) {
      const connectionNodeParentEdge = makeSelectParentConnectingEdge(
        connectionNode.id
      )(getState());
      edgeChanges.push({ type: 'remove', id: connectionNodeParentEdge.id });
      nodesToRemove.push({ type: 'remove', id: connectionNode.id });
    }

    dispatch(onEdgesChange(edgeChanges));
    dispatch(onNodesChange(nodesToRemove));

    dispatch(sanitizeChildSortOrders(parent.id));
    dispatch(removeInvalidNodes());
    dispatch(resetFocus());
    dispatch(layoutChart());

    const { chainItemId } = node.data;
    if (chainItemId != null) {
      await dispatch(saveGraphState());
    }
  };

export const toggleNodeCollapseState =
  (nodeId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    const node = makeSelectNode(nodeId)(getState());
    if (node == null) {
      return;
    }

    const willCollapse = !node.data.collapse;
    const descendants = makeSelectAlLDescendants(nodeId)(getState());

    dispatch(updateNodeData(nodeId, { collapse: willCollapse }));
    dispatch(setHoverVisibilityNodeId(undefined));

    for (const descendant of descendants) {
      const descendantChildCount = makeSelectNonMetaChildNodes(descendant.id)(
        getState()
      ).length;

      dispatch(
        updateNodeData(descendant.id, {
          collapse: willCollapse ? false : descendantChildCount > 0,
        })
      );
    }

    return dispatch(saveGraphState());
  };

export const unCollapseNodeAndCollapseImmediateChildren =
  (nodeId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    const children = makeSelectNonMetaChildNodes(nodeId)(getState());

    dispatch(updateNodeData(nodeId, { collapse: false }));
    dispatch(setHoverVisibilityNodeId(undefined));

    for (const child of children) {
      const subChildCount = makeSelectNonMetaChildNodes(child.id)(
        getState()
      ).length;
      dispatch(updateNodeData(child.id, { collapse: subChildCount > 0 }));
    }

    return dispatch(saveGraphState());
  };

export const createStorageDragNode =
  (storageNode: StorageNode, x: number, y: number) =>
  (dispatch: AppDispatch) => {
    const nodeId = storageNode.clientUuid;
    const node: RcaNode = {
      id: nodeId,
      position: { x, y },
      type: RcaNodeType.default,
      data: {
        label: storageNode.description ?? '',
        sortOrder: -1,
        chainItemId: storageNode.chainItemId,
        isRoot: false,
        caseId: storageNode.caseId,
        endState: storageNode.endState,
        endStateId: storageNode.endStateId,
        collapse: false,
      },
    };

    dispatch(setStorageGraphNode(node));

    return node;
  };

export const upgradeStorageGraphNodePosition =
  (x: number, y: number) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const node = selectStorageGraphNode(getState());
    if (node && node.position.x !== x && node.position.y !== y) {
      dispatch(
        setStorageGraphNode({
          ...node,
          position: { x, y },
        })
      );

      dispatch(updateProximityEdge(node, { x, y }));
    }
  };

export const beginDraggingNode =
  (node: RcaNode) => (dispatch: AppDispatch, getState: () => RootState) => {
    // Cache the original parent of the node being dragged
    const parent = makeSelectParentNode(node.id)(getState());
    dispatch(setOnDragOriginalId(parent!.id));

    // Cache the descendants of the node being dragged
    const descendants = makeSelectAlLDescendants(node.id, true)(getState());
    dispatch(setOnDragDescendantIds(descendants.map((n) => n.id)));

    const nodeChangeEvents: Array<NodeAddChange> = [];
    const edgeChangeEvents: Array<EdgeResetChange | EdgeRemoveChange> = [];

    // Insert temp holding node to connect the immediate descendants to this, so edges stay in place
    const dragHolderNodeId = uuid();
    const dragHolderNode: RcaNode = {
      id: dragHolderNodeId,
      type: RcaNodeType.dragHolder,
      position: node.position,
      data: node.data,
    };

    nodeChangeEvents.push({
      type: 'add',
      item: dragHolderNode,
    });

    // Break parent connection to node being dragged
    const parentEdge = makeSelectParentConnectingEdge(node.id)(getState());
    if (parentEdge != null) {
      edgeChangeEvents.push({
        type: 'remove',
        id: parentEdge.id,
      });
    }

    dispatch(onNodesChange(nodeChangeEvents));
    dispatch(onEdgesChange(edgeChangeEvents));

    // Add the immediate descendants to the holder node
    const edges = makeSelectOutgoingEdges(node.id)(getState());
    for (const edge of edges) {
      dispatch(
        updateEdge({
          ...edge,
          source: dragHolderNodeId,
        })
      );
    }
  };

export const stopDraggingNode =
  (node: RcaNode) => (dispatch: AppDispatch, getState: () => RootState) => {
    const isDraggingIntoStorage = selectIsDraggingIntoStorageContainer(
      getState()
    );
    const holderNode = selectDragHolderNode(getState());
    const proximityConnectSourceId = selectProximityEdge(getState())?.source;

    let didSwapParentNodes = false;

    let originalParentId: string | undefined;
    // User dragging nodes pre-existing in-chart nodes...
    if (holderNode != null) {
      originalParentId = selectOnDragOriginalParentId(getState());

      const nodeChangeEvents: Array<NodeAddChange | NodeRemoveChange> = [];
      const edgeChangeEvents: Array<EdgeResetChange> = [];

      // Remove holder node
      nodeChangeEvents.push({
        type: 'remove',
        id: holderNode.id,
      });

      // Reconnect edges from holder node to node that was originally dragged
      const edges = makeSelectOutgoingEdges(holderNode.id)(getState());
      for (const edge of edges) {
        dispatch(
          updateEdge({
            ...edge,
            source: node.id,
          })
        );
      }

      dispatch(onNodesChange(nodeChangeEvents));
      dispatch(onEdgesChange(edgeChangeEvents));
      dispatch(
        onConnectNode({
          source: proximityConnectSourceId ?? originalParentId!,
          target: node.id,
          sourceHandle: null,
          targetHandle: null,
        })
      );

      if (originalParentId != null) {
        dispatch(sanitizeChildSortOrders(originalParentId));
      }

      if (isDraggingIntoStorage) {
        dispatch(removeNode(node.id, { moveToStorage: true }));

        if (originalParentId != null) {
          dispatch(sanitizeChildSortOrders(originalParentId));
        }
      } else if (
        proximityConnectSourceId != null &&
        proximityConnectSourceId !== originalParentId
      ) {
        didSwapParentNodes = true;
        dispatch(
          updateNode({
            ...node,
            draggable: true,
            data: { ...node.data, sortOrder: 999999 },
          })
        );
        dispatch(sanitizeChildSortOrders(proximityConnectSourceId));
      }
    } else {
      const storageNode = selectStorageGraphNode(getState());
      if (storageNode != null) {
        if (proximityConnectSourceId != null) {
          const nodeChangeEvents: Array<NodeAddChange> = [
            { type: 'add', item: storageNode },
          ];

          dispatch(onNodesChange(nodeChangeEvents));
          dispatch(
            onConnectNode({
              source: proximityConnectSourceId!,
              target: storageNode.id,
              sourceHandle: null,
              targetHandle: null,
            })
          );
          dispatch(
            updateNode({
              ...storageNode,
              draggable: true,
              data: { ...storageNode.data, sortOrder: 999999 },
            })
          );
          dispatch(sanitizeChildSortOrders(proximityConnectSourceId!));
          didSwapParentNodes = true;
        }
        dispatch(setStorageGraphNode(undefined));
      }
    }

    dispatch(setIsDraggingIntoStorageContainer(false));
    dispatch(clearProximityEdge());
    dispatch(resetOnDragIds());
    dispatch(removeInvalidNodes());

    const parentId = makeSelectParentNode(node.id)(getState());
    if (parentId != null && originalParentId !== parentId.id) {
      dispatch(setDescendantsDisprovedBasedOn(parentId.id));
    }

    dispatch(layoutChart());
    if (didSwapParentNodes) {
      dispatch(saveGraphState());
    }
  };

export const updateProximityEdge =
  (movingNode: RcaNode, mousePosition: XYPosition) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    let nodes = selectNodes(getState());

    const storageNode = selectStorageGraphNode(getState());
    nodes = storageNode ? [...nodes, storageNode] : nodes;

    const proximityEdge = selectProximityEdge(getState());
    const draggingDescendantIds =
      selectDraggingNodeDescendants(getState()) ?? [];

    const isHoveringOverStorageContainer = RcaUtil.isPointInStorageContainer(
      mousePosition.x,
      mousePosition.y
    );

    if (storageNode == null) {
      dispatch(
        setIsDraggingIntoStorageContainer(isHoveringOverStorageContainer)
      );
    }

    const NODE_AREA_WIDTH = 250;
    let dx: number, dy: number, d: number;
    const closestNode = nodes.reduce(
      (res, n) => {
        if (draggingDescendantIds.includes(n.id)) {
          return res;
        }

        if (n.id !== movingNode.id && RcaUtil.isAttachable(n)) {
          dx = n.position.x - movingNode.position.x + NODE_AREA_WIDTH;
          dy = n.position.y - movingNode.position.y;
          d = dx * dx + dy * dy;

          if (d < res.distance) {
            res.distance = d;
            res.node = n;
          }
        }

        return res;
      },
      {
        distance: Number.MAX_VALUE,
        node: null as RcaNode | null,
      }
    );

    const { node } = closestNode;
    if (node === null) {
      dispatch(clearProximityEdge());
    } else if (proximityEdge?.source !== node.id) {
      dispatch(
        setProximityEdge({
          id: 'proximity',
          className: 'proximity',
          source: node.id,
          target: movingNode.id,
          type: 'simplebezier',
        })
      );
    }
  };

export const makeNodeEndState =
  (
    nodeId: string,
    endState: NodeEndStateType,
    linkedChainItems: Array<number> = []
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const node = makeSelectNode(nodeId)(getState());
    if (node == null) {
      return;
    }

    const isRemoving = endState === NodeEndStateType.none;

    const originalLinkedNodes = makeSelectConnectionChildNodes(nodeId)(
      getState()
    );
    const removedNodes = originalLinkedNodes.filter(
      (x) => !linkedChainItems.includes(x.data.chainItemId ?? -999)
    );
    const addedNodes = linkedChainItems.filter(
      (x) => !originalLinkedNodes.map((y) => y.data.chainItemId).includes(x)
    );

    dispatch(removeNodeArray(removedNodes));

    dispatch(
      updateNode({
        ...node,
        type: !isRemoving ? RcaNodeType.endState : RcaNodeType.default,
        data: {
          ...node.data,
          endState,
        },
      })
    );

    const chainItemId = node.data.chainItemId!;

    if (
      endState === NodeEndStateType.linkToChainItem &&
      addedNodes.length > 0
    ) {
      let sortOrder = 9999;
      for (let i = 0; i < addedNodes.length; i++) {
        const linkedChainItemId = addedNodes[i];
        const linkToNode = makeSelectNodeFromChainItemId(linkedChainItemId)(
          getState()
        );

        if (linkToNode == null) {
          continue;
        }

        const linkedItems = [...(linkToNode.data.linkedFromChainItems ?? [])];
        if (linkedItems.findIndex((x) => x.id === chainItemId) === -1) {
          linkedItems.push({
            id: chainItemId,
            label: node.data.label,
          });
        }

        sortOrder += 1;
        dispatch(
          createNodeAndLinkFrom(nodeId, sortOrder, RcaNodeType.connection, {
            ...linkToNode.data,
          })
        );

        dispatch(
          updateNodeData(linkToNode.id, {
            linkedFromChainItems: linkedItems,
          })
        );
      }
    }

    const chainId = selectChainId(getState())!;

    dispatch(layoutChart());
    dispatch(
      saveGraphState(async () => {
        await dispatch(
          chainItemApi.endpoints.setChainItemEndState.initiate({
            chainId,
            endState,
            chainItemIdPath: chainItemId,
            chainItemId: linkedChainItems,
          })
        ).unwrap();
      })
    );

    dispatch(resetFocus());
  };

export const insertMetaNode =
  (type: RcaNodeType, children: Array<RcaNode>) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (children.length === 0) {
      return;
    }

    const node1 = children[0];

    const parentNode = makeSelectParentNode(node1.id)(getState());
    if (parentNode == null) {
      return;
    }

    let saveChart = true;
    let node2 = children.length > 1 ? children[1] : undefined;
    if (node2 == null) {
      // We don't want to save straight away because the user will have to enter the cause box label
      // for the automatically created second node
      saveChart = false;
      node2 = dispatch(
        createNodeAndLinkFrom(parentNode.id, node1.data.sortOrder + 1)
      )!;

      if (node2 == null) {
        return;
      }

      children.push(node2);
    }

    const metaNodeId = `meta-node-${type}-${uuid()}`;
    const metaNode: RcaNode = {
      id: metaNodeId,
      type: type,
      position: { x: 0, y: 0 },
      data: { isRoot: false, label: type, sortOrder: node1.data.sortOrder },
    };

    const nodeChangeEveents: Array<NodeAddChange> = [
      {
        type: 'add',
        item: metaNode,
      },
    ];

    const metaParentEdgeId = `meta-edge-${metaNodeId}-${uuid()}`;
    const metaParentEdge: RcaEdge = {
      id: metaParentEdgeId,
      source: parentNode.id,
      target: metaNodeId,
      targetHandle: null,
      sourceHandle: null,
    };

    const edgeChangeEvents: Array<EdgeRemoveChange | EdgeAddChange> = [
      {
        type: 'add',
        item: metaParentEdge,
      },
    ];

    for (const child of children) {
      let nodeParentEdge = makeSelectParentConnectingEdge(child.id)(getState());

      // Re-parent child to the new meta node
      nodeParentEdge = {
        ...nodeParentEdge,
        source: metaNodeId,
      };

      edgeChangeEvents.push({
        type: 'remove',
        id: nodeParentEdge.id,
      });

      edgeChangeEvents.push({
        type: 'add',
        item: nodeParentEdge,
      });
    }

    dispatch(onNodesChange(nodeChangeEveents));
    dispatch(onEdgesChange(edgeChangeEvents));
    dispatch(layoutChart());

    if (saveChart) {
      try {
        dispatch(incrementNodeBusyTracker(metaNodeId));
        await dispatch(saveGraphState());
      } catch (e) {
        console.log(e);
        throw e;
      } finally {
        dispatch(decrementNodeBusyTracker(metaNodeId));
      }
    }
  };

export const devOnlyResetGraph = () => (dispatch: AppDispatch) => {
  if (!isProd) {
    dispatch(resetToInitialState());
    dispatch(saveGraphState());
  }
};

export const devOnlyToggleDevMode = () => (dispatch: AppDispatch) => {
  if (!isProd) {
    dispatch(toggleDevMode());
  }
};

export const disproveNode =
  (nodeId: string, moveChildrenToStorage: boolean) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const node = makeSelectNode(nodeId)(getState());
    if (node == null) {
      return;
    }

    dispatch(updateNodeData(nodeId, { disproved: true }));

    const children = makeSelectAlLDescendants(nodeId)(getState());
    if (children.length > 0) {
      if (moveChildrenToStorage) {
        const nodeChangeEvt: Array<NodeRemoveChange> = [];
        for (const child of children) {
          nodeChangeEvt.push({
            type: 'remove',
            id: child.id,
          });
        }

        dispatch(addNodesToStorage(children));
        dispatch(onNodesChange(nodeChangeEvt));
        dispatch(layoutChart());
      } else {
        dispatch(setDescendantsDisprovedBasedOn(nodeId));
      }
    }

    return dispatch(saveGraphState());
  };

export const removeDisproval =
  (nodeId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    const node = makeSelectNode(nodeId)(getState());
    if (node == null) {
      return;
    }

    dispatch(updateNodeData(nodeId, { disproved: false }));

    const children = makeSelectAlLDescendants(nodeId)(getState());
    if (children.length > 0) {
      dispatch(setDescendantsDisprovedBasedOn(nodeId));
    }

    dispatch(
      setAlert({
        type: 'success',
        message: `You have successfully removed the disproved status of the cause box '${node.data.label}'`,
      })
    );

    return dispatch(saveGraphState());
  };

export const highlightNode =
  (nodeId: string, highlightDescendants: boolean) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const node = makeSelectNode(nodeId)(getState());
    if (node == null) {
      return;
    }

    dispatch(updateNodeData(nodeId, { highlight: true }));

    if (highlightDescendants) {
      const descendants = makeSelectAlLDescendants(nodeId)(getState());
      for (const descendant of descendants) {
        dispatch(updateNodeData(descendant.id, { highlight: true }));
      }
    }

    return dispatch(saveGraphState());
  };

export const addNodeToStorage =
  (chainItem: ChainItemResource) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const storageNodes = selectStorageNodes(getState());

    const newNodes: Array<StorageNode> = [
      ...storageNodes,
      {
        clientUuid: uuid(),
        ...chainItem,
      },
    ];

    dispatch(setStorageNodes(newNodes));
  };

export const removeNodeFromStorage =
  (node: StorageNode) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const chainId = selectChainId(getState())!;
    await dispatch(
      chainItemStorageApi.endpoints.deleteStorageItem.initiate({
        chainId,
        chainItemId: node.chainItemId,
      })
    ).unwrap();

    const storageNodes = selectStorageNodes(getState());

    dispatch(
      setStorageNodes(
        storageNodes.filter((x) => x.clientUuid !== node.clientUuid)
      )
    );
  };

export const updateStorageNodeDescription =
  (node: StorageNode, newDescription: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const chainId = selectChainId(getState())!;
    await dispatch(
      chainItemStorageApi.endpoints.updateStorageItem.initiate({
        chainId,
        chainItemId: node.chainItemId,
        description: newDescription,
      })
    ).unwrap();

    const storageNodes = selectStorageNodes(getState());

    const newNodes = storageNodes.map((storageNode) => {
      const description =
        storageNode.clientUuid === node.clientUuid
          ? newDescription
          : storageNode.description;

      return {
        ...storageNode,
        description,
      };
    });

    dispatch(setStorageNodes(newNodes));
  };

export const focusNodeAndBringIntoView =
  (
    node: RcaNode,
    activePanel: NodePanelEditorTab = NodePanelEditorTab.overview
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(selectNodePanelEditorTab(activePanel));
    dispatch(setSelectedNode(node));
    RcaUtil.focusNode(node);
  };

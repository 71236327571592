import SectionScaffold from '@components/outcome-report/components/section-scaffold';
import { Box, styled } from '@mui/material';
import { CaseOutcomeResource } from '@api/types/case/case-outcome/case-outcome.resource';
import Column from '@components/layout-util-components/column';
import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  outcome: CaseOutcomeResource;
}

// Grid layout split into 2 columns,
// each column takes up 1/2 of the space and has 1 row
const Container = styled('div')(({ theme: { palette } }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1.5fr',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
    'title-container summary'
  `,
  columnGap: '64px',
  rowGap: 0,
  height: '100%',
  width: '100%',
  padding: '10% 25%',
  color: '#2D2D2D',

  '.title-container': {
    gridArea: 'title-container',

    '.section-title': {
      fontFamily: 'Roboto',
      fontSize: '26px',
      fontWeight: '400',
      lineHeight: '31.4px',
      letterSpacing: '0.15000000596046448px',
      textAlign: 'right',
    },

    '.initials-container-with-data': {
      '.circle-container': {
        backgroundColor: '#BDBDBD',
      },

      '.name-container': {
        '.title': {
          color: palette.common.black,
        },
        '.subtitle': {
          color: '#6E6E6EBF',
        },
      },
    },
  },

  '.summary': {
    gridArea: 'summary',

    p: {
      margin: 0,
      padding: 0,
      fontFamily: 'Roboto',
      fontSize: '20px',
      fontWeight: '400',
      lineHeight: '31.4px',
      letterSpacing: '0.15000000596046448px',
      textAlign: 'left',
    },
  },
}));

export default function ClosingStatementSection({ outcome }: Props) {
  const {
    createdByCompanyUserName,
    createdByCompanyUserJobTitle,
    recommendations,
  } = outcome;

  return (
    <SectionScaffold id="closing-statement" backgroundColor="#F1F1F1">
      <Container>
        <div className="title-container">
          <Column>
            <div className="section-title">CLOSING STATEMENT</div>
            <Gap size={50} />
            <Box alignSelf="flex-end">
              <InitialsContainerWithData
                fullName={createdByCompanyUserName}
                label={createdByCompanyUserName}
                subLabel={createdByCompanyUserJobTitle}
              />
            </Box>
          </Column>
        </div>
        <div
          className="summary"
          dangerouslySetInnerHTML={{ __html: recommendations! }}
        />
      </Container>
    </SectionScaffold>
  );
}

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useAppSelector } from '@store/store';
import { selectDateOnlyFormatShort } from '@store/locale/locale-selector';
import { useMemo } from 'react';
import { i18nDayJs } from '@i18n/index';
import { DefaultTFuncReturn } from 'i18next';
import { optionalLabel } from '@util/string-util';

interface Props {
  label?: string | DefaultTFuncReturn;
  name?: string;
  required?: boolean;
  value?: string;
  error?: string;
  placeholder?: string | DefaultTFuncReturn;
  onChange: (value?: string) => void;
  onBlur?: () => void;
  disablePast?: boolean;
  readonly?: boolean;
}

export default function WCTDateField({
  name,
  label,
  value,
  required,
  onChange,
  error,
  placeholder,
  onBlur,
  disablePast,
  readonly,
}: Props) {
  const format = useAppSelector(selectDateOnlyFormatShort);
  const hasError = !!error;

  const valueAsDate = useMemo(() => {
    if (value == null) {
      return null;
    }

    try {
      const date = i18nDayJs(value).utc();
      if (!date.isValid()) {
        return null;
      }

      return date;
    } catch {
      return null;
    }
  }, [value]);

  return (
    <DatePicker
      label={optionalLabel(label, required)}
      value={valueAsDate}
      onChange={(v) => {
        const value = i18nDayJs(v);
        if (v == null) {
          onChange(undefined);
        } else if (value.isValid()) {
          onChange(value.startOf('day').toISOString() || undefined);
        }
      }}
      format={format}
      disablePast={disablePast}
      readOnly={readonly}
      disabled={readonly}
      slotProps={{
        textField: {
          name: name,
          hiddenLabel: !label,
          error: hasError,
          variant: 'filled',
          helperText: error,
          placeholder: placeholder as string | undefined,
          required: required,
          onBlur: onBlur,
          fullWidth: true,
          sx: ({ palette }) => ({
            maxWidth: 225,
            '& .MuiInputBase-root': {
              borderRadius: '4px',
              boxSizing: 'border-box',
              border: `2px solid ${palette.common.grey50}`,
              minHeight: '56px',
              '&::before': {
                display: 'none',
              },
              '&:hover::before': {
                display: 'none',
              },
              '&.Mui-focused': {
                border: `2px solid ${palette.primary.main}`,
                '&::after': {
                  display: 'none',
                },
              },
              '& input:-webkit-autofill': {
                WebkitBackgroundClip: 'text',
              },
              '& input:-webkit-autofill:hover': {
                WebkitBackgroundClip: 'text',
              },
              '& input:-webkit-autofill:focus': {
                WebkitBackgroundClip: 'text',
              },
              '& input:-webkit-autofill:active': {
                WebkitBackgroundClip: 'text',
              },
            },
          }),
        },
      }}
      className="test"
      sx={{
        padding: 0,
        margin: 0,
      }}
    />
  );
}

import { createSelector } from '@reduxjs/toolkit';
import { RootState, store } from '@store/store';
import { getIncomers, getOutgoers } from 'reactflow';
import {
  NodeCollapseState,
  RcaNode,
  RcaNodeType,
} from '@store/rca-editor/types';
import { RcaEditorState } from '@store/rca-editor/rca-editor-slice';
import { RcaUtil } from '@util/rca-util';
import chainApi from '@api/endpoints/chain/chain.api';
import {
  selectRunReportData,
  selectSelectedRankRow,
} from '@store/reports/reports-selectors';
import { ReportUtil } from '@util/report-util';
import { ReportCellType } from '@api/types/case/case-report/run-report.response';
import {
  getColorForId,
  getLightenedColorForId,
} from '@util/colour-identifiers';

const selectEditorState = (state: RootState): RcaEditorState => state.rcaEditor;

export const selectIsDevMode = (state: RootState) => state.rcaEditor.isDevMode;

export const selectOnDragOriginalParentId = (state: RootState) =>
  state.rcaEditor.onDragOriginalParentId;

export const selectHoverVisibilityNodeId = (state: RootState) =>
  state.rcaEditor.hoverVisibilityNodeId;

export const selectDraggingNodeDescendants = (state: RootState) =>
  state.rcaEditor.onDragDescendantIds;

export const selectStorageGraphNode = (state: RootState) =>
  state.rcaEditor.storageGraphNode;

export const selectIsHighlightMode = (state: RootState) =>
  state.rcaEditor.isHighlightMode;

export const selectIsEditingAnyNode = (state: RootState) =>
  state.rcaEditor.editNodeContentId != null;

export const selectHasFocusedNode = (state: RootState) =>
  state.rcaEditor.selectedNodeId != null;

// Before using this selector, make sure to check if the node is focused

export const selectChainId = (state: RootState) =>
  state.rcaEditor.caseDetail?.chainId;

export const selectCurrentRcaCaseId = (state: RootState) =>
  state.rcaEditor.caseDetail!.caseId;

export const selectCurrentRcaCurrency = (state: RootState) =>
  state.rcaEditor.caseDetail?.currency ?? '£';

export const selectDisplayHealthState = (state: RootState) =>
  state.rcaEditor.displayHealthState ?? false;

export const selectNodePanelEditorSelectedTab = (state: RootState) =>
  state.rcaEditor.nodePanelEditorTab;

export const selectIsDraggingIntoStorageContainer = (state: RootState) =>
  !!state.rcaEditor.isDraggingIntoStorageContainer;

export const selectNodePanelEditorShouldDefaultToCreate = (state: RootState) =>
  state.rcaEditor.defaultToCreate ?? false;

export const selectEditingNodeId = (state: RootState) =>
  state.rcaEditor.editNodeContentId;

export const makeSelectIsEditingContent =
  (nodeId: string) => (state: RootState) =>
    state.rcaEditor.editNodeContentId === nodeId;

export const selectRcaChartMode = (state: RootState) => state.rcaEditor.mode;

export const makeSelectChainItemHealthScore = (
  chainId: number,
  chainItemId: number
) =>
  createSelector(
    [chainApi.endpoints.getChainHealthScores.select(chainId)],
    ({ data }) => {
      return data?.chainItems?.[chainItemId];
    }
  );

export const selectStorageNodes = createSelector(
  [selectEditorState],
  (state) => state.storage
);

export const selectProximityEdge = createSelector(
  [selectEditorState],
  (state) => state.proximityEdge
);

export const selectNodes = createSelector(
  selectEditorState,
  (state) => state.nodes
);

export const selectFocusedNodeIdNullable = createSelector(
  [(state: RootState) => state.rcaEditor.selectedNodeId, selectNodes],
  (id, nodes) => {
    return nodes.find((n) => n.id === id)?.id;
  }
);

// To be used only when known there is a focused node as we are not checking for null
export const selectFocusedNode = createSelector(
  [(state: RootState) => state.rcaEditor.selectedNodeId, selectNodes],
  (id, nodes) => {
    return nodes.find((n) => n.id === id)!;
  }
);

export const makeSelectNodeFromChainItemId = (chainItemId: number) =>
  createSelector([selectNodes], (nodes) => {
    return nodes.find(
      (node) =>
        node.data.chainItemId === chainItemId &&
        node.type !== RcaNodeType.connection
    );
  });

export const selectIsGraphBusy = (state: RootState) =>
  !!state.rcaEditor.globalBusyTracker;

export const makeSelectIsNodeBusy = (nodeId: string) =>
  createSelector(
    [(state: RootState) => state.rcaEditor.nodeBusyTracker[nodeId]],
    (busy) => !!busy
  );

export const makeSelectHighlightAsConnectedNode = (nodeId: string) =>
  createSelector(
    [makeSelectNode(nodeId)],
    (node) => (node?.data?.linkedFromChainItems?.length ?? 0) > 0
  );

export const selectMetaNodes = createSelector(selectNodes, (nodes) =>
  nodes.filter(RcaUtil.isMetaNode)
);

export const selectCauseNodes = createSelector(selectNodes, (nodes) =>
  nodes.filter(RcaUtil.isCauseNode)
);

export const selectEdges = createSelector(
  selectEditorState,
  (state) => state.edges
);

export const makeSelectNode = (nodeId: string) =>
  createSelector([selectNodes], (nodes) => {
    return nodes.find((n) => n.id === nodeId);
  });

export const makeSelectParentNode = (childNodeId: string) =>
  createSelector([selectNodes, selectEdges], (nodes, edges) => {
    const childNode = nodes.find((n) => n.id === childNodeId);
    if (childNode == null) {
      return undefined;
    }

    const incomingNodes = getIncomers(childNode, nodes, edges);
    if (incomingNodes == null || incomingNodes.length === 0) {
      return undefined;
    }

    return incomingNodes[0] as RcaNode;
  });

export const makeSelectGrandfatherNode = (childNodeId: string) =>
  createSelector(
    [selectNodes, selectEdges, makeSelectParentNode(childNodeId)],
    (nodes, edges, immediateParent) => {
      if (immediateParent == null) {
        return undefined;
      }

      const incomingNodes = getIncomers(immediateParent, nodes, edges);
      if (incomingNodes == null || incomingNodes.length === 0) {
        return undefined;
      }

      return incomingNodes[0] as RcaNode;
    }
  );

export const makeSelectNonMetaParentNode = (childNodeId: string) =>
  createSelector(
    [makeSelectParentNode(childNodeId), makeSelectGrandfatherNode(childNodeId)],
    (parent, grandfather) => {
      if (grandfather == null) {
        return parent;
      }
      if (parent == null) {
        return;
      }
      if (RcaUtil.isMetaNode(parent)) {
        return grandfather;
      }
      return parent;
    }
  );

export const makeSelectNonMetaChildNodes = (nodeId: string) =>
  createSelector(
    [makeSelectNode(nodeId), selectNodes, selectEdges],
    (node, nodes, edges) => {
      if (node == null) {
        return [];
      }

      const outGoers = Array.from(
        getOutgoers(node, nodes, edges).values()
      ) as RcaNode[];
      if (outGoers == null || outGoers.length === 0) {
        return [];
      }

      const ret: Array<RcaNode> = [];
      for (const outGoer of outGoers) {
        if (RcaUtil.isMetaNode(outGoer)) {
          const childNodesOfMetaNode = makeSelectNonMetaChildNodes(outGoer.id)(
            store.getState()
          );
          ret.push(...childNodesOfMetaNode);
        } else {
          ret.push(outGoer);
        }
      }

      return ret;
    }
  );

export const makeSelectParentConnectingEdge = (childNodeId: string) =>
  createSelector([selectEdges], (edges) => {
    return edges.filter((e) => e.target === childNodeId)[0];
  });

export const makeSelectChildNodes = (
  parentNodeId: string,
  allowMetaNodes: boolean = false
) =>
  createSelector([selectNodes, selectEdges], (nodes, edges): Array<RcaNode> => {
    const parentNode = nodes.find((n) => n.id === parentNodeId);
    if (parentNode == null) {
      return [];
    }

    const outGoingNodes = getOutgoers(parentNode, nodes, edges);
    if (outGoingNodes == null) {
      return [];
    }

    return (Array.from(outGoingNodes.values()) as RcaNode[]).filter(
      (x) => allowMetaNodes || !RcaUtil.isMetaNode(x)
    );
  });

// Returns immediate children (normal and meta nodes)
// AND meta node children

export type NormalGroupedNodes = {
  type: 'normal';
  node: RcaNode;
};

export type MetaGroupedNodes = {
  type: 'meta';
  node: RcaNode;
  children: RcaNode[];
};

export type GroupedNode = NormalGroupedNodes | MetaGroupedNodes;
export const makeSelectChildNodesWithSubMetaChildNodes = (
  parentNodeId: string
) =>
  createSelector(
    [selectNodes, selectEdges],
    (nodes, edges): Array<GroupedNode> => {
      const parentNode = nodes.find((n) => n.id === parentNodeId);
      if (parentNode == null) {
        return [];
      }

      const outGoingNodes = getOutgoers(parentNode, nodes, edges);
      if (outGoingNodes == null) {
        return [];
      }

      const immediateChildren = Array.from(outGoingNodes.values()) as RcaNode[];

      const ret: Array<GroupedNode> = [];
      for (const child of immediateChildren) {
        if (RcaUtil.isMetaNode(child)) {
          const childNodes = makeSelectChildNodes(
            child.id,
            false
          )(store.getState());

          ret.push({
            type: 'meta',
            node: child,
            children: childNodes,
          });
        } else {
          ret.push({
            type: 'normal',
            node: child,
          });
        }
      }

      return ret;
    }
  );

export const makeSelectAlLAncestors = (nodeId: string) =>
  createSelector([selectEditorState], (_) => {
    const ancestors: Array<RcaNode> = [];

    let currentNodeId = nodeId;
    let parentNode: RcaNode | undefined = undefined;
    do {
      parentNode = makeSelectParentNode(currentNodeId)(store.getState());
      if (parentNode != null) {
        ancestors.push(parentNode);
        currentNodeId = parentNode.id;
      }
    } while (parentNode != null && !parentNode.data.isRoot);

    return ancestors;
  });

export const makeSelectAlLDescendants = (
  parentId: string,
  allowMetaNodes: boolean = false
) =>
  createSelector([selectEditorState], (_) => {
    const descendants: Array<RcaNode> = [];

    const childNodes = makeSelectChildNodes(parentId, true)(store.getState());
    descendants.push(...childNodes);

    for (const childNode of childNodes) {
      const childrenOfChildren = makeSelectAlLDescendants(
        childNode.id,
        true
      )(store.getState());
      descendants.push(...childrenOfChildren);
    }

    return descendants.filter((x) => allowMetaNodes || !RcaUtil.isMetaNode(x));
  });

export const makeSelectChildCount = (
  nodeId: string,
  allowMetaNodes: boolean = false
) =>
  createSelector(
    [makeSelectChildNodes(nodeId, allowMetaNodes)],
    (nodes) => nodes.length
  );

export const makeSelectDescendantCount = (nodeId) =>
  createSelector(
    [makeSelectAlLDescendants(nodeId)],
    (descendants) => descendants.length
  );
export const makeSelectParentNodeType = (nodeId: string) =>
  createSelector(
    [makeSelectParentNode(nodeId)],
    (parentNode) => parentNode?.type
  );

export const makeSelectNodeCollapsedState = (nodeId: string) =>
  createSelector(
    [
      makeSelectNode(nodeId),
      makeSelectNonMetaParentNode(nodeId),
      makeSelectAlLAncestors(nodeId),
      selectHoverVisibilityNodeId,
    ],
    RcaUtil.getNodeCollapsedState
  );

export const makeSelectParentOrChildrenCollapsedState = (nodeId: string) =>
  createSelector(
    [
      makeSelectNonMetaParentNode(nodeId),
      makeSelectNonMetaChildNodes(nodeId),
      makeSelectAlLAncestors(nodeId),
      selectHoverVisibilityNodeId,
    ],
    (parent, children, ancestors, hoverVisibilityNodeId) => {
      for (const child of children) {
        const state = RcaUtil.getNodeCollapsedState(
          child,
          parent,
          ancestors,
          hoverVisibilityNodeId
        );

        if (state !== NodeCollapseState.default) {
          return state;
        }
      }

      return NodeCollapseState.default;
    }
  );

export const makeSelectOutgoingEdges = (nodeId: string) =>
  createSelector([selectEdges], (edges) => {
    return edges.filter((e) => e.source === nodeId);
  });

export const selectDragHolderNode = createSelector([selectNodes], (nodes) => {
  return nodes.find((n) => n.type === 'drag-holder');
});

export const selectRootNode = createSelector([selectNodes], (nodes) => {
  return nodes.find((node) => node.data.isRoot)!;
});

export const selectHighlightedNodeCount = createSelector(
  [selectNodes],
  (nodes) => {
    return nodes.filter((x) => x.data.highlight).length;
  }
);

export const makeSelectIsDescendantOfAndOrMetaNode = (nodeId: string) =>
  createSelector([makeSelectAlLAncestors(nodeId)], (ancestors) => {
    return ancestors.some((node) => node.type === RcaNodeType.andOr);
  });

export const makeSelectConnectionChildNodes = (nodeId: string) =>
  createSelector([makeSelectChildNodes(nodeId, true)], (nodes) => {
    return nodes.filter((x) => x.type === RcaNodeType.connection);
  });

export const makeSelectConnectionNodesTo = (nodeId: string) =>
  createSelector([makeSelectNode(nodeId), selectNodes], (node, nodes) => {
    const chainItemId = node?.data.chainItemId;
    if (chainItemId == null) {
      return [];
    }

    return nodes.filter(
      (x) =>
        x.type === RcaNodeType.connection && x.data.chainItemId === chainItemId
    );
  });

export const makeSelectNodeDisplayProperties = (nodeId: string) =>
  createSelector(
    [
      makeSelectNode(nodeId),
      selectDraggingNodeDescendants,
      selectDisplayHealthState,
      selectRunReportData,
      selectSelectedRankRow,
    ],
    (
      node,
      draggingDescendantIds,
      shouldDisplayHealthState,
      data,
      selectedRankRowId
    ) => {
      const chainItemId = node?.data.chainItemId;
      if (chainItemId == null) {
        return {
          type: 'none',
        };
      }

      const metaData = data?.metaData;
      if (
        data != null &&
        metaData != null &&
        ReportUtil.isCoverageMetaData(metaData)
      ) {
        let badges: {
          rank: number;
          baseColor: string;
          indicatorColor?: string;
          text: string | undefined;
        }[] = [];

        let outlineColor: string | undefined;
        for (let i = 0; i < metaData.length; i++) {
          if (chainItemId in metaData[i].chainItems) {
            const row = data.data[i];
            const rowData = row.cells.find(
              (x) => x.type === ReportCellType.identifier
            );

            const baseColor = getLightenedColorForId(row.clientGeneratedId);
            const indicatorColor = getColorForId(row.clientGeneratedId);
            if (selectedRankRowId === row.clientGeneratedId) {
              outlineColor = indicatorColor;
            }

            badges.push({
              baseColor,
              indicatorColor,
              rank: metaData[i].rank,
              text: rowData?.value,
            });
          }
        }

        if (badges.length > 2) {
          const diff = badges.length - 2;
          badges = [...badges.slice(0, 2)];
          badges.push({
            baseColor: '#EEEEEE',
            rank: -99,
            text: `+${diff}`,
          });
        }

        return {
          type: 'coverage',
          badges,
          outlineColor,
        };
      }

      if (node!.type === RcaNodeType.connection) {
        return {
          type: 'connection',
        };
      }

      if (node!.data.disproved) {
        return {
          type: 'disproved',
          highlightColor: '#FFD0D0',
        };
      }

      if (shouldDisplayHealthState) {
        return {
          type: 'health-score',
          outlineColor: RcaUtil.getHealthScoreColorFromScore(
            node!.data.healthScore ?? -1
          ),
        };
      }

      if (
        draggingDescendantIds != null &&
        draggingDescendantIds.includes(node!.id)
      ) {
        return {
          type: 'default',
          highlightColor: '#a8fda3',
        };
      }

      return {
        type: 'default',
      };
    }
  );

import useLoadMore from '@hooks/use-load-more-hook';
import { useGetUserGroupsQuery } from '@api/endpoints/company-group.api';

export default function useMyGroups() {
  const { skip, take, loadMore, canLoadMore } = useLoadMore();
  const { data: groups, isLoading: loadingGroups } = useGetUserGroupsQuery({
    skip,
    take,
  });

  const isLoading = loadingGroups;

  return {
    isLoading,
    groups,
    loadMore,
    showLoadMoreButton: canLoadMore(groups?.totalCount ?? 0),
  };
}

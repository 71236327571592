import { Button, styled } from '@mui/material';
import Row from '@components/layout-util-components/row';
import FilledBadge from '@components/badges/filled-badge';
import Spacer from '@components/layout-util-components/spacer';
import OutlinedBadge from '@components/badges/outlined-badge';
import { ReactComponent as BoxItemIcon } from '@assets/svgs/box-item.svg';
import {
  ChainItemAction,
  ChainItemActionRow,
  ChainItemActionType,
} from '@api/types/chain/chain-item-health-score/chain-item-action.response';
import { v4 as uuid } from 'uuid';
import { makeSelectNodeFromChainItemId } from '@store/rca-editor/selectors';
import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@store/store';
import { RcaUtil } from '@util/rca-util';
import {
  NodePanelEditorTab,
  selectNodePanelEditorTab,
  setSelectedNode,
} from '@store/rca-editor/rca-editor-slice';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  data: ChainItemActionRow;
}

const Container = styled('div', { name: 'health-score-node-item' })(
  ({ theme: { palette } }) => ({
    marginBottom: 10,
    border: '1px solid #00000040',
    borderRadius: 5,
    background: palette.common.white,
    padding: 10,

    '>.title': {
      paddingLeft: 0,
      paddingRight: 0,
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '17px',
      letterSpacing: '0.15000000596046448px',
      textAlign: 'left',
      color: '#000000DE',
      textDecoration: 'underline',
    },

    '.actions-list': {
      padding: 0,
      margin: 0,
      listStyleType: 'none',

      li: {
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${palette.common.grey90}`,
        borderRadius: 3,
        color: '#000000DE',
        marginTop: 8,
        padding: '8px 10px',
        cursor: 'pointer',

        '>.title': {
          fontFamily: 'Roboto',
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '20px',
          letterSpacing: '0.4000000059604645px',
          textAlign: 'left',
        },
      },
    },
  })
);

export default function HealthScoreNodeItem({
  data: { points, label, actions, chainItemId },
}: Props) {
  const dispatch = useAppDispatch();

  const selectNode = useMemo(
    () => makeSelectNodeFromChainItemId(chainItemId),
    [chainItemId]
  );

  const node = useAppSelector(selectNode);

  const onActionClick = (action: ChainItemAction) => {
    if (node != null) {
      RcaUtil.focusNode(node);
      dispatch(setSelectedNode(node));

      switch (action.chainItemActionTypeKey) {
        case ChainItemActionType.task:
          dispatch(selectNodePanelEditorTab(NodePanelEditorTab.tasks));
          break;
        case ChainItemActionType.evidence:
          dispatch(selectNodePanelEditorTab(NodePanelEditorTab.evidence));
          break;
        case ChainItemActionType.solution:
          dispatch(selectNodePanelEditorTab(NodePanelEditorTab.solutions));
          break;
        case ChainItemActionType.noEndState:
          dispatch(selectNodePanelEditorTab(NodePanelEditorTab.overview));
          break;
        case ChainItemActionType.impact:
          dispatch(selectNodePanelEditorTab(NodePanelEditorTab.impacts));
          break;
      }
    }
  };

  const getLabel = ({ chainItemActionTypeKey, count }: ChainItemAction) => {
    switch (chainItemActionTypeKey) {
      case ChainItemActionType.task:
        if (count === 1) {
          return '1 task is still incomplete';
        }
        return `${count} tasks are still incomplete`;
      case ChainItemActionType.evidence:
        return `No associated ${chainItemActionTypeKey.toLocaleLowerCase()}`;
      case ChainItemActionType.solution:
      case ChainItemActionType.impact:
        return `No associated ${chainItemActionTypeKey.toLocaleLowerCase()}s`;
      case ChainItemActionType.noEndState:
        return 'No associated end state';
      default:
        throw new Error(
          'Invalid chain item action type: ' + chainItemActionTypeKey
        );
    }
  };

  return (
    <Container>
      <Row>
        <FilledBadge>{`${actions.length} outstanding action${
          actions.length > 1 ? 's' : ''
        }`}</FilledBadge>
        <Spacer />
        <OutlinedBadge>-{points} pts</OutlinedBadge>
        <Gap size={20} />
        <BoxItemIcon />
      </Row>
      <Gap size={8} />
      <Button
        variant="text"
        className="title"
        onClick={() => node && RcaUtil.focusNode(node)}
      >
        {label}
      </Button>
      <ul className="actions-list">
        {actions.map((action) => (
          <li key={uuid()} onClick={() => onActionClick(action)}>
            <div className="title">{getLabel(action)}</div>
            <Spacer />
            <FilledBadge>+{action.points} pts</FilledBadge>
            <Gap size={20} />
            <BoxItemIcon />
          </li>
        ))}
      </ul>
    </Container>
  );
}

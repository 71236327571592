import useOutcomeReport from '@components/outcome-report/outcome-report-hook';
import { LoadingIndicator } from '@components/loading-indicator';
import { styled } from '@mui/material';
import CoverSection from '@components/outcome-report/sections/cover-section';
import OverviewSection from '@components/outcome-report/sections/overview-section';
import ExecSummarySection from '@components/outcome-report/sections/exec-summary-section';
import EvidenceSection from '@components/outcome-report/sections/evidence-section';
import TeamMembersSection from '@components/outcome-report/sections/team-members-section';
import ChartSection from '@components/outcome-report/sections/chart-section';
import ImpactsSection from '@components/outcome-report/sections/impacts-section';
import SolutionsSection from '@components/outcome-report/sections/solutions-section';
import InsightsSection from '@components/outcome-report/sections/insights-section';
import ClosingStatementSection from '@components/outcome-report/sections/closing-statement-section';
import { useRef } from 'react';

interface Props {
  caseId: number;
  outcomeId: number;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  scrollSnapType: 'y mandatory',
  overflowY: 'auto',
});

export default function OutcomeReportScaffold({ caseId, outcomeId }: Props) {
  const reportScrollContainer = useRef<HTMLDivElement>(null);

  const state = useOutcomeReport(caseId, outcomeId);
  const { isLoading, result, outcomeDetail, scaleOptionsLookup } = state;

  if (isLoading) {
    return <LoadingIndicator show />;
  }

  const outcome = outcomeDetail!;
  const report = result!;
  const caseDetails = report.case!;

  return (
    <Container ref={reportScrollContainer} id="report-scroll-container">
      <CoverSection caseDetails={caseDetails} />
      <OverviewSection caseDetails={caseDetails} />
      {outcome.showOverview && outcome.executiveSummary ? (
        <ExecSummarySection outcome={outcome} />
      ) : null}
      {outcome.showEvidence && (
        <EvidenceSection
          evidence={report.evidence!}
          evidenceOverview={report.evidenceOverview!}
        />
      )}
      {outcome.showTeam &&
      report.teamMembers != null &&
      report.teamMembers.length > 0 ? (
        <TeamMembersSection teamMembers={report.teamMembers} />
      ) : null}
      {outcome.showAnalysis && (
        <ChartSection
          chainDetail={report.chain!}
          healthScore={report.healthScore}
          themeCount={report.themeCount}
        />
      )}
      {outcome.showImpacts &&
      report.caseImpacts != null &&
      report.caseImpacts.length > 0 ? (
        <ImpactsSection
          impacts={report.caseImpacts}
          optionsLookup={scaleOptionsLookup}
        />
      ) : null}
      {outcome.showSolutions &&
      report.solutionCount != null &&
      report.solutionCount > 0 ? (
        <SolutionsSection
          solutionCount={report.solutionCount}
          solutions={report.solutions!}
          coverage={report.solutionCoverageReport!}
          termReport={report.solutionTermReport!}
          effectivnessReport={report.solutionEffectivenessReport!}
        />
      ) : null}
      {outcome.showInsights && (
        <InsightsSection themeCoverageReport={report.themeCoverageReport!} />
      )}
      {outcome.showRecommendations && outcome.recommendations ? (
        <ClosingStatementSection outcome={outcome} />
      ) : null}
    </Container>
  );
}

import requireAuth from '@components/hoc/with-required-auth-hoc';
import { Grid, styled, Typography } from '@mui/material';
import WCTTextField from '@components/input/text-field';
import PasswordCriteria from '@pages/auth/components/password-criteria';
import { PrimaryButton } from '@components/buttons';
import useProfileUpdatePassword from '@pages/app/profile/settings/profile-update-password-hook';
import SubpageTitle from '@components/menu-items/subpage-title';
import { Divider } from '@components/layout-util-components/divider';
import RefreshCancelButton from '@components/buttons/refresh-cancel-button';
import { Gap } from '@components/layout-util-components/gap';

const Container = styled('form')({
  display: 'flex',
  flexDirection: 'column',
});

function ProfileUpdatePasswordPage() {
  const {
    newPassword,
    confirmNewPassword,
    currentPassword,
    canSubmit,
    submit,
    isSubmitting,
  } = useProfileUpdatePassword();

  return (
    <Container
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}
    >
      <SubpageTitle breadCrumb="Security" title="Update Your Password" />
      <Gap size={32} />
      <Grid container>
        <Grid item xs={6} flexDirection="column">
          <Typography variant="body1">Enter Current Password</Typography>
          <Typography variant="body2">
            Please enter your current password
          </Typography>
        </Grid>
        <Grid item xs={6} flexDirection="column">
          <WCTTextField
            label="Enter Current Password"
            name="current-password"
            value={currentPassword.value}
            error={currentPassword.error}
            onChange={currentPassword.set}
            required
            obscure
          />
        </Grid>
      </Grid>
      <Divider addMargin />
      <Grid container>
        <Grid item xs={6} flexDirection="column">
          <Typography variant="body1">Create New Password</Typography>
          <Typography variant="body2">
            Please create and confirm your new password to update your account
          </Typography>
        </Grid>
        <Grid item xs={6} flexDirection="column">
          <WCTTextField
            label="Create New Password"
            name="new-password"
            value={newPassword.value}
            error={newPassword.error}
            onChange={newPassword.set}
            required
            obscure
          />
          <Gap size={20} />
          <PasswordCriteria password={newPassword.value} />
          <Gap size={20} />

          <WCTTextField
            label="Confirm New Password"
            name="confirm-new-password"
            value={confirmNewPassword.value}
            error={confirmNewPassword.error}
            onChange={confirmNewPassword.set}
            required
            obscure
          />
        </Grid>
      </Grid>
      <Divider addMargin />
      <Grid item container justifyContent="flex-end" alignItems="center">
        <RefreshCancelButton />
        <Gap size={20} />
        <PrimaryButton
          disabled={!canSubmit}
          isBusy={isSubmitting}
          type="submit"
        >
          Update Password
        </PrimaryButton>
      </Grid>
    </Container>
  );
}

export default requireAuth(ProfileUpdatePasswordPage);

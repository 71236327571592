export interface DateFormat {
  long24: string;
  long12: string;
  short24: string;
  short12: string;
  dateOnlyLong: string;
  dateOnlyShort: string;
  timeOnlyShort: string;
}

export const dateFormats: { [id: number]: DateFormat } = {
  1: {
    long24: 'dddd, DD MMMM YYYY HH:mm:ss',
    long12: 'dddd, DD MMMM YYYY hh:mm:ss A',
    short24: 'DD/MM/YYYY HH:mm:ss',
    short12: 'DD/MM/YYYY hh:mm:ss A',
    dateOnlyLong: 'dddd, DD MMMM YYYY',
    dateOnlyShort: 'DD/MM/YYYY',
    timeOnlyShort: 'HH:mm',
  },
};

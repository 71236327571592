import { Box, Grid, TableCell, TableRow, Typography } from '@mui/material';
import { useAppTr } from '@i18n/use-app-tr';
import { ElipsisButton, OutlinedButton } from '@components/buttons';
import requireAuth from '@components/hoc/with-required-auth-hoc';
import { Permission } from '@api/types/permission';
import useManageUsers from '@pages/app/company-profile/manage-users-hook';
import CompanyUserForm, {
  UserFormProps,
} from '@pages/app/company-profile/forms/user-form';
import { WCTTable } from '@components/table';
import FilledBadge from '@components/badges/filled-badge';
import ColorBadge from '@components/badges/color-badge';
import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import UserTableFilters from '@pages/app/company-profile/components/user-table-filters';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import getCompanyUserStatusTr from '@util/tr/user-status-tr';
import { CompanyUserStatus } from '@api/types/company/company-user/company-user-status';
import { useRef, useState } from 'react';
import UserGroupsPopup from '@pages/app/company-profile/components/user-groups-popup';
import DeleteConfirmationModal from '@components/ui-popup/modals/delete-confirmation-modal';
import { usePageAlertVariants } from '@components/alerts';
import { ApiError } from '@api/types/api-error';
import { Gap } from '@components/layout-util-components/gap';

function ManageUsersPage() {
  const { t } = useAppTr('userManagement');
  const { showPopup } = useUiPopup();
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();

  const userGroupRef = useRef<HTMLDivElement>();
  const [displayUserGroupsId, setDisplayUserGroupsId] = useState<number>();

  const {
    canCreateUser,
    isLoading,
    users,
    fetchingUsers,
    userStatusOptions,
    statusFilter,
    sort,
    groupOptions,
    kudosFilter,
    groupsFilter,
    rolesFilter,
    searchFilter,
    roleOptions,
    kudosOptions,
    pagination,
    canDeleteUser,
    canViewUser,
    canEditUser,
    reinviteUser,
    onDeleteUser,
  } = useManageUsers();

  const showCreateUser = () => {
    showPopup(CompanyUserForm);
  };

  const viewUser = (userId: number) => {
    showPopup<UserFormProps, never>(CompanyUserForm, {
      userId,
      isView: true,
    });
  };

  const editUser = (userId: number) => {
    showPopup<UserFormProps, never>(CompanyUserForm, { userId });
  };

  const deleteUser = (userId: number) => {
    showPopup(DeleteConfirmationModal, {
      itemName: 'User',
      onDelete: async () => {
        try {
          await onDeleteUser(userId);
          showSuccessMessage('User deleted successfully');
          return true;
        } catch (error) {
          const { message, errors } = error as ApiError<{ userId: number }>;
          showErrorMessage(errors?.userId ?? message);
          return false;
        }
      },
    });
  };

  const showGroups = (
    userId: number,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    userGroupRef.current = e.currentTarget;
    setDisplayUserGroupsId(userId);
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography variant="h6">
          {t('title', { count: users?.totalCount ?? 0 })}
        </Typography>
        <Gap size={12} />
        {canCreateUser ? (
          <OutlinedButton disabled={isLoading} onClick={showCreateUser}>
            {t('createButtonLabel')}
          </OutlinedButton>
        ) : null}
      </Grid>
      <Gap size={20} />
      <Grid item container alignItems="stretch" direction="column">
        <WCTTable
          isLoading={isLoading || fetchingUsers}
          count={users?.totalCount ?? 0}
          pagination={pagination}
          filters={
            <UserTableFilters
              isLoading={isLoading}
              search={searchFilter}
              role={rolesFilter}
              group={groupsFilter}
              kudos={kudosFilter}
              status={statusFilter}
              roleOptions={roleOptions}
              statusOptions={userStatusOptions}
              groupOptions={groupOptions}
              kudosOptions={kudosOptions}
            />
          }
          headers={[
            { title: t('table.headers.name'), sortProperty: 'name', ...sort },
            {
              title: t('table.headers.role'),
              sortProperty: 'companyRoleName',
              ...sort,
            },
            {
              title: t('table.headers.groups'),
            },
            { title: t('table.headers.rca') },
            {
              title: t('table.headers.kudos'),
            },
            {
              title: t('table.headers.status'),
            },
            { title: <></> },
          ]}
        >
          {users?.model.map(
            ({
              companyUserId,
              name,
              jobTitle,
              companyRoleName,
              firstCompanyGroup,
              otherGroupCount,
              rcaCount,
              kudosLevel,
              kudosPoints,
              status,
            }) => (
              <TableRow key={companyUserId}>
                <TableCell>
                  <InitialsContainerWithData
                    label={name}
                    fullName={name}
                    subLabel={jobTitle}
                  />
                </TableCell>
                <TableCell>{companyRoleName}</TableCell>
                <TableCell>
                  <Box gap={1}>
                    {firstCompanyGroup ? (
                      <FilledBadge>{firstCompanyGroup.label}</FilledBadge>
                    ) : null}
                    {otherGroupCount ? (
                      <FilledBadge
                        onMouseEnter={(e) => showGroups(companyUserId, e)}
                      >
                        +{otherGroupCount}
                      </FilledBadge>
                    ) : null}
                  </Box>
                </TableCell>
                <TableCell>{rcaCount} </TableCell>
                <TableCell>
                  {kudosLevel ? `${kudosLevel} (${kudosPoints})` : null}
                </TableCell>
                <TableCell>
                  <ColorBadge>{getCompanyUserStatusTr(status)}</ColorBadge>
                </TableCell>
                <TableCell>
                  <ElipsisButton
                    actions={[
                      {
                        label: 'Resend Invite',
                        onClick: () => reinviteUser(companyUserId),
                        hasPermission:
                          canEditUser &&
                          status === CompanyUserStatus.inviteExpired,
                      },
                      {
                        label: 'View',
                        hasPermission: canViewUser,
                        onClick: () => viewUser(companyUserId),
                      },
                      {
                        label: 'Edit',
                        hasPermission: canEditUser,
                        onClick: () => editUser(companyUserId),
                      },
                      {
                        label: 'Delete',
                        hasPermission: canDeleteUser,
                        onClick: () => deleteUser(companyUserId),
                      },
                    ]}
                  />
                </TableCell>
              </TableRow>
            )
          )}
        </WCTTable>
      </Grid>
      {displayUserGroupsId ? (
        <UserGroupsPopup
          target={userGroupRef.current!}
          companyUserId={displayUserGroupsId!}
          onClose={() => setDisplayUserGroupsId(undefined)}
        />
      ) : null}
    </>
  );
}

export default requireAuth(ManageUsersPage, {
  permissions: [Permission.manageCompanyUsers],
});

import { styled } from '@mui/material';
import { ReactNode } from 'react';

const OuterContainer = styled('div')<{ widthOverride?: number | string }>(
  ({ widthOverride, theme: { palette } }) => ({
    flex: '0 0 auto',
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: 0,
    background: palette.common.white,
    boxShadow: '0px 0px 104px rgba(0, 0, 0, 0.07)',
    borderRadius: 5,
    width: widthOverride ?? 285,
  })
);

const ContentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 20px',
});

const FooterContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px 20px',
  background: '#FCFCFC',
  borderTop: '1px solid rgba(190, 190, 190, 0.30)',
});

interface Props {
  content: ReactNode;
  footer?: ReactNode;
  onClick?: () => void;
  widthOverride?: number | string;
  background?: string;
}

export default function ContentFooterCard({
  content,
  footer,
  onClick,
  widthOverride,
  background,
}: Props) {
  return (
    <OuterContainer widthOverride={widthOverride}>
      <ContentContainer
        style={{ background }}
        onClick={(e) => {
          onClick?.call(e);
        }}
      >
        {content}
      </ContentContainer>
      {footer ? <FooterContainer>{footer}</FooterContainer> : null}
    </OuterContainer>
  );
}

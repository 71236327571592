export const formatCurrency = (value: string, currencyCode: string) => {
  const numericValue = parseFloat(value.replace(/,/g, '')) || 0;
  return numericValue
    .toLocaleString('en-US', {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 2,
    })
    .replace(/[^0-9.,]+/, '');
};

export const parseCurrency = (formattedValue: string) => {
  return formattedValue.replace(/[^0-9.-]+/g, '');
};

import { Box, Grid, Link, Skeleton, Typography } from '@mui/material';
import WCTTextField from '@components/input/text-field';
import WCTPhoneNumberField from '@components/input/phone-number-field';
import WCTCheckboxField from '@components/input/checkbox-field';
import { PrimaryButton } from '@components/buttons';
import useCreateAccount from '@pages/auth/create-account-hook';
import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useAuthTr } from '@i18n/use-auth-tr';
import HaveAccountLoginLink from '@pages/auth/components/have-account-login-link';
import {
  ButtonSkeleton,
  CheckboxSkeleton,
  InputSkeleton,
} from '@components/skeletons';
import useSearchParamsTyped from '@hooks/router-dom-helper-hooks';
import { PageAlertDisplay } from '@components/alerts';
import { Gap } from '@components/layout-util-components/gap';

type QueryParams = {
  packageId?: string;
};

export default function CreateAccountPage() {
  const { packageId } = useSearchParamsTyped<QueryParams>();

  const {
    dialCodes,
    email,
    firstName,
    surname,
    phone,
    optIn,
    agreeToTerms,
    canSubmit,
    submit,
    isLoading,
    isSubmitting,
  } = useCreateAccount(+(packageId ?? ''));

  const { t } = useAuthTr('createAccount');
  const { t: commonTr } = useAuthTr('common');
  const navigate = useNavigate();

  const onSubmit = async () => {
    const didSubmit = await submit();
    if (didSubmit) {
      navigate('../confirm-email-request', {
        replace: true,
        state: email.value,
      });
    }
  };

  return (
    <Grid container direction="column" justifyContent="center">
      <Typography variant="h4">
        {isLoading ? <Skeleton /> : t('title')}
      </Typography>
      <Gap size={10} />
      <Typography variant="body1">
        {isLoading ? <Skeleton /> : t('message')}
      </Typography>
      <Gap size={10} />
      <PageAlertDisplay />
      <Gap size={10} />
      {isLoading ? (
        <InputSkeleton />
      ) : (
        <WCTTextField
          label={t('form.email')}
          value={email.value}
          error={email.error}
          onChange={email.set}
          required
        />
      )}
      <Gap size={15} />

      <Grid container wrap="nowrap" gap={1}>
        <Grid item xs={6}>
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTTextField
              label={t('form.firstName')}
              value={firstName.value}
              error={firstName.error}
              onChange={firstName.set}
              required
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTTextField
              label={t('form.surname')}
              value={surname.value}
              error={surname.error}
              onChange={surname.set}
              required
            />
          )}
        </Grid>
      </Grid>
      <Gap size={15} />
      {isLoading ? (
        <InputSkeleton />
      ) : (
        <WCTPhoneNumberField
          id="phone-number"
          dialCodes={dialCodes!}
          value={phone.value}
          errors={phone.errors}
          onChange={phone.set}
          label={t('form.phone')}
        />
      )}
      <Gap size={15} />
      {isLoading ? (
        <CheckboxSkeleton />
      ) : (
        <WCTCheckboxField
          label={commonTr('marketingOptIn')}
          value={optIn.value}
          onChange={optIn.set}
        />
      )}
      <Gap size={10} />

      {isLoading ? (
        <CheckboxSkeleton />
      ) : (
        <WCTCheckboxField
          label={
            <Trans t={commonTr} i18nKey="terms">
              {/*TODO(Nick): Implement terms of use*/}I agree to the
              <Link href="#terms" target="_blank">
                Terms of Use
              </Link>
              {/*TODO(Nick): Implement privacy policy*/}
              <Link href="#privacy" target="_blank">
                Privacy Policy
              </Link>
            </Trans>
          }
          value={agreeToTerms.value}
          onChange={agreeToTerms.set}
        />
      )}
      <Gap size={15} />

      {isLoading ? (
        <ButtonSkeleton />
      ) : (
        <PrimaryButton
          onClick={onSubmit}
          disabled={!canSubmit}
          isBusy={isSubmitting}
        >
          {t('buttonLabel')}
        </PrimaryButton>
      )}
      <Gap size={15} />

      {isLoading ? (
        <Box alignSelf="center">
          <Skeleton variant="rectangular">
            <HaveAccountLoginLink />
          </Skeleton>
        </Box>
      ) : (
        <HaveAccountLoginLink />
      )}
    </Grid>
  );
}

import usePermission from '@store/user/permission-hook';
import { Permission } from '@api/types/permission';
import useFilter from '../../../components/table/hooks/use-filter-hook';
import useSortableHeader from '../../../components/table/hooks/use-sortable-header-hook';
import { usePagination } from '@components/table';
import { CompanyUserStatus } from '@api/types/company/company-user/company-user-status';
import { useGetCompanyRolesQuery } from '@api/endpoints/company-role.api';
import { useGetCompanyGroupOptionsQuery } from '@api/endpoints/company/company-company-group.api';
import { useGetKudosPointOptionsQuery } from '@api/endpoints/kudos-point.api';
import {
  useDeleteCompanyUserMutation,
  useGetCompanyUsersListQuery,
  useGetCompanyUserStatsOptionsQuery,
  useResendUserInviteMutation,
} from '@api/endpoints/company/company-company-user.api';
import { ApiError } from '@api/types/api-error';
import { ResendUserInviteRequest } from '@api/types/company/company-user/invite-user.request';
import { usePageAlertVariants } from '@components/alerts';

export default function useManageUsers() {
  const canViewUser = usePermission(Permission.manageCompanyUsers);
  const canEditUser = usePermission(Permission.manageCompanyUsers);
  const canDeleteUser = usePermission(Permission.manageCompanyUsers);

  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();
  const canCreateUser = usePermission(Permission.manageCompanyUsers);

  const { data: roleOptions, isLoading: loadingRoles } =
    useGetCompanyRolesQuery();

  const { data: groupOptions, isLoading: loadingGroups } =
    useGetCompanyGroupOptionsQuery({});

  const { data: kudosOptions, isLoading: loadingKudos } =
    useGetKudosPointOptionsQuery();

  const { data: userStatusOptions, isLoading: loadingUserStatus } =
    useGetCompanyUserStatsOptionsQuery();

  const [resendUserInvitation] = useResendUserInviteMutation();

  const searchFilter = useFilter<string>();

  const rolesFilter = useFilter<Array<number>>();
  const groupsFilter = useFilter<Array<number>>();
  const kudosFilter = useFilter<Array<number>>([]);
  const statusFilter = useFilter<Array<CompanyUserStatus>>();

  const sort = useSortableHeader({ initialProperty: 'name' });
  const pagination = usePagination(25);

  const [deleteUser, { isLoading: isDeletingUser }] =
    useDeleteCompanyUserMutation();

  const {
    data: users,
    isLoading: loadingUsers,
    isFetching: fetchingUsers,
  } = useGetCompanyUsersListQuery({
    searchText: searchFilter.value,
    skip: pagination.skip,
    take: pagination.take,
    sortBy: sort.property,
    sortDirection: sort.direction,
    groups: groupsFilter.arrayValue,
    status: statusFilter.arrayValue,
    kudosLevels: kudosFilter.arrayValue,
    roles: rolesFilter.arrayValue,
  });

  const isLoading =
    loadingRoles ||
    loadingGroups ||
    loadingKudos ||
    loadingUserStatus ||
    loadingUsers;

  const reinviteUser = (companyUserId: number) => {
    return resendUserInvitation({ companyUserId })
      .unwrap()
      .then(() => {
        showSuccessMessage('Invitation resent');
        return true;
      })
      .catch(({ message, errors }: ApiError<ResendUserInviteRequest>) => {
        showErrorMessage(errors?.companyUserId ?? message);

        return false;
      });
  };

  const onDeleteUser = (companyUserId: number) => {
    return deleteUser(companyUserId)
      .unwrap()
      .then(() => {
        showSuccessMessage('User deleted');
        return true;
      })
      .catch(({ message }: ApiError<never>) => {
        showErrorMessage(message);

        return false;
      });
  };

  return {
    canCreateUser,
    isLoading,
    fetchingUsers,
    searchFilter,
    rolesFilter,
    groupsFilter,
    kudosFilter,
    statusFilter,
    sort,
    pagination,
    roleOptions,
    groupOptions,
    kudosOptions,
    userStatusOptions,
    users,
    reinviteUser,
    onDeleteUser,
    canDeleteUser,
    canViewUser,
    canEditUser,
  };
}

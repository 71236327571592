import { Typography } from '@mui/material';
import {
  NavTabContainer,
  NavTabHeader,
} from '@components/menu-items/app/nav-tab-header';
import ContentContainer from '@components/containers/content-container';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { PageAlertDisplay } from '@components/alerts';
import { Gap } from '@components/layout-util-components/gap';

export interface ProfileLayoutTab {
  name: string;
  path: string;
  subPaths?: string[];
  index?: boolean;
}

interface Props {
  baseRoute: string;
  title: string;
  tabs: Array<ProfileLayoutTab>;
}

export default function ProfileLayoutScaffold({
  title,
  baseRoute,
  tabs,
}: Props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isSelected = (path: string) => {
    return isOneSelected([path]);
  };

  const isOneSelected = (paths: string[]): boolean => {
    const cleanPath = pathname.replace(/\/+$/, '');
    return (
      paths.filter(
        (path: string) =>
          cleanPath === `/${baseRoute}/${path}`.replace(/\/+$/, '')
      ).length > 0
    );
  };

  const onClick = (path: string) => () => navigate(`/${baseRoute}/${path}`);

  return (
    <>
      <PageAlertDisplay />
      <ContentContainer maxWidth={920}>
        <Typography
          variant="h1"
          fontSize={24}
          fontWeight={600}
          lineHeight="32px"
        >
          {title}
        </Typography>
        <Gap size={24} />
        <NavTabContainer>
          {tabs.map(({ name, path, index, subPaths }) => (
            <NavTabHeader
              key={path}
              onClick={onClick(index ? '' : path)}
              isSelected={
                (index && (isSelected('') || isSelected('/'))) ||
                isSelected(path) ||
                (subPaths && isOneSelected(subPaths))
              }
            >
              {name}
            </NavTabHeader>
          ))}
        </NavTabContainer>
        <Gap size={50} />
        <Outlet />
      </ContentContainer>
    </>
  );
}

import Column from '@components/layout-util-components/column';
import EditPanelHeader from '@pages/app/rca/tabs/components/editor-panels/edit-panel-header';
import useConnectionsPanel, {
  ConnectionsPanelViewState,
} from '@pages/app/rca/tabs/components/editor-panels/connections-panel-hook';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';
import { ReactComponent as NoConnectionsIcon } from '@assets/svgs/no-connections.svg';
import { Typography } from '@mui/material';
import { SortByFilter, WCTTableFilters } from '@components/table';
import ConnectionOption from '@pages/app/rca/tabs/components/editor-panels/components/connections-panel/connection-option';
import Spacer from '@components/layout-util-components/spacer';
import ActionsRow from '@components/layout-util-components/actions-row';
import {
  ElipsisButton,
  FlatButton,
  OutlinedButton,
  PrimaryButton,
} from '@components/buttons';

import { ReactComponent as SelectedIcon } from '@assets/svgs/check.svg';
import { ReactComponent as ConnectionIcon } from '@assets/svgs/connection.svg';
import Row from '@components/layout-util-components/row';
import { LoadingIndicator } from '@components/loading-indicator';
import { Gap } from '@components/layout-util-components/gap';

export default function ConnectionsPanel() {
  const {
    toggleSelected,
    isSelected,
    selectableNodes,
    viewState,
    beginSelecting,
    search,
    sortBy,
    sortByOptions,
    confirmSelection,
    cancelSelection,
    selectedNodes,
    canContinue,
    isSubmitting,
    removeSelectedNode,
    isBusy,
    isLoading,
  } = useConnectionsPanel();

  return (
    <Column>
      <EditPanelHeader
        title="Create Connections"
        count={selectedNodes.length}
        end={
          viewState === ConnectionsPanelViewState.listSelected ? (
            <Row contentAtEnd>
              <LoadingIndicator show={isBusy} />
              <Gap size={8} />
              <OutlinedButton onClick={beginSelecting}>
                Edit Connections
              </OutlinedButton>
            </Row>
          ) : null
        }
      />
      <Gap size={24} />
      {isLoading ? (
        <LoadingIndicator show />
      ) : viewState === ConnectionsPanelViewState.empty ? (
        <OnboardingEntryContainer
          icon={<NoConnectionsIcon />}
          title="There are currently no connected causes added"
          message="You haven’t currently created any connections from this cause box"
          createMessage="Add Connection"
          onCreateClicked={beginSelecting}
          dark
          compact
        />
      ) : viewState === ConnectionsPanelViewState.select ? (
        <Column>
          <Typography variant="body1">
            Please select the cause boxes below that should be added as causes
            of this cause box
          </Typography>
          <Gap size={10} />
          <WCTTableFilters
            search={search.value}
            onSearch={search.set}
            rightOfSearchContent={
              <SortByFilter
                value={sortBy.value}
                onChange={sortBy.set}
                options={sortByOptions}
              />
            }
          />
          <Gap size={10} />
          {selectableNodes.map((node) => (
            <ConnectionOption
              key={node.id}
              title={node.data?.label}
              chainItemId={node.data?.chainItemId}
              isSelected={isSelected(node)}
              trailing={
                <FlatButton
                  onClick={() => toggleSelected(node)}
                  dark={isSelected(node)}
                >
                  {isSelected(node) ? <SelectedIcon /> : <ConnectionIcon />}
                </FlatButton>
              }
            />
          ))}

          <Spacer />
          <ActionsRow>
            <OutlinedButton onClick={cancelSelection}>Cancel</OutlinedButton>
            <PrimaryButton
              onClick={confirmSelection}
              disabled={!canContinue}
              isBusy={isSubmitting}
            >
              Continue
            </PrimaryButton>
          </ActionsRow>
        </Column>
      ) : (
        <Column>
          {selectedNodes.map((node) => (
            <ConnectionOption
              key={node.id}
              title={node.data?.label}
              chainItemId={node.data?.chainItemId}
              trailing={
                <ElipsisButton
                  actions={[
                    {
                      label: 'Remove',
                      onClick: () => removeSelectedNode(node),
                    },
                  ]}
                />
              }
            />
          ))}
        </Column>
      )}
    </Column>
  );
}

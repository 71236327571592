import Column from '@components/layout-util-components/column';
import EditPanelHeader from '@pages/app/rca/tabs/components/editor-panels/edit-panel-header';
import useSolutionsPanel, {
  SolutionsPanelViewState,
} from '@pages/app/rca/tabs/components/editor-panels/solutions-panel-hook';
import { LoadingIndicator } from '@components/loading-indicator';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';
import { ReactComponent as EmptyIcon } from '@assets/svgs/no-solutions-icon.svg';
import SolutionsOptionsView from '@pages/app/rca/tabs/components/editor-panels/components/solutions-panel/solutions-options-view';
import CreateSolutionFormView from '@pages/app/rca/tabs/components/editor-panels/components/solutions-panel/create-solution-form-view';
import { OutlinedButton } from '@components/buttons';
import CreateFromCaseSolutionFormView from '@pages/app/rca/tabs/components/editor-panels/components/solutions-panel/create-from-case-solution-form-view';
import SolutionsListView from '@pages/app/rca/tabs/components/editor-panels/components/solutions-panel/solutions-list-view';
import { Gap } from '@components/layout-util-components/gap';

export default function SolutionsPanel() {
  const state = useSolutionsPanel();
  const { solutions, viewState, startCreateFlow, isLoading, isEditing } = state;

  const isInForm =
    viewState === SolutionsPanelViewState.create ||
    viewState === SolutionsPanelViewState.createFromCaseSolution;

  return (
    <Column>
      <EditPanelHeader
        title={
          !isInForm ? 'Solutions' : isEditing ? 'Edit Solution' : 'Add Solution'
        }
        count={!isInForm ? solutions?.totalCount : undefined}
        end={
          viewState === SolutionsPanelViewState.view ? (
            <OutlinedButton onClick={startCreateFlow}>
              Add Solution
            </OutlinedButton>
          ) : null
        }
      />
      <Gap size={20} />
      {isLoading ? (
        <LoadingIndicator show />
      ) : viewState === SolutionsPanelViewState.empty ? (
        <OnboardingEntryContainer
          icon={<EmptyIcon />}
          title="There are no solutions currently assigned to this cause box"
          message="You haven’t currently linked any solutions to this cause box, please click below to add or link solutions"
          createMessage="Add Solution"
          onCreateClicked={startCreateFlow}
          compact
          dark
        />
      ) : viewState === SolutionsPanelViewState.createOptions ? (
        <SolutionsOptionsView state={state} />
      ) : viewState === SolutionsPanelViewState.create ? (
        <CreateSolutionFormView state={state} />
      ) : viewState === SolutionsPanelViewState.createFromCaseSolution ||
        viewState === SolutionsPanelViewState.edit ? (
        <CreateFromCaseSolutionFormView state={state} />
      ) : viewState === SolutionsPanelViewState.view ? (
        <SolutionsListView state={state} />
      ) : null}
    </Column>
  );
}

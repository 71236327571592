import { CaseSolutionItemResponse } from '@api/types/case/case-solution/case-solution-item.response';
import { Typography } from '@mui/material';
import Column from '@components/layout-util-components/column';
import { isNullOrEmpty } from '@util/string-util';
import { ReactNode } from 'react';
import Spacer from '@components/layout-util-components/spacer';
import AssociatedCauseBoxBadge from '@components/badges/associated-cause-box-badge';
import FlatBadge from '@components/badges/flat-badge';
import { CaseSolutionResource } from '@api/types/case/case-solution/case-solution.resource';
import { BorderedContainer } from '@components/containers/bordered-container';
import Row from '@components/layout-util-components/row';
import { currencyFormatter } from '@i18n/formatters';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  solution: CaseSolutionItemResponse | CaseSolutionResource;
  trailing?: ReactNode;
  displayCost?: boolean;
  currency?: string;
  hideAssociatedCauseBoxBadge?: boolean;
}

export default function CaseSolutionItem({
  solution: { name, description, chainItems, cost, themes },
  trailing,
  displayCost,
  currency,
  hideAssociatedCauseBoxBadge,
}: Props) {
  return (
    <BorderedContainer>
      <Column>
        <Row sx={{ gap: 0.5 }}>
          {themes?.map(({ id, label }) => (
            <FlatBadge small key={id}>
              {label}
            </FlatBadge>
          ))}
        </Row>
        <Gap size={8} />
        <Typography variant="body2">{name}</Typography>
        {!isNullOrEmpty(description) ? (
          <>
            <Gap size={8} />
            <Typography variant="caption">{description}</Typography>
          </>
        ) : null}
        {chainItems && !hideAssociatedCauseBoxBadge ? (
          <>
            <Gap size={8} />
            <AssociatedCauseBoxBadge count={chainItems.length} />
          </>
        ) : null}
        {displayCost && !!cost ? (
          <>
            <Gap size={8} />
            <FlatBadge className="cost">
              <span>
                Cost:
                <b>
                  {currencyFormatter(cost, {
                    decimalPlaces: 2,
                    currency: currency || '',
                  })}
                </b>
              </span>
            </FlatBadge>
          </>
        ) : null}
      </Column>
      {trailing ? (
        <>
          <Gap size={15} />
          <Spacer />
          {trailing}
        </>
      ) : null}
    </BorderedContainer>
  );
}

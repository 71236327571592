import { api } from '@api/api';
import { UserStorePreference } from '@api/types/user-store/user-store-preference';

const userStoreApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserStorePreferences: build.query<UserStorePreference[], void>({
      query: () => ({
        url: 'userStore',
        method: 'GET',
      }),
    }),
    setUserStorePreference: build.mutation<
      UserStorePreference,
      UserStorePreference
    >({
      query: (body) => ({
        url: 'userStore',
        method: 'POST',
        body,
      }),
      async onQueryStarted(newPreference, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            userStoreApi.util.updateQueryData(
              'getUserStorePreferences',
              undefined,
              (draft) => {
                const indexOfPreference = draft.findIndex(
                  (x) => x.storeKey === newPreference.storeKey
                );
                if (indexOfPreference !== -1) {
                  draft[indexOfPreference] = { ...newPreference };
                } else {
                  draft.push(newPreference);
                }
              }
            )
          );
        } catch {}
      },
    }),
  }),
});

export const {
  useGetUserStorePreferencesQuery,
  useSetUserStorePreferenceMutation,
} = userStoreApi;

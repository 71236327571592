import { styled } from '@mui/material';

interface Props {
  filled?: boolean;
  maxWidth?: number;
  padding?: string;
}

const ContentContainer = styled('div')<Props>(
  ({ theme, filled, maxWidth, padding }) => ({
    display: 'flex',
    width: '100%',
    margin: '0 auto',
    maxWidth,
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: padding || `${theme.spacing(3)} ${theme.spacing(6)}`,
    ...(filled && {
      backgroundColor: theme.palette.common.grey40,
    }),
  })
);

export default ContentContainer;

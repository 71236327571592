import { AppDispatch, RootState } from '@store/store';
import {
  addSnapshot,
  commitSnapshotUptoId,
  hydrateSnapshot,
  revertToLastCommittedSnapshot,
  setInitialSnapshot,
} from '@store/rca-editor-snapshot/rca-editor-snapshot-slice';
import { selectChainId } from '@store/rca-editor/selectors';
import chainApi from '@api/endpoints/chain/chain.api';
import {
  consumeStorageChainIds,
  decrementGlobalBusyTracker,
  incrementGlobalBusyTracker,
  resetEditorToSnapshot,
} from '@store/rca-editor/rca-editor-slice';
import Semaphore from 'semaphore';
import { ApiError, isApiError } from '@api/types/api-error';
import { setAlert } from '@store/ui/ui-slice';

const sp = Semaphore(1);

export const captureInitialEditorSnapshot =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const { rcaEditor } = getState();
    dispatch(setInitialSnapshot(rcaEditor));
  };

export const saveGraphState =
  (asyncAction?: () => Promise<unknown>) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const chainId = selectChainId(getState())!;

    // Capture the editor state before the async action
    dispatch(addSnapshot(getState().rcaEditor));
    const snapshotId = getState().rcaEditorSnapshot.currentSnapshot!.id;

    return new Promise((resolve, reject) => {
      sp.take(async () => {
        try {
          // If the snapshot doesn't exist, it means a previous request failed and the chain
          // will have been in a broken state and reverted. Hence, reject immediately.
          const snapshot = getState().rcaEditorSnapshot.snapshots.find(
            (x) => x.id === snapshotId
          );
          if (snapshot == null) {
            console.log(
              'Unable to save due to a previous graph being reverted'
            );
            reject({
              message: 'Unable to save due to a previous graph being reverted',
            } as ApiError<never>);
            return;
          }

          console.log('Saving graph state', snapshot.id);
          dispatch(incrementGlobalBusyTracker());

          if (asyncAction != null) {
            await asyncAction();
          }

          const chainDetail = await dispatch(
            chainApi.endpoints.updateChain.initiate({
              chainId,
              nodes: snapshot.data.nodes,
              edges: snapshot.data.edges,
              moveToStorageChainItemIds:
                snapshot.data.storageChainItemIdsToConsume,
            })
          ).unwrap();

          dispatch(
            consumeStorageChainIds(snapshot.data.storageChainItemIdsToConsume)
          );

          dispatch(hydrateSnapshot({ snapshotId, data: chainDetail }));
          dispatch(commitSnapshotUptoId(snapshotId));
          resolve(undefined);
        } catch (e) {
          if (isApiError<any>(e)) {
            const { message, errors } = e;
            let errorMessage = message;
            if (errors) {
              errorMessage += ': ' + Object.values(errors).join(', ');
            }
            dispatch(setAlert({ message: errorMessage, type: 'error' }));
          }
          console.log(e);

          dispatch(revertToLastCommittedSnapshot());

          // Should always be a snapshot, earliest is the very first one when we loaded the chain
          const previousSnapshot =
            getState().rcaEditorSnapshot.currentSnapshot!;
          dispatch(resetEditorToSnapshot(previousSnapshot.data));

          reject(e);
        } finally {
          dispatch(decrementGlobalBusyTracker());

          sp.leave();
        }
      });
    });
  };

import { useMemo } from 'react';
import { getInitials } from '@util/name-util';
import { styled } from '@mui/material';
import CircleContainer from '@components/containers/circle-container';
import LabelValueDisplay from '@components/data/label-value-display';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  name: string;
  jobTitle?: string;
}

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export default function UserNameCell({ name, jobTitle }: Props) {
  const initials = useMemo(() => getInitials(name), [name]);

  return (
    <Container>
      <CircleContainer size={40} light>
        {initials}
      </CircleContainer>
      <Gap size={15} />
      <LabelValueDisplay label={name} value={jobTitle} />
    </Container>
  );
}

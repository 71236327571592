import CompleteTick from '@assets/svgs/circle-tick.svg';
import IncompleteTick from '@assets/svgs/circle.svg';
import { styled, Typography } from '@mui/material';

interface Props {
  label: string;
  isComplete: () => boolean;
}

const StyledRow = styled('div')({
  display: 'flex',
  marginTop: 8,
  gap: 12,
  '&>img': {
    width: 20,
    height: 20,
  },
});

export default function PasswordCriteriaItem({ isComplete, label }: Props) {
  return (
    <StyledRow>
      {isComplete() ? (
        <img src={CompleteTick} alt="complete" />
      ) : (
        <img src={IncompleteTick} alt="Not complete" />
      )}
      <Typography variant="body2">{label}</Typography>
    </StyledRow>
  );
}

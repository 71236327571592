import { styled } from '@mui/material';
import FilledBadge from '@components/badges/filled-badge';
import Row from '@components/layout-util-components/row';
import IdColorIndicator from '@pages/app/rca/tabs/reports/components/id-color-indicator';
import { shouldShrinkReportView } from '@store/reports/reports-selectors';
import { useAppSelector } from '@store/store';
import { v4 as uuid } from 'uuid';
import {
  ReportCell,
  ReportCellType,
  ReportRow,
} from '@api/types/case/case-report/run-report.response';
import { Fragment, useMemo } from 'react';
import { CoverageReportMetaData } from '@pages/app/rca/tabs/reports/views/coverage-report-view';
import { ReportUtil } from '@util/report-util';
import { numberFromString } from '@util/string-util';
import {
  HeaderItem,
  useSortableHeader,
  WCTTableHeaders,
} from '@components/table';
import { SortDirection } from '@api/types/sort-direction';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  headers: string[];
  rows: ReportRow[];
  metaData: CoverageReportMetaData[];
  selectedRow?: number;
  onSelect?: (index: number) => void;
}

const Table = styled('table')<{ isShrunk: boolean }>(
  ({ isShrunk, theme: { palette } }) => ({
    borderCollapse: 'collapse',
    th: {
      border: 0,
      backgroundColor: '#E9E9E9',
      padding: '14px 12px',
      margin: 0,
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '16px',
      letterSpacing: '0px',
    },
    td: {
      backgroundColor: 'white',
      padding: 12,
      fontFamily: 'Roboto',
      fontSize: '15px',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0px',
      textAlign: 'left',
      borderTop: `1px solid ${palette.common.grey20}`,

      ...(isShrunk && {
        borderTop: 'none',

        '&:first-child': {
          borderTopLeftRadius: 6,
          borderBottomLeftRadius: 6,
        },

        '&:last-child': {
          borderTopRightRadius: 6,
          borderBottomRightRadius: 6,
        },
      }),
    },
  })
);

export default function CoverageLeagueTable({
  headers,
  rows,
  selectedRow,
  onSelect,
  metaData,
}: Props) {
  const sort = useSortableHeader({
    allowNoSort: false,
  });
  const isShrunk = useAppSelector(shouldShrinkReportView);

  const sortedRows = useMemo(() => {
    const sortIdx = numberFromString(sort.value?.id);
    if (sortIdx == null) return rows;
    return [...rows].sort((a, b) => {
      const aVal = a.cells[sortIdx].value;
      const bVal = b.cells[sortIdx].value;

      // remove the % (if it exists) sign from the string and convert to number
      const aNum = numberFromString(aVal);
      const bNum = numberFromString(bVal);

      if (aNum != null && bNum != null) {
        return sort.direction === SortDirection.asc ? aNum - bNum : bNum - aNum;
      }

      return sort.direction === SortDirection.asc
        ? aVal.localeCompare(bVal)
        : bVal.localeCompare(aVal);
    });
  }, [rows, sort.direction, sort.value]);

  const displayToggle = onSelect != null;

  const renderCell = (cell: ReportCell, row: ReportRow) => {
    switch (cell.type) {
      case ReportCellType.empty:
        if (isShrunk) return <td style={{ width: 0 }}></td>;
        return (
          <td style={{ width: 32 }}>
            <Row>
              {displayToggle && (
                <>
                  <input
                    type="checkbox"
                    checked={row.clientGeneratedId === selectedRow}
                    onClick={() => onSelect(row.clientGeneratedId)}
                  />
                  <Gap size={10} />
                </>
              )}
              <span>
                {ReportUtil.getRank(row.clientGeneratedId, rows, metaData)}
              </span>
            </Row>
          </td>
        );
      case ReportCellType.identifier:
        return (
          <td>
            <Row>
              <IdColorIndicator id={row.clientGeneratedId} />
              <Gap size={10} />
              <span>{cell.value}</span>
            </Row>
          </td>
        );
      case ReportCellType.badge:
        if (isShrunk) return <td style={{ width: 0 }}></td>;
        return (
          <td style={{ width: 200 }}>
            <FilledBadge>{cell.value}</FilledBadge>
          </td>
        );
      case ReportCellType.percent:
        const value = numberFromString(cell.value);
        return (
          <td style={{ width: 128 }}>
            <span>{value?.toFixed(1)}%</span>
          </td>
        );
      case ReportCellType.string:
      default:
        if (isShrunk) return <td style={{ width: 0 }}></td>;
        return (
          <td style={{ width: 128 }}>
            <span>{cell.value}</span>
          </td>
        );
    }
  };

  return (
    <Table isShrunk={isShrunk}>
      {!isShrunk && (
        <WCTTableHeaders
          items={headers.map(
            (value, index): HeaderItem => ({
              title: value,
              ...(index > 0 && {
                sortProperty: `${index}`,
                ...sort,
              }),
            })
          )}
        />
      )}
      <tbody>
        {sortedRows.map((row) => (
          <tr
            key={row.clientGeneratedId}
            style={{
              display:
                isShrunk && row.clientGeneratedId !== selectedRow
                  ? 'none'
                  : undefined,
            }}
          >
            {row.cells.map((cell) => (
              <Fragment key={uuid()}>{renderCell(cell, row)}</Fragment>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

import requireAuth from '@components/hoc/with-required-auth-hoc';
import withEditorScaffold from '@pages/app/rca/tabs/components/rca-editor-scaffold';
import ContentContainer from '@components/containers/content-container';
import EvidenceIndexPage from '@pages/app/evidence/evidence-index-page';

function RcaEditorEvidence() {
  return (
    <ContentContainer>
      <EvidenceIndexPage canAddEvidence />
    </ContentContainer>
  );
}

export default requireAuth(withEditorScaffold(RcaEditorEvidence));

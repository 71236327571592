import { api } from '@api/api';
import {
  InviteUserErrors,
  InviteUserRequest,
  ResendUserInviteRequest,
} from '@api/types/company/company-user/invite-user.request';
import { makeError } from '@api/types/api-error';
import { CompanyUserListRequest } from '@api/types/company/company-user/company-user-list.request';
import { CompanyUserListItemResponse } from '@api/types/company/company-user/company-user-list-item.response';
import { CompanyUserStatus } from '@api/types/company/company-user/company-user-status';
import { Option } from '@api/types/option';
import { PaginatedResult } from '@api/types/paginated-result';
import { CompanyUserResource } from '@api/types/company-user/company-user.resource';
import { UpdateCompanyUserRequest } from '@api/types/company/company-user/update-company-user.request';

const companyUserApi = api.injectEndpoints({
  endpoints: (build) => ({
    inviteUser: build.mutation<void, InviteUserRequest>({
      query: (body) => ({
        url: 'company/companyUser/invite',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CompanyUser'],
      transformErrorResponse: (error) => makeError<InviteUserErrors>(error),
    }),
    getCompanyUsersList: build.query<
      PaginatedResult<CompanyUserListItemResponse>,
      CompanyUserListRequest
    >({
      query: (params) => ({
        url: 'company/companyUser',
        method: 'GET',
        params,
      }),
      providesTags: (response) =>
        response
          ? [
              ...response.model.map((x) => ({
                id: x.companyUserId,
                type: 'CompanyUser' as const,
              })),
              'CompanyUser',
            ]
          : ['CompanyUser'],
    }),
    getCompanyUserStatsOptions: build.query<Option<CompanyUserStatus>[], void>({
      query: () => ({
        url: 'company/companyUser/status/options',
        method: 'GET',
      }),
    }),
    resendUserInvite: build.mutation<void, ResendUserInviteRequest>({
      query: (body) => ({
        url: 'company/companyUser/invite/resend',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CompanyUser'],
      transformErrorResponse: (error) =>
        makeError<ResendUserInviteRequest>(error),
    }),
    getCompanyUserDetail: build.query<CompanyUserResource, number>({
      query: (companyUserId) => ({
        url: `company/companyUser/${companyUserId}`,
        method: 'GET',
      }),
      providesTags: (_, __, companyUserId) => [
        { id: companyUserId, type: 'CompanyUser' as const },
      ],
    }),
    updateCompanyUser: build.mutation<void, UpdateCompanyUserRequest>({
      query: (body) => ({
        url: 'company/companyUser',
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, error, req) =>
        !error ? [{ id: req.companyUserId, type: 'CompanyUser' as const }] : [],
      transformErrorResponse: (err) => makeError<UpdateCompanyUserRequest>(err),
    }),
    deleteCompanyUser: build.mutation<void, number>({
      query: (userId) => ({
        url: `company/companyUser/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, error, userId) =>
        !error
          ? [{ id: userId, type: 'CompanyUser' as const }, 'CompanyUser']
          : [],
      transformErrorResponse: (err) => makeError<never>(err),
    }),
  }),
});

export const {
  useInviteUserMutation,
  useGetCompanyUsersListQuery,
  useGetCompanyUserStatsOptionsQuery,
  useResendUserInviteMutation,
  useGetCompanyUserDetailQuery,
  useUpdateCompanyUserMutation,
  useDeleteCompanyUserMutation,
} = companyUserApi;

import { api } from '@api/api';
import { PaginatedResult } from '@api/types/paginated-result';
import { TaskDetailListItemResponse } from '@api/types/task-detail/task-detail-list-item.response';
import { TaskDetailListRequest } from '@api/types/task-detail/task-detail-list.request';
import { TaskDetailResource } from '@api/types/task-detail/task-detail.resource';
import { Option } from '@api/types/option';
import { CreateTaskDetailRequest } from '@api/types/task-detail/create-task-detail.request';
import { makeError } from '@api/types/api-error';
import { UpdateTaskDetailRequest } from '@api/types/task-detail/update-task-detail.request';
import { TaskDetailStats } from '@api/types/task-detail/task-detail-stats';
import { TaskDetailAttachmentResource } from '@api/types/task-detail/task-detail-attachment.resource';
import { FileUploadRequest, FileUploadResponse } from '@api/types/file-types';
import { CompleteTaskRequest } from '@api/types/task-detail/complete-task.request';

const taskDetailApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTasksForUser: build.query<
      PaginatedResult<TaskDetailListItemResponse, TaskDetailStats>,
      TaskDetailListRequest
    >({
      query: (params) => ({ url: 'taskdetail', method: 'GET', params }),
      providesTags: (response) =>
        response
          ? [
              ...response!.model.map((data) => ({
                id: data.taskDetailId,
                type: 'TaskDetail' as const,
              })),
              'TaskDetail',
            ]
          : ['TaskDetail'],
    }),
    getTaskDetailForUser: build.query<TaskDetailResource, number>({
      query: (id) => ({
        url: `taskDetail/${id}`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [{ id: result.taskDetailId, type: 'TaskDetail' as const }]
          : [],
    }),
    createTaskForUser: build.mutation<
      TaskDetailResource,
      CreateTaskDetailRequest
    >({
      query: (body) => ({
        url: 'taskDetail',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<CreateTaskDetailRequest>(error),
      invalidatesTags: ['TaskDetail', 'CaseTotals'],
    }),
    updateTaskForUser: build.mutation<
      TaskDetailResource,
      UpdateTaskDetailRequest
    >({
      query: (body) => ({
        url: 'taskDetail',
        method: 'PUT',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<CreateTaskDetailRequest>(error),
      invalidatesTags: (_, __, arg) => [
        { id: arg.taskDetailID, type: 'TaskDetail' as const },
      ],
    }),
    completeTaskForUser: build.mutation<void, CompleteTaskRequest>({
      query: (body) => ({
        url: 'taskDetail/complete',
        method: 'PUT',
        body,
      }),
      transformErrorResponse: (error) => makeError(error),
      invalidatesTags: (_, __, arg) => [
        { id: arg.taskDetailId, type: 'TaskDetail' as const },
        'CaseTotals',
      ],
    }),
    getTaskPriorityOptions: build.query<Option<string>[], void>({
      query: () => ({
        url: 'taskDetail/priority/options',
        method: 'GET',
      }),
    }),
    getTaskStatusOptions: build.query<Option<string>[], void>({
      query: () => ({
        url: 'taskDetail/status/options',
        method: 'GET',
      }),
    }),
    uploadTaskDetailAttachment: build.mutation<
      FileUploadResponse<TaskDetailAttachmentResource>,
      FileUploadRequest
    >({
      query: (request) => {
        const { files } = request;
        const formData = new FormData();
        files.forEach((file) => {
          formData.append('files', file);
        });

        return {
          url: 'taskDetail/attachment',
          method: 'POST',
          body: formData,
        };
      },
      transformErrorResponse: (error) => makeError<FileUploadRequest>(error),
    }),
  }),
});

export const {
  useGetTasksForUserQuery,
  useCreateTaskForUserMutation,
  useGetTaskDetailForUserQuery,
  useGetTaskPriorityOptionsQuery,
  useGetTaskStatusOptionsQuery,
  useUpdateTaskForUserMutation,
  useCompleteTaskForUserMutation,
  useUploadTaskDetailAttachmentMutation,
} = taskDetailApi;

import useUserPreferences from '@store/user/user-preferences-hook';
import { useMemo } from 'react';
import { useGetUserProfileQuery } from '@api/endpoints/user.api';

// Don't use this hook unless the user is logged in.
export default function useSharedState() {
  const { data: profile, isLoading: loadingProfile } = useGetUserProfileQuery();
  const userPreferences = useUserPreferences();

  const isLoading = loadingProfile || userPreferences.isLoading;

  return useMemo(
    () => ({
      userPreferences,
      profile,
      isLoading,
    }),
    [isLoading, profile, userPreferences]
  );
}

import { FormHelperText, styled, Typography } from '@mui/material';
import { Option } from '@api/types/option';
import { DefaultTFuncReturn } from 'i18next';
import { useDebouncedCallback } from 'use-debounce';
import { Gap } from '@components/layout-util-components/gap';

interface Props<T> {
  name: string;
  label?: string | DefaultTFuncReturn;
  error?: string;
  options: Array<Option<T>>;
  value: T;
  onChange: (value: T) => void;
  compact?: boolean;
}

const StyledContainer = styled('div')<{ compact?: boolean }>(({ compact }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: compact ? 42 : 58,
  alignItems: 'stretch',
  gap: compact ? 10 : 30,
}));

const StyledOption = styled('div')<{
  isSelected: boolean;
  compact?: boolean;
}>(({ isSelected, compact }) => ({
  display: 'inline-flex',
  minWidth: compact ? 176 : 210,
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid #00000040',
  borderRadius: 4,
  backgroundColor: 'white',
  padding: compact ? 8 : 16,
  cursor: 'pointer',
  ...(isSelected && {
    border: '3px solid rgba(0, 0, 0, 0.75)',
    borderRadius: 8,
    cursor: 'default',
  }),

  'input[type="radio"]': {
    appearance: 'none',
    margin: 0,
  },
}));

export default function BinarySelectField<T>({
  name,
  options,
  label,
  error,
  onChange,
  value,
  compact,
}: Props<T>) {
  if (options.length !== 2) {
    throw new Error('binary select options must always be a length of 2');
  }

  const debounceOnChange = useDebouncedCallback(onChange);

  return (
    <>
      {label ? (
        <>
          <Typography>{label}</Typography>
          <Gap size={15} />
        </>
      ) : null}
      <StyledContainer compact={compact}>
        {options.map(({ id, label }, index) => {
          const elementId = `${name}-${id}-option`;
          const isSelected = id === value;

          return (
            <StyledOption
              key={`${id}`}
              isSelected={isSelected}
              onClick={() => debounceOnChange(id)}
              compact={compact}
            >
              <label htmlFor={elementId}>
                <input
                  type="radio"
                  id={elementId}
                  name={name}
                  value={`${label}`}
                />
                {label}
              </label>
            </StyledOption>
          );
        })}
        {error ? <FormHelperText error>{error}</FormHelperText> : null}
      </StyledContainer>
    </>
  );
}

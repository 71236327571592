import { Box, Chip, styled, Typography } from '@mui/material';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dateOnlyShortFormatter } from '@i18n/formatters';
import { faPen } from '@fortawesome/pro-light-svg-icons';

interface Props {
  title: string;
  message: string;
  modifiedTime?: Date;
  onClick: () => void;
}

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 20,
  marginTop: 20,
  padding: '15px 20px',
  backgroundColor: theme.palette.common.white,
  borderRadius: 7,
  cursor: 'pointer',
  boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
}));

export default function SettingsOptionContainer({
  title,
  message,
  modifiedTime,
  onClick,
}: Props) {
  return (
    <Container onClick={() => onClick()}>
      <Box sx={{ flex: 1 }}>
        <Typography variant="h4" sx={{ marginBottom: '8px' }}>
          {title}
        </Typography>
        <Typography variant="body2">{message}</Typography>
      </Box>
      {modifiedTime && (
        <Chip
          icon={<FontAwesomeIcon icon={faPen} />}
          label={`Edited ${dateOnlyShortFormatter(modifiedTime)}`}
        />
      )}
      <FontAwesomeIcon icon={faChevronRight} />
    </Container>
  );
}

import useField from '@hooks/use-field-hook';
import { required, validEmail } from '@util/validators';
import { useLoginMutation } from '@api/endpoints/auth.api';
import { ApiError } from '@api/types/api-error';
import { LoginRequest } from '@api/types/auth/login.request';
import { usePageAlertVariants } from '@components/alerts';
import { useAuthTr } from '@i18n/use-auth-tr';
import useFieldsWatcher from '@hooks/use-fields-watcher';

export enum LoginResultAction {
  Proceed = 'proceed',
  ShowAlert = 'showAlert',
  InvalidAttemptsError = 'invalidAttemptsError',
  LockoutDueToInactivityError = 'lockoutError',
}

export default function useLogin(inviteToken?: string) {
  const { t } = useAuthTr('login');
  const { showErrorMessage, hideAlert } = usePageAlertVariants();

  const [login, { isLoading }] = useLoginMutation();

  const email = useField<string>([required(), validEmail()]);
  const password = useField<string>([required()]);
  const rememberMe = useField([], false);

  const { isValid, validateAll } = useFieldsWatcher([email, password]);
  const canSubmit = isValid && !isLoading;

  const submit = (): Promise<LoginResultAction | undefined> => {
    hideAlert();
    if (!validateAll()) {
      return Promise.resolve(undefined);
    }

    return login({
      username: email.value,
      password: password.value,
      inviteToken,
    })
      .unwrap()
      .then(() => LoginResultAction.Proceed)
      .catch(({ message, errors }: ApiError<LoginRequest>) => {
        if (message == null) {
          showErrorMessage();
          return LoginResultAction.ShowAlert;
        }

        if (message === 'Unauthorized') {
          showErrorMessage(t('prompts.invalidCredentials'));
          return LoginResultAction.ShowAlert;
        } else if (!!errors?.username || !!errors?.password) {
          showErrorMessage(message);

          email.setError(errors?.username);
          password.setError(errors?.password);

          return LoginResultAction.ShowAlert;
        } else if (message.startsWith('User locked out until')) {
          return LoginResultAction.InvalidAttemptsError;
        } else if (message.startsWith('User locked out due to inactivity')) {
          return LoginResultAction.LockoutDueToInactivityError;
        }

        return LoginResultAction.ShowAlert;
      });
  };

  return {
    email,
    password,
    canSubmit,
    isLoading,
    rememberMe,
    submit,
  };
}

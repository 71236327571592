import { StorageNode } from '@store/rca-editor/types';
import { usePageAlertVariants } from '@components/alerts';
import { useAppDispatch, useAppSelector } from '@store/store';
import { selectChainId } from '@store/rca-editor/selectors';
import { useEffect, useMemo, useState } from 'react';
import { removeNodeFromStorage } from '@store/rca-editor/rca-editor-actions';
import { isNullOrEmpty } from '@util/string-util';
import useField from '@hooks/use-field-hook';
import { equals, required } from '@util/validators';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { useGetCaseTotalsForChainItemQuery } from '@api/endpoints/case.api';

export enum DeleteStorageNodeUiState {
  loading,
  yesNo,
  confirm,
  close,
}

export default function useDeleteStorageNode(node: StorageNode) {
  const dispatch = useAppDispatch();

  const [pageState, setPageState] = useState(DeleteStorageNodeUiState.loading);
  const { showSuccessMessage } = usePageAlertVariants();

  const chainId = useAppSelector(selectChainId)!;
  const { chainItemId } = node;
  const skip = chainItemId == null;

  const { data: totals, isLoading: loadingTotals } =
    useGetCaseTotalsForChainItemQuery(
      {
        caseId: chainId,
        chainItemId: chainItemId ?? -1,
      },
      { skip: chainItemId == null }
    );

  const deleteConfirmText = useField<string>([required(), equals('DELETE')]);

  const { isValid: canSubmitConfirm } = useFieldsWatcher([deleteConfirmText]);

  const isLoading = !skip && loadingTotals;

  const hasNotes = skip || isLoading ? false : totals!.notes > 0;
  const hasTasks = skip || isLoading ? false : totals!.tasks! > 0;
  const hasImpacts = skip || isLoading ? false : totals!.impacts! > 0;
  const hasEvidence = skip || isLoading ? false : totals!.evidence! > 0;
  const hasSolutions = skip || isLoading ? false : totals!.solutions! > 0;
  const hasAnyData =
    hasNotes || hasTasks || hasImpacts || hasEvidence || hasSolutions;

  const withDataMessage = useMemo(() => {
    const str = [
      hasNotes ? 'Notes' : null,
      hasTasks ? 'Tasks' : null,
      hasImpacts ? 'Impacts' : null,
      hasEvidence ? 'Evidence' : null,
      hasSolutions ? 'Solutions' : null,
    ]
      .filter((x) => x != null)
      .join(', ');

    if (isNullOrEmpty(str)) {
      return null;
    }

    return `This Cause Box has ${str} associated with it. Are you sure you want to delete this Cause Box?`;
  }, [hasEvidence, hasImpacts, hasNotes, hasSolutions, hasTasks]);

  useEffect(() => {
    if (!isLoading) {
      if (hasAnyData) {
        setPageState(DeleteStorageNodeUiState.yesNo);
      } else {
        dispatch(removeNodeFromStorage(node));
        showSuccessMessage(
          `You have successfully deleted cause Box “${node.description}” from cause storage`
        );
        setPageState(DeleteStorageNodeUiState.close);
      }
    }
  }, [dispatch, hasAnyData, isLoading, node, showSuccessMessage]);

  const onConfirmYes = () => {
    console.assert(pageState === DeleteStorageNodeUiState.yesNo);
    setPageState(DeleteStorageNodeUiState.confirm);
  };

  const onSubmitConfirm = () => {
    console.assert(pageState === DeleteStorageNodeUiState.confirm);
    dispatch(removeNodeFromStorage(node));
    showSuccessMessage(
      `You have successfully deleted cause Box “${node.description}” from cause storage`
    );
    setPageState(DeleteStorageNodeUiState.close);
  };

  return {
    isLoading,
    hasNotes,
    hasTasks,
    hasImpacts,
    hasEvidence,
    hasSolutions,
    hasAnyData,
    pageState,
    withDataMessage,
    onConfirmYes,
    deleteConfirmText,
    onSubmitConfirm,
    canSubmitConfirm,
  };
}

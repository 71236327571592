import { Box, styled, Typography } from '@mui/material';
import { FlatButton } from '@components/buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faGripVertical } from '@fortawesome/pro-light-svg-icons';
import Column from '@components/layout-util-components/column';
import FlatBadge from '@components/badges/flat-badge';
import {
  dateOnlyShortFormatter,
  timeOnlyShortFormatter,
} from '@i18n/formatters';
import Spacer from '@components/layout-util-components/spacer';
import { Gap } from '@components/layout-util-components/gap';

export interface DraggableReportItemProps {
  id: number;
  reportName: string;
  reportTypeName: string;
  subReportTypeName: string;
  created: string;
  createdBy: string;
  isSelected?: boolean;
}

type ComponentProps = DraggableReportItemProps & {
  onToggleSelected: (id: number) => void;
};

const Container = styled('div', { target: 'draggable-report-item' })({
  border: '1px solid #E0E0E0',
  background: 'white',
  padding: 12,
  display: 'flex',
  gap: 14,
  alignItems: 'flex-start',
  borderRadius: 5,
});

export default function DraggableReportItem({
  isSelected,
  id,
  reportName,
  reportTypeName,
  subReportTypeName,
  createdBy,
  created,
  onToggleSelected,
}: ComponentProps) {
  return (
    <Container>
      <FlatButton dark={isSelected} onClick={() => onToggleSelected(id)}>
        {isSelected ? <FontAwesomeIcon icon={faCheck} /> : null}
      </FlatButton>
      <Column>
        <Box flexGrow={0} alignSelf="flex-start">
          <FlatBadge>{reportTypeName} Report</FlatBadge>
        </Box>
        <Gap size={8} />
        <Typography variant="body1">
          {reportName} ({subReportTypeName})
        </Typography>
        <Gap size={4} />
        <Typography variant="caption">
          Created by {createdBy} {dateOnlyShortFormatter(created)} |{' '}
          {timeOnlyShortFormatter(created)}
        </Typography>
      </Column>
      <Spacer />
      <Box alignSelf="center">
        <FontAwesomeIcon icon={faGripVertical} />
      </Box>
    </Container>
  );
}

import { UiPopupProps } from '@components/ui-popup/ui-popup-type';
import withModal from '@components/ui-popup/modals/modal-hoc';
import { styled } from '@mui/material';
import { useState } from 'react';
import CarouselScaffold from '@components/onboarding/carousel-scaffold';
import { StepOneBody, StepOneHeader } from '@components/onboarding/step-one';
import { StepTwoBody, StepTwoHeader } from '@components/onboarding/step-two';
import {
  StepThreeBody,
  StepThreeHeader,
} from '@components/onboarding/step-three';
import { StepFourBody, StepFourHeader } from '@components/onboarding/step-four';

export interface AppOnboardingModalOwnerProps extends UiPopupProps<void> {}

export interface StepProps {
  onNext?: () => void;
  onSkip?: () => void;
}

const Container = styled('div')({
  width: 658,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
});

function AppOnboardingModalOwner({ onClose }: AppOnboardingModalOwnerProps) {
  const [step, setStep] = useState(0);

  const moveNext = () => {
    if (step === 3) {
      onClose();
      return;
    }

    setStep(step + 1);
  };

  const skip = () => {
    onClose();
  };

  return (
    <Container>
      <CarouselScaffold index={step} indicators>
        <StepOneHeader />
        <StepTwoHeader />
        <StepThreeHeader />
        <StepFourHeader />
      </CarouselScaffold>
      <CarouselScaffold index={step}>
        <StepOneBody onNext={moveNext} onSkip={skip} />
        <StepTwoBody onNext={moveNext} onSkip={skip} />
        <StepThreeBody onNext={moveNext} onSkip={skip} />
        <StepFourBody onNext={moveNext} />
      </CarouselScaffold>
    </Container>
  );
}

export default withModal(AppOnboardingModalOwner, {
  noPadding: true,
  blurBackdrop: true,
});

import { api } from '@api/api';
import { CaseImpactTypeOption } from '@api/types/case-impact-type/case-impact-type.option';

const caseImpactTypeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCaseImpactTypes: build.query<CaseImpactTypeOption[], number>({
      query: (caseId: number) => ({
        url: 'caseImpactType',
        method: 'GET',
        params: {
          caseId,
        },
      }),
    }),
  }),
});

export const { useGetCaseImpactTypesQuery } = caseImpactTypeApi;

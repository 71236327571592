import { StatusPanel } from '@pages/app/outcome/components/status-panel';
import Column from '@components/layout-util-components/column';
import { ReactComponent as Logo } from '@assets/svgs/logo.svg';
import { Box, Typography } from '@mui/material';
import { PrimaryButton } from '@components/buttons';
import { useNavigate } from 'react-router-dom';
import { Gap } from '@components/layout-util-components/gap';

export default function InvalidPermissionView() {
  const navigate = useNavigate();

  return (
    <StatusPanel>
      <Column>
        <Box alignSelf="center">
          <Logo width={51} height="auto" fill="black" />
        </Box>
        <Gap size={50} />
        <Typography variant="h5">Invalid permission</Typography>
        <Gap size={32} />
        <Box alignSelf="center">
          <PrimaryButton onClick={() => navigate('/')}>
            Back to home
          </PrimaryButton>
        </Box>
      </Column>
    </StatusPanel>
  );
}

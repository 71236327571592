import { styled } from '@mui/material';
import { useMemo } from 'react';
import { RcaUtil } from '@util/rca-util';

const StyledBadge = styled('div')(({ theme: { palette } }) => ({
  fontFamily: 'Roboto',
  display: 'inline-flex',
  marginRight: 5,
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: palette.badge.green,
  borderRadius: 16,
  padding: '3px 10px',
  textTransform: 'capitalize',
  fontWeight: 500,
  fontSize: 13,
  lineHeight: '18px',
  letterSpacing: '0.16px',

  '> .indicator': {
    marginRight: 4,
    width: 8,
    height: 8,
    borderRadius: 4,
  },

  '&.healthy': {
    backgroundColor: palette.badge.healthy.background,
    color: palette.badge.healthy.contrast,

    '> .indicator': {
      backgroundColor: palette.badge.healthy.contrast,
    },
  },

  '&.average': {
    backgroundColor: palette.badge.average.background,
    color: palette.badge.average.contrast,

    '> .indicator': {
      backgroundColor: palette.badge.average.contrast,
    },
  },

  '&.unhealthy': {
    backgroundColor: palette.badge.unhealthy.background,
    color: palette.badge.unhealthy.contrast,

    '> .indicator': {
      backgroundColor: palette.badge.unhealthy.contrast,
    },
  },

  '&.small': {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '15px',
    letterSpacing: '0.15000000596046448px',
    textAlign: 'left',
    padding: '1px 5px',
  },
}));

interface Props {
  health?: number;
  small?: boolean;
}
export default function HealthBadge({ health, small }: Props) {
  const statusClassName = useMemo(() => {
    if (health == null) {
      return '';
    }

    return RcaUtil.getHealthStateFromScore(health!);
  }, [health]);

  if (health == null) return <></>;

  return (
    <StyledBadge className={`${statusClassName} ${small ? 'small' : ''}`}>
      <div className="indicator" />
      <span>{`Health: ${health}%`}</span>
    </StyledBadge>
  );
}

import { PropsWithChildren } from 'react';
import { styled } from '@mui/material';

interface Props {
  isOpen: boolean;
  shrink: boolean;
}

const Container = styled('div')<Props>(
  ({ isOpen, shrink, theme: { palette } }) => ({
    zIndex: 9999999999,
    position: 'fixed',
    right: shrink ? 16 : 0,
    top: shrink ? 96 : 80,
    bottom: shrink ? undefined : 0,
    borderRadius: shrink ? 8 : 0,
    transform: 'translateX(100%)',
    transition: 'transform ease-in-out 300ms',

    backgroundColor: palette.common.grey14,
    boxShadow: '0px 7.5px 15px 0px #00000040',
    minWidth: !shrink ? 800 : undefined,
    width: shrink ? 400 : undefined,

    ...(isOpen && {
      transform: 'translateX(0)',
    }),
  })
);

export default function ReportContainer({
  children,
  ...props
}: PropsWithChildren<Props>) {
  return <Container {...props}>{children}</Container>;
}

import { styled } from '@mui/material';

const CircleContainer = styled('div', { target: 'circle-container' })<{
  size: 45 | 42 | 40 | 32;
  light?: boolean;
}>(({ size, light, theme: { palette } }) => ({
  flex: `0 0 ${size}px`,
  display: 'inline-flex',
  justifyContent: 'center',
  backgroundColor: palette.common.grey45,
  color: 'black',
  borderRadius: 100,
  alignItems: 'center',
  width: size,
  height: size,
  overflow: 'hidden',
  fontSize: 10,
  fontWeight: 800,
  lineHeight: 22,
  letterSpacing: '0.05em',
  textAlign: 'center',
  ...(light && {
    backgroundColor: palette.common.grey45,
    color: palette.common.black,
    opacity: 0.5,
  }),
}));

export default CircleContainer;

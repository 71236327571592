import { LensesPanelState } from '@pages/app/rca/tabs/lenses/lenses-panel-hook';
import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from '@components/ui-popup/drawers';
import { Divider } from '@components/layout-util-components/divider';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import { Typography } from '@mui/material';
import WCTSelectField from '@components/input/select-field';
import { InputSkeleton } from '@components/skeletons';
import { ColumnForm } from '@components/layout-util-components/column';
import useImpactReportView from '@pages/app/rca/tabs/lenses/views/report-form-hook';
import InlineRadioSelectGroup from '@components/input/inline-radio-select-group';
import { isNullOrEmpty } from '@util/string-util';
import { LoadingIndicator } from '@components/loading-indicator';
import SelectableReportTypeListItem from '@pages/app/rca/tabs/lenses/components/selectable-report-type-list-item';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  onClose: () => void;
  state: LensesPanelState;
}

export default function ReportFormView({ onClose, state }: Props) {
  const { backToOptionsView } = state;
  const {
    reportType,
    reportSubType,
    reportSubTypeOptions,
    loadingReportSubTypes,
    hasLoadedReportSubTypes,
    canSubmit,
    submit,
    isLoading,
    impactType,
    trackedImpactOptions,
    impactReportGroupOptions,
    impactReportTypeOptions,
    trackedImpact,
    rankBy,
    rankByOptions,
    loadingRankByOptions,
    hasLoadedRankByOptions,
    impactGroup,
    selectableOptions,
    toggleSelect,
    isSelected,
    shouldDisplayImpactOptions,
  } = useImpactReportView(state);

  return (
    <ColumnForm
      noValidate
      onSubmit={async (e) => {
        e.preventDefault();
        if (await submit()) {
          onClose();
        }
      }}
    >
      <DrawerHeader onBack={backToOptionsView} onClose={onClose}>
        {reportType.name} report
      </DrawerHeader>
      <Divider addMargin compact negativeMargin={-24} />
      <DrawerContent>
        <Typography variant="body2">Lorem ipsum explaination</Typography>
        <Gap size={20} />
        {loadingReportSubTypes ? (
          <InputSkeleton />
        ) : hasLoadedReportSubTypes ? (
          <WCTSelectField
            id="reportType"
            name="reportType"
            label="Report Type"
            options={reportSubTypeOptions!.map((option) => ({
              id: option.reportSubTypeId,
              label: option.name,
            }))}
            value={reportSubType.value}
            error={reportSubType.error}
            onChange={reportSubType.set}
            required
          />
        ) : null}
        {loadingRankByOptions ? (
          <>
            <Gap size={20} />
            <InputSkeleton />
          </>
        ) : hasLoadedRankByOptions && rankByOptions!.length > 1 ? (
          <>
            <Gap size={20} />
            <WCTSelectField
              id="rankBy"
              name="rankBy"
              label="Rank By"
              options={rankByOptions!.map((option) => ({
                id: option.reportRankById,
                label: option.name,
              }))}
              value={rankBy.value}
              error={rankBy.error}
              onChange={rankBy.set}
              required
            />
          </>
        ) : null}
        {shouldDisplayImpactOptions ? (
          <>
            <Gap size={20} />
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <WCTSelectField
                id="trackedImpact"
                name="trackedImpact"
                label="Tracked Impacts"
                options={trackedImpactOptions!.map((option) => ({
                  id: option.caseImpactTypeId,
                  label: option.name,
                }))}
                value={trackedImpact.value}
                error={trackedImpact.error}
                onChange={trackedImpact.set}
                required
              />
            )}
            <Gap size={20} />
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <InlineRadioSelectGroup
                name="impactType"
                label="Impact type"
                options={impactReportTypeOptions!}
                value={impactType.value}
                error={impactType.error}
                onChange={impactType.set}
              />
            )}
            <Gap size={20} />
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <InlineRadioSelectGroup
                name="displayLevel"
                label="Display Level"
                options={impactReportGroupOptions!}
                value={impactGroup.value}
                error={impactGroup.error}
                onChange={impactGroup.set}
              />
            )}
          </>
        ) : null}
        {!isNullOrEmpty(selectableOptions.name) &&
        selectableOptions.options.length > 0 ? (
          <>
            <Divider addMargin compact />
            <Typography>{`Filter ${selectableOptions.name}`}</Typography>
            <Gap size={12} />
            {selectableOptions.isLoading ? (
              <LoadingIndicator show />
            ) : (
              selectableOptions.options.map((option) => (
                <SelectableReportTypeListItem
                  option={option}
                  isSelected={isSelected(option)}
                  onToggleSelect={() => toggleSelect(option)}
                />
              ))
            )}
          </>
        ) : null}
      </DrawerContent>
      <DrawerFooter showBorderTop>
        <ActionsRow>
          <OutlinedButton onClick={backToOptionsView}>Cancel</OutlinedButton>
          <PrimaryButton type="submit" disabled={!canSubmit}>
            Generate
          </PrimaryButton>
        </ActionsRow>
      </DrawerFooter>
    </ColumnForm>
  );
}

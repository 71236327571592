import { PropsWithChildren } from 'react';
import { styled } from '@mui/material';

interface Props {
  id: string;
  backdropImg?: string;
  backgroundColor?: string;
}

const Container = styled('div')<Props>(({ backdropImg, backgroundColor }) => ({
  width: '100vw',
  minHeight: '100vh',
  overflowY: 'hidden',
  scrollSnapAlign: 'start',

  ...(backgroundColor && {
    background: backgroundColor,
  }),

  ...(backdropImg && {
    background: `url(${backdropImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }),
}));

export default function SectionScaffold({
  id,
  children,
  ...props
}: PropsWithChildren<Props>) {
  return (
    <Container id={id} {...props}>
      {children}
    </Container>
  );
}

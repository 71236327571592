import { CaseImpactResource } from '@api/types/case/case-impact/case-impact.resource';
import { ImpactsPanelState } from '@pages/app/rca/tabs/components/editor-panels/impacts-panel-hook';
import { Box, styled, Typography } from '@mui/material';
import Column from '@components/layout-util-components/column';
import Spacer from '@components/layout-util-components/spacer';
import FilledBadge from '@components/badges/filled-badge';
import { ReactComponent as AddIcon } from '@assets/svgs/plus.svg';
import { ReactComponent as CheckIcon } from '@assets/svgs/check.svg';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  impact: CaseImpactResource;
  state: ImpactsPanelState;
}

const Container = styled('div', { target: 'impact-option' })(
  ({ theme: { palette } }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid #00000040',
    borderRadius: 5,
    marginBottom: 10,
    padding: 10,

    '.select-button': {
      border: 'none',
      flex: '0 0 auto',
      width: 32,
      height: 32,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: `${palette.common.grey45}80`,
      borderRadius: 4,

      svg: {
        fill: 'black',
      },
    },

    '&.selected': {
      border: '2px solid #000000BF',

      '.select-button': {
        background: palette.common.grey90,

        svg: {
          fill: 'white',
        },
      },
    },
  })
);

export default function ImpactOption({ impact, state }: Props) {
  const isSelected = state.isSelected(impact);

  return (
    <Container className={isSelected ? 'selected' : undefined}>
      <Column>
        <Box>
          <FilledBadge>{impact.caseImpactTypeName}</FilledBadge>
        </Box>
        <Gap size={8} />
        <Typography variant="body2">{impact.description}</Typography>
      </Column>
      <Spacer />
      <Gap size={24} />
      <button
        type="button"
        className="select-button"
        onClick={() => state.toggleImpactSelection(impact)}
      >
        {isSelected ? <CheckIcon /> : <AddIcon />}
      </button>
    </Container>
  );
}

import { Box, styled, Typography } from '@mui/material';
import Row from '@components/layout-util-components/row';
import { RunReportModel } from '@store/reports/reports-slice';
import { getColorForId } from '@util/colour-identifiers';
import { shouldShrinkReportView } from '@store/reports/reports-selectors';
import { useAppSelector } from '@store/store';
import { v4 as uuid } from 'uuid';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  model: RunReportModel;
  causeBoxCount: number;
  totalCoverage: number;
  percentages: number[];
}

export const CoverageStatContainer = styled('div')<{ isShrunk?: boolean }>(
  ({ isShrunk }) => ({
    border: '1px solid #00000040',
    borderRadius: 5,
    padding: '5px 10px',
    display: 'inline-flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    alignItems: 'center',
    gap: 1,

    '.value': {
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '21px',
      letterSpacing: '0px',
      textAlign: 'center',
    },

    '.title': {
      fontFamily: 'Roboto',
      fontSize: '11px',
      fontWeight: '500',
      lineHeight: '17px',
      letterSpacing: '0.15000000596046448px',
      textAlign: 'right',
      textTransform: 'uppercase',
    },

    ...(isShrunk && {
      border: 'none',
      padding: 0,
      flexDirection: 'row',
      gap: 3,

      '.value,.title': {
        fontFamily: 'Roboto',
        fontSize: '11px',
        fontWeight: '500',
        lineHeight: '17px',
        letterSpacing: '0.15000000596046448px',
        textAlign: 'left',
        textTransform: 'uppercase',
      },

      '.title': {
        fontWeight: '400 !important',
      },
    }),
  })
);

const Container = styled('div')<{ isShrunk?: boolean }>(
  ({ theme: { palette } }) => ({
    backgroundColor: palette.common.white,
    padding: 20,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',

    '.coverage-bar-container': {
      width: '100%',
      backgroundColor: '#E9E9E9',
    },
  })
);

const BarSegment = styled('div')<{ percent: number; color: string }>(
  ({ percent, color }) => ({
    display: 'inline-block',
    height: 19,
    backgroundColor: color,
    width: `${percent}%`,
  })
);

export default function CoverageOverviewContainer({
  model,
  causeBoxCount,
  totalCoverage,
  percentages,
}: Props) {
  const isShrunk = useAppSelector(shouldShrinkReportView);

  return (
    <Container isShrunk={isShrunk}>
      <Box flexGrow={1}>
        <Typography>{model.reportSubTypeName} coverage</Typography>
        <Gap size={10} />
        <div className="coverage-bar-container">
          {percentages.map((percent, index) => (
            <BarSegment
              key={uuid()}
              percent={percent}
              color={getColorForId(index)}
            />
          ))}
        </div>
        {isShrunk && (
          <>
            <Gap size={10} />
            <Row>
              <CoverageStatContainer>
                <Typography className="value">{causeBoxCount}</Typography>
                <Typography className="title">Cause boxes</Typography>
              </CoverageStatContainer>
              <Gap size={10} />
              <CoverageStatContainer>
                <Typography className="value">
                  {totalCoverage.toFixed(1)}%
                </Typography>
                <Typography className="title">
                  {model.reportSubTypeName} COVERAGE
                </Typography>
              </CoverageStatContainer>
            </Row>
          </>
        )}
      </Box>
      {!isShrunk && (
        <>
          <Gap size={32} />
          <Row>
            <div className="stat-container">
              <Typography className="value">{causeBoxCount}</Typography>
              <Typography className="title">Cause boxes</Typography>
            </div>
            <Gap size={10} />
            <div className="stat-container">
              <Typography className="value">{totalCoverage}%</Typography>
              <Typography className="title">
                {model.reportSubTypeName} COVERAGE
              </Typography>
            </div>
          </Row>
        </>
      )}
    </Container>
  );
}

import { UserGroup } from '@api/types/dashboard/user-groups-response';
import { ElipsisButton } from '@components/buttons';
import ContentFooterCard from '@components/cards/content-footer-card';
import GroupedAvatars from '@components/containers/grouped-avatars';
import Spacer from '@components/layout-util-components/spacer';
import { timespanFormatter } from '@i18n/formatters';
import { Avatar, Box, Typography } from '@mui/material';
import { getInitials } from '@util/name-util';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  group: UserGroup;
}

const RcaGroupCard: React.FC<Props> = ({ group }) => {
  return (
    <ContentFooterCard
      background="linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(64, 64, 64, 0.05) 100%)"
      content={
        <>
          <Typography fontSize={20} fontWeight={400} lineHeight="32px">
            {group.groupName}
          </Typography>
          <Gap size={20} />
          <Box display="flex" gap="10px">
            <Avatar>{getInitials(group.groupAdminName)}</Avatar>
            <Box display="flex" flexDirection="column">
              <Typography fontSize={11} fontWeight={500} color="#00000080">
                PRIMARY ADMIN
              </Typography>
              <Typography fontSize={14} fontWeight={400}>
                {group.groupAdminName}
              </Typography>
            </Box>
          </Box>
          <Gap size={15} />
          <Typography color="#00000080" fontSize={12} variant="caption">
            Created {timespanFormatter(group.created)}
          </Typography>
        </>
      }
      footer={
        <>
          <GroupedAvatars
            items={group.firstCompanyUsers.map((user) => ({
              ...user,
              label: getInitials(user.label),
            }))}
          />
          <Spacer />
          {/* TODO: Verify what options should be rendered */}
          <ElipsisButton actions={[]} />
        </>
      }
    />
  );
};

export default RcaGroupCard;

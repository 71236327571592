import withModal from '@components/ui-popup/modals/modal-hoc';
import { useAppTr } from '@i18n/use-app-tr';
import { styled, Typography } from '@mui/material';
import { PrimaryButton } from '@components/buttons';
import { useNavigate } from 'react-router-dom';
import { COMPANY_BILLING_PATH } from '@util/path-util';
import useUser from '@store/user/user-hook';
import { useGetCompanyAdminsQuery } from '@api/endpoints/company/company.api';
import { useMemo } from 'react';
import { isNullOrEmpty } from '@util/string-util';
import { CompanyAdminNameOptionType } from '@api/types/company/company-admin-name-option-type';
import { Gap } from '@components/layout-util-components/gap';

const Container = styled('div')({
  maxWidth: 658,
  display: 'flex',
  flexDirection: 'column',
});

function TrialExpiryGateModal() {
  const { t } = useAppTr('common');
  const { isAdminUser, logout } = useUser();
  const navigate = useNavigate();

  const { data: companyAdmins } = useGetCompanyAdminsQuery(
    CompanyAdminNameOptionType.email
  );
  const firstCompanyAdmin = useMemo(() => {
    if (companyAdmins == null || companyAdmins.length === 0) {
      return '';
    }

    return companyAdmins[0].label;
  }, [companyAdmins]);

  return (
    <Container>
      {isAdminUser ? (
        <>
          <Typography variant="h3">
            {t('trialExpireAlert.after12Days.admin.title')}
          </Typography>
          <Gap size={20} />
          <Typography variant="body2">
            {t('trialExpireAlert.after12Days.admin.message')}
          </Typography>
          <Gap size={24} />
          <PrimaryButton onClick={() => navigate(COMPANY_BILLING_PATH)}>
            {t('trialExpireAlert.after12Days.admin.buttonLabel')}
          </PrimaryButton>
        </>
      ) : (
        <>
          <Typography variant="h3">
            {t('trialExpireAlert.after12Days.user.title')}
          </Typography>
          <Gap size={20} />
          <Typography variant="body2">
            {isNullOrEmpty(firstCompanyAdmin)
              ? t('trialExpireAlert.after12Days.user.message.loading')
              : t('trialExpireAlert.after12Days.user.message.loaded', {
                  admin: firstCompanyAdmin,
                })}
          </Typography>
          <Gap size={24} />
          <PrimaryButton onClick={() => logout()}>
            {t('trialExpireAlert.after12Days.user.buttonLabel')}
          </PrimaryButton>
        </>
      )}
    </Container>
  );
}

export default withModal(TrialExpiryGateModal, { blurBackdrop: true });

import { PropsWithChildren, ReactNode } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from '@assets/svgs/close.svg';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  onClose: () => void;
  before?: ReactNode;
  after?: ReactNode;
}

const StyledContainer = styled('div')({
  display: 'flex',
  flex: '0 0 auto',
  padding: '30px 25px',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const StyledButton = styled('button')({
  display: 'inline-flex',
  flex: '0 0 auto',
  justifyContent: 'center',
  alignItems: 'center',
  width: 50,
  height: 50,
  border: 'none',
  borderRadius: 64,
  backgroundColor: '#E0E0E0',
  '&svg': {
    fill: 'rgba(0, 0, 0, 0.87)',
  },
});

export default function SmallFormHeader({
  children,
  onClose,
  before,
  after,
}: PropsWithChildren<Props>) {
  return (
    <StyledContainer>
      {before && (
        <>
          {before}
          <Gap size={4} />
        </>
      )}
      <Box display="flex" flexGrow={1} justifySelf="flex-start">
        <Typography variant="h3">{children}</Typography>
      </Box>
      {after && (
        <>
          <Gap size={4} />
          {after}
        </>
      )}
      <StyledButton type="button" onClick={() => onClose()}>
        <CloseIcon />
      </StyledButton>
    </StyledContainer>
  );
}

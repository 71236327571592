import { ChainItemCaseImpactResource } from '@api/types/chain/chain-item-case-impact/chain-item-case-impact.resource';
import { Box, styled, Typography } from '@mui/material';
import FilledBadge from '@components/badges/filled-badge';
import { CaseImpactUtil } from '@util/case-impact-util';
import Column from '@components/layout-util-components/column';
import Spacer from '@components/layout-util-components/spacer';
import { ElipsisButton } from '@components/buttons';
import { Gap } from '@components/layout-util-components/gap';
import { useAppSelector } from '@store/store';
import { selectCurrentRcaCurrency } from '@store/rca-editor/selectors';

interface Props {
  item: ChainItemCaseImpactResource;
}

const Container = styled('div', { target: 'selected-impact-item-view' })({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  border: '1px solid #00000040',
  borderRadius: 5,
  marginBottom: 10,
  padding: 10,
});

export default function SelectedImpactItemView({ item }: Props) {
  const currency = useAppSelector(selectCurrentRcaCurrency);

  return (
    <Container>
      <Column>
        <Box>
          <FilledBadge>{item.caseImpactTypeName}</FilledBadge>
        </Box>
        <Gap size={8} />
        <Typography variant="body2">{item.description}</Typography>
        <Gap size={8} />
        <Box>
          <FilledBadge>
            {CaseImpactUtil.getImpactValue(
              item.impactValue.toString(),
              item.caseImpactTypeMetric,
              item.options,
              currency
            )}
          </FilledBadge>
        </Box>
      </Column>
      <Spacer />
      <ElipsisButton actions={[]} />
    </Container>
  );
}

import {
  useGetCompanyUserCaseTotalsQuery,
  useGetImpactsByCoverageQuery,
  useGetSolutionsByEffectivenessQuery,
  useGetSolutionsByTermQuery,
  useGetThemesByCoverageQuery,
  useLazyGetUserCasesQuery,
  useLazyGetUserCompanyGroupsQuery,
  useLazyGetUserOutcomesQuery,
  useLazyGetUserTaskDetailsQuery,
} from '@api/endpoints/dashboard-api';
import { CaseItemRowResponse } from '@api/types/case/case-item-row.response';
import { UserGroup } from '@api/types/dashboard/user-groups-response';
import { UserOutcome } from '@api/types/dashboard/user-outcomes-response';
import { UserTask } from '@api/types/dashboard/user-tasks-response';
import { useFilter, usePagination, useSortableHeader } from '@components/table';
import { AnalysisViewMode } from '@pages/app/analysis/analysis-overview-hook';
import { useEffect, useMemo, useState } from 'react';
import { useGetCaseAnalysisTypesQuery } from '@api/endpoints/case-analysis-type.api';
import { DashboardFiltersRequest } from '@api/types/dashboard/dashboard-filters.request';
import { useGetCompanyUsersOptionsQuery } from '@api/endpoints/company-user.api';

const categoryFilters = ['RCA', 'Outcomes', 'Tasks', 'Groups'] as const;
type CategoryFilter = (typeof categoryFilters)[number];

export default function useDashboard() {
  const [activeCategoryFilter, setActiveCategoryFilter] =
    useState<CategoryFilter>('RCA');

  const caseAnalysisTypeFilter = useFilter<string>();
  const companyUserFilter = useFilter<number>();
  const viewMode = useFilter<AnalysisViewMode>(AnalysisViewMode.grid);

  const rcaListSort = useSortableHeader({ initialProperty: 'name' });
  const rcaListPagination = usePagination();

  const [RCAs, setRCAs] = useState<Array<CaseItemRowResponse>>([]);
  const [tasks, setTasks] = useState<Array<UserTask>>([]);
  const [outcomes, setOutcomes] = useState<Array<UserOutcome>>([]);
  const [groups, setGroups] = useState<Array<UserGroup>>([]);

  const onCategoryFilterChange = (e: React.MouseEvent<HTMLElement>) =>
    setActiveCategoryFilter(e.currentTarget.id as CategoryFilter);

  const { data: userOptions, isLoading: loadingUserOptions } =
    useGetCompanyUsersOptionsQuery({});

  const { data: caseAnalysisTypeOptions, isLoading: loadingCaseAnalysisTypes } =
    useGetCaseAnalysisTypesQuery({});

  const filters = useMemo(
    (): DashboardFiltersRequest => ({
      caseType: caseAnalysisTypeFilter.value,
      companyUserId: companyUserFilter.value,
    }),
    [caseAnalysisTypeFilter.value, companyUserFilter.value]
  );

  const {
    data: companyUserCaseTotalsData,
    isLoading: isLoadingCompanyUserCaseTotals,
  } = useGetCompanyUserCaseTotalsQuery(filters);

  const { data: themesByCoverageData, isLoading: isLoadingThemesByCoverage } =
    useGetThemesByCoverageQuery(filters);

  const { data: impactsByCoverageData, isLoading: isLoadingImpactsByCoverage } =
    useGetImpactsByCoverageQuery(filters);

  const { data: solutionsByTermData, isLoading: isLoadingSolutionsByTerm } =
    useGetSolutionsByTermQuery(filters);

  const {
    data: solutionsByEffectivenessData,
    isLoading: isLoadingSolutionsByEffectiveness,
  } = useGetSolutionsByEffectivenessQuery(filters);

  const [getUserCases, { isLoading: isLoadingUserCases }] =
    useLazyGetUserCasesQuery();

  const [getCompanyGroups, { isLoading: isLoadingCompanyGroups }] =
    useLazyGetUserCompanyGroupsQuery();

  const [getUserOutcomes, { isLoading: isLoadingUserOutcomes }] =
    useLazyGetUserOutcomesQuery();

  const [getTaskDetails, { isLoading: isLoadingTaskDetails }] =
    useLazyGetUserTaskDetailsQuery();

  const isLoading =
    isLoadingCompanyUserCaseTotals ||
    isLoadingThemesByCoverage ||
    isLoadingImpactsByCoverage ||
    isLoadingSolutionsByTerm ||
    isLoadingSolutionsByEffectiveness ||
    loadingCaseAnalysisTypes ||
    loadingUserOptions;

  const isLoadingPanels =
    isLoadingUserCases ||
    isLoadingCompanyGroups ||
    isLoadingUserOutcomes ||
    isLoadingTaskDetails;

  useEffect(() => {
    if (activeCategoryFilter === 'Groups') {
      getCompanyGroups(filters).then(({ data }) =>
        setGroups(data?.model || [])
      );
    }
    if (activeCategoryFilter === 'RCA') {
      getUserCases(filters).then(({ data }) => setRCAs(data?.model || []));
    }
    if (activeCategoryFilter === 'Outcomes') {
      getUserOutcomes(filters).then(({ data }) =>
        setOutcomes(data?.model || [])
      );
    }
    if (activeCategoryFilter === 'Tasks') {
      getTaskDetails(filters).then(({ data }) => setTasks(data?.model || []));
    }
  }, [
    activeCategoryFilter,
    getCompanyGroups,
    getTaskDetails,
    getUserCases,
    getUserOutcomes,
    filters,
  ]);

  return {
    companyUserCaseTotals: companyUserCaseTotalsData,
    themesByCoverage: themesByCoverageData,
    impactsByCoverage: impactsByCoverageData,
    solutionsByTerm: solutionsByTermData,
    solutionsByEffectiveness: solutionsByEffectivenessData,
    isLoading,
    isLoadingPanels,
    RCAs,
    tasks,
    groups,
    outcomes,
    categoryFilters,
    activeCategoryFilter,
    onCategoryFilterChange,
    viewMode,
    rcaListPagination,
    rcaListSort,
    caseAnalysisTypeOptions,
    caseAnalysisTypeFilter,
    userOptions,
    companyUserFilter,
  };
}

import { Box, Grid, Typography, styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const StyledStatContainer = styled(Grid)(({ theme: { palette } }) => ({
  display: 'flex',
  padding: '15px 20px',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: '1 0 0',
  background: palette.common.white,
  borderRadius: 4,
}));

function Stat({ title, value, icon, color }: StatInfo) {
  return (
    <StyledStatContainer>
      <Box display="flex" alignContent="center">
        {icon && <FontAwesomeIcon color={color} icon={icon} />}
        <Typography
          marginLeft="12px"
          fontSize={12}
          fontWeight={400}
          lineHeight="18px"
          color="#001927"
        >
          {title}
        </Typography>
      </Box>
      {value != null && (
        <Typography fontSize={20} fontWeight={400} lineHeight="27px">
          {value}
        </Typography>
      )}
    </StyledStatContainer>
  );
}

const StyledStatsContainer = styled('div')({
  display: 'flex',
  overflowX: 'auto',
  columnGap: '32px',
  rowGap: '10px',
  flexWrap: 'wrap',
  paddingBottom: 24,
  borderBottom: '1px solid #E3E3E3',
});

interface StatInfo {
  title: string;
  value?: string | number;
  icon?: IconProp;
  color?: string;
}

interface StatsProps {
  show?: boolean;
  stats: Array<StatInfo>;
}

export default function StatsContainer({ show, stats }: StatsProps) {
  return (
    <StyledStatsContainer>
      {stats.map(({ value, title, icon, color }) => (
        <Stat
          key={title}
          title={title}
          value={show ? value : '-'}
          icon={icon}
          color={color}
        />
      ))}
    </StyledStatsContainer>
  );
}

import { styled, Typography } from '@mui/material';
import Column from '@components/layout-util-components/column';
import Spacer from '@components/layout-util-components/spacer';
import { FlatButton } from '@components/buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownLeftAndUpRightToCenter,
  faTimes,
  faUpRightAndDownLeftFromCenter,
} from '@fortawesome/pro-light-svg-icons';
import {
  RunReportModel,
  setOpenLensesSlideOut,
  toggleShrinkView,
} from '@store/reports/reports-slice';
import { useAppDispatch, useAppSelector } from '@store/store';
import { shouldShrinkReportView } from '@store/reports/reports-selectors';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  runModel: RunReportModel;
  onClose: () => void;
}

const Container = styled('div')({
  display: 'flex',
  padding: 20,
});

export default function ReportTitleBar({ runModel, onClose }: Props) {
  const dispatch = useAppDispatch();

  const isShrunk = useAppSelector(shouldShrinkReportView);
  const canEdit = runModel.reportTypeId !== -1;

  return (
    <Container>
      <Column>
        <Typography variant="body2">
          {runModel.reportTypeName} Report
        </Typography>
        <Typography variant="h6">{runModel.reportSubTypeName}</Typography>
      </Column>
      <Spacer />
      <FlatButton outline onClick={() => dispatch(toggleShrinkView())}>
        <FontAwesomeIcon
          icon={
            isShrunk
              ? faUpRightAndDownLeftFromCenter
              : faDownLeftAndUpRightToCenter
          }
        />
      </FlatButton>

      {canEdit ? (
        <>
          <Gap size={15} />
          <FlatButton
            onClick={() => dispatch(setOpenLensesSlideOut(true))}
            outline
          >
            Edit
          </FlatButton>
        </>
      ) : null}
      {!isShrunk ? (
        <>
          <Gap size={32} />
          <FlatButton shape="circle" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </FlatButton>
        </>
      ) : null}
    </Container>
  );
}

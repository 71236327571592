import { styled } from '@mui/material';
import { ReactNode } from 'react';

export interface FilterRadioItem<T> {
  id: T;
  label: ReactNode;
}

interface Props<T> {
  options: Array<FilterRadioItem<T>>;
  value: T;
  onChange: (value: T) => void;
  className?: string;
}

const Container = styled('div', { target: 'radio-filter' })({
  display: 'flex',
  borderRadius: 4,

  '> .filter-option': {
    '&:first-of-type': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },

    '&:last-of-type': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      borderRight: 'none',
    },

    border: 'none',
    cursor: 'pointer',
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '6px 16px',
    gap: 8,
    width: 45,
    height: 36,
    flexGrow: 0,
    background: '#D8DBE3',
    color: '#5C5C5C',
    '& > svg': {
      fill: '#5C5C5C',
    },

    '&.selected': {
      backgroundColor: '#FFF',
      color: '#FF6C00',

      'svg, path': {
        fill: '#FF6C00',
      },
    },
  },
});

export default function RadioFilter<T = number>({
  options,
  value,
  onChange,
  className,
}: Props<T>) {
  return (
    <Container className={className}>
      {options.map(({ label, id: itemValue }) => (
        <button
          key={itemValue as number | string}
          type="button"
          className={`filter-option ${value === itemValue ? 'selected' : ''}`}
          onClick={() => onChange(itemValue)}
        >
          {label}
        </button>
      ))}
    </Container>
  );
}

import { SolutionsPanelState } from '@pages/app/rca/tabs/components/editor-panels/solutions-panel-hook';
import useField from '@hooks/use-field-hook';
import { validCurrency } from '@util/validators';
import { useGetSolutionTermOptionsQuery } from '@api/endpoints/solution-term.api';
import { useGetEfficacyOptionsQuery } from '@api/endpoints/efficacy.api';
import {
  useCreateChainItemSolutionMutation,
  useUpdateChainItemSolutionMutation,
} from '@api/endpoints/chain/chain-item-solution.api';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { CreateChainItemSolutionRequest } from '@api/types/chain/chain-item-solution/create-chain-item-solution.request';
import { ApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';
import { numberFromString } from '@util/string-util';
import { useAppSelector } from '@store/store';
import {
  selectCurrentRcaCurrency,
  selectFocusedNode,
} from '@store/rca-editor/selectors';
import { useGetSolutionTypeOptionsQuery } from '@api/endpoints/solution-type.api';
import { useState } from 'react';
import { UpdateChainItemSolutionRequest } from '@api/types/chain/chain-item-solution/update-chain-item-solution.request';

export default function useCreateFromCaseSolutionForm(
  state: SolutionsPanelState
) {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const currency = useAppSelector(selectCurrentRcaCurrency);
  const caseSolution = state.selectedCaseSolution!;
  const { chainItemId, chainId, editingSolution } = state;

  const focusedNode = useAppSelector(selectFocusedNode);
  const { label } = focusedNode.data;

  const { data: solutionTypes, isLoading: loadingSolutionTypes } =
    useGetSolutionTypeOptionsQuery();
  const { data: termOptions, isLoading: loadingTerms } =
    useGetSolutionTermOptionsQuery();
  const { data: efficacyOptions, isLoading: loadingEfficacyOptions } =
    useGetEfficacyOptionsQuery();

  const [updateSolution, { isLoading: submittingUpdate }] =
    useUpdateChainItemSolutionMutation();
  const [createSolution, { isLoading: submittingCreate }] =
    useCreateChainItemSolutionMutation();

  const [showAdditionalCost, setShowAdditionalCost] = useState(
    editingSolution?.additionalCost && editingSolution?.additionalCost > 0
      ? true
      : false
  );
  const type = useField<number>([], editingSolution?.solutionTypeId);
  const additionalCost = useField<string>(
    [validCurrency({ when: () => showAdditionalCost })],
    editingSolution?.additionalCost?.toString()
  );
  const effectiveness = useField<number>([], editingSolution?.efficacyId);
  const term = useField<number>([], editingSolution?.solutionTermId);

  const { isValid, validateAll } = useFieldsWatcher([
    type,
    additionalCost,
    effectiveness,
    term,
  ]);

  const isSubmitting = submittingCreate || submittingUpdate;
  const isLoading =
    loadingTerms || loadingEfficacyOptions || loadingSolutionTypes;
  const canSubmit = isValid && !isSubmitting;

  const submit = async () => {
    if (!validateAll()) {
      return false;
    }

    if (editingSolution) {
      try {
        await updateSolution({
          chainId: chainId!,
          chainItemSolutionId: editingSolution.chainItemSolutionId,
          solutionTypeId: type.value,
          solutionTermId: term.value,
          efficacyId: effectiveness.value,
          additionalCost: showAdditionalCost
            ? numberFromString(additionalCost.value)
            : undefined,
          rejected: editingSolution.rejected ?? false,
        }).unwrap();

        showSuccessMessage(
          `You have successfully updated evidence for Cause Box “${label}”`
        );

        return true;
      } catch (error) {
        const { errors, message } =
          error as ApiError<UpdateChainItemSolutionRequest>;
        showErrorMessage(
          errors?.chainId ?? errors?.chainItemSolutionId ?? message
        );

        type.setError(errors?.solutionTypeId);
        term.setError(errors?.solutionTermId);
        effectiveness.setError(errors?.efficacyId);
        additionalCost.setError(errors?.additionalCost);

        return false;
      }
    } else {
      try {
        await createSolution({
          chainId: chainId!,
          chainItemId: chainItemId!,
          solutionTypeId: type.value,
          solutionTermId: term.value,
          solutionId: caseSolution.solutionId,
          additionalCost: showAdditionalCost
            ? numberFromString(additionalCost.value)
            : undefined,
          efficacyId: effectiveness.value,
          rejected: false,
        }).unwrap();

        showSuccessMessage(
          `You have successfully added evidence to Cause Box “${label}”`
        );

        return true;
      } catch (error) {
        const { errors, message } =
          error as ApiError<CreateChainItemSolutionRequest>;

        showErrorMessage(
          errors?.chainId ??
            errors?.chainItemId ??
            errors?.solutionId ??
            message
        );

        type.setError(errors?.solutionTypeId);
        term.setError(errors?.solutionTermId);
        effectiveness.setError(errors?.efficacyId);
        additionalCost.setError(errors?.additionalCost);

        return false;
      }
    }
  };

  return {
    caseSolution,
    termOptions,
    efficacyOptions,
    additionalCost,
    solutionTypes,
    effectiveness,
    term,
    isLoading,
    canSubmit,
    submit,
    isSubmitting,
    showAdditionalCost,
    setShowAdditionalCost,
    type,
    currency,
  };
}

import { CompanyInviteDetailsRequest } from '@api/types/company-user/company-invite-details.request';
import { useGetCompanyInviteDetailsQuery } from '@api/endpoints/company-user.api';
import useField from '@hooks/use-field-hook';
import { equals, phoneNumber, required } from '@util/validators';
import { PhoneNumber } from '@components/input/phone-number-field';
import { useEffect } from 'react';
import {
  useCompleteUserMutation,
  useGetInvitedUserDetailsQuery,
} from '@api/endpoints/user.api';
import { useGetDialCodesQuery } from '@api/endpoints/country.api';
import { ApiError } from '@api/types/api-error';
import { CompleteUserRequest } from '@api/types/user/complete-user.request';
import { usePageAlertVariants } from '@components/alerts';
import useFieldsWatcher from '@hooks/use-fields-watcher';

export default function useAcceptInvite({
  companyId,
  token,
}: CompanyInviteDetailsRequest) {
  const { showErrorMessage } = usePageAlertVariants();

  const { data: inviteDetails, isError: errorLoadingDetails } =
    useGetCompanyInviteDetailsQuery({ companyId, token });

  const { data: invitedUserDetails, isLoading: loadingUserDetails } =
    useGetInvitedUserDetailsQuery({
      inviteToken: token,
      companyId,
    });

  const { data: dialCodes, isLoading: loadingDialCodes } =
    useGetDialCodesQuery(true);

  const firstName = useField<string>(
    [required()],
    invitedUserDetails?.firstName
  );
  const surname = useField<string>([required()], invitedUserDetails?.lastName);
  const jobTitle = useField<string>([], invitedUserDetails?.jobTitle);
  const phone = useField<PhoneNumber | undefined>([
    phoneNumber({ when: () => false }),
  ]);
  const marketingConsent = useField<boolean>([required()], false);
  const acceptTerms = useField<boolean>([required(), equals(true)], false);

  const isLoading = loadingUserDetails || loadingDialCodes;

  const [completeUser, { isLoading: isSubmitting }] = useCompleteUserMutation();
  const { isDirty, isValid, validateAll } = useFieldsWatcher([
    firstName,
    surname,
    phone,
    acceptTerms,
  ]);

  const canSubmit = isDirty && isValid && !isSubmitting;

  useEffect(() => {
    if (errorLoadingDetails) {
      showErrorMessage();
    }
  }, [errorLoadingDetails, showErrorMessage]);

  const submit = async (): Promise<boolean> => {
    if (!validateAll()) {
      return false;
    }

    return completeUser({
      inviteToken: token,
      companyId: companyId,
      firstName: firstName.value,
      lastName: surname.value,
      countryDialCode: phone.value?.dialCode,
      telephoneNumber: phone.value?.number,
      acceptTerms: acceptTerms.value,
      marketingOptIn: marketingConsent.value,
    })
      .unwrap()
      .then(() => {
        return true;
      })
      .catch(({ message, errors }: ApiError<CompleteUserRequest>) => {
        showErrorMessage(message);

        firstName.setError(errors?.firstName);
        surname.setError(errors?.lastName);
        phone.setError(errors?.countryDialCode || errors?.telephoneNumber);
        acceptTerms.setError(errors?.acceptTerms);
        marketingConsent.setError(errors?.marketingOptIn);

        return false;
      });
  };

  return {
    inviteDetails,
    firstName,
    surname,
    jobTitle,
    phone,
    marketingConsent,
    acceptTerms,
    isLoading,
    isSubmitting,
    dialCodes,
    canSubmit,
    submit,
  };
}

import useFilter from '@components/table/hooks/use-filter-hook';
import useSortableHeader from '@components/table/hooks/use-sortable-header-hook';
import { usePagination } from '@components/table';
import { SortDirection } from '@api/types/sort-direction';
import { useGetSubscriptionStatementOptionsQuery } from '@api/endpoints/subscription-status.api';
import { useGetAdminUserManagersQuery } from '@api/endpoints/admin/admin-user.api';
import { useGetAdminPackageOptionsQuery } from '@api/endpoints/admin/admin-package.api';
import { useGetAdminCompanyTagsQuery } from '@api/endpoints/admin/admin-tag.api';
import { useGetAdminCompaniesQuery } from '@api/endpoints/admin/admin-company.api';

export default function useCompaniesIndex() {
  const searchFilter = useFilter<string>();

  const subscriptionFilter = useFilter<string>();
  const wctManagerFilter = useFilter<string>();
  const tagsFilter = useFilter<Array<number>>([]);
  const statusFilter = useFilter<number>();

  const sort = useSortableHeader({
    initialProperty: 'created',
    initialDirection: SortDirection.desc,
  });

  const pagination = usePagination();

  const {
    data: companies,
    isLoading,
    isFetching,
  } = useGetAdminCompaniesQuery({
    searchText: searchFilter.value,
    sortBy: sort.property,
    sortDirection: sort.direction,
    managerUserId: wctManagerFilter.value,
    tagIds: tagsFilter.arrayValue,
    packageId: subscriptionFilter.value,
    statusId: statusFilter.value,
    skip: pagination.skip,
    take: pagination.take,
  });

  const { data: tags, isLoading: isLoadingTags } =
    useGetAdminCompanyTagsQuery();

  const { data: managers, isLoading: isLoadingManagers } =
    useGetAdminUserManagersQuery();

  const { data: subscriptionStatuses, isLoading: isLoadingStatuses } =
    useGetSubscriptionStatementOptionsQuery();

  const { data: packages, isLoading: isLoadingPackages } =
    useGetAdminPackageOptionsQuery();

  return {
    searchFilter,
    subscriptionFilter,
    wctManagerFilter,
    tagsFilter,
    statusFilter,
    sort,
    pagination,
    companies,
    isFetching,
    tags,
    managers,
    subscriptionStatuses,
    packages,
    isLoading:
      isLoading ||
      isLoadingTags ||
      isLoadingManagers ||
      isLoadingStatuses ||
      isLoadingPackages,
  };
}

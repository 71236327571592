import { CaseResource } from '@api/types/case/case.resource';
import SectionScaffold from '@components/outcome-report/components/section-scaffold';
import { styled, Typography } from '@mui/material';
import Spacer from '@components/layout-util-components/spacer';
import Logo from '@assets/images/logo.png';
import { isNullOrEmpty } from '@util/string-util';
import FlatBadge from '@components/badges/flat-badge';
import { Divider } from '@components/layout-util-components/divider';
import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import { dateOnlyShortFormatter } from '@i18n/formatters';
import BackdropSrc from '@assets/images/report-backdrop.png';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  caseDetails: CaseResource;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: '90%',
  zIndex: 200,

  padding: '0 25%',

  '>.title': {
    fontFamily: 'Roboto',
    fontSize: '40px',
    fontWeight: '400',
    lineHeight: '53px',
    letterSpacing: '0px',
    textAlign: 'center',
  },

  '.description': {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '29px',
    letterSpacing: '0.17000000178813934px',
    textAlign: 'center',
  },

  '.types-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '17px',
  },

  '.footer-container': {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  span: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: 8,
  },
});

export default function CoverSection({ caseDetails }: Props) {
  const {
    name,
    description,
    caseTypes,
    created,
    createdByCompanyUserName,
    caseSeverityName,
    casePriorityName,
  } = caseDetails;

  return (
    <SectionScaffold id="cover" backdropImg={BackdropSrc}>
      <Container>
        <Spacer flex={2} />
        <Gap size={32} />
        <img src={Logo} width={102} height="auto" alt="logo" />
        <Gap size={64} />
        <div className="title">{name}</div>
        {!isNullOrEmpty(name) ? (
          <>
            <Gap size={20} />
            <div className="desctipion">{description}</div>
          </>
        ) : null}
        {caseTypes != null && caseTypes.length > 0 ? (
          <>
            <Gap size={20} />
            <div className="types-container">
              {caseTypes.map((caseType) => (
                <FlatBadge key={caseType.id} rounded>
                  {caseType.label}
                </FlatBadge>
              ))}
            </div>
          </>
        ) : null}
        <Divider addMargin />
        <div className="footer-container">
          <InitialsContainerWithData
            label="Created By"
            fullName={createdByCompanyUserName}
          />
          <Gap size={24} />
          <Typography variant="subtitle2">
            CREATED {dateOnlyShortFormatter(created)}
          </Typography>
          <Spacer />
          <span>
            SEVERITY <FlatBadge rounded>{caseSeverityName}</FlatBadge>
          </span>
          {!isNullOrEmpty(casePriorityName) ? (
            <>
              <Gap size={50} />
              <span>
                PRIORITY <FlatBadge rounded>{caseSeverityName}</FlatBadge>
              </span>
            </>
          ) : null}
        </div>
        <Spacer flex={1} />
      </Container>
    </SectionScaffold>
  );
}

import { ListItemRow } from '@components/containers/list-container';
import { Badge, Chip, IconButton, Typography } from '@mui/material';
import {
  CaseSolutionItemChainItemResponse,
  CaseSolutionItemResponse,
} from '@api/types/case/case-solution/case-solution-item.response';
import FolderOpenIcon from '@mui/icons-material/FolderOutlined';
import { ElipsisButton } from '@components/buttons';
import { useMemo, useState } from 'react';
import Row from '@components/layout-util-components/row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { currencyFormatter } from '@i18n/formatters';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@store/store';
import {
  selectCurrentRcaCaseId,
  selectCurrentRcaCurrency,
} from '@store/rca-editor/selectors';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  solution: CaseSolutionItemResponse;
  onEdit: () => void;
  canDelete: boolean;
  onDelete: () => void;
  onOpen: () => void;
}
export default function SolutionsTableRow({
  solution,
  onEdit,
  onDelete,
  onOpen,
}: Props) {
  const caseId = useAppSelector(selectCurrentRcaCaseId);
  const currency = useAppSelector(selectCurrentRcaCurrency);
  const navigate = useNavigate();
  const { name, description, chainItems, cost } = solution;
  const chainItemCount = chainItems.length;

  const firstChain = chainItems?.[0];
  const oneOrMoreChains = chainItemCount > 0 && firstChain != null;
  const multipleChains = chainItemCount > 1 && firstChain != null;

  const [expanded, setExpanded] = useState(false);

  const getChipValue = (k: keyof CaseSolutionItemChainItemResponse) => {
    if (firstChain == null) {
      return '-';
    }
    const originalValue = firstChain[k];
    let value = originalValue;
    if (value == null) {
      return '-';
    }

    if (typeof value === 'string') {
      value = value.trim().toLowerCase();
    }

    const otherValues = chainItems?.map((chainItem) => {
      let otherValue = chainItem[k];
      if (otherValue != null && typeof otherValue === 'string') {
        otherValue = otherValue.trim().toLowerCase();
      }
      return otherValue;
    });

    return otherValues?.every((v) => v === value) ? originalValue : '-';
  };

  const accumulatedCost = useMemo(() => {
    const additionalCosts = chainItems.map(
      (chainItem) => chainItem.additionalCost ?? 0
    );
    return additionalCosts.reduce((acc, cost) => acc + cost, 0);
  }, [chainItems]);

  return (
    <>
      <ListItemRow onClick={onOpen}>
        <div>
          {multipleChains && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setExpanded(!expanded);
              }}
            >
              {expanded ? (
                <FontAwesomeIcon icon={faChevronUp} />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} />
              )}
            </IconButton>
          )}
        </div>
        <div>
          <Typography variant="body1">{name}</Typography>
          <Typography variant="body2">{description}</Typography>
        </div>
        <div>
          {oneOrMoreChains ? (
            <Chip
              label={
                chainItemCount === 1
                  ? 'Associated to 1 Cause box'
                  : `Associated to ${chainItemCount} Cause boxes`
              }
            />
          ) : null}
        </div>
        <div>
          <Badge badgeContent={chainItemCount} color="primary">
            <FolderOpenIcon />
          </Badge>
        </div>
        <div>
          {oneOrMoreChains && <Chip label={getChipValue('solutionType')} />}
        </div>
        <div>
          {oneOrMoreChains && <Chip label={getChipValue('efficacyName')} />}
        </div>
        <div>
          {oneOrMoreChains && <Chip label={getChipValue('solutionTermName')} />}
        </div>
        <div>
          <Row>
            {!!cost && cost !== 0 ? (
              <Chip
                label={currencyFormatter(cost, {
                  decimalPlaces: 2,
                  currency: currency || '',
                })}
              />
            ) : null}
            {!!accumulatedCost && accumulatedCost !== 0 ? (
              <>
                <Gap size={10} />
                <Typography variant="overline">
                  +
                  {currencyFormatter(accumulatedCost, {
                    decimalPlaces: 2,
                    currency: currency || '',
                  })}
                </Typography>
              </>
            ) : null}
          </Row>
        </div>
        <ElipsisButton
          actions={[
            {
              label: 'View',
              onClick: () => onOpen(),
            },
            {
              label: 'Edit',
              onClick: () => onEdit(),
            },
            {
              label: 'Delete',
              onClick: () => onDelete(),
            },
          ]}
        />
      </ListItemRow>
      {expanded && (
        <>
          {chainItems.map(
            ({
              chainItemId,
              additionalCost,
              chainItemName,
              efficacyName,
              solutionTermName,
              solutionType,
            }) => (
              <ListItemRow>
                <Gap size={8} />
                <Gap size={8} />
                <div>
                  {!!chainItemCount ? <Chip label={chainItemName} /> : null}
                </div>
                <Gap size={8} />
                <div>{solutionType && <Chip label={solutionType} />}</div>
                <div>{efficacyName && <Chip label={efficacyName} />}</div>
                <div>
                  {solutionTermName && <Chip label={solutionTermName} />}
                </div>
                <div>
                  {!!additionalCost && additionalCost !== 0 ? (
                    <Chip
                      label={`${currencyFormatter(additionalCost, {
                        decimalPlaces: 2,
                        currency: currency || '',
                      })}`}
                    />
                  ) : null}
                </div>
                <ElipsisButton
                  actions={[
                    {
                      label: 'View visual analysis',
                      onClick: () =>
                        navigate(
                          `/rca/tabs/${caseId}/chart/?chainItem=${chainItemId}`
                        ),
                    },
                  ]}
                />
              </ListItemRow>
            )
          )}
        </>
      )}
    </>
  );
}

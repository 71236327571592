import { api } from '@api/api';
import { CaseReportResource } from '@api/types/case/case-report/case-report.resource';
import { CreateReportRequest } from '@api/types/case/case-report/create-report.request';
import { makeError } from '@api/types/api-error';
import { UpdateReportRequest } from '@api/types/case/case-report/update-report.request';
import { DeleteReportRequest } from '@api/types/case/case-report/delete-report.request';
import { PaginatedResult } from '@api/types/paginated-result';
import { RunReportResponse } from '@api/types/case/case-report/run-report.response';
import { RunReportRequest } from '@api/types/case/case-report/run-report.request';

const caseReportApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCaseReports: build.query<PaginatedResult<CaseReportResource>, number>({
      query: (caseId) => ({
        url: `case/${caseId}/report`,
      }),
      providesTags: ['CaseReport'],
    }),
    getRunReport: build.query<RunReportResponse<any>, RunReportRequest>({
      query: ({ caseId, ...params }) => ({
        url: `case/${caseId}/report/run`,
        params,
      }),
      transformResponse: RunReportResponse.fromJson,
    }),
    getCaseReport: build.query<
      CaseReportResource,
      { caseId: number; reportId: number }
    >({
      query: ({ caseId, reportId }) => ({
        url: `case/${caseId}/report/${reportId}`,
      }),
      providesTags: (arg) =>
        !!arg
          ? [
              {
                id: arg.reportId,
                type: 'CaseReport',
              },
            ]
          : [],
    }),
    createCaseReport: build.mutation<
      CaseReportResource,
      CreateReportRequest & { caseId: number }
    >({
      query: ({ caseId, ...body }) => ({
        url: `case/${caseId}/report`,
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) => makeError<CreateReportRequest>(error),
      invalidatesTags: (_, error) => (!error ? ['CaseReport'] : []),
    }),
    updateCaseReport: build.mutation<
      void,
      UpdateReportRequest & { caseId: number }
    >({
      query: ({ caseId, ...body }) => ({
        url: `case/${caseId}/report`,
        method: 'PUT',
        body,
      }),
      transformErrorResponse: (error) => makeError<UpdateReportRequest>(error),
      invalidatesTags: (_, error) => (!error ? ['CaseReport'] : []),
    }),
    deleteReport: build.mutation<void, DeleteReportRequest>({
      query: ({ caseId, reportId }) => ({
        url: `case/${caseId}/report/${reportId}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (error) => makeError<DeleteReportRequest>(error),
      invalidatesTags: (_, error) => (!error ? ['CaseReport'] : []),
    }),
    getCaseOverviewSolutions: build.query<RunReportResponse<any>, number>({
      query: (caseId) => ({
        url: `case/${caseId}/Report/Run/All/Coverage/Solution/SolutionTerm`,
      }),
      transformResponse: RunReportResponse.fromJson,
      providesTags: ['Case'],
    }),
    getCaseOverviewThemes: build.query<RunReportResponse<any>, number>({
      query: (caseId) => ({
        url: `case/${caseId}/Report/Run/All/Coverage/theme/coverageAll`,
      }),
      transformResponse: RunReportResponse.fromJson,
      providesTags: ['Case'],
    }),
    getCaseOverviewImpacts: build.query<RunReportResponse<any>, number>({
      query: (caseId) => ({
        url: `case/${caseId}/Report/Run/All/Coverage/impact/coverageAll`,
      }),
      transformResponse: RunReportResponse.fromJson,
      providesTags: ['Case'],
    }),
  }),
});

export const {
  useGetCaseReportsQuery,
  useCreateCaseReportMutation,
  useGetRunReportQuery,
  useDeleteReportMutation,
  useGetCaseReportQuery,
  useGetCaseOverviewSolutionsQuery,
  useGetCaseOverviewImpactsQuery,
  useGetCaseOverviewThemesQuery,
  useUpdateCaseReportMutation,
} = caseReportApi;

export default caseReportApi;

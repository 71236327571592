import {
  DragEventHandler,
  forwardRef,
  KeyboardEventHandler,
  ReactNode,
} from 'react';
import { PopoverMenuAction } from '@components/popover-menu/use-popover-menu';
import { styled, Typography } from '@mui/material';
import Row from '@components/layout-util-components/row';
import Column from '@components/layout-util-components/column';
import Spacer from '@components/layout-util-components/spacer';
import HealthBadge from '@components/badges/health-badge';
import { Gap } from '@components/layout-util-components/gap';

const NodeDefaultBody = '/svgs/node-default-body.svg';

interface Props {
  onDragStart?: DragEventHandler;
  onDragEnd?: DragEventHandler;
  actions?: Array<PopoverMenuAction>;
  actionButton?: ReactNode;
  onEditDescription?: (value?: string) => void;
  onSubmitDescription?: () => void;
  description?: string;
  healthScore?: number;
}

const Container = styled('div')({
  flex: '0 0 auto',
  width: '100%',
  height: 116,
  position: 'relative',
  padding: '10px 18px 10px 10px',
  color: 'black',

  '*': {
    zIndex: 1,
  },

  '.node-background': {
    zIndex: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    mask: `url(${NodeDefaultBody}) center center no-repeat`,
    maskSize: 'contain',
    backgroundColor: 'white',
    pointerEvents: 'none',
  },

  textarea: {
    backgroundColor: 'transparent',
    outline: 'none',
    border: 'none',
    padding: '5px 20px',
    width: '100%',
    height: '100%',
    resize: 'none',
  },

  'textarea, .description': {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '14px',
    letterSpacing: '0.15000000596046448px',
    textAlign: 'left',
  },
});

const ChartStorageNode = forwardRef<string, Props>(
  (
    {
      description,
      healthScore,
      actionButton,
      onDragStart,
      onDragEnd,
      actions,
      onEditDescription,
      onSubmitDescription,
    },
    ref
  ) => {
    const keyEnter: KeyboardEventHandler = (e) => {
      if (!e.shiftKey && e.code === 'Enter') {
        e.preventDefault();
        e.stopPropagation();
        onSubmitDescription!();
      }
    };

    return (
      <Container
        draggable={onDragStart != null}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
      >
        <div className="node-background" />
        <Row sx={{ height: '100%' }}>
          <Column sx={{ alignSelf: 'stretch' }}>
            {onEditDescription == null ? (
              <>
                <Typography variant="body1">{description}</Typography>
                <Spacer />
                <HealthBadge health={healthScore} />
              </>
            ) : (
              <textarea
                autoFocus
                value={description || ''}
                onChange={(e) => onEditDescription(e.target.value)}
                onKeyDown={keyEnter}
                placeholder="Caused By"
              />
            )}
          </Column>
          <Spacer />
          <Gap size={8} />
          {actionButton ? actionButton : null}
        </Row>

        {/*<Box alignSelf="center">*/}
        {/*  {mode === ViewMode.add ? (*/}
        {/*    <FlatButton onClick={() => submit()}>*/}
        {/*      <BinIcon />*/}
        {/*    </FlatButton>*/}
        {/*  ) : mode === ViewMode.edit ? (*/}
        {/*    <Row>*/}
        {/*      <FlatButton onClick={() => submit(value)}>*/}
        {/*        <CheckIcon />*/}
        {/*      </FlatButton>*/}
        {/*      <Gap size={4} />*/}
        {/*      <FlatButton onClick={() => submit()}>*/}
        {/*        <CloseIcon />*/}
        {/*      </FlatButton>*/}
        {/*    </Row>*/}
        {/*  ) : actions != null && actions.length > 0 ? (*/}
        {/*    <ElipsisButton className="actions" actions={actions} />*/}
        {/*  ) : null}*/}
        {/*</Box>*/}
      </Container>
    );
  }
);

export default ChartStorageNode;

import { styled } from '@mui/material';
import { LoadingIndicator } from '@components/loading-indicator';

const Container = styled('div')({
  position: 'absolute',
  top: 20,
  left: 20,
  width: 20,
  height: 20,
  backgroundColor: 'white',
  borderRadius: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 10000,

  '>*': {
    transform: 'translateY(-5px)',
  },

  '.pending-indicator': {
    transform: 'translateY(0px) !important',
    width: 12,
    height: 12,
    borderRadius: 6,
    background: 'red',
  },
});

export default function ChartBusyIndicator() {
  return (
    <Container>
      <LoadingIndicator show size={12} />
    </Container>
  );
}

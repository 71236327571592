import { RcaOutcomeState } from '@pages/app/rca/outcome/rca-outcome-hook';
import useField from '@hooks/use-field-hook';
import { required } from '@util/validators';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import {
  useCreateCaseOutcomeMutation,
  useUpdateCaseOutcomeMutation,
} from '@api/endpoints/case-outcome.api';
import { ApiError } from '@api/types/api-error';
import { UpdateCaseOutcomeRequest } from '@api/types/case/case-outcome/update-case-outcome.request';
import { usePageAlertVariants } from '@components/alerts';
import { useEffect } from 'react';
import { isNullOrEmpty } from '@util/string-util';

export default function useCreateOutcome(state: RcaOutcomeState) {
  const { showErrorMessage } = usePageAlertVariants();
  const {
    caseDetail,
    isLoading: loadingState,
    outcomeDetail,
    outcomeId,
    caseId,
    setCurrentOutcomeId,
  } = state;

  const [create, { isLoading: isSubmittingCreate }] =
    useCreateCaseOutcomeMutation();
  const [update, { isLoading: isSubmittingUpdate }] =
    useUpdateCaseOutcomeMutation();

  const name = useField<string>([required()], outcomeDetail?.name);

  const toggleOverview = useField<boolean>(
    [],
    outcomeDetail?.showOverview ?? true
  );
  const toggleImpacts = useField<boolean>(
    [],
    outcomeDetail?.showImpacts ?? true
  );
  const toggleEvidence = useField<boolean>(
    [],
    outcomeDetail?.showEvidence ?? true
  );
  const toggleTeam = useField<boolean>([], outcomeDetail?.showTeam ?? true);
  const toggleAnalysis = useField<boolean>(
    [],
    outcomeDetail?.showAnalysis ?? true
  );
  const toggleInsights = useField<boolean>(
    [],
    outcomeDetail?.showInsights ?? true
  );
  const toggleSolutions = useField<boolean>(
    [],
    outcomeDetail?.showSolutions ?? true
  );
  const toggleRecommendations = useField<boolean>(
    [],
    outcomeDetail?.showRecommendations ?? true
  );

  const { isValid, validateAll, isDirty } = useFieldsWatcher([name]);

  const isLoading = loadingState;
  const isBusy = isSubmittingCreate || isSubmittingUpdate;
  const canSubmit = isValid && !isLoading && !isBusy;

  const caseName = caseDetail?.name;
  const setDefaultName = name.set;
  useEffect(() => {
    if (outcomeId == null && !isNullOrEmpty(caseName)) {
      setDefaultName(`${caseName} - Outcome`);
    }
  }, [caseName, outcomeId, setDefaultName]);

  const submit = async () => {
    if (!validateAll()) {
      return false;
    }

    try {
      if (outcomeId != null) {
        await update({
          caseId,
          outcomeId,
          name: name.value,
          showOverview: toggleOverview.value,
          showImpacts: toggleImpacts.value,
          showTeam: toggleTeam.value,
          showAnalysis: toggleAnalysis.value,
          showInsights: toggleInsights.value,
          showSolutions: toggleSolutions.value,
          showRecommendations: toggleRecommendations.value,
          showEvidence: toggleEvidence.value,
        }).unwrap();
      } else {
        const result = await create({
          caseId,
          name: name.value,
          showOverview: toggleOverview.value,
          showImpacts: toggleImpacts.value,
          showTeam: toggleTeam.value,
          showAnalysis: toggleAnalysis.value,
          showInsights: toggleInsights.value,
          showSolutions: toggleSolutions.value,
          showRecommendations: toggleRecommendations.value,
          showEvidence: toggleEvidence.value,
        }).unwrap();

        setCurrentOutcomeId(result.outcomeId);
      }

      return true;
    } catch (e) {
      const { errors, message } = e as ApiError<UpdateCaseOutcomeRequest>;

      showErrorMessage(errors?.caseId ?? errors?.outcomeId ?? message);

      name.setError(errors?.name);
      toggleOverview.setError(errors?.showOverview);
      toggleImpacts.setError(errors?.showImpacts);
      toggleTeam.setError(errors?.showTeam);
      toggleAnalysis.setError(errors?.showAnalysis);
      toggleInsights.setError(errors?.showInsights);
      toggleSolutions.setError(errors?.showSolutions);
      toggleRecommendations.setError(errors?.showRecommendations);
      toggleEvidence.setError(errors?.showEvidence);

      return false;
    }
  };

  return {
    name,
    toggleOverview,
    toggleImpacts,
    toggleTeam,
    toggleAnalysis,
    toggleInsights,
    toggleSolutions,
    toggleRecommendations,
    toggleEvidence,
    isLoading,
    isBusy,
    canSubmit,
    submit,
  };
}

import { CompanyGroupUserResource } from '@api/types/company/company-group/company-group-user.resource';
import { ListItemRow } from '@components/containers/list-container';
import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import { getFirstName, getLastName } from '@util/name-util';
import { Typography } from '@mui/material';
import ColorBadge from '@components/badges/color-badge';
import { ElipsisButton } from '@components/buttons';

interface Props {
  member: CompanyGroupUserResource;
  isAdmin?: boolean;
}

export default function GroupMemberRow({ member, isAdmin }: Props) {
  const { name, jobTitle, kudosLevel, kudosPoints } = member;
  const firstName = getFirstName(name);
  const lastName = getLastName(name);

  return (
    <ListItemRow>
      <InitialsContainerWithData
        label={name}
        firstName={firstName}
        lastName={lastName}
        subLabel={jobTitle}
      />
      <Typography variant="caption">{`Level ${kudosLevel ?? 0} (${
        kudosPoints ?? 0
      }xp)`}</Typography>
      {isAdmin ? <ColorBadge>Admin</ColorBadge> : null}
      <ElipsisButton actions={[]} />
    </ListItemRow>
  );
}

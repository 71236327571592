import {
  ComponentType,
  createContext,
  PropsWithChildren,
  useCallback,
  useRef,
  useState,
} from 'react';
import { UiPopupProps, UiPopupType } from '@components/ui-popup/ui-popup-type';
import useCreateContextHook from '@util/create-context-hook-util';
import Completer from 'async-completer';
import { v4 as uuid } from 'uuid';
import ConfirmationModal, {
  ConfirmationModalProps,
} from '@components/ui-popup/modals/confirmation-modal';
import { OkModal, OkModalProps } from '@components/ui-popup/modals';
import DeleteConfirmationModal, {
  DeleteConfirmationModalProps,
} from '@components/ui-popup/modals/delete-confirmation-modal';

export type ShowPopupFn = <P extends UiPopupProps<T>, T = any>(
  RenderComponent: ComponentType<P>,
  props?: Omit<P, 'show' | 'onClose'>
) => Promise<T | undefined>;

interface UiPopupContextState {
  showPopup: ShowPopupFn;
}

const UiPopupContext = createContext<UiPopupContextState | undefined>(
  undefined
);

export default function UiPopupProvider({ children }: PropsWithChildren<{}>) {
  const [popups, setPopups] = useState<Array<UiPopupType<any>>>([]);

  const showPopup = useCallback<ShowPopupFn>((RenderComponent, props) => {
    const completer = new Completer<any>();
    const id = uuid();

    const item: UiPopupType<any> = {
      id,
      completer,
      RenderComponent,
      props: props as any,
      show: false,
    };

    setPopups((v) => [...v, item]);

    setTimeout(() => {
      setPopups((v) =>
        v.map((item) => {
          let { show } = item;
          if (item.id === id) {
            show = true;
          }

          return {
            ...item,
            show,
          };
        })
      );
    }, 10);

    return completer.promise;
  }, []);

  const closePopup = (id: string) => {
    setPopups((v) =>
      v.map((item) => {
        let { show } = item;
        if (item.id === id) {
          show = false;
        }
        return {
          ...item,
          show,
        };
      })
    );

    setTimeout(() => {
      setPopups((v) => v.filter((x) => x.id !== id));
    }, 2000);
  };

  return (
    <UiPopupContext.Provider value={{ showPopup }}>
      {children}
      {popups.map(({ RenderComponent, completer, props, id, show }) => (
        <RenderComponent
          key={id}
          show={show}
          onClose={(v: any) => {
            completer.complete(v);
            closePopup(id);
          }}
          {...props}
        />
      ))}
    </UiPopupContext.Provider>
  );
}

export const useUiPopup = () => {
  const isShowingSingle = useRef(false);

  const { showPopup } = useCreateContextHook(UiPopupContext);

  const showConfirmationModal = useCallback(
    (props: Omit<ConfirmationModalProps, 'show' | 'onClose'>) => {
      return showPopup<ConfirmationModalProps, boolean>(
        ConfirmationModal,
        props
      );
    },
    [showPopup]
  );

  const showDeleteConfirmationModal = useCallback(
    (props: Omit<DeleteConfirmationModalProps, 'show' | 'onClose'>) => {
      return showPopup<DeleteConfirmationModalProps, boolean>(
        DeleteConfirmationModal,
        props
      );
    },
    [showPopup]
  );

  const showSingleActionModal = useCallback(
    (props: Omit<OkModalProps, 'show' | 'onClose'>) => {
      return showPopup<OkModalProps, boolean>(OkModal, props);
    },
    [showPopup]
  );

  const showSingle = useCallback((callback: () => Promise<any>) => {
    if (isShowingSingle.current) {
      return;
    }

    isShowingSingle.current = true;
    return callback().finally(() => (isShowingSingle.current = false));
  }, []);

  return {
    showPopup,
    showConfirmationModal,
    showDeleteConfirmationModal,
    showSingleActionModal,
    showSingle,
    isShowingSingle,
  };
};

import { StepLayout } from '@pages/app/rca/create/components/step-layout';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import { Box, Typography } from '@mui/material';
import Spacer from '@components/layout-util-components/spacer';
import { Fragment } from 'react';
import { LoadingIndicator } from '@components/loading-indicator';
import useImpactStep, {
  CreateDetailViewState,
} from '@pages/app/rca/create/steps/impact-step-hook';
import { ReactComponent as NoImpactIcon } from '@assets/svgs/no-impact-icon.svg';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';
import ImpactInfoForm from '@pages/app/rca/create/components/impact-info-form';
import { v4 as uuid } from 'uuid';
import Row from '@components/layout-util-components/row';
import ImpactInfoRow from '@pages/app/rca/create/components/impact-info-row';
import SpacedTable from '@components/table/spaced-table';
import { useAppSelector } from '@store/store';
import { selectStepTitle } from '@store/create-analysis-process/selectors';
import { ImpactInfoModel } from '@pages/app/rca/create/steps/impact-step-models';
import ImpactStatsSummary from '@pages/app/rca/create/components/impact-stats-summary';
import { RcaStepProps } from '@pages/app/rca/create/rca-creation-steps-page';
import useDirtyGuard from '@hooks/use-dirty-guard';
import { Gap } from '@components/layout-util-components/gap';

export default function ImpactStep({
  onComplete,
  onGoBack,
  caseId,
}: RcaStepProps) {
  const { confirmDirty } = useDirtyGuard();
  const title = useAppSelector((state) => selectStepTitle(state, 'Details'));

  const state = useImpactStep(caseId!);
  const {
    viewState,
    startEditing,
    pushNew,
    remove,
    currentModel,
    addOrEditIndex,
    isLoading,
    canSubmit,
    isBusy,
    impactModels,
    actualImpacts,
    potentialImpacts,
    statData,
    isEdit,
    caseData,
    systemText,
  } = state;

  const renderModel = (model: ImpactInfoModel) => {
    const key = model.impactId ?? uuid();
    const index = impactModels.indexOf(model);
    if (viewState === CreateDetailViewState.edit && index === addOrEditIndex) {
      return (
        <tr className="full-row" key={key}>
          <td colSpan={7}>
            <ImpactInfoForm model={model} createDetailsState={state} />
          </td>
        </tr>
      );
    }

    if (viewState === CreateDetailViewState.add && index === addOrEditIndex) {
      return <Fragment key={key} />;
    }

    return (
      <ImpactInfoRow
        key={key}
        info={model}
        caseId={caseId!}
        onEditClick={() => startEditing(index)}
        onDeleteClick={() => remove(index)}
      />
    );
  };

  return (
    <StepLayout
      actionsRow={
        <>
          <ActionsRow>
            <OutlinedButton onClick={confirmDirty(onGoBack)}>
              Back
            </OutlinedButton>

            <Spacer />
            {!isEdit && (
              <OutlinedButton
                onClick={confirmDirty(onComplete)}
                disabled={!canSubmit}
              >
                Skip
              </OutlinedButton>
            )}
            <PrimaryButton
              disabled={!canSubmit}
              isBusy={isBusy}
              type="submit"
              onClick={onComplete}
            >
              {isEdit ? 'Update' : 'Continue'}
            </PrimaryButton>
          </ActionsRow>
        </>
      }
    >
      <Row>
        <Typography variant="h5">{title}</Typography>
        <Spacer />
        {viewState === CreateDetailViewState.view ? (
          <OutlinedButton onClick={pushNew}>Add</OutlinedButton>
        ) : null}
      </Row>
      <Gap size={10} />
      {isLoading ? (
        <LoadingIndicator show />
      ) : (
        <>
          {caseData && <Typography variant="body1">{caseData.name}</Typography>}
          {viewState === CreateDetailViewState.empty ? (
            <>
              <Gap size={32} />
              <OnboardingEntryContainer
                icon={<NoImpactIcon />}
                title="You don’t currently have any impacts"
                message="Currently you haven’t added any forecasted impacts for this investigation"
                createMessage="Add Impact"
                onCreateClicked={pushNew}
              />
            </>
          ) : (
            <>
              <Gap size={32} />
              {viewState === CreateDetailViewState.add ? (
                <ImpactInfoForm
                  model={currentModel!}
                  createDetailsState={state}
                />
              ) : (
                <Box height={100} flexShrink={0} />
              )}
              <Gap size={32} />
              <Typography variant="h5">
                Actual Impacts ({actualImpacts.length})
              </Typography>
              <Gap size={15} />
              <SpacedTable>
                {actualImpacts.length > 1 ||
                (viewState === CreateDetailViewState.view &&
                  actualImpacts.length === 1) ? (
                  <thead>
                    <tr>
                      <td>Tracked</td>
                      <td>{systemText['RCA.Details.ImpactCategory']}</td>
                      <td>Type</td>
                      <td>Description</td>
                      <td>Severity</td>
                      <td>Actual Impact</td>
                      <td></td>
                    </tr>
                  </thead>
                ) : null}
                <tbody>{actualImpacts.map(renderModel)}</tbody>
              </SpacedTable>

              <Gap size={32} />
              <Typography variant="h5">
                Potential Impacts ({potentialImpacts.length})
              </Typography>
              <Gap size={15} />
              <SpacedTable>
                {potentialImpacts.length > 1 ||
                (viewState === CreateDetailViewState.view &&
                  potentialImpacts.length === 1) ? (
                  <thead>
                    <tr>
                      <td>Tracked</td>
                      <td>{systemText['RCA.Details.ImpactCategory']}</td>
                      <td>Type</td>
                      <td>Description</td>
                      <td>Severity</td>
                      <td>Forecasted</td>
                      <td></td>
                    </tr>
                  </thead>
                ) : null}
                <tbody>{potentialImpacts.map(renderModel)}</tbody>
              </SpacedTable>
            </>
          )}

          {(statData?.rows?.length ?? 0) > 0 ? (
            <>
              <Gap size={32} />
              <ImpactStatsSummary stats={statData!} />
            </>
          ) : null}
        </>
      )}
    </StepLayout>
  );
}

import { Grid, Typography } from '@mui/material';
import { PrimaryButton } from '@components/buttons';
import WCTTextField from '@components/input/text-field';
import { useNavigate } from 'react-router-dom';
import useResetPassword from '@pages/auth/reset-password-hook';
import { LoginPageLocationState } from '@pages/auth/login-page';
import { useAuthTr } from '@i18n/use-auth-tr';
import PasswordCriteria from '@pages/auth/components/password-criteria';
import ContactSupportLink from '@pages/auth/components/contact-support-link';
import useSearchParamsTyped from '@hooks/router-dom-helper-hooks';
import { PageAlertDisplay } from '@components/alerts';
import { Gap } from '@components/layout-util-components/gap';

type QueryParameters = {
  token: string;
  uid: string;
};

export default function ResetPasswordPage() {
  const { password, confirmPassword, canSubmit, isLoading, submit } =
    useResetPassword();

  const { t } = useAuthTr('resetPassword');

  const { token, uid } = useSearchParamsTyped<QueryParameters>();
  const navigate = useNavigate();

  const onSubmit = async () => {
    if (await submit(token!, uid!)) {
      const routeState: LoginPageLocationState = {
        prompt: 'password-reset-success',
      };

      navigate('../login', { replace: true, state: routeState });
    }
  };

  return (
    <Grid container direction="column" gap={2} justifyContent="center">
      <Typography variant="h4">{t('title')}</Typography>
      <Typography variant="body1">{t('message')}</Typography>
      <Gap size={4} />
      <PageAlertDisplay />
      <WCTTextField
        label={t('form.password')}
        value={password.value}
        error={password.error}
        onChange={password.set}
        required
        obscure
      />

      <PasswordCriteria password={password.value} />

      <WCTTextField
        label={t('form.confirmPassword.label')}
        value={confirmPassword.value}
        error={confirmPassword.error}
        onChange={confirmPassword.set}
        required
        obscure
      />
      <PrimaryButton
        onClick={onSubmit}
        disabled={!canSubmit}
        isBusy={isLoading}
      >
        {t('buttonLabel')}
      </PrimaryButton>
      <ContactSupportLink />
    </Grid>
  );
}

import { Editor } from '@tinymce/tinymce-react';
import { useRef, useState } from 'react';
import { isNullOrEmpty } from '@util/string-util';
import { FormLabel, styled } from '@mui/material';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  id?: string;
  name: string;
  placeholder?: string;
  value?: string;
  error?: string;
  onChange: (value?: string) => void;
  onBlur?: () => void;
}

const Container = styled('div', { target: 'wct-rich-text-field' })({
  flex: '1 1 auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
});

export default function WCTRichTextField({
  id,
  name,
  placeholder,
  value,
  error,
  onChange,
  onBlur,
}: Props) {
  const editorRef = useRef<Editor>();
  const [initialValue] = useState(value);

  return (
    <Container>
      <Editor
        id={id}
        textareaName={name}
        onInit={(e, ref) => (editorRef.current = ref as any)}
        initialValue={initialValue}
        onChange={(e) => onChange(e.target.getContent())}
        onBlur={onBlur}
        init={{
          min_height: 220,
          menubar: false,
          placeholder,
        }}
        toolbar={
          'formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat'
        }
      />
      {!isNullOrEmpty(error) ? (
        <>
          <Gap size={10} />
          <FormLabel error>{error}</FormLabel>
        </>
      ) : null}
    </Container>
  );
}

import { api } from '@api/api';
import { LoginRequest } from '@api/types/auth/login.request';
import { makeError } from '@api/types/api-error';
import { MfaCodeRequest } from '@api/types/auth/mfa-code.request';
import { MfaOptionsResponse } from '@api/types/auth/mfa-options.response';
import { MfaSubmitCodeRequest } from '@api/types/auth/mfa-submit-code.request';
import { RequestMfaOptInCodeRequest } from '@api/types/auth/request-mfa-opt-in-code.request';
import { refreshUserState } from '@store/user/user-slice';
import { OauthLoginRequest } from '@api/types/auth/oauth-login.request';

const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<void, LoginRequest>({
      query: (body) => ({
        url: 'auth/login',
        method: 'POST',
        body,
      }),
      onCacheEntryAdded: async (_, { cacheDataLoaded, dispatch }) => {
        await cacheDataLoaded;
        dispatch(refreshUserState());
      },
      transformErrorResponse: (error) => makeError<LoginRequest>(error),
    }),
    oauthLogin: build.mutation<void, OauthLoginRequest>({
      query: (body) => ({
        url: 'auth/oauth/login',
        method: 'POST',
        body,
      }),
      onCacheEntryAdded: async (_, { cacheDataLoaded, dispatch }) => {
        await cacheDataLoaded;
        dispatch(refreshUserState());
      },
      transformErrorResponse: (error) => makeError<OauthLoginRequest>(error),
    }),
    logout: build.mutation<void, void>({
      query: () => ({
        url: 'auth/logout',
        method: 'POST',
      }),
      onCacheEntryAdded: async (_, { cacheDataLoaded, dispatch }) => {
        await cacheDataLoaded;
        dispatch(refreshUserState());
      },
      transformErrorResponse: (error) => makeError<never>(error),
    }),
    getMfaOptions: build.query<MfaOptionsResponse, void>({
      query: () => ({
        url: 'auth/twofactoroptions',
      }),
    }),
    request2FACode: build.query<void, MfaCodeRequest>({
      query: (body) => ({
        url: 'auth/requesttwofactorcode',
        method: 'POST',
        body,
      }),
    }),
    loginWith2FACode: build.mutation<void, MfaSubmitCodeRequest>({
      query: (body) => ({
        url: 'auth/loginwithtwofactorcode',
        method: 'POST',
        body,
      }),
      onCacheEntryAdded: async (_, { cacheDataLoaded, dispatch }) => {
        await cacheDataLoaded;
        dispatch(refreshUserState());
      },
      transformErrorResponse: (error) => makeError<MfaSubmitCodeRequest>(error),
    }),
    request2FAOptInCode: build.query<void, RequestMfaOptInCodeRequest>({
      query: (body) => ({
        url: 'auth/requestTwoFAOptInCode',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<RequestMfaOptInCodeRequest>(error),
    }),
  }),
});

export const {
  useLoginMutation,
  useOauthLoginMutation,
  useLogoutMutation,
  useLoginWith2FACodeMutation,
  useGetMfaOptionsQuery,
  useLazyRequest2FACodeQuery,
  useLazyRequest2FAOptInCodeQuery,
} = authApi;

import {
  Box,
  Button,
  Drawer,
  DrawerProps,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faClose } from '@fortawesome/pro-light-svg-icons';
import useHelpCentre from '@pages/app/help-centre/help-centre-hook';
import { useNavigate } from 'react-router-dom';
import { HelpCenterFAQs } from '@pages/app/help-centre/components/help-centre-faqs';
import { Videos } from '@pages/app/help-centre/components/videos';
import { Categories } from '@pages/app/help-centre/components/categories';
import { SearchBar } from '@pages/app/help-centre/components/search-bar';
import { Articles } from '@pages/app/help-centre/components/articles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const StyledDrawer = styled(Drawer, {
  target: 'health-score-side-panel',
})<DrawerProps>(({ theme: { palette } }) => ({
  '.MuiPaper-root': {
    background: palette.common.white,
    padding: 20,
    overflowY: 'auto',
    top: 89,
    display: 'flex',
    minWidth: '477px',
    flexDirection: 'column',
    gap: 20,
    borderRadius: 8,
  },
}));

const StyledHelpButton = styled(Button)(({ theme: { palette } }) => ({
  borderRadius: 17,
  background: palette.common.grey15,
  display: 'flex',
  alignItems: 'center',
  color: palette.common.black,
  fontSize: '15px',
  lineHeight: '133.4%',
}));

const StyledHeaderBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 25,
}));

const StyledIconButton = styled(IconButton)(({ theme: { palette } }) => ({
  display: 'flex',
  width: 32.5,
  height: 32.5,
  borderRadius: 43,
  background: palette.common.grey17,
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: 'auto',
}));

export default function RCAHelpPanel({ isOpen, onClose }: Props) {
  const state = useHelpCentre();
  const navigate = useNavigate();

  const {
    search,
    searchResult,
    videoContent,
    faqContent,
    articleContent,
    helpCentreCategoryOptions,
    selectedCategories,
    toggleCategory,
  } = state;

  return (
    <StyledDrawer open={isOpen} onClose={onClose} anchor="right" hideBackdrop>
      <StyledHeaderBox>
        <Typography variant="h2">RCA Help</Typography>
        <StyledHelpButton onClick={() => navigate('/help-centre')}>
          Help Centre <FontAwesomeIcon width={31} icon={faChevronRight} />
        </StyledHelpButton>
        <StyledIconButton onClick={onClose}>
          <FontAwesomeIcon icon={faClose} />
        </StyledIconButton>
      </StyledHeaderBox>
      <SearchBar search={search} searchResult={searchResult} />
      <Categories
        categories={helpCentreCategoryOptions}
        onClick={toggleCategory}
        selected={selectedCategories}
      />
      <Videos
        label="Related Videos"
        showAll={false}
        videos={videoContent ?? []}
        direction="row"
      />
      <Articles
        label="Related Articles"
        showAll={false}
        articles={articleContent ?? []}
        direction="row"
      />
      <HelpCenterFAQs
        label="Related FAQ's"
        showAll={false}
        faqs={faqContent ?? []}
      />
    </StyledDrawer>
  );
}

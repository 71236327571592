import {
  selectDateOnlyFormatLong,
  selectDateOnlyFormatShort,
  selectDatetimeLongFormat,
  selectDatetimeShortFormat,
  selectLocale,
  selectTimeOnlyFormatShort,
} from '@store/locale/locale-selector';
import { store } from '@store/store';
import { getDate } from '@util/date-util';
import i18n from 'i18next';

export function formatDateCustom(value: any, format: string) {
  const date = getDate(value);
  if (date == null) return value;

  return date.format(format);
}

export function datetimeLongFormatter(value: any) {
  const format = selectDatetimeLongFormat(store.getState());

  return formatDateCustom(value, format);
}

export function datetimeShortFormatter(value: any) {
  const format = selectDatetimeShortFormat(store.getState());

  return formatDateCustom(value, format);
}

export function dateOnlyLongFormatter(value: any) {
  const format = selectDateOnlyFormatLong(store.getState());

  return formatDateCustom(value, format);
}

export function dateOnlyShortFormatter(value: any) {
  const format = selectDateOnlyFormatShort(store.getState());

  return formatDateCustom(value, format);
}

export function timeOnlyShortFormatter(value: any) {
  const format = selectTimeOnlyFormatShort(store.getState());

  return formatDateCustom(value, format);
}

export function timespanFormatter(value: any) {
  const date = getDate(value);
  if (date == null) return value;

  const locale = selectLocale(store.getState());
  const dayJsDate = date.locale(locale);
  return dayJsDate.fromNow();
}

export function currencyFormatter(
  value: any,
  opt = { decimalPlaces: 0, currency: 'GBP' }
) {
  const sanitisedValue = value ?? 0;

  const locale = selectLocale(store.getState());
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: opt.currency,
    minimumFractionDigits: opt.decimalPlaces,
  });
  return formatter.format(sanitisedValue);
}

export function numberFormatter(value: any, opt = { decimalPlaces: 0 }) {
  const sanitisedValue = value ?? 0;

  const locale = selectLocale(store.getState());
  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: opt.decimalPlaces,
  });
  return formatter.format(sanitisedValue);
}

export function resetFormatters() {
  const formatter = i18n.services.formatter!;
  formatter.add('datetime', datetimeLongFormatter);
  formatter.add('datetimeShort', datetimeShortFormatter);
  formatter.add('date', dateOnlyLongFormatter);
  formatter.add('dateShort', dateOnlyShortFormatter);
  formatter.add('timespan', timespanFormatter);
}

import { api } from '@api/api';
import { ChainDetailResponse } from '@api/types/chain/chain-detail.response';
import { ChainUpdateRequest } from '@api/types/chain/chain-update.request';
import { makeError } from '@api/types/api-error';
import { PaginatedResult } from '@api/types/paginated-result';
import { TaskDetailListItemResponse } from '@api/types/task-detail/task-detail-list-item.response';
import { TaskDetailListRequest } from '@api/types/task-detail/task-detail-list.request';
import { ChainHealthScoreLookupResponse } from '@api/types/chain/chain-health-score-lookup.response';
import { RcaEdgeType, RcaNodeType } from '@store/rca-editor/types';
import { setChartFromServerData } from '@store/rca-editor/rca-editor-slice';
import { parseChain } from '@util/node-parsing-util';

const transformUpdateRequest = (
  body: ChainUpdateRequest
): ChainUpdateRequest => {
  // Need to make sure we don't parse non-saveable node types
  return {
    edges: body.edges.filter((x) => x.type !== RcaEdgeType.connection),
    nodes: body.nodes.filter((x) => x.type !== RcaNodeType.connection),
    moveToStorageChainItemIds: body.moveToStorageChainItemIds,
  };
};

const chainApi = api.injectEndpoints({
  endpoints: (build) => ({
    getChainDetail: build.query<ChainDetailResponse, number>({
      query: (chainId) => ({
        url: `chain/${chainId}`,
        method: 'GET',
      }),
      transformResponse: parseChain,
    }),
    getChainHealthScores: build.query<ChainHealthScoreLookupResponse, number>({
      query: (chainId) => ({
        url: `chain/${chainId}/healthScores`,
        method: 'GET',
      }),
      providesTags: ['CaseTotals'],
    }),
    updateChain: build.mutation<
      ChainDetailResponse,
      ChainUpdateRequest & { chainId: number }
    >({
      query: ({ chainId, ...body }) => ({
        url: `chain/${chainId}/updateGraph`,
        method: 'POST',
        body: transformUpdateRequest(body),
      }),
      invalidatesTags: ['CaseTotals'],
      transformErrorResponse: (error) => makeError<ChainUpdateRequest>(error),
      transformResponse: parseChain,
      onQueryStarted: async ({ chainId }, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(setChartFromServerData(data));
        dispatch(
          chainApi.util.updateQueryData('getChainDetail', chainId, (draft) => {
            Object.assign(draft, data);
          })
        );
      },
    }),
    getTasksForChainItem: build.query<
      PaginatedResult<TaskDetailListItemResponse>,
      TaskDetailListRequest & { chainId: number; chainItemId: number }
    >({
      query: ({ chainId, chainItemId, ...params }) => ({
        url: `chain/${chainId}/chainItem/${chainItemId}/taskDetail`,
        method: 'GET',
        params,
      }),
      providesTags: (response) =>
        response
          ? [
              ...response!.model.map((data) => ({
                id: data.taskDetailId,
                type: 'TaskDetail' as const,
              })),
              'TaskDetail',
            ]
          : ['TaskDetail'],
    }),
  }),
});

export const {
  useGetChainDetailQuery,
  useLazyGetChainDetailQuery,
  useGetTasksForChainItemQuery,
  useGetChainHealthScoresQuery,
} = chainApi;

export default chainApi;

import { StepLayout } from '@pages/app/rca/create/components/step-layout';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import { Box, styled, Typography } from '@mui/material';
import Spacer from '@components/layout-util-components/spacer';
import useCollaboration, {
  CollaborationViewMode,
} from '@pages/app/rca/create/steps/collaboration-step-hook';
import CollaborateForm from '@pages/app/rca/create/components/collaborate-form';
import CollaborateUsers from '@pages/app/rca/create/components/collaborate-users';
import { useAppSelector } from '@store/store';
import { selectStepTitle } from '@store/create-analysis-process/selectors';
import { RcaStepProps } from '@pages/app/rca/create/rca-creation-steps-page';
import useDirtyGuard from '@hooks/use-dirty-guard';
import Row from '@components/layout-util-components/row';
import Column from '@components/layout-util-components/column';
import { ReactComponent as LockSvg } from '@assets/svgs/lock.svg';
import { ReactComponent as UsersSvg } from '@assets/svgs/users.svg';
import { LoadingIndicator } from '@components/loading-indicator';
import { Gap } from '@components/layout-util-components/gap';

const OverviewActions = styled('div', {
  target: 'collaboration-step-overview-actions',
})(({ theme: { palette } }) => ({
  display: 'flex',
  minHeight: 60,
  background: '#E9E9E9',

  '.divider': {
    border: '1px solid #67676780',
    marginLeft: 30,
  },

  '.item': {
    padding: 10,

    '.icon': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 40,
      height: 40,
      background: '#CDCDCD',
      marginRight: 20,
      padding: '0 5px',

      svg: {
        width: 40,
        height: 'auto',
        maxHeight: 30,
        stroke: '#00000080',
      },
    },

    '.title': {
      color: palette.common.black,
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.17000000178813934px',
      textAlign: 'left',
    },

    '.text': {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.4000000059604645px',
      textAlign: 'left',
    },
  },
}));

export default function CollaborationStep({
  caseId,
  onGoBack,
  onComplete,
}: RcaStepProps) {
  const { confirmDirty } = useDirtyGuard();

  const title = useAppSelector((state) =>
    selectStepTitle(state, 'Collaborate')
  );

  const state = useCollaboration(caseId!);
  const {
    isEdit,
    viewMode,
    onSyncGroups,
    isBusy,
    isLoading,
    contributors,
    invitedGroups,
    isPublicCase,
    canSubmit,
    setViewMode,
    shouldCollaborate,
    onCompleteCase,
  } = state;

  const onNext = async () => {
    const doCompleteLogic = async () => {
      if (!isEdit) {
        const didComplete = await onCompleteCase();
        if (didComplete) {
          onComplete();
        }
      }
    };

    if (viewMode === CollaborationViewMode.form) {
      const didSync = await onSyncGroups();
      if (didSync) {
        if (shouldCollaborate.value) {
          setViewMode(CollaborationViewMode.users);
        } else {
          await doCompleteLogic();
        }
      }
    } else {
      await doCompleteLogic();
    }
  };

  return (
    <StepLayout
      actionsRow={
        <>
          <ActionsRow>
            {
              <OutlinedButton onClick={confirmDirty(onGoBack)}>
                Back
              </OutlinedButton>
            }
            <Spacer />
            <PrimaryButton
              disabled={!canSubmit}
              isBusy={isBusy}
              onClick={onNext}
            >
              {isEdit ? 'Update' : 'Continue'}
            </PrimaryButton>
          </ActionsRow>
        </>
      }
    >
      <Typography variant="h5">{title}</Typography>
      <Gap size={10} />
      {isLoading ? (
        <LoadingIndicator show />
      ) : viewMode === CollaborationViewMode.form ? (
        <CollaborateForm state={state} />
      ) : (
        <>
          <OverviewActions>
            <Row className="item">
              <div className="icon">
                <LockSvg />
              </div>
              <Column>
                <div className="title">
                  {isPublicCase.value ? 'Public' : 'Private'} Investigation
                </div>
                <div className="text">Example sub text</div>
              </Column>
            </Row>
            <div className="divider" />
            <Row className="item">
              <div className="icon">
                <LockSvg />
              </div>
              <Column>
                <div className="title">
                  Viewing Groups
                  {(invitedGroups?.length ?? 0) > 0
                    ? ` (${invitedGroups!.length})`
                    : ''}
                </div>
                <div className="text">Example sub text</div>
              </Column>
            </Row>
            <div className="divider" />
            <Row className="item">
              <div className="icon">
                <UsersSvg />
              </div>
              <Column>
                <div className="title">
                  Contributors
                  {contributors != null
                    ? ` (${contributors!.model.length})`
                    : ''}
                </div>
                <div className="text">Example sub text</div>
              </Column>
            </Row>
            <Spacer />
            <Box py="9px" pr="15px">
              <OutlinedButton
                onClick={() => setViewMode(CollaborationViewMode.form)}
              >
                Edit
              </OutlinedButton>
            </Box>
          </OverviewActions>
          <Gap size={32} />
          <CollaborateUsers state={state} />
        </>
      )}
    </StepLayout>
  );
}

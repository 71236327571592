import { ChainDetailResponse } from '@api/types/chain/chain-detail.response';
import {
  NodeEndStateType,
  RcaEdgeType,
  RcaNodeType,
} from '@store/rca-editor/types';
import { v4 as uuid } from 'uuid';
import { layoutNodes } from '@store/rca-editor/rca-editor-actions';

export function parseChain(chain: ChainDetailResponse): ChainDetailResponse {
  let newNodes = [...chain.nodes];
  const newEdges = [...chain.edges];

  for (const node of chain.nodes) {
    const nodeData = node.data!;
    // Need to add linked items to the graph
    if (
      nodeData.endState === NodeEndStateType.linkToChainItem &&
      !!nodeData.linkedToChainItems?.length
    ) {
      for (const linkedItem of nodeData.linkedToChainItems) {
        const linkedNode = chain.nodes.find(
          (x) => x.data?.chainItemId === linkedItem.id
        );

        if (linkedNode) {
          const id = uuid();

          newNodes.push({
            ...linkedNode,
            id,
            type: RcaNodeType.connection,
          });

          newEdges.push({
            id: `connection-edge-${id}`,
            source: node.id,
            target: id,
            type: RcaEdgeType.connection,
          });
        }
      }
    }
  }

  // Pass through nodes and set types according to data
  newNodes = newNodes.map((nodeValue) => {
    let node = nodeValue;
    let data = node.data!;
    const { endStateId } = data;

    if (endStateId != null) {
      node.type = RcaNodeType.endState;
    }

    return { ...node };
  });

  const laidOutNodes = layoutNodes(newNodes, newEdges);

  return {
    ...chain,
    nodes: laidOutNodes,
    edges: newEdges,
    storage: chain.storage.map((storageNode) => ({
      ...storageNode,
      clientUuid: uuid(),
    })),
  };
}

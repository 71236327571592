import { TableCell, TableRow } from '@mui/material';
import { dateOnlyShortFormatter, timespanFormatter } from '@i18n/formatters';
import ColorBadge from '@components/badges/color-badge';
import OutlinedBadge from '@components/badges/outlined-badge';
import { ElipsisButton } from '@components/buttons';
import FilledBadge from '@components/badges/filled-badge';
import HealthBadge from '@components/badges/health-badge';
import GroupedAvatars from '@components/containers/grouped-avatars';
import { CaseItemRowResponse } from '@api/types/case/case-item-row.response';
import { useMemo } from 'react';
import { getAddressString } from '@util/string-util';
import { useCaseElipsisOptions } from '@pages/app/analysis/components/case-elipsis-options';
import { getInitials } from '@util/name-util';

interface Props {
  data: CaseItemRowResponse;
}
export default function RcaTableRow({ data }: Props) {
  const elipsisOptions = useCaseElipsisOptions(data);
  const incidentLocation = useMemo(() => {
    const location = data.incidentLocation;
    if (location == null) {
      return;
    }

    return getAddressString({ ...location }) || location.name;
  }, [data.incidentLocation]);

  return (
    <TableRow>
      <TableCell>{data.name}</TableCell>
      <TableCell>
        <FilledBadge>{data.caseAnalysisTypeShortName} </FilledBadge>
      </TableCell>
      <TableCell>
        {
          <>
            <div>{dateOnlyShortFormatter(data.created)}</div>
            <div>{timespanFormatter(data.created)}</div>
          </>
        }
      </TableCell>
      <TableCell>
        {data.incidentLocation != null ? incidentLocation : null}
      </TableCell>
      <TableCell>
        <ColorBadge>{data.caseSeverityName}</ColorBadge>
      </TableCell>
      <TableCell>
        <GroupedAvatars
          items={data.companyUsers.map(({ companyUserId, name }) => ({
            id: companyUserId,
            label: getInitials(name),
          }))}
        />
      </TableCell>
      <TableCell>
        <OutlinedBadge>{data.caseStatusName}</OutlinedBadge>
      </TableCell>
      <TableCell>
        <HealthBadge health={data.healthScore} />
      </TableCell>
      <TableCell>
        <ElipsisButton actions={elipsisOptions} />
      </TableCell>
    </TableRow>
  );
}

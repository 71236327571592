import { api } from '@api/api';
import { PaginatedResult } from '@api/types/paginated-result';
import { CompanyGroupListItemResponse } from '@api/types/company-group/company-group-list-item.response';
import { CompanyGroupListRequest } from '@api/types/company-group/company-group-list.request';
import { makeError } from '@api/types/api-error';
import {
  CreateCompanyGroupRequest,
  UpdateCompanyGroupRequest,
} from '@api/types/company-group/create-company-group.request';
import { CompanyGroupResource } from '@api/types/company/company-group/company-group.resource';
import { Option } from '@api/types/option';
import { CompanyGroupOptionsFilter } from '@api/types/company/company-group/company-group-options-filter';

const companyCompanyGroupApi = api.injectEndpoints({
  endpoints: (build) => ({
    getGroupsForCompany: build.query<
      PaginatedResult<CompanyGroupListItemResponse>,
      CompanyGroupListRequest
    >({
      query: (params) => ({
        url: 'company/companygroup',
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.model.map(({ companyGroupId }) => ({
                type: 'CompanyGroup' as const,
                id: companyGroupId,
              })),
              'CompanyGroup',
            ]
          : ['CompanyGroup'],
    }),
    getCompanyGroupDetail: build.query<CompanyGroupResource, number>({
      query: (id) => ({
        url: `company/companygroup/${id}`,
      }),
      providesTags: (result) =>
        result ? [{ id: result.companyGroupId, type: 'CompanyGroup' }] : [],
    }),
    createCompanyGroup: build.mutation<
      CompanyGroupResource,
      CreateCompanyGroupRequest
    >({
      query: (body) => ({
        url: 'company/companygroup',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<CreateCompanyGroupRequest>(error),
      invalidatesTags: ['CompanyGroup'],
    }),
    updateCompanyGroup: build.mutation<
      CompanyGroupResource,
      UpdateCompanyGroupRequest
    >({
      query: (body) => ({
        url: 'company/companygroup',
        method: 'PUT',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<UpdateCompanyGroupRequest>(error),
      invalidatesTags: (result) =>
        result
          ? [
              { id: result.companyGroupId, type: 'CompanyGroup' },
              'CompanyGroup',
            ]
          : ['CompanyGroup'],
    }),
    getCompanyGroupOptions: build.query<Option[], CompanyGroupOptionsFilter>({
      query: (params) => ({
        url: 'company/companyGroup/options',
        params,
      }),
      providesTags: ['CompanyGroup'],
    }),
    deleteCompanyGroup: build.mutation<void, number>({
      query: (groupId) => ({
        url: `company/companyGroup/${groupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) =>
        result
          ? ['CompanyGroup', { id: arg, type: 'CompanyGroup' }]
          : ['CompanyGroup'],
      transformErrorResponse: (error) => makeError(error),
    }),
  }),
});

export const {
  useGetGroupsForCompanyQuery,
  useCreateCompanyGroupMutation,
  useUpdateCompanyGroupMutation,
  useGetCompanyGroupDetailQuery,
  useGetCompanyGroupOptionsQuery,
  useDeleteCompanyGroupMutation,
} = companyCompanyGroupApi;

import { api } from '@api/api';
import { makeError } from '@api/types/api-error';
import { Option } from '@api/types/option';
import { Skill } from '@api/types/skill/skill';

const skillApi = api.injectEndpoints({
  endpoints: (build) => ({
    // User Profile endpoints
    getSkillOptions: build.query<Option[], void>({
      query: () => ({
        url: 'Skill/Options',
      }),
    }),
    getSkillLinked: build.query<Option[], void>({
      query: () => ({
        url: 'Skill/Linked',
      }),
      providesTags: ['SkillLinked'],
    }),
    updateSkillsLinks: build.mutation<Option[], number[]>({
      query: (body) => ({
        url: 'Skill/Link',
        method: 'PUT',
        body: { skillIds: body },
      }),
      transformErrorResponse: (error) =>
        makeError<{
          skillId: number;
          name: string;
        }>(error),
      invalidatesTags: ['SkillLinked'],
    }),
    // Company profile endpoints
    getCompanySkillOptions: build.query<Array<Skill>, void>({
      query: () => ({
        url: 'Company/Skill',
      }),
      providesTags: ['CompanySkills'],
    }),
    getCompanySkill: build.query<Option[], number>({
      query: (skillId: number) => ({
        url: `Company/Skill/${skillId}`,
      }),
    }),
    deleteCompanySkill: build.mutation<void, number>({
      query: (skillId) => ({
        url: `Company/Skill/${skillId}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (error) => makeError<{ skillId: number }>(error),
      invalidatesTags: ['CompanySkills'],
    }),
    upsertCompanySkill: build.mutation<Option, Option>({
      query: ({ id: skillId, ...body }) => ({
        url: skillId != null ? `Company/Skill/${skillId}` : 'Company/Skill',
        method: skillId != null ? 'PUT' : 'POST',
        body,
      }),
      transformErrorResponse: (error) => makeError<Option>(error),
    }),
  }),
});

export const {
  // User
  useUpdateSkillsLinksMutation,
  useGetSkillLinkedQuery,
  useGetSkillOptionsQuery,
  // Company
  useDeleteCompanySkillMutation,
  useGetCompanySkillOptionsQuery,
  useGetCompanySkillQuery,
  useUpsertCompanySkillMutation,
} = skillApi;

import { styled } from '@mui/material';
import { ReactComponent as CauseBoxIcon } from '@assets/svgs/box-item.svg';

interface Props {
  count: number;
}

const Container = styled('div', { target: 'associated-cause-box-badge' })(
  ({ theme: { palette } }) => ({
    borderRadius: 4,
    background: palette.common.grey20,
    color: palette.common.black,
    padding: 4,
    fontFamily: 'Kyn, Roboto',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '14px',
    letterSpacing: '0em',
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    alignSelf: 'flex-start',

    svg: {
      height: 8,
      width: 'auto',
    },
  })
);

export default function AssociatedCauseBoxBadge({ count }: Props) {
  if (count === 0) return null;
  return (
    <Container>
      <CauseBoxIcon />
      <span>
        Associated to {count} Cause {count === 1 ? 'box' : 'boxes'}
      </span>
    </Container>
  );
}

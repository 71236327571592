import StatsContainer from '@components/containers/stats-container';

interface Props {
  show?: boolean;
}
export default function CompanyStats({ show }: Props) {
  return (
    <StatsContainer
      show={show}
      stats={[
        { title: 'Active', value: 55 },
        { title: 'Free Trial', value: 10 },
        { title: 'EXPIRING SOON', value: 10 },
        { title: 'Cancelled', value: 10 },
        { title: 'Lite', value: 10 },
        { title: 'Pro', value: 10 },
        { title: 'Enterprise', value: 10 },
      ]}
    />
  );
}

import { useGetLanguageOptionsQuery } from '@api/endpoints/language.api';
import { useGetTimezoneOptionsQuery } from '@api/endpoints/timezone.api';
import { useGetDateFormatOptionsQuery } from '@api/endpoints/dateformat.api';
import { useGetFrontendModeOptionsQuery } from '@api/endpoints/frontend-mode.api';
import { useMemo } from 'react';
import { Option } from '@api/types/option';
import { useAppTr } from '@i18n/use-app-tr';
import useSharedState from '@store/shared-state-hook';
import { useUpdateUserPreferencesMutation } from '@api/endpoints/user.api';
import { usePageAlertVariants } from '@components/alerts';
import { ApiError } from '@api/types/api-error';
import { UpdatePreferencesRequest } from '@api/types/user/update-preferences.request';
import useField from '@hooks/use-field-hook';
import { required } from '@util/validators';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { formatDateCustom } from '@i18n/formatters';
import { dateFormats } from '@store/locale/date-formats';

export default function useProfilePreferences() {
  const { t } = useAppTr('preferences');
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const { profile } = useSharedState();

  const { data: languageOptions, isLoading: loadingLanguages } =
    useGetLanguageOptionsQuery();

  const { data: dateFormatOptions, isLoading: loadingDateFormats } =
    useGetDateFormatOptionsQuery();

  const { data: timezoneOptions, isLoading: loadingTimezones } =
    useGetTimezoneOptionsQuery();

  const { data: themeOptions, isLoading: loadingThemeOptions } =
    useGetFrontendModeOptionsQuery();

  const [updateUserPreferences, { isLoading: isSubmitting }] =
    useUpdateUserPreferencesMutation();

  const timeDisplayOptions = useMemo(
    (): Option<boolean>[] => [
      { id: true, label: t('timeFormat.options.twentyFour') },
      { id: false, label: t('timeFormat.options.twelve') },
    ],
    [t]
  );

  const language = useField<number>([required()], profile!.languageId);
  const dateFormat = useField<number>([required()], profile!.dateFormatId);
  const timezone = useField<number>([required()], profile!.timezoneId);
  const twentyFourHourClock = useField<boolean>(
    [required()],
    profile!.use24HourTime
  );
  const theme = useField<number>([required()], profile!.frontEndModeId);

  const dateExample = useMemo(() => {
    if (dateFormat.value == null) {
      return '';
    }
    const fmt = dateFormats[dateFormat.value];
    if (fmt == null || fmt.long12 == null) {
      return '';
    }
    return formatDateCustom(new Date(), fmt.long12);
  }, [dateFormat.value]);

  const { isValid, isDirty, validateAll } = useFieldsWatcher([
    language,
    dateFormat,
    timezone,
    twentyFourHourClock,
    theme,
  ]);

  const isLoading =
    loadingLanguages ||
    loadingTimezones ||
    loadingDateFormats ||
    loadingThemeOptions;

  const canSubmit = !isLoading && !isSubmitting && isValid && isDirty;

  const submitPreferences = async (): Promise<boolean> => {
    if (!validateAll()) {
      return false;
    }

    return updateUserPreferences({
      frontEndModeId: theme.value,
      dateFormatId: dateFormat.value,
      timezoneId: timezone.value,
      use24HourTime: twentyFourHourClock.value,
      languageId: language.value,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage(t('toasts.success'));
        return true;
      })
      .catch(({ message, errors }: ApiError<UpdatePreferencesRequest>) => {
        showErrorMessage(message);

        theme.setError(errors?.frontEndModeId);
        dateFormat.setError(errors?.dateFormatId);
        timezone.setError(errors?.timezoneId);
        twentyFourHourClock.setError(errors?.use24HourTime);
        language.setError(errors?.languageId);

        return false;
      });
  };

  return {
    languageOptions,
    timezoneOptions,
    dateFormatOptions,
    timeDisplayOptions,
    themeOptions,
    language,
    dateFormat,
    timezone,
    twentyFourHourClock,
    theme,
    submitPreferences,
    isLoading,
    isSubmitting,
    canSubmit,
    dateExample,
  };
}

import Column from '@components/layout-util-components/column';
import { Link } from '@mui/material';
import { PrimaryButton } from '@components/buttons';
import App from 'src/app';

interface Props {
  goTo?: string;
}

export default function ErrorRouteCatcher({ goTo }: Props) {
  return (
    <App>
      <Column
        centerContent
        noStretch
        sx={{
          minWidth: 400,
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <h1>Error</h1>
        <p>Sorry, an error has occurred.</p>
        {goTo ? (
          <Link href={goTo}>{'Go back'}</Link>
        ) : (
          <PrimaryButton onClick={() => window.location.reload()}>
            Reload
          </PrimaryButton>
        )}
      </Column>
    </App>
  );
}

import { CollaborationState } from '@pages/app/rca/create/steps/collaboration-step-hook';
import { Grid, Typography } from '@mui/material';
import BinarySelectField from '@components/input/binary-select-field';
import WCTSelectField from '@components/input/select-field';
import { Divider } from '@components/layout-util-components/divider';
import { useBinaryOptions } from '@util/constant-options';
import Column from '@components/layout-util-components/column';
import { InputSkeleton } from '@components/skeletons';
import WarningAlert from '@components/alerts/warning-alert';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  state: CollaborationState;
}

export default function CollaborateForm({ state }: Props) {
  const { showConfirmationModal } = useUiPopup();
  const binaryOptions = useBinaryOptions({ reverse: true });

  const { groupOptions, isLoading, isPublicCase, shouldCollaborate, groups } =
    state;

  const onMaybeMakePublic = async (shouldMakePublic: boolean) => {
    if (shouldMakePublic) {
      const shouldContinue = await showConfirmationModal({
        title: 'Are you sure you want to make this investigation public?',
        message:
          'Please note all users within your company will be able to view this RCA and request to participate.',
        noButtonLabel: 'Cancel',
        yesButtonLabel: 'Continue',
      });

      if (shouldContinue == null || !shouldContinue) {
        return;
      }
    }

    isPublicCase.set(shouldMakePublic);
  };

  return (
    <Column>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            Would you like to make this RCA public to your company?
          </Typography>
          <Typography variant="body2">
            To allow your whole team to view the RCA make it public or
            alternatively you can make it private (invite only)
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <BinarySelectField
              name="isPublicCase"
              options={[
                { id: false, label: 'Invite Only Investigation' },
                { id: true, label: 'Public Investigation' },
              ]}
              value={isPublicCase.value}
              error={isPublicCase.error}
              onChange={onMaybeMakePublic}
            />
          )}
        </Grid>
      </Grid>
      {isPublicCase.value != null ? (
        <>
          <Gap size={32} />
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                Are you looking to share this RCA with groups?
              </Typography>
              <Typography variant="body2">
                To allow groups to view the RCA please add them here
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <WCTSelectField
                  id="groups"
                  name="groups"
                  options={groupOptions!}
                  value={groups.value}
                  error={groups.error}
                  onChange={groups.set}
                  onBlur={groups.validate}
                  multiple
                />
              )}

              {groups.value.length > 0 && !isLoading ? (
                <>
                  <Gap size={32} />
                  <WarningAlert
                    title="Access granted to the above groups"
                    message="All team members in above groups will have access"
                  />
                </>
              ) : null}
            </Grid>
          </Grid>
        </>
      ) : null}
      <Divider addMargin />
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            Are you looking to collaborate on this RCA?
          </Typography>
          <Typography variant="body2">Example sub text</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <BinarySelectField
              name="shouldCollaborate"
              options={binaryOptions}
              value={shouldCollaborate.value}
              error={shouldCollaborate.error}
              onChange={shouldCollaborate.set}
            />
          )}
        </Grid>
      </Grid>
    </Column>
  );
}

import { useLocation } from 'react-router-dom';
import { useResendEmailConfirmationMutation } from '@api/endpoints/user.api';
import { ApiError } from '@api/types/api-error';
import { ResendEmailConfirmationRequest } from '@api/types/user/resend-email-confirmation.request';
import { useAuthTr } from '@i18n/use-auth-tr';
import { usePageAlertVariants } from '@components/alerts';

export default function useConfirmEmailRequest() {
  const { t } = useAuthTr('confirmEmailRequest');
  const { state: email } = useLocation();
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();

  const [sendEmail, { isLoading }] = useResendEmailConfirmationMutation();

  const resendEmail = () => {
    sendEmail({
      emailAddress: email,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage(t('toasts.success'));
      })
      .catch((error: ApiError<ResendEmailConfirmationRequest>) => {
        showErrorMessage(error.message);
      });
  };

  return {
    email,
    resendEmail,
    isSending: isLoading,
  };
}

import { useEffect, useMemo, useRef, useState } from 'react';
import useField from '@hooks/use-field-hook';
import { required } from '@util/validators';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { useFilter } from '@components/table';
import {
  useCompleteCaseMutation,
  useGetCaseDetailQuery,
  useGetGroupOptionsForCaseQuery,
  useGetUsersForCaseQuery,
  useInviteUserToCaseMutation,
  useRemoveUserFromCaseMutation,
  useSyncGroupsForCaseMutation,
  useUpdateSyncedGroupsForCaseMutation,
  useUpdateUserCaseRoleMutation,
} from '@api/endpoints/case.api';
import { useGetCompanyGroupOptionsQuery } from '@api/endpoints/company/company-company-group.api';
import { SyncCaseGroupsRequest } from '@api/types/case/case-collaboration/sync-case-groups-request';
import { ApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';
import { useGetCaseRoleOptionsQuery } from '@api/endpoints/case-role.api';
import { CaseUserResource } from '@api/types/case/case-collaboration/case-user.resource';
import { InviteCaseUserRequest } from '@api/types/case/case-collaboration/invite-case-user.request';
import { UpdateSyncGroupsRequest } from '@api/types/case/case-collaboration/update-sync-groups.request';
import { useIsPath } from '@util/path-util';

export enum CollaborationViewMode {
  form,
  users,
}

export default function useCollaboration(caseId: number) {
  const isEdit = useIsPath('/rca/edit', { startsWith: true });
  const { showErrorMessage } = usePageAlertVariants();
  const [viewMode, setViewMode] = useState(CollaborationViewMode.form);

  const { data: caseDetail, isLoading: loadingCaseDetail } =
    useGetCaseDetailQuery(caseId);

  const { data: roleOptions, isLoading: loadingRoleOptions } =
    useGetCaseRoleOptionsQuery();

  const { data: groupOptions, isLoading: loadingGroupOptions } =
    useGetCompanyGroupOptionsQuery({});

  const { data: invitedGroups, isLoading: loadingInvitedGroups } =
    useGetGroupOptionsForCaseQuery(caseId);

  const contributorSearch = useFilter<string>();
  const {
    data: recomendedUsers,
    isLoading: loadingRecommendedUsers,
    isFetching: reloadingRecommendedUsers,
  } = useGetUsersForCaseQuery({
    caseId,
    searchText: contributorSearch.value,
    invitedOnly: false,
  });

  const {
    data: contributors,
    isLoading: loadingContributors,
    isFetching: reloadingContributors,
  } = useGetUsersForCaseQuery({
    caseId,
    searchText: contributorSearch.value,
    invitedOnly: true,
  });

  const [completeCase, { isLoading: isCompletingCase }] =
    useCompleteCaseMutation();

  const [syncGroups, { isLoading: isSyncingGroups }] =
    useSyncGroupsForCaseMutation();
  const [updateSyncGroups, { isLoading: isUpdatingGroups }] =
    useUpdateSyncedGroupsForCaseMutation();

  const [inviteUser, { isLoading: isInvitingUser }] =
    useInviteUserToCaseMutation();
  const [updateUserRole, { isLoading: isUpdatingUserRole }] =
    useUpdateUserCaseRoleMutation();
  const [removeUser, { isLoading: isRemovingUserFromCase }] =
    useRemoveUserFromCaseMutation();

  const isPublicCase = useField<boolean>(
    [required()],
    isEdit ? caseDetail?.isPublic : undefined
  );
  const groups = useField<Array<number>>(
    [required({ when: () => isPublicCase.value })],
    useMemo(
      () => caseDetail?.groups.map((group) => group.id) ?? [],
      [caseDetail?.groups]
    )
  );
  const shouldCollaborate = useField<boolean>(
    [required()],
    isEdit ? caseDetail?.hasCollaboration : undefined
  );

  const { isValid: isFormFieldsValid } = useFieldsWatcher([
    isPublicCase,
    groups,
    shouldCollaborate,
  ]);

  const isLoading =
    loadingInvitedGroups ||
    loadingGroupOptions ||
    loadingRecommendedUsers ||
    loadingContributors ||
    loadingRoleOptions ||
    loadingCaseDetail;

  const isBusy =
    isSyncingGroups ||
    reloadingRecommendedUsers ||
    reloadingContributors ||
    isInvitingUser ||
    isUpdatingUserRole ||
    isRemovingUserFromCase ||
    isCompletingCase ||
    isUpdatingGroups;

  const isValid =
    viewMode === CollaborationViewMode.form ? isFormFieldsValid : true;
  const canSubmit = !isLoading && !isBusy && isValid;

  const hasInit = useRef(false);
  useEffect(() => {
    if (caseDetail != null && !hasInit.current) {
      setViewMode(
        caseDetail!.hasCollaboration
          ? CollaborationViewMode.users
          : CollaborationViewMode.form
      );
      hasInit.current = true;
    }
  }, [caseDetail]);

  const onSyncGroups = () => {
    // TODO(Nick): This likely will be the only endpoint we use for this. Awaiting feedback from NM.
    if (isEdit) {
      return updateSyncGroups({
        caseId,
        groups: groups.value,
        hasCollaboration: shouldCollaborate.value,
        isPublic: isPublicCase.value,
      })
        .unwrap()
        .then(() => true)
        .catch(({ message, errors }: ApiError<UpdateSyncGroupsRequest>) => {
          showErrorMessage(message);
          groups.setError(errors?.groups);
          shouldCollaborate.setError(errors?.hasCollaboration);
          isPublicCase.setError(errors?.isPublic);
          return false;
        });
    }

    return syncGroups({
      caseId,
      companyGroupIds: groups.value,
    })
      .unwrap()
      .then(() => true)
      .catch(({ message, errors }: ApiError<SyncCaseGroupsRequest>) => {
        showErrorMessage(message);
        groups.setError(errors?.companyGroupIds);
        return false;
      });
  };

  const onInviteUser = (userId: number) => {
    return inviteUser({
      caseId,
      companyUserId: userId,
    })
      .unwrap()
      .then((resource) => {
        // TODO(Nick): temp
        // setContributors((v) => [...v, resource]);
        return true;
      })
      .catch(({ message, errors }: ApiError<InviteCaseUserRequest>) => {
        showErrorMessage(errors?.companyUserId ?? message);
        return false;
      });
  };

  const onUpdateUserRole = (
    updatedUser: CaseUserResource,
    newRoleId: number
  ) => {
    return updateUserRole({
      caseId,
      companyUserCaseId: updatedUser.companyUserCaseId,
      caseRoleId: newRoleId,
    })
      .unwrap()
      .then(() => {
        // TODO(Nick): temp
        // const role = roleOptions!.find((x) => x.id === newRoleId);
        // if (role != null) {
        //   setContributors((v) =>
        //     v.map((user) => {
        //       if (user.companyUserCaseId !== updatedUser.companyUserCaseId) {
        //         return user;
        //       }
        //
        //       return { ...user, caseRoleId: role.id, caseRoleName: role.label };
        //     })
        //   );
        // }
        return true;
      })
      .catch(({ message }: ApiError<never>) => {
        showErrorMessage(message);
        return false;
      });
  };

  const onRemoveUser = (removedUser: CaseUserResource) => {
    return removeUser({
      caseId,
      companyUserCaseId: removedUser.companyUserCaseId,
    })
      .unwrap()
      .then(() => {
        // TODO(Nick): temp.
        // setContributors((v) =>
        //   v.filter((x) => x.companyUserCaseId !== removedUser.companyUserCaseId)
        // );
        return true;
      })
      .catch(({ message }: ApiError<never>) => {
        showErrorMessage(message);
        return false;
      });
  };

  const onCompleteCase = () => {
    return completeCase(caseId)
      .unwrap()
      .then(() => true)
      .catch(({ message }: ApiError<never>) => {
        showErrorMessage(message);
        return false;
      });
  };

  return {
    caseId,
    viewMode,
    setViewMode,
    isPublicCase,
    groupOptions,
    groups,
    shouldCollaborate,
    isFormFieldsValid,
    contributors,
    contributorSearch,
    invitedGroups,
    recomendedUsers,
    isLoading,
    roleOptions,
    onSyncGroups,
    canSubmit,
    isBusy,
    onInviteUser,
    onUpdateUserRole,
    onRemoveUser,
    onCompleteCase,
    isEdit,
  };
}

export type CollaborationState = ReturnType<typeof useCollaboration>;

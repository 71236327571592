import { OutcomeReportState } from '@pages/app/outcome/outcome-report-hook';
import useField from '@hooks/use-field-hook';
import { required } from '@util/validators';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { usePageAlertVariants } from '@components/alerts';

export default function useEnterPasswordView({
  caseId,
  outcomeId,
  loadReport,
}: OutcomeReportState) {
  const { showErrorMessage } = usePageAlertVariants();

  const password = useField<string>([required()]);

  const { isValid, isDirty, validateAll } = useFieldsWatcher([password]);

  const isBusy = false;
  const canSubmit = isValid && isDirty && !isBusy;

  const submit = async () => {
    if (!validateAll()) {
      return false;
    }

    // TODO(Nick): Submit to the backend to check...
    // Temp await and transition
    await new Promise((resolve) => setTimeout(resolve, 1000));

    loadReport();

    return true;
  };

  return {
    password,
    canSubmit,
    isBusy,
    submit,
  };
}

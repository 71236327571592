import { ReactComponent as NoSolutionsIcon } from '@assets/svgs/no-tasks.svg';
import OnboardingEntryContainer, {
  OnboardingEntryContainerProps,
} from '@components/containers/onboarding-entry-container';

type Props = {
  isCaseSolutions: boolean;
  onCreateClicked?: () => void;
} & Partial<OnboardingEntryContainerProps>;

export default function NoSolutionsInfo({
  onCreateClicked,
  isCaseSolutions,
  ...rest
}: Props) {
  return (
    <OnboardingEntryContainer
      {...rest}
      icon={<NoSolutionsIcon />}
      title="You don't currently have any solutions"
      message={
        isCaseSolutions
          ? // eslint-disable-next-line quotes
            "There aren't currently any solutions for this case"
          : 'There are currently no solutions within the platform.'
      }
      createMessage={
        isCaseSolutions && onCreateClicked != null ? 'Add Solution' : undefined
      }
      onCreateClicked={
        isCaseSolutions && onCreateClicked != null ? onCreateClicked : undefined
      }
    />
  );
}

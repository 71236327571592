import { Grid, styled, Typography } from '@mui/material';
import { CaseImpactStats } from '@pages/app/rca/create/steps/impact-step-models';

interface Props {
  stats: CaseImpactStats;
}

const Container = styled('div', { target: 'impact-stats-summary' })({
  display: 'flex',
  maxWidth: 900,
  flexDirection: 'column',
  alignItems: 'stretch',
  padding: 20,
  background: '#EBEBEB',
  '.title': {
    textAlign: 'left',
  },

  table: {
    alignSelf: 'flex-end',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0.17000000178813934px',

    th: {
      padding: 10,
      minWidth: 150,
      minHeight: 42,
      background: '#777777',
      color: 'white',
      textAlign: 'center',

      '&.no-color': {
        background: 'unset',
      },

      '&.dark-color': {
        background: '#4B4B4B',
      },
    },

    td: {
      minHeight: 42,
      background: 'white',
      color: 'black',
      padding: 10,
      textAlign: 'center',

      '&.large-width': {
        minWidth: 260,
        textAlign: 'left',
      },
    },
  },
});

export default function ImpactStatsSummary({ stats: { rows } }: Props) {
  return (
    <Grid container justifyContent="center">
      <Container>
        <Typography variant="h6" className="title">
          Summary
        </Typography>
        <table>
          <thead>
            <tr>
              <th className="no-color"></th>
              <th>Actual</th>
              <th>Potential</th>
              <th className="dark-color">TOTAL</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row.name}>
                <td className="large-width">{row.name}</td>
                <td>{row.actualValue}</td>
                <td>{row.potentialValue}</td>
                <td>{row.total}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Container>
    </Grid>
  );
}

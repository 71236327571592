import { useGetCountryNamesQuery } from '@api/endpoints/country.api';
import { useFilter, usePagination, useSortableHeader } from '@components/table';
import {
  useDeleteLocationForCompanyMutation,
  useGetLocationsForCompanyQuery,
} from '@api/endpoints/company/company-location.api';
import { usePageAlertVariants } from '@components/alerts';
import { ApiError } from '@api/types/api-error';

export default function useFunctionalLocations() {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();

  const { data: countryOptions, isLoading: loadingCountries } =
    useGetCountryNamesQuery(false);

  const sort = useSortableHeader({ initialProperty: 'name' });
  const pagination = usePagination();

  const search = useFilter<string>();
  const country = useFilter<number>();

  const { data, isLoading: loadingData } = useGetLocationsForCompanyQuery({
    skip: pagination.skip,
    take: pagination.take,
    searchText: search.value,
    sortBy: sort.property,
    sortDirection: sort.direction,
  });

  const [deleteCompany] = useDeleteLocationForCompanyMutation();

  const isLoading = loadingCountries || loadingData;

  const onDeleteCompany = (id: number) => {
    return deleteCompany(id)
      .unwrap()
      .then(() => {
        showSuccessMessage('Location removed');
        return true;
      })
      .catch(({ message }: ApiError<never>) => {
        showErrorMessage(message);
        return false;
      });
  };

  return {
    isLoading,
    sort,
    countryOptions,
    search,
    country,
    pagination,
    data,
    onDeleteCompany,
  };
}

import { api } from '@api/api';
import { CreateCompanyRequest } from '@api/types/company/create-company.request';
import { makeError } from '@api/types/api-error';
import { refreshUserState } from '@store/user/user-slice';
import { CompanyDetailsResource } from '@api/types/company/company-details.resource';
import { UpdateCompanyDetailsRequest } from '@api/types/company/update-company-details.request';
import { SetCompany2faRequest } from '@api/types/company/set-company-2fa.request';
import { Option } from '@api/types/option';
import { CompanyAdminNameOptionType } from '@api/types/company/company-admin-name-option-type';
import { CompanyRcaModifiedTimesResponse } from '@api/types/company/company-user/company-rca-modified-times.response';

const companyApi = api.injectEndpoints({
  endpoints: (build) => ({
    createCompany: build.mutation<number, CreateCompanyRequest>({
      query: (body) => ({
        url: 'company',
        method: 'POST',
        body,
      }),
      onCacheEntryAdded: async (_, { cacheDataLoaded, dispatch }) => {
        await cacheDataLoaded;
        dispatch(refreshUserState());
      },
      transformErrorResponse: (error) => makeError<CreateCompanyRequest>(error),
      invalidatesTags: (response) => (response ? ['CompanyUser'] : []),
    }),
    getCompanyDetails: build.query<CompanyDetailsResource, void>({
      query: () => ({
        url: 'company/details',
        method: 'GET',
      }),
      providesTags: ['CompanyUser'],
    }),
    updateCompanyDetails: build.mutation<number, UpdateCompanyDetailsRequest>({
      query: (body) => ({
        url: 'company/details',
        method: 'PUT',
        body,
      }),
      onCacheEntryAdded: async (_, { cacheDataLoaded, dispatch }) => {
        await cacheDataLoaded;
        dispatch(refreshUserState());
      },
      transformErrorResponse: (error) =>
        makeError<UpdateCompanyDetailsRequest>(error),
      invalidatesTags: (response) => (response ? ['CompanyUser'] : []),
    }),
    setCompany2FA: build.mutation<void, SetCompany2faRequest>({
      query: (body) => ({
        url: 'company/setTwoFactor',
        method: 'PUT',
        body,
      }),
      onCacheEntryAdded: async (_, { cacheDataLoaded, dispatch }) => {
        await cacheDataLoaded;
        dispatch(refreshUserState());
      },
      transformErrorResponse: (error) => makeError<SetCompany2faRequest>(error),
      invalidatesTags: (response) => [
        'CompanyUser',
        { id: 'self', type: 'User' },
      ],
    }),
    getCompanyAdmins: build.query<Option[], CompanyAdminNameOptionType>({
      query: (nameType) => ({
        url: 'company/admins',
        method: 'GET',
        params: {
          userOptionType: nameType,
        },
      }),
    }),
    getCompanyReferenceDataModifiedTimes: build.query<
      CompanyRcaModifiedTimesResponse,
      void
    >({
      query: () => ({
        url: 'company/ReferenceDataModifiedTimes',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useCreateCompanyMutation,
  useGetCompanyDetailsQuery,
  useUpdateCompanyDetailsMutation,
  useSetCompany2FAMutation,
  useGetCompanyAdminsQuery,
  useGetCompanyReferenceDataModifiedTimesQuery,
} = companyApi;

import { Box, Grid, Link, Skeleton, Typography } from '@mui/material';
import useAcceptInvite from '@pages/auth/accept-invite-hook';
import useSearchParamsTyped from '@hooks/router-dom-helper-hooks';
import { isNullOrEmpty, numberFromString } from '@util/string-util';
import { LoadingIndicator } from '@components/loading-indicator';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import {
  ButtonSkeleton,
  CheckboxSkeleton,
  InputSkeleton,
} from '@components/skeletons';
import WCTTextField from '@components/input/text-field';
import WCTPhoneNumberField from '@components/input/phone-number-field';
import WCTCheckboxField from '@components/input/checkbox-field';
import { PrimaryButton } from '@components/buttons';
import HaveAccountLoginLink from '@pages/auth/components/have-account-login-link';
import { useAuthTr } from '@i18n/use-auth-tr';
import { Trans } from 'react-i18next';
import { PageAlertDisplay } from '@components/alerts';
import useUser from '@store/user/user-hook';
import { Gap } from '@components/layout-util-components/gap';

type QueryParams = {
  companyId: string;
  token: string;
};

export default function AcceptInvitePage() {
  const { isLoggedInAndFullyAuthenticated } = useUser();
  const { t } = useAuthTr('acceptInvite');
  const { t: commonTr } = useAuthTr('common');

  const { companyId, token } = useSearchParamsTyped<QueryParams>();

  const {
    inviteDetails,
    isLoading,
    isSubmitting,
    surname,
    acceptTerms,
    firstName,
    jobTitle,
    phone,
    marketingConsent,
    dialCodes,
    canSubmit,
    submit,
  } = useAcceptInvite({
    companyId: numberFromString(companyId)!,
    token,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (inviteDetails == null) {
      return;
    }

    if (inviteDetails.hasConfirmedAccount || !inviteDetails.isValid) {
      if (isLoggedInAndFullyAuthenticated) {
        navigate('/', { replace: true });
      } else {
        let returnTo = '/auth/login';
        if (!isNullOrEmpty(token)) {
          returnTo += `?inviteToken=${token}`;
        }
        navigate(returnTo, { replace: true, state: { returnTo: '/' } });
      }
    }
  }, [inviteDetails, isLoggedInAndFullyAuthenticated, navigate, token]);

  const onSubmit = async () => {
    if (await submit()) {
      navigate(`/auth/create-password/?token=${token}&companyId=${companyId}`, {
        replace: true,
      });
    }
  };

  return (
    <>
      {inviteDetails == null ||
      !inviteDetails.isValid ||
      inviteDetails.hasConfirmedAccount ? (
        <Box alignSelf="center">
          <LoadingIndicator show />
        </Box>
      ) : (
        <Grid
          component="form"
          container
          direction="column"
          gap={2}
          justifyContent="center"
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <Typography variant="h4">
            {isLoading ? <Skeleton /> : t('title')}
          </Typography>
          <Typography variant="body1">
            {isLoading ? <Skeleton /> : t('message')}
          </Typography>
          <Gap size={2} />
          <PageAlertDisplay />

          <Grid container wrap="nowrap" gap={1}>
            <Grid item xs={6}>
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <WCTTextField
                  name="firstName"
                  label={t('form.firstName')}
                  value={firstName.value}
                  error={firstName.error}
                  onChange={firstName.set}
                  required
                />
              )}
            </Grid>
            <Grid item xs={6}>
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <WCTTextField
                  name="surname"
                  label={t('form.surname')}
                  value={surname.value}
                  error={surname.error}
                  onChange={surname.set}
                  required
                />
              )}
            </Grid>
          </Grid>

          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTTextField
              label={t('form.jobTitle')}
              name="jobTitle"
              value={jobTitle.value}
              error={jobTitle.error}
              onChange={jobTitle.set}
            />
          )}

          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTPhoneNumberField
              id="phone-number"
              name="phoneNumber"
              label={t('form.phone')}
              dialCodes={dialCodes!}
              value={phone.value}
              errors={phone.errors}
              onChange={phone.set}
            />
          )}

          {isLoading ? (
            <CheckboxSkeleton />
          ) : (
            <WCTCheckboxField
              name="acceptMarketing"
              label={commonTr('marketingOptIn')}
              value={marketingConsent.value}
              onChange={marketingConsent.set}
            />
          )}

          {isLoading ? (
            <CheckboxSkeleton />
          ) : (
            <WCTCheckboxField
              name="acceptTerms"
              label={
                <Trans t={commonTr} i18nKey="terms">
                  {/*TODO(Nick): Implement terms of use*/}I agree to the
                  <Link href="#terms">Terms of Use</Link>
                  {/*TODO(Nick): Implement privacy policy*/}
                  <Link href="#privacy">Privacy Policy</Link>
                </Trans>
              }
              value={acceptTerms.value}
              onChange={acceptTerms.set}
            />
          )}

          {isLoading ? (
            <ButtonSkeleton />
          ) : (
            <PrimaryButton
              type="submit"
              disabled={!canSubmit}
              isBusy={isSubmitting}
            >
              {t('buttonLabel')}
            </PrimaryButton>
          )}

          {isLoading ? (
            <Box alignSelf="center">
              <Skeleton variant="rectangular">
                <HaveAccountLoginLink />
              </Skeleton>
            </Box>
          ) : (
            <HaveAccountLoginLink />
          )}
        </Grid>
      )}
    </>
  );
}

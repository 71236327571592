import { CircularProgress, Grid, styled, Typography } from '@mui/material';
import Spacer from '@components/layout-util-components/spacer';
import { useAppSelector } from '@store/store';
import {
  selectIsDraggingIntoStorageContainer,
  selectIsGraphBusy,
  selectStorageNodes,
} from '@store/rca-editor/selectors';
import { faChevronDown, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Column from '@components/layout-util-components/column';
import Row from '@components/layout-util-components/row';
import { Gap } from '@components/layout-util-components/gap';
import { FlatButton } from '@components/buttons';
import useChartStoragePanel from '@pages/app/rca/tabs/components/storage/chart-storage-hook';
import ChartStorageListView from '@pages/app/rca/tabs/components/storage/views/chart-storage-list-view';
import ChartStorageAddButton from '@pages/app/rca/tabs/components/storage/views/chart-storage-add-button';
import { RcaUtil } from '@util/rca-util';

type StyledProps = {
  expanded: boolean;
  isHoveringOver: boolean;
};

const Container = styled(Column, { target: 'chart-storage' })<StyledProps>(
  ({ theme: { palette }, expanded, isHoveringOver }) => ({
    zIndex: 100,
    position: 'absolute',
    right: 20,
    bottom: 20,
    width: 330,
    gap: 0,
    backgroundColor: 'white',

    ...(expanded && {
      right: 0,
      bottom: 0,
      top: 0,
    }),

    ...(isHoveringOver && {
      backgroundColor: '#FFDABF',
      border: '2px dashed #FF6C00',
      backgroundSize: '30px 1px',
    }),

    '.MuiAccordion-root,.MuiAccordion-rounded': {
      boxShadow: 'none',
      margin: 0,
      padding: 0,

      '.MuiAccordionSummary-content': {
        display: 'flex',
        alignItems: 'center',

        '.indicator': {
          width: 32,
          height: 32,
          padding: 8,
          borderRadius: 2,
          background: palette.common.grey15,
        },
      },
    },
  })
);

const StyledIndicatorContainer = styled('div')(({ theme: { palette } }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: '0 0 auto',
  background: palette.common.grey15,
  color: 'black',
  width: 32,
  height: 32,
  borderRadius: '50%',
  fontFamily: 'Roboto',
  fontSize: '13px',
  fontWeight: '500',
  lineHeight: '13px',
  letterSpacing: '0.46px',
  textAlign: 'center',
}));

export default function ChartStorage() {
  const state = useChartStoragePanel();
  const { isOpen, setIsOpen } = state;

  const nodes = useAppSelector(selectStorageNodes);
  const isBusy = useAppSelector(selectIsGraphBusy);
  const isHoveringOver = useAppSelector(selectIsDraggingIntoStorageContainer);

  // const onUpdateNodeDescription = async (
  //   node: StorageNode,
  //   description?: string
  // ) => {
  //   if (!isNullOrEmpty(description)) {
  //     try {
  //       await dispatch(updateStorageNodeDescription(node, description!));
  //       showSuccessMessage('Updated storage item');
  //     } catch (e) {
  //       const { message, errors } = e as ApiError<UpdateStorageItemRequest>;
  //       showErrorMessage(
  //         errors?.chainItemId ??
  //           errors?.chainId ??
  //           errors?.description ??
  //           message
  //       );
  //     }
  //   }
  //   setEditNodeId(undefined);
  // };

  return (
    <Container
      ref={RcaUtil.setChartStorageContainer}
      expanded={isOpen}
      isHoveringOver={isHoveringOver}
      sx={{ padding: '12px' }}
    >
      <Grid container>
        <Grid item xs={12}>
          {isOpen ? (
            <Row>
              <Typography variant="h6">Cause Stack</Typography>
              <Spacer />
              <FlatButton shape="circle" onClick={() => setIsOpen(false)}>
                <FontAwesomeIcon icon={faTimes} />
              </FlatButton>
            </Row>
          ) : (
            <Row onClick={() => setIsOpen(true)} sx={{ cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faChevronDown} />
              <Gap size={8} />
              <Typography>Cause Stack</Typography>
              <Spacer />
              {isBusy ? (
                <CircularProgress size={16} />
              ) : (
                <StyledIndicatorContainer>
                  {nodes.length}
                </StyledIndicatorContainer>
              )}
            </Row>
          )}
        </Grid>
      </Grid>
      {isOpen && (
        <>
          <Gap size={20} />
          <ChartStorageListView state={state} />
          <ChartStorageAddButton />
        </>
      )}
    </Container>
  );
}

import {
  useDeleteCompanyGroupMutation,
  useGetGroupsForCompanyQuery,
} from '@api/endpoints/company/company-company-group.api';
import { useFilter, useSortableHeader } from '@components/table';
import useLoadMore from '@hooks/use-load-more-hook';
import { SortDirection } from '@api/types/sort-direction';
import { useAppTr } from '@i18n/use-app-tr';
import usePermission from '@store/user/permission-hook';
import { Permission } from '@api/types/permission';
import { ApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';
import { SortOption } from '@components/table/hooks/use-sortable-header-hook';

export default function useManageGroups() {
  const { t } = useAppTr('groups');
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();

  const canDelete = usePermission(Permission.manageCompanyGroups);
  const canUpdate = usePermission(Permission.manageCompanyGroups);
  const canView = usePermission(Permission.manageCompanyGroups);

  const search = useFilter<string>();
  const { skip, take, loadMore, canLoadMore } = useLoadMore();

  const sort = useSortableHeader({ initialProperty: 'groupName' });

  const sortByOptions: Array<SortOption> = [
    {
      id: 'groupName',
      label: t('admin.sort.nameAsc'),
      direction: SortDirection.asc,
    },
    {
      id: 'groupName',
      label: t('admin.sort.nameDesc'),
      direction: SortDirection.desc,
    },
    {
      id: 'additionalUsers',
      label: t('admin.sort.memberCountAsc'),
      direction: SortDirection.asc,
    },
    {
      id: 'additionalUsers',
      label: t('admin.sort.memberCountDesc'),
      direction: SortDirection.desc,
    },
  ];

  const { data: groups, isLoading: loadingGroups } =
    useGetGroupsForCompanyQuery({
      searchText: search.value,
      skip,
      take,
      sortBy: sort.property,
      sortDirection: sort.direction,
    });

  const [deleteGroup] = useDeleteCompanyGroupMutation();

  const isLoading = loadingGroups;

  const onDeleteGroup = (id: number, name: string): Promise<boolean> => {
    return deleteGroup(id)
      .unwrap()
      .then(() => {
        showSuccessMessage(t('deleteModal.toasts.success', { name }));
        return true;
      })
      .catch(({ message }: ApiError<any>) => {
        showErrorMessage(message);
        return false;
      });
  };

  return {
    canDelete,
    canView,
    canUpdate,
    isLoading,
    groups,
    search,
    loadMore,
    sortByOptions,
    sort,
    onDeleteGroup,
    showLoadMoreButton: canLoadMore(groups?.totalCount ?? 0),
  };
}

import {
  NodeCollapseState,
  NodeEndStateType,
  RcaNode,
  RcaNodeType,
} from '@store/rca-editor/types';
import useNode from '@pages/app/rca/tabs/components/node-types/node-hook';
import { useAppDispatch, useAppSelector } from '@store/store';
import {
  commitCreateNode,
  highlightNode,
  makeNodeEndState,
  removeNode,
} from '@store/rca-editor/rca-editor-actions';
import { useMemo, useState } from 'react';
import {
  makeSelectChainItemHealthScore,
  makeSelectChildCount,
  makeSelectHighlightAsConnectedNode,
  makeSelectIsEditingContent,
  makeSelectNodeDisplayProperties,
  selectDisplayHealthState,
  selectFocusedNodeIdNullable,
  selectIsDevMode,
  selectIsHighlightMode,
} from '@store/rca-editor/selectors';
import { RcaUtil } from '@util/rca-util';
import { setSelectedNode } from '@store/rca-editor/rca-editor-slice';

export default function useDefaultNode(node: RcaNode) {
  const dispatch = useAppDispatch();

  const [hasCommitted, setHasCommitted] = useState(false);

  const {
    id,
    dragging,
    type,
    selected,
    data: {
      disproved,
      chainItemId,
      label,
      isRoot,
      highlight,
      endState: endStateType,
    },
  } = node;

  const hasChainItemId = chainItemId != null;

  const baseNodeState = useNode(node);
  const {
    isEndStateOrConnection,
    isEndState,
    isLinkNode,
    chainId,
    parentNodeType,
    collapsedState,
    isConnection,
    isPresenterMode,
  } = baseNodeState;

  const selectHealthScore = useMemo(() => {
    if (chainId == null || !hasChainItemId) {
      return (_) => undefined;
    }

    return makeSelectChainItemHealthScore(chainId, chainItemId);
  }, [chainId, chainItemId, hasChainItemId]);

  const selectIsEditingContent = useMemo(
    () => makeSelectIsEditingContent(id),
    [id]
  );
  const selectChildCount = useMemo(() => makeSelectChildCount(id), [id]);
  const selectChildCountIncludingMetaNodes = useMemo(
    () => makeSelectChildCount(id, true),
    [id]
  );
  const selectShouldHighlightAsConnection = useMemo(
    () => makeSelectHighlightAsConnectedNode(id),
    [id]
  );
  const selectDisplayProperties = useMemo(() => {
    return makeSelectNodeDisplayProperties(id);
  }, [id]);

  const maybeFocusedNodeId = useAppSelector(selectFocusedNodeIdNullable);
  const displayProperties = useAppSelector(selectDisplayProperties);
  const isDevMode = useAppSelector(selectIsDevMode);
  const isHighlightMode = useAppSelector(selectIsHighlightMode);
  const isEditing = useAppSelector(selectIsEditingContent);
  const childCount = useAppSelector(selectChildCount);
  const childCountIncludingMetaNodes = useAppSelector(
    selectChildCountIncludingMetaNodes
  );
  const healthScore = useAppSelector(selectHealthScore);
  const healthStateBeingDisplayed = useAppSelector(selectDisplayHealthState);
  const isConnectedFromOtherNodes = useAppSelector(
    selectShouldHighlightAsConnection
  );

  const shouldFadeOut =
    maybeFocusedNodeId != null && id !== maybeFocusedNodeId && !isPresenterMode;
  const shouldDisplayCollapsedIndicator =
    collapsedState === NodeCollapseState.collapsed ||
    collapsedState === NodeCollapseState.ghost;
  const shouldDisplayEndStateInfo = isEndState && !isConnection;
  const shouldDisplayEndStateElipsis =
    isEndState && !isConnection && !isPresenterMode;
  const shouldDisplayHealthScore =
    healthScore != null &&
    !isConnection &&
    displayProperties?.type === 'health-score';
  const shouldDisplayHealthState =
    healthStateBeingDisplayed && shouldDisplayHealthScore;
  const shouldDisplayConnectionIndicator =
    isConnectedFromOtherNodes && !isConnection;

  const canConnectEdgeFromThisNode = !isEndStateOrConnection || isLinkNode;
  const canConnectEdgeToThisNode = !isRoot;
  const canDrag = !isEndStateOrConnection && !isRoot && !isPresenterMode;
  const canHandleClick = !(isEditing || dragging);
  const canMakeEndState =
    childCount === 0 && !disproved && !isEndStateOrConnection;
  const canShowRightMetaAction =
    !isPresenterMode &&
    !isConnection &&
    collapsedState === NodeCollapseState.default &&
    childCount > 0;
  const canShowLeftMetaAction =
    !isPresenterMode &&
    !isConnection &&
    !RcaUtil.isMetaNodeType(parentNodeType) &&
    collapsedState === NodeCollapseState.default;
  const canShowBorder =
    selected ||
    highlight ||
    displayProperties.outlineColor != null ||
    shouldDisplayHealthState;

  const visualType = useMemo(() => {
    switch (type) {
      case RcaNodeType.endState: {
        if (endStateType === NodeEndStateType.furtherAnalysis) {
          return RcaNodeType.default;
        }
        break;
      }
    }

    return type ?? RcaNodeType.default;
  }, [endStateType, type]);

  const maybeCommitCreate = async (label?: string) => {
    try {
      const shouldCommit = label != null && label.length > 0;
      setHasCommitted(shouldCommit);

      if (shouldCommit) {
        await dispatch(commitCreateNode(id, label));
      } else {
        await dispatch(removeNode(id, { moveToStorage: false }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const cancelCreate = async () => {
    try {
      if (hasCommitted) {
        return;
      }

      await dispatch(removeNode(id, { moveToStorage: false }));
    } catch (e) {
      console.log(e);
    }
  };

  const setNodeHighlight = (highlightChain?: boolean) => {
    dispatch(highlightNode(id, highlightChain ?? false));
  };

  const selectNode = () => {
    dispatch(setSelectedNode(node));
  };

  const removeEndState = () => {
    return dispatch(makeNodeEndState(id, NodeEndStateType.none));
  };

  return {
    ...baseNodeState,
    label,
    maybeCommitCreate,
    cancelCreate,
    canMakeEndState,
    disproved,
    childCount,
    childCountIncludingMetaNodes,
    healthScore,
    shouldDisplayHealthScore,
    shouldDisplayHealthState,
    shouldDisplayEndStateInfo,
    shouldDisplayCollapsedIndicator,
    canShowLeftMetaAction,
    canShowRightMetaAction,
    canConnectEdgeToThisNode,
    canConnectEdgeFromThisNode,
    shouldDisplayConnectionIndicator,
    canHandleClick,
    isEditing,
    isHighlightMode,
    setNodeHighlight,
    selectNode,
    isDevMode,
    hasChainItemId,
    isRoot,
    chainItemId,
    displayProperties,
    canShowBorder,
    removeEndState,
    shouldDisplayEndStateElipsis,
    canDrag,
    shouldFadeOut,
    visualType,
  };
}

export type BaseNodeState = ReturnType<typeof useDefaultNode>;

import ProfileLayoutScaffold, {
  ProfileLayoutTab,
} from '@components/layouts/app/scaffolds/profile-layout-scaffold';
import { useMemo } from 'react';
import { usePermissions } from '@store/user/permission-hook';
import { Permission } from '@api/types/permission';

export default function CompanyProfileLayout() {
  const canAccessUserManagement = usePermissions([
    Permission.manageCompanyUsers,
  ]);

  const canAccessCompanyDetails = usePermissions([Permission.manageCompany]);

  const canAccessBilling = usePermissions([Permission.manageCompanyPayments]);

  const canAccessLocations = usePermissions([
    Permission.manageCompanyLocations,
  ]);

  const canManageCompanyReferenceData = usePermissions([
    Permission.manageCompanyReferenceData,
  ]);

  const tabs = useMemo((): Array<ProfileLayoutTab> => {
    const ret: Array<ProfileLayoutTab> = [];

    if (canAccessCompanyDetails) {
      ret.push({
        path: '',
        subPaths: ['information', 'mfa', 'settings'],
        name: 'Company Settings',
        index: true,
      });
    }

    if (canAccessLocations) {
      ret.push({
        path: 'locations',
        name: 'Company Locations',
      });
    }

    if (canAccessUserManagement) {
      ret.push({
        path: 'users',
        name: 'User Management',
      });
    }

    if (canAccessBilling) {
      ret.push({
        path: 'billing',
        name: 'Billing',
      });
    }

    if (canManageCompanyReferenceData) {
      ret.push({
        path: 'rca-settings',
        name: 'RCA Settings',
        subPaths: [
          'rca-settings-themes',
          'rca-settings-impacts',
          'rca-settings-types',
          'rca-settings-labels',
        ],
      });
    }

    return ret;
  }, [
    canAccessBilling,
    canAccessCompanyDetails,
    canAccessLocations,
    canAccessUserManagement,
    canManageCompanyReferenceData,
  ]);

  return (
    <ProfileLayoutScaffold
      baseRoute="company-profile"
      title="Company Profile"
      tabs={tabs}
    />
  );
}

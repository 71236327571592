import { Paper, styled, Typography } from '@mui/material';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  title: string;
  description: string;
  onClick: () => void;
}

const Container = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  padding: '51px 23px',
  border: '4px solid #00000000',
  minWidth: 250,
  maxWidth: 294,
  cursor: 'pointer',

  '& > .type-icon': {
    width: 75,
    height: 75,
    backgroundColor: '#ccc',
  },

  '&:hover': {
    border: '4px solid #000000BF',

    '& > .type-icon': {
      backgroundColor: '#888',
    },
  },

  transition: 'border,background-color 80ms',
});

export default function RcaTypeCard({ title, onClick, description }: Props) {
  return (
    <Container onClick={onClick}>
      <div className="type-icon" />
      <Gap size={20} />
      <Typography variant="h4">{title}</Typography>
      <Gap size={4} />
      <Typography variant="body2">{description}</Typography>
    </Container>
  );
}

import { styled, Typography } from '@mui/material';
import { PrimaryButton } from '@components/buttons';
import { ReactNode, useState } from 'react';
import withModal, { ModalProps } from '@components/ui-popup/modals/modal-hoc';
import ActionsRow from '../../layout-util-components/actions-row';
import { Gap } from '@components/layout-util-components/gap';

type OkButtonAsyncAction = () => Promise<boolean>;

export interface OkModalProps extends ModalProps<boolean> {
  title?: ReactNode;
  message: ReactNode;
  okButtonLabel?: ReactNode;
  okButtonAsyncAction?: OkButtonAsyncAction;
}

const Container = styled('div')({
  width: 400,
  display: 'flex',
  flexDirection: 'column',
});

const OkModal = ({
  title,
  message,
  okButtonLabel,
  okButtonAsyncAction,
  onClose,
}: OkModalProps) => {
  const [isBusy, setIsBusy] = useState(false);

  const onOkClicked = async () => {
    if (okButtonAsyncAction == null) {
      onClose(true);
      return;
    }

    setIsBusy(true);
    try {
      const result = await okButtonAsyncAction();
      if (result) {
        onClose(result);
      } else {
        setIsBusy(false);
      }
    } catch (_) {
      setIsBusy(false);
    }
  };

  return (
    <Container>
      {title ? <Typography typography="h2">{title}</Typography> : null}
      <Gap size={15} />
      <Typography typography="body1">{message}</Typography>
      <Gap size={24} />
      <ActionsRow center>
        <PrimaryButton isBusy={isBusy} onClick={onOkClicked}>
          {okButtonLabel ? okButtonLabel : 'OK'}
        </PrimaryButton>
      </ActionsRow>
    </Container>
  );
};

export default withModal(OkModal);

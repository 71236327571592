import { TaskPriority } from '@api/types/task-detail/task-priority';
import { FormGroup, FormHelperText, styled } from '@mui/material';
import { ReactComponent as HighPriorityIcon } from '@assets/svgs/flag-high.svg';
import { ReactComponent as MediumPriorityIcon } from '@assets/svgs/flag-medium.svg';
import { ReactComponent as LowPriorityIcon } from '@assets/svgs/flag-low.svg';

interface Props {
  name: string;
  value?: TaskPriority;
  error?: string;
  onChange: (value: TaskPriority) => void;
  readonly?: boolean;
}

const Container = styled('div')(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'row',

  input: {
    WebkitAppearance: 'none',
    appearance: 'none',
    /* For iOS < 15 to remove gradient background */
    backgroundColor: palette.common.white,
    /* Not removed via appearance */
    margin: 0,
  },

  '& .priority-label': {
    border: '1px solid #0000001F',
    borderRight: 'none',

    '&:first-of-type': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    '&:last-of-type': {
      borderRight: '1px solid #0000001F',
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
  },
}));

const Option = styled('label', {
  target: 'priority-label',
})<{
  isSelected: boolean;
  readOnly?: boolean;
}>(({ isSelected, readOnly }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 10,
  background: isSelected ? 'rgba(0, 0, 0, 0.08)' : 'white',
  cursor: isSelected || readOnly ? 'default' : 'pointer',
}));

export default function PrioritySelectorField({
  name,
  value,
  error,
  onChange,
  readonly,
}: Props) {
  const makeSelect = (priority: TaskPriority) => () => {
    if (readonly) {
      return null;
    }

    onChange(priority);
  };

  return (
    <FormGroup>
      <Container>
        <Option
          isSelected={value === TaskPriority.high}
          onClick={makeSelect(TaskPriority.high)}
          readOnly={readonly}
        >
          <input
            type="radio"
            id={TaskPriority.high}
            name={name}
            value={TaskPriority.high}
            disabled={readonly}
            readOnly={readonly}
          />
          <HighPriorityIcon />
        </Option>
        <Option
          isSelected={value === TaskPriority.standard}
          onClick={makeSelect(TaskPriority.standard)}
          readOnly={readonly}
        >
          <input
            type="radio"
            id={TaskPriority.standard}
            name={name}
            value={TaskPriority.standard}
            disabled={readonly}
            readOnly={readonly}
          />
          <MediumPriorityIcon />
        </Option>
        <Option
          isSelected={value === TaskPriority.low}
          onClick={makeSelect(TaskPriority.low)}
          readOnly={readonly}
        >
          <input
            type="radio"
            id={TaskPriority.low}
            name={name}
            value={TaskPriority.low}
            disabled={readonly}
            readOnly={readonly}
          />
          <LowPriorityIcon />
        </Option>
      </Container>
      {error ? <FormHelperText error>{error}</FormHelperText> : null}
    </FormGroup>
  );
}

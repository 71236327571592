import { api } from '@api/api';
import { CanLeaveGroupResponse } from '@api/types/company-group-user/can-leave-group.response';
import { CanLeaveGroupRequest } from '@api/types/company-group-user/can-leave-group.request';
import { makeError } from '@api/types/api-error';

const companyGroupUserApi = api.injectEndpoints({
  endpoints: (build) => ({
    canLeaveGroup: build.query<CanLeaveGroupResponse, CanLeaveGroupRequest>({
      query: (params) => ({
        url: 'companyGroupUser/canLeave',
        method: 'GET',
        params,
      }),
      providesTags: ['CompanyGroup'],
    }),
    leaveGroup: build.mutation<void, number>({
      query: (id) => ({
        url: `companyGroupUser/leave/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CompanyGroup'],
      transformErrorResponse: (error) => makeError(error),
    }),
  }),
});

export const { useCanLeaveGroupQuery, useLeaveGroupMutation } =
  companyGroupUserApi;

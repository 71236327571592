export const getActivityDescription = ({
  companyUserName,
  timeOfEvent,
  date,
}: {
  companyUserName: string;
  timeOfEvent: Date;
  date: Date;
}) => {
  return `${companyUserName} | ${timeOfEvent} - ${
    ('0' + date.getHours()).slice(-2) +
    ':' +
    ('0' + date.getMinutes()).slice(-2)
  }`;
};

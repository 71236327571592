import SectionScaffold from '@components/outcome-report/components/section-scaffold';
import { Box, styled } from '@mui/material';
import { ChainDetailResponse } from '@api/types/chain/chain-detail.response';
import RenderGraph from '@pages/app/rca/tabs/components/graph/render-graph';
import { RcaUtil } from '@util/rca-util';
import { useAppDispatch } from '@store/store';
import chainApi from '@api/endpoints/chain/chain.api';
import { useEffect } from 'react';
import Column from '@components/layout-util-components/column';
import Row from '@components/layout-util-components/row';
import { BlackButton } from '@components/outcome-report/components/black-button';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import HealthBadge from '@components/badges/health-badge';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  chainDetail: ChainDetailResponse;
  themeCount: number;
  healthScore: number;
}

const Container = styled('div')(({ theme: { palette } }) => ({
  position: 'relative',
  width: '100vw',
  height: '100vh',

  '.input-blocker': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',

    zIndex: 1000,
  },

  '.overview-container': {
    position: 'absolute',
    top: 45,
    left: 45,
    background: 'white',
    borderRadius: 5,
    boxShadow: '0px 2.0626375675201416px 35.06483840942383px 0px #0000001C',
    padding: 32,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    zIndex: 2000,

    '.title': {
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '21px',
      letterSpacing: '0.07734891027212143px',
      textAlign: 'left',
      color: palette.common.black,
    },

    '.data-value': {
      display: 'inline-flex',
      flexDirection: 'column',
      gap: 10,

      '.label': {
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '19px',
        letterSpacing: '0px',
        textAlign: 'left',
        color: '#A6A6A6',
      },

      '.value': {
        fontFamily: 'Roboto',
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0px',
        textAlign: 'left',
      },
    },
  },
}));

export default function ChartSection({
  chainDetail,
  themeCount,
  healthScore,
}: Props) {
  const { caseId } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const r = dispatch(
      chainApi.endpoints.getChainHealthScores.initiate(chainDetail.chainId, {
        subscribe: true,
        forceRefetch: true,
      })
    );
    return () => r.unsubscribe();
  }, [chainDetail.chainId, dispatch]);

  return (
    <SectionScaffold id="chart">
      <Container>
        <RenderGraph
          onInit={(reactFlowInstance) => {
            RcaUtil.setReactFlowInstance(reactFlowInstance);

            reactFlowInstance.fitView({
              nodes: chainDetail.nodes,
              maxZoom: 0.8,
            });

            const vp = reactFlowInstance.getViewport();
            vp.x += 40;
            reactFlowInstance.setViewport(vp);
          }}
          nodes={chainDetail.nodes}
          edges={chainDetail.edges}
        />
        <div className="input-blocker" />
        <div className="overview-container">
          <Column>
            <div className="title">Visual analysis</div>
            <Gap size={24} />
            <Row>
              <div className="data-value">
                <div className="label">CAUSE BOXES</div>
                <div className="value">{chainDetail.nodes.length}</div>
              </div>
              <Gap size={50} />
              <div className="data-value">
                <div className="label">THEMES</div>
                <div className="value">{themeCount}</div>
              </div>
              <Gap size={50} />
              <div className="data-value">
                <div className="label">Health Score</div>
                <div className="value">
                  <HealthBadge health={healthScore} />
                </div>
              </div>
            </Row>
            <Gap size={32} />
            <Box alignSelf="flex-start">
              <BlackButton href={`/rca/tabs/${caseId}/chart`}>
                <span>View Analysis</span>
                <FontAwesomeIcon icon={faArrowRight} />
              </BlackButton>
            </Box>
          </Column>
        </div>
      </Container>
    </SectionScaffold>
  );
}

import { Outlet, useNavigate } from 'react-router-dom';
import { Link, styled } from '@mui/material';
import AppLogo from '@assets/svgs/app-logo.svg';
import MenuItemRow from '@components/menu-items/app/menu-item-row';
import MenuItemProfile from '@components/menu-items/app/menu-item-profile';
import BillingAlert from '@components/alerts/billing-alert';
import { useAppTr } from '@i18n/use-app-tr';
import { CircleButton } from '@components/buttons';
import { Divider } from '@components/layout-util-components/divider';
import { useIsPath } from '@util/path-util';
import {
  faBars,
  faChevronLeft,
  faCog,
  faDiagramProject,
  faHome,
  faBell,
  faLifeRing,
  faListCheck,
  faUsers,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePermissions } from '@store/user/permission-hook';
import { Permission } from '@api/types/permission';
import { useState } from 'react';
import { Gap } from '@components/layout-util-components/gap';

const PageContainer = styled('div')(({ theme: { palette } }) => ({
  display: 'flex',
  width: '100vw',
  height: '100vh',
  backgroundColor: palette.common.grey10,
}));

const SideNav = styled('div')<{ isOpen: boolean }>(({ isOpen }) => ({
  position: 'absolute',
  width: isOpen ? 275 : 80,
  height: '100vh',
  padding: isOpen ? '30px 0 0 0' : '30px 15px 0px 15px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: '0 0 auto',
  backgroundColor: 'white',

  '& > .nav-top': {
    display: 'flex',
    alignSelf: 'stretch',
    alignItems: 'center',
    gap: 5,
    justifyContent: 'space-between',
    flexDirection: isOpen ? 'row' : 'column',
    padding: isOpen ? '0 30px 0 30px' : '0',
  },

  '& .nav-logo': {
    width: isOpen ? 80 : 48,
    height: isOpen ? 80 : 48,
  },

  '& .menu-item-row': {
    width: isOpen ? null : 41,
    borderRadius: isOpen ? null : 7,
    borderLeft: isOpen ? null : 0,
    paddingLeft: isOpen ? null : 8,
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    svg: {
      width: isOpen ? 31 : 'auto',
    },
    '&.selected': {
      borderLeft: isOpen ? null : 0,
    },
  },

  '& .menu-item-descriptor': {
    display: isOpen ? 'block' : 'none',
  },

  '& .menu-item-profile': {
    padding: isOpen ? '15px 30px' : '15px 0',
    '& .name-container': {
      width: isOpen ? 'auto' : 0,
      display: isOpen ? 'block' : 'none',
    },
  },
}));

const NavItemsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: '1 0 auto',
  gap: 8,
});

const LowerNavItemsContainer = styled('div')({
  display: 'flex',
  padding: '25px 0',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  marginTop: 'auto',
  '>div:first-child': {
    marginBottom: 20,
    height: 1,
    background: 'rgba(0, 0, 0, 0.08)',
    border: 'none',
  },
  '>.menu-item': {
    padding: '12px 10px',
  },
});

const OutletContainer = styled('div')<{ isNavOpen: boolean }>(
  ({ isNavOpen }) => ({
    flex: '1 0 auto',
    marginLeft: isNavOpen ? 275 : 80,
    width: 'calc(100vw - 275px)',
    overflowY: 'auto',
  })
);

export default function AppLayout() {
  const { t } = useAppTr('common');
  const navigate = useNavigate();
  const [openNav, setOpenNav] = useState(false);

  const isDashboard = useIsPath(['/', '/dashboard', '/home']);
  const isAnalysis = useIsPath('/analysis', { startsWith: true });
  const isGroups = useIsPath('/groups', { startsWith: true });
  const isCompanyProfile = useIsPath('/company-profile', { startsWith: true });
  const isTasks = useIsPath('/tasks', { startsWith: true });

  const canSeeAnalysis = usePermissions([Permission.useCaseFunctionality]);

  const canSeeGroups = usePermissions([
    Permission.manageMyGroups,
    Permission.manageCompanyGroups,
  ]);

  const canSeeCompanyProfile = usePermissions([Permission.manageCompany]);

  const canSeeTasks = usePermissions([
    Permission.manageMyTasks,
    Permission.manageCompanyTasks,
  ]);

  const toggleNav = () => {
    setOpenNav(!openNav);
  };

  return (
    <PageContainer>
      <SideNav isOpen={openNav}>
        <div className="nav-top">
          <Link href="/">
            <img className="nav-logo" src={AppLogo} alt="logo" />
          </Link>
          <CircleButton onClick={toggleNav}>
            <FontAwesomeIcon icon={openNav ? faChevronLeft : faBars} />
          </CircleButton>
        </div>
        <Gap size={32} />
        <NavItemsContainer>
          <MenuItemRow
            onClick={() => navigate('/')}
            isSelected={isDashboard}
            icon={<FontAwesomeIcon icon={faHome} />}
          >
            {t('navBarItems.dashboard')}
          </MenuItemRow>
          {canSeeAnalysis && (
            <MenuItemRow
              onClick={() => navigate('/analysis')}
              isSelected={isAnalysis}
              icon={<FontAwesomeIcon icon={faDiagramProject} />}
            >
              {t('navBarItems.analysis')}
            </MenuItemRow>
          )}
          {canSeeGroups && (
            <MenuItemRow
              onClick={() => navigate('/groups')}
              isSelected={isGroups}
              icon={<FontAwesomeIcon icon={faUsers} />}
            >
              Groups
            </MenuItemRow>
          )}
          {canSeeCompanyProfile && (
            <MenuItemRow
              onClick={() => navigate('/company-profile')}
              isSelected={isCompanyProfile}
              icon={<FontAwesomeIcon icon={faCog} />}
            >
              {t('navBarItems.company')}
            </MenuItemRow>
          )}
          {canSeeTasks && (
            <MenuItemRow
              onClick={() => navigate('/tasks')}
              isSelected={isTasks}
              icon={<FontAwesomeIcon icon={faListCheck} />}
            >
              {t('navBarItems.tasks')}
            </MenuItemRow>
          )}
        </NavItemsContainer>
        <LowerNavItemsContainer>
          <Divider compact />
          <MenuItemRow
            onClick={() => {}}
            icon={<FontAwesomeIcon icon={faBell} />}
          >
            {t('navBarItems.notifications')}
          </MenuItemRow>
          <MenuItemRow
            onClick={() => navigate('/help-centre')}
            icon={<FontAwesomeIcon icon={faLifeRing} />}
          >
            {t('navBarItems.help')}
          </MenuItemRow>
        </LowerNavItemsContainer>

        <MenuItemProfile isSideDrawerOpen={openNav} />
      </SideNav>
      <OutletContainer isNavOpen={openNav}>
        <BillingAlert />
        <Outlet />
      </OutletContainer>
    </PageContainer>
  );
}

import { api } from '@api/api';
import { Option } from '@api/types/option';
import { PaginatedResult } from '@api/types/paginated-result';
import { UserRowResponse } from '@api/types/user/admin/user-row.response';
import { UserTableFilters } from '@api/types/user/admin/user-table.filters';
import { CreateUserRequest } from '@api/types/user/create-user.request';
import { makeError } from '@api/types/api-error';

const adminUserApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAdminUserManagers: build.query<Option<string>[], void>({
      query: () => ({
        url: 'admin/user/managers',
      }),
    }),
    getAdminUserStatusOptions: build.query<Option<string>[], void>({
      query: () => ({
        url: 'admin/user/status/options',
      }),
    }),
    getAdminUserTable: build.query<
      PaginatedResult<UserRowResponse>,
      UserTableFilters
    >({
      query: (params) => ({
        url: 'admin/user',
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.model.map(({ userId }) => ({
                type: 'User' as const,
                id: userId,
              })),
              'User',
            ]
          : ['User'],
    }),
    adminCreateUser: build.mutation<void, CreateUserRequest>({
      query: (body) => ({
        url: 'admin/user/create',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) => makeError<CreateUserRequest>(error),
      invalidatesTags: ['User'],
    }),
    adminDisableUser: build.mutation<void, { userId: string }>({
      query: (body) => ({
        url: 'admin/user/disable',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) => makeError(error),
      invalidatesTags: (result, error, arg) => [
        { type: 'User', id: arg.userId },
      ],
    }),
    adminEnableUser: build.mutation<void, { userId: string }>({
      query: (body) => ({
        url: 'admin/user/enable',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) => makeError(error),
      invalidatesTags: (result, error, arg) => [
        { type: 'User', id: arg.userId },
      ],
    }),
  }),
});

export const {
  useGetAdminUserManagersQuery,
  useGetAdminUserStatusOptionsQuery,
  useGetAdminUserTableQuery,
  useAdminCreateUserMutation,
  useAdminDisableUserMutation,
  useAdminEnableUserMutation,
} = adminUserApi;

import { api } from '@api/api';
import { CompleteCaseQuestionResponse } from '@api/types/complete-case-question/complete-case-question.response';

const completeCaseQuestionApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompleteCaseQuestions: build.query<
      Array<CompleteCaseQuestionResponse>,
      void
    >({
      query: () => ({
        url: 'completeCaseQuestion',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetCompleteCaseQuestionsQuery } = completeCaseQuestionApi;

import { styled } from '@mui/material';
import { forwardRef, HTMLProps } from 'react';

const StyledBadge = styled('div', { target: 'filled-badge' })<
  HTMLProps<HTMLDivElement>
>(({ theme: { palette } }) => ({
  display: 'inline-flex',
  marginRight: 5,
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: palette.common.grey20,
  borderRadius: 4,
  padding: '4px 7px',
  textTransform: 'capitalize',
  fontWeight: 500,
  fontSize: 12,
  lineHeight: '14px',
  letterSpacing: '0.17px',
}));

const FilledBadge = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(
  ({ children, ...rest }, ref) => {
    return (
      <StyledBadge ref={ref} {...(rest as any)}>
        {children}
      </StyledBadge>
    );
  }
);

export default FilledBadge;

import useField from '@hooks/use-field-hook';
import { useGetThemeOptionsQuery } from '@api/endpoints/theme.api';
import {
  useGetChainItemQuery,
  useUpdateChainItemMutation,
} from '@api/endpoints/chain/chain-item.api';
import { useAppDispatch, useAppSelector } from '@store/store';
import {
  makeSelectIsNodeBusy,
  selectChainId,
  selectCurrentRcaCaseId,
  selectFocusedNode,
} from '@store/rca-editor/selectors';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { usePageAlertVariants } from '@components/alerts';
import { useBinaryOptions } from '@util/constant-options';
import { useMemo } from 'react';
import {
  removeDisproval,
  updateNodeLabel,
} from '@store/rca-editor/rca-editor-actions';
import { UpdateChainItemRequest } from '@api/types/chain/update-chain-item.request';
import { ApiError } from '@api/types/api-error';

export default function useOverviewPanel() {
  const dispatch = useAppDispatch();
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const caseId = useAppSelector(selectCurrentRcaCaseId);

  const { data: themeOptions, isLoading: loadingThemes } =
    useGetThemeOptionsQuery(caseId);

  const focusedNode = useAppSelector(selectFocusedNode);
  const isNodeBusy = useAppSelector(makeSelectIsNodeBusy(focusedNode.id));
  const chainId = useAppSelector(selectChainId)!;
  const { chainItemId, disproved } = focusedNode.data ?? {};
  const canEdit = chainItemId != null;

  const [updateOverview, { isLoading: isSubmitting }] =
    useUpdateChainItemMutation();

  const { data: chainItemData, isLoading: loadingChainItem } =
    useGetChainItemQuery(
      { chainId, chainItemId: chainItemId ?? -1 },
      { skip: !canEdit }
    );

  const nodeTypeOptions = useBinaryOptions({
    trueLabel: 'Transitory',
    falseLabel: 'non-Transitory',
  });

  const description = useField<string>([], chainItemData?.description);
  const theme = useField<Array<number>>(
    [],
    useMemo(() => {
      return chainItemData?.themes.map((theme) => theme.id) ?? [];
    }, [chainItemData?.themes])
  );
  const transitory = useField<boolean>([], chainItemData?.transitory);

  const { isValid, isDirty } = useFieldsWatcher([
    description,
    theme,
    transitory,
  ]);

  const isBusy = isNodeBusy || isSubmitting;
  const isLoading = loadingThemes || loadingChainItem;
  const canSubmit = !isLoading && !isBusy && isValid && isDirty;

  const onSubmit = async () => {
    try {
      await dispatch(updateNodeLabel(focusedNode.id, description.value));

      await updateOverview({
        chainId,
        chainItemId: chainItemId!,
        description: description.value,
        transitory: transitory.value,
        themes: theme.value,
      }).unwrap();

      showSuccessMessage(`Updated Cause Box ${description.value}`);

      return true;
    } catch (e) {
      const { message, errors } = e as ApiError<UpdateChainItemRequest>;
      showErrorMessage(message);

      description.setError(errors?.description);
      theme.setError(errors?.themes);
      transitory.setError(errors?.transitory);

      return false;
    }
  };

  const removeDisproved = () => {
    dispatch(removeDisproval(focusedNode.id));
  };

  return {
    nodeTypeOptions,
    description,
    theme,
    transitory,
    themeOptions,
    isLoading,
    canEdit,
    canSubmit,
    isBusy,
    onSubmit,
    disproved,
    removeDisproved,
  };
}

import { api } from '@api/api';
import { ChainItemResource } from '@api/types/chain/chain-item.resource';
import { UpdateChainItemRequest } from '@api/types/chain/update-chain-item.request';
import { makeError } from '@api/types/api-error';
import { ChainItemSetEndStateRequest } from '@api/types/chain/chain-item-set-end-state.request';

export type ChainItemApiPathParams = {
  chainId: number;
  chainItemId: number;
};

const chainItemApi = api.injectEndpoints({
  endpoints: (build) => ({
    getChainItem: build.query<ChainItemResource, ChainItemApiPathParams>({
      query: ({ chainId, chainItemId }) => ({
        url: `chain/${chainId}/chainItem/${chainItemId}`,
        method: 'GET',
      }),
      providesTags: (response) =>
        response
          ? [{ type: 'ChainItem' as const, id: response.chainItemId }]
          : [],
    }),
    updateChainItem: build.mutation<
      void,
      UpdateChainItemRequest & { chainId: number; chainItemId: number }
    >({
      query: ({ chainId, chainItemId, ...body }) => ({
        url: `chain/${chainId}/chainItem/${chainItemId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, error, arg) =>
        !error ? [{ type: 'ChainItem' as const, id: arg.chainItemId }] : [],
      transformErrorResponse: (error) =>
        makeError<UpdateChainItemRequest>(error),
    }),
    setChainItemEndState: build.mutation<
      void,
      ChainItemSetEndStateRequest & {
        chainId: number;
        chainItemIdPath: number;
      }
    >({
      query: ({ chainId, chainItemIdPath, ...body }) => ({
        url: `chain/${chainId}/chainItem/${chainItemIdPath}/setEndState`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, error, arg) =>
        !error ? [{ type: 'ChainItem' as const, id: arg.chainItemIdPath }] : [],
      transformErrorResponse: (error) =>
        makeError<ChainItemSetEndStateRequest>(error),
    }),
  }),
});

export const {
  useGetChainItemQuery,
  useUpdateChainItemMutation,
  useSetChainItemEndStateMutation,
} = chainItemApi;

export default chainItemApi;

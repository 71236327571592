import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  SideDrawerFormProps,
  withSideDrawer,
} from '@components/ui-popup/drawers';
import { Skeleton, styled, Typography } from '@mui/material';
import { useAppTr } from '@i18n/use-app-tr';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import WCTTextField from '@components/input/text-field';
import WCTSelectField from '@components/input/select-field';
import useGroupForm from '@pages/app/groups/forms/group-form-hook';
import { ButtonSkeleton, InputSkeleton } from '@components/skeletons';
import { CompanyGroupResource } from '@api/types/company/company-group/company-group.resource';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import Spacer from '@components/layout-util-components/spacer';
import WarningAlert from '@components/alerts/warning-alert';
import { Gap } from '@components/layout-util-components/gap';

export interface GroupFormProps
  extends SideDrawerFormProps<CompanyGroupResource> {
  groupId?: number;
}

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
});

function GroupForm({ onClose, groupId }: GroupFormProps) {
  const { t } = useAppTr('groupForm');
  const { showConfirmationModal } = useUiPopup();
  const isEdit = !!groupId;

  const {
    members,
    adminOptions,
    memberOptions,
    originalMembers,
    originalAdmins,
    admins,
    name,
    submit,
    description,
    isLoading,
    isSubmitting,
    canSubmit,
    originalGroupData,
    removeAdminsFromMembers,
  } = useGroupForm(groupId);

  const onSubmit = async () => {
    if (isEdit) {
      const originalMemberUsersBecomingAdmins = admins.value.filter(
        (adminId) => {
          return originalMembers.some(
            (member) => member.companyUserId === adminId
          );
        }
      );
      const originalAdminUsersBecomingMembers = members.value.filter(
        (memberId) => {
          return originalAdmins.some(
            (admin) => admin.companyUserId === memberId
          );
        }
      );

      const addedAdmins = admins.value
        .filter((userId) => {
          return (
            !originalAdmins.some((user) => user.companyUserId === userId) &&
            !originalMemberUsersBecomingAdmins.includes(userId)
          );
        })
        .map((userId) => adminOptions.find((x) => x.id === userId))
        .filter((x) => x != null);

      const addedAdminNames = addedAdmins.map((x) => x!.label);

      const addedUsers = members.value
        .filter((userId) => {
          return (
            !originalMembers.some((user) => user.companyUserId === userId) &&
            !originalAdminUsersBecomingMembers.includes(userId)
          );
        })
        .map((userId) => memberOptions.find((x) => x.id === userId))
        .filter((x) => x != null);

      const addedUserNames = addedUsers.map((x) => x!.label);

      const removedAdmins = originalAdmins.filter((user) => {
        return (
          !admins.value.some((userId) => user.companyUserId === userId) &&
          !originalAdminUsersBecomingMembers.includes(user.companyUserId)
        );
      });

      const removedAdminNames = removedAdmins.map((x) => x.name);

      const removedUsers = originalMembers.filter((user) => {
        return (
          !members.value.some((userId) => user.companyUserId === userId) &&
          !originalMemberUsersBecomingAdmins.includes(user.companyUserId)
        );
      });

      const removedUserNames = removedUsers.map((x) => x.name);

      const added = [...addedUserNames, ...addedAdminNames];
      const removed = [...removedUserNames, ...removedAdminNames];

      if (added.length > 0) {
        const shouldAdd = await showConfirmationModal({
          title: t('addUserModal.title', {
            count: added.length,
            name: added.join(', '),
          }),
          message: t('addUserModal.message'),
          yesButtonLabel: t('addUserModal.yesButtonLabel'),
          noButtonLabel: t('addUserModal.noButtonLabel'),
          otherActions: groupId
            ? () => (
                <OutlinedButton href={`/analysis/?group=${groupId}`}>
                  View RCA's
                </OutlinedButton>
              )
            : undefined,
        });

        if (!shouldAdd) {
          return;
        }
      }

      if (removed.length > 0) {
        const shouldRemove = await showConfirmationModal({
          title: t('removeUserModal.title', {
            count: removed.length,
            name: removed.join(', '),
          }),
          message: t('removeUserModal.message'),
          yesButtonLabel: t('removeUserModal.yesButtonLabel'),
          noButtonLabel: t('removeUserModal.noButtonLabel'),
          otherActions: groupId
            ? () => (
                <OutlinedButton href={`/analysis/?group=${groupId}`}>
                  View RCA's
                </OutlinedButton>
              )
            : undefined,
        });

        if (!shouldRemove) {
          return;
        }
      }
    }

    const group = await submit();
    if (typeof group === 'number') {
      return;
    }

    if (isEdit) {
      onClose(group || originalGroupData);
    } else if (group != null) {
      onClose(group);
    }
  };

  return (
    <Form
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <DrawerHeader onClose={onClose}>
        {t(isEdit ? 'title.update' : 'title.create')}
      </DrawerHeader>
      <DrawerContent>
        <Typography variant="body1">
          {isLoading ? <Skeleton /> : t('form.groupOverviewLabel')}
        </Typography>
        <Gap size={20} />
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <WCTTextField
            name="name"
            label={t('form.name')}
            value={name.value}
            error={name.error}
            onChange={name.set}
            required
          />
        )}
        <Gap size={20} />
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <WCTTextField
            name="description"
            label={t('form.description')}
            value={description.value}
            error={description.error}
            onChange={description.set}
            multiline
          />
        )}
        <Gap size={32} />
        <Typography variant="body1">
          {isLoading ? (
            <Skeleton />
          ) : (
            t('form.adminUsersLabel', { count: admins.value.length })
          )}
        </Typography>
        <Gap size={20} />
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <WCTSelectField
            name="admins"
            id="admins"
            label={t('form.admins.label')}
            options={adminOptions!}
            value={admins.value}
            error={admins.error}
            onChange={(v) => {
              admins.set(v as Array<number>);
              removeAdminsFromMembers(v as Array<number>);
            }}
            required
            multiple
          />
        )}
        <Gap size={20} />
        <Typography variant="caption">
          {isLoading ? <Skeleton /> : t('form.admins.hint')}
        </Typography>
        <Gap size={20} />
        <Typography variant="body1">
          {isLoading ? (
            <Skeleton />
          ) : (
            t('form.teamMembersLabel', { count: members.value.length })
          )}
        </Typography>
        <Gap size={20} />
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <WCTSelectField
            name="members"
            id="members"
            label={t('form.teamMembers')}
            options={memberOptions!}
            value={members.value}
            error={members.error}
            onChange={(v) => members.set(v as Array<number>)}
            multiple
          />
        )}
        <Spacer />
        <Gap size={15} />
        <WarningAlert message="Team members will be able to view all permitted content created within this group." />
      </DrawerContent>
      <DrawerFooter>
        {isLoading ? (
          <ButtonSkeleton noGrow />
        ) : (
          <OutlinedButton onClick={() => onClose()}>
            {t('cancelButtonLabel')}
          </OutlinedButton>
        )}
        {isLoading ? (
          <ButtonSkeleton noGrow />
        ) : (
          <PrimaryButton
            type="submit"
            disabled={!canSubmit}
            isBusy={isSubmitting}
          >
            {t(isEdit ? 'buttonLabel.update' : 'buttonLabel.create')}
          </PrimaryButton>
        )}
      </DrawerFooter>
    </Form>
  );
}

export default withSideDrawer(GroupForm);

import { SolutionsPanelState } from '@pages/app/rca/tabs/components/editor-panels/solutions-panel-hook';
import useCreateFromCaseSolutionForm from '@pages/app/rca/tabs/components/editor-panels/components/solutions-panel/create-from-case-solution-form-view-hook';
import { ColumnForm } from '@components/layout-util-components/column';
import CaseSolutionItem from '@components/cards/case-solution-item';
import { Skeleton, Typography } from '@mui/material';
import AssociatedCauseBoxBadge from '@components/badges/associated-cause-box-badge';
import { Divider } from '@components/layout-util-components/divider';
import WCTCurrencyField from '@components/input/currency-field';
import { InputSkeleton } from '@components/skeletons';
import InlineRadioSelectGroup from '@components/input/inline-radio-select-group';
import ActionsRow from '@components/layout-util-components/actions-row';
import { LinkButton, OutlinedButton, PrimaryButton } from '@components/buttons';
import { useBinaryOptions } from '@util/constant-options';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  state: SolutionsPanelState;
}

export default function CreateFromCaseSolutionFormView({ state }: Props) {
  const { cancelCreateNewSolution, gotoViewList, updateCaseSolution } = state;
  const {
    additionalCost,
    submit,
    caseSolution,
    canSubmit,
    termOptions,
    term,
    efficacyOptions,
    isLoading,
    effectiveness,
    solutionTypes,
    type,
    isSubmitting,
    showAdditionalCost,
    setShowAdditionalCost,
    currency,
  } = useCreateFromCaseSolutionForm(state);

  const binaryOptions = useBinaryOptions();

  const onSubmit = async () => {
    if (await submit()) {
      gotoViewList();
    }
  };

  return (
    <ColumnForm
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <CaseSolutionItem
        solution={caseSolution}
        hideAssociatedCauseBoxBadge
        displayCost
        currency={currency}
        trailing={
          <LinkButton onClick={() => updateCaseSolution(caseSolution)}>
            Edit Solution
          </LinkButton>
        }
      />
      {caseSolution.chainItems ? (
        <>
          <Gap size={20} />
          <AssociatedCauseBoxBadge count={caseSolution.chainItems.length} />
        </>
      ) : null}
      <Divider addMargin negativeMargin={-25} compact />
      <Typography variant="body1">
        {isLoading ? <Skeleton /> : 'Solution Detail'}
      </Typography>
      <Gap size={15} />
      {isLoading ? (
        <InputSkeleton />
      ) : (
        <InlineRadioSelectGroup
          options={solutionTypes!}
          name="type"
          label="Type"
          onChange={type.set}
          error={type.error}
          value={type.value}
        />
      )}
      <Gap size={15} />
      {isLoading ? (
        <InputSkeleton />
      ) : (
        <InlineRadioSelectGroup
          options={efficacyOptions!}
          name="effectiveness"
          label="Effectiveness"
          onChange={effectiveness.set}
          error={effectiveness.error}
          value={effectiveness.value}
        />
      )}
      <Gap size={15} />
      {isLoading ? (
        <InputSkeleton />
      ) : (
        <InlineRadioSelectGroup
          options={termOptions!}
          name="term"
          label="Term"
          onChange={term.set}
          error={term.error}
          value={term.value}
        />
      )}
      <Gap size={15} />
      {isLoading ? (
        <InputSkeleton />
      ) : (
        <InlineRadioSelectGroup
          options={binaryOptions!}
          name="showAdditionalCost"
          label="Additional cost to implement?"
          onChange={(v) => setShowAdditionalCost((v as boolean) ?? false)}
          value={showAdditionalCost}
        />
      )}
      {showAdditionalCost && (
        <>
          <Gap size={15} />
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTCurrencyField
              name="additionalCost"
              label="Additional Cost"
              onChange={additionalCost.set}
              error={additionalCost.error}
              value={additionalCost.value}
              currency={currency}
            />
          )}
        </>
      )}

      <Divider addMargin negativeMargin={-25} compact />
      <ActionsRow>
        <OutlinedButton onClick={cancelCreateNewSolution}>
          Cancel
        </OutlinedButton>
        <PrimaryButton
          type="submit"
          disabled={!canSubmit}
          isBusy={isSubmitting}
        >
          Save
        </PrimaryButton>
      </ActionsRow>
    </ColumnForm>
  );
}

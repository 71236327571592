import useCustomiseOutcome from '@pages/app/rca/outcome/views/customise-outcome-hook';
import { Grid, Typography } from '@mui/material';
import FullPageFormScaffold from '@components/layouts/scaffolds/full-page-form-scaffold';
import React from 'react';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import Spacer from '@components/layout-util-components/spacer';
import { OutcomeStepProps } from '@pages/app/rca/outcome/rca-outcome-page';
import WCTRichTextField from '@components/input/rich-text-field';
import { Divider } from '@components/layout-util-components/divider';
import WCTSortableListField from '@components/input/sortable-list-field';
import { InputSkeleton } from '@components/skeletons';
import DraggableReportItem, {
  DraggableReportItemProps,
} from '@pages/app/rca/outcome/components/draggable-report-item';
import InlineRadioSelectGroup from '@components/input/inline-radio-select-group';
import WCTSelectField from '@components/input/select-field';
import ShareButton from '@pages/app/rca/outcome/components/share-button';
import { Gap } from '@components/layout-util-components/gap';

export default function CustomiseOutcomeView({
  state,
  onBack,
  onContinue,
}: OutcomeStepProps) {
  const { outcomeId, caseId } = state;
  const {
    recommendations,
    noOfSolutions,
    summary,
    rankBy,
    rankByOptions,
    lenses,
    canSubmit,
    isBusy,
    isLoading,
    toggleSelectedReport,
    submit,
    showLenses,
    showSolutions,
    showRecommendations,
    showSummary,
  } = useCustomiseOutcome(state);

  const renderDraggable = (item: DraggableReportItemProps) => {
    return (
      <DraggableReportItem {...item} onToggleSelected={toggleSelectedReport} />
    );
  };

  return (
    <FullPageFormScaffold
      appBarRef={state.appBarRef}
      onSubmit={async () => {
        if (await submit()) {
          onContinue();
        }
      }}
    >
      <Gap size={64} />
      <Typography variant="h5">Customise outcome</Typography>
      <Gap size={10} />
      <Typography variant="body2">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lectus
        lorem, sollicitudin consequat felis egestas, pretium viverra sapien.
        Vestibulum eu sodales magna.
      </Typography>
      <Gap size={32} />
      {showSummary ? (
        <>
          <Grid container>
            <Grid item container xs={6}>
              <div>
                <Typography variant="body1">Executive Summary</Typography>
                <Typography variant="body2">
                  Provide an overview of why this document should be read.
                </Typography>
              </div>
            </Grid>
            <Grid item container xs={6}>
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <WCTRichTextField
                  name="executiveSummary"
                  placeholder="Executive Summary"
                  value={summary.value}
                  error={summary.error}
                  onChange={summary.set}
                />
              )}
            </Grid>
          </Grid>
        </>
      ) : null}

      {showLenses ? (
        <>
          {showSummary ? <Divider addMargin /> : null}
          <Grid container>
            <Grid item container xs={6}>
              <div>
                <Typography variant="body1">Lenses</Typography>
                <Typography variant="body2">
                  Some detail about lenses..
                </Typography>
              </div>
            </Grid>
            <Grid item container xs={6}>
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <WCTSortableListField
                  name="lenses"
                  itemIdKey="id"
                  value={lenses.value}
                  onChange={lenses.set}
                  RenderItem={renderDraggable}
                />
              )}
            </Grid>
          </Grid>
        </>
      ) : null}

      {showSolutions ? (
        <>
          {showLenses || showSummary ? <Divider addMargin /> : null}
          <Grid container>
            <Grid item container xs={6}>
              <div>
                <Typography variant="body1">Solutions</Typography>
                <Typography variant="body2">
                  Select which team members to feature in your outcome
                </Typography>
              </div>
            </Grid>
            <Grid
              item
              container
              xs={6}
              flexGrow={1}
              alignItems="stretch"
              flexDirection="column"
            >
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <WCTSelectField
                  name="ranking"
                  id="ranking"
                  label="Ranking"
                  options={rankByOptions!.map((option) => ({
                    id: option.reportRankById,
                    label: option.name,
                  }))}
                  value={rankBy.value}
                  error={rankBy.error}
                  onChange={rankBy.set}
                  required
                />
              )}

              <Gap size={15} />

              {isLoading ? (
                <InputSkeleton />
              ) : (
                <InlineRadioSelectGroup
                  name="display"
                  label="Display"
                  options={[
                    {
                      id: 3,
                      label: 'Top 3 Solutions',
                    },
                    {
                      id: 6,
                      label: 'Top 6 Solutions',
                    },
                  ]}
                  value={noOfSolutions.value}
                  error={noOfSolutions.error}
                  onChange={noOfSolutions.set}
                />
              )}
            </Grid>
          </Grid>
        </>
      ) : null}

      {showRecommendations ? (
        <>
          {showLenses || showSummary || showSolutions ? (
            <Divider addMargin />
          ) : null}
          <Grid container>
            <Grid item container xs={6}>
              <div>
                <Typography variant="body1">Closing Statement</Typography>
                <Typography variant="body2">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                  lectus lorem.
                </Typography>
              </div>
            </Grid>
            <Grid item container xs={6}>
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <WCTRichTextField
                  name="recommendations"
                  placeholder="Closing Statement"
                  value={recommendations.value}
                  error={recommendations.error}
                  onChange={recommendations.set}
                />
              )}
            </Grid>
          </Grid>
        </>
      ) : null}

      <ActionsRow anchorBottom addPadding filled>
        <OutlinedButton onClick={onBack}>Back</OutlinedButton>
        <Spacer />
        {outcomeId != null ? (
          <ShareButton caseId={caseId} outcomeId={outcomeId} />
        ) : null}
        <PrimaryButton type="submit" disabled={!canSubmit} isBusy={isBusy}>
          Continue
        </PrimaryButton>
      </ActionsRow>
    </FullPageFormScaffold>
  );
}

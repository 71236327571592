import { UserOutcome } from '@api/types/dashboard/user-outcomes-response';
import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import { faCalendar, faDiagramProject } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDateCustom } from '@i18n/formatters';
import { Box, Typography, styled } from '@mui/material';
import { getInitials } from '@util/name-util';

interface Props {
  outcome: UserOutcome;
}

const StyledRow = styled(Box)(({ theme: { palette } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
  alignSelf: 'stretch',
  borderRadius: 5,
  border: '1px solid rgba(0, 0, 0, 0.25)',
  background: palette.common.white,
  padding: 16,
  justifyContent: 'space-between',
  p: {
    '&.heading': {
      color: 'rgba(0, 0, 0, 0.50)',
      fontSize: 11,
      fontWeight: 500,
    },
    '&.value': {
      color: palette.common.black,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '120%' /* 16.8px */,
      letterSpacing: '0.15px',
      '&.title': {
        fontSize: 16,
      },
    },
  },
  '>.MuiBox-root': {
    gap: 15,
    alignItems: 'center',
    padding: '0 30px',
  },
  '.MuiBox-root': {
    display: 'flex',
    '&.section': {
      gap: 10,
      alignItems: 'center',
    },
    '&.icon-box': {
      width: 32,
      height: 32,
      borderRadius: 32,
      background: '#F3F3F3',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      wordWrap: 'break-word',
      '&.status': {
        width: 'auto',
        padding: '0px 5px',
      },
    },
    '&.details': {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
}));

export const RCAUserOutcomeRow: React.FC<Props> = ({ outcome }) => (
  <StyledRow>
    <Box>
      <Typography className="value title">{outcome.name}</Typography>
    </Box>
    <Box>
      <Box className="section">
        <Box className="icon-box">
          <FontAwesomeIcon icon={faDiagramProject} />
        </Box>
        <Box className="details">
          <Typography className="heading">RCA Problem Analysis</Typography>
          <Typography className="value">
            {outcome.createdByCompanyUserId}
          </Typography>
        </Box>
      </Box>
      <Box className="section">
        <Box className="icon-box">
          <FontAwesomeIcon icon={faCalendar} />
        </Box>
        <Box className="details">
          <Typography className="heading">CREATED</Typography>
          <Typography className="value">
            {formatDateCustom(outcome.created, 'd MMM YY')}
          </Typography>
        </Box>
      </Box>
      <InitialsContainerWithData initials={getInitials(outcome.createdBy)} />
    </Box>
  </StyledRow>
);

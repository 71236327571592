import { PropsWithChildren, ReactNode } from 'react';
import { styled } from '@mui/material';

interface Props {
  appbar: ReactNode;
  sidebar?: ReactNode;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  height: '100vh',
});

const InnerContainer = styled('div')({
  height: 'calc(100vh - 80x)',
  flex: '1 1 auto',
  display: 'flex',
  alignItems: 'stretch',
  backgroundColor: '#eee',
});

const ContentContainer = styled('div', {
  target: 'rca-form-scaffold-content-container',
})({
  display: 'flex',
  justifyContent: 'stretch',
  flex: '1 0 auto',
});

const SidebarContainer = styled('div')({
  overflowY: 'auto',
  backgroundColor: '#E5E5E5',
  flex: '0 1 354px',
  height: 'calc(100vh - 80x)',
  padding: 25,
  '>*': {
    minHeight: '100%',
  },
});

export default function RcaCreateScaffold({
  appbar,
  sidebar,
  children,
}: PropsWithChildren<Props>) {
  return (
    <Container>
      {appbar}
      <InnerContainer>
        <ContentContainer>{children}</ContentContainer>
        {sidebar ? <SidebarContainer>{sidebar}</SidebarContainer> : null}
      </InnerContainer>
    </Container>
  );
}

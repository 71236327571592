import { StatusPanel } from '@pages/app/outcome/components/status-panel';
import Column from '@components/layout-util-components/column';
import { Box, Typography } from '@mui/material';
import { LoadingIndicator } from '@components/loading-indicator';

export default function CheckingPermissionView() {
  return (
    <StatusPanel>
      <Column>
        <Typography variant="h6">Checking Permission</Typography>
        <Typography variant="body1">
          Please wait while we check your permission to view this outcome.
        </Typography>
        <Box mt={3} alignSelf="center" position="relative">
          <LoadingIndicator show />
        </Box>
      </Column>
    </StatusPanel>
  );
}

import { ChainItemNoteResource } from '@api/types/chain/chain-item-notes/chain-item-note.resource';
import { styled } from '@mui/material';
import Column from '@components/layout-util-components/column';
import useUser from '@store/user/user-hook';
import { ElipsisButton } from '@components/buttons';
import Spacer from '@components/layout-util-components/spacer';
import Row from '@components/layout-util-components/row';
import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import { formatDateCustom } from '@i18n/formatters';
import ShowMoreText from 'react-show-more-text';
import { PopoverMenuAction } from '@components/popover-menu/use-popover-menu';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  note: ChainItemNoteResource;
  onEdit?: () => void;
  onDelete?: () => void;
}

const Container = styled('div', { target: 'note-row-container' })({
  border: '1px solid #00000040',
  borderRadius: 5,
  marginBottom: 10,
  display: 'flex',
  alignItems: 'center',
  padding: 10,

  '.note-content': {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0.17000000178813934px',
    textAlign: 'left',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },

  '.note-date': {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '17px',
    letterSpacing: '0.17000000178813934px',
    textAlign: 'left',
  },
});

export default function NoteRow({ note, onEdit, onDelete }: Props) {
  const { companyUserId: loggedInUserId, fullName } = useUser();
  const { note: content, companyUserId, created } = note;

  const menuActions: Array<PopoverMenuAction> = [];
  if (loggedInUserId === companyUserId) {
    if (onEdit) {
      menuActions.push({
        label: 'Edit',
        onClick: onEdit,
      });
    }
    if (onDelete) {
      menuActions.push({
        label: 'Delete',
        onClick: onDelete,
      });
    }
  }

  return (
    <Container>
      <Column>
        <ShowMoreText className="note-content" lines={2}>
          {content}
        </ShowMoreText>
        <Gap size={10} />
        <Row>
          <InitialsContainerWithData fullName={fullName} />
          <Gap size={15} />
          <div className="note-date">
            {formatDateCustom(created, 'D MMM YY - HH:mm')}
          </div>
        </Row>
      </Column>
      <Spacer />
      {loggedInUserId === companyUserId ? (
        <>
          <Gap size={4} />
          <ElipsisButton actions={menuActions} />
        </>
      ) : null}
    </Container>
  );
}

import { SolutionsPanelState } from '@pages/app/rca/tabs/components/editor-panels/solutions-panel-hook';
import useSolutionsOptionsView from '@pages/app/rca/tabs/components/editor-panels/components/solutions-panel/solutions-options-view-hook';
import Column from '@components/layout-util-components/column';
import { FlatButton, OutlinedButton } from '@components/buttons';
import { Box, Skeleton, Typography } from '@mui/material';
import { Divider } from '@components/layout-util-components/divider';
import Row from '@components/layout-util-components/row';
import { ButtonSkeleton } from '@components/skeletons';
import { LoadingIndicator } from '@components/loading-indicator';
import CaseSolutionItem from '@components/cards/case-solution-item';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  state: SolutionsPanelState;
}

export default function SolutionsOptionsView({ state }: Props) {
  const { createNewSolution } = state;
  const {
    solutions,
    isLoading,
    selectCaseSolution,
    isFetchingSolutionResource,
    currency,
  } = useSolutionsOptionsView(state);

  return (
    <Column>
      {isLoading ? (
        <ButtonSkeleton />
      ) : (
        <OutlinedButton onClick={createNewSolution}>
          Add New Solution
        </OutlinedButton>
      )}
      <Gap size={20} />
      {isLoading || solutions!.totalCount > 0 ? (
        <Row>
          <Box flexGrow={1} pr={2}>
            <Divider />
          </Box>
          <span>{isLoading ? <Skeleton /> : 'Or'}</span>
          <Box flexGrow={1} pl={2}>
            <Divider />
          </Box>
        </Row>
      ) : null}
      <Gap size={20} />
      <Typography variant="body1">
        {isLoading ? (
          <Skeleton />
        ) : solutions!.totalCount > 0 ? (
          'Add existing solution'
        ) : (
          ''
        )}
      </Typography>
      <Gap size={10} />
      {isLoading ? (
        <LoadingIndicator show />
      ) : (
        solutions?.model.map((solution) => (
          <>
            <CaseSolutionItem
              key={solution.solutionId}
              solution={solution}
              currency={currency}
              trailing={
                <FlatButton
                  disabled={isFetchingSolutionResource}
                  onClick={() => selectCaseSolution(solution)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </FlatButton>
              }
            />
            <Gap size={8} />
          </>
        ))
      )}
    </Column>
  );
}

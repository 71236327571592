import {
  useGetCaseSolutionsQuery,
  useLazyGetCaseSolutionByIdQuery,
} from '@api/endpoints/case.api';
import { SolutionsPanelState } from '@pages/app/rca/tabs/components/editor-panels/solutions-panel-hook';
import { useEffect, useRef } from 'react';
import { CaseSolutionItemResponse } from '@api/types/case/case-solution/case-solution-item.response';
import { useAppSelector } from '@store/store';
import { selectCurrentRcaCurrency } from '@store/rca-editor/selectors';

export default function useSolutionsOptionsView({
  createNewFromCaseSolution,
  createNewSolution,
  caseId,
}: SolutionsPanelState) {
  const {
    data: solutions,
    isLoading: loadingSolutions,
    isSuccess,
  } = useGetCaseSolutionsQuery({ caseId: caseId });
  const currency = useAppSelector(selectCurrentRcaCurrency);
  const [getCaseSolutionById, { isLoading: isFetchingSolutionResource }] =
    useLazyGetCaseSolutionByIdQuery();

  const isLoading = loadingSolutions;

  const createRef = useRef(createNewSolution);
  useEffect(() => {
    if (isSuccess && solutions?.totalCount === 0) {
      createRef.current();
    }
  }, [isSuccess, solutions?.totalCount]);

  const selectCaseSolution = async (solution: CaseSolutionItemResponse) => {
    const resource = await getCaseSolutionById({
      caseId,
      solutionId: solution.solutionId,
    }).unwrap();
    createNewFromCaseSolution(resource);
  };

  return {
    solutions,
    isLoading,
    isFetchingSolutionResource,
    selectCaseSolution,
    currency,
  };
}

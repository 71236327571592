import { ListItemRow } from '@components/containers/list-container';
import { Badge, Box, Chip, Typography } from '@mui/material';
import { ElipsisButton } from '@components/buttons';
import { CaseEvidenceResource } from '@api/types/case/case-evidence/case-evidence.resource';

import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import AssociatedCauseBoxBadge from '@components/badges/associated-cause-box-badge';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  evidence: CaseEvidenceResource;
  onView: () => void;
  onEdit: () => void;
  onDelete: () => void;
}
export default function EvidenceRow({
  evidence,
  onView,
  onEdit,
  onDelete,
}: Props) {
  const { description, name, sensitivity, caseFiles, chainItems, quality } =
    evidence;

  return (
    <ListItemRow onClick={onView}>
      <div>
        <Typography>{name}</Typography>
        <Typography>{description}</Typography>
      </div>
      <div>
        <Badge badgeContent={caseFiles.length} color="primary">
          <FolderOpenIcon />
        </Badge>
      </div>
      <div>
        {!!chainItems?.length ? (
          <Box display="flex" justifyContent="flex-start">
            <AssociatedCauseBoxBadge count={chainItems.length} />
          </Box>
        ) : null}
      </div>
      <div>
        <Chip label={sensitivity} />
      </div>
      <div>
        <Chip label={`${quality}/5`} />
      </div>
      <Box display="flex" justifyContent="flex-end">
        <ElipsisButton
          actions={[
            {
              label: 'View',
              onClick: () => onView(),
            },
            {
              label: 'Edit',
              onClick: () => onEdit(),
            },
            {
              label: 'Delete',
              onClick: () => onDelete(),
            },
          ]}
        />
        <Gap size={10} />
      </Box>
    </ListItemRow>
  );
}

import { WCTTable } from '@components/table';
import RcaTableRow from '@pages/app/analysis/components/rca-table-row';
import { AnalysisOverviewState } from '@pages/app/analysis/analysis-overview-hook';

export default function AnalysisTableView({
  sort,
  pagination,
  caseData,
}: Pick<AnalysisOverviewState, 'sort' | 'pagination' | 'caseData'>) {
  const data = caseData!.model!;

  return (
    <>
      <WCTTable
        count={5}
        pagination={pagination}
        headers={[
          { title: 'Name' },
          { title: 'Channel' },
          { title: 'Create Date', sortProperty: 'created', ...sort },
          { title: 'Incident Location' },
          { title: 'Severity' },
          { title: 'Contributors' },
          { title: 'Status' },
          { title: 'Health Score' },
          { title: <></> },
        ]}
      >
        {data.map((data) => (
          <RcaTableRow key={data.caseId} data={data} />
        ))}
      </WCTTable>
    </>
  );
}

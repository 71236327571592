import { Box, Button, Grid, Link, styled, Typography } from '@mui/material';
import WCTTextField from '@components/input/text-field';
import { useLocation, useNavigate } from 'react-router-dom';
import useLogin, { LoginResultAction } from '@pages/auth/login-hook';
import WCTCheckboxField from '@components/input/checkbox-field';
import { PageAlertDisplay, usePageAlertVariants } from '@components/alerts';
import { useAuthTr } from '@i18n/use-auth-tr';
import NoAccountSignupLink from '@pages/auth/components/no-account-signup-link';
import { useAppDispatch } from '@store/store';
import { refreshUserState } from '@store/user/user-slice';
import { useEffect } from 'react';
import { isApp } from '@util/env';
import useUser from '@store/user/user-hook';
import { Navigate } from 'react-router';
import useSearchParamsTyped from '@hooks/router-dom-helper-hooks';
import { LoadingIndicator } from '@components/loading-indicator';

type QueryParams = {
  inviteToken?: string;
};

export interface LoginPageLocationState {
  prompt?: 'password-reset-success' | 'logout-success';
  returnTo?: string;
  noRedirect?: boolean;
}

const StyledBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: -10,
  '.MuiFormControlLabel-root': {
    gap: 4,
  },
});

const StyledButton = styled(Button)({
  display: 'flex',
  height: 56,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 3,
  marginTop: 8,
  border: '1px solid rgba(58, 58, 58, 0.11)',
  background: 'linear-gradient(180deg, #FF6C00 0%, #FF5451 100%)',
  p: {
    color: 'white',
    // fontFamily: 'Usual',
    fontSize: 15,
    fontWeight: 700,
    lineHeight: '26px' /* 173.333% */,
    letterSpacing: '0.46px',
  },
});

const StyledTextField = styled(WCTTextField)({
  '.MuiFormHelperText-root': {
    fontSize: 12,
    margin: 0,
  },
  label: {
    color: 'rgba(0, 0, 0, 0.45)',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    transform: 'translate(12px, 12px) scale(1)',
    '&.MuiFormLabel-filled, &.Mui-focused': {
      transform: 'translate(12px, 3px) scale(0.75)',
    },
  },
  '.MuiInputBase-root': {
    minHeight: '48px',
    display: 'flex',
    padding: 12,
    borderRadius: 4,
    border: '1px solid #212121',
    background: 'white',
    '&.Mui-focused': {
      outline: '5px solid rgba(255, 108, 0, 0.12)',
    },
    '.MuiInputAdornment-root': {
      button: {
        svg: {
          fill: '#FF5F30',
          width: 21,
        },
      },
    },
    input: {
      padding: 0,
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '24px' /* 150% */,
      letterSpacing: '0.15px',
      marginBottom: -15,
    },
  },
});

export default function LoginPage() {
  const { inviteToken } = useSearchParamsTyped<QueryParams>();
  const { isLoggedInAndFullyAuthenticated } = useUser();
  const { email, password, rememberMe, submit, canSubmit, isLoading } =
    useLogin(inviteToken);

  const { t } = useAuthTr('login');

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { state } = useLocation();
  const { prompt, returnTo, noRedirect } = (state ||
    {}) as LoginPageLocationState;

  const { showSuccessAlert } = usePageAlertVariants();

  useEffect(() => {
    if (!!prompt) {
      switch (prompt) {
        case 'logout-success':
          showSuccessAlert({
            title: t('prompts.logout'),
          });
          break;
        case 'password-reset-success': {
          showSuccessAlert({
            title: t('prompts.passwordReset.title'),
            message: t('prompts.passwordReset.message'),
          });
          break;
        }
      }
    }
  }, [prompt, showSuccessAlert, t]);

  const onSubmit = async () => {
    const action = await submit();
    if (action == null) {
      return;
    }

    switch (action) {
      case LoginResultAction.Proceed:
        dispatch(refreshUserState());
        navigate(returnTo || '/', { replace: true });
        break;
      case LoginResultAction.InvalidAttemptsError:
        navigate('/auth/login-error', {
          state: {
            title:
              'Your account has been locked due to too many failed login attempts.',
            message:
              '\n' +
              'To unlock your account you will need to <a href="/auth/forgot-password">reset your password</a> and follow the instructions.',
          },
        });

        break;
      case LoginResultAction.LockoutDueToInactivityError:
        navigate('/auth/login-error', {
          state: {
            title:
              'Your account has been locked due to an extended period of inactivity.',
            message:
              '\n' +
              'To unlock your account you will need to <a href="/auth/forgot-password">reset your password</a> and follow the instructions.',
          },
        });

        break;
      default:
        break;
    }
  };

  if (isLoggedInAndFullyAuthenticated && !noRedirect) {
    return <Navigate to="/" />;
  }

  if (isLoading) {
    return <LoadingIndicator show={isLoading} />;
  }

  return (
    <Grid
      component="form"
      noValidate
      container
      position="relative"
      direction="column"
      gap={2}
      justifyContent="center"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <Typography variant="h1">{t('title')}</Typography>
      <PageAlertDisplay />
      <Typography fontSize={18} variant="body1" color="common.textMid">
        {t('subtitle')}
      </Typography>
      <StyledTextField
        label={t('form.email')}
        name="email"
        value={email.value}
        error={email.error}
        onChange={email.set}
        required
      />

      <StyledTextField
        label={t('form.password')}
        name="password"
        value={password.value}
        error={password.error}
        onChange={password.set}
        required
        obscure
      />

      <StyledBox>
        <WCTCheckboxField
          label={t('form.rememberMe')}
          value={rememberMe.value}
          onChange={rememberMe.set}
        />
        <Link href="../forgot-password">{t('forgotPasswordLink')}</Link>
      </StyledBox>

      <StyledButton type="submit" size="large" disabled={!canSubmit}>
        <Typography>{t('buttonLabel')}</Typography>
      </StyledButton>

      {isApp && <NoAccountSignupLink />}
    </Grid>
  );
}

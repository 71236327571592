import { ReactNode } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { ReactComponent as StatIndicator } from '@assets/svgs/stat-indicator.svg';

interface Props {
  label: 'OPEN TASKS' | 'OVERDUE TASKS' | 'HIGH PRIORITY';
  stat: ReactNode;
}

const Container = styled('div')(({ theme: { palette } }) => ({
  minWidth: 250,
  padding: '15px 20px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: palette.common.white,
  borderRadius: 4,
  flex: '1 0 0',
}));

export default function TasksStat({ label, stat }: Props) {
  const LABEL_TO_COLOUR: Record<
    Props['label'],
    { fill: string; stroke: string }
  > = {
    'HIGH PRIORITY': {
      fill: '#FF3D3D',
      stroke: 'rgba(255, 98, 98, 0.10)',
    },
    'OVERDUE TASKS': {
      fill: '#FF6C00',
      stroke: 'rgba(255, 108, 0, 0.10)',
    },
    'OPEN TASKS': {
      fill: '#7A9BBA',
      stroke: 'rgba(95, 150, 173, 0.12)',
    },
  };

  return (
    <Container>
      <Box display="flex" gap="12px" alignItems="center">
        <StatIndicator {...LABEL_TO_COLOUR[label]} />
        <Typography
          fontSize={12}
          fontWeight={400}
          lineHeight="18px"
          letterSpacing="0.15px"
          color="#001927"
        >
          {label}
        </Typography>
      </Box>
      <Typography fontSize={20} fontWeight={400}>
        {stat}
      </Typography>
    </Container>
  );
}

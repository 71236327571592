import useField from '@hooks/use-field-hook';
import { required, validEmail } from '@util/validators';
import { useMemo } from 'react';
import { useGetSectorOptionsQuery } from '@api/endpoints/sector.api';
import { ApiError } from '@api/types/api-error';
import { CreateUserRequest } from '@api/types/user/create-user.request';
import { usePageAlertVariants } from '@components/alerts';
import { useAdminTr } from '@i18n/use-admin-tr';
import { useGetAdminRoleOptionsQuery } from '@api/endpoints/admin/admin-role.api';
import { useAdminCreateUserMutation } from '@api/endpoints/admin/admin-user.api';
import { useGetAdminCompanyOptionsQuery } from '@api/endpoints/admin/admin-company.api';
import useFieldsWatcher from '@hooks/use-fields-watcher';

export default function useCreateUser() {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const { t } = useAdminTr('createUserForm');

  const { data: sectorOptions, isLoading: loadingSectors } =
    useGetSectorOptionsQuery();
  const { data: companyOptions, isLoading: loadingCompanies } =
    useGetAdminCompanyOptionsQuery();
  const { data: roleOptions, isLoading: loadingRoles } =
    useGetAdminRoleOptionsQuery();

  const [createUser, { isLoading: isSubmitting }] =
    useAdminCreateUserMutation();

  const firstName = useField<string>([required()]);
  const surname = useField<string>([required()]);
  const email = useField<string>([required(), validEmail()]);
  const companyRole = useField<string>([required()]);
  const sector = useField<Array<number>>(
    undefined,
    useMemo(() => [], [])
  );
  const jobTitle = useField<string>();
  const company = useField<Array<number>>(
    undefined,
    useMemo(() => [], [])
  );

  const { isDirty, isValid, validateAll } = useFieldsWatcher([
    firstName,
    surname,
    email,
    companyRole,
    sector,
    jobTitle,
    company,
  ]);

  const isLoading = loadingSectors || loadingCompanies || loadingRoles;
  const canSubmit = !isLoading && !isSubmitting && isDirty && isValid;

  const submit = async (): Promise<boolean> => {
    if (!validateAll()) {
      return false;
    }

    return createUser({
      firstName: firstName.value,
      lastName: surname.value,
      jobTitle: jobTitle.value,
      sectorIds: sector.value,
      emailAddress: email.value,
      managerCompanyIds: company.value,
      role: companyRole.value,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage(
          t('alerts.userAdded', { name: `${firstName.value} ${surname.value}` })
        );
        return true;
      })
      .catch(({ message, errors }: ApiError<CreateUserRequest>) => {
        showErrorMessage(message);

        firstName.setError(errors?.firstName);
        surname.setError(errors?.lastName);
        jobTitle.setError(errors?.jobTitle);
        sector.setError(errors?.sectorIds);
        email.setError(errors?.emailAddress);
        company.setError(errors?.managerCompanyIds);
        companyRole.setError(errors?.role);

        return false;
      });
  };

  return {
    firstName,
    surname,
    email,
    companyRole,
    sector,
    jobTitle,
    company,
    canSubmit,
    sectorOptions,
    companyOptions,
    roleOptions,
    isLoading,
    isSubmitting,
    submit,
  };
}

import { ReactNode } from 'react';
import { styled } from '@mui/material';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  icon: ReactNode;
  label: string;
  value: string | number;
}

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flex: '0 1 30%',
  backgroundColor: 'white',
  padding: '12px 20px 12px 16px',

  borderRadius: 4,
  '.icon-container': {
    width: 32,
    height: 32,
    borderRadius: 4,
    backgroundColour: '#EDEDED',
    color: '#595959',
  },

  '.data': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    h3: {
      margin: 0,
      padding: 0,
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: '500',
      lineHeight: '18px',
      letterSpacing: '0.15000000596046448px',
      textAlign: 'left',
    },

    span: {
      margin: 0,
      padding: 0,
      fontFamily: 'Roboto',
      fontSize: '18px',
      fontWeight: '400',
      lineHeight: '24px',
      letterSpacing: '0px',
      textAlign: 'right',
    },
  },
});

export default function OverviewStatContainer({ icon, label, value }: Props) {
  return (
    <Container>
      <div className="icon-container">{icon}</div>
      <Gap size={15} />
      <div className="data">
        <span>{value}</span>
        <h3>{label}</h3>
      </div>
    </Container>
  );
}

import { CoverageReportMetaData } from '@pages/app/rca/tabs/reports/views/coverage-report-view';
import { ReportRow } from '@api/types/case/case-report/run-report.response';

export namespace ReportUtil {
  export function getRank(
    rowClientGeneratedId: number,
    rows: ReportRow[],
    meteData: CoverageReportMetaData[]
  ) {
    const rowIndex = rows.findIndex(
      (x) => x.clientGeneratedId === rowClientGeneratedId
    );
    if (rowIndex === -1) {
      return;
    }

    return meteData[rowIndex].rank;
  }

  export function isCoverageMetaData(
    data: any
  ): data is CoverageReportMetaData[] {
    if (!Array.isArray(data) || data.length === 0) {
      return false;
    }

    return data[0].rank != null && data[0].chainItems != null;
  }
}

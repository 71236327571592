import { api } from '@api/api';
import { PaginatedResult } from '@api/types/paginated-result';
import { CaseFileListItemResponse } from '@api/types/case/case-file/case-file-list-item.response';
import { CaseFileFiltersRequest } from '@api/types/case/case-file/case-file-filters.request';
import { BaseFile } from '@api/types/file-types';
import { CreateCaseFileRequest } from '@api/types/case/case-file/create-case-file.request';
import { makeError } from '@api/types/api-error';
import { CaseFileResource } from '@api/types/case/case-file/case-file.resource';

const caseFileApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCaseFileDetail: build.query<
      CaseFileResource,
      { caseId: number; caseFileId: number }
    >({
      query: ({ caseId, caseFileId }) => ({
        url: `case/${caseId}/caseFile/${caseFileId}`,
        method: 'GET',
      }),
      providesTags: ['CaseFile'],
    }),
    getAllCaseFiles: build.query<
      PaginatedResult<CaseFileListItemResponse>,
      CaseFileFiltersRequest & { caseId: number }
    >({
      query: ({ caseId, ...params }) => ({
        url: `case/${caseId}/caseFile`,
        method: 'GET',
        params,
      }),
      providesTags: ['CaseFile'],
    }),
    uploadCaseFile: build.mutation<
      Array<BaseFile>,
      { caseId: number; files: Array<File> }
    >({
      query: ({ caseId, files }) => ({
        url: `case/${caseId}/caseFile/file`,
        method: 'POST',
        body: files,
      }),
      invalidatesTags: ['CaseTotals'],
    }),
    createCaseFile: build.mutation<
      CaseFileResource[],
      CreateCaseFileRequest & { caseId: number }
    >({
      query: ({ caseId, ...body }) => ({
        url: `case/${caseId}/caseFile`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CaseFile', 'CaseTotals'],
    }),
    removeCaseFile: build.mutation<
      void,
      { caseFileId: number; caseId: number }
    >({
      query: ({ caseId, caseFileId }) => ({
        url: `case/${caseId}/caseFile/${caseFileId}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (error) => makeError<never>(error),
      invalidatesTags: ['CaseFile', 'AllCaseFiles', 'CaseTotals'],
    }),
  }),
});

export const {
  useGetAllCaseFilesQuery,
  useRemoveCaseFileMutation,
  useGetCaseFileDetailQuery,
} = caseFileApi;

export default caseFileApi;

import { api } from '@api/api';
import { PaginatedResult } from '@api/types/paginated-result';
import { ChainItemActionRow } from '@api/types/chain/chain-item-health-score/chain-item-action.response';
import { ChainItemActionRequest } from '@api/types/chain/chain-item-health-score/chain-item-action.request';

export interface ChainItemSolutionPathParams {
  chainId: number;
  chainItemId: number;
}

const chainItemHealthScoreActionsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getHealthScoreActions: build.query<
      PaginatedResult<ChainItemActionRow>,
      ChainItemActionRequest
    >({
      query: ({ chainId, ...params }) => ({
        url: `chain/${chainId}/actions`,
        method: 'GET',
        params,
      }),
      providesTags: ['CaseTotals'],
    }),
  }),
});

export const { useGetHealthScoreActionsQuery } = chainItemHealthScoreActionsApi;

export default chainItemHealthScoreActionsApi;

import { Grid, Typography } from '@mui/material';
import useConfirmEmailRequest from '@pages/auth/confirm-email-request-hook';
import { OutlinedButton } from '@components/buttons';
import { useAuthTr } from '@i18n/use-auth-tr';
import ContactSupportLink from '@pages/auth/components/contact-support-link';
import { LoadingIndicator } from '@components/loading-indicator';
import { PageAlertDisplay } from '@components/alerts';
import { Gap } from '@components/layout-util-components/gap';

export default function ConfirmEmailRequestPage() {
  const { email, resendEmail, isSending } = useConfirmEmailRequest();

  const { t } = useAuthTr('confirmEmailRequest');

  return (
    <Grid container direction="column" gap={2} justifyContent="center">
      <Typography variant="h4">{t('title')}</Typography>
      <Typography variant="body1">{t('message', { email })}</Typography>

      <Gap size={2} />
      <PageAlertDisplay />

      <OutlinedButton onClick={resendEmail} disabled={isSending}>
        {t('buttonLabel')} <LoadingIndicator show={isSending} />
      </OutlinedButton>

      <ContactSupportLink />
    </Grid>
  );
}

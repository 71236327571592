import { InputAdornment, styled, TextField } from '@mui/material';
import { DefaultTFuncReturn } from 'i18next';
import { TextFieldMaxSize } from '@components/input/text-field';
import { optionalLabel } from '@util/string-util';
import { useState } from 'react';
import { formatCurrency, parseCurrency } from '@util/currency-util';
import getSymbolFromCurrency from 'currency-symbol-map';

interface Props {
  label?: string | DefaultTFuncReturn;
  name?: string;
  required?: boolean;
  value?: string;
  placeholder?: string | DefaultTFuncReturn;
  error?: string | DefaultTFuncReturn;
  onChange: (value: string) => void;
  onBlur?: () => void;
  readonly?: boolean;
  className?: string;
  currency?: string;
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  margin: 0,
  padding: 0,
  maxWidth: TextFieldMaxSize.small,
  '& .MuiInputBase-root': {
    borderRadius: '4px',
    boxSizing: 'border-box',
    border: `2px solid ${theme.palette.common.grey50}`,
    minHeight: '56px',
    '&::before': {
      display: 'none',
    },
    '&:hover::before': {
      display: 'none',
    },
    '&.Mui-focused': {
      border: `2px solid ${theme.palette.primary.main}`,
      '&::after': {
        display: 'none',
      },
    },
    '& input:-webkit-autofill': {
      WebkitBackgroundClip: 'text',
    },
    '& input:-webkit-autofill:hover': {
      WebkitBackgroundClip: 'text',
    },
    '& input:-webkit-autofill:focus': {
      WebkitBackgroundClip: 'text',
    },
    '& input:-webkit-autofill:active': {
      WebkitBackgroundClip: 'text',
    },
  },
  '.MuiInputAdornment-root, .MuiInputAdornment-filled': {
    height: 'unset',
    width: 49,
    maxHeight: 'unset',
    alignSelf: 'stretch',
    justifyContent: 'center',
    marginLeft: -11,
    backgroundColor: '#CECECE',
  },
}));

export default function WCTCurrencyField({
  label,
  name,
  required,
  value,
  error,
  placeholder,
  onChange,
  onBlur,
  readonly,
  className,
  currency = 'GBP',
}: Props) {
  const [currencyValue, setCurrencyValue] = useState<string | undefined>(
    formatCurrency(value || '', currency)
  );

  const onInputChange = (newValue: string) => {
    onChange(parseCurrency(newValue));
    setCurrencyValue(newValue);
  };

  const onInputBlur = () => {
    setCurrencyValue(formatCurrency(currencyValue || '', currency));
    onBlur && onBlur();
  };

  return (
    <StyledTextField
      variant="filled"
      label={optionalLabel(label, required)}
      name={name}
      className={className}
      helperText={error}
      placeholder={placeholder as string | undefined}
      required={required}
      fullWidth
      value={currencyValue}
      error={!!error}
      disabled={readonly}
      onChange={(e: any) => {
        if (e == null) {
          onInputChange('');
        } else if (typeof e === 'string') {
          onInputChange(e);
        } else {
          onInputChange(e.target.value);
        }
      }}
      onBlur={onInputBlur}
      type="text"
      hiddenLabel={!label}
      InputProps={{
        readOnly: readonly,
        startAdornment: (
          <InputAdornment position="start">
            {getSymbolFromCurrency(currency)}
          </InputAdornment>
        ),
      }}
    />
  );
}

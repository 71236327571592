import Column, { ColumnForm } from '@components/layout-util-components/column';
import EditPanelHeader from '@pages/app/rca/tabs/components/editor-panels/edit-panel-header';
import useTasksPanel from '@pages/app/rca/tabs/components/editor-panels/tasks-panel-hook';
import { LoadingIndicator } from '@components/loading-indicator';
import NoTasksInfo from '@pages/app/tasks/components/no-tasks-info';
import { Box, Button } from '@mui/material';
import WCTTextField from '@components/input/text-field';
import WCTSelectField from '@components/input/select-field';
import Row from '@components/layout-util-components/row';
import WCTDateField from '@components/input/date-field';
import PrioritySelectorField from '@components/input/priority-selector-field';
import { Divider } from '@components/layout-util-components/divider';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import WCTCheckboxField from '@components/input/checkbox-field';
import { SortByFilter } from '@components/table';
import TaskPanelTaskRow from '@pages/app/rca/tabs/components/editor-panels/components/task-panel-task-row';
import { Gap } from '@components/layout-util-components/gap';

export default function TasksPanel() {
  const {
    tasks,
    isLoading,
    showForm,
    beginAddingTask,
    description,
    dueDate,
    assignedUser,
    userOptions,
    name,
    priority,
    canSubmit,
    isSubmitting,
    isEditting,
    sort,
    sortOptions,
    showCompleted,
    submit,
    completeTask,
    openTask,
    isChangingTaskStatus,
    setShowForm,
    beginEditting,
  } = useTasksPanel();

  const taskCount = tasks?.count ?? 0;

  return (
    <Column>
      {showForm ? (
        <EditPanelHeader title={isEditting ? 'Edit Task' : 'Create Task'} />
      ) : (
        <EditPanelHeader
          title="Tasks"
          count={tasks?.count}
          end={
            taskCount > 0 && (
              <OutlinedButton onClick={beginAddingTask}>
                Create Task
              </OutlinedButton>
            )
          }
        />
      )}
      <Gap size={24} />
      {isLoading ? (
        <LoadingIndicator show />
      ) : showForm ? (
        <ColumnForm
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            submit();
          }}
        >
          <WCTTextField
            name="name"
            label="Task Name"
            value={name.value}
            error={name.error}
            onChange={name.set}
            readonly={isEditting}
            required
          />
          <Gap size={15} />
          <WCTTextField
            name="description"
            label="Task Description"
            value={description.value}
            error={description.error}
            onChange={description.set}
            required
            multiline
          />
          <Gap size={15} />
          <WCTSelectField
            id="assignedUser"
            name="assignedUser"
            label="Assigned User"
            options={userOptions!}
            value={assignedUser.value}
            error={assignedUser.error}
            onChange={assignedUser.set}
            required
          />
          <Gap size={15} />
          <Row>
            <Box maxWidth={204}>
              <WCTDateField
                name="dueDate"
                label="Due date"
                value={dueDate.value}
                error={dueDate.error}
                onChange={dueDate.set}
                disablePast
                required
              />
            </Box>
            <Gap size={24} />
            <PrioritySelectorField
              name="taskPriority"
              value={priority.value}
              error={priority.error}
              onChange={priority.set}
            />
          </Row>
          <Divider addMargin compact />
          <ActionsRow>
            <Button variant="text" onClick={() => setShowForm(false)}>
              Cancel
            </Button>
            <PrimaryButton
              type="submit"
              isBusy={isSubmitting}
              disabled={!canSubmit}
            >
              {isEditting ? 'Edit Task' : 'Create Task'}
            </PrimaryButton>
          </ActionsRow>
        </ColumnForm>
      ) : taskCount === 0 ? (
        <NoTasksInfo onCreateClicked={beginAddingTask} compact dark />
      ) : (
        <Column>
          <Row spaceBetween>
            <WCTCheckboxField
              name="showCompleted"
              label="Show completed"
              value={showCompleted.value ?? false}
              onChange={showCompleted.set}
            />
            <LoadingIndicator show={isChangingTaskStatus} />
            <Gap size={12} />
            <SortByFilter
              value={sort.value}
              onChange={sort.set}
              options={sortOptions}
            />
          </Row>
          <Gap size={15} />
          {tasks!.model.map((task) => (
            <TaskPanelTaskRow
              key={task.taskDetailId}
              task={task}
              onComplete={() => completeTask(task.taskDetailId)}
              onOpen={() => openTask(task.taskDetailId)}
              onEdit={() => beginEditting(task)}
            />
          ))}
        </Column>
      )}
    </Column>
  );
}

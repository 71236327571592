import requireAuth from '@components/hoc/with-required-auth-hoc';
import useBilling from '@pages/app/company-profile/billing-hook';
import Column from '@components/layout-util-components/column';
import { Grid, Link, styled, Typography } from '@mui/material';
import { useAppTr } from '@i18n/use-app-tr';
import Row from '@components/layout-util-components/row';
import ColorBadge from '@components/badges/color-badge';
import LabelValueDisplay from '@components/data/label-value-display';
import { OutlinedButton } from '@components/buttons';
import { ButtonSkeleton, InlineBoxSkeleton } from '@components/skeletons';
import LabelValueSkeleton from '@components/skeletons/label-value-skeleton';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { LoadingIndicator } from '@components/loading-indicator';
import { ErrorAlert } from '@components/alerts';
import { Trans } from 'react-i18next';
import { isNullOrEmpty } from '@util/string-util';
import { i18nDayJs } from '@i18n/index';
import { BillingPortalUrlType } from '@api/types/company/subscription/billing-portal-url-type';
import { Gap } from '@components/layout-util-components/gap';

const DetailsContainer = styled('div')({
  color: 'white',
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    gap: 11,
    padding: '30px 50px',
    backgroundColor: '#6B6B6B',

    '& dd,dt': {
      color: 'white',
      marginBottom: 7,
    },
  },
});

function BillingPage() {
  const { t } = useAppTr('billing');
  const { showConfirmationModal, showSingleActionModal } = useUiPopup();

  const {
    details,
    isLoading,
    loadingPortalUrl,
    gotoPortal,
    canCancel,
    canViewHistory,
    canAddPaymentMethod,
    canChangePaymentMethod,
    isEnterprise,
    loadingLinkType,
    canReactivate,
  } = useBilling();

  const onCancel = () => {
    if (isEnterprise) {
      showSingleActionModal({
        title: t('enterprise.cancelModal.title'),
        message: t('enterprise.cancelModal.message', {
          date: details?.nextPaymentDate,
        }),
        okButtonLabel: t('enterprise.cancelModal.buttonLabel'),
      });
      return;
    }

    return showConfirmationModal({
      title: t('cancelConfirmModal.title'),
      message: t('cancelConfirmModal.message'),
      noButtonLabel: t('cancelConfirmModal.falseButtonLabel'),
      yesButtonLabel: t('cancelConfirmModal.trueButtonLabel'),
      yesButtonAsyncAction: () =>
        gotoPortal(BillingPortalUrlType.subscriptionCancel),
    });
  };

  const onBillingHistoryClicked = () => {
    if (isEnterprise) {
      showSingleActionModal({
        title: t('enterprise.historyModal.title'),
        message: t('enterprise.historyModal.message'),
        okButtonLabel: t('enterprise.historyModal.buttonLabel'),
      });
      return;
    }

    gotoPortal(BillingPortalUrlType.default);
  };

  return (
    <Column>
      <Typography variant="h6">{t('title')}</Typography>
      <Gap size={32} />
      <DetailsContainer>
        <div>
          <Typography>{t('plan.title')}</Typography>
          <Row>
            {isLoading ? (
              <InlineBoxSkeleton width={150} />
            ) : (
              <Typography variant="h5">{details!.packageName}</Typography>
            )}
            <Gap size={24} />
            {isLoading ? (
              <InlineBoxSkeleton width={100} />
            ) : !isNullOrEmpty(details!.billingFrequency) ? (
              <ColorBadge>{details!.billingFrequency}</ColorBadge>
            ) : null}
          </Row>
        </div>
        <div style={{ backgroundColor: '#777777' }}>
          <Grid container alignItems="center">
            <Grid item md={3}>
              {isLoading ? (
                <LabelValueSkeleton />
              ) : !isNullOrEmpty(details!.nextPaymentDate) ? (
                <LabelValueDisplay
                  label={t('plan.dateLabel')}
                  value={t('plan.dateValue', {
                    date: details!.nextPaymentDate,
                  })}
                />
              ) : !isNullOrEmpty(details!.freeTrialEnd) ? (
                <LabelValueDisplay
                  label={t('plan.freeTrialEndLabel')}
                  value={t('plan.freeTrialEndValue', {
                    date: details!.freeTrialEnd,
                  })}
                />
              ) : null}
            </Grid>
            <Grid item md={3}>
              {isLoading ? (
                <LabelValueSkeleton />
              ) : !isNullOrEmpty(details!.endDate) ? (
                <LabelValueDisplay
                  label={t('plan.cancelDateLabel')}
                  value={t('plan.cancelDateValue', {
                    date: details!.endDate,
                  })}
                />
              ) : null}
            </Grid>
          </Grid>
        </div>
      </DetailsContainer>
      {!isLoading && details!.hasPaymentIssue ? (
        <>
          <Gap size={32} />
          <ErrorAlert
            title={t('alert.paymentIssue.title', {
              date: i18nDayJs(details!.nextPaymentDate)
                .subtract(1, 'month')
                .toDate(),
            })}
            message={
              <Trans
                t={t}
                i18nKey={'alert.paymentIssue.message'}
                values={{ deadline: details!.nextPaymentDate }}
              >
                <Link
                  href="#"
                  onClick={() =>
                    gotoPortal(BillingPortalUrlType.paymentMethodUpdate)
                  }
                >
                  Update your payment details
                </Link>
              </Trans>
            }
          />
        </>
      ) : null}
      {!isLoading && canReactivate ? (
        <>
          <Gap size={32} />
          <ErrorAlert
            title={t('alert.cancelledAccount.title', {
              planName: details!.packageName,
              endDate: details!.endDate,
            })}
            message={t('alert.cancelledAccount.message')}
          />
        </>
      ) : null}
      <Gap size={50} />
      <Row contentAtEnd>
        {isLoading ? (
          <ButtonSkeleton />
        ) : canAddPaymentMethod || canChangePaymentMethod ? (
          <OutlinedButton
            disabled={loadingPortalUrl}
            onClick={() => gotoPortal(BillingPortalUrlType.paymentMethodUpdate)}
          >
            {canAddPaymentMethod
              ? t('addDetailsButtonLabel')
              : t('changeDetailsButtonLabel')}
            <LoadingIndicator
              show={
                loadingLinkType === BillingPortalUrlType.paymentMethodUpdate
              }
            />
          </OutlinedButton>
        ) : null}
        {canViewHistory ? (
          isLoading ? (
            <ButtonSkeleton />
          ) : (
            <>
              <Gap size={20} />
              <OutlinedButton
                disabled={loadingPortalUrl}
                onClick={onBillingHistoryClicked}
              >
                {t('historyButtonLabel')}
                <LoadingIndicator
                  show={loadingLinkType === BillingPortalUrlType.default}
                />
              </OutlinedButton>
            </>
          )
        ) : null}
        {canCancel ? (
          isLoading ? (
            <ButtonSkeleton />
          ) : (
            <>
              <Gap size={20} />
              <OutlinedButton disabled={loadingPortalUrl} onClick={onCancel}>
                {t('cancelButtonLabel')}
                <LoadingIndicator
                  show={
                    loadingLinkType === BillingPortalUrlType.subscriptionCancel
                  }
                />
              </OutlinedButton>
            </>
          )
        ) : canReactivate ? (
          isLoading ? (
            <ButtonSkeleton />
          ) : (
            <>
              <Gap size={20} />
              <OutlinedButton disabled={loadingPortalUrl} onClick={onCancel}>
                {t('reactivateButtonLabel')}
                <LoadingIndicator
                  show={
                    loadingLinkType === BillingPortalUrlType.subscriptionConfirm
                  }
                />
              </OutlinedButton>
            </>
          )
        ) : null}
      </Row>
    </Column>
  );
}

export default requireAuth(BillingPage);

import FullPageFormScaffold from '@components/layouts/scaffolds/full-page-form-scaffold';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import Spacer from '@components/layout-util-components/spacer';
import useCreateOutcome from '@pages/app/rca/outcome/views/create-outcome-hook';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import WCTTextField from '@components/input/text-field';
import { Divider } from '@components/layout-util-components/divider';
import WCTSwitchField, {
  SwitchContainer,
} from '@components/input/switch-field';
import { OutcomeStepProps } from '@pages/app/rca/outcome/rca-outcome-page';
import { InputSkeleton } from '@components/skeletons';
import SwitchFieldSkeleton from '@components/skeletons/switch-field-skeleton';
import useSystemText from '@hooks/use-system-text';
import { Gap } from '@components/layout-util-components/gap';

export default function CreateOutcomeView({
  state,
  onContinue,
  onBack,
}: OutcomeStepProps) {
  const { systemText } = useSystemText();
  const { appBarRef } = state;
  const {
    toggleOverview,
    toggleRecommendations,
    toggleAnalysis,
    toggleInsights,
    toggleImpacts,
    toggleSolutions,
    toggleEvidence,
    toggleTeam,
    name,
    submit,
    isBusy,
    isLoading,
    canSubmit,
  } = useCreateOutcome(state);

  return (
    <FullPageFormScaffold
      appBarRef={appBarRef}
      onSubmit={async () => {
        if (await submit()) {
          onContinue();
        }
      }}
    >
      <Gap size={64} />
      <Typography variant="h5">Create an outcome</Typography>
      <Gap size={10} />
      <Typography variant="body2">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lectus
        lorem, sollicitudin consequat felis egestas, pretium viverra sapien.
        Vestibulum eu sodales magna.
      </Typography>
      <Gap size={32} />
      <Grid container>
        <Grid container item xs={6}>
          <div>
            <Typography variant="body1">Name your outcome</Typography>
            <Typography variant="body2">
              Enter the {systemText['RCA.Overview.FocalPoint']} for your
              investigation
            </Typography>
          </div>
        </Grid>
        <Grid container item xs={6}>
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTTextField
              name="name"
              label="Name"
              value={name.value}
              error={name.error}
              onChange={name.set}
              required
            />
          )}
        </Grid>
      </Grid>
      <Divider addMargin />
      <Grid container>
        <Grid container item xs={6}>
          <div>
            <Typography variant="body1">Configure outcome</Typography>
            <Typography variant="body2">
              Summarise the reason for this investigation
            </Typography>
          </div>
        </Grid>
        <Grid container item xs={6} direction="column" gap={1}>
          <SwitchContainer>
            {isLoading ? (
              <SwitchFieldSkeleton />
            ) : (
              <WCTSwitchField
                name="toggleOverview"
                label="Overview"
                value={toggleOverview.value}
                error={toggleOverview.error}
                onChange={toggleOverview.set}
              />
            )}
          </SwitchContainer>
          <SwitchContainer>
            {isLoading ? (
              <SwitchFieldSkeleton />
            ) : (
              <WCTSwitchField
                name="toggleImpacts"
                label="Impacts"
                value={toggleImpacts.value}
                error={toggleImpacts.error}
                onChange={toggleImpacts.set}
              />
            )}
          </SwitchContainer>
          <SwitchContainer>
            {isLoading ? (
              <SwitchFieldSkeleton />
            ) : (
              <WCTSwitchField
                name="toggleTeam"
                label="Team"
                value={toggleTeam.value}
                error={toggleTeam.error}
                onChange={toggleTeam.set}
              />
            )}
          </SwitchContainer>
          <SwitchContainer>
            {isLoading ? (
              <SwitchFieldSkeleton />
            ) : (
              <WCTSwitchField
                name="toggleAnalysis"
                label="Analysis"
                value={toggleAnalysis.value}
                error={toggleAnalysis.error}
                onChange={toggleAnalysis.set}
              />
            )}
          </SwitchContainer>
          <SwitchContainer>
            {isLoading ? (
              <SwitchFieldSkeleton />
            ) : (
              <WCTSwitchField
                name="toggleSolutions"
                label="Solutions"
                value={toggleSolutions.value}
                error={toggleSolutions.error}
                onChange={toggleSolutions.set}
              />
            )}
          </SwitchContainer>
          <SwitchContainer>
            {isLoading ? (
              <SwitchFieldSkeleton />
            ) : (
              <WCTSwitchField
                name="toggleEvidence"
                label="Evidence"
                value={toggleEvidence.value}
                error={toggleEvidence.error}
                onChange={toggleEvidence.set}
              />
            )}
          </SwitchContainer>
          <SwitchContainer>
            {isLoading ? (
              <SwitchFieldSkeleton />
            ) : (
              <WCTSwitchField
                name="toggleLenses"
                label="Lenses"
                value={toggleInsights.value}
                error={toggleInsights.error}
                onChange={toggleInsights.set}
              />
            )}
          </SwitchContainer>
          <SwitchContainer>
            {isLoading ? (
              <SwitchFieldSkeleton />
            ) : (
              <WCTSwitchField
                name="toggleRecommendations"
                label="Closing Statement"
                value={toggleRecommendations.value}
                error={toggleRecommendations.error}
                onChange={toggleRecommendations.set}
              />
            )}
          </SwitchContainer>
        </Grid>
      </Grid>
      <ActionsRow anchorBottom addPadding filled>
        <OutlinedButton onClick={onBack}>Back</OutlinedButton>
        <Spacer />
        <PrimaryButton type="submit" disabled={!canSubmit} isBusy={isBusy}>
          Continue
        </PrimaryButton>
      </ActionsRow>
    </FullPageFormScaffold>
  );
}

import { useGetLanguageOptionsQuery } from '@api/endpoints/language.api';
import { useGetTimezoneOptionsQuery } from '@api/endpoints/timezone.api';
import { useGetDateFormatOptionsQuery } from '@api/endpoints/dateformat.api';
import { useMemo } from 'react';
import { Option } from '@api/types/option';
import { useAppTr } from '@i18n/use-app-tr';
import { usePageAlertVariants } from '@components/alerts';
import { ApiError } from '@api/types/api-error';
import useField from '@hooks/use-field-hook';
import { required } from '@util/validators';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { formatDateCustom } from '@i18n/formatters';
import { dateFormats } from '@store/locale/date-formats';
import {
  useGetCompanyDetailsQuery,
  useUpdateCompanyDetailsMutation,
} from '@api/endpoints/company/company.api';
import { UpdateCompanyDetailsRequest } from '@api/types/company/update-company-details.request';

export default function useCompanyPreferences() {
  const { t } = useAppTr('companyPreferences');
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();

  const { data: details, isLoading: loadingDetails } =
    useGetCompanyDetailsQuery();

  const { data: languageOptions, isLoading: loadingLanguages } =
    useGetLanguageOptionsQuery();

  const { data: dateFormatOptions, isLoading: loadingDateFormats } =
    useGetDateFormatOptionsQuery();

  const { data: timezoneOptions, isLoading: loadingTimezones } =
    useGetTimezoneOptionsQuery();

  const [updateCompanyDetails, { isLoading: isSubmitting }] =
    useUpdateCompanyDetailsMutation();

  const timeDisplayOptions = useMemo(
    (): Option<boolean>[] => [
      { id: true, label: t('timeFormat.options.twentyFour') },
      { id: false, label: t('timeFormat.options.twelve') },
    ],
    [t]
  );

  const language = useField<number>([required()], details?.defaultLanguageId);
  const dateFormat = useField<number>(
    [required()],
    details?.defaultDateFormatId
  );
  const timezone = useField<number>([required()], details?.defaultTimezoneId);
  const twentyFourHourClock = useField<boolean>(
    [required()],
    details?.use24HourClock
  );

  const dateExample = useMemo(() => {
    if (dateFormat.value == null) {
      return '';
    }

    const fmt = dateFormats[dateFormat.value];
    if (fmt == null || fmt.long12 == null) {
      return '';
    }

    return formatDateCustom(new Date(), fmt.long12);
  }, [dateFormat.value]);

  const { isValid, isDirty, validateAll } = useFieldsWatcher([
    language,
    dateFormat,
    timezone,
    twentyFourHourClock,
  ]);

  const isLoading =
    loadingLanguages ||
    loadingTimezones ||
    loadingDateFormats ||
    loadingDetails;

  const canSubmit = !isLoading && !isSubmitting && isValid && isDirty;

  const submitPreferences = async (): Promise<boolean> => {
    if (!validateAll()) {
      return false;
    }

    const existingDetails = details!;
    return updateCompanyDetails({
      ...existingDetails,
      defaultLanguageId: language.value,
      defaultDateFormatId: dateFormat.value,
      defaultTimezoneId: timezone.value,
      use24HourClock: twentyFourHourClock.value,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage(t('toasts.success'));
        return true;
      })
      .catch(({ message, errors }: ApiError<UpdateCompanyDetailsRequest>) => {
        showErrorMessage(message);

        dateFormat.setError(errors?.defaultDateFormatId);
        timezone.setError(errors?.defaultTimezoneId);
        twentyFourHourClock.setError(errors?.use24HourClock);
        language.setError(errors?.defaultLanguageId);

        return false;
      });
  };

  return {
    languageOptions,
    timezoneOptions,
    dateFormatOptions,
    timeDisplayOptions,
    language,
    dateFormat,
    timezone,
    twentyFourHourClock,
    submitPreferences,
    isLoading,
    isSubmitting,
    canSubmit,
    dateExample,
  };
}

import { FlatButton, OutlinedButton } from '@components/buttons';
import { useState } from 'react';
import ChartStorageNode from '@pages/app/rca/tabs/components/storage/chart-storage-node';
import { useAppDispatch, useAppSelector } from '@store/store';
import { addNodeToStorage } from '@store/rca-editor/rca-editor-actions';
import { isNullOrEmpty } from '@util/string-util';
import { selectChainId } from '@store/rca-editor/selectors';
import { ApiError } from '@api/types/api-error';
import { CreateChainItemRequest } from '@api/types/chain/create-chain-item.request';
import { usePageAlertVariants } from '@components/alerts';
import { useCreateStorageItemMutation } from '@api/endpoints/chain/chain-item-storage.api';
import { ColumnForm } from '@components/layout-util-components/column';
import { Gap } from '@components/layout-util-components/gap';
import { styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

const Container = styled(ColumnForm)({
  '.adding-area': {
    backgroundColor: '#f5f5f6',
  },
});

export default function ChartStorageAddButton() {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const chainId = useAppSelector(selectChainId);
  const dispatch = useAppDispatch();

  const [isAdding, setIsAdding] = useState(false);
  const [description, setDescription] = useState<string>();

  const [createItem, { isLoading }] = useCreateStorageItemMutation();

  const onCreate = async () => {
    if (!isNullOrEmpty(description)) {
      try {
        const node = await createItem({
          chainId: chainId!,
          description: description!,
          tasksNA: false,
        }).unwrap();

        dispatch(addNodeToStorage(node));
        showSuccessMessage(`Added cause box "${description}" to storage`);

        setDescription('');
      } catch (e) {
        const { errors, message } = e as ApiError<
          CreateChainItemRequest & { chainId: number }
        >;

        showErrorMessage(
          errors?.chainId ?? errors?.tasksNA ?? errors?.description ?? message
        );
      }
    }
    setIsAdding(false);
  };

  return (
    <Container
      sx={{ flex: '0 0 auto', minHeight: 'unset' }}
      onSubmit={(e) => {
        e.preventDefault();
        onCreate();
      }}
    >
      {isAdding ? (
        <div className="adding-area">
          <ChartStorageNode
            onSubmitDescription={onCreate}
            onEditDescription={setDescription}
            description={description}
            actionButton={
              <FlatButton
                onClick={() => {
                  setDescription('');
                  setIsAdding(false);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </FlatButton>
            }
          />
          <Gap size={8} />
        </div>
      ) : null}
      <Gap size={12} />
      <OutlinedButton
        type="submit"
        onClick={(e) => {
          if (!isAdding) {
            e.preventDefault();
            setIsAdding(true);
          }
        }}
        disabled={isLoading || chainId == null}
        isBusy={isLoading}
      >
        Add Cause box
      </OutlinedButton>
    </Container>
  );
}

import {
  useCreateCaseSolutionMutation,
  useGetCaseSolutionByIdQuery,
  useRemoveCaseSolutionMutation,
  useUpdateCaseSolutionMutation,
} from '@api/endpoints/case.api';
import useField from '@hooks/use-field-hook';
import { required, validCurrency } from '@util/validators';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { usePageAlertVariants } from '@components/alerts';
import { ApiError } from '@api/types/api-error';
import { CaseFileListItemResponse } from '@api/types/case/case-file/case-file-list-item.response';
import { useMemo } from 'react';
import { useGetThemeOptionsQuery } from '@api/endpoints/theme.api';
import { numberFromString } from '@util/string-util';
import { UpdateCaseSolutionRequest } from '@api/types/case/case-solution/update-case-solution.request';
import useUser from '@store/user/user-hook';
import { CreateCaseSolutionRequest } from '@api/types/case/case-solution/create-case-solution.request';
import { useAppSelector } from '@store/store';
import { selectCurrentRcaCurrency } from '@store/rca-editor/selectors';

export default function useSolutionsPopupView(
  caseId: number,
  solutionId?: number
) {
  const { isAdminUser, companyUserId } = useUser();
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();
  const currency = useAppSelector(selectCurrentRcaCurrency);

  const { data: themeOptions, isLoading: loadingThemeOptions } =
    useGetThemeOptionsQuery(caseId);

  const { data: solution, isLoading: loadingSolutions } =
    useGetCaseSolutionByIdQuery(
      {
        caseId: caseId,
        solutionId: solutionId ?? -1,
      },
      {
        skip: caseId == null || solutionId == null,
      }
    );

  const [deleteSolution, { isLoading: isDeletingSolution }] =
    useRemoveCaseSolutionMutation();
  const [updateSolution, { isLoading: isUpdatingSolution }] =
    useUpdateCaseSolutionMutation();
  const [createSolution, { isLoading: isCreatingSolution }] =
    useCreateCaseSolutionMutation();

  const name = useField<string>([required()], solution?.name);
  const description = useField<string>([required()], solution?.description);
  const files = useField<CaseFileListItemResponse[]>(
    [],
    useMemo(() => solution?.caseFiles ?? [], [solution?.caseFiles])
  );
  const themes = useField<number[]>(
    [],
    useMemo(() => solution?.themes.map((t) => t.id), [solution?.themes])
  );
  const cost = useField<string>([validCurrency()], solution?.cost?.toString());

  const { isValid, isDirty, validateAll } = useFieldsWatcher([
    name,
    description,
    files,
    themes,
    cost,
  ]);

  const isLoading = loadingSolutions || loadingThemeOptions;
  const isSubmitting =
    isDeletingSolution || isUpdatingSolution || isCreatingSolution;
  const canSubmit = !isLoading && !isSubmitting && isValid && isDirty;
  const canDelete =
    (solutionId != null && isAdminUser) ||
    solution?.createdByCompanyUserId === companyUserId;

  const onSubmit = async () => {
    if (!validateAll()) {
      return false;
    }

    const isEdit = solutionId != null;
    if (isEdit) {
      return updateSolution({
        caseId: caseId,
        solutionId: solutionId,
        cost: numberFromString(cost.value) ?? 0,
        name: name.value,
        description: description.value,
        caseFileIds: files.value.map((f) => f.caseFileId),
        themes: themes.value,
      })
        .unwrap()
        .then(() => {
          showSuccessMessage('Solution updated successfully');
          return true;
        })
        .catch(({ errors, message }: ApiError<UpdateCaseSolutionRequest>) => {
          showErrorMessage(errors?.caseId ?? errors?.solutionId ?? message);

          name.setError(errors?.name);
          description.setError(errors?.description);
          cost.setError(errors?.cost);
          files.setError(errors?.caseFileIds);
          themes.setError(errors?.themes);

          return false;
        });
    } else {
      return createSolution({
        caseId: caseId,
        cost: numberFromString(cost.value) ?? 0,
        name: name.value,
        description: description.value,
        caseFileIds: files.value.map((f) => f.caseFileId),
        themes: themes.value,
      })
        .unwrap()
        .then(() => {
          showSuccessMessage('Solution created successfully');
          return true;
        })
        .catch(({ errors, message }: ApiError<CreateCaseSolutionRequest>) => {
          showErrorMessage(errors?.caseId ?? message);

          name.setError(errors?.name);
          description.setError(errors?.description);
          cost.setError(errors?.cost);
          files.setError(errors?.caseFileIds);
          themes.setError(errors?.themes);

          return false;
        });
    }
  };

  const onDeleteSolution = () => {
    return deleteSolution({
      caseId: caseId,
      solutionId: solutionId!,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage('Solution deleted');
        return true;
      })
      .catch(({ message }: ApiError<never>) => {
        showErrorMessage(message);
        return false;
      });
  };

  return {
    isLoading,
    solution,
    themeOptions,
    onDeleteSolution,
    onSubmit,
    name,
    description,
    isSubmitting,
    canSubmit,
    files,
    themes,
    cost,
    canDelete,
    currency,
  };
}

import { Typography } from '@mui/material';
import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import { useAppTr } from '@i18n/use-app-tr';
import { getFirstName, getLastName } from '@util/name-util';
import { ElipsisButton } from '@components/buttons';
import { CompanyGroupListItemResponse } from '@api/types/company-group/company-group-list-item.response';
import ContentFooterCard from '@components/cards/content-footer-card';
import GroupedAvatars from '@components/containers/grouped-avatars';
import Spacer from '@components/layout-util-components/spacer';
import { PopoverMenuAction } from '@components/popover-menu/use-popover-menu';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  group: CompanyGroupListItemResponse;
  actions: Array<PopoverMenuAction>;
}

export default function GroupCard({ group, actions }: Props) {
  const { t } = useAppTr('groups');

  const { groupName: name, groupAdminName, firstCompanyUsers, created } = group;
  return (
    <ContentFooterCard
      content={
        <>
          <Typography variant="h6">{name}</Typography>
          <Gap size={20} />
          <InitialsContainerWithData
            label={t('card.adminLabel')}
            firstName={getFirstName(groupAdminName)}
            lastName={getLastName(groupAdminName)}
          />
          <Gap size={15} />
          <Typography variant="caption" color="#00000080">
            {t('card.created', { value: created })}
          </Typography>
        </>
      }
      footer={
        <>
          <GroupedAvatars items={firstCompanyUsers} extractInitials />
          <Spacer />
          <ElipsisButton actions={actions} />
        </>
      }
    />
  );
}

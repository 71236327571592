import { BaseEdge, EdgeProps, getSmoothStepPath } from 'reactflow';
import {
  makeSelectIsDescendantOfAndOrMetaNode,
  makeSelectNodeCollapsedState,
} from '@store/rca-editor/selectors';
import { useAppSelector } from '@store/store';
import { useMemo } from 'react';

export default function RcaDefaultEdge({
  id,
  source,
  sourceX,
  sourceY,
  targetX,
  targetY,
  target,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}: EdgeProps) {
  const [path] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    offset: 0,
    borderRadius: 30,
  });

  const selectCollapsedState = useMemo(() => {
    return makeSelectNodeCollapsedState(target);
  }, [target]);

  const selectIsDescendantOfAndOrMetaNode = useMemo(
    () => makeSelectIsDescendantOfAndOrMetaNode(source),
    [source]
  );

  const collapsedState = useAppSelector(selectCollapsedState);
  const isDescendantOfMetaNode = useAppSelector(
    selectIsDescendantOfAndOrMetaNode
  );

  if (collapsedState === 'collapse-state-hidden') {
    return <></>;
  }

  return (
    <BaseEdge
      id={id}
      path={path}
      markerEnd={markerEnd}
      style={{
        ...style,
        stroke: '#787878',
        strokeWidth: 2,
        strokeDasharray: isDescendantOfMetaNode ? '5,5' : undefined,
        opacity:
          isDescendantOfMetaNode || collapsedState === 'collapse-state-ghost'
            ? 0.2
            : 1.0,
      }}
    />
  );
}

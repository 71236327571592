import OutlinedButton from '@components/buttons/outlined-button';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import ShareOutcomeModal, {
  ShareOutcomeModalProps,
} from '@pages/app/rca/outcome/modals/share-outcome-modal';

interface Props {
  caseId: number;
  outcomeId: number;
}

export default function ShareButton({ caseId, outcomeId }: Props) {
  const { showPopup } = useUiPopup();

  const onClick = async () => {
    await showPopup<ShareOutcomeModalProps, boolean>(ShareOutcomeModal, {
      caseId,
      outcomeId,
    });
  };

  return <OutlinedButton onClick={onClick}>Share</OutlinedButton>;
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RcaEditorState } from '@store/rca-editor/rca-editor-slice';
import { cloneDeep } from 'lodash';
import { v4 as uuid } from 'uuid';
import { ChainDetailResponse } from '@api/types/chain/chain-detail.response';
import { RcaUtil } from '@util/rca-util';

export type SnapshotState = Pick<
  RcaEditorState,
  'nodes' | 'edges' | 'storage' | 'storageChainItemIdsToConsume'
>;

export type HydrateSnapshotPayload = {
  snapshotId: string;
  data: ChainDetailResponse;
};

const MAX_SNAPSHOTS = 30;

interface Snapshot {
  id: string;
  timestamp: number;
  data: SnapshotState;
  isCommitted: boolean;
}

interface RcaEditorSnapshotState {
  snapshots: Array<Snapshot>;
  currentSnapshot: Snapshot | null;
}

const initialState: RcaEditorSnapshotState = {
  snapshots: [],
  currentSnapshot: null,
};

const getSnapshotData = (data: RcaEditorState): SnapshotState => {
  const { nodes, edges, storage, storageChainItemIdsToConsume } =
    cloneDeep(data);

  return {
    nodes,
    edges,
    storage,
    storageChainItemIdsToConsume,
  };
};

const rcaEditorSnapshotSlice = createSlice({
  name: 'rcaEditorSnapshot',
  initialState: initialState,
  reducers: {
    hardResetSnapshotState: (state, _: PayloadAction) => {
      return initialState;
    },
    setInitialSnapshot: (state, { payload }: PayloadAction<RcaEditorState>) => {
      const data = getSnapshotData(payload);
      const snapshot: Snapshot = {
        id: uuid(),
        timestamp: Date.now(),
        data,
        isCommitted: true,
      };

      state.snapshots.push(snapshot);
    },
    addSnapshot: (state, { payload }: PayloadAction<RcaEditorState>) => {
      const data = getSnapshotData(payload);
      const snapshot: Snapshot = {
        id: uuid(),
        timestamp: Date.now(),
        data,
        isCommitted: false,
      };

      state.snapshots.push(snapshot);
      state.currentSnapshot = snapshot;

      if (state.snapshots.length > MAX_SNAPSHOTS) {
        state.snapshots.shift();
      }
    },
    resetSnapshotState: (state, _) => {
      Object.assign(state, initialState);
    },
    commitSnapshotUptoId: (state, { payload }: PayloadAction<string>) => {
      const index = state.snapshots.findIndex((x) => x.id === payload);
      if (index === -1) {
        return;
      }

      for (let i = 0; i <= index; i++) {
        state.snapshots[i].isCommitted = true;
      }
    },
    hydrateSnapshot: (
      state,
      { payload }: PayloadAction<HydrateSnapshotPayload>
    ) => {
      const { snapshotId, data } = payload;
      const index = state.snapshots.findIndex((x) => x.id === snapshotId);
      if (index === -1) {
        return;
      }

      // We will also need to hydrate the storage chain item ids in
      // snapshots ABOVE this current one aswell, otherwise future update graph
      // calls will fail due to missing chain item IDs.
      for (let i = index; i < state.snapshots.length; i++) {
        RcaUtil.populateNodesFromServerData(
          state.snapshots[i].data.nodes,
          data
        );
      }
    },
    revertToLastCommittedSnapshot: (state, _: PayloadAction) => {
      state.snapshots = state.snapshots.filter((x) => x.isCommitted);
      state.currentSnapshot = state.snapshots[state.snapshots.length - 1];
    },
  },
});

export const {
  hardResetSnapshotState,
  hydrateSnapshot,
  commitSnapshotUptoId,
  addSnapshot,
  setInitialSnapshot,
  revertToLastCommittedSnapshot,
} = rcaEditorSnapshotSlice.actions;

export default rcaEditorSnapshotSlice.reducer;

import { useGetChainItemEvidenceQuery } from '@api/endpoints/chain/chain-item-evidence.api';
import { useAppSelector } from '@store/store';
import {
  selectChainId,
  selectFocusedNode,
  selectNodePanelEditorShouldDefaultToCreate,
} from '@store/rca-editor/selectors';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFilter, useSortableHeader } from '@components/table';
import useLoadMore from '@hooks/use-load-more-hook';
import { SortDirection } from '@api/types/sort-direction';
import { SortOption } from '@components/table/hooks/use-sortable-header-hook';
import { CaseEvidenceResource } from '@api/types/case/case-evidence/case-evidence.resource';

export enum EvidencePanelViewState {
  empty,
  view,
  createOptions,
  create,
  edit,
}

export default function useEvidencePanel() {
  const hasLoadedRef = useRef(false);
  const defaultToCreate = useAppSelector(
    selectNodePanelEditorShouldDefaultToCreate
  );
  const [viewState, setViewState] = useState<EvidencePanelViewState>(
    defaultToCreate
      ? EvidencePanelViewState.createOptions
      : EvidencePanelViewState.empty
  );
  const [edittingEvidenceDetail, setEdittingEvidenceDetail] =
    useState<CaseEvidenceResource>();
  const chainId = useAppSelector(selectChainId);
  const focusedNode = useAppSelector(selectFocusedNode);
  const { chainItemId } = focusedNode.data;

  const sortByOptions: Array<SortOption> = [
    { id: 'name', label: 'Name', direction: SortDirection.asc },
  ];

  const search = useFilter<string>();
  const { skip, take, loadMore, canLoadMore } = useLoadMore();
  const sort = useSortableHeader({ initialProperty: 'name' });

  const {
    data: evidence,
    isLoading: loadingEvidence,
    isSuccess,
    isFetching,
  } = useGetChainItemEvidenceQuery(
    {
      chainItemId: chainItemId ?? -1,
      chainId: chainId ?? -1,
      searchText: search.value,
      sortBy: sort.property,
      sortDirection: sort.direction,
      skip,
      take,
    }
    // { skip: !chainItemId || !chainId }
  );

  const isLoading = loadingEvidence;

  const startCreateFlow = useCallback(() => {
    setViewState(EvidencePanelViewState.createOptions);
  }, []);

  const createNew = useCallback(() => {
    setEdittingEvidenceDetail(undefined);
    setViewState(EvidencePanelViewState.create);
  }, []);

  const editEvidence = useCallback((evidence: CaseEvidenceResource) => {
    setEdittingEvidenceDetail(evidence);
    setViewState(EvidencePanelViewState.edit);
  }, []);

  const cancelCreate = useCallback(() => {
    if (evidence!.totalCount === 0) {
      setViewState(EvidencePanelViewState.empty);
    } else {
      setViewState(EvidencePanelViewState.view);
    }
  }, [evidence]);

  const gotoViewList = useCallback(() => {
    setViewState(EvidencePanelViewState.view);
  }, []);

  const totalCount = evidence?.totalCount ?? 0;
  useEffect(() => {
    if (hasLoadedRef.current) {
      return;
    }

    if (!isSuccess) {
      return;
    }

    hasLoadedRef.current = true;
    if (isSuccess && totalCount > 0) {
      setViewState(EvidencePanelViewState.view);
    }
  }, [isSuccess, totalCount]);

  return {
    search,
    sort,
    sortByOptions,
    evidence,
    isLoading,
    viewState,
    startCreateFlow,
    createNew,
    cancelCreate,
    gotoViewList,
    loadMore,
    canLoadMore,
    isFetching,
    chainId,
    chainItemId,
    editEvidence,
    edittingEvidenceDetail,
    setViewState,
  };
}

export type EvidencePanelState = ReturnType<typeof useEvidencePanel>;

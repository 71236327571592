import RcaCreateScaffold from '@pages/app/rca/create/components/rca-create-scaffold';
import ContentContainer from '@components/containers/content-container';
import { Typography } from '@mui/material';
import Row from '@components/layout-util-components/row';
import RcaTypeCard from '@pages/app/rca/create/components/rca-type-card';
import { useNavigate } from 'react-router-dom';
import RcaStepperAppBar from '@pages/app/rca/create/components/rca-stepper-app-bar';
import { useAppDispatch } from '@store/store';
import { setAnalysisCreationType } from '@store/create-analysis-process/create-analysis-slice';
import Column from '@components/layout-util-components/column';
import TutorialCard from '@components/cards/tutorial-card';
import CrossSellPlaceholder from '@assets/images/cross-sell-placeholder.png';
import useCreateTypePage from './rca-create-type-page-hook';
import { Gap } from '@components/layout-util-components/gap';

function RcaCreateTypePage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { caseAnalysisTypes } = useCreateTypePage();

  const onSelectChoice = (caseAnalysisTypeId: number) => {
    dispatch(setAnalysisCreationType(caseAnalysisTypeId));
    navigate(`./steps/?type=${caseAnalysisTypeId}`);
  };

  return (
    <RcaCreateScaffold
      appbar={<RcaStepperAppBar onBack={() => navigate('/analysis')} />}
      sidebar={
        <Column centerContent>
          <Typography variant="h6">Setting up your analysis</Typography>
          <Gap size={4} />
          <Typography variant="body2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </Typography>
          <Gap size={32} />
          <Typography variant="body1">How to get started</Typography>
          <Gap size={20} />
          <TutorialCard
            title="How to setup an RCA investigation"
            tags={['Analysis', 'RCA']}
            time="2:00"
            isVideo
          />
          <Gap size={32} />
          <Typography variant="body1">Need assistance?</Typography>
          <Gap size={20} />
          <img src={CrossSellPlaceholder} alt="placeholder" />
        </Column>
      }
    >
      <ContentContainer>
        <Typography variant="h4">Create</Typography>
        <Gap size={20} />
        <Typography variant="h6">Select RCA Type *</Typography>
        <Typography variant="body2">
          Select your RCA type based on the analysis type
        </Typography>
        <Gap size={50} />
        <Row wrap>
          {caseAnalysisTypes?.map((x, i) => (
            <>
              <RcaTypeCard
                key={x.caseAnalysisTypeId}
                title={x.name}
                description={x.description}
                onClick={() => onSelectChoice(x.caseAnalysisTypeId)}
              />
              {i < caseAnalysisTypes.length - 1 && <Gap size={24} />}
            </>
          ))}
        </Row>
      </ContentContainer>
    </RcaCreateScaffold>
  );
}

export default RcaCreateTypePage;

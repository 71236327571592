import { Grid, Skeleton, Typography } from '@mui/material';
import WCTTextField from '@components/input/text-field';
import { PrimaryButton } from '@components/buttons';
import { useNavigate } from 'react-router-dom';
import useCreateCompany from '@pages/auth/create-company-hook';
import WCTSelectField from '@components/input/select-field';
import { useAuthTr } from '@i18n/use-auth-tr';
import { ButtonSkeleton, InputSkeleton } from '@components/skeletons';
import requireAuth from '@components/hoc/with-required-auth-hoc';
import useSearchParamsTyped from '@hooks/router-dom-helper-hooks';
import { PageAlertDisplay } from '@components/alerts';

type QueryParams = {
  packageId: string;
};

function CreateCompanyPage() {
  const { packageId } = useSearchParamsTyped<QueryParams>();

  const {
    companyName,
    sector,
    businessArea,
    jobTitle,
    country,
    canSubmit,
    submit,
    isSubmitting,
    isLoading,
    sectors,
    businessAreas,
    countries,
  } = useCreateCompany(+packageId!);

  const { t } = useAuthTr('createCompany');
  const navigate = useNavigate();

  const onSubmit = async () => {
    const didSubmit = await submit();
    if (didSubmit) {
      navigate('../create-account-progress', { replace: true });
    }
  };

  return (
    <Grid container direction="column" gap={2} justifyContent="center">
      <Typography variant="h4">
        {isLoading ? <Skeleton /> : t('title')}
      </Typography>
      <Typography variant="body1">
        {isLoading ? <Skeleton /> : t('message')}
      </Typography>

      <PageAlertDisplay />

      {isLoading ? (
        <InputSkeleton />
      ) : (
        <WCTTextField
          label={t('form.companyName')}
          value={companyName.value}
          error={companyName.error}
          onChange={companyName.set}
          required
        />
      )}

      {isLoading ? (
        <InputSkeleton />
      ) : (
        <WCTSelectField
          label={t('form.sector')}
          id="sector-selector"
          options={sectors!}
          value={sector.value}
          error={sector.error}
          onChange={(v) => sector.set(v as number)}
          required
        />
      )}

      {isLoading ? (
        <InputSkeleton />
      ) : (
        <WCTSelectField
          label={t('form.businessArea')}
          id="business-area-selector"
          options={businessAreas!}
          value={businessArea.value}
          error={businessArea.error}
          onChange={(v) => businessArea.set(v as number)}
          required
        />
      )}

      {isLoading ? (
        <InputSkeleton />
      ) : (
        <WCTTextField
          label={t('form.jobTitle')}
          value={jobTitle.value}
          error={jobTitle.error}
          onChange={jobTitle.set}
          required
        />
      )}

      <Grid container gap={2} wrap="nowrap">
        <Grid item xs={12}>
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTSelectField
              label={t('form.country')}
              id="country-selector"
              options={countries!}
              value={country.value}
              error={country.error}
              onChange={(v) => country.set(v as number)}
              required
            />
          )}
        </Grid>
      </Grid>

      {isLoading ? (
        <ButtonSkeleton />
      ) : (
        <PrimaryButton
          onClick={onSubmit}
          disabled={!canSubmit}
          isBusy={isSubmitting}
        >
          {t('buttonLabel')}
        </PrimaryButton>
      )}
    </Grid>
  );
}

export default requireAuth(CreateCompanyPage);

import { Grid, Link, Typography } from '@mui/material';
import { useAuthTr } from '@i18n/use-auth-tr';

interface Props {
  messageOverride?: string;
}
export default function HaveAccountLoginLink({ messageOverride }: Props) {
  return (
    <Grid item alignSelf="center">
      <Typography variant="body1">
        {messageOverride ?? 'Already have an account?'}
        <Link href="/auth/logout">Login</Link>
      </Typography>
    </Grid>
  );
}

import { useAppDispatch } from '@store/store';
import {
  addChildToNode,
  insertSiblingAbove,
  insertSiblingBelow,
} from '@store/rca-editor/rca-editor-actions';
import { BaseNodeState } from '@pages/app/rca/tabs/components/node-types/default-node-hook';

export default function useNodeAction(state: BaseNodeState) {
  const { id, isLinkNode } = state;

  const dispatch = useAppDispatch();

  const canAddRight = !isLinkNode;

  const addSiblingAbove = () => {
    dispatch(insertSiblingAbove(id));
  };

  const addSiblingBelow = () => {
    dispatch(insertSiblingBelow(id));
  };

  const addChild = () => {
    dispatch(addChildToNode(id));
  };

  return {
    canAddRight,
    addSiblingAbove,
    addSiblingBelow,
    addChild,
  };
}

import { Box, CircularProgress, styled } from '@mui/material';

const StyledCircularProgress = styled(CircularProgress)<{ isWhite?: boolean }>(
  ({ theme, isWhite }) => ({
    color: isWhite ? theme.palette.common.white : undefined,
  })
);

interface LoadingIndicatorProps {
  show: boolean;
  size?: number;
  isWhite?: boolean;
}

const DEFAULT_SIZE = 24;

export default function LoadingIndicator({
  show,
  size,
  isWhite,
}: LoadingIndicatorProps) {
  const SIZE = size ?? DEFAULT_SIZE;

  return (
    <Box
      className={`loading-indicator-container ${show ? 'show' : 'hide'}`}
      sx={{
        visibility: show ? 'visible' : 'hidden',
        height: SIZE,
        width: SIZE,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <StyledCircularProgress
        isWhite={isWhite}
        size={SIZE}
        sx={{ width: SIZE, height: SIZE }}
      />
    </Box>
  );
}

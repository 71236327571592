import useReportPanel from '@pages/app/rca/tabs/reports/report-panel-hook';
import { LoadingIndicator } from '@components/loading-indicator';
import ReportContainer from '@pages/app/rca/tabs/reports/components/report-container';
import ReportTitleBar from '@pages/app/rca/tabs/reports/components/report-title-bar';
import { ReportView } from '@store/reports/reports-selectors';
import CoverageReportView from '@pages/app/rca/tabs/reports/views/coverage-report-view';
import UnknownReportView from '@pages/app/rca/tabs/reports/views/unknown-report-view';
import Column from '@components/layout-util-components/column';
import ReportFooterBar from '@pages/app/rca/tabs/reports/components/report-footer-bar';
import React from 'react';
import { Box } from '@mui/material';
import { Gap } from '@components/layout-util-components/gap';

const ViewMap = {
  [ReportView.coverage]: CoverageReportView,
  [ReportView.unknown]: UnknownReportView,
};

export default function ReportPanel() {
  const state = useReportPanel();
  const {
    isOpen,
    isLoading,
    close,
    runModel,
    runData,
    shouldShrink,
    reportView,
  } = state;

  const View = ViewMap[reportView];

  return (
    <ReportContainer isOpen={isOpen} shrink={shouldShrink}>
      {!isOpen || isLoading ? (
        <LoadingIndicator show />
      ) : (
        <Column>
          <ReportTitleBar runModel={runModel!} onClose={close} />
          <Box sx={{ overflowY: 'auto' }}>
            <View data={runData!} model={runModel!} />
          </Box>
          {!shouldShrink && (
            <>
              <Gap size={50} />
              <ReportFooterBar state={state} />
            </>
          )}
        </Column>
      )}
    </ReportContainer>
  );
}

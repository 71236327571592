import useFilter from '@components/table/hooks/use-filter-hook';
import useSortableHeader from '@components/table/hooks/use-sortable-header-hook';
import { usePagination } from '@components/table';
import { SortDirection } from '@api/types/sort-direction';
import {
  useAdminDisableUserMutation,
  useAdminEnableUserMutation,
  useGetAdminUserStatusOptionsQuery,
  useGetAdminUserTableQuery,
} from '@api/endpoints/admin/admin-user.api';
import { useGetAdminRoleOptionsQuery } from '@api/endpoints/admin/admin-role.api';
import { ApiError } from '@api/types/api-error';
import useSnackbarVariants from '@hooks/use-snackbar-variants-hook';

export default function useWCTUsersIndex() {
  const searchFilter = useFilter<string>();

  const roleFilter = useFilter<Array<string>>([]);
  const statusFilter = useFilter<Array<string>>([]);

  const sort = useSortableHeader({
    initialProperty: 'created',
    initialDirection: SortDirection.desc,
  });

  const pagination = usePagination();

  const {
    data: users,
    isLoading,
    isFetching,
  } = useGetAdminUserTableQuery({
    skip: pagination.skip,
    take: pagination.take,
    searchText: searchFilter.value,
    sortBy: sort.property,
    sortDirection: sort.direction,
    status: statusFilter.arrayValue,
    roles: roleFilter.arrayValue,
  });

  const { data: roleOptions, isLoading: loadingRoles } =
    useGetAdminRoleOptionsQuery();
  const { data: statusOptions, isLoading: loadingStatus } =
    useGetAdminUserStatusOptionsQuery();

  const [enableUser, { isLoading: isEnablingUser }] =
    useAdminEnableUserMutation();
  const [disableUser, { isLoading: isDisablingUser }] =
    useAdminDisableUserMutation();

  const { showError, showSuccess } = useSnackbarVariants();

  const toggleUserStatus = (userId: string) => {
    const user = users!.model.find((x) => x.userId === userId);
    if (user == null) {
      return;
    }

    const toggle = () => {
      if (user.status === 'Active') {
        return disableUser({ userId });
      } else {
        return enableUser({ userId });
      }
    };

    return toggle()
      .unwrap()
      .then(() => {
        showSuccess('User status updated');
        return true;
      })
      .catch(({ message }: ApiError<any>) => {
        showError(message);
        return false;
      });
  };

  return {
    searchFilter,
    roleFilter,
    statusFilter,
    sort,
    pagination,
    users,
    isFetching,
    roleOptions,
    statusOptions,
    isLoading: isLoading || loadingRoles || loadingStatus,
    toggleUserStatus,
    isTogglingStatus: isEnablingUser || isDisablingUser,
  };
}

import { useAppSelector } from '@store/store';
import { selectCurrentRcaCaseId } from '@store/rca-editor/selectors';
import {
  useGetCaseDetailQuery,
  useGetCaseOverviewTotalsQuery,
} from '@api/endpoints/case.api';
import {
  useGetCaseOverviewImpactsQuery,
  useGetCaseOverviewSolutionsQuery,
  useGetCaseOverviewThemesQuery,
} from '@api/endpoints/case-report.api';
import {
  CaseOutcomePathParams,
  useDeleteCaseOutcomeMutation,
  useGetCaseOutcomesQuery,
} from '@api/endpoints/case-outcome.api';
import { ApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';

export default function useRcaEditorOverview() {
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();

  const caseId = useAppSelector(selectCurrentRcaCaseId);

  const { data: caseDetail, isLoading: loadingCaseDetail } =
    useGetCaseDetailQuery(caseId);
  const { data: totals, isLoading: loadingTotals } =
    useGetCaseOverviewTotalsQuery(caseId);
  const { data: themes, isLoading: loadingThemes } =
    useGetCaseOverviewThemesQuery(caseId);
  const { data: solutions, isLoading: loadingSolutions } =
    useGetCaseOverviewSolutionsQuery(caseId);
  const { data: impacts, isLoading: loadingImpacts } =
    useGetCaseOverviewImpactsQuery(caseId);
  const { data: outcomes, isLoading: loadingOutcomes } =
    useGetCaseOutcomesQuery(caseId);

  const [deleteCaseOutcome] = useDeleteCaseOutcomeMutation();

  const isLoading =
    loadingCaseDetail ||
    loadingTotals ||
    loadingThemes ||
    loadingSolutions ||
    loadingImpacts ||
    loadingOutcomes;

  const canShowCompleteButton = caseDetail?.caseStatusName === 'In Progress';

  const deleteOutcome = async (outcomeId: number) => {
    try {
      await deleteCaseOutcome({ caseId, outcomeId });

      showSuccessMessage('Outcome deleted successfully');
      return true;
    } catch (error) {
      const { message, errors } = error as ApiError<CaseOutcomePathParams>;

      showErrorMessage(errors?.caseId ?? errors?.outcomeId ?? message);
      return false;
    }
  };

  return {
    caseId,
    caseDetail,
    totals,
    themes,
    solutions,
    impacts,
    outcomes,
    isLoading,
    deleteOutcome,
    canShowCompleteButton,
  };
}

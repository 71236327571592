import { CollaborationState } from '@pages/app/rca/create/steps/collaboration-step-hook';
import Column from '@components/layout-util-components/column';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';
import { ReactComponent as NoContributorsIcon } from '@assets/svgs/no-contributers.svg';
import { Typography } from '@mui/material';
import { WCTTableFilters } from '@components/table';
import SpacedTable from '@components/table/spaced-table';
import CollaborateRecomendUserRow from '@pages/app/rca/create/components/collaborate-recomend-user-row';
import CollaborateInvitedUserRow from '@pages/app/rca/create/components/collaborate-invited-user-row';
import { LoadingIndicator } from '@components/loading-indicator';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  state: CollaborationState;
}

export default function CollaborateUsers({ state }: Props) {
  const {
    isLoading,
    contributors,
    roleOptions,
    contributorSearch,
    recomendedUsers,
    onInviteUser,
    onUpdateUserRole,
    onRemoveUser,
  } = state;

  return (
    <Column>
      <Gap size={10} />
      <WCTTableFilters
        isLoading={isLoading}
        search={contributorSearch.value}
        onSearch={contributorSearch.set}
        searchPlaceholder="Type to add users/groups"
        size="large"
      />
      <Gap size={24} />

      {isLoading ? (
        <LoadingIndicator show />
      ) : contributors?.model.length === 0 ? (
        <OnboardingEntryContainer
          icon={<NoContributorsIcon />}
          title="You don’t currently have any contributors"
          message="Currently you haven’t added any team members to allow collaboration on this RCA"
        />
      ) : (
        <>
          <Typography>{`Contributors (${contributors?.model.length})`}</Typography>
          <SpacedTable>
            <thead>
              <tr>
                <th>Name/Job Title</th>
                <th>Role</th>
                <th>Skills</th>
                {/*<th>RCA(s)</th>*/}
                <th>Role</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {contributors?.model.map((user) => (
                <CollaborateInvitedUserRow
                  key={user.companyUserId}
                  user={user}
                  onRoleChange={(newRoleId: number) =>
                    onUpdateUserRole(user, newRoleId)
                  }
                  onRemove={() => onRemoveUser(user)}
                  roleOptions={roleOptions!}
                />
              ))}
            </tbody>
          </SpacedTable>
        </>
      )}
      <Gap size={32} />
      <Typography>{`Recommended (${recomendedUsers?.totalCount})`}</Typography>
      <SpacedTable>
        <thead>
          <tr>
            <th>Name/Job Title</th>
            <th>Role</th>
            <th>Skills</th>
            <th>RCA(s)</th>
            <th>Kudos Points</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {recomendedUsers?.model.map((user) => (
            <CollaborateRecomendUserRow
              key={user.companyUserId}
              user={user}
              onInvite={() => onInviteUser(user.companyUserId)}
            />
          ))}
        </tbody>
      </SpacedTable>
    </Column>
  );
}

import { useState } from 'react';
import { useAppSelector } from '@store/store';
import { selectStorageNodes } from '@store/rca-editor/selectors';

export default function useChartStoragePanel() {
  const [isOpen, setIsOpen] = useState(false);
  const nodes = useAppSelector(selectStorageNodes);

  return {
    isOpen,
    setIsOpen,
    nodes,
  };
}

export type ChartStoragePanelState = ReturnType<typeof useChartStoragePanel>;

import requireAuth from '@components/hoc/with-required-auth-hoc';
import Column from '@components/layout-util-components/column';
import Row from '@components/layout-util-components/row';
import { Link, TableCell, TableRow, Typography } from '@mui/material';
import Spacer from '@components/layout-util-components/spacer';
import { ElipsisButton, OutlinedButton } from '@components/buttons';
import { DropDownFilter, WCTTable, WCTTableFilters } from '@components/table';
import useFunctionalLocations from '@pages/app/company-profile/functional-locations-hook';
import LabelValueDisplay from '@components/data/label-value-display';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import FunctionalLocationForm, {
  FunctionalLocationFormProps,
} from '@pages/app/company-profile/forms/functional-location-form';
import { CompanyLocationRowResponse } from '@api/types/company/company-location/company-location-row.response';
import { useCreateLocationForCompanyMutation } from '@api/endpoints/company/company-location.api';
import { CompanyLocationResource } from '@api/types/company/company-location/company-location.resource';
import { LoadingIndicator } from '@components/loading-indicator';
import NoFunctionalLocationInfo from './components/no-functional-locations-info';
import { usePageAlertVariants } from '@components/alerts';
import DeleteConfirmationModal from '@components/ui-popup/modals/delete-confirmation-modal';
import { ApiError } from '@api/types/api-error';
import { Gap } from '@components/layout-util-components/gap';

function FunctionalLocationsPage() {
  const { showPopup } = useUiPopup();
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();

  const {
    search,
    data,
    onDeleteCompany,
    sort,
    pagination,
    isLoading,
    countryOptions,
    country,
  } = useFunctionalLocations();

  const onAddButtonClicked = () => {
    showPopup<FunctionalLocationFormProps, CompanyLocationResource>(
      FunctionalLocationForm,
      {
        creationMutation: useCreateLocationForCompanyMutation,
      }
    );
  };

  const onViewButtonClicked = (id: number) => {
    showPopup<FunctionalLocationFormProps, CompanyLocationResource>(
      FunctionalLocationForm,
      {
        creationMutation: useCreateLocationForCompanyMutation,
        functionalLocationId: id,
      }
    );
  };

  const onDeleteCompanyClicked = (id: number) => {
    showPopup(DeleteConfirmationModal, {
      itemName: 'Location',
      onDelete: async () => {
        try {
          await onDeleteCompany(id);
          showSuccessMessage('Location deleted successfully');
          return true;
        } catch (error) {
          const { message, errors } = error as ApiError<{ groupId: number }>;
          showErrorMessage(errors?.groupId ?? message);
          return false;
        }
      },
    });
  };

  return (
    <Column>
      <Row>
        <Typography variant="h6">
          Company Locations {!isLoading ? `(${data!.totalCount})` : null}
        </Typography>
        <Spacer />
        <OutlinedButton onClick={onAddButtonClicked}>
          Add Location
        </OutlinedButton>
      </Row>
      <Gap size={20} />
      {isLoading ? (
        <LoadingIndicator show />
      ) : data!.totalCount === 0 ? (
        <NoFunctionalLocationInfo onCreateClicked={onAddButtonClicked} />
      ) : (
        <>
          <WCTTable
            count={data!.totalCount}
            isLoading={isLoading}
            pagination={pagination}
            filters={
              <WCTTableFilters
                isLoading={isLoading}
                search={search.value}
                onSearch={search.set}
              >
                <DropDownFilter
                  options={countryOptions ?? []}
                  value={country.value}
                  onChange={country.set}
                >
                  Country
                </DropDownFilter>
              </WCTTableFilters>
            }
            headers={[
              { title: 'Location', sortProperty: 'name', ...sort },
              { title: 'Address', sortProperty: 'address', ...sort },
              { title: 'RCAs', sortProperty: 'rcaCount', ...sort },
              { title: <></> },
            ]}
          >
            {!isLoading
              ? data!.model.map((row: CompanyLocationRowResponse) => (
                  <TableRow key={row.companyLocationId}>
                    <TableCell>
                      <LabelValueDisplay
                        label={row.name}
                        value={row.description}
                      />
                    </TableCell>
                    <TableCell>{row.address}</TableCell>
                    <TableCell>
                      {row.rcaCount > 0 ? (
                        <Link
                          href={`/analysis?incidentLocation=${row.companyLocationId}`}
                        >
                          {row.rcaCount}
                        </Link>
                      ) : (
                        <>{row.rcaCount}</>
                      )}
                    </TableCell>
                    <TableCell width={75}>
                      <ElipsisButton
                        actions={[
                          {
                            label: 'View',
                            onClick: () =>
                              onViewButtonClicked(row.companyLocationId),
                          },
                          {
                            label: 'Delete',
                            onClick: () =>
                              onDeleteCompanyClicked(row.companyLocationId),
                          },
                        ]}
                      />
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </WCTTable>
        </>
      )}
    </Column>
  );
}

export default requireAuth(FunctionalLocationsPage);

import requireAuth from '@components/hoc/with-required-auth-hoc';
import withEditorScaffold from '@pages/app/rca/tabs/components/rca-editor-scaffold';
import { Box, Link, styled, Typography } from '@mui/material';
import { OutlinedButton } from '@components/buttons';
import { useNavigate } from 'react-router-dom';
import useRcaEditorOverview from '@pages/app/rca/tabs/rca-editor-overview-hook';
import { ButtonSkeleton, InlineBoxSkeleton } from '@components/skeletons';
import Row from '@components/layout-util-components/row';
import Spacer from '@components/layout-util-components/spacer';
import LabelValueDisplay from '@components/data/label-value-display';
import LabelValueSkeleton from '@components/skeletons/label-value-skeleton';
import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import FlatBadge from '@components/badges/flat-badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCircleCheck } from '@fortawesome/pro-light-svg-icons';
import ContentContainer from '@components/containers/content-container';
import OverviewStatContainer from '@pages/app/rca/tabs/components/overview/overview-stat-container';
import { OverviewContainer } from '@pages/app/rca/tabs/components/overview/overview-container';
import TopStatEmptyImage from '@assets/images/empty-overview-item.png';
import OutcomeEmptyImage from '@assets/images/empty-outcome.png';
import EmptySection from '@pages/app/rca/tabs/components/overview/empty-section';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import CreateOutcomeDialog, {
  CreateOutcomeDialogProps,
  OutcomeType,
} from '@pages/app/rca/tabs/components/overview/create-outcome-dialog';
import SectionData from '@pages/app/rca/tabs/components/overview/section-data';
import OutcomesData from '@pages/app/rca/tabs/components/overview/outcomes-data';
import { useAppDispatch } from '@store/store';
import { RunReportResponse } from '@api/types/case/case-report/run-report.response';
import {
  RunReportModel,
  setLoadedReportData,
} from '@store/reports/reports-slice';
import { ReportType } from '@api/types/report-type/report-type.option';
import { ReportSubType } from '@api/types/report-type/report-subtype.option';
import { ReportRankBy } from '@api/types/report-type/report-rank-by.option';
import CompleteRcaModal from '@pages/app/rca/tabs/components/complete-rca/complete-rca-modal';
import ActivityPanelDrawer, {
  ActivityPanelDrawerProps,
} from '@pages/app/rca/tabs/components/events/activity-panel-drawer';
import { ActivityPanelView } from '@pages/app/rca/tabs/components/events/activity-panel-hook';
import { Gap } from '@components/layout-util-components/gap';

const Container = styled(ContentContainer)({
  '.quick-links': {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: '#EAEAEA',
    padding: '20px 20px 24px 20px',
    borderRadius: 4,
    alignSelf: 'stretch',

    h3: {
      margin: 0,
      padding: 0,
      fontFamily: 'Roboto',
      fontSize: '13px',
      fontWeight: '500',
      lineHeight: '13px',
      letterSpacing: '0px',
      textAlign: 'left',
      color: '#00000099',
    },

    '.link': {
      fontFamily: 'Roboto',
      fontSize: '13px',
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing: '0.15000000596046448px',
      textAlign: 'left',
      color: '#000000',
    },
  },
});

function RcaEditorOverview() {
  const { showPopup, showConfirmationModal } = useUiPopup();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    caseId,
    caseDetail,
    impacts,
    solutions,
    themes,
    totals,
    outcomes,
    isLoading,
    deleteOutcome,
    canShowCompleteButton,
  } = useRcaEditorOverview();

  const onAddOutcomeClicked = async () => {
    const result = await showPopup<CreateOutcomeDialogProps, OutcomeType>(
      CreateOutcomeDialog
    );
    if (result != null) {
      navigate(`/rca/outcome/${caseId}/?type=${result}`);
    }
  };

  const onEditOutcome = (outcomeId: number) => {
    navigate(`/rca/outcome/${caseId}/?outcomeId=${outcomeId}&step=0`);
  };
  const onViewOutcome = (outcomeId: number) => {
    navigate(`/rca/outcome/${caseId}/?outcomeId=${outcomeId}&step=2`);
  };

  const onDeleteOutcome = async (outcomeId: number) => {
    await showConfirmationModal({
      title: 'Delete Outcome',
      message: 'Are you sure you want to delete this outcome?',
      yesButtonLabel: 'Delete',
      noButtonLabel: 'Cancel',
      yesButtonAsyncAction: () => deleteOutcome(outcomeId),
    });
  };

  const openChartWithReportData = (
    data: RunReportResponse<any>,
    model: Omit<
      RunReportModel,
      'reportTypeId' | 'reportSubTypeId' | 'reportRankById'
    >
  ) => {
    dispatch(
      setLoadedReportData({
        data,
        model: {
          ...model,
          reportRankById: -1,
          reportSubTypeId: -1,
          reportTypeId: -1,
        },
      })
    );
    navigate(`/rca/tabs/${caseId}/chart`);
  };

  const startCompleteFlow = async () => {
    await showPopup(CompleteRcaModal);
  };

  return (
    <Container>
      <Gap size={32} />
      <Row>
        <div>
          <Typography variant="h5">
            {isLoading ? <InlineBoxSkeleton width={250} /> : caseDetail!.name}
          </Typography>
          <Gap size={8} />
          <Typography>
            {isLoading ? (
              <InlineBoxSkeleton width={150} />
            ) : (
              `Type: ${caseDetail!.caseAnalysisTypeName}`
            )}
          </Typography>
        </div>
        <Spacer />
        {isLoading ? (
          <LabelValueSkeleton />
        ) : (
          <LabelValueDisplay
            label="CREATED BY"
            value={
              <InitialsContainerWithData
                fullName={caseDetail!.createdByCompanyUserName}
              />
            }
          />
        )}
        <Gap size={64} />
        {isLoading ? (
          <LabelValueSkeleton />
        ) : (
          <LabelValueDisplay
            label="SENSITIVITY"
            value={caseDetail!.caseSeverityName}
          />
        )}
        <Gap size={64} />
        {isLoading ? (
          <LabelValueSkeleton />
        ) : (
          <LabelValueDisplay
            label="STATUS"
            value={<FlatBadge>{caseDetail!.caseStatusName}</FlatBadge>}
          />
        )}
        <Gap size={64} />
        {isLoading ? (
          <ButtonSkeleton noGrow />
        ) : canShowCompleteButton ? (
          <OutlinedButton onClick={startCompleteFlow}>
            Complete RCA
            <FontAwesomeIcon icon={faArrowRight} />
          </OutlinedButton>
        ) : null}
      </Row>
      <Gap size={32} />
      <Row>
        <Box
          display="flex"
          width="80%"
          flexWrap="wrap"
          alignItems="flex-start"
          rowGap="20px"
          columnGap="20px"
        >
          {isLoading ? (
            <InlineBoxSkeleton width={346} />
          ) : (
            <OverviewStatContainer
              icon={<FontAwesomeIcon icon={faCircleCheck} />}
              label="Cause Boxes"
              value={totals!.chainItems}
            />
          )}
          {isLoading ? (
            <InlineBoxSkeleton width={346} />
          ) : (
            <OverviewStatContainer
              icon={<FontAwesomeIcon icon={faCircleCheck} />}
              label="Solutions"
              value={totals!.solutions}
            />
          )}
          {isLoading ? (
            <InlineBoxSkeleton width={346} />
          ) : (
            <OverviewStatContainer
              icon={<FontAwesomeIcon icon={faCircleCheck} />}
              label="Impacts"
              value={totals!.impacts}
            />
          )}
          {isLoading ? (
            <InlineBoxSkeleton width={346} />
          ) : (
            <OverviewStatContainer
              icon={<FontAwesomeIcon icon={faCircleCheck} />}
              label="Themes"
              value={totals!.themes}
            />
          )}
          {isLoading ? (
            <InlineBoxSkeleton width={346} />
          ) : (
            <OverviewStatContainer
              icon={<FontAwesomeIcon icon={faCircleCheck} />}
              label="Team Members"
              value={totals!.team}
            />
          )}
          {isLoading ? (
            <InlineBoxSkeleton width={346} />
          ) : (
            <OverviewStatContainer
              icon={<FontAwesomeIcon icon={faCircleCheck} />}
              label="Groups"
              value={totals!.group}
            />
          )}
        </Box>
        <div className="quick-links">
          <h3>Quick Links</h3>
          <Gap size={12} />
          <Link className="link" href={`/rca/edit/${caseId}`}>
            Edit RCA <FontAwesomeIcon icon={faArrowRight} />
          </Link>
          <Gap size={4} />
          <Link className="link" href={`/rca/tabs/${caseId}/files`}>
            Files <FontAwesomeIcon icon={faArrowRight} />
          </Link>
          <Gap size={4} />
          <Link
            className="link"
            href="../activity"
            onClick={(e) => {
              e.preventDefault();
              showPopup<ActivityPanelDrawerProps, never>(ActivityPanelDrawer, {
                view: ActivityPanelView.caseLevel,
              });
            }}
          >
            View Activity Feed <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </div>
      </Row>
      <Gap size={32} />
      <Row gap={24} stretchItems>
        <OverviewContainer>
          {isLoading ? (
            <InlineBoxSkeleton width="33.33%" height={300} />
          ) : themes!.data.length > 0 ? (
            <SectionData
              title="Themes by coverage"
              items={themes!.data.map((row) => ({
                id: row.cells[2].value,
                title: row.cells[1].value,
                value: row.cells[3].value,
              }))}
              link={{
                text: 'View Coverage Report',
                onClick: () =>
                  openChartWithReportData(themes!, {
                    reportType: ReportType.coverage,
                    reportTypeName: 'Coverage',
                    reportSubType: ReportSubType.theme,
                    reportSubTypeName: 'Theme',
                    reportRankBy: ReportRankBy.coverage,
                    reportRankByName: 'Coverage',
                  }),
              }}
            />
          ) : (
            <EmptySection
              icon={<img src={TopStatEmptyImage} alt="empty icon" />}
              title="No themes assigned to visual analysis"
            />
          )}
        </OverviewContainer>
        <OverviewContainer>
          {isLoading ? (
            <InlineBoxSkeleton width="33.33%" height={300} />
          ) : solutions!.data.length > 0 ? (
            <SectionData
              title="Solutions by coverage"
              items={solutions!.data.map((row) => ({
                id: row.cells[2].value,
                title: row.cells[1].value,
                value: row.cells[3].value,
              }))}
              link={{
                text: 'View Coverage Report',
                onClick: () =>
                  openChartWithReportData(solutions!, {
                    reportType: ReportType.coverage,
                    reportTypeName: 'Coverage',
                    reportSubType: ReportSubType.solution,
                    reportSubTypeName: 'Solution',
                    reportRankBy: ReportRankBy.coverage,
                    reportRankByName: 'Coverage',
                  }),
              }}
            />
          ) : (
            <EmptySection
              icon={<img src={TopStatEmptyImage} alt="empty icon" />}
              title="No solutions assigned to visual analysis"
            />
          )}
        </OverviewContainer>
        <OverviewContainer>
          {isLoading ? (
            <InlineBoxSkeleton width="33.33%" height={300} />
          ) : impacts!.data.length > 0 ? (
            <SectionData
              title="Impacts category by coverage"
              items={impacts!.data.map((row) => ({
                id: row.cells[3].value,
                title: row.cells[1].value,
                value: row.cells[4].value,
              }))}
              link={{
                href: `../../edit/${caseId}/?initialTab=impacts`,
                text: 'View Impacts',
              }}
            />
          ) : (
            <EmptySection
              icon={<img src={TopStatEmptyImage} alt="empty icon" />}
              title="No impacts added to RCA problem analysis"
            />
          )}
        </OverviewContainer>
      </Row>
      <Gap size={32} />
      <OverviewContainer>
        <Row>
          {isLoading ? (
            <InlineBoxSkeleton width="100%" height={300} />
          ) : outcomes!.model.length > 0 ? (
            <OutcomesData
              onDeleteOutcome={onDeleteOutcome}
              onEditOutcome={onEditOutcome}
              onViewOutcome={onViewOutcome}
              outcomes={outcomes!.model}
              action={
                <OutlinedButton onClick={onAddOutcomeClicked}>
                  Add Outcomes
                </OutlinedButton>
              }
            />
          ) : (
            <EmptySection
              asRow
              icon={<img src={OutcomeEmptyImage} alt="empty icon" />}
              title="You don’t currently have any outcomes"
              message="There aren’t currently any tasks within the platform, you can add one below."
              action={
                <OutlinedButton onClick={onAddOutcomeClicked}>
                  Add Outcomes
                </OutlinedButton>
              }
            />
          )}
        </Row>
      </OverviewContainer>
    </Container>
  );
}

export default requireAuth(withEditorScaffold(RcaEditorOverview));

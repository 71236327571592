import { styled } from '@mui/material';
import {
  NodeCollapseState,
  RcaNode,
  RcaNodeType,
} from '@store/rca-editor/types';
import { Handle, Position } from 'reactflow';
import { useAppDispatch, useAppSelector } from '@store/store';
import { makeSelectParentOrChildrenCollapsedState } from '@store/rca-editor/selectors';
import usePopoverMenu from '@components/popover-menu/use-popover-menu';
import { removeNode } from '@store/rca-editor/rca-editor-actions';
import PopoverMenu from '@components/popover-menu/popover-menu';
import { useMemo } from 'react';

const Indicator = styled('div')(({ theme: { palette } }) => ({
  zIndex: 1,
  border: 'none',
  width: 25,
  height: 25,
  padding: '7px 6px 7px 6px',
  borderRadius: '50%',
  background: palette.common.grey90,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  fontFamily: 'Roboto',
  fontSize: '9px',
  fontWeight: '500',
  lineHeight: '8px',
  letterSpacing: '0px',
  textAlign: 'center',
  textTransform: 'uppercase',
  cursor: 'default',
}));

export default function RcaMetaNode({ type, id }: RcaNode) {
  const dispatch = useAppDispatch();
  const menu = usePopoverMenu();

  const selectCollapsedState = useMemo(() => {
    return makeSelectParentOrChildrenCollapsedState(id);
  }, [id]);

  const collapsedState = useAppSelector(selectCollapsedState)!;
  if (
    collapsedState === NodeCollapseState.ghost ||
    collapsedState === NodeCollapseState.hidden
  ) {
    return <></>;
  }

  return (
    <>
      <Handle position={Position.Left} type="target" />
      <Indicator
        ref={menu.ref}
        sx={{
          pointerEvents: 'none !important',
        }}
        onMouseDownCapture={(e) => {
          e.stopPropagation();
          e.preventDefault();

          menu.open([
            {
              label: 'Remove',
              onClick: () =>
                dispatch(removeNode(id, { reparentChildren: true })),
            },
          ]);
        }}
      >
        {type === RcaNodeType.or ? 'or' : 'and /or'}
      </Indicator>
      <Handle position={Position.Right} type="source" />
      <PopoverMenu {...menu} />
    </>
  );
}

import { styled } from '@mui/material';

export const HeaderComponent = styled('div')({
  height: 225,
  backgroundColor: '#D9D9D9BF',

  '.icon-container': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 250,
    width: 250,

    '& img': {
      width: '100%',
      height: '100%',
    },
  },
});

import { api } from '@api/api';
import { ChainItemResource } from '@api/types/chain/chain-item.resource';
import { CreateStorageItemRequest } from '@api/types/chain/chain-item-storage/create-storage-item.request';
import { makeError } from '@api/types/api-error';
import { UpdateStorageItemRequest } from '@api/types/chain/chain-item-storage/update-storage-item.request';

export interface ChainItemStoragePathParams {
  chainId: number;
  chainItemId?: number;
}

const chainItemStorageApi = api.injectEndpoints({
  endpoints: (build) => ({
    createStorageItem: build.mutation<
      ChainItemResource,
      CreateStorageItemRequest
    >({
      query: ({ chainId, ...body }) => ({
        url: `chain/${chainId}/chainItem/storage`,
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<CreateStorageItemRequest>(error),
    }),
    updateStorageItem: build.mutation<void, UpdateStorageItemRequest>({
      query: ({ chainId, chainItemId, ...body }) => ({
        url: `chain/${chainId}/chainItem/storage/${chainItemId}`,
        method: 'PUT',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<UpdateStorageItemRequest>(error),
    }),
    deleteStorageItem: build.mutation<void, ChainItemStoragePathParams>({
      query: ({ chainId, chainItemId }) => ({
        url: `chain/${chainId}/chainItem/storage/${chainItemId}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (error) =>
        makeError<ChainItemStoragePathParams>(error),
    }),
  }),
});

export const {
  useCreateStorageItemMutation,
  useUpdateStorageItemMutation,
  useDeleteStorageItemMutation,
} = chainItemStorageApi;

export default chainItemStorageApi;

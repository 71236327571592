import { styled } from '@mui/material';
import FlatBadge from '@components/badges/flat-badge';

interface Props {
  label: string;
  value: string;
}

const BadgeInfoContainer = styled('div')(({ theme: { palette } }) => ({
  display: 'inline-flex',
  flexDirection: 'column',
  gap: 9,
  alignItems: 'flex-start',

  '.label': {
    fontFamily: 'Roboto',
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '19px',
    letterSpacing: '0.17000000178813934px',
    textAlign: 'center',
    color: palette.common.grey60,
  },

  '.value': {
    '*': {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '13px',
      letterSpacing: '0.11861567944288254px',
      textAlign: 'left',
    },
  },
}));

export default function BadgeInfo({ label, value }: Props) {
  return (
    <BadgeInfoContainer>
      <div className="label">{label}</div>
      <div className="value">
        <FlatBadge rounded>{value}</FlatBadge>
      </div>
    </BadgeInfoContainer>
  );
}

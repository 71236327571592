import { ReactNode } from 'react';
import { styled } from '@mui/material';

interface Props {
  label?: ReactNode;
  value?: ReactNode;
}

const Container = styled('dl')({
  display: 'inline-flex',
  flexDirection: 'column',
  margin: 0,
});

const StyledDT = styled('dt')({
  fontSize: 10,
  fontWeight: 600,
  color: '#949FA2',
});

const StyledDD = styled('dd')({
  fontSize: 12,
  fontWeight: 400,
  lineHeight: '17px',
  color: '#000000DE',
  margin: 0,
  a: {
    fontSize: 12,
    fontWeight: 400,
    padding: 0,
  },
});

export default function LabelValueDisplay({ label, value }: Props) {
  return (
    <Container>
      {label ? <StyledDT>{label}</StyledDT> : null}
      {value ? <StyledDD>{value}</StyledDD> : null}
    </Container>
  );
}

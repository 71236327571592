import { ReactNode } from 'react';
import { Box, Link, styled } from '@mui/material';
import Row from '@components/layout-util-components/row';
import Spacer from '@components/layout-util-components/spacer';
import FlatBadge from '@components/badges/flat-badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { Gap } from '@components/layout-util-components/gap';

interface ItemData {
  id: string;
  title: string;
  value: string;
}

interface LinkData {
  text: string;
  href?: string;
  onClick?: () => void;
}

interface Props {
  title: string;
  items: ItemData[];
  actions?: ReactNode;
  link?: LinkData;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',

  h4: {
    margin: 0,
    padding: 0,
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '29px',
    letterSpacing: '0.15000000596046448px',
    textAlign: 'left',
    color: '#000000',
  },

  table: {
    // remove border between cells
    borderCollapse: 'separate',
    borderSpacing: '0 4px',

    td: {
      padding: '24px 20px',
      backgroundColor: '#F4F4F4',
    },
  },
});

export default function SectionData({ link, actions, items, title }: Props) {
  return (
    <Container>
      <Row>
        <h4>{title}</h4>
        <Spacer />
        {actions ? actions : null}
      </Row>
      <Gap size={20} />
      <table>
        <tbody>
          {items.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.title}</td>
              <td>
                <Box display="flex">
                  <FlatBadge rounded>{item.value}</FlatBadge>
                </Box>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Gap size={12} />
      <Spacer />
      <Row>
        <Spacer />
        {link ? (
          <Link
            href={link.href ?? '#'}
            className="link"
            onClick={(e) => {
              if (link?.onClick != null) {
                e.preventDefault();
                link.onClick();
              }
            }}
          >
            {link.text}
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        ) : null}
      </Row>
    </Container>
  );
}

import { useAppSelector } from '@store/store';
import {
  selectChainId,
  selectCurrentRcaCaseId,
  selectFocusedNode,
} from '@store/rca-editor/selectors';
import useLoadMore from '@hooks/use-load-more-hook';
import {
  useGetEventsForCaseQuery,
  useGetEventsForChainItemQuery,
  useGetEventsForChainQuery,
} from '@api/endpoints/events.api';

export enum ActivityPanelView {
  caseLevel,
  chainLevel,
  chainItemLevel,
}

export default function useActivityPanel(view: ActivityPanelView) {
  const caseId = useAppSelector(selectCurrentRcaCaseId);
  const chainId = useAppSelector(selectChainId);
  const focusedNode = useAppSelector(selectFocusedNode);
  const { chainItemId } = focusedNode?.data ?? {};

  const { loadMore, canLoadMore, skip, take } = useLoadMore();

  const { data: caseEvents, isLoading: loadingCaseEvents } =
    useGetEventsForCaseQuery(
      { caseId, skip, take },
      {
        refetchOnMountOrArgChange: true,
        skip: view !== ActivityPanelView.caseLevel,
      }
    );

  const { data: chainEvents, isLoading: loadingChainEvents } =
    useGetEventsForChainQuery(
      { chainId: chainId ?? -1, skip, take },
      {
        refetchOnMountOrArgChange: true,
        skip: view !== ActivityPanelView.chainLevel,
      }
    );

  const { data: chainItemEvents, isLoading: loadingChainItemEvents } =
    useGetEventsForChainItemQuery(
      { chainId: chainId ?? -1, chainItemId: chainItemId ?? -1, skip, take },
      {
        refetchOnMountOrArgChange: true,
        skip: view !== ActivityPanelView.chainItemLevel,
      }
    );

  const isLoading =
    loadingCaseEvents || loadingChainEvents || loadingChainItemEvents;

  return {
    events: caseEvents ?? chainEvents ?? chainItemEvents,
    isLoading,
    loadMore,
    canLoadMore,
    chainId,
    chainItemId,
  };
}

export type ActivityPanelState = ReturnType<typeof useActivityPanel>;

import { styled } from '@mui/material';
import { ReactComponent as TaskCompleteIcon } from '@assets/svgs/task-complete.svg';
import { ReactComponent as TaskIncompleteIcon } from '@assets/svgs/task-incomplete.svg';

interface Props {
  isComplete: boolean;
  onComplete: () => void;
  onOpen: () => void;
}

const StyledIndicatorContainer = styled('div')({
  width: 32,
  height: 32,
  cursor: 'pointer',
  '& > *': {
    width: '100%',
    height: '100%',
  },
});

export default function TaskCompleteIndicator({
  isComplete,
  onComplete,
  onOpen,
}: Props) {
  return (
    <StyledIndicatorContainer
      onClick={(e) => {
        e.stopPropagation();
        isComplete ? onOpen() : onComplete();
      }}
    >
      {isComplete ? <TaskCompleteIcon /> : <TaskIncompleteIcon />}
    </StyledIndicatorContainer>
  );
}

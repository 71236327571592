import requireAuth from '@components/hoc/with-required-auth-hoc';
import useDashboard from './dashboard-hook';
import { Box, Button, Chip, styled, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faCalendar,
  faDiagramProject,
  faUser,
  IconDefinition,
} from '@fortawesome/pro-light-svg-icons';
import { CompanyUserCaseTotalsResponse } from '@api/types/dashboard/company-user-case-totals-response';
import { getColorForId } from '@util/colour-identifiers';
import { SolutionsByTermResponse } from '@api/types/dashboard/solutions-by-term-response';
import { SolutionsByEffectivenessResponse } from '@api/types/dashboard/solutions-by-effectiveness-response';
import { ReactComponent as BarsIcon } from '@assets/svgs/bars.svg';
import { ReactComponent as GridIcon } from '@assets/svgs/grid-2.svg';
import { ReactComponent as LocationIcon } from '@assets/svgs/location-dot.svg';
import { LoadingIndicator } from '@components/loading-indicator';
import RadioFilter from '@components/table/filters/radio-filter';
import { AnalysisViewMode } from '@pages/app/analysis/analysis-overview-hook';
import { useMemo } from 'react';
import RcaCard from '@components/cards/rca-card';
import { RCAUserTaskRow } from './user-task-row';
import RcaGroupCard from './rca-group-card';
import useUser from '@store/user/user-hook';
import { v4 as uuid } from 'uuid';
import AnalysisMapView from '@pages/app/analysis/components/analysis-map-view';
import { RCAUserOutcomeRow } from './user-outcome-row';
import AnalysisTableView from '@pages/app/analysis/components/analysis-table-view';
import { DropDownFilter } from '@components/table';
import { ButtonSkeleton } from '@components/skeletons';
import { PrimaryButton } from '@components/buttons';
import { useNavigate } from 'react-router-dom';

const StyledHeaderBox = styled(Box)<{ styleButtons?: true }>(
  ({ theme: { palette }, styleButtons }) => ({
    display: 'flex',
    flex: '1 1 auto',
    flexWrap: 'wrap',
    marginBottom: 25,
    alignItems: 'center',
    '.radio-filter': {
      marginLeft: 'auto',
    },
    rowGap: 10,
    columnGap: 16,
    ...(styleButtons && {
      button: {
        borderRadius: 4,
        background: palette.common.grey40,
        color: '#001927',
        fontSize: '13px',
        lineHeight: '24px' /* 184.615% */,
        letterSpacing: '0.4px',
        display: 'flex',
        gap: 8,
        padding: '6px 16px',
        justifyContent: 'center',
        alignItems: 'center',
        '&.create-rca, &.active': {
          background: 'linear-gradient(90deg, #FF6C00 0%, #FF5451 100%)',
          color: palette.common.white,
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '24px' /* 171.429% */,
          letterSpacing: '0.4px',
        },
      },
    }),
    h2: {
      color: palette.common.black,
      fontSize: 24,
      fontWeight: 500,
      lineHeight: '133.4%' /* 32.016px */,
      marginRight: 'auto',
    },
  })
);

const StyledStatContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  gap: 20,
  flex: '1 0 0',
  alignSelf: 'stretch',
  hr: {
    height: 1,
    background: 'rgba(0, 0, 0, 0.15)',
    margin: '20px 0 10px 0',
  },
  '&.user-case-totals': {
    '> .MuiBox-root': {
      gap: 16,
      alignItems: 'center',
      '> .MuiBox-root:first-of-type': {
        display: 'flex',
        alignItems: 'center',
        width: '32px',
        height: '32px',
        justifyContent: 'center',
        fontSize: '14px',
        fontWeight: 300,
        borderRadius: 4,
      },
      '> .MuiBox-root:last-of-type': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
  '&.coverage-solutions': {
    margin: '30px 0',
    '> .MuiBox-root': {
      flexDirection: 'column',
      '> .MuiBox-root.coverage': {
        marginTop: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
      },
      '> .MuiBox-root.title': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 11,
      },
      '> .MuiBox-root.solution': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
  },
}));

const StyledHeader = styled(Typography)({
  fontSize: 18,
  lineHeight: '133.4%' /* 24.012px */,
  fontWeight: 400,
});

const StyledSubHeader = styled(Typography)({
  color: '#949FA2',
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '150%' /* 18px */,
  letterSpacing: '0.15px',
});

const StyledStatBox = styled(Box)(({ theme: { palette } }) => ({
  borderRadius: 4,
  display: 'flex',
  background: palette.common.white,
  padding: '12px 20px 12px 16px',
  flex: '1 0 0',
  alignSelf: 'stretch',
}));

const StyledThemeByCoverageBox = styled(Box)({
  display: 'flex',
  padding: '8px 10px 8px 16px',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
  borderRadius: 3,
  border: '1px solid #E0E0E0',
  background: 'white',
  p: {
    fontSize: 14,
  },
});

const StyledChip = styled(Chip)(() => ({
  borderRadius: 76,
  background: '#F8F8FA',
  width: 61,
  '> span > .MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    p: {
      fontSize: 12,
      color: '#525B5E',
    },
  },
}));

const StyledSolutionBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  '.MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '> .MuiBox-root.solution': {
    flexDirection: 'column',
    borderRadius: 5,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '7px 13px 5px 13px',
    '> .MuiBox-root': {
      gap: 8,
    },
    '> p': {
      textTransform: 'uppercase',
    },
  },
});

const StyledViewAllButton = styled(Button)({
  marginTop: 'auto',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '143%' /* 20.02px */,
  letterSpacing: '0.17px',
  color: '#6B858C',
  width: 'fit-content',
  alignSelf: 'flex-end',
  display: 'flex',
  gap: 8,
  textTransform: 'none',
});

const PanelContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 25,
  alignSelf: 'stretch',
  '>div': {
    '@media (max-width:750px)': {
      width: '100%',
    },
  },
});

const TaskContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

const companyUserCaseTotalToIconAndLabel: Record<
  keyof CompanyUserCaseTotalsResponse,
  { label: string; icon: IconDefinition; bgColour: string; fontColour: string }
> = {
  openCases: {
    label: 'OPEN RCAS',
    icon: faDiagramProject,
    bgColour: '#C8F8EF66',
    fontColour: '#279987',
  },
  overdueTasks: {
    label: 'OVERDUE TASKS',
    icon: faCalendar,
    bgColour: '#FF6C0012',
    fontColour: '#FF6C00',
  },
  totalCases: {
    label: 'TOTAL RCAS',
    icon: faDiagramProject,
    bgColour: '#FFCFAB3D',
    fontColour: '#EA6300',
  },
  users: {
    label: 'USERS',
    icon: faUser,
    bgColour: '#DC4EFF0F',
    fontColour: '#C640A9',
  },
};

function DashboardPage() {
  const {
    companyUserCaseTotals,
    themesByCoverage,
    impactsByCoverage,
    solutionsByTerm,
    solutionsByEffectiveness,
    isLoading,
    isLoadingPanels,
    categoryFilters,
    onCategoryFilterChange,
    activeCategoryFilter,
    RCAs,
    tasks,
    groups,
    outcomes,
    viewMode,
    rcaListPagination,
    rcaListSort,
    caseAnalysisTypeOptions,
    caseAnalysisTypeFilter,
    companyUserFilter,
    userOptions,
  } = useDashboard();

  const { firstName } = useUser();

  const navigate = useNavigate();

  const filterOptions = useMemo(() => {
    if (['Outcomes', 'Tasks', 'Groups'].includes(activeCategoryFilter)) {
      return [];
    }

    const options = [
      { id: AnalysisViewMode.grid, label: <GridIcon /> },
      { id: AnalysisViewMode.table, label: <BarsIcon /> },
      { id: AnalysisViewMode.map, label: <LocationIcon /> },
    ];

    return options;
  }, [activeCategoryFilter]);

  if (isLoading) {
    return <StyledHeaderBox />;
  }

  const buildRCAView = () => {
    if (activeCategoryFilter === 'RCA') {
      if (viewMode.value === AnalysisViewMode.grid) {
        return (
          <PanelContainer>
            {RCAs.map((rca) => (
              <RcaCard key={rca.caseId} data={rca} />
            ))}
          </PanelContainer>
        );
      }

      if (viewMode.value === AnalysisViewMode.table) {
        return (
          <AnalysisTableView
            pagination={rcaListPagination}
            sort={rcaListSort}
            caseData={{
              totalCount: RCAs.length,
              model: RCAs,
              count: RCAs.length,
            }}
          />
        );
      }

      if (viewMode.value === AnalysisViewMode.map) {
        return (
          <AnalysisMapView
            viewMode={viewMode}
            caseData={{
              count: RCAs.length,
              model: RCAs,
              totalCount: RCAs.length,
            }}
            location={{
              arrayValue: undefined,
              value: undefined,
              set: () => null,
            }}
          />
        );
      }
    }

    if (activeCategoryFilter === 'Outcomes') {
      return (
        <TaskContainer>
          {outcomes.map((outcome) => (
            <RCAUserOutcomeRow key={outcome.outcomeId} outcome={outcome} />
          ))}
        </TaskContainer>
      );
    }

    if (activeCategoryFilter === 'Tasks') {
      return (
        <TaskContainer>
          {tasks.map((task) => (
            <RCAUserTaskRow key={task.caseId} task={task} />
          ))}
        </TaskContainer>
      );
    }

    if (activeCategoryFilter === 'Groups') {
      return (
        <PanelContainer>
          {groups.map((group) => (
            <RcaGroupCard key={group.companyGroupId} group={group} />
          ))}
        </PanelContainer>
      );
    }

    return <p>Missing designs</p>;
  };

  const solutionsToGradient = ({
    data,
  }: SolutionsByTermResponse | SolutionsByEffectivenessResponse) => {
    const id = Number(data?.[0]?.[2]?.value ?? 1);
    const defaultColor = getColorForId(id);

    // Initialise with fallback colour
    let gradientString = `linear-gradient(90deg, ${defaultColor} 0% 100%`;

    data.forEach(({ '2': badge, '3': percent }, i) => {
      const startPoint = !i ? '0%' : data[i - 1][3].value;
      const stopPoint = percent.value || startPoint;

      const color = getColorForId(Number(badge.value));

      gradientString += `, ${color} ${startPoint} ${stopPoint}`;
    });

    return (gradientString += ')');
  };

  return (
    <Box id="dashboard-page" sx={{ padding: '5vh 5vw' }}>
      <StyledHeaderBox>
        <Typography variant="h2">Welcome back, {firstName}!</Typography>
        {isLoading ? (
          <ButtonSkeleton />
        ) : (
          <DropDownFilter
            options={caseAnalysisTypeOptions!.map((opt) => ({
              id: opt.shortName,
              label: opt.name,
            }))}
            value={caseAnalysisTypeFilter.value}
            onChange={caseAnalysisTypeFilter.set}
          >
            RCA Type
          </DropDownFilter>
        )}
        {isLoading ? (
          <ButtonSkeleton />
        ) : (
          <DropDownFilter
            options={userOptions!}
            value={companyUserFilter.value}
            onChange={companyUserFilter.set}
          >
            User
          </DropDownFilter>
        )}
        <PrimaryButton
          buttonVariant="two"
          onClick={() => navigate('/rca/create')}
        >
          Create RCA
        </PrimaryButton>
      </StyledHeaderBox>
      {companyUserCaseTotals && (
        <StyledStatContainer className="user-case-totals">
          {Object.entries(companyUserCaseTotals).map(([key, value]) => {
            const { icon, label, bgColour, fontColour } =
              companyUserCaseTotalToIconAndLabel[
                key as keyof CompanyUserCaseTotalsResponse
              ];

            return (
              <StyledStatBox key={key}>
                <Box sx={{ background: bgColour, color: fontColour }}>
                  <FontAwesomeIcon icon={icon} />
                </Box>
                <Box>
                  <StyledHeader variant="body1">{value}</StyledHeader>
                  <StyledSubHeader variant="body2">{label}</StyledSubHeader>
                </Box>
              </StyledStatBox>
            );
          })}
        </StyledStatContainer>
      )}
      <StyledStatContainer className="coverage-solutions">
        {!!themesByCoverage?.data.length && (
          <StyledStatBox>
            <StyledHeader variant="body1">Top themes by coverage</StyledHeader>
            <StyledSubHeader variant="body1">ALL RCAS</StyledSubHeader>
            <Box className="coverage">
              {themesByCoverage.data.map((data, i) => (
                <StyledThemeByCoverageBox key={uuid()}>
                  <StyledHeader>{i + 1}</StyledHeader>
                  <StyledHeader>{data[1].value}</StyledHeader>
                  <StyledChip
                    variant="filled"
                    label={
                      <Box>
                        <Box
                          sx={{
                            width: '8px',
                            height: '8px',
                            borderRadius: 8,
                            background: getColorForId(Number(data[2].value)),
                          }}
                        />
                        <Typography>
                          {Math.round(parseFloat(data[3].value))}%
                        </Typography>
                      </Box>
                    }
                  />
                </StyledThemeByCoverageBox>
              ))}
            </Box>
            <StyledViewAllButton>
              View all <FontAwesomeIcon icon={faArrowRight} />
            </StyledViewAllButton>
          </StyledStatBox>
        )}
        {impactsByCoverage && (
          <StyledStatBox>
            <StyledHeader variant="body1">
              Impact categories by coverage
            </StyledHeader>
            <StyledSubHeader variant="body1">ALL RCAS</StyledSubHeader>
            <Box className="coverage">
              {impactsByCoverage.data.map((data, i) => (
                <StyledThemeByCoverageBox key={uuid()}>
                  <StyledHeader>{i + 1}</StyledHeader>
                  <StyledHeader>{data[1].value}</StyledHeader>
                  <StyledChip
                    variant="filled"
                    label={
                      <Box>
                        <Box
                          sx={{
                            width: '8px',
                            height: '8px',
                            borderRadius: 8,
                            background: getColorForId(Number(data[2].value)),
                          }}
                        />
                        <Typography>
                          {Math.round(parseFloat(data[3].value))}%
                        </Typography>
                      </Box>
                    }
                  />
                </StyledThemeByCoverageBox>
              ))}
            </Box>
            <StyledViewAllButton>
              View all <FontAwesomeIcon icon={faArrowRight} />
            </StyledViewAllButton>
          </StyledStatBox>
        )}
        <StyledStatBox>
          <StyledHeader variant="body1">Solutions</StyledHeader>
          <StyledSubHeader variant="body1">ALL RCAS</StyledSubHeader>
          {solutionsByTerm && (
            <>
              <Box className="title">
                <StyledHeader>Term breakdown</StyledHeader>
                <StyledSubHeader>155 Solutions</StyledSubHeader>
              </Box>
              <Box
                sx={{
                  height: 25,
                  marginY: 1.5,
                  borderRadius: 2,
                  background: solutionsToGradient(solutionsByTerm),
                }}
              />
              <StyledSolutionBox>
                {solutionsByTerm.data.map((data, i) => (
                  <Box className="solution" key={i}>
                    <Box>
                      <Box
                        sx={{
                          width: '8px',
                          height: '8px',
                          borderRadius: 8,
                          background: getColorForId(Number(data[2].value)),
                        }}
                      />
                      <StyledHeader>{data[3].value || '0.00%'}</StyledHeader>
                    </Box>
                    <StyledSubHeader>{data[1].value}</StyledSubHeader>
                  </Box>
                ))}
              </StyledSolutionBox>
              <hr />
            </>
          )}
          {solutionsByEffectiveness && (
            <>
              <Box className="title">
                <StyledHeader>Effectiveness breakdown</StyledHeader>
                <StyledSubHeader>155 Solutions</StyledSubHeader>
              </Box>
              <Box
                sx={{
                  height: 25,
                  marginY: 1.5,
                  borderRadius: 2,
                  background: solutionsToGradient(solutionsByEffectiveness),
                }}
              />
              <StyledSolutionBox>
                {solutionsByEffectiveness.data.map((data) => (
                  <Box className="solution" key={uuid()}>
                    <Box>
                      <Box
                        sx={{
                          width: '8px',
                          height: '8px',
                          borderRadius: 8,
                          background: getColorForId(Number(data[2].value)),
                        }}
                      />
                      <StyledHeader>{data[3].value || '100%'}</StyledHeader>
                    </Box>
                    <StyledSubHeader>{data[1].value}</StyledSubHeader>
                  </Box>
                ))}
              </StyledSolutionBox>
            </>
          )}
        </StyledStatBox>
      </StyledStatContainer>
      <StyledHeaderBox styleButtons>
        {categoryFilters.map((filter) => (
          <Button
            key={filter}
            id={filter}
            disabled={isLoadingPanels}
            onClick={onCategoryFilterChange}
            className={activeCategoryFilter === filter ? 'active' : ''}
          >
            {filter}
          </Button>
        ))}
        <RadioFilter
          options={filterOptions}
          value={viewMode.value}
          onChange={viewMode.set}
        />
      </StyledHeaderBox>
      <LoadingIndicator show={isLoadingPanels} />
      {buildRCAView()}
    </Box>
  );
}

export default requireAuth(DashboardPage);

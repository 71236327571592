import requireAuth from '@components/hoc/with-required-auth-hoc';
import useCompanyCaseAnalysisTypes from './company-case-analysis-types-hook';
import ReferenceDataDisableList from './components/reference-data-disable-list';
import SubpageTitle from '@components/menu-items/subpage-title';
import { Gap } from '@components/layout-util-components/gap';

function CompanyCaseAnalysisTypesPage() {
  const {
    options,
    loadingCaseAnalysisTypes,
    enableCaseAnalysisType,
    disableCaseAnalysisType,
  } = useCompanyCaseAnalysisTypes();

  return (
    <>
      <SubpageTitle breadCrumb="RCA Settings" title="RCA Type Configuration" />
      <Gap size={32} />
      <ReferenceDataDisableList
        options={options}
        isLoading={loadingCaseAnalysisTypes}
        onEnable={enableCaseAnalysisType}
        onDisable={disableCaseAnalysisType}
        type="caseAnalysisType"
      />
    </>
  );
}

export default requireAuth(CompanyCaseAnalysisTypesPage);

import { Grid, LinearProgress, Typography } from '@mui/material';
import useCreateAccountProgress from '@pages/auth/create-account-progress-hook';
import { useAuthTr } from '@i18n/use-auth-tr';
import requireAuth from '@components/hoc/with-required-auth-hoc';
import { isAdmin } from '@util/env';
import { useLocation } from 'react-router-dom';
import { PageAlertDisplay } from '@components/alerts';
import React from 'react';
import { Gap } from '@components/layout-util-components/gap';

type CreateAccountProgressPageState = {
  isUserInvite?: boolean;
};

function CreateAccountProgressPage() {
  const { progress } = useCreateAccountProgress();

  const { state } = useLocation();
  const { isUserInvite } = (state || {}) as CreateAccountProgressPageState;

  const { t } = useAuthTr('createAccountProgress');

  const prefix = isAdmin || isUserInvite ? 'admin' : 'app';

  return (
    <Grid container direction="column" gap={2} justifyContent="center">
      <Typography variant="h4">{t(`${prefix}.title`)}</Typography>
      <Typography variant="body1">{t(`${prefix}.message`)}</Typography>

      <Gap size={2} />
      <PageAlertDisplay />

      <LinearProgress variant="determinate" value={progress} />
    </Grid>
  );
}

export default requireAuth(CreateAccountProgressPage);

import requireAuth from '@components/hoc/with-required-auth-hoc';
import Column from '@components/layout-util-components/column';
import ContentContainer from '@components/containers/content-container';
import { Box, Typography } from '@mui/material';
import useSolutions from '@pages/app/solutions/solutions-index-hook';
import Row from '@components/layout-util-components/row';
import {
  DropDownFilter,
  SortByFilter,
  WCTTable,
  WCTTableFilters,
} from '@components/table';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { PageAlertDisplay, usePageAlertVariants } from '@components/alerts';
import NoSolutionsInfo from './components/no-solutions-info';
import SolutionsTableRow from './components/solutions-table-row';
import SolutionPopoupView from './components/view-solution-popup';
import deleteConfirmationModal from '@components/ui-popup/modals/delete-confirmation-modal';
import useSearchParamsTyped from '@hooks/router-dom-helper-hooks';
import { useCallback, useEffect, useRef } from 'react';
import { numberFromString } from '@util/string-util';
import Spacer from '@components/layout-util-components/spacer';
import { OutlinedButton } from '@components/buttons';
import { ButtonSkeleton } from '@components/skeletons';

interface Props {
  caseId?: number;
  canAddSolution?: boolean;
}

type QueryParams = {
  solutionId?: string;
};

function SolutionsIndexPage({ caseId }: Props) {
  const { solutionId: solutionIdQuery } = useSearchParamsTyped<QueryParams>();
  const { showPopup, showSingle } = useUiPopup();
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();

  const {
    hasNoSolutions,
    solutions,
    search,
    sort,
    sortByOptions,
    isLoading,
    pagination,
    typeFilter,
    typeFilterOptions,
    effectivenessFilter,
    effectivenessFilterOptions,
    termFilter,
    termFilterOptions,
    deleteSolution,
    canDelete,
  } = useSolutions(caseId);

  const onAddSolution = () => {
    showSingle(() =>
      showPopup(SolutionPopoupView, {
        caseId: caseId!,
        isViewMode: false,
      })
    );
  };

  const onEditSolution = (solutionId: number) => {
    onOpenSolution(solutionId, false);
  };

  const onDeleteSolution = (solutionId: number) => {
    showPopup(deleteConfirmationModal, {
      itemName: 'Solution',
      title: 'Are you sure you want to delete this solution?',
      message:
        'Are you sure you want to delete this solution?\n\nPlease note this will remove this solution from all associated cause boxes.',
      deleteButtonMessage: 'Delete Solution',
      onDelete: async () => {
        try {
          await deleteSolution({ caseId: caseId!, solutionId });
          showSuccessMessage('Solution deleted successfully');
          return true;
        } catch (error) {
          showErrorMessage('Something went wrong');
          return false;
        }
      },
    });
  };

  const onOpenSolution = useCallback(
    (solutionId: number, openInViewMode?: boolean) => {
      showSingle(() =>
        showPopup(SolutionPopoupView, {
          caseId: caseId!,
          solutionId: solutionId,
          isViewMode: openInViewMode,
        })
      );
    },
    [caseId, showPopup, showSingle]
  );

  const hasOpenedInitial = useRef(false);
  useEffect(() => {
    if (hasOpenedInitial.current) {
      return;
    }

    const id = numberFromString(solutionIdQuery);
    if (id != null) {
      onOpenSolution(id, true);
      hasOpenedInitial.current = true;
    }
  }, [onOpenSolution, solutionIdQuery]);

  return (
    <Column>
      {caseId == null ? <PageAlertDisplay /> : null}
      <ContentContainer>
        {hasNoSolutions ? (
          <Typography variant="h1">Solutions</Typography>
        ) : (
          <Row>
            <Typography variant="h1">
              Solutions ({solutions?.totalCount ?? 0})
            </Typography>
            <Spacer />
            {isLoading ? (
              <Box>
                <ButtonSkeleton />
              </Box>
            ) : (
              <OutlinedButton onClick={onAddSolution}>
                Add Solution
              </OutlinedButton>
            )}
          </Row>
        )}
      </ContentContainer>
      <ContentContainer>
        {hasNoSolutions ? (
          <NoSolutionsInfo isCaseSolutions={caseId != null} />
        ) : (
          <WCTTable
            count={solutions?.totalCount ?? 0}
            isLoading={isLoading}
            pagination={pagination}
            filters={
              <WCTTableFilters
                search={search.value}
                onSearch={search.set}
                isLoading={isLoading}
                rightOfSearchContent={
                  <SortByFilter
                    value={sort.value}
                    onChange={sort.set}
                    options={sortByOptions}
                  />
                }
              >
                <DropDownFilter
                  value={typeFilter.value}
                  onChange={typeFilter.set}
                  options={typeFilterOptions ?? []}
                  multiple
                >
                  Type
                </DropDownFilter>
                <DropDownFilter
                  value={effectivenessFilter.value}
                  onChange={effectivenessFilter.set}
                  options={effectivenessFilterOptions ?? []}
                  multiple
                >
                  Effectiveness
                </DropDownFilter>
                <DropDownFilter
                  value={termFilter.value}
                  onChange={termFilter.set}
                  options={termFilterOptions ?? []}
                  multiple
                >
                  Term
                </DropDownFilter>
              </WCTTableFilters>
            }
            headers={[
              {
                title: 'Solution Name',
                sortProperty: 'name',
                colSpan: 2,
                ...sort,
              },
              { title: 'Cause Boxes' },
              { title: 'Files' },
              { title: 'Type' },
              { title: 'Effectiveness' },
              { title: 'Term' },
              { title: 'Cost', sortProperty: 'cost', ...sort },
              { title: '' },
            ]}
          >
            {!isLoading
              ? solutions?.model.map((solution) => (
                  <SolutionsTableRow
                    key={solution.solutionId}
                    solution={solution}
                    onEdit={() => onEditSolution(solution.solutionId)}
                    onDelete={() => onDeleteSolution(solution.solutionId)}
                    onOpen={() => onOpenSolution(solution.solutionId, true)}
                    canDelete={canDelete(solution)}
                  />
                ))
              : null}
          </WCTTable>
        )}
      </ContentContainer>
    </Column>
  );
}

export default requireAuth(SolutionsIndexPage);

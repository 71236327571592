import { api } from '@api/api';
import { Option } from '@api/types/option';

const adminPackageApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAdminPackageOptions: build.query<Option<string>[], void>({
      query: () => ({
        url: 'admin/package/options',
      }),
    }),
  }),
});

export const { useGetAdminPackageOptionsQuery } = adminPackageApi;

import { Link, Skeleton, styled, Typography } from '@mui/material';
import Logo from '@assets/images/logo.png';
import { CircleButton } from '@components/buttons';
import { ReactComponent as ChevronLeft } from '@assets/svgs/chevron-left.svg';
import { ReactComponent as HomeIcon } from '@assets/svgs/home.svg';
import { ReactComponent as GraphIcon } from '@assets/svgs/nodes.svg';
import { ReactComponent as TasksAltIcon } from '@assets/svgs/tasks-alt.svg';
import { ReactComponent as BellIcon } from '@assets/svgs/bell.svg';
import { ReactComponent as BrokenPieIcon } from '@assets/svgs/broken-pie.svg';
import { ReactComponent as FileIcon } from '@assets/svgs/file.svg';
import { ReactComponent as SolutionIcon } from '@assets/svgs/solution.svg';
import { ReactComponent as HelpRingIcon } from '@assets/svgs/help-ring.svg';
import { useMatch, useNavigate } from 'react-router-dom';
import Spacer from '@components/layout-util-components/spacer';
import MenuItemRow from '@components/menu-items/app/menu-item-row';
import CircleContainer from '@components/containers/circle-container';
import useUser from '@store/user/user-hook';
import { PageAlertDisplay } from '@components/alerts';
import { CaseResource } from '@api/types/case/case.resource';
import { InlineBoxSkeleton } from '@components/skeletons';
import useDirtyGuard from '@hooks/use-dirty-guard';
import HealthScoreSidePanel from '@pages/app/rca/tabs/components/health-score/health-score-side-panel';
import { useEffect, useRef, useState } from 'react';
import { useGetCaseTotalsQuery } from '@api/endpoints/case.api';
import { useAppDispatch, useAppSelector } from '@store/store';
import { selectHasFocusedNode } from '@store/rca-editor/selectors';
import LensesPanel from '@pages/app/rca/tabs/lenses/lenses-panel';
import ReportPanel from '@pages/app/rca/tabs/reports/report-panel';
import {
  selectIsLensesSlideOutOpen,
  selectIsRcaHelpSlideOutOpen,
} from '@store/reports/reports-selectors';
import {
  setIsRCAHelpSlideOutOpen,
  setOpenLensesSlideOut,
} from '@store/reports/reports-slice';
import RCAHelpPanel from '../lenses/rca-help-panel';
import { DEFAULT_PAGE_ALERT_MAX_TIME } from '@components/alerts/page-alert-display';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  caseDetail?: CaseResource;
}

const Container = styled('div', { target: 'rca-editor-app-bar' })({
  display: 'flex',
  alignItems: 'center',
  flex: '0 0 auto',
  height: 80,
  backgroundColor: 'white',
  padding: 15,
  zIndex: 1,
  width: '100vw',

  '.title,.subtitle': {
    fontSize: 14,
  },

  '& > .logo': {
    width: 50,
    height: 50,
  },

  '& > .vert-divider': {
    alignSelf: 'stretch',
    margin: '-15px 15px',
    backgroundColor: '#eee',
    width: 2,
    border: 'none',
  },

  '.menu-item-row': {
    maxHeight: 48,
    flex: '0 1 auto',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0.17000000178813934px',
    textAlign: 'left',
  },
});

export default function RcaEditorAppBar({ caseDetail }: Props) {
  const dispatch = useAppDispatch();
  const appBarRef = useRef<HTMLDivElement>(null);
  const [isHealthScoreOpen, setHealthScoreOpen] = useState(false);
  const isLensesOpen = useAppSelector(selectIsLensesSlideOutOpen);
  const isRcaHelpSlideOutOpen = useAppSelector(selectIsRcaHelpSlideOutOpen);

  const { confirmDirty } = useDirtyGuard();
  const { caseId } = caseDetail || {};
  const { initials } = useUser();
  const navigate = useNavigate();

  const isOverview = !!useMatch('/rca/tabs/:caseId/overview');
  const isChart = !!useMatch('/rca/tabs/:caseId/chart');
  const isTasks = !!useMatch('/rca/tabs/:caseId/tasks');
  const isEvidence = !!useMatch('/rca/tabs/:caseId/evidence');
  const isSolutions = !!useMatch('/rca/tabs/:id/solutions');

  const hasFocusedNode = useAppSelector(selectHasFocusedNode);

  const { data: totals } = useGetCaseTotalsQuery(caseId ?? -1, {
    skip: caseId == null,
  });

  useEffect(() => {
    if (hasFocusedNode) {
      setHealthScoreOpen(false);
    }
  }, [hasFocusedNode]);

  return (
    <>
      <Container ref={appBarRef}>
        <PageAlertDisplay maxTime={DEFAULT_PAGE_ALERT_MAX_TIME} absolute />
        <Link href="/">
          <img className="logo" src={Logo} alt="logo" width="52" />
        </Link>
        <div className="vert-divider" />
        <Gap size={4} />
        <CircleButton onClick={confirmDirty(() => navigate(-1))}>
          <ChevronLeft />
        </CircleButton>
        <Gap size={20} />
        <div>
          {caseDetail ? (
            <>
              <Typography className="title">
                TYPE - {caseDetail.caseAnalysisTypeName}
              </Typography>
              <Typography className="subtitle">
                <b>
                  {caseDetail.name} | #{caseDetail.caseId}
                </b>
              </Typography>
            </>
          ) : (
            <>
              <Typography>
                <Skeleton />
              </Typography>
              <Typography>
                <Skeleton />
              </Typography>
            </>
          )}
        </div>
        <Spacer />
        {caseDetail ? (
          <MenuItemRow
            icon={<HomeIcon />}
            onClick={confirmDirty(() =>
              navigate(`/rca/tabs/${caseDetail!.caseId}/overview`)
            )}
            outline
            isSelected={isOverview}
          >
            Overview
          </MenuItemRow>
        ) : (
          <InlineBoxSkeleton width={80} />
        )}
        <Gap size={10} />
        {caseDetail ? (
          <MenuItemRow
            icon={<GraphIcon />}
            onClick={confirmDirty(() =>
              navigate(`/rca/tabs/${caseDetail!.caseId}/chart`)
            )}
            outline
            isSelected={isChart}
          >
            Visual Analysis
          </MenuItemRow>
        ) : (
          <InlineBoxSkeleton width={80} />
        )}
        <Gap size={10} />
        {caseDetail ? (
          <MenuItemRow
            icon={<TasksAltIcon />}
            indicatorContent={totals?.tasks}
            onClick={confirmDirty(() =>
              navigate(`/rca/tabs/${caseDetail!.caseId}/tasks`)
            )}
            outline
            isSelected={isTasks}
          >
            Tasks
          </MenuItemRow>
        ) : (
          <InlineBoxSkeleton width={80} />
        )}
        <Gap size={10} />
        {caseDetail ? (
          <MenuItemRow
            icon={<FileIcon />}
            indicatorContent={totals?.evidence}
            onClick={confirmDirty(() =>
              navigate(`/rca/tabs/${caseDetail!.caseId}/evidence`)
            )}
            outline
            isSelected={isEvidence}
          >
            Evidence
          </MenuItemRow>
        ) : (
          <InlineBoxSkeleton width={80} />
        )}
        <Gap size={10} />
        {caseDetail ? (
          <MenuItemRow
            icon={<SolutionIcon />}
            onClick={confirmDirty(() =>
              navigate(`/rca/tabs/${caseDetail!.caseId}/solutions`)
            )}
            outline
            isSelected={isSolutions}
            indicatorContent={totals?.solutions}
          >
            Solutions
          </MenuItemRow>
        ) : (
          <InlineBoxSkeleton width={80} />
        )}
        <Spacer />
        <div className="vert-divider" />
        {totals?.healthScore != null ? (
          <>
            <MenuItemRow onClick={() => setHealthScoreOpen(true)} outline>
              Health Score {totals!.healthScore.toFixed(0)}%
            </MenuItemRow>
            <CircleButton onClick={() => dispatch(setOpenLensesSlideOut(true))}>
              <BrokenPieIcon />
            </CircleButton>
          </>
        ) : (
          <>
            <InlineBoxSkeleton width={50} />
            <InlineBoxSkeleton width={32} />
          </>
        )}

        <div className="vert-divider" />
        {/* TODO(Nick): Get notification count from somewhere.. */}
        {caseDetail ? (
          <>
            <CircleButton onClick={() => {}} badge={8}>
              <BellIcon />
            </CircleButton>
            <Gap size={15} />
            <CircleButton
              onClick={() => dispatch(setIsRCAHelpSlideOutOpen(true))}
            >
              <HelpRingIcon />
            </CircleButton>
          </>
        ) : (
          <>
            <InlineBoxSkeleton width={32} />
            <Gap size={15} />
            <InlineBoxSkeleton width={32} />
          </>
        )}
        <div className="vert-divider" />
        <CircleContainer size={45}>{initials}</CircleContainer>
      </Container>
      <RCAHelpPanel
        isOpen={isRcaHelpSlideOutOpen}
        onClose={() => dispatch(setIsRCAHelpSlideOutOpen(false))}
      />
      {caseId != null && (
        <>
          <HealthScoreSidePanel
            isOpen={isHealthScoreOpen}
            onClose={() => setHealthScoreOpen(false)}
            topMargin={appBarRef.current?.clientHeight ?? 0}
          />
          <LensesPanel
            isOpen={isLensesOpen}
            onClose={() => dispatch(setOpenLensesSlideOut(false))}
          />
          <ReportPanel />
        </>
      )}
    </>
  );
}

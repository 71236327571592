import { Drawer, DrawerProps, styled } from '@mui/material';
import useLensesPanel, {
  LensesPanelViewState,
} from '@pages/app/rca/tabs/lenses/lenses-panel-hook';
import OptionSelectView from '@pages/app/rca/tabs/lenses/views/option-select-view';
import ReportFormView from '@pages/app/rca/tabs/lenses/views/report-form-view';
import AllRecentLensesView from '@pages/app/rca/tabs/lenses/views/all-recent-lenses-view';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const StyledDrawer = styled(Drawer, {
  target: 'health-score-side-panel',
})<DrawerProps>(({ theme: { palette } }) => ({
  '.MuiPaper-root': {
    minWidth: 414,
    boxShadow: '0px 0px 15px 0px #00000040',
    background: palette.common.grey14,
    padding: 20,
    overflowY: 'auto',
  },
}));

export default function LensesPanel({ isOpen, onClose }: Props) {
  const state = useLensesPanel();
  const { viewState } = state;

  return (
    <StyledDrawer open={isOpen} onClose={onClose} anchor="right" hideBackdrop>
      {viewState === LensesPanelViewState.optionSelect ? (
        <OptionSelectView onClose={onClose} state={state} />
      ) : viewState === LensesPanelViewState.reportForm ? (
        <ReportFormView onClose={onClose} state={state} />
      ) : viewState === LensesPanelViewState.allRecentLenses ? (
        <AllRecentLensesView onClose={onClose} state={state} />
      ) : null}
    </StyledDrawer>
  );
}

import { LinearProgress, styled } from '@mui/material';
import Row from '@components/layout-util-components/row';
import Spacer from '@components/layout-util-components/spacer';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  score: number;
}

const Container = styled('div', { target: 'health-score-bar' })(
  ({ theme: { palette } }) => ({
    border: '1px solid #E0E0E0',
    borderRadius: 5,
    background: palette.common.grey40,
    padding: '10px 15px',

    '.info': {
      fontFamily: 'Roboto',
      fontSize: '11px',
      fontWeight: '400',
      lineHeight: '12px',
      letterSpacing: '0.17000000178813934px',

      '&.score': {
        fontSize: '14px',
      },
    },

    '.MuiLinearProgress-root': {
      height: 9,
      borderRadius: 10,

      '>span': {
        borderRadius: 10,
      },
    },
  })
);

export default function HealthScoreBar({ score }: Props) {
  return (
    <Container>
      <Row>
        <span className="info">HEALTH SCORE</span>
        <Spacer />
        <span className="info score">{`${score.toFixed(0)}%`}</span>
      </Row>
      <Gap size={10} />
      <LinearProgress variant="determinate" value={score} />
    </Container>
  );
}

import { Box, Link, styled } from '@mui/material';
import { Outlet } from 'react-router-dom';
import AppLogo from '@assets/svgs/app-logo.svg';

import { useTranslation } from 'react-i18next';

const StyledFooterLinks = styled('div')({
  display: 'flex',
  marginTop: 'auto',
  justifyContent: 'space-between',
  a: {
    color: 'black',
    textDecorationColor: 'black',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '166%' /* 19.92px */,
    letterSpacing: '0.4px',
    textDecorationLine: 'underline',
  },
});

const StyledPageContainer = styled(Box)({
  height: '100vh',
  width: '100vw',
  display: 'flex',
});

const StyledLeftSection = styled(Box)(({ theme: { spacing } }) => ({
  padding: `${spacing(8)} 10vw`,
  flexBasis: '50%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}));

const StyledRightSection = styled(Box)(({ theme: { palette } }) => ({
  flexBasis: '50%',
  background: palette.common.grey30,
}));

const StyledLogo = styled('img')(({ theme: { spacing } }) => ({
  marginBottom: spacing(6),
  width: '80px',
  height: '80px',
}));

export default function AuthLayout() {
  const { t } = useTranslation('auth', { keyPrefix: 'common' });

  return (
    <StyledPageContainer>
      <StyledLeftSection>
        <StyledLogo src={AppLogo} alt="logo" />
        <Outlet />
        <StyledFooterLinks>
          <Link
            variant="caption"
            href="https://wct.withcandour.dev/terms-of-use"
            target="_blank"
          >
            {t('footerLinks.terms')}
          </Link>
          <Link
            variant="caption"
            href="https://wct.withcandour.dev/privacy-notice"
            target="_blank"
          >
            {t('footerLinks.privacy')}
          </Link>
          <Link
            variant="caption"
            href="https://wct.withcandour.dev/data-protection"
            target="_blank"
          >
            {t('footerLinks.data')}
          </Link>
        </StyledFooterLinks>
      </StyledLeftSection>
      <StyledRightSection />
    </StyledPageContainer>
  );
}

import { styled, Table } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';
import WCTTableHeaders, { HeaderItem } from '@components/table/table-headers';
import { WCTTablePagination } from '@components/table/index';
import WCTTableBody from '@components/table/table-body';
import { TableBodySkeleton } from '@components/skeletons';
import { PaginationState } from '@components/table/hooks/use-pagination-hook';

interface Props {
  count: number;
  filters?: ReactNode;
  headers?: Array<HeaderItem>;
  pagination?: PaginationState;
  isLoading?: boolean;
}

const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
});

export default function WCTTable({
  count,
  children,
  filters,
  headers,
  pagination,
  isLoading,
}: PropsWithChildren<Props>) {
  return (
    <StyledContainer>
      {filters ? filters : null}
      <Table>
        {headers ? <WCTTableHeaders items={headers} /> : null}
        {isLoading ? (
          <TableBodySkeleton cols={headers?.length} />
        ) : (
          <WCTTableBody>{children}</WCTTableBody>
        )}
        {pagination ? (
          <WCTTablePagination count={count} {...pagination} />
        ) : null}
      </Table>
    </StyledContainer>
  );
}

import { useEffect, useMemo, useRef, useState } from 'react';
import {
  CaseImpactStatRow,
  CaseImpactStats,
  ImpactInfoModel,
} from '@pages/app/rca/create/steps/impact-step-models';
import { useAppDispatch, useAppSelector } from '@store/store';
import {
  useCreateCaseImpactMutation,
  useDeleteCaseImpactMutation,
  useGetCaseImpactsQuery,
  useUpdateCaseImpactMutation,
} from '@api/endpoints/case.api';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { numberFromString } from '@util/string-util';
import {
  CaseImpactType,
  CaseImpactTypeMetricsResource,
} from '@api/types/case-impact-type-metric/case-impact-type-metric.resource';
import { ApiError } from '@api/types/api-error';
import { CreateCaseImpactRequest } from '@api/types/case/case-impact/create-case-impact.request';
import { UpdateCaseImpactRequest } from '@api/types/case/case-impact/update-case-impact.request';
import { useGetCaseImpactTypesQuery } from '@api/endpoints/case-impact-type.api';
import caseImpactTypeMetricApi from '@api/endpoints/case-impact-type-metric.api';
import { CaseImpactUtil } from '@util/case-impact-util';
import { useGetCaseImpactSeverityOptionsQuery } from '@api/endpoints/case-impact-severity.api';
import { useIsPath } from '@util/path-util';
import { useGetCaseDetailQuery } from '@api/endpoints/case.api';
import useSystemText from '@hooks/use-system-text';
import { selectCurrentRcaCurrency } from '@store/rca-editor/selectors';

export enum CreateDetailViewState {
  view,
  edit,
  add,
  empty,
}

export default function useImpactStep(caseId: number) {
  const isEdit = useIsPath('/rca/edit', { startsWith: true });
  const dispatch = useAppDispatch();
  const { systemText } = useSystemText();
  const currency = useAppSelector(selectCurrentRcaCurrency);

  const [statData, setStatData] = useState<CaseImpactStats>();

  const { data: severityOptions, isLoading: loadingSeverityOptions } =
    useGetCaseImpactSeverityOptionsQuery(caseId);

  const { data: impactTypeOptions, isLoading: loadingImpactTypeOptions } =
    useGetCaseImpactTypesQuery(caseId);

  const {
    data: impactData,
    isLoading: loadingImpactData,
    status: impactDataStatus,
  } = useGetCaseImpactsQuery(caseId);

  const { data: caseData } = useGetCaseDetailQuery(caseId, {
    skip: caseId == null,
  });

  const [createImpact, { isLoading: isCreatingImpact }] =
    useCreateCaseImpactMutation();
  const [updateImpact, { isLoading: isUpdatingImpact }] =
    useUpdateCaseImpactMutation();
  const [deleteImpact, { isLoading: isDeletingImpact }] =
    useDeleteCaseImpactMutation();

  const [viewState, setViewState] = useState(CreateDetailViewState.empty);
  const [impactModels, setImpactModels] = useState<Array<ImpactInfoModel>>([]);

  const [addOrEditIndex, setAddOrEditIndex] = useState(-1);

  const isLoading =
    loadingImpactTypeOptions || loadingImpactData || loadingSeverityOptions;
  const isBusy = isDeletingImpact || isUpdatingImpact || isCreatingImpact;
  const canSubmit = !isLoading && !isBusy;

  const hasLoaded = useRef(false);

  const actualImpacts = useMemo(() => {
    return impactModels.filter((x) => x.actualValue === CaseImpactType.actual);
  }, [impactModels]);

  const potentialImpacts = useMemo(() => {
    return impactModels.filter(
      (x) => x.actualValue === CaseImpactType.potential
    );
  }, [impactModels]);

  useEffect(() => {
    if (hasLoaded.current) {
      return;
    }

    if (impactDataStatus === QueryStatus.fulfilled && impactData != null) {
      setImpactModels(
        impactData.map((data) => ({
          impactId: data.caseImpactId,
          description: data.description,
          metricId: data.caseImpactTypeId,
          track: data.tracked,
          actualValue: data.actualValue,
          severityId: data.caseImpactSeverityId,
          severityName: data.caseImpactSeverityName,
          impactValue:
            data.impactValue != null ? data.impactValue.toString() : '',
        }))
      );

      if (impactData.length > 0) {
        setViewState(CreateDetailViewState.view);
      }
      hasLoaded.current = true;
    }
  }, [impactData, impactDataStatus]);

  const pushNew = () => {
    const newIndex = impactModels.length;

    setImpactModels((v) => [...v, {} as ImpactInfoModel]);
    setAddOrEditIndex(newIndex);
    setViewState(CreateDetailViewState.add);
  };

  const startEditing = (index: number) => {
    setAddOrEditIndex(index);
    setViewState(CreateDetailViewState.edit);
  };

  const remove = (indexToRemove: number) => {
    const impactModel = impactModels[indexToRemove];
    if (impactModel.impactId != null) {
      deleteImpact({
        caseId,
        caseImpactId: impactModel.impactId,
      });
    }

    const newModels = impactModels.filter(
      (_, index) => index !== indexToRemove
    );

    setImpactModels(newModels);
    setAddOrEditIndex(-1);
    setViewState(CreateDetailViewState.view);
  };

  const submit = async (
    updatedModel: ImpactInfoModel,
    addAnotherOnSubmit?: boolean
  ): Promise<
    | boolean
    | ApiError<
        (CreateCaseImpactRequest | UpdateCaseImpactRequest) & { caseId: number }
      >
  > => {
    const onComplete = (newId?: number) => {
      const newModels = impactModels.map((model, index) => {
        if (index !== addOrEditIndex) {
          return model;
        }

        return { ...updatedModel, impactId: newId || updatedModel.impactId };
      });

      setImpactModels(newModels);

      if (addAnotherOnSubmit) {
        pushNew();
      } else {
        setViewState(CreateDetailViewState.view);
        setAddOrEditIndex(-1);
      }
    };

    if (viewState === CreateDetailViewState.add) {
      return createImpact({
        caseId,
        description: updatedModel.description,
        caseImpactSeverityId: updatedModel.severityId,
        tracked: updatedModel.track,
        caseImpactTypeId: updatedModel.metricId,
        actualValue: updatedModel.actualValue,
        impactValue: numberFromString(updatedModel.impactValue)!,
      })
        .unwrap()
        .then((resource) => {
          onComplete(resource?.caseImpactId);
          return true;
        })
        .catch(
          (error: ApiError<CreateCaseImpactRequest & { caseId: number }>) =>
            error
        );
    } else {
      return updateImpact({
        caseId,
        caseImpactId: updatedModel.impactId!,
        caseImpactSeverityId: updatedModel.severityId,
        description: updatedModel.description,
        tracked: updatedModel.track,
        caseImpactTypeId: updatedModel.metricId,
        actualValue: updatedModel.actualValue,
        impactValue: numberFromString(updatedModel.impactValue)!,
      })
        .unwrap()
        .then((_) => {
          onComplete();
          return true;
        })
        .catch(
          (error: ApiError<UpdateCaseImpactRequest & { caseId: number }>) =>
            error
        );
    }
  };

  const cancel = () => {
    if (viewState === CreateDetailViewState.add) {
      const newModels = impactModels.slice(0, impactModels.length - 1);
      setImpactModels(newModels);
    }

    setViewState(CreateDetailViewState.view);
    setAddOrEditIndex(-1);
  };

  useEffect(() => {
    if (impactTypeOptions == null) {
      return;
    }

    const timeout = setTimeout(async () => {
      const metricInfos: Record<number, CaseImpactTypeMetricsResource> = {};
      for (const inputType of impactTypeOptions) {
        metricInfos[inputType.caseImpactTypeMetricId] = await dispatch(
          caseImpactTypeMetricApi.endpoints.getMetricDetail.initiate(
            inputType.caseImpactTypeMetricId
          )
        ).unwrap();
      }

      const rows: Array<CaseImpactStatRow> = [];
      for (const impactModel of impactModels) {
        const impactType = impactTypeOptions.find(
          (x) => x.caseImpactTypeId === impactModel.metricId
        );
        if (impactType == null) {
          continue;
        }

        const metricInfo = metricInfos[impactType.caseImpactTypeMetricId];
        if (metricInfo == null) {
          continue;
        }

        const actualImpactArr = impactModels.filter(
          (x) => x.metricId === impactType.caseImpactTypeId && x.actualValue
        );
        const potentialImpactArr = impactModels.filter(
          (x) => x.metricId === impactType.caseImpactTypeId && !x.actualValue
        );

        const stats = CaseImpactUtil.getImpactStatsRow(
          actualImpactArr,
          potentialImpactArr,
          metricInfo,
          currency
        );
        if (!rows.find((x) => x.name === stats.name)) {
          rows.push(stats);
        }
      }
      setStatData({
        rows,
      });
    }, 300);

    return () => clearTimeout(timeout);
  }, [dispatch, impactModels, impactTypeOptions]);

  return {
    viewState,
    impactModels,
    currentModel: addOrEditIndex !== -1 ? impactModels[addOrEditIndex] : null,
    addOrEditIndex,
    pushNew,
    startEditing,
    remove,
    submit,
    cancel,
    isLoading,
    isBusy,
    canSubmit,
    impactTypeOptions,
    actualImpacts,
    potentialImpacts,
    statData,
    isEdit,
    severityOptions,
    caseData,
    systemText,
  };
}

export type ImpactStepState = ReturnType<typeof useImpactStep>;

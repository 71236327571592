import { ReactNode } from 'react';
import {
  styled,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import { SortDirection } from '@api/types/sort-direction';
import { SortableHeader } from '@components/table/hooks/use-sortable-header-hook';
import Row from '@components/layout-util-components/row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesUpDown } from '@fortawesome/pro-light-svg-icons';

export type HeaderItem = {
  title: ReactNode;
  sortProperty?: string;
  colSpan?: number;
} & Partial<SortableHeader>;

interface Props {
  items: Array<HeaderItem>;
}

const StyledHead = styled(TableHead)({
  margin: 0,
  padding: 0,
  '&>tr': {
    '&>th': {
      fontSize: 14,
      padding: '16px 12px',
    },
  },
});

export default function WCTTableHeaders({ items }: Props) {
  return (
    <StyledHead>
      <TableRow>
        {items.map(
          ({
            sortProperty,
            property,
            title,
            direction,
            onToggle,
            allowNoSort,
            colSpan,
          }) => (
            <TableCell
              key={uuid()}
              sx={{ borderBottom: 'none' }}
              colSpan={colSpan}
            >
              {sortProperty ? (
                <TableSortLabel
                  direction={
                    sortProperty === property
                      ? direction === SortDirection.asc
                        ? 'asc'
                        : direction === SortDirection.desc
                        ? 'desc'
                        : undefined
                      : undefined
                  }
                  onClick={() => onToggle!(sortProperty)}
                  active={direction != null && sortProperty === property}
                >
                  <Row sx={{ gap: 1 }}>
                    <span>{title}</span>
                    {!allowNoSort && sortProperty !== property ? (
                      <FontAwesomeIcon icon={faAnglesUpDown} />
                    ) : null}
                  </Row>
                </TableSortLabel>
              ) : (
                title
              )}
            </TableCell>
          )
        )}
      </TableRow>
    </StyledHead>
  );
}

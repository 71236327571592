import useField from '@hooks/use-field-hook';
import { required } from '@util/validators';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { useEffect, useMemo } from 'react';
import { LensesPanelState } from '@pages/app/rca/tabs/lenses/lenses-panel-hook';
import {
  useGetReportRankByOptionsQuery,
  useGetReportSubtypeOptionsQuery,
} from '@api/endpoints/report-type.api';
import { useGetCaseImpactTypesQuery } from '@api/endpoints/case-impact-type.api';
import useReportTypeOptions, {
  ReportTypeListItem,
} from '@pages/app/rca/tabs/lenses/report-type-options-hook';
import { ReportType } from '@api/types/report-type/report-type.option';
import { usePageAlertVariants } from '@components/alerts';
import { ApiError } from '@api/types/api-error';
import { useAppDispatch, useAppSelector } from '@store/store';
import { showReportByRunRequest } from '@store/reports/reports-actions';
import { RunReportRequest } from '@api/types/case/case-report/run-report.request';
import { selectRunReportModel } from '@store/reports/reports-selectors';
import {
  useGetImpactReportGroupOptionsQuery,
  useGetImpactReportTypeOptionsQuery,
} from '@api/endpoints/impact-report.api';
import { selectCurrentRcaCaseId } from '@store/rca-editor/selectors';

export default function useReportForm({
  selectedReportType,
}: LensesPanelState) {
  const dispatch = useAppDispatch();
  const { showErrorMessage } = usePageAlertVariants();
  const caseId = useAppSelector(selectCurrentRcaCaseId);
  const currentRunReport = useAppSelector(selectRunReportModel);

  const reportType = selectedReportType!;
  const shouldDisplayImpactOptions =
    reportType.reportType === ReportType.impact;

  const { data: trackedImpactOptions, isLoading: loadingTrackedImpactOptions } =
    useGetCaseImpactTypesQuery(caseId);

  const {
    data: impactReportTypeOptions,
    isLoading: loadingImpactReportTypeOptions,
  } = useGetImpactReportTypeOptionsQuery();

  const {
    data: impactReportGroupOptions,
    isLoading: loadingImpactReportGroupOptions,
  } = useGetImpactReportGroupOptionsQuery();

  const reportSubType = useField<number>(
    [required()],
    currentRunReport?.reportSubTypeId
  );
  const rankBy = useField<number>(
    [required()],
    currentRunReport?.reportRankById
  );
  const trackedImpact = useField<number>(
    [
      required({
        when: () => shouldDisplayImpactOptions,
      }),
    ],
    currentRunReport?.caseImpactTypeId
  );
  const impactType = useField<string>(
    [
      required({
        when: () => shouldDisplayImpactOptions,
      }),
    ],
    currentRunReport?.impactType
  );
  const impactGroup = useField<string>(
    [required({ when: () => shouldDisplayImpactOptions })],
    currentRunReport?.impactGroup
  );
  const selectedIds = useField<Array<number>>(
    [],
    useMemo(() => currentRunReport?.ids ?? [], [currentRunReport?.ids])
  );

  const selectableOptions = useReportTypeOptions(
    reportType.reportTypeId,
    reportSubType.value
  );

  const {
    data: reportSubTypeOptions,
    isFetching: loadingReportSubTypes,
    isSuccess: hasLoadedReportSubTypes,
  } = useGetReportSubtypeOptionsQuery(reportType.reportTypeId);

  const {
    data: rankByOptions,
    isFetching: loadingRankByOptions,
    isSuccess: hasLoadedRankByOptions,
  } = useGetReportRankByOptionsQuery(reportSubType.value ?? -1, {
    skip: reportSubType.value == null,
  });

  const { isValid, validateAll } = useFieldsWatcher([
    reportSubType,
    rankBy,
    trackedImpact,
    impactType,
    impactGroup,
  ]);

  const isLoading =
    loadingTrackedImpactOptions ||
    loadingImpactReportGroupOptions ||
    loadingImpactReportTypeOptions;
  const canSubmit =
    isValid && !isLoading && !loadingReportSubTypes && !loadingRankByOptions;

  const resetRankBy = rankBy.reset;
  const resetImpactType = impactType.reset;
  const resetDisplayLevel = impactType.reset;
  const resetSelectedIds = selectedIds.reset;
  useEffect(() => {
    if (reportSubType.value != null) {
      resetRankBy();
      resetImpactType();
      resetDisplayLevel();
      resetSelectedIds();
    }
  }, [
    reportSubType.value,
    resetDisplayLevel,
    resetImpactType,
    resetRankBy,
    resetSelectedIds,
  ]);

  const setRankBy = rankBy.set;
  useEffect(() => {
    if (rankByOptions != null && rankByOptions.length === 1) {
      setRankBy(rankByOptions[0].reportRankById);
    }
  }, [rankByOptions, setRankBy]);

  const toggleSelect = (option: ReportTypeListItem) => {
    if (selectedIds.value.includes(option.id)) {
      selectedIds.set(selectedIds.value.filter((id) => id !== option.id));
    } else {
      selectedIds.set([...selectedIds.value, option.id]);
    }
  };

  const isSelected = (option: ReportTypeListItem) => {
    return selectedIds.value.includes(option.id);
  };

  const submit = async () => {
    if (!validateAll()) {
      return false;
    }

    const selectedSubType = reportSubTypeOptions!.find(
      (x) => x.reportSubTypeId === reportSubType.value
    )!;
    const selectedRankBy = rankByOptions!.find(
      (x) => x.reportRankById === rankBy.value
    )!;

    try {
      dispatch(
        showReportByRunRequest({
          reportType: reportType.reportType,
          reportTypeName: reportType.name,
          reportTypeId: reportType.reportTypeId,
          reportSubTypeId: selectedSubType.reportSubTypeId,
          reportSubTypeName: selectedSubType.name,
          reportSubType: selectedSubType.reportSubType,
          reportRankById: selectedRankBy.reportRankById,
          reportRankByName: selectedRankBy.name,
          reportRankBy: selectedRankBy.reportRankBy,
          caseImpactTypeId: trackedImpact.value,
          impactGroup: impactGroup.value,
          ids: selectedIds.value,
          impactType: impactType.value,
        })
      );
      return true;
    } catch (e) {
      const { message, errors } = e as ApiError<RunReportRequest>;

      reportSubType.setError(errors?.reportSubTypeId);
      rankBy.setError(errors?.reportRankById);

      if (shouldDisplayImpactOptions) {
        showErrorMessage(errors?.reportTypeId ?? errors?.ids ?? message);
      } else {
        showErrorMessage(
          errors?.reportTypeId ??
            errors?.reportRankById ??
            errors?.ids ??
            message
        );
      }

      return false;
    }
  };

  return {
    reportType,
    submit,
    canSubmit,
    reportSubTypeOptions,
    reportSubType,
    loadingReportSubTypes,
    hasLoadedReportSubTypes,
    isLoading,
    rankBy,
    impactType,
    trackedImpactOptions,
    trackedImpact,
    impactGroup,
    impactReportTypeOptions,
    impactReportGroupOptions,
    selectableOptions,
    rankByOptions,
    loadingRankByOptions,
    hasLoadedRankByOptions,
    toggleSelect,
    isSelected,
    shouldDisplayImpactOptions,
  };
}

import { ImpactsPanelState } from '@pages/app/rca/tabs/components/editor-panels/impacts-panel-hook';
import { CaseImpactResource } from '@api/types/case/case-impact/case-impact.resource';
import { styled, Typography } from '@mui/material';
import Column from '@components/layout-util-components/column';
import { useGetMetricOptionsQuery } from '@api/endpoints/case-impact-type-metric.api';
import MetricInputField from '@components/input/metric-input-field';
import { InputSkeleton } from '@components/skeletons';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  impact: CaseImpactResource;
  state: ImpactsPanelState;
  value: number | null;
}

const Container = styled('div', { target: 'impact-value-option' })(
  ({ theme: { palette } }) => ({
    background: palette.common.grey15,
    padding: 15,
    marginBottom: 15,
    border: '1px solid #DDDDDD',
    borderRadius: 4,

    '.type-name': {
      color: '#646464',
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing: '0px',
      textAlign: 'left',
    },
  })
);

export default function ImpactValueOptions({ impact, value, state }: Props) {
  const { updateValue } = state;
  const { data: options, isLoading } = useGetMetricOptionsQuery(
    impact.caseImpactTypeMetricId
  );

  const onChange = (val?: number) => {
    updateValue(impact.caseImpactId, val);
  };

  return (
    <Container>
      <Column>
        <Typography className="type-name">
          {impact.caseImpactTypeName}
        </Typography>
        <Gap size={4} />
        <Typography variant="body2">{impact.description}</Typography>
        <Gap size={4} />
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <MetricInputField
            name={`metric-${impact.caseImpactTypeName}`}
            type={impact.caseImpactTypeMetric}
            options={options!}
            value={value != null ? value : undefined}
            onChange={onChange}
            optionsAsRadioButtons
          />
        )}
      </Column>
    </Container>
  );
}

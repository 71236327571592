const base: string[] = [
  '#F7B637',
  '#C640A9',
  '#26AEB6',
  '#0097E1',
  '#DF3C58',
  '#4CB648',
];

const lightened: string[] = [
  '#FFF2D9',
  '#F6DDF1',
  '#CDF1F3',
  '#C7E1FF',
  '#EFC8CF',
  '#E3F3E2',
];

export const getLightenedColorForId = (id: number) => {
  return lightened[id % lightened.length];
};

export const getColorForId = (id: number) => {
  return base[id % base.length];
};

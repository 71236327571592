import {
  Background,
  BackgroundVariant,
  ReactFlow,
  ReactFlowProps,
} from 'reactflow';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { RcaDefaultNode } from '@pages/app/rca/tabs/components/node-types/rca-default-node';
import DragHolderNode from '@pages/app/rca/tabs/components/node-types/drag-holder-node';
import RcaMetaNode from '@pages/app/rca/tabs/components/node-types/meta-node';
import RcaDefaultEdge from '@pages/app/rca/tabs/components/edge-types/rca-default-edge';
import 'reactflow/dist/base.css';
import './style.scss';
import { RcaUtil } from '@util/rca-util';

interface Props extends Partial<ReactFlowProps> {}
export default function RenderGraph({
  children,
  ...reactFlowProps
}: PropsWithChildren<Props>) {
  const nodeTypes = useMemo(
    () => ({
      default: RcaDefaultNode,
      'drag-holder': DragHolderNode,
      'end-state': RcaDefaultNode,
      connection: RcaDefaultNode,
      and: RcaMetaNode,
      andOr: RcaMetaNode,
    }),
    []
  );

  const edgeTypes = useMemo(
    () => ({
      default: RcaDefaultEdge,
    }),
    []
  );

  useEffect(() => {
    return () => {
      RcaUtil.setReactFlowInstance(undefined);
    };
  }, []);

  return (
    <ReactFlow
      {...reactFlowProps}
      nodeTypes={nodeTypes as any}
      edgeTypes={edgeTypes as any}
    >
      <Background
        variant={BackgroundVariant.Dots}
        gap={24}
        size={1}
        style={{ background: '#ECEDF0' }}
      />

      {children}
    </ReactFlow>
  );
}

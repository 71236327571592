import { useAuthTr } from '@i18n/use-auth-tr';
import { Grid, Link, Typography } from '@mui/material';
import { Trans } from 'react-i18next';

export default function ContactSupportLink() {
  const { t } = useAuthTr('common');

  return (
    <Grid item alignSelf="center">
      <Typography variant="body1">
        <Trans t={t} i18nKey="supportButtonLabel">
          {/*TODO(Nick): Implement contact support*/}
          Need Help? <Link href="#">Contact Support</Link>
        </Trans>
      </Typography>
    </Grid>
  );
}

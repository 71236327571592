import React, { PropsWithChildren, Suspense } from 'react';
import { SnackbarProvider } from 'notistack';

import './i18n';
import { PageAlertProvider } from '@components/alerts/hooks/page-alert-hook';
import UiPopupProvider from '@components/ui-popup/ui-popup-provider';
import { FullScreenLoadingIndicator } from '@components/loading-indicator';
import { Provider } from 'react-redux';
import { store } from '@store/store';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from '@styles/theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Outlet } from 'react-router-dom';
import { i18nDayJs } from '@i18n/index';

function App({ children }: PropsWithChildren<{}>) {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          dateLibInstance={i18nDayJs.utc}
        >
          <CssBaseline />
          <SnackbarProvider maxSnack={3}>
            <PageAlertProvider>
              <UiPopupProvider>{children || <Outlet />}</UiPopupProvider>
            </PageAlertProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default function WrappedApp(props: any) {
  return (
    <Suspense fallback={<FullScreenLoadingIndicator />}>
      <App {...props} />
    </Suspense>
  );
}

import { styled } from '@mui/material';
import { getColorForId } from '@util/colour-identifiers';

interface Props {
  id: number;
}

const Container = styled('div')<{ color: string }>(({ color }) => ({
  display: 'block',
  width: 12,
  height: 12,
  borderRadius: 6,
  backgroundColor: color,
}));

export default function IdColorIndicator({ id }: Props) {
  return <Container color={getColorForId(id)} />;
}

import { PropsWithChildren, ReactNode } from 'react';
import { css, styled } from '@mui/material';

interface DivLayoutProps {
  actionsRow: ReactNode;
}

interface FormLayoutProps {
  onSubmit: () => void;
  actionsRow: ReactNode;
}

const StepContainer = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  height: 'calc(100vh - 80px)',
  width: '100vw',
  background: '#F7F7F7',

  '& .content': {
    flex: '1 1 auto',
    overflow: 'auto',
    padding: '60px 164px 100px',
  },

  '& .actions': {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    flex: '0 0 auto',
    display: 'flex',
    padding: '20px 164px',
    alignItems: 'center',
    height: 82,
    backgroundColor: 'white',
  },
});

const DivLayoutContainer = styled('div', { target: 'step-form-container' })(
  StepContainer
);

const FormLayoutContainer = styled('form', { target: 'step-form-container' })(
  StepContainer
);

export function StepFormLayout({
  children,
  actionsRow,
  onSubmit,
}: PropsWithChildren<FormLayoutProps>) {
  return (
    <FormLayoutContainer
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <div className="content">{children}</div>
      <div className="actions">{actionsRow}</div>
    </FormLayoutContainer>
  );
}

export function StepLayout({
  children,
  actionsRow,
}: PropsWithChildren<DivLayoutProps>) {
  return (
    <DivLayoutContainer>
      <div className="content">{children}</div>
      <div className="actions">{actionsRow}</div>
    </DivLayoutContainer>
  );
}

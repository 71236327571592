import { Box, Typography } from '@mui/material';
import Column from '@components/layout-util-components/column';
import { isNullOrEmpty } from '@util/string-util';
import { ReactNode } from 'react';
import Spacer from '@components/layout-util-components/spacer';
import AssociatedCauseBoxBadge from '@components/badges/associated-cause-box-badge';
import FlatBadge from '@components/badges/flat-badge';
import { ChainItemSolutionResource } from '@api/types/chain/chain-item-solution/chain-item-solution.resource';
import Row from '@components/layout-util-components/row';
import { currencyFormatter } from '@i18n/formatters';
import { BorderedContainer } from '@components/containers/bordered-container';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  solution: ChainItemSolutionResource;
  trailing?: ReactNode;
  currency?: string;
}

export default function CaseSolutionItem({
  solution: {
    solutionType,
    name,
    description,
    chainItemCount,
    cost,
    solutionTerm,
    efficacy,
    themes,
    additionalCost,
  },
  trailing,
  currency,
}: Props) {
  return (
    <BorderedContainer>
      <Column>
        <Row sx={{ gap: 0.5 }}>
          {themes?.map(({ id, label }) => (
            <FlatBadge small key={id}>
              {label}
            </FlatBadge>
          ))}
        </Row>
        <Gap size={8} />
        <Typography variant="body2">{name}</Typography>
        {!isNullOrEmpty(description) ? (
          <>
            <Gap size={8} />
            <Typography variant="caption">{description}</Typography>
          </>
        ) : null}

        {!!cost && (
          <Box alignSelf="flex-start">
            <Gap size={8} />
            <FlatBadge className="cost">
              <span>
                Cost:{' '}
                <b>
                  {currencyFormatter(cost, {
                    decimalPlaces: 2,
                    currency: currency || '',
                  })}
                </b>{' '}
                {!!additionalCost
                  ? ` (+${currencyFormatter(additionalCost, {
                      decimalPlaces: 2,
                      currency: currency || '',
                    })})`
                  : null}
              </span>
            </FlatBadge>
          </Box>
        )}
        <Gap size={12} />
        <Row sx={{ gap: 0.5 }}>
          {!!additionalCost && (
            <FlatBadge small>
              +
              {currencyFormatter(additionalCost, {
                decimalPlaces: 2,
                currency: currency || '',
              })}
            </FlatBadge>
          )}
          <FlatBadge small>{solutionTerm} Term</FlatBadge>
          <FlatBadge small>{efficacy} Effectiveness</FlatBadge>
          <FlatBadge small>{solutionType}</FlatBadge>
        </Row>
        {chainItemCount > 0 ? (
          <>
            <Gap size={8} />
            <AssociatedCauseBoxBadge count={chainItemCount} />
          </>
        ) : null}
      </Column>
      {trailing ? (
        <>
          <Gap size={15} />
          <Spacer />
          <Box alignSelf="flex-start">{trailing}</Box>
        </>
      ) : null}
    </BorderedContainer>
  );
}

import { styled } from '@mui/material';
import { ReactComponent as ChevronLeft } from '@assets/svgs/chevron-left.svg';
import { StepperState } from '@components/stepper/use-stepper-hook';
import Stepper from '@components/stepper/stepper';
import Spacer from '@components/layout-util-components/spacer';
import { forwardRef, ReactNode } from 'react';
import { PageAlertDisplay } from '@components/alerts';
import { Gap } from '@components/layout-util-components/gap';

interface Props<T = any> {
  stepper?: StepperState<T>;
  onBack: () => void;
  end?: ReactNode;
}

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flex: '0 0 auto',
  height: 80,
  backgroundColor: 'white',
});

const BackButton = styled('button')(({ theme: { palette } }) => ({
  width: 80,
  height: 80,
  backgroundColor: palette.common.grey40,
  color: 'black',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: 'none',
  cursor: 'pointer',
}));

const StepperContainer = styled('div')({
  justifySelf: 'center',
  flex: '1 0 auto',
});

const AlertContainer = styled('div')({
  position: 'absolute',
  left: 80,
  right: 0,
  top: 0,
  height: 80,
  zIndex: 100,
  pointerEvents: 'none',

  '.MuiAlert-root': {
    display: 'flex',
    alignItems: 'center',
  },

  '>*': {
    width: '100% !important',
    height: '100% !important',
  },
});

const RcaStepperAppBar = forwardRef<HTMLDivElement, Props>(
  ({ stepper, onBack, end }: Props, ref) => {
    const { steps, currentStepIndex } = stepper ?? {};

    return (
      <Container ref={ref}>
        <BackButton type="button" onClick={onBack}>
          <ChevronLeft />
        </BackButton>

        <AlertContainer>
          <PageAlertDisplay />
        </AlertContainer>
        {steps ? (
          <>
            <Spacer />
            <StepperContainer>
              <Stepper activeStep={currentStepIndex!} steps={steps!} />
            </StepperContainer>
            <Spacer />
          </>
        ) : null}
        {end != null ? (
          <>
            {end}
            <Gap size={50} />
          </>
        ) : null}
      </Container>
    );
  }
);

export default RcaStepperAppBar;

import {
  useGetCaseOutcomeDetailQuery,
  useShareCaseOutcomeMutation,
} from '@api/endpoints/case-outcome.api';
import useField from '@hooks/use-field-hook';
import { required, validEmail } from '@util/validators';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { v4 as uuid } from 'uuid';
import { useCallback, useEffect, useMemo } from 'react';
import { ApiError } from '@api/types/api-error';
import { ShareOutcomeRequest } from '@api/types/case/case-outcome/share-outcome.request';
import { usePageAlertVariants } from '@components/alerts';
import { isNullOrEmpty } from '@util/string-util';
import { useLazyGetCompanyUsersOptionsQuery } from '@api/endpoints/company-user.api';

export interface SharePersonModel {
  clientId: string;
  id?: number;
  nameOrEmail: string;
}

export default function useShareOutcomeModal(
  caseId: number,
  outcomeId: number
) {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();

  const [getPeople] = useLazyGetCompanyUsersOptionsQuery();
  const [shareWithPeople, { isLoading: isBusy }] =
    useShareCaseOutcomeMutation();

  const {
    data: outcomeDetail,
    isLoading: loadingOutcomeDetails,
    isSuccess: loadedOutcomeDetails,
  } = useGetCaseOutcomeDetailQuery({ caseId, outcomeId });

  const people = useField<SharePersonModel[]>(
    [required()],
    useMemo(() => [], [])
  );
  const addPassword = useField<boolean>([], false);
  const password = useField<string>([
    required({ when: () => addPassword.value }),
  ]);

  const { isValid, validateAll } = useFieldsWatcher([
    people,
    addPassword,
    password,
  ]);

  const isLoading = loadingOutcomeDetails;
  const canSubmit = isValid && !isLoading && !isBusy;

  const resetPeople = people.reset;
  useEffect(() => {
    const users = outcomeDetail?.companyUsers ?? [];
    if (loadedOutcomeDetails && users.length > 0) {
      const sharePersons: SharePersonModel[] = users.map((user) => ({
        clientId: uuid(),
        id: user.id,
        nameOrEmail: user.label,
      }));

      resetPeople(sharePersons);
    }
  }, [loadedOutcomeDetails, outcomeDetail?.companyUsers, resetPeople]);

  const search = useCallback(
    async (searchText: string) =>
      getPeople({ searchText, skip: 0, take: 50 })
        .unwrap()
        .then((result) => {
          const emailValidator = validEmail();

          const results: Array<SharePersonModel> = [
            ...result.map(
              (user): SharePersonModel => ({
                clientId: uuid(),
                id: user.id,
                nameOrEmail: user.label,
              })
            ),
          ];

          // Commented out as client has changed their mind about wanting external users for now
          // if (
          //   results.length === 0 &&
          //   emailValidator.validate(searchText) == null
          // ) {
          //   results.push({
          //     clientId: uuid(),
          //     nameOrEmail: searchText,
          //   });
          // }

          return results;
        }),
    [caseId, getPeople]
  );

  const copyUrlToClipboard = () => {
    try {
      // get host and scheme from window
      const host = window.location.host;
      const scheme = window.location.protocol;

      navigator.clipboard.writeText(
        `${scheme}//${host}/outcome/${caseId}/report/${outcomeId}`
      );
      showSuccessMessage('Share link copied to clipboard');
    } catch (e) {
      console.log(e);
      showErrorMessage('Failed to copy share link to clipboard');
    }
  };

  const share = async () => {
    if (!validateAll()) {
      return false;
    }

    const containsAnyExternalUsers = people.value.some((x) => x.id == null);
    if (
      containsAnyExternalUsers &&
      (!addPassword.value || isNullOrEmpty(password.value))
    ) {
      showErrorMessage('Please enter a password to share with external users');
      return false;
    }

    try {
      const companyUsers = people.value.filter((x) => x.id != null);

      // commented out because client has changed mind about external users
      // const externalUsers = people.value.filter((x) => x.id == null);

      await shareWithPeople({
        caseId,
        outcomeId,
        companyUserIds: companyUsers.map((x) => x.id!),
      }).unwrap();
      showSuccessMessage('Successfully shared outcome');
      return true;
    } catch (e) {
      const { message, errors } = e as ApiError<ShareOutcomeRequest>;
      showErrorMessage(
        errors?.caseId ?? errors?.outcomeId ?? errors?.companyUserIds ?? message
      );
      return false;
    }
  };

  return {
    isLoading,
    addPassword,
    password,
    canSubmit,
    share,
    isBusy,
    search,
    people,
    copyUrlToClipboard,
  };
}

import { Filter } from '@components/table/hooks/use-filter-hook';
import { DropDownFilter, WCTTableFilters } from '@components/table';
import { Option } from '@api/types/option';

interface Props {
  isLoading: boolean;
  search: Filter<string>;
  subscription: Filter<string>;
  wctManager: Filter<string>;
  tags: Filter<Array<number>>;
  status: Filter<number>;
  tagOptions?: Array<Option>;
  statusOptions?: Array<Option>;
  managerOptions?: Array<Option<string>>;
  packageOptions?: Array<Option<string>>;
}
export default function CompanyTableFilters({
  isLoading,
  search,
  subscription,
  wctManager,
  tags,
  status,
  managerOptions,
  statusOptions,
  tagOptions,
  packageOptions,
}: Props) {
  return (
    <WCTTableFilters
      isLoading={isLoading}
      search={search.value}
      onSearch={search.set}
    >
      <DropDownFilter
        value={subscription.value}
        onChange={subscription.set}
        options={packageOptions ?? []}
      >
        Subscription
      </DropDownFilter>
      <DropDownFilter
        value={wctManager.value}
        onChange={wctManager.set}
        options={managerOptions ?? []}
      >
        WCT Manager
      </DropDownFilter>
      <DropDownFilter
        value={tags.value}
        onChange={tags.set}
        options={tagOptions ?? []}
        multiple
      >
        Tags
      </DropDownFilter>
      <DropDownFilter
        value={status.value}
        onChange={status.set}
        options={statusOptions ?? []}
      >
        Status
      </DropDownFilter>
    </WCTTableFilters>
  );
}

import ActivityPanel from '@pages/app/rca/tabs/components/events/activity-panel';
import {
  SideDrawerFormProps,
  withSideDrawer,
} from '@components/ui-popup/drawers';
import { ActivityPanelView } from '@pages/app/rca/tabs/components/events/activity-panel-hook';

export interface ActivityPanelDrawerProps extends SideDrawerFormProps<never> {
  view: ActivityPanelView;
}
function ActivityPanelDrawer({ view, onClose }: ActivityPanelDrawerProps) {
  return <ActivityPanel view={view} onClose={onClose} />;
}

export default withSideDrawer(ActivityPanelDrawer);

import { styled } from '@mui/material';
import { forwardRef, HTMLProps } from 'react';

interface Props extends HTMLProps<HTMLDivElement> {
  rounded?: boolean;
  small?: boolean;
}

const StyledBadge = styled('div', { target: 'flat-badge' })<Props>(
  ({ rounded, small, theme: { palette } }) => ({
    padding: small ? 4 : 8,
    borderRadius: rounded ? '25%' : 4,
    background: palette.common.grey20,
    color: palette.common.black,
    fontFamily: 'Kyn, Roboto',
    fontSize: small ? '10px' : '12px',
    fontWeight: '400',
    lineHeight: small ? '12px' : '14px',
    letterSpacing: '0em',
    textAlign: 'center',
    flex: '0 0 auto',
  })
);

const FlatBadge = forwardRef<HTMLDivElement, Props>(
  ({ children, ...rest }, ref) => {
    return (
      <StyledBadge ref={ref} {...(rest as any)}>
        {children}
      </StyledBadge>
    );
  }
);

export default FlatBadge;

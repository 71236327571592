import requireAuth from '@components/hoc/with-required-auth-hoc';
import { Box, IconButton, Skeleton, styled, Typography } from '@mui/material';
import useSkillPage from './skills-page-hook';
import { SkillsTypeaheadField } from './components/skills-typeahead-field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { InputSkeleton } from '@components/skeletons';
import ConfirmationModal from '@components/ui-popup/modals/confirmation-modal';

const StyledSkillContainerBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: 25,
});

const StyledSkillBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  gap: '4px',
  padding: theme.spacing(1.5),
  paddingLeft: theme.spacing(3.5),
  borderRadius: 12,
  border: '1px solid #aeaeae',
  fontSize: '13px',
  lineHeight: '18px',
  letterSpacing: '0.16px',
  '&:hover button': {
    opacity: 1,
  },
}));

const StyledH6 = styled(Typography)({
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '160%',
  letterSpacing: '0.15px',
});

const StyledBox = styled(Box)(({ theme }) => ({
  maxWidth: 380,
  margin: `${theme.spacing(2.5)} 0 ${theme.spacing(4)} 0`,
}));

const StyledIconBox = styled(Box)({
  display: 'flex',
  width: '16px',
  height: '16px',
  flexDirection: 'column',
  justifyContent: 'center',
  '& button': {
    color: '#001825',
    fontSize: '8px',
    fontWeight: 300,
    letterSpacing: '0.4px',
    borderRadius: '64px',
    background: '#DCDCDC',
    display: 'flex',
    width: '16px',
    height: '16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5.333px',
    /** Only display close icon on hover of parent skill box */
    opacity: 0,
    transition: 'opacity',
    transitionTimingFunction: 'ease-out',
    transitionDuration: '100ms',
  },
});

interface Props {
  isCompanySkills: boolean;
}

export const SkillPage = ({ isCompanySkills }: Props) => {
  const {
    linkedSkills,
    isLoading,
    skill,
    skillsOptions,
    skillIdToDelete,
    setSkillIdToDelete,
    onSkillSelect,
    onSkillRemove,
    deleteCompanySkill,
  } = useSkillPage({ isCompanySkills });

  return (
    <Box>
      {skillIdToDelete && (
        <ConfirmationModal
          show={true}
          title="Are you sure you want to delete this SKILL?"
          message="Please note this will remove this skill from all associated user profiles."
          yesButtonAsyncAction={async () => {
            const response = await deleteCompanySkill(skillIdToDelete);

            return 'data' in response;
          }}
          onClose={() => setSkillIdToDelete(null)}
        />
      )}
      <StyledH6 variant="h6">Skills</StyledH6>
      <StyledBox>
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <SkillsTypeaheadField
            id="skills"
            name="skills"
            placeholder="Search skills"
            value={skill.value}
            error={skill.error}
            onChange={onSkillSelect}
            options={skillsOptions!}
            required
          />
        )}
      </StyledBox>
      <StyledSkillContainerBox>
        {isLoading
          ? [...Array(4)].map((_) => <Skeleton width="12rem" height="8rem" />)
          : linkedSkills?.map(({ skillId, name }) => (
              <StyledSkillBox key={skillId}>
                <Typography>{name}</Typography>
                <StyledIconBox>
                  <IconButton onClick={() => onSkillRemove(skillId)}>
                    <FontAwesomeIcon icon={faClose} />
                  </IconButton>
                </StyledIconBox>
              </StyledSkillBox>
            ))}
      </StyledSkillContainerBox>
    </Box>
  );
};

export default requireAuth(SkillPage);

import { Option } from '@api/types/option';

export class CaseImpactType {
  static actual: true = true;
  static potential: false = false;
}

export enum CaseImpactTypeMetric {
  currency = 'Currency',
  scale = 'Scale',
  numeric = 'Numeric',
}
export interface CaseImpactTypeMetricsResource<T = number> {
  caseImpactTypeMetricId: number;
  name: string;
  caseImpactTypeMetric: CaseImpactTypeMetric;
  options: Option<T>[];
}

import { Grid, TableCell, TableRow } from '@mui/material';
import useCompaniesIndex from '@pages/admin/companies/companies-index-hook';
import CompanyTableFilters from '@pages/admin/companies/components/company-table-filters';
import { WCTTable } from '@components/table';
import OutlinedBadge from '@components/badges/outlined-badge';
import FilledBadge from '@components/badges/filled-badge';
import ColorBadge from '@components/badges/color-badge';
import { useAdminTr } from '@i18n/use-admin-tr';
import CompanyHeader from '@pages/admin/companies/components/company-header';
import ContentContainer from '@components/containers/content-container';
import requireAuth from '@components/hoc/with-required-auth-hoc';
import { ElipsisButton } from '@components/buttons';
import GroupedAvatars from '@components/containers/grouped-avatars';

function CompaniesIndexPage() {
  const {
    searchFilter,
    subscriptionFilter,
    wctManagerFilter,
    tagsFilter,
    statusFilter,
    sort,
    companies,
    pagination,
    isLoading,
    isFetching,
    tags,
    subscriptionStatuses,
    managers,
    packages,
  } = useCompaniesIndex();

  const { t } = useAdminTr('companies');

  return (
    <>
      <CompanyHeader companyCount={companies?.totalCount} />
      <ContentContainer>
        <Grid item container alignItems="stretch" direction="column">
          <WCTTable
            isLoading={isLoading || isFetching}
            count={companies?.totalCount ?? 0}
            pagination={pagination}
            filters={
              <CompanyTableFilters
                isLoading={isLoading}
                search={searchFilter}
                subscription={subscriptionFilter}
                wctManager={wctManagerFilter}
                status={statusFilter}
                tags={tagsFilter}
                managerOptions={managers}
                statusOptions={subscriptionStatuses}
                tagOptions={tags}
                packageOptions={packages}
              />
            }
            headers={[
              { title: 'Company Name', sortProperty: 'companyName', ...sort },
              { title: 'Subscription' },
              { title: 'Status' },
              { title: 'Users', sortProperty: 'usersCount', ...sort },
              { title: 'RCAs' },
              { title: 'Client Admin' },
              { title: 'WCT Manager' },
              { title: 'Tags' },
              { title: 'Sector' },
              { title: 'Account Created', sortProperty: 'created', ...sort },
              { title: <></> },
            ]}
          >
            {companies?.model.map(
              ({
                companyId,
                companyName,
                companyStatusName,
                sectorName,
                tags,
                created,
                subscriptionName,
                accountManagers,
                rcaCount,
                usersCount,
                clientAdminName,
              }) => (
                <TableRow key={companyId}>
                  <TableCell>{companyName}</TableCell>
                  <TableCell>
                    <OutlinedBadge>{subscriptionName}</OutlinedBadge>
                  </TableCell>
                  <TableCell>{companyStatusName}</TableCell>
                  <TableCell>{usersCount}</TableCell>
                  <TableCell>{rcaCount}</TableCell>
                  <TableCell>{clientAdminName}</TableCell>
                  <TableCell>
                    {accountManagers ? (
                      <GroupedAvatars
                        items={accountManagers!}
                        extractInitials
                      />
                    ) : null}
                  </TableCell>
                  <TableCell>
                    {(tags || []).map(({ id, label }) => (
                      <FilledBadge key={id}>{label}</FilledBadge>
                    ))}
                  </TableCell>
                  <TableCell>
                    <ColorBadge>{sectorName}</ColorBadge>
                  </TableCell>
                  <TableCell>
                    {t('table.data.created', {
                      value: new Date(created),
                    })}
                  </TableCell>
                  <TableCell>
                    <ElipsisButton actions={[]} />
                  </TableCell>
                </TableRow>
              )
            )}
          </WCTTable>
        </Grid>
      </ContentContainer>
    </>
  );
}

export default requireAuth(CompaniesIndexPage);

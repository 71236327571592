import {
  useDeleteCompanySkillMutation,
  useGetCompanySkillOptionsQuery,
  useGetSkillLinkedQuery,
  useGetSkillOptionsQuery,
  useUpdateSkillsLinksMutation,
  useUpsertCompanySkillMutation,
} from '@api/endpoints/skills.api';
import { Option } from '@api/types/option';
import { Skill } from '@api/types/skill/skill';

import useField from '@hooks/use-field-hook';
import { useMemo, useState } from 'react';

export default function useSkillPage({
  isCompanySkills,
}: {
  isCompanySkills: boolean;
}) {
  // Common
  const { data: skillsOptions, isLoading: loadingSkillsOptions } =
    useGetSkillOptionsQuery();

  const skill = useField<Option>([], undefined);

  // User level skill hooks
  const { data: userLinkedSkills, isLoading: loadingUserLinkedSkills } =
    useGetSkillLinkedQuery();

  const [updateSkillLinks, { isLoading: isSubmittingUpdate }] =
    useUpdateSkillsLinksMutation();

  const isLoadingUserSkills =
    loadingSkillsOptions || loadingUserLinkedSkills || isSubmittingUpdate;

  // Company level skill hooks
  const [skillIdToDelete, setSkillIdToDelete] = useState<number | null>(null);

  const { data: companyLinkedSkills, isLoading: loadingCompanyLinkedSkills } =
    useGetCompanySkillOptionsQuery();

  const [deleteCompanySkill, { isLoading: isDeletingCompanySkill }] =
    useDeleteCompanySkillMutation();

  const [upsertCompanySkill, { isLoading: isUpsertingCompanySkill }] =
    useUpsertCompanySkillMutation();

  const isLoadingCompanySkills =
    loadingCompanyLinkedSkills ||
    loadingSkillsOptions ||
    isDeletingCompanySkill ||
    isUpsertingCompanySkill;

  // Common

  const onSkillSelect = (value: Option | Array<Option>) => {
    if (Array.isArray(value)) return;

    if (isCompanySkills) {
      upsertCompanySkill(value);
    } else {
      const currentSkillIds = (userLinkedSkills ?? [])?.map((m) => m.id);
      updateSkillLinks([...currentSkillIds, value.id]);
    }
  };

  const onSkillRemove = async (skillId: number) => {
    if (isCompanySkills) {
      setSkillIdToDelete(skillId);
    } else {
      updateSkillLinks([
        ...(userLinkedSkills ?? [])
          ?.filter((m) => m.id !== skillId)
          .map((m) => m.id),
      ]);
    }

    return false;
  };

  const linkedSkills: Array<Skill> = useMemo(() => {
    if (isCompanySkills) return companyLinkedSkills || [];

    return (userLinkedSkills || []).map((skill) => ({
      skillId: skill.id,
      name: skill.label,
    }));
  }, [userLinkedSkills, companyLinkedSkills, isCompanySkills]);

  return {
    skillsOptions,
    linkedSkills,
    skill,
    onSkillSelect,
    onSkillRemove,
    skillIdToDelete,
    setSkillIdToDelete,
    deleteCompanySkill,
    isLoading: isCompanySkills ? isLoadingCompanySkills : isLoadingUserSkills,
  };
}

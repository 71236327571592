import { api } from '@api/api';
import { Option } from '@api/types/option';

const adminRoleApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAdminRoleOptions: build.query<Option<string>[], void>({
      query: () => ({
        url: 'admin/role/options',
      }),
    }),
  }),
});

export const { useGetAdminRoleOptionsQuery } = adminRoleApi;

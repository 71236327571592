import { ImpactInfoModel } from '@pages/app/rca/create/steps/impact-step-models';
import { Option } from '@api/types/option';
import useField from '@hooks/use-field-hook';
import { onlyNumbers, required, validCurrency } from '@util/validators';
import { useBinaryOptions } from '@util/constant-options';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import {
  CaseImpactType,
  CaseImpactTypeMetric,
} from '@api/types/case-impact-type-metric/case-impact-type-metric.resource';
import { useGetMetricDetailQuery } from '@api/endpoints/case-impact-type-metric.api';
import { isApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';
import { useEffect, useMemo } from 'react';
import { ImpactStepState } from '@pages/app/rca/create/steps/impact-step-hook';
import useSystemText from '@hooks/use-system-text';
import { useAppSelector } from '@store/store';
import { selectCurrentRcaCurrency } from '@store/rca-editor/selectors';

export default function useImpactInfo(
  state: ImpactStepState,
  model: ImpactInfoModel
) {
  const { impactTypeOptions: impactTypes } = state;

  const { systemText } = useSystemText();
  const currency = useAppSelector(selectCurrentRcaCurrency);

  const typeOfImpactOptions: Array<Option<boolean>> = [
    { id: CaseImpactType.actual, label: 'Actual' },
    { id: CaseImpactType.potential, label: 'Potential' },
  ];
  const trackOptions = useBinaryOptions({ reverse: true });
  const { showErrorMessage } = usePageAlertVariants();

  const metric = useField<number>([required()], model.metricId);
  const chosenMetricInfo = useMemo(() => {
    return impactTypes?.find((x) => x.caseImpactTypeId === metric.value);
  }, [impactTypes, metric.value]);

  const { data: metricValueData, isFetching: loadingMetricOptions } =
    useGetMetricDetailQuery(chosenMetricInfo?.caseImpactTypeMetricId ?? -1, {
      skip: chosenMetricInfo == null,
    });

  const typeOfImpact = useField<boolean>([required()], model.actualValue);
  const description = useField<string>([required()], model.description);
  const track = useField<boolean>([required()], model.track);
  const severity = useField<number>([required()], model.severityId);
  const metricValue = useField<string>(
    [
      required(),
      validCurrency({
        when: () =>
          metricValueData?.caseImpactTypeMetric ===
          CaseImpactTypeMetric.currency,
      }),
      onlyNumbers({
        when: () =>
          metricValueData?.caseImpactTypeMetric ===
          CaseImpactTypeMetric.numeric,
      }),
    ],
    model.impactValue
  );

  const impactTypeOptions = useMemo(() => {
    return (
      impactTypes?.map((option) => ({
        id: option.caseImpactTypeId,
        label: option.name,
      })) ?? []
    );
  }, [impactTypes]);

  const { isValid, isDirty, resetAll, validateAll } = useFieldsWatcher([
    metric,
    typeOfImpact,
    description,
    track,
    severity,
    metricValue,
  ]);

  useEffect(() => {
    // This is needed for 'add another' where the state doesn't change and the
    // use field doesn't reset because the one previously added would be a null-value
    // object anyway...
    resetAll();
  }, [model, resetAll]);

  const submit = async (addAnother: boolean) => {
    if (!validateAll()) {
      return false;
    }

    state
      .submit(
        {
          impactId: model.impactId,
          track: track.value,
          actualValue: typeOfImpact.value,
          metricId: metric.value,
          description: description.value,
          impactValue: metricValue.value,
          severityId: severity.value,
          severityName: state.severityOptions?.find(
            (x) => x.id === severity.value
          )?.label,
        },
        addAnother
      )
      .then((result) => {
        if (isApiError(result)) {
          const { message, errors } = result;

          showErrorMessage(
            errors?.caseImpactTypeId ?? errors?.caseId ?? message
          );

          metric.setError(errors?.caseImpactTypeId);
          typeOfImpact.setError(errors?.actualValue);
          description.setError(errors?.description);
          track.setError(errors?.tracked);
          metricValue.setError(errors?.impactValue);
          severity.setError(errors?.caseImpactSeverityId);
        }
      });
  };

  return {
    metric,
    typeOfImpact,
    description,
    track,
    typeOfImpactOptions,
    trackOptions,
    isBusy: state.isBusy,
    canSubmit: isValid && isDirty && !loadingMetricOptions && !state.isBusy,
    metricType: metricValueData?.caseImpactTypeMetric,
    impactTypeOptions,
    metricValueOptions: metricValueData?.options ?? [],
    loadingMetricOptions,
    metricValue,
    isEdit: model.impactId != null,
    submit,
    severityOptions: state.severityOptions ?? [],
    severity,
    systemText,
    currency,
  };
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  RcaEdge,
  RcaNode,
  RcaNodeType,
  StorageNode,
} from '@store/rca-editor/types';
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Connection,
  EdgeChange,
  NodeChange,
  XYPosition,
} from 'reactflow';
import caseApi from '@api/endpoints/case.api';
import { CaseResource } from '@api/types/case/case.resource';
import chainApi from '@api/endpoints/chain/chain.api';
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';
import { ChainDetailResponse } from '@api/types/chain/chain-detail.response';
import { SnapshotState } from '@store/rca-editor-snapshot/rca-editor-snapshot-slice';
import { RcaUtil } from '@util/rca-util';
import caseOutcomeApi from '@api/endpoints/case-outcome.api';

export enum NodePanelEditorTab {
  overview,
  evidence,
  tasks,
  impacts,
  notes,
  solutions,
  connections,
  activity,
}

export enum RcaChartMode {
  build,
  present,
}

export interface RcaEditorState {
  nodes: Array<RcaNode>;
  edges: Array<RcaEdge>;
  storageChainItemIdsToConsume: Array<number>;
  storage: Array<StorageNode>;
  selectedNodeId?: string;
  nodePanelEditorTab: NodePanelEditorTab;
  defaultToCreate?: boolean;
  mode: RcaChartMode;
  proximityEdge?: RcaEdge;
  storageGraphNode?: RcaNode;

  editNodeContentId?: string;
  onDragOriginalParentId?: string;
  onDragDescendantIds?: Array<string>;
  hoverVisibilityNodeId?: string;
  isHighlightMode: boolean;

  caseDetail?: CaseResource;

  displayHealthState?: boolean;
  isDevMode: boolean;

  nodeBusyTracker: Record<string, number>;
  globalBusyTracker: number;
  isDraggingIntoStorageContainer?: boolean;
}

const initialNodes: Array<RcaNode> = [
  {
    position: { x: 0, y: 0 },
    id: '1',
    draggable: false,
    data: {
      label: '',
      isRoot: true,
      sortOrder: 0,
    },
  },
];

const initialState: RcaEditorState = {
  nodes: initialNodes,
  edges: [],
  storage: [],
  storageChainItemIdsToConsume: [],
  mode: RcaChartMode.build,
  nodePanelEditorTab: NodePanelEditorTab.overview,
  isDevMode: false,
  isHighlightMode: false,
  nodeBusyTracker: {},
  globalBusyTracker: 0,
};

export const rcaEditorSlide = createSlice({
  name: 'rca-editor',
  initialState,
  reducers: {
    hardResetRcaToInitialState(state, _: PayloadAction) {
      return initialState;
    },
    resetToInitialState(state, _: PayloadAction) {
      return {
        ...initialState,
        caseDetail: state.caseDetail,
        isDevMode: state.isDevMode,
      };
    },
    resetEditorToSnapshot(state, { payload }: PayloadAction<SnapshotState>) {
      Object.assign(state, payload);
    },
    toggleDevMode(state, _: PayloadAction) {
      state.isDevMode = !state.isDevMode;
    },
    onNodesChange(state, { payload }: PayloadAction<NodeChange[]>) {
      state.nodes = applyNodeChanges(payload, state.nodes) as RcaNode[];
    },
    onEdgesChange(state, { payload }: PayloadAction<EdgeChange[]>) {
      state.edges = applyEdgeChanges(payload, state.edges);
    },
    onConnectNode(state, { payload }: PayloadAction<Connection>) {
      state.edges = addEdge(payload, state.edges);
    },
    refreshNodes(state, { payload }: PayloadAction<RcaNode[]>) {
      state.nodes = payload;
    },
    refreshEdges(state, { payload }: PayloadAction<RcaEdge[]>) {
      state.edges = payload;
    },
    addNodesToStorage(state, { payload }: PayloadAction<RcaNode[]>) {
      const currentCase = state.caseDetail!;
      const newStorageNodes = payload
        .filter((node) => node.data.chainItemId != null)
        .filter(
          (node) =>
            node.type == null ||
            node.type === RcaNodeType.default ||
            node.type === RcaNodeType.endState
        )
        .map(
          (node): StorageNode => ({
            clientUuid: uuid(),
            caseId: node.data.caseId,
            chainId: currentCase.chainId,
            caseName: currentCase.name,
            chainItemId: node.data.chainItemId!,
            created: dayjs().utc().toISOString(),
            description: node.data.label,
            endState: node.data.endState,
            endStateId: node.data.endStateId,
            themes: [],
          })
        );

      state.storage = [...state.storage, ...newStorageNodes];
      state.storageChainItemIdsToConsume = [
        ...state.storageChainItemIdsToConsume,
        ...newStorageNodes
          .filter((x) => x.chainItemId != null)
          .map((node) => node.chainItemId!),
      ];
    },
    consumeStorageChainIds(state, { payload }: PayloadAction<Array<number>>) {
      state.storageChainItemIdsToConsume =
        state.storageChainItemIdsToConsume.filter((x) => !payload.includes(x));
    },
    setSelectedNode(state, { payload }: PayloadAction<RcaNode | undefined>) {
      const nodeId = payload?.id;
      state.selectedNodeId = nodeId;
      state.editNodeContentId = undefined;
      state.nodes = state.nodes.map((node) => {
        return {
          ...node,
          selected: !!nodeId && node.id === nodeId,
        };
      });
    },
    setEditNodeContentId(state, { payload }: PayloadAction<string>) {
      state.nodes = state.nodes.map((node) => {
        return {
          ...node,
          selected: node.id === payload,
        };
      });
      state.selectedNodeId = undefined;
      state.editNodeContentId = payload;
    },
    resetFocus(state, _: PayloadAction) {
      state.editNodeContentId = undefined;
      state.selectedNodeId = undefined;
      state.nodes = state.nodes.map((node) => {
        return {
          ...node,
          selected: false,
        };
      });
    },
    stopEditing(state, _: PayloadAction) {
      state.editNodeContentId = undefined;
    },
    updateNode(state, { payload }: PayloadAction<RcaNode>) {
      state.nodes = state.nodes.map((node) => {
        if (node.id !== payload.id) {
          return node;
        }

        return {
          ...node,
          ...payload,
        };
      });
    },
    updateEdge(state, { payload }: PayloadAction<RcaEdge>) {
      state.edges = state.edges.map((edge) => {
        if (edge.id !== payload.id) {
          return edge;
        }

        return {
          ...edge,
          ...payload,
        };
      });
    },
    setProximityEdge(state, { payload }: PayloadAction<RcaEdge>) {
      if (state.proximityEdge == null) {
        state.proximityEdge = payload;
      } else {
        state.proximityEdge = {
          ...state.proximityEdge,
          ...payload,
        };
      }
    },
    clearProximityEdge(state, _: PayloadAction) {
      state.proximityEdge = undefined;
    },
    setOnDragOriginalId(state, { payload }: PayloadAction<string | undefined>) {
      state.onDragOriginalParentId = payload;
    },
    setOnDragDescendantIds(state, { payload }: PayloadAction<Array<string>>) {
      state.onDragDescendantIds = payload;
    },
    resetOnDragIds(state, _: PayloadAction) {
      state.onDragOriginalParentId = undefined;
      state.onDragDescendantIds = undefined;
    },
    selectNodePanelEditorTab(
      state,
      { payload }: PayloadAction<NodePanelEditorTab>
    ) {
      state.nodePanelEditorTab = payload;
      state.defaultToCreate = false;
    },
    selectNodePanelEditorForCreateTab(
      state,
      { payload }: PayloadAction<NodePanelEditorTab>
    ) {
      state.nodePanelEditorTab = payload;
      state.defaultToCreate = true;
    },
    setRcaChartMode(state, { payload }: PayloadAction<RcaChartMode>) {
      state.mode = payload;
      state.isHighlightMode = false;
      state.nodes = state.nodes.map((node) => ({
        ...node,
        selected: false,
        data: {
          ...node.data,
          highlight: false,
        },
      }));
      // Make sure the root node is focused so that the presenter bar appears
      if (payload === RcaChartMode.present) {
        state.selectedNodeId = state.nodes.find((x) => !!x.data?.isRoot)?.id;
      } else {
        state.selectedNodeId = undefined;
      }
    },
    unHighlightAllNodes(state, _: PayloadAction) {
      state.nodes = state.nodes.map((node) => ({
        ...node,
        selected: false,
        data: {
          ...node.data,
          highlight: false,
        },
      }));
    },
    setSortedSiblings(state, { payload }: PayloadAction<Array<RcaNode>>) {
      state.nodes = state.nodes.map((node) => {
        const sibling = payload.find((sibling) => sibling.id === node.id);
        return sibling ?? node;
      });
    },
    setHoverVisibilityNodeId(
      state,
      { payload }: PayloadAction<string | undefined>
    ) {
      state.hoverVisibilityNodeId = payload;
    },
    setStorageGraphNode(
      state,
      { payload }: PayloadAction<RcaNode | undefined>
    ) {
      state.storageGraphNode = payload;
    },
    setStorageNodes(state, { payload }: PayloadAction<Array<StorageNode>>) {
      state.storage = payload;
    },
    setStorageGraphNodePosition(state, { payload }: PayloadAction<XYPosition>) {
      if (
        state.storageGraphNode &&
        state.storageGraphNode.position.x !== payload.x &&
        state.storageGraphNode.position.y !== payload.y
      ) {
        state.storageGraphNode.position = payload;
      }
    },
    setHighlightMode(state, { payload }: PayloadAction<boolean>) {
      state.isHighlightMode = payload;
    },
    setDisplayHealthState(state, { payload }: PayloadAction<boolean>) {
      state.displayHealthState = payload;
    },
    incrementNodeBusyTracker(state, { payload }: PayloadAction<string>) {
      state.nodeBusyTracker[payload] = state.nodeBusyTracker[payload] ?? 0;
      state.nodeBusyTracker[payload]++;
    },
    decrementNodeBusyTracker(state, { payload }: PayloadAction<string>) {
      state.nodeBusyTracker[payload] = state.nodeBusyTracker[payload] ?? 0;
      if (state.nodeBusyTracker[payload] !== 0) {
        state.nodeBusyTracker[payload]--;
      }
    },
    incrementGlobalBusyTracker(state, _: PayloadAction) {
      state.globalBusyTracker++;
    },
    decrementGlobalBusyTracker(state, _: PayloadAction) {
      if (state.globalBusyTracker !== 0) {
        state.globalBusyTracker--;
      }
    },
    setChartFromServerData(
      state,
      { payload }: PayloadAction<ChainDetailResponse>
    ) {
      state.storage = payload.storage;
      RcaUtil.populateNodesFromServerData(
        state.nodes,
        payload,
        state.selectedNodeId
      );
    },
    setIsDraggingIntoStorageContainer(
      state,
      { payload }: PayloadAction<boolean>
    ) {
      state.isDraggingIntoStorageContainer = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      caseApi.endpoints.getCaseDetail.matchFulfilled,
      (state, { payload: caseDetail }) => {
        state.caseDetail = caseDetail;
      }
    );

    builder.addMatcher(
      chainApi.endpoints.getChainDetail.matchFulfilled,
      (state, { payload: chainDetail }) => {
        state.edges = chainDetail.edges;
        state.storage = chainDetail.storage;
        state.selectedNodeId = undefined;

        // Ensure nothing is selected or highlighted
        state.nodes = chainDetail.nodes.map((node) => ({
          ...node,
          selected: false,
          data: {
            ...node.data,
            highlight: false,
          },
        }));
      }
    );

    builder.addMatcher(
      caseOutcomeApi.endpoints.getCaseOutcomeResult.matchFulfilled,
      (state, { payload: outcomeResult }) => {
        const { chain } = outcomeResult;
        if (chain != null) {
          state.nodes = chain.nodes;
          state.edges = chain.edges;
          state.storage = chain.storage;
          state.selectedNodeId = undefined;
        }
      }
    );
  },
});

export default rcaEditorSlide.reducer;

export const {
  hardResetRcaToInitialState,
  resetEditorToSnapshot,
  setSelectedNode,
  stopEditing,
  resetFocus,
  setEditNodeContentId,
  updateNode,
  updateEdge,
  refreshEdges,
  refreshNodes,
  onNodesChange,
  onEdgesChange,
  onConnectNode,
  setOnDragOriginalId,
  setProximityEdge,
  clearProximityEdge,
  setOnDragDescendantIds,
  resetOnDragIds,
  selectNodePanelEditorTab,
  setRcaChartMode,
  addNodesToStorage,
  resetToInitialState,
  toggleDevMode,
  setSortedSiblings,
  consumeStorageChainIds,
  setStorageGraphNode,
  setHoverVisibilityNodeId,
  setHighlightMode,
  unHighlightAllNodes,
  setStorageNodes,
  setDisplayHealthState,
  incrementNodeBusyTracker,
  decrementNodeBusyTracker,
  incrementGlobalBusyTracker,
  decrementGlobalBusyTracker,
  setChartFromServerData,
  selectNodePanelEditorForCreateTab,
  setIsDraggingIntoStorageContainer,
} = rcaEditorSlide.actions;

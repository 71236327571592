import { Button, ButtonProps as MuiButtonProps, styled } from '@mui/material';
import { AnchorHTMLAttributes, PropsWithChildren } from 'react';

import { ButtonProps } from './button-props';
import { LoadingIndicator } from '@components/loading-indicator';

const StyledButton = styled(Button)<MuiButtonProps & AnchorHTMLAttributes<any>>(
  ({ theme }) => ({
    textTransform: 'none',
    minHeight: 42,
    flexShrink: 0,
    borderRadius: 21,
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: 'none',
  })
);

const OutlinedButton = ({
  id,
  children,
  isBusy,
  onClick,
  disabled,
  type,
  href,
}: PropsWithChildren<ButtonProps>) => {
  return (
    <StyledButton
      id={id}
      type={type ?? 'button'}
      variant="outlined"
      onClick={onClick}
      disabled={disabled || isBusy}
      href={href}
      target={!!href ? '_blank' : undefined}
      rel={!!href ? 'noreferrer' : undefined}
    >
      <span
        className="button-content"
        style={{ visibility: isBusy ? 'hidden' : 'visible' }}
      >
        {children}
      </span>
      <LoadingIndicator show={isBusy ?? false} />
    </StyledButton>
  );
};

export default OutlinedButton;

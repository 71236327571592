import withModal, { ModalProps } from '@components/ui-popup/modals/modal-hoc';
import { styled, Typography } from '@mui/material';
import Placeholder from '@assets/images/placeholder-45.png';
import Row from '@components/layout-util-components/row';
import { useState } from 'react';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import { Gap } from '@components/layout-util-components/gap';

// TODO(Nick): This is going to be an API enum?
export enum OutcomeType {
  html = 'html',
  csv = 'csv',
}

export interface CreateOutcomeDialogProps extends ModalProps<OutcomeType> {}

const Container = styled('div', { target: 'create-outcome-dialog' })(
  ({ theme }) => ({
    minWidth: 960,
    padding: '32px 32px 24px 32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  })
);

const Option = styled('button')<{ selected?: boolean }>(
  ({ selected, theme: { palette } }) => ({
    cursor: 'pointer',
    display: 'flex',
    minWidth: 411,
    borderRadius: 8,
    border: '1px solid #00000040',
    outline: 'none',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '51px 150px 51px 23px',
    background: palette.common.white,
    textAlign: 'left',

    ...(selected && {
      border: '2px solid #000000',
      padding: '50px 99px 50px 22px',
    }),
  })
);

function CreateOutcomeDialog({ onClose, show }: CreateOutcomeDialogProps) {
  const [selected, setSelected] = useState<OutcomeType>();

  return (
    <Container>
      <Typography variant="h6">Create an outcome</Typography>
      <Gap size={24} />
      <Typography variant="body1">Select type of report</Typography>
      <Gap size={20} />
      <Row>
        <Option
          type="button"
          selected={selected === OutcomeType.html}
          onClick={() => setSelected(OutcomeType.html)}
        >
          <img src={Placeholder} width={75} height={75} alt="placeholder" />
          <Gap size={20} />
          <Typography variant="h5">HTML</Typography>
          <Gap size={4} />
          <Typography variant="body2">
            Nulla varius convallis lacinia. In in hendrerit felis, sit amet
            semper nibh.
          </Typography>
        </Option>
        <Gap size={24} />
        <Option
          type="button"
          selected={selected === OutcomeType.csv}
          onClick={() => setSelected(OutcomeType.csv)}
        >
          <img src={Placeholder} width={75} height={75} alt="placeholder" />
          <Gap size={20} />
          <Typography variant="h5">CSV</Typography>
          <Gap size={4} />
          <Typography variant="body2">
            Nulla varius convallis lacinia. In in hendrerit felis, sit amet
            semper nibh.
          </Typography>
        </Option>
      </Row>
      <Gap size={32} />
      <ActionsRow>
        <OutlinedButton onClick={() => onClose(undefined)}>
          Cancel
        </OutlinedButton>
        <PrimaryButton
          disabled={selected == null}
          onClick={() => onClose(selected!)}
        >
          Confirm
        </PrimaryButton>
      </ActionsRow>
    </Container>
  );
}

export default withModal(CreateOutcomeDialog);

import { ReportTypeListItem } from '@pages/app/rca/tabs/lenses/report-type-options-hook';
import { styled, Typography } from '@mui/material';
import { FlatButton } from '@components/buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import Spacer from '@components/layout-util-components/spacer';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  option: ReportTypeListItem;
  isSelected: boolean;
  onToggleSelect: () => void;
}

const Container = styled('div')<Props>(({ theme: { palette } }) => ({
  display: 'flex',
  border: '1px solid #00000040',
  borderRadius: 5,
  padding: 10,
  backgroundColor: palette.common.white,
  marginBottom: 8,
}));

export default function SelectableReportTypeListItem(props: Props) {
  const {
    option: { name },
    isSelected,
    onToggleSelect,
  } = props;

  return (
    <Container {...props}>
      <FlatButton onClick={onToggleSelect} dark={isSelected}>
        {isSelected ? <FontAwesomeIcon icon={faCheck} /> : null}
      </FlatButton>
      <Gap size={12} />
      <Typography variant="body2">{name}</Typography>
      <Spacer />
    </Container>
  );
}

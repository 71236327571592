import { Box, Grid, styled, Typography } from '@mui/material';
import { useAppTr } from '@i18n/use-app-tr';
import { GroupCard } from '@components/cards';
import { LinkButton, OutlinedButton } from '@components/buttons';
import GroupForm, { GroupFormProps } from '@pages/app/groups/forms/group-form';
import requireAuth from '@components/hoc/with-required-auth-hoc';
import { Permission } from '@api/types/permission';
import useManageGroups from '@pages/app/groups/manage-groups-hook';
import { LoadingIndicator } from '@components/loading-indicator';
import { SortByFilter, WCTTableFilters } from '@components/table';
import { CompanyGroupResource } from '@api/types/company/company-group/company-group.resource';
import GroupDetailView, {
  GroupDetailViewProps,
} from '@pages/app/profile/components/group-detail-view';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { useCallback, useEffect, useRef } from 'react';
import useSearchParamsTyped from '@hooks/router-dom-helper-hooks';
import { numberFromString } from '@util/string-util';
import NoGroupsInfo from './components/no-groups-info';
import ContentContainer from '@components/containers/content-container';
import { usePageAlertVariants } from '@components/alerts';
import DeleteConfirmationModal from '@components/ui-popup/modals/delete-confirmation-modal';
import { ApiError } from '@api/types/api-error';
import { Gap } from '@components/layout-util-components/gap';

type SearchParams = {
  companyGroupId: string;
};

const GroupsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  gap: 30,
  width: '100%',
});

function ManageGroupsPage() {
  const { t } = useAppTr('groups');
  const { showPopup } = useUiPopup();
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();

  const hasHandledSearchParams = useRef(false);
  const { companyGroupId } = useSearchParamsTyped<SearchParams>();

  const {
    canDelete,
    canUpdate,
    groups,
    isLoading,
    search,
    loadMore,
    sort,
    sortByOptions,
    onDeleteGroup,
    showLoadMoreButton,
  } = useManageGroups();

  const showCreateGroup = () => {
    showPopup(GroupForm);
  };

  const showEditGroup = (id: number) => {
    showPopup<GroupFormProps, CompanyGroupResource>(GroupForm, {
      groupId: id,
    });
  };

  const showDeleteGroup = (id: number, name: string) => {
    showPopup(DeleteConfirmationModal, {
      itemName: 'Group',
      message: t('deleteModal.message') ?? '',
      onDelete: async () => {
        try {
          await onDeleteGroup(id, name);
          showSuccessMessage('Group deleted successfully');
          return true;
        } catch (error) {
          const { message, errors } = error as ApiError<{ groupId: number }>;
          showErrorMessage(errors?.groupId ?? message);
          return false;
        }
      },
    });
  };

  const showViewGroup = useCallback(
    (id: number) => {
      showPopup<GroupDetailViewProps, void>(GroupDetailView, {
        id,
      });
    },
    [showPopup]
  );

  useEffect(() => {
    if (hasHandledSearchParams.current) {
      return;
    }
    const companyGroupIdNumber = numberFromString(companyGroupId);
    if (companyGroupIdNumber != null) {
      hasHandledSearchParams.current = true;
      showViewGroup(companyGroupIdNumber);
    }
  }, [companyGroupId, showViewGroup]);

  return (
    <ContentContainer>
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography variant="h1">
          {t('title.admin', { count: groups?.totalCount ?? 0 })}
        </Typography>
        <Gap size={12} />
        <OutlinedButton disabled={isLoading} onClick={showCreateGroup}>
          {t('createButtonLabel')}
        </OutlinedButton>
      </Grid>
      <Gap size={20} />
      {isLoading ? (
        <LoadingIndicator show />
      ) : groups!.totalCount === 0 ? (
        <NoGroupsInfo onCreateClicked={showCreateGroup} />
      ) : (
        <>
          <WCTTableFilters
            search={search.value}
            onSearch={search.set}
            isLoading={isLoading}
            rightOfSearchContent={
              <SortByFilter
                value={sort.value}
                onChange={sort.set}
                options={sortByOptions}
              />
            }
          />
          <Gap size={32} />
          <GroupsContainer>
            {groups?.model.map((group) => (
              <GroupCard
                key={group.companyGroupId}
                group={group}
                actions={[
                  {
                    label: t('card.actions.view'),
                    onClick: () => showViewGroup(group.companyGroupId),
                    hasPermission: true, //canView,
                  },
                  {
                    label: t('card.actions.edit'),
                    onClick: () => showEditGroup(group.companyGroupId),
                    hasPermission: canUpdate,
                  },
                  { divider: true },
                  {
                    label: t('card.actions.delete'),
                    onClick: () =>
                      showDeleteGroup(group.companyGroupId, group.groupName),
                    hasPermission: canDelete,
                  },
                ]}
              />
            ))}
          </GroupsContainer>
          <Gap size={15} />
          <Box alignSelf="center">
            {showLoadMoreButton ? (
              <LinkButton disabled={isLoading} onClick={loadMore}>
                {t('loadMoreButtonLabel')}
              </LinkButton>
            ) : null}
          </Box>
        </>
      )}
    </ContentContainer>
  );
}

export default requireAuth(ManageGroupsPage, {
  permissions: [Permission.manageCompanyGroups],
});

import { PropsWithChildren } from 'react';
import { styled } from '@mui/material';
import { PageAlertDisplay } from '@components/alerts';
import { Divider } from '@components/layout-util-components/divider';

interface Props {
  showBorderTop?: boolean;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
});

const StyledContainer = styled('div')({
  display: 'flex',
  flex: '0 0 auto',
  justifyContent: 'flex-end',
  gap: 20,
  alignItems: 'center',
});

export default function DrawerFooter({
  children,
  ...props
}: PropsWithChildren<Props>) {
  const { showBorderTop } = props;

  return (
    <Container>
      {showBorderTop ? (
        <Divider addMargin compact negativeMargin={-24} />
      ) : null}
      <PageAlertDisplay ignoreSuccess addMargin />
      <StyledContainer>{children}</StyledContainer>
    </Container>
  );
}

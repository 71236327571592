import {
  NodeCollapseState,
  NodeEndStateType,
  RcaNode,
  RcaNodeType,
} from '@store/rca-editor/types';
import { ReactFlowInstance } from 'reactflow';
import { ChainDetailResponse } from '@api/types/chain/chain-detail.response';
import { GroupedNode, MetaGroupedNodes } from '@store/rca-editor/selectors';

// This isn't technically the react way, but it's the only way to get the react flow instance
// outside of the chart component.

export let reactFlowInstance: ReactFlowInstance | undefined;
export let chartStorageContainer: HTMLDivElement | undefined;

export namespace RcaUtil {
  export const NODE_WIDTH = 350;
  export const NODE_HEIGHT = 102;
  export const HALF_NODE_WIDTH = NODE_WIDTH / 2.0;
  export const HALF_NODE_HEIGHT = NODE_HEIGHT / 2.0;

  export function setReactFlowInstance(instance?: ReactFlowInstance) {
    reactFlowInstance = instance;
  }

  export function setChartStorageContainer(container: HTMLDivElement | null) {
    chartStorageContainer = container ?? undefined;
  }

  export function isPointInStorageContainer(x: number, y: number) {
    if (chartStorageContainer == null) {
      return false;
    }

    const rect = chartStorageContainer.getBoundingClientRect();

    return (
      x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom
    );
  }

  export function isCauseNode(node: RcaNode) {
    return (
      node.type == null ||
      node.type === RcaNodeType.default ||
      node.type === RcaNodeType.endState
    );
  }

  export function isDraggable(node: RcaNode) {
    return !isMetaNode(node);
  }

  export function isMetaNode(node: RcaNode) {
    return isMetaNodeType(node.type);
  }

  export function isMetaNodeType(nodeType?: RcaNodeType) {
    return (
      nodeType === RcaNodeType.or ||
      nodeType === RcaNodeType.andOr ||
      nodeType === RcaNodeType.connection
    );
  }

  export function isAttachable(node: RcaNode) {
    return (
      (node.type == null ||
        node.type === RcaNodeType.default ||
        node.type === RcaNodeType.or ||
        node.type === RcaNodeType.andOr) &&
      node.data?.endState !== NodeEndStateType.linkToChainItem
    );
  }

  export function sortNode(a: RcaNode, b: RcaNode): number {
    if (a.data.sortOrder < b.data.sortOrder) {
      return -1;
    } else if (a.data.sortOrder > b.data.sortOrder) {
      return 1;
    }
    return 0;
  }

  export function sortNodes(nodes: Array<RcaNode>) {
    return [...nodes].sort(sortNode);
  }

  // Iterates through the array of nodes and bump's any node's sortOrder that is above
  // [insertingSortOrder] by 1
  export function incrementInsertedSortOrder(
    nodes: Array<RcaNode>,
    insertingSortOrder: number
  ) {
    console.assert(insertingSortOrder >= 0);

    return nodes.map((node) => {
      if (node.data.sortOrder < insertingSortOrder) {
        return node;
      }
      return {
        ...node,
        data: {
          ...node.data,
          sortOrder: node.data.sortOrder + 1,
        },
      };
    });
  }

  // Iterates nodes and sets their sort order sequentially
  export function setSortOrders(nodes: Array<GroupedNode>) {
    const normalNodes = nodes
      .filter((x) => x.type === 'normal')
      .map((x) => x.node);
    const metaNodes = nodes.filter(
      (x) => x.type === 'meta'
    ) as MetaGroupedNodes[];

    const sortedNormalNodes = [...normalNodes].sort(sortNode);
    const sortedMetaNodes = metaNodes.flatMap((x) => x.children).sort(sortNode);

    return [...sortedNormalNodes, ...sortedMetaNodes].map(
      (node, index): RcaNode => {
        return {
          ...node,
          data: {
            ...node.data,
            sortOrder: index,
          },
        };
      }
    );
  }

  export function isEndStateNode(node: RcaNode) {
    return (
      node.type === RcaNodeType.connection ||
      (node.data.endState != null &&
        node.data.endState !== NodeEndStateType.none)
    );
  }

  export function getNodePosFromCursor(e: any) {
    if (reactFlowInstance == null) {
      throw Error('reactFlowInstance is null');
    }

    return reactFlowInstance.project({
      x: e.clientX - NODE_WIDTH / 2.0,
      y: e.clientY - NODE_HEIGHT,
    });
  }

  export function getHealthStateFromScore(health: number) {
    if (health >= 70) {
      return 'healthy';
    } else if (health >= 30) {
      return 'average';
    }

    return 'unhealthy';
  }

  export function getHealthScoreColorFromScore(health: number) {
    const state = getHealthStateFromScore(health);
    switch (state) {
      case 'healthy':
        return '#ffffff88';
      case 'average':
        return '#ff974a';
      case 'unhealthy':
        return '#ff504a';
    }
  }

  export function focusNode(node: RcaNode, instant = false) {
    reactFlowInstance?.fitView({
      nodes: [node],
      duration: instant ? 0 : 500,
      padding: 5,
    });
  }

  export function populateNodesFromServerData(
    nodes: Array<RcaNode>,
    data: ChainDetailResponse,
    focusedNodeId?: string
  ) {
    // Populate the nodes that don't have a chain item id
    for (const node of nodes) {
      if (node.data.chainItemId == null) {
        const serverData = data.nodes.find((x) => x.id === node.id)?.data;
        if (serverData) {
          const { chainItemId, caseId, endStateId, healthScore } = serverData;
          node.draggable = !node.data.isRoot;
          node.selected = focusedNodeId === node.id;
          node.data = {
            ...node.data,
            chainItemId: chainItemId,
            caseId: caseId,
            endStateId: endStateId,
            healthScore: healthScore,
            highlight: focusedNodeId === node.id,
          };
        }
      }
    }
  }

  export function getNodeCollapsedState(
    node: RcaNode | undefined,
    parent: RcaNode | undefined,
    anscestors: Array<RcaNode>,
    hoverVisibilityNodeId: string | undefined
  ) {
    if (node == null) {
      return NodeCollapseState.default;
    }

    if (parent != null && parent.id === hoverVisibilityNodeId) {
      return NodeCollapseState.ghost;
    }

    const collapseChain = node.data.collapse;
    if (collapseChain) {
      return NodeCollapseState.collapsed;
    }

    if (anscestors.some((x) => x.data.collapse)) {
      return NodeCollapseState.hidden;
    }

    return NodeCollapseState.default;
  }
}

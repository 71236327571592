import { Edge, Node } from 'reactflow';
import { ChainItemResource } from '@api/types/chain/chain-item.resource';

export enum RcaNodeType {
  default = 'default',
  dragHolder = 'drag-holder',
  endState = 'end-state',
  or = 'and', // This was changed to 'Or' visually, but the type is still 'and'
  andOr = 'andOr',
  connection = 'connection',
}

export enum RcaEdgeType {
  default = 'default',
  connection = 'connection',
}

export enum NodeEndStateType {
  none = 'None',
  furtherAnalysis = 'FurtherAnalysis',
  complete = 'AnalysisComplete',
  linkToChainItem = 'LinkToChainItem',
}

export enum NodeCollapseState {
  default = 'collapse-state-default',
  collapsed = 'collapse-state-collapsed',
  ghost = 'collapse-state-ghost',
  hidden = 'collapse-state-hidden',
}

export interface StorageNode extends ChainItemResource {
  clientUuid: string;
}

// These 2 items seem to come back different from the API
export type ChainItemLink = {
  chainItemId: number;
  description: string;
};

export type NodeChainItemLink = {
  id: number;
  label: string;
};

export type ItemLink = NodeChainItemLink;

export interface RcaNodeData {
  label: string;
  chainItemId?: number;
  isRoot: boolean;
  highlight?: boolean;
  sortOrder: number;
  collapse?: boolean;
  disproved?: boolean;
  caseId?: number;
  caseName?: string;
  endStateId?: number;
  endState?: NodeEndStateType;
  healthScore?: number;
  linkedToChainItems?: Array<ItemLink>;
  linkedFromChainItems?: Array<ItemLink>;
}

export interface RcaEdgeData {}

export type RcaNode = Node<RcaNodeData, RcaNodeType>;

export type RcaEdge = Edge<RcaEdgeData>;

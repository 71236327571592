import { styled } from '@mui/material';

export default styled('table', {
  target: 'spaced-table',
})(({ theme: { palette } }) => ({
  border: 'none',
  width: '100%',
  position: 'relative',
  borderCollapse: 'separate',
  borderSpacing: '0 10px',

  thead: {
    th: {
      padding: '6px 16px',
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.17000000178813934px',
      textAlign: 'left',
    },
  },

  tbody: {
    'tr:not(.full-row)': {
      background: palette.common.white,
      border: 'none',
      td: {
        borderBottom: '1px solid #CACACA',
        padding: '8px 0px',

        '&:first-of-type': {
          width: 70,
          padding: '8px 15px',
        },

        '&:last-of-type': {
          padding: '8px 15px',
        },
      },
    },

    '.full-row': {
      zIndex: 1000000,
      width: '100%',
      td: {
        left: 0,
        right: 0,
      },
    },
  },
}));

import SectionScaffold from '@components/outcome-report/components/section-scaffold';
import { Box, styled } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { CaseCompanyUserResource } from '@api/types/case/case-company-user.resource';
import { OutlinedButton } from '@components/buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { SelectedCaseEditTab } from '@pages/app/rca/edit/edit-case-page';
import { getInitials } from '@util/name-util';
import Column from '@components/layout-util-components/column';
import Spacer from '@components/layout-util-components/spacer';
import FlatBadge from '@components/badges/flat-badge';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  teamMembers: CaseCompanyUserResource[];
}

const Container = styled('div')({
  display: 'column',
  alignItems: 'stretch',
  padding: '100px 70px',

  '.title': {
    fontFamily: 'Roboto',
    fontSize: '32px',
    fontWeight: '400',
    lineHeight: '43px',
    letterSpacing: '0px',
    textAlign: 'center',
  },

  '.members-grid': {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '20px',
    alignItems: 'stretch',
  },
});

export default function TeamMembersSection({ teamMembers }: Props) {
  const { caseId } = useParams();
  const navigate = useNavigate();

  const cardProps: Array<UserCardProps> = teamMembers.map((user) => ({
    name: user.name,
    jobTitle: user.jobTitle,
    tag: user.caseRoleName,
    xp: user.kudosPoints,
  }));

  return (
    <SectionScaffold id="team-members" backgroundColor="#ECECEC">
      <Container>
        <Box alignSelf="center">
          <div className="title">Team Members</div>
        </Box>
        <Gap size={50} />
        <div className="members-grid">
          {cardProps.map((user, index) => (
            <UserCard key={index} {...user} />
          ))}
        </div>
        <Gap size={50} />
        <Box display="flex" alignSelf="center" justifyContent="center">
          <OutlinedButton
            onClick={() =>
              navigate(
                `/rca/edit/${caseId}/?initialTab=${SelectedCaseEditTab.collaboration}`
              )
            }
          >
            View Team Members <FontAwesomeIcon icon={faArrowRight} />
          </OutlinedButton>
        </Box>
      </Container>
    </SectionScaffold>
  );
}

interface UserCardProps {
  name: string;
  jobTitle?: string;
  tag: string;
  xp?: number;
}

const UserCardContainer = styled('div')(({ theme: { palette } }) => ({
  padding: 32,
  display: 'flex',
  background: palette.common.grey05,
  borderRadius: 12,
  minHeight: 157,

  '.avatar': {
    width: 88,
    height: 88,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#BBBBBB',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '26px',
    letterSpacing: '0.17000000178813934px',
    textAlign: 'center',
    color: palette.common.black,
  },

  '.name': {
    color: palette.common.black,
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '26px',
    letterSpacing: '0.17000000178813934px',
    textAlign: 'left',
  },

  '.job-title': {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '23px',
    letterSpacing: '0.17000000178813934px',
    textAlign: 'left',
    color: palette.common.grey60,
  },
}));

const UserCard = ({ tag, xp, name, jobTitle }: UserCardProps) => {
  return (
    <UserCardContainer>
      <div className="avatar">{getInitials(name)}</div>
      <Gap size={24} />
      <Column>
        <div className="name">{name}</div>
        {jobTitle && <div className="job-title">{jobTitle}</div>}
        <Spacer />
        <Box display="flex" gap={2} alignItems="center">
          {tag && <FlatBadge rounded>{tag}</FlatBadge>}
          {xp && <FlatBadge rounded>{`${xp}xp`}</FlatBadge>}
        </Box>
      </Column>
    </UserCardContainer>
  );
};

import { Box, styled } from '@mui/material';
import WCTTextField from '@components/input/text-field';
import { useCommonTr } from '@i18n/use-common-tr';
import { Option } from '@api/types/option';
import { ErrorType } from '@api/types/api-error';
import WCTTypeaheadField from '@components/input/typeahead-field';

export interface PhoneNumber {
  dialCode?: number;
  number?: string;
}

interface PhoneNumberFieldProps {
  id: string;
  name?: string;
  label: string;
  dialCodes: Array<Option>;
  required?: boolean;
  value?: PhoneNumber;
  errors?: ErrorType<PhoneNumber>;
  onChange: (value?: PhoneNumber) => void;
  onBlur?: () => void;
}

const StyledContainer = styled('div')({
  display: 'flex',
  '&>.wct-select': {
    maxWidth: 105,
  },
});

export default function WCTPhoneNumberField({
  id,
  name,
  label,
  dialCodes,
  value,
  errors,
  onChange,
  onBlur,
  required,
}: PhoneNumberFieldProps) {
  const { t } = useCommonTr('input');

  return (
    <StyledContainer>
      <WCTTypeaheadField
        id={`${id}-area-code`}
        name={name ? `${name}-code` : undefined}
        label={t('phoneEntry.code')}
        value={dialCodes.find((x) => x.id === value?.dialCode)}
        onChange={(dialCode) => {
          let newValue: number | undefined;
          if (Array.isArray(dialCode)) {
            if (dialCode.length > 0) {
              newValue = dialCode[0].id;
            }
          } else {
            newValue = dialCode?.id;
          }
          onChange({
            ...value,
            dialCode: newValue,
          });
        }}
        options={dialCodes}
      />
      <Box sx={{ width: 10 }} />
      <WCTTextField
        label={label}
        name={name}
        value={value?.number}
        error={errors?.number || errors?.dialCode}
        onChange={(phoneNumber) => {
          onChange({
            ...value,
            number: phoneNumber,
          });
        }}
        onBlur={onBlur}
        required={required}
      />
    </StyledContainer>
  );
}

import Column from '@components/layout-util-components/column';
import EditPanelHeader from '@pages/app/rca/tabs/components/editor-panels/edit-panel-header';
import { LoadingIndicator } from '@components/loading-indicator';
import useImpactsPanel, {
  ImpactsPanelView,
} from '@pages/app/rca/tabs/components/editor-panels/impacts-panel-hook';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';
import { ReactComponent as NoImpactsIcon } from '@assets/svgs/no-tasks.svg';
import SelectImpacts from '@pages/app/rca/tabs/components/editor-panels/components/impacts-panel/select-impacts';
import EnterImpactValues from '@pages/app/rca/tabs/components/editor-panels/components/impacts-panel/enter-impact-values';
import { OutlinedButton } from '@components/buttons';
import SelectedImpactsView from '@pages/app/rca/tabs/components/editor-panels/components/impacts-panel/selected-impacts-view';
import { Gap } from '@components/layout-util-components/gap';

export default function ImpactsPanel() {
  const state = useImpactsPanel();
  const { impacts, isLoading, onBeginAddingImpacts, view } = state;
  const hasImpacts = impacts != null && impacts.length > 0;

  const title = () => {
    switch (view) {
      case ImpactsPanelView.initial:
        return 'Impacts';
      case ImpactsPanelView.selectImpacts:
        return 'Select Impacts';
      case ImpactsPanelView.enterValues:
        return 'Add Impacts';
      default:
        return 'Impacts';
    }
  };

  return (
    <Column>
      <EditPanelHeader
        title={title()}
        count={view === ImpactsPanelView.initial ? impacts?.length : undefined}
        end={
          view === ImpactsPanelView.initial && hasImpacts ? (
            <OutlinedButton onClick={onBeginAddingImpacts}>
              Add Impacts
            </OutlinedButton>
          ) : undefined
        }
      />
      <Gap size={24} />
      {isLoading ? (
        <LoadingIndicator show />
      ) : view === ImpactsPanelView.initial ? (
        !hasImpacts ? (
          <OnboardingEntryContainer
            icon={<NoImpactsIcon />}
            title="There are no impacts currently assigned to this chain item"
            message="You haven’t currently linked any tracked impacts to this cause box, please click below to link impacts"
            createMessage="Select Impact"
            onCreateClicked={onBeginAddingImpacts}
            compact
            dark
          />
        ) : (
          <SelectedImpactsView state={state} />
        )
      ) : view === ImpactsPanelView.selectImpacts ? (
        <SelectImpacts state={state} />
      ) : (
        <EnterImpactValues state={state} />
      )}
    </Column>
  );
}

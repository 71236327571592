import { useMemo, useRef } from 'react';
import { makeSelectDescendantCount } from '@store/rca-editor/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import { RcaNode } from '@store/rca-editor/types';
import { styled } from '@mui/material';
import { setHoverVisibilityNodeId } from '@store/rca-editor/rca-editor-slice';
import { unCollapseNodeAndCollapseImmediateChildren } from '@store/rca-editor/rca-editor-actions';

interface Props {
  node: RcaNode;
}

const HEIGHT = 36;
const HALF_HEIGHT = HEIGHT * 0.5;
const OFFSET = 70;

// noinspection JSSuspiciousNameCombination
const Indicator = styled('button', { target: 'collapsed-node-indicator' })(
  ({ theme: { palette } }) => ({
    zIndex: 1000,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    right: -OFFSET,
    top: '50%',
    transform: 'translateY(-50%)',
    width: HEIGHT,
    height: HEIGHT,
    borderRadius: HEIGHT * 0.25,
    outline: 'none',
    border: 'none',
    background: palette.common.grey90,
    color: 'white',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    letterSpacing: '-0.8500000238418579px',
    textAlign: 'left',
  })
);

export default function CollapsedNodeIndicator({ node }: Props) {
  const { id } = node;

  const indicatorRef = useRef<HTMLButtonElement>(null);
  const dispatch = useAppDispatch();

  const selectDescendantCount = useMemo(
    () => makeSelectDescendantCount(id),
    [id]
  );

  const descendantCount = useAppSelector(selectDescendantCount);
  if (descendantCount === 0) {
    return <></>;
  }

  return (
    <>
      <Indicator
        ref={indicatorRef}
        type="button"
        onMouseEnter={() => {
          dispatch(setHoverVisibilityNodeId(id));
        }}
        onMouseOver={(e) => {
          e.stopPropagation();
        }}
        onMouseLeave={(e) => {
          dispatch(setHoverVisibilityNodeId(undefined));
        }}
        onClick={() => dispatch(unCollapseNodeAndCollapseImmediateChildren(id))}
      >
        <svg
          overflow="visible"
          style={{ position: 'absolute' }}
          height="36"
          width="36"
        >
          <line
            x1={0}
            y1={HALF_HEIGHT}
            x2={-OFFSET + 33}
            y2={HALF_HEIGHT}
            style={{ stroke: 'rgba(0,0,0)', strokeWidth: 2 }}
          />
        </svg>
        {descendantCount}
      </Indicator>
    </>
  );
}

import { ComponentType } from 'react';
import { Drawer, DrawerProps, styled } from '@mui/material';
import { UiPopupProps } from '@components/ui-popup/ui-popup-type';

export interface SideDrawerFormProps<T> extends UiPopupProps<T> {}

const StyledDrawer = styled(Drawer)<DrawerProps>({
  '&>.MuiDrawer-paper': {
    padding: '40px 52px',
    width: 620,
    height: '100vh',
    display: 'flex',
    flexDirection: ' column',
    alignItems: 'stretch',
  },
});

export default function withSideDrawer<
  P extends SideDrawerFormProps<T>,
  T = any
>(Component: ComponentType<P>) {
  return (props: P) => {
    const { show, onClose } = props;

    return (
      <StyledDrawer
        anchor="right"
        open={show}
        onClose={() => onClose()}
        hideBackdrop
        variant="temporary"
      >
        <Component {...props} />
      </StyledDrawer>
    );
  };
}

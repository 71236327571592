import { CaseOutcomeListItemResponse } from '@api/types/case/case-outcome/case-outcome-list-item.response';
import { ReactNode } from 'react';
import { styled, Typography } from '@mui/material';
import Row from '@components/layout-util-components/row';
import Spacer from '@components/layout-util-components/spacer';
import { ElipsisButton } from '@components/buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import LabelValueDisplay from '@components/data/label-value-display';
import { dateOnlyShortFormatter } from '@i18n/formatters';
import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  outcomes: Array<CaseOutcomeListItemResponse>;
  action: ReactNode;

  onEditOutcome: (outcomeId: number) => void;
  onDeleteOutcome: (outcomeId: number) => void;
  onViewOutcome: (outcomeId: number) => void;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  width: '100%',

  table: {
    // remove border between cells
    borderCollapse: 'separate',
    borderSpacing: '0 4px',

    td: {
      padding: '24px 20px',
      backgroundColor: '#F4F4F4',
    },
  },
});

export default function OutcomesData({
  outcomes,
  action,
  onEditOutcome,
  onDeleteOutcome,
  onViewOutcome,
}: Props) {
  return (
    <Container>
      <Row>
        <Typography variant="h4">Outcomes ({outcomes.length})</Typography>
        <Spacer />
        {action}
      </Row>
      <Gap size={15} />
      <table>
        <tbody>
          {outcomes.map((outcome) => (
            <tr key={outcome.outcomeId}>
              <td width="40%">{outcome.name}</td>
              <td width="20%">
                <Row>
                  <FontAwesomeIcon icon={faCalendar} />
                  <Gap size={10} />
                  <LabelValueDisplay
                    label="CREATED"
                    value={dateOnlyShortFormatter(outcome.created)}
                  />
                </Row>
              </td>
              <td width="20%">
                <InitialsContainerWithData
                  label="AUTHOR"
                  fullName={outcome.createdBy}
                />
              </td>
              <td width="20%">
                <Row contentAtEnd>
                  <ElipsisButton
                    actions={[
                      {
                        label: 'View',
                        onClick: () => onViewOutcome(outcome.outcomeId),
                      },
                      {
                        label: 'Edit',
                        onClick: () => onEditOutcome(outcome.outcomeId),
                      },
                      {
                        label: 'Delete',
                        onClick: () => onDeleteOutcome(outcome.outcomeId),
                      },
                    ]}
                  />
                </Row>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
}

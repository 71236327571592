import SectionScaffold from '@components/outcome-report/components/section-scaffold';
import { styled } from '@mui/material';
import Row from '@components/layout-util-components/row';
import Spacer from '@components/layout-util-components/spacer';
import { RunReportResponse } from '@api/types/case/case-report/run-report.response';
import DonutChart from 'react-donut-chart';
import { getColorForId } from '@util/colour-identifiers';
import { Circle } from '@mui/icons-material';
import FlatBadge from '@components/badges/flat-badge';
import { numberFromString } from '@util/string-util';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  themeCoverageReport: RunReportResponse<
    {
      themeId: number;
      chainItems: Record<string, string>;
      rank: number;
    },
    {
      chainItemCount: number;
      coveragePercent: number;
      coverageShare: Array<number>;
    }
  >;
}

const Container = styled('div')(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  padding: '110px 10%',

  '.overview-container': {
    margin: '0 20%',

    '.section-title': {
      fontFamily: 'Roboto',
      fontSize: '28px',
      fontWeight: '500',
      lineHeight: '42px',
      letterSpacing: '0.15000000596046448px',
      textAlign: 'center',
      color: palette.common.white,
    },

    '.section-overview': {
      fontFamily: 'Roboto',
      fontSize: '20px',
      fontWeight: '400',
      lineHeight: '31px',
      letterSpacing: '0.15000000596046448px',
      textAlign: 'center',
      color: '#E3E3E3',
    },
  },

  '.insights-table': {
    minWidth: '50%',
    borderCollapse: 'seperate',
    borderSpacing: '0 8px',

    th: {
      margin: 0,
      padding: '24px 12px',
      fontFamily: 'Roboto',
      fontSize: '18px',
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing: '0px',
      textAlign: 'left',
      backgroundColor: '#FFFFFF1A',
      color: palette.common.white,

      '&:first-child': {
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
      },

      '&:last-child': {
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
      },
    },

    td: {
      margin: 0,
      padding: '28px 0 28px 32px',
      backgroundColor: palette.common.white,

      '&:first-child': {
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
      },

      '&:last-child': {
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
      },
    },
  },
}));

export default function InsightsSection({ themeCoverageReport }: Props) {
  const stats: Array<RadialOverviewStat> = themeCoverageReport.metaData.map(
    (data, index) => {
      const percentage = themeCoverageReport.totals.coverageShare[index] ?? 0;
      return {
        id: index,
        rank: data.rank,
        name: '!!TBD!!',
        percentage,
      };
    }
  );

  const rows: Array<InsightRowProps> = themeCoverageReport.metaData.map(
    (data, index) => {
      const percentage = themeCoverageReport.totals.coverageShare[index] ?? 0;
      const row = themeCoverageReport.data[index];
      const causeBoxCount = numberFromString(row?.cells[0]?.value) ?? 0;

      return {
        id: index,
        rank: data.rank,
        name: '!!TBD!!',
        coverage: percentage,
        causeBoxCount,
      };
    }
  );

  return (
    <SectionScaffold id="insights" backgroundColor="#5E5E5E">
      <Container>
        <div className="overview-container">
          <div className="section-title">INSIGHTS - THEME COVERAGE</div>
          <Gap size={24} />
          <div className="section-overview">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed aliquet
            id libero eu laoreet. Sed congue eros vel nisl sagittis, lacinia
            condimentum urna malesuada. Mauris tincidunt quis velit non maximus.
          </div>
        </div>
        <Gap size={64} />
        <Row>
          <ReportRadialOverview
            name="Theme Coverage"
            totalPercentage={themeCoverageReport.totals.coveragePercent}
            stats={stats}
          />
          <Spacer />
          <table className="insights-table">
            <thead>
              <tr>
                <th></th>
                <th>Theme</th>
                <th>Cause boxes</th>
                <th>Coverage (%)</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => (
                <InsightRow key={row.id} {...row} />
              ))}
            </tbody>
          </table>
        </Row>
      </Container>
    </SectionScaffold>
  );
}

interface RadialOverviewStat {
  id: number;
  name: string;
  percentage: number;
  rank: number;
}

interface ReportRadialOverviewProps {
  name: string;
  totalPercentage: number;
  stats: Array<RadialOverviewStat>;
}

const DONUT_SIZE = 474;
const ReportRadialOverviewContainer = styled('div')(
  ({ theme: { palette } }) => ({
    '.donut-container': {
      position: 'relative',

      '.donut-chart': {
        maxHeight: DONUT_SIZE,
      },

      '.chart-title-container': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',

        '.chart-title': {
          fontFamily: 'Roboto',
          fontSize: '22px',
          fontWeight: '600',
          lineHeight: '29px',
          letterSpacing: '0px',
          textAlign: 'center',
          textTransform: 'uppercase',
        },

        '.chart-percentage': {
          fontFamily: 'Roboto',
          fontWeight: '300',
          letterSpacing: '0px',
          fontSize: '80px',
          lineHeight: '107px',
          textAlign: 'center',
          color: palette.common.white,

          '>.small': {
            fontSize: '40px',
            lineHeight: '53px',
          },
        },
      },
    },
  })
);

const ReportRadialOverview = ({
  name,
  totalPercentage,
  stats: nonFilteredStats,
}: ReportRadialOverviewProps) => {
  const stats = nonFilteredStats.filter((x) => x.id !== -1);

  return (
    <ReportRadialOverviewContainer>
      <Row>
        <div className="donut-container">
          <DonutChart
            width={DONUT_SIZE}
            height={DONUT_SIZE}
            legend={false}
            interactive={false}
            strokeColor="transparent"
            className="donut-chart"
            colorFunction={(_, index) => getColorForId(index)}
            data={stats.map((stat) => ({
              label: stat.name,
              value: stat.percentage,
            }))}
          />
          <div className="chart-title-container">
            <div className="chart-title">{name}</div>
            <div className="chart-percentage">
              {totalPercentage}
              <span className="small">%</span>
            </div>
          </div>
        </div>
      </Row>
    </ReportRadialOverviewContainer>
  );
};

interface InsightRowProps {
  id: number;
  rank: number;
  name: string;
  causeBoxCount: number;
  coverage: number;
}

const InsightRowContainer = styled('tr')(({ theme: { palette } }) => ({
  '.rank': {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: palette.common.black,
  },

  '.name': {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: palette.common.black,
  },

  '.coverage': {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: palette.common.black,
  },
}));

const InsightRow = ({
  rank,
  name,
  id,
  causeBoxCount,
  coverage,
}: InsightRowProps) => {
  return (
    <InsightRowContainer>
      <td>
        <div className="rank">{rank}</div>
      </td>
      <td>
        <Row>
          <Circle htmlColor={getColorForId(id)} />
          <Gap size={12} />
          <div className="name">{name}</div>
        </Row>
      </td>
      <td>
        <FlatBadge rounded>{causeBoxCount}</FlatBadge>
      </td>
      <td>
        <div className="coverage">{coverage}%</div>
      </td>
    </InsightRowContainer>
  );
};

import { useGetReportSubtypeOptionsQuery } from '@api/endpoints/report-type.api';
import { useMemo } from 'react';
import {
  useGetCaseEvidenceQuery,
  useGetCaseSolutionsQuery,
} from '@api/endpoints/case.api';
import { useAppSelector } from '@store/store';
import { selectCurrentRcaCaseId } from '@store/rca-editor/selectors';
import { ReportSubType } from '@api/types/report-type/report-subtype.option';
import { useGetThemeOptionsQuery } from '@api/endpoints/theme.api';
import { useGetCaseImpactTypesQuery } from '@api/endpoints/case-impact-type.api';

export interface ReportTypeListItem {
  id: number;
  name: string;
}

export default function useReportTypeOptions(
  reportTypeId: number,
  selectedSubTypeId?: number
) {
  const { data: reportSubTypeOptions } =
    useGetReportSubtypeOptionsQuery(reportTypeId);

  const selectedSubTypeOption = useMemo(() => {
    if (reportSubTypeOptions) {
      return reportSubTypeOptions.find(
        (option) => option.reportSubTypeId === selectedSubTypeId
      );
    }
  }, [reportSubTypeOptions, selectedSubTypeId]);

  const reportSubType = selectedSubTypeOption?.reportSubType;
  const caseId = useAppSelector(selectCurrentRcaCaseId);

  const { data: evidence, isLoading: loadingEvidence } =
    useGetCaseEvidenceQuery(
      {
        caseId,
      },
      { skip: reportSubType !== ReportSubType.evidence }
    );

  const { data: caseImpacts, isLoading: loadingImpacts } =
    useGetCaseImpactTypesQuery(caseId, {
      skip: reportSubType !== ReportSubType.impact,
    });

  const { data: solutions, isLoading: loadingSolutions } =
    useGetCaseSolutionsQuery(
      { caseId },
      {
        skip: reportSubType !== ReportSubType.solution,
      }
    );

  const { data: themes, isLoading: loadingThemes } = useGetThemeOptionsQuery(
    caseId,
    {
      skip: reportSubType !== ReportSubType.theme,
    }
  );

  const isLoading =
    loadingEvidence || loadingImpacts || loadingSolutions || loadingThemes;

  const name = useMemo(() => {
    return selectedSubTypeOption?.name ?? '';
  }, [selectedSubTypeOption?.name]);

  const options = useMemo((): Array<ReportTypeListItem> => {
    if (reportSubType == null || isLoading) {
      return [];
    }

    switch (reportSubType) {
      case ReportSubType.evidence:
        return (
          evidence!.model.map((x) => ({ id: x.evidenceId, name: x.name })) ?? []
        );
      case ReportSubType.impact:
        return (
          caseImpacts!.map((x) => ({
            id: x.caseImpactTypeId,
            name: x.name,
          })) ?? []
        );
      case ReportSubType.solution:
        return (
          solutions!.model.map((x) => ({ id: x.solutionId, name: x.name })) ??
          []
        );
      case ReportSubType.theme:
        return themes!.map((x) => ({ id: x.id, name: x.label })) ?? [];
      default:
        return [];
    }
  }, [caseImpacts, evidence, isLoading, reportSubType, solutions, themes]);

  return useMemo(
    () => ({
      isLoading,
      name,
      options,
    }),
    [isLoading, name, options]
  );
}

import { api } from '@api/api';
import { makeError } from '@api/types/api-error';
import { ChainItemNoteResource } from '@api/types/chain/chain-item-notes/chain-item-note.resource';
import { CreateChainItemNoteRequest } from '@api/types/chain/chain-item-notes/create-chain-item-note.request';
import { ChainItemNoteUpdateRequest } from '@api/types/chain/chain-item-notes/chain-item-note-update.request';
import { ChainItemNoteDeleteRequest } from '@api/types/chain/chain-item-notes/chain-item-note-delete.request';

export interface ChainItemCaseImpactPathParams {
  chainId: number;
  chainItemId: number;
}

const chainItemNotesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getChainItemNotes: build.query<
      Array<ChainItemNoteResource>,
      ChainItemCaseImpactPathParams
    >({
      query: ({ chainId, chainItemId }) => ({
        url: `chain/${chainId}/chainItemNote/${chainItemId}`,
        method: 'GET',
      }),
      providesTags: ['ChainItemNote'],
    }),
    createChainItemNote: build.mutation<
      ChainItemNoteResource,
      CreateChainItemNoteRequest
    >({
      query: ({ chainId, chainItemId, ...body }) => ({
        url: `chain/${chainId}/chainItemNote/${chainItemId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, error, __) =>
        !error ? ['ChainItemNote', 'CaseTotals'] : [],
      transformErrorResponse: (error) =>
        makeError<CreateChainItemNoteRequest>(error),
    }),
    updateChainItemNote: build.mutation<void, ChainItemNoteUpdateRequest>({
      query: ({ chainId, chainItemId, ...body }) => ({
        url: `chain/${chainId}/chainItemNote/${chainItemId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, error, __) =>
        !error ? ['ChainItemNote', 'CaseTotals'] : [],
      transformErrorResponse: (error) =>
        makeError<ChainItemNoteUpdateRequest>(error),
    }),
    deleteChainItemNote: build.mutation<void, ChainItemNoteDeleteRequest>({
      query: ({ chainId, chainItemId, chainItemNoteId }) => ({
        url: `chain/${chainId}/chainItemNote/${chainItemId}/${chainItemNoteId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, error, __) =>
        !error ? ['ChainItemNote', 'CaseTotals'] : [],
      transformErrorResponse: (error) =>
        makeError<ChainItemNoteDeleteRequest>(error),
    }),
  }),
});

export const {
  useGetChainItemNotesQuery,
  useCreateChainItemNoteMutation,
  useUpdateChainItemNoteMutation,
  useDeleteChainItemNoteMutation,
} = chainItemNotesApi;

export default chainItemNotesApi;

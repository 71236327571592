import { createBrowserRouter } from 'react-router-dom';
import HomePage from '@pages/dashboard';
import AuthLayout from '@components/layouts/auth-layout';
import LoginPage from '@pages/auth/login-page';
import ForgotPasswordPage from '@pages/auth/forgot-password-page';
import ResetPasswordPage from '@pages/auth/reset-password-page';
import AdminLayout from '@components/layouts/admin-layout';
import CompaniesIndexPage from '@pages/admin/companies/companies-index-page';
import WctUsersIndexPage from '@pages/admin/users/wct-users-index-page';
import MfaPage from '@pages/auth/mfa-page';
import LoginErrorPage from '@pages/auth/login-error-page';
import CreatePasswordPage from '@pages/auth/create-password-page';
import CreateAccountProgressPage from '@pages/auth/create-account-progress-page';
import App from 'src/app';
import LogoutCapturePage from '@pages/auth/logout-capture-page';
import ErrorRouteCatcher from '@components/error-handling/error-route-catcher';

const adminRoutes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorRouteCatcher />,
    children: [
      {
        path: 'companies',
        element: <AdminLayout />,
        errorElement: <ErrorRouteCatcher />,
        children: [{ index: true, element: <CompaniesIndexPage /> }],
      },
      {
        path: 'users',
        element: <AdminLayout />,
        errorElement: <ErrorRouteCatcher />,
        children: [{ index: true, element: <WctUsersIndexPage /> }],
      },
      {
        path: 'auth',
        element: <AuthLayout />,
        errorElement: <ErrorRouteCatcher />,
        children: [
          {
            path: 'login',
            element: <LoginPage />,
          },
          { path: 'logout', element: <LogoutCapturePage /> },
          {
            path: 'login-error',
            element: <LoginErrorPage />,
          },
          {
            path: 'mfa',
            element: <MfaPage />,
          },
          {
            path: 'forgot-password',
            element: <ForgotPasswordPage />,
          },
          {
            path: 'reset-password',
            element: <ResetPasswordPage />,
          },
          {
            path: 'create-password',
            element: <CreatePasswordPage />,
          },
          {
            path: 'create-account-progress',
            element: <CreateAccountProgressPage />,
          },
        ],
      },
      {
        path: '/',
        element: <AdminLayout />,
        errorElement: <ErrorRouteCatcher />,
        children: [{ index: true, element: <HomePage /> }],
      },
    ],
  },
]);

export default adminRoutes;

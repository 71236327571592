import { api } from '@api/api';
import { PaginatedResult } from '@api/types/paginated-result';
import { ChainItemSolutionResource } from '@api/types/chain/chain-item-solution/chain-item-solution.resource';
import { CreateChainItemSolutionRequest } from '@api/types/chain/chain-item-solution/create-chain-item-solution.request';
import { makeError } from '@api/types/api-error';
import { ChainItemSolutionRequest } from '@api/types/chain/chain-item-solution/chain-item-solution.request';
import { DeleteChainItemSolutionRequest } from '@api/types/chain/chain-item-solution/delete-chain-item-solution.request';
import { UpdateChainItemSolutionRequest } from '@api/types/chain/chain-item-solution/update-chain-item-solution.request';

export interface ChainItemSolutionPathParams {
  chainId: number;
  chainItemId: number;
}

const chainItemSolutionApi = api.injectEndpoints({
  endpoints: (build) => ({
    getChainItemSolutions: build.query<
      PaginatedResult<ChainItemSolutionResource>,
      ChainItemSolutionRequest
    >({
      query: ({ chainId, chainItemId, ...params }) => ({
        url: `chain/${chainId}/chainItemSolution/${chainItemId}`,
        method: 'GET',
        params,
      }),
      providesTags: ['Solution'],
    }),
    createChainItemSolution: build.mutation<
      ChainItemSolutionResource,
      CreateChainItemSolutionRequest
    >({
      query: ({ chainId, ...body }) => ({
        url: `chain/${chainId}/chainItemSolution`,
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<CreateChainItemSolutionRequest>(error, {
          chainItemSolutionId: 'solutionId',
        }),
      invalidatesTags: ['Solution', 'CaseTotals'],
    }),
    updateChainItemSolution: build.mutation<
      void,
      UpdateChainItemSolutionRequest
    >({
      query: ({ chainId, ...body }) => ({
        url: `chain/${chainId}/chainItemSolution`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Solution'],
    }),
    deleteChainItemSolution: build.mutation<
      void,
      DeleteChainItemSolutionRequest
    >({
      query: ({ chainId, solutionId }) => ({
        url: `chain/${chainId}/chainItemSolution/${solutionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Solution', 'CaseTotals'],
    }),
  }),
});

export const {
  useGetChainItemSolutionsQuery,
  useCreateChainItemSolutionMutation,
  useUpdateChainItemSolutionMutation,
  useDeleteChainItemSolutionMutation,
} = chainItemSolutionApi;

export default chainItemSolutionApi;

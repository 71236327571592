import {
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
} from '@api/endpoints/user.api';
import { useGetDialCodesQuery } from '@api/endpoints/country.api';
import useField from '@hooks/use-field-hook';
import { phoneNumber, required, validEmail } from '@util/validators';
import { PhoneNumber } from '@components/input/phone-number-field';
import { useEffect, useMemo } from 'react';
import { ApiError } from '@api/types/api-error';
import { UpdateUserProfileRequest } from '@api/types/user/update-user-profile.request';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { usePageAlertVariants } from '@components/alerts';
import { useGetCompanyLocationOptionsQuery } from '@api/endpoints/company-location.api';
import { useAppTr } from '@i18n/use-app-tr';
import { Permission } from '@api/types/permission';
import usePermission from '@store/user/permission-hook';

export default function usePersonalDetails() {
  const { t } = useAppTr('personalDetails');
  const { showErrorMessage, showSuccessMessage, hideAlert } =
    usePageAlertVariants();

  const canAddCompanyLocation = usePermission(
    Permission.manageCompanyLocations
  );

  const { data: profile, isLoading: loadingProfile } = useGetUserProfileQuery();

  const { data: dialCodes, isLoading: loadingDialCodes } =
    useGetDialCodesQuery(true);

  const { data: workLocations, isLoading: loadingWorkLocations } =
    useGetCompanyLocationOptionsQuery();

  const [updateProfile, { isLoading: isSubmitting }] =
    useUpdateUserProfileMutation();

  const firstName = useField<string>([required()], profile?.firstName);
  const surname = useField<string>([required()], profile?.lastName);
  const email = useField<string>([required(), validEmail()], profile?.email);
  const jobTitle = useField<string>([], profile?.companyJobTitle);
  const location = useField<number>([], profile?.companyLocationId);
  const phone = useField<PhoneNumber | undefined>(
    phoneNumber({ when: () => false }),
    useMemo(
      () => ({
        dialCode: profile?.countryId,
        number: profile?.phoneNumber,
      }),
      [profile?.countryId, profile?.phoneNumber]
    )
  );

  const { isValid, isDirty, validateAll } = useFieldsWatcher([
    firstName,
    surname,
    email,
    jobTitle,
    location,
    phone,
  ]);

  const isLoading = loadingProfile || loadingDialCodes || loadingWorkLocations;
  const canSubmit = !isLoading && !isSubmitting && isValid && isDirty;

  useEffect(() => {
    return () => hideAlert();
  }, [hideAlert]);

  const submit = async (
    phone2faCode?: string,
    emailChangeCode?: string
  ): Promise<boolean> => {
    if (!validateAll()) {
      return false;
    }

    return updateProfile({
      firstName: firstName.value,
      lastName: surname.value,
      emailAddress: email.value,
      emailAddressConfirmationCode: emailChangeCode,
      phoneNumber: phone.value!.number,
      countryDialCode: phone.value!.dialCode!,
      phoneNumberConfirmationCode: phone2faCode,
      companyJobTitle: jobTitle.value,
      companyLocationID: location.value,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage(t('toasts.success'));
        return true;
      })
      .catch(({ message, errors }: ApiError<UpdateUserProfileRequest>) => {
        showErrorMessage(message);

        firstName.setError(errors?.firstName);
        surname.setError(errors?.lastName);
        jobTitle.setError(errors?.companyJobTitle);
        location.setError(errors?.companyLocationID);
        email.setError(
          errors?.emailAddressConfirmationCode || errors?.emailAddress
        );
        phone.setError(
          errors?.phoneNumberConfirmationCode ||
            errors?.phoneNumber ||
            errors?.countryDialCode
        );

        return false;
      });
  };

  return {
    dialCodes,
    workLocations,
    isLoading,
    isSubmitting,
    canSubmit,
    submit,
    firstName,
    surname,
    email,
    phone,
    jobTitle,
    location,
    canAddCompanyLocation,
  };
}

import { dateOnlyLongFormatter } from '@i18n/formatters';

export const isActivityItemSameDayAsNext = ({
  activity,
  activities,
  idx,
}: {
  activity;
  activities;
  idx;
}) => {
  const nextActivity = activities[idx - 1];

  if (!nextActivity) {
    return false;
  }

  if (
    dateOnlyLongFormatter(activity.eventDate) ===
    dateOnlyLongFormatter(nextActivity.eventDate)
  ) {
    return true;
  }

  return false;
};

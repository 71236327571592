import {
  FormControlLabel,
  FormGroup,
  FormLabel,
  styled,
  Switch,
} from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  name?: string;
  label?: ReactNode;
  value: boolean;
  error?: string;
  onChange: (value: boolean) => void;
  onBlur?: () => void;
}

interface SwitchContainerProps {
  variant?: 'light' | 'grey';
}

export const SwitchContainer = styled('div')<SwitchContainerProps>(
  ({ variant = 'grey' }) => ({
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 auto',
    padding: 20,
    background: variant === 'light' ? '#FFF' : 'rgba(0, 0, 0, 0.2)',
  })
);

export default function WCTSwitchField({
  onBlur,
  value,
  name,
  onChange,
  error,
  label,
}: Props) {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            name={name}
            checked={value}
            onChange={(_, v) => onChange(v)}
            onBlur={onBlur}
          />
        }
        label={label}
      />
      {error ? <FormLabel error>{error}</FormLabel> : null}
    </FormGroup>
  );
}

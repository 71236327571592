import useField from '@hooks/use-field-hook';
import {
  lessThan,
  required,
  requiredAddress,
  validatorSelector,
  validPostcode,
} from '@util/validators';
import { Address } from '@components/input/address-field';
import { useGetCountryNamesQuery } from '@api/endpoints/country.api';
import { useGetLocationForCompanyQuery } from '@api/endpoints/company/company-location.api';
import useAddressMemo from '@hooks/use-address-hook';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { usePageAlertVariants } from '@components/alerts';
import { ApiError, ErrorType } from '@api/types/api-error';
import { CreateCompanyLocationRequest } from '@api/types/company/company-location/create-company-location.request';
import { CompanyLocationResource } from '@api/types/company/company-location/company-location.resource';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { MutationDefinition } from '@reduxjs/toolkit/query';
import { useState } from 'react';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { getGoogleLocationForString } from '@util/address-util';

export default function useFunctionalLocationForm(
  useCreate: UseMutation<
    MutationDefinition<CreateCompanyLocationRequest, any, any, any>
  >,
  functionalLocationId?: number
) {
  const isEdit = !!functionalLocationId;

  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const [trackLocationName, setTrackLocationName] = useState<boolean>();
  const [shouldUseMap, setShouldUseMap] = useState<boolean>(false);

  const { data: countryOptions, isLoading: loadingCountryOptions } =
    useGetCountryNamesQuery(false);

  const { data, isLoading: loadingData } = useGetLocationForCompanyQuery(
    functionalLocationId as number,
    { skip: !isEdit }
  );

  const { showConfirmationModal } = useUiPopup();
  const [createLocation, { isLoading: isSubmittingCreate }] = useCreate();

  const title = useField<string>([required(), lessThan(51)], data?.name);
  const description = useField<string>([], data?.description);
  const address = useField<Address>(
    [
      requiredAddress({ when: () => !trackLocationName }),
      validatorSelector('postcode', [validPostcode()]),
    ],
    useAddressMemo({
      addressLineOne: data?.addressLineOne,
      addressLineTwo: data?.addressLineTwo,
      country: data?.countryName,
      countryId: data?.countryId,
      county: data?.county,
      town: data?.town,
      postcode: data?.postcode,
    })
  );

  const { isValid, isDirty, resetAll, validateAll } = useFieldsWatcher([
    title,
    description,
    address,
  ]);

  const isLoading = loadingCountryOptions || loadingData;
  const canSubmit = !isSubmittingCreate && !isLoading && isDirty && isValid;
  const isSubmitting = isSubmittingCreate;

  const showOptionToSelectOnMap = () => {
    showConfirmationModal({
      title: 'Warning: Address cannot be found on Google Maps',
      message: [
        'We are unable to location the address you have entered.',
        // eslint-disable-next-line quotes
        "Please click below to search for the location and 'Pin on Map'.",
      ],
      yesButtonLabel: 'Select on map',
      noButtonLabel: 'Change address',
      yesButtonAsyncAction: async () =>
        getGoogleLocationForString(address.value).then((gLocation) => {
          if (gLocation) {
            const location = gLocation.geometry.location;
            address.value.latitude = location.lat();
            address.value.longitude = location.lng();
          }

          setShouldUseMap(true);

          return true;
        }),
    });
  };

  const submit = async (
    addAnother: boolean
  ): Promise<CompanyLocationResource | null> => {
    if (!validateAll()) {
      return null;
    }

    if (!isEdit) {
      return createLocation({
        name: title.value,
        description: description.value,
        longitude: address.value.longitude,
        latitude: address.value.latitude,
        addressLineOne: address.value.addressLineOne!,
        addressLineTwo: address.value.addressLineTwo,
        town: address.value.town,
        county: address.value.county,
        countryId: address.value.countryId!,
        postcode: address.value.postcode!,
      })
        .unwrap()
        .then((result) => {
          showSuccessMessage(
            'You have successfully created a new company location'
          );
          if (addAnother) {
            resetAll();
          }
          return result;
        })
        .catch(
          ({ message, errors }: ApiError<CreateCompanyLocationRequest>) => {
            const latLongError = errors?.latitude || errors?.longitude;

            if (!latLongError) {
              showErrorMessage(message);
            } else {
              showOptionToSelectOnMap();
            }

            title.setError(errors?.name);
            description.setError(errors?.description);

            const addressErrors: ErrorType<Address> = {
              addressLineOne: errors?.addressLineOne,
              addressLineTwo: errors?.addressLineTwo,
              county: errors?.county,
              town: errors?.town,
              countryId: errors?.countryId,
              postcode: errors?.postcode,
              country: errors?.countryId,
            };
            address.setError(addressErrors);

            return null;
          }
        );
    }

    return null;
  };

  return {
    isEdit,
    isLoading,
    canSubmit,
    isSubmitting,
    countryOptions,
    title,
    description,
    address,
    submit,
    shouldUseMap,
    setShouldUseMap,
    trackLocationName,
    setTrackLocationName,
  };
}

import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Chip,
  Box,
  SxProps,
  Theme,
  IconButton,
  styled,
} from '@mui/material';
import { IHelpCentreContent } from '@api/types/help-center/help-center-content';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

const StyledArticleHeaderWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

interface Props {
  sx?: SxProps<Theme>;
  articles: IHelpCentreContent[];
  label?: string;
  showAll?: boolean;
  direction?: 'column' | 'row';
}

export const Articles = ({
  sx,
  articles,
  label = 'Articles',
  direction = 'column',
  showAll = true,
}: Props) => {
  const navigate = useNavigate();

  if (!articles.length) {
    return null;
  }

  return (
    <Box sx={sx}>
      <StyledArticleHeaderWrapper>
        <h2>{label}</h2>
        {showAll && (
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <Typography fontWeight={500}>View All</Typography>
            <IconButton
              onClick={() => navigate('article')}
              aria-label="View all"
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </IconButton>
          </Box>
        )}
      </StyledArticleHeaderWrapper>
      <Grid mb={6} container spacing={2}>
        {articles.map((article) => (
          <Grid
            item
            xs={direction === 'column' ? 4 : 12}
            key={`article${article.helpCentreContentId}`}
          >
            <Card sx={{ p: 2, display: 'flex' }}>
              <Box sx={{ display: 'flex', flexDirection: direction }}>
                <CardMedia
                  sx={{ height: 140, width: 200 }}
                  image={article.mediaUrl}
                  title={article.title}
                  onClick={() =>
                    navigate(
                      `/help-centre/article/${article.helpCentreContentId}`
                    )
                  }
                />
                <Box>
                  <StyledCardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {article.title}
                    </Typography>
                  </StyledCardContent>
                  <CardActions>
                    {article.categories.map((category) => (
                      <Chip
                        size="small"
                        key={`article-category-${category.id}`}
                        label={category.label}
                      />
                    ))}
                  </CardActions>
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

import { Popover, PopoverProps, styled, Typography } from '@mui/material';
import useUser from '@store/user/user-hook';
import { ReactComponent as ChevronDownItem } from '@assets/svgs/chevron-down.svg';
import { useRef, useState } from 'react';
import { useGetUserProfileQuery } from '@api/endpoints/user.api';
import Row from '@components/layout-util-components/row';
import Spacer from '@components/layout-util-components/spacer';
import { ReactComponent as ChevronLeft } from '@assets/svgs/chevron-left.svg';
import { ReactComponent as Tasks } from '@assets/svgs/tasks.svg';
import { ReactComponent as Logout } from '@assets/svgs/logout.svg';
import { ReactComponent as Lock } from '@assets/svgs/lock.svg';
import { Divider } from '@components/layout-util-components/divider';
import { useNavigate } from 'react-router-dom';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import MenuItemInitialsContainer from '@components/menu-items/app/menu-item-initials-container';

interface Props {
  isSideDrawerOpen?: boolean;
}

const Container = styled('div', { target: 'menu-item-profile' })<{
  isOpen: boolean;
}>(({ isOpen }) => ({
  display: 'flex',
  padding: '15px 28px',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 15,
  alignSelf: 'stretch',
  ...(isOpen && {
    background:
      'linear-gradient(90deg, rgba(238, 230, 227, 0.60) 0%, rgba(218, 214, 215, 0.60) 100%)',
  }),
}));

const StyledPopover = styled(Popover)<PopoverProps>({
  '&>.MuiPaper-root': {
    minWidth: 306,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: 20,
  },
});

const ItemRow = styled('div')<{ noBottomMargin?: boolean }>(
  ({ noBottomMargin, theme: { palette } }) => ({
    display: 'flex',
    gap: 10,
    margin: `0 0 ${noBottomMargin ? 0 : 5}px 0`,
    padding: '10px 20px',
    alignSelf: 'stretch',
    cursor: 'pointer',
    backgroundColor: palette.common.grey05,
    transition: 'background-color 600ms ease',
    borderTop: `1px solid ${palette.common.grey30}`,

    '&:hover': {
      backgroundColor: '#ddd',
    },

    '& > svg': {
      flex: '0 0 auto',
      height: 'auto',
      width: 18,
    },
  })
);

const StyledChevronLeft = styled(ChevronLeft)({
  '&:hover': {
    cursor: 'pointer',
  },
});

export default function MenuItemProfile({ isSideDrawerOpen }: Props) {
  const navigate = useNavigate();
  const anchorEl = useRef<HTMLElement>();
  const [open, setOpen] = useState(false);

  const { showConfirmationModal } = useUiPopup();
  const { initials, fullName, logout } = useUser();

  const { data: profile, isLoading } = useGetUserProfileQuery();

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    anchorEl.current = e.currentTarget;
    setOpen(true);
  };

  const goto = (link: string) => {
    navigate(link);
    setOpen(false);
  };

  const onLogOut = async () => {
    const didLogout = await showConfirmationModal({
      title: 'Log out',
      message: 'Are you sure you would like to log out?',
      yesButtonLabel: 'Yes',
      noButtonLabel: 'No',
      yesButtonAsyncAction: logout,
    });

    if (!didLogout) {
      setOpen(false);
    }
  };

  return (
    <>
      <Container
        aria-describedby="profile-drawer-item"
        onClick={handleClick}
        isOpen={isSideDrawerOpen || false}
      >
        <MenuItemInitialsContainer
          isOpen={isSideDrawerOpen || false}
          loading={isLoading}
          label={fullName}
          initials={initials}
          subLabel={profile?.companyJobTitle ?? ' '}
          avatarSize={45}
        />
        {isSideDrawerOpen && <Spacer />}
        {isSideDrawerOpen && <ChevronDownItem className="chevron-dropdown" />}
      </Container>

      <StyledPopover
        id="profile-drawer-item"
        open={open}
        anchorEl={anchorEl.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Row>
          <MenuItemInitialsContainer
            isOpen
            loading={isLoading}
            label={fullName}
            initials={initials}
            subLabel={profile?.companyJobTitle ?? ' '}
          />
          <Spacer />
          <StyledChevronLeft onClick={() => setOpen(false)} />
        </Row>
        <Divider compact addMargin negativeMargin={-20} />

        <ItemRow onClick={() => goto('/profile')}>
          <Tasks />
          <Typography variant="body2">View Profile</Typography>
        </ItemRow>

        <ItemRow onClick={() => goto('/profile')}>
          <Tasks />
          <Typography variant="body2">Edit Personal Details</Typography>
        </ItemRow>

        <ItemRow onClick={() => goto('/profile/settings/password')}>
          <Lock />
          <Typography variant="body2">Update Password</Typography>
        </ItemRow>
        <Divider compact addMargin negativeMargin={-20} />
        <ItemRow noBottomMargin onClick={onLogOut}>
          <Logout />
          <Typography variant="body2">Logout</Typography>
        </ItemRow>
      </StyledPopover>
    </>
  );
}

import { PropsWithChildren } from 'react';
import Carousel from 'react-material-ui-carousel';

interface CarouselScaffoldProps {
  index: number;
  indicators?: boolean;
}

export default function CarouselScaffold({
  index,
  indicators,
  children,
}: PropsWithChildren<CarouselScaffoldProps>) {
  return (
    <Carousel
      navButtonsAlwaysInvisible
      autoPlay={false}
      index={index}
      swipe={false}
      indicatorIconButtonProps={{
        style: {
          pointerEvents: 'none',
          display: indicators ? undefined : 'none',
        },
      }}
    >
      {children}
    </Carousel>
  );
}

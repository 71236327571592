import useNodeAction from '@pages/app/rca/tabs/components/node-action-hook';
import { BaseNodeState } from '@pages/app/rca/tabs/components/node-types/default-node-hook';
import { ReactComponent as AddButtonIcon } from '@assets/svgs/chain-item-add-button.svg';

export enum NodeActionDirection {
  Left = 'left',
  Up = 'up',
  Right = 'right',
  Down = 'down',
}

interface NodeActionProps {
  direction: NodeActionDirection;
  onClick: () => void;
}

function NodeAction({ direction, onClick }: NodeActionProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`node-action node-action-${direction}`}
    >
      <AddButtonIcon />
    </button>
  );
}

export default function NodeActions(state: BaseNodeState) {
  const { canAddRight, addSiblingBelow, addSiblingAbove, addChild } =
    useNodeAction(state);

  return (
    <div className="node-actions-container">
      {/*<NodeAction*/}
      {/*  direction={NodeActionDirection.Left}*/}
      {/*  icon={<PlusIcon fill="inherit" />}*/}
      {/*  onClick={() => {}}*/}
      {/*/>*/}
      <NodeAction
        direction={NodeActionDirection.Up}
        onClick={addSiblingAbove}
      />
      {canAddRight ? (
        <NodeAction direction={NodeActionDirection.Right} onClick={addChild} />
      ) : null}
      <NodeAction
        direction={NodeActionDirection.Down}
        onClick={addSiblingBelow}
      />
    </div>
  );
}

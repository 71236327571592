import { CaseFileListItemResponse } from '@api/types/case/case-file/case-file-list-item.response';
import { Box, Grid, styled, Typography } from '@mui/material';
import CaseFileTypeIconContainer from '@components/containers/case-file-type-icon-container';
import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import { getInitials } from '@util/name-util';
import { timespanFormatter } from '@i18n/formatters';
import { ElipsisButton } from '@components/buttons';
import Row from '@components/layout-util-components/row';
import AssociatedCauseBoxBadge from '@components/badges/associated-cause-box-badge';
import FlatBadge from '@components/badges/flat-badge';
import Column from '@components/layout-util-components/column';
import Spacer from '@components/layout-util-components/spacer';
import useCaseFileMenuActions from '@pages/app/rca/tabs/hooks/case-file-menu-actions-hook';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  caseFile: CaseFileListItemResponse;
  onSelectDeleteFile: (file: CaseFileListItemResponse) => Promise<boolean>;
}

const Container = styled('div')(({ theme: { palette } }) => ({
  border: '1px solid #00000040',
  borderRadius: 5,
  display: 'flex',
  background: '#f4f4f4',
  flexDirection: 'column',

  'file-size': {
    fontFamily: 'Roboto',
    fontSize: '11px',
    fontWeight: '500',
    lineHeight: '11px',
    letterSpacing: '0px',
    textAlign: 'left',
  },

  'linked-cause-box-container': {
    outline: 'none',
    padding: 4,
    display: 'inline-flex',
    gap: '5px',
    border: '1px solid #000000',
    borderRadius: 4,
    maxWidth: 212,
    overflowY: 'elipsis',
    fontFamily: 'Kyn, Roboto',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '14px',
    letterSpacing: '0em',
    textAlign: 'center',
    color: palette.common.black,

    svg: {
      height: 8,
      width: 'auto',
    },

    span: {
      textDecoration: 'underline',
    },
  },
}));

export default function CaseFileItem({ caseFile, onSelectDeleteFile }: Props) {
  const { linkedSolutions } = caseFile;
  const { getCaseFileMenuActions } = useCaseFileMenuActions({
    onDelete: onSelectDeleteFile,
  });

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Container>
        <Column
          sx={{
            alignItems: 'stretch',
            p: 2,
            gap: 1,
          }}
        >
          <CaseFileTypeIconContainer type={caseFile.caseFileType} />
          <Typography variant="caption">{caseFile.name}</Typography>
          <Gap size={12} />
          <Spacer />
          {caseFile.chainItems != null && caseFile.chainItems.length > 0 ? (
            <Box alignSelf="flex-start">
              <Gap size={6} />
              <AssociatedCauseBoxBadge count={caseFile.chainItems.length} />
            </Box>
          ) : null}
          {linkedSolutions > 0 ? (
            <Box display="flex" alignSelf="flex-start">
              <Gap size={6} />
              <FlatBadge>{`Solutions (${linkedSolutions})`}</FlatBadge>
            </Box>
          ) : null}
          <Box>{`Added ${timespanFormatter(new Date(caseFile.created))}`}</Box>
        </Column>

        <Box
          sx={{
            background: 'white',
            p: 2,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
          }}
        >
          <Row spaceBetween>
            <InitialsContainerWithData
              initials={getInitials(caseFile.createdByName)}
              label="Added By"
              subLabel={caseFile.createdByName}
            />
            <ElipsisButton actions={getCaseFileMenuActions(caseFile)} />
          </Row>
        </Box>
      </Container>
    </Grid>
  );
}

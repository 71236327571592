import { Filter } from '@components/table/hooks/use-filter-hook';
import { DropDownFilter, WCTTableFilters } from '@components/table';
import { Option } from '@api/types/option';
import { CompanyUserStatus } from '@api/types/company/company-user/company-user-status';
import { useAppTr } from '@i18n/use-app-tr';

interface Props {
  isLoading: boolean;
  search: Filter<string>;
  role: Filter<Array<number>>;
  group: Filter<Array<number>>;
  kudos: Filter<Array<number>>;
  status: Filter<Array<CompanyUserStatus>>;
  roleOptions?: Array<Option<string>>;
  groupOptions?: Array<Option>;
  kudosOptions?: Array<Option>;
  statusOptions?: Array<Option<CompanyUserStatus>>;
}
export default function UserTableFilters({
  isLoading,
  search,
  kudos,
  kudosOptions,
  groupOptions,
  group,
  roleOptions,
  role,
  statusOptions,
  status,
}: Props) {
  const { t } = useAppTr('userManagement');

  return (
    <WCTTableFilters
      isLoading={isLoading}
      search={search.value}
      onSearch={search.set}
    >
      <DropDownFilter
        value={role.value}
        onChange={role.set}
        options={roleOptions ?? []}
        multiple
      >
        {t('filters.role')}
      </DropDownFilter>
      <DropDownFilter
        value={group.value}
        onChange={group.set}
        options={groupOptions ?? []}
        multiple
      >
        {t('filters.groups')}
      </DropDownFilter>
      <DropDownFilter
        value={status.value}
        onChange={status.set}
        options={statusOptions ?? []}
        multiple
      >
        {t('filters.status')}
      </DropDownFilter>
      <DropDownFilter
        value={kudos.value}
        onChange={kudos.set}
        options={kudosOptions ?? []}
        multiple
      >
        {t('filters.kudos')}
      </DropDownFilter>
    </WCTTableFilters>
  );
}

import requireAuth from '@components/hoc/with-required-auth-hoc';
import Column from '@components/layout-util-components/column';
import ContentContainer from '@components/containers/content-container';
import { Chip, styled, Typography } from '@mui/material';
import NoTasksInfo from '@pages/app/tasks/components/no-tasks-info';
import useTasks from '@pages/app/tasks/tasks-index-hook';
import Row from '@components/layout-util-components/row';
import { LinkButton } from '@components/buttons';
import TasksStat from '@pages/app/tasks/components/tasks-stat';
import {
  DropDownFilter,
  SortByFilter,
  WCTTableFilters,
} from '@components/table';
import { ListContainer } from '@components/containers/list-container';
import TaskRow from '@pages/app/tasks/components/task-row';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import TaskForm from '@pages/app/tasks/forms/task-form';
import { PageAlertDisplay } from '@components/alerts';
import { Gap } from '@components/layout-util-components/gap';
import PrimaryButton from '@components/buttons/primary-button';

interface Props {
  caseId?: number;
  canAddTask?: boolean;
}

const StyledChip = styled(Chip)({
  padding: '4px 15px',
  borderRadius: 36,
  background: 'rgba(255, 108, 0, 0.10)',
  fontSize: 14,
  fontWeight: 600,
  marginLeft: 15,
  span: {
    padding: 0,
  },
});

const StatsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  columnGap: 40,
  rowGap: 10,
  flexWrap: 'wrap',
  overflowX: 'auto',
});

function TasksIndexPage({ caseId, canAddTask }: Props) {
  const { showPopup, showSingle, isShowingSingle } = useUiPopup();

  const {
    hasNoTasks,
    search,
    sort,
    sortByOptions,
    isLoading,
    isFetching,
    tasks,
    userOptions,
    taskStatusOptions,
    loadMore,
    companyUserFilter,
    taskStatusFilter,
    completeTask,
    openTask,
    canLoadMore,
  } = useTasks(caseId);

  const { highPriority, open, overdue } = tasks?.counts ?? {};

  const onAddTask = () => {
    showSingle(() => showPopup(TaskForm, { caseId }));
  };

  const onEditTask = (taskId: number) => {
    showSingle(() => showPopup(TaskForm, { taskId, caseId }));
  };

  const onCompleteTask = (taskId: number) => {
    if (isShowingSingle.current) {
      return;
    }

    completeTask(taskId);
  };

  const onOpenTask = (taskId: number) => {
    if (isShowingSingle.current) {
      return;
    }

    openTask(taskId);
  };

  return (
    <Column>
      {caseId == null ? <PageAlertDisplay /> : null}
      <ContentContainer maxWidth={960} padding="40px 20px 30px 20px">
        {hasNoTasks ? (
          <Typography
            variant="h1"
            fontSize={24}
            fontWeight={500}
            lineHeight="32px"
          >
            Tasks
          </Typography>
        ) : (
          <Row>
            <Typography
              variant="h1"
              fontSize={24}
              fontWeight={500}
              lineHeight="32px"
            >
              Tasks
              <StyledChip label={tasks?.count ?? 0} />
            </Typography>
            {canAddTask && (
              <PrimaryButton
                buttonVariant="two"
                onClick={onAddTask}
                styleOptions={{ marginLeft: 'auto' }}
              >
                Add Task
              </PrimaryButton>
            )}
          </Row>
        )}
      </ContentContainer>
      <ContentContainer maxWidth={960} padding="0px 20px">
        {hasNoTasks ? (
          <NoTasksInfo
            isCaseTasks={caseId != null}
            onCreateClicked={onAddTask}
          />
        ) : (
          <>
            <StatsContainer>
              <TasksStat label="OPEN TASKS" stat={open ?? 0} />
              <TasksStat label="OVERDUE TASKS" stat={overdue ?? 0} />
              <TasksStat label="HIGH PRIORITY" stat={highPriority ?? 0} />
            </StatsContainer>
            <Gap size={32} />
            <WCTTableFilters
              search={search.value}
              onSearch={search.set}
              isLoading={isLoading}
              rightOfSearchContent={
                <SortByFilter
                  value={sort.value}
                  onChange={sort.set}
                  options={sortByOptions}
                />
              }
            >
              <DropDownFilter
                value={taskStatusFilter.value}
                onChange={taskStatusFilter.set}
                options={taskStatusOptions ?? []}
                multiple
                styledOptions={{
                  background: '#D8DBE3',
                  border: '1px solid #D8DBE3',
                  marginLeft: 'auto',
                }}
              >
                Status
              </DropDownFilter>
              <DropDownFilter
                value={companyUserFilter.value}
                onChange={companyUserFilter.set}
                options={userOptions ?? []}
                multiple
                styledOptions={{
                  background: '#D8DBE3',
                  border: '1px solid #D8DBE3',
                }}
              >
                Assigned
              </DropDownFilter>
            </WCTTableFilters>
            <Gap size={20} />

            <ListContainer isLoading={isLoading} isLight>
              {!isLoading
                ? tasks!.model.map((task) => (
                    <TaskRow
                      key={task.taskDetailId}
                      task={task}
                      onEdit={() => onEditTask(task.taskDetailId)}
                      onComplete={() => onCompleteTask(task.taskDetailId)}
                      onOpen={() => {
                        onOpenTask(task.taskDetailId);
                      }}
                    />
                  ))
                : null}
            </ListContainer>
            <Gap size={20} />
            <Row centerContent>
              {canLoadMore ? (
                <LinkButton disabled={isFetching} onClick={loadMore}>
                  Load More
                </LinkButton>
              ) : null}
            </Row>
          </>
        )}
      </ContentContainer>
    </Column>
  );
}

export default requireAuth(TasksIndexPage);

import ContentContainer from '@components/containers/content-container';
import requireAuth from '@components/hoc/with-required-auth-hoc';
import Column from '@components/layout-util-components/column';
import { BecomeAnExpertBanner } from './components/become-expert';
import { Categories } from './components/categories';
import { HelpCenterFAQs } from './components/help-centre-faqs';
import { Videos } from './components/videos';
import useHelpCentre from './help-centre-hook';
import { useNavigate } from 'react-router-dom';
import { SearchBar } from './components/search-bar';
import { Articles } from './components/articles';

export const HelpCentreIndexPage = () => {
  const state = useHelpCentre();
  const {
    search,
    searchResult,
    helpCentreCategoryOptions,
    faqContent,
    videoContent,
    articleContent,
  } = state;

  const navigate = useNavigate();

  return (
    <Column>
      <ContentContainer>
        <h1>Help Centre</h1>
        <SearchBar search={search} searchResult={searchResult} />
        <Categories
          categories={helpCentreCategoryOptions}
          onClick={(id: number) => navigate(`category/${id}`)}
        />
        <Videos videos={videoContent ?? []} />
        <Articles articles={articleContent ?? []} />
        <BecomeAnExpertBanner sx={{ mb: 4 }} />
        <HelpCenterFAQs faqs={faqContent ?? []} />
      </ContentContainer>
    </Column>
  );
};

export default requireAuth(HelpCentreIndexPage);

import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Chip,
  Box,
  SxProps,
  Theme,
  IconButton,
  styled,
  Modal,
} from '@mui/material';
import { IHelpCentreContent } from '@api/types/help-center/help-center-content';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const StyledVideosHeaderWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

interface Props {
  sx?: SxProps<Theme>;
  videos: IHelpCentreContent[];
  label?: string;
  showAll?: boolean;
  defaultId?: number;
  direction?: 'column' | 'row';
}

export const Videos = ({
  sx,
  videos,
  label = 'Videos',
  direction = 'column',
  showAll = true,
  defaultId,
}: Props) => {
  const defaultVideo = useMemo(() => {
    return defaultId
      ? videos.find((x) => x.helpCentreContentId === defaultId)
      : undefined;
  }, [defaultId, videos]);

  const [selectedVideo, setSelectedVideo] = useState<string | undefined>(
    defaultVideo?.content
  );
  const navigate = useNavigate();

  if (!videos.length) {
    return null;
  }

  return (
    <Box sx={sx}>
      <Modal open={!!selectedVideo} onClose={() => setSelectedVideo(undefined)}>
        <Box
          style={{
            width: '80%',
            height: '80%',
            margin: 'auto',
            marginTop: '5%',
          }}
        >
          <iframe
            width="100%"
            height="100%"
            src={selectedVideo}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="What Caused This Help Centre"
          />
        </Box>
      </Modal>
      <StyledVideosHeaderWrapper>
        <h2>{label}</h2>
        {showAll && (
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <Typography fontWeight={500}>View All</Typography>
            <IconButton
              onClick={() => navigate('/help-centre/video')}
              aria-label="View all"
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </IconButton>
          </Box>
        )}
      </StyledVideosHeaderWrapper>
      <Grid mb={6} container spacing={2}>
        {videos.map((video) => (
          <Grid
            item
            xs={direction === 'column' ? 4 : 12}
            key={`video${video.helpCentreContentId}`}
          >
            <Card sx={{ p: 2, display: 'flex' }}>
              <Box sx={{ display: 'flex', flexDirection: direction }}>
                <CardMedia
                  sx={{ height: 140, width: 200 }}
                  image={video.mediaUrl}
                  title={video.title}
                  onClick={() => setSelectedVideo(video.mediaUrl)}
                />
                <Box>
                  <StyledCardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {video.title}
                    </Typography>
                  </StyledCardContent>
                  <CardActions>
                    {video.categories.map((category) => (
                      <Chip
                        size="small"
                        key={`video-category-${category.id}`}
                        label={category.label}
                      />
                    ))}
                  </CardActions>
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

import { CaseFileManagerState } from '@pages/app/rca/tabs/components/modals/case-file-manager/case-file-manager-modal-hook';
import { Typography } from '@mui/material';
import FilesField from '@components/input/files-field/files-field';
import { API_URL } from '@api/api';
import WCTCheckboxField from '@components/input/checkbox-field';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import Column from '@components/layout-util-components/column';
import { CaseFileListItemResponse } from '@api/types/case/case-file/case-file-list-item.response';
import useCaseFileManagerUpload from '@pages/app/rca/tabs/components/modals/case-file-manager/views/case-file-manager-upload-hook';
import { CaseFileResource } from '@api/types/case/case-file/case-file.resource';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  state: CaseFileManagerState;
  onClose: (caseFiles?: Array<CaseFileListItemResponse>) => void;
}

export default function CaseFileManagerListView({ state, onClose }: Props) {
  const { caseId, preselectedFileIds, caseFiles } = state;
  const { uploadedFiles, confirmedTerms, canSubmitFiles, submit, isBusy } =
    useCaseFileManagerUpload(state);

  const onSubmit = async () => {
    const result = await submit();
    if (result === false) {
      return;
    }

    const newFiles = result.map(CaseFileResource.toListItemResponse);
    const oldFiles =
      caseFiles?.model.filter(
        (x) => preselectedFileIds?.includes(x.caseFileId) ?? false
      ) ?? [];
    const allFiles = [...oldFiles, ...newFiles];
    onClose(allFiles);
  };

  return (
    <Column>
      <Typography variant="body1">Upload file(s)</Typography>
      <Gap size={4} />
      <FilesField
        uploadUrl={`${API_URL}/api/case/${caseId}/caseFile/file`}
        value={uploadedFiles.value}
        onChange={uploadedFiles.set}
        error={uploadedFiles.error}
        uploadMessage="Drag files to upload evidence"
      />
      <Gap size={15} />
      <WCTCheckboxField
        label={
          <span>
            I confirm that I am not uploading sensitive materials that would
            breach the <a href="#">terms & conditions</a> of the WCT platform
          </span>
        }
        name="terms"
        value={confirmedTerms.value}
        onChange={confirmedTerms.set}
        error={confirmedTerms.error}
      />
      <Gap size={12} />
      <ActionsRow>
        <OutlinedButton onClick={() => onClose(undefined)}>
          Cancel
        </OutlinedButton>
        <PrimaryButton
          disabled={!canSubmitFiles}
          onClick={onSubmit}
          isBusy={isBusy}
        >
          Add file(s)
        </PrimaryButton>
      </ActionsRow>
    </Column>
  );
}

import { useFilter, usePagination, useSortableHeader } from '@components/table';
import { SortDirection } from '@api/types/sort-direction';
import { useGetAllCaseDataQuery } from '@api/endpoints/case.api';
import { useGetCompanyUsersOptionsQuery } from '@api/endpoints/company-user.api';
import { useGetCompanyLocationOptionsQuery } from '@api/endpoints/company-location.api';
import { useGetCaseStatusOptionsQuery } from '@api/endpoints/case-status.api';
import { useGetCaseAnalysisTypeOptionsQuery } from '@api/endpoints/case-analysis-type.api';
import { useGetCompanyGroupOptionsQuery } from '@api/endpoints/company/company-company-group.api';
import { SortOption } from '@components/table/hooks/use-sortable-header-hook';

export enum AnalysisViewMode {
  table = 1,
  grid = 2,
  map = 3,
}

export default function useAnalysis(
  personId?: number,
  incidentLocationId?: number,
  groupId?: number
) {
  const viewMode = useFilter<AnalysisViewMode>(AnalysisViewMode.grid);

  const { data: userOptions, isLoading: loadingUserOptions } =
    useGetCompanyUsersOptionsQuery({});

  const { data: locationOptions, isLoading: loadingLocationOptions } =
    useGetCompanyLocationOptionsQuery();

  const { data: statusOptions, isLoading: loadingStatusOptions } =
    useGetCaseStatusOptionsQuery();

  const { data: categoryOptions, isLoading: loadingCategoryOptions } =
    useGetCaseAnalysisTypeOptionsQuery({});

  const { data: groupOptions, isLoading: loadingGroupOptions } =
    useGetCompanyGroupOptionsQuery({});

  const search = useFilter<string>();
  const status = useFilter<number>();
  const category = useFilter<number>();
  const group = useFilter<number>(groupId);
  const location = useFilter<number>(incidentLocationId);
  const person = useFilter<number>(personId);

  const sort = useSortableHeader({ initialProperty: 'name' });
  const pagination = usePagination();

  const { data: caseData, isLoading: loadingCases } = useGetAllCaseDataQuery({
    searchText: search.value,
    companyUserId: person.value,
    caseStatusId: status.value,
    companyLocationId: location.value,
    caseAnalysisTypeId: category.value,
    companyGroupId: group.value,
    skip: pagination.skip,
    take: pagination.take,
    sortBy: sort.property,
    sortDirection: sort.direction,
  });

  const sortByOptions: Array<SortOption> = [
    { id: 'name', label: 'Name A-Z', direction: SortDirection.asc },
    { id: 'name', label: 'Name Z-A', direction: SortDirection.desc },
    {
      id: 'created',
      label: 'Date Created (Newest First)',
      direction: SortDirection.desc,
    },
    {
      id: 'created',
      label: 'Date Created (Oldest First)',
      direction: SortDirection.asc,
    },
  ];

  const isLoading =
    loadingCases ||
    loadingUserOptions ||
    loadingLocationOptions ||
    loadingStatusOptions ||
    loadingCategoryOptions ||
    loadingGroupOptions;

  return {
    viewMode,
    search,
    status,
    category,
    location,
    person,
    group,
    sort,
    pagination,
    caseData,
    isLoading,
    userOptions,
    locationOptions,
    statusOptions,
    categoryOptions,
    groupOptions,
    sortByOptions,
    statTotals:
      caseData?.counts == null
        ? 0
        : caseData.counts.improvement +
          caseData.counts.risk +
          caseData.counts.problem,
  };
}

export type AnalysisOverviewState = ReturnType<typeof useAnalysis>;

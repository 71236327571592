import { useGetCountryNamesQuery } from '@api/endpoints/country.api';
import useField from '@hooks/use-field-hook';
import { useEffect } from 'react';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { usePageAlertVariants } from '@components/alerts';
import { useAppTr } from '@i18n/use-app-tr';
import { Address } from '@components/input/address-field';
import { useGetSectorOptionsQuery } from '@api/endpoints/sector.api';
import { useGetBusinessAreaOptionsQuery } from '@api/endpoints/business-area.api';
import { required, validatorSelector, validPostcode } from '@util/validators';
import {
  useGetCompanyDetailsQuery,
  useUpdateCompanyDetailsMutation,
} from '@api/endpoints/company/company.api';
import useAddressMemo from '@hooks/use-address-hook';
import { UpdateCompanyDetailsRequest } from '@api/types/company/update-company-details.request';
import { ApiError } from '@api/types/api-error';
import { useGetCurrencyOptionsQuery } from '@api/endpoints/currency.api';

export default function useCompanyInformation() {
  const { t } = useAppTr('companyInformation');
  const { showErrorMessage, showSuccessMessage, hideAlert } =
    usePageAlertVariants();

  const { data: details, isLoading: loadingDetails } =
    useGetCompanyDetailsQuery();

  const { data: currencyOptions, isLoading: loadingCurrencies } =
    useGetCurrencyOptionsQuery();
  const { data: countryOptions, isLoading: loadingCountries } =
    useGetCountryNamesQuery(true);
  const { data: sectorOptions, isLoading: loadingSectors } =
    useGetSectorOptionsQuery();
  const { data: businessAreaOptions, isLoading: loadingBusinessAreas } =
    useGetBusinessAreaOptionsQuery();

  const [submitDetails, { isLoading: isSubmitting }] =
    useUpdateCompanyDetailsMutation();

  const name = useField<string>([required()], details?.name);
  const sector = useField<number>([required()], details?.sectorId);
  const businessArea = useField<number>([required()], details?.businessAreaId);
  const defaultCurrency = useField<number>(
    [required()],
    details?.defaultCurrencyId
  );
  const address = useField<Address>(
    [validatorSelector('postcode', [validPostcode()])],
    useAddressMemo({
      addressLineOne: details?.addressLineOne,
      addressLineTwo: details?.addressLineTwo,
      town: details?.town,
      countryId: details?.countryId,
      country: details?.countryName,
      postcode: details?.postcode,
    })
  );

  const { isValid, isDirty, validateAll } = useFieldsWatcher([
    name,
    sector,
    defaultCurrency,
    businessArea,
    address,
  ]);

  const isLoading =
    loadingDetails ||
    loadingCountries ||
    loadingSectors ||
    loadingBusinessAreas ||
    loadingCurrencies;
  const canSubmit = !isLoading && !isSubmitting && isValid && isDirty;

  useEffect(() => {
    return () => hideAlert();
  }, [hideAlert]);

  const submit = async (): Promise<boolean> => {
    if (!validateAll()) {
      return false;
    }

    const existingDetails = details!;
    return submitDetails({
      ...existingDetails,
      name: name.value,
      sectorId: sector.value,
      businessAreaId: businessArea.value,
      addressLineOne: address.value.addressLineOne,
      addressLineTwo: address.value.addressLineTwo,
      town: address.value.town,
      countryId: address.value.countryId!,
      postcode: address.value.postcode!,
      defaultCurrencyId: defaultCurrency.value,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage(t('toasts.success'));
        return true;
      })
      .catch(({ message, errors }: ApiError<UpdateCompanyDetailsRequest>) => {
        showErrorMessage(message);

        name.setError(errors?.name);
        sector.setError(errors?.sectorId);
        businessArea.setError(errors?.businessAreaId);
        defaultCurrency.setError(errors?.defaultCurrencyId);
        address.setError({
          addressLineOne: errors?.addressLineOne,
          addressLineTwo: errors?.addressLineTwo,
          town: errors?.town,
          countryId: errors?.countryId,
          postcode: errors?.postcode,
        });

        return false;
      });
  };

  return {
    name,
    sector,
    businessArea,
    address,
    defaultCurrency,
    countryOptions,
    sectorOptions,
    businessAreaOptions,
    currencyOptions,
    isLoading,
    isSubmitting,
    canSubmit,
    submit,
  };
}

import SectionScaffold from '@components/outcome-report/components/section-scaffold';
import { Box, styled, Typography } from '@mui/material';
import Spacer from '@components/layout-util-components/spacer';
import { OutcomeEvidenceOverviewResponse } from '@api/types/case/case-outcome/outcome-report-types/outcome-evidence-overview.response';
import { CaseEvidenceResource } from '@api/types/case/case-evidence/case-evidence.resource';
import { OutlinedButton } from '@components/buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faFolder } from '@fortawesome/pro-light-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import Column from '@components/layout-util-components/column';
import Row from '@components/layout-util-components/row';
import { Circle } from '@mui/icons-material';
import { CoverageStatContainer } from '@pages/app/rca/tabs/reports/components/coverage-overview-container';
import { EvidenceStrength } from '@api/types/case/case-evidence/evidence-strength';
import AssociatedCauseBoxBadge from '@components/badges/associated-cause-box-badge';
import { EvidenceSensitivity } from '@api/types/case/case-evidence/evidence-sensitivity';
import BadgeInfo from '@components/outcome-report/components/badge-info';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  evidenceOverview: OutcomeEvidenceOverviewResponse;
  evidence: Array<CaseEvidenceResource>;
}

// Grid layout split into 2 columns,
// each column takes up 1/2 of the space and has 1 row
const Container = styled('div')({
  padding: '12px 75px',
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'stretch',

  '.section-title': {
    fontFamily: 'Roboto',
    fontSize: '32px',
    fontWeight: '400',
    lineHeight: '43px',
    letterSpacing: '0px',
    textAlign: 'center',
    color: 'black',
  },

  '.evidence-grid': {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '20px',
    alignItems: 'stretch',
  },
});

export default function EvidenceSection({ evidenceOverview, evidence }: Props) {
  const { caseId } = useParams();
  const navigate = useNavigate();

  if (evidenceOverview.totalEvidence === 0) {
    return <></>;
  }

  const evidenceByQuality = evidence
    .reduce((acc, evidence) => {
      const quality = evidence.quality;
      const existing = acc.find((x) => x.quality === quality);
      if (existing) {
        existing.count++;
      } else {
        acc.push({ quality, count: 1 });
      }
      return acc;
    }, [] as Array<{ quality: EvidenceStrength; count: number }>)
    .sort((a, b) => a.quality - b.quality)
    .map((x) => ({ count: x.count }));

  const evidenceProps: Array<EvidenceCardProps> = evidence.map((evidence) => ({
    evidenceName: evidence.name,
    description: evidence.description,
    sensitivity: evidence.sensitivity,
    strength: evidence.quality,
    numberOfAssociatedCauseBoxes: evidence.chainItemIds?.length ?? 0,
    fileCount: evidence.caseFiles?.length ?? 0,
  }));

  return (
    <SectionScaffold id="evidence" backgroundColor="#FBFBFB">
      <Container>
        <Spacer />
        <div className="section-title">Evidence</div>
        <Gap size={50} />
        <StrengthBreakdown
          breakdown={evidenceByQuality}
          averageScore={evidenceOverview.averageScore}
          totalEvidenceCount={evidenceOverview.totalEvidence}
        />
        <Gap size={50} />
        <div className="evidence-grid">
          {evidenceProps.map((evidence, index) => (
            <EvidenceCard key={index} {...evidence} />
          ))}
        </div>
        <Gap size={50} />
        <Box alignSelf="center">
          <OutlinedButton
            onClick={() => navigate(`/rca/tabs/${caseId}/evidence`)}
          >
            View Evidence <FontAwesomeIcon icon={faArrowRight} />
          </OutlinedButton>
        </Box>
        <Spacer />
      </Container>
    </SectionScaffold>
  );
}

interface StretchBreakdownProps {
  totalEvidenceCount: number;
  averageScore: number;
  breakdown: Array<{ count: number }>;
}

const StretchBreakdownContainer = styled('div')({
  background: 'white',
  padding: 35,
  borderRadius: 4,
  display: 'flex',

  '.bars-col': {
    width: '75%',
  },

  '.segment-breakdown-container': {
    width: '100%',
    height: '19px',

    '.segment-bar': {
      display: 'inline-block',
      height: '100%',
    },
  },
});

const StrengthBreakdown = ({
  breakdown,
  totalEvidenceCount,
  averageScore,
}: StretchBreakdownProps) => {
  const colors = ['#EFEFEF', '#E1E1E1', '#C8C8C8', '#9E9E9E', '#5C5C5C'];

  return (
    <StretchBreakdownContainer>
      <Column className="bars-col">
        <Row spaceBetween>
          <Typography>Strength</Typography>
          <Box display="flex" gap={2}>
            <Box display="inline-flex" gap={1} alignItems="center">
              <Circle htmlColor={colors[0]} />
              <span>1/5</span>
            </Box>
            <Box display="inline-flex" gap={1} alignItems="center">
              <Circle htmlColor={colors[1]} />
              <span>2/5</span>
            </Box>
            <Box display="inline-flex" gap={1} alignItems="center">
              <Circle htmlColor={colors[2]} />
              <span>3/5</span>
            </Box>
            <Box display="inline-flex" gap={1} alignItems="center">
              <Circle htmlColor={colors[3]} />
              <span>4/5</span>
            </Box>
            <Box display="inline-flex" gap={1} alignItems="center">
              <Circle htmlColor={colors[4]} />
              <span>5/5</span>
            </Box>
          </Box>
        </Row>
        <Gap size={10} />
        <div className="segment-breakdown-container">
          {breakdown.map(({ count }, index) => (
            <div
              key={index}
              className="segment-bar"
              style={{
                backgroundColor: colors[index],
                width: `${(count / totalEvidenceCount) * 100}%`,
              }}
            />
          ))}
        </div>
      </Column>
      <Spacer />
      <Box display="inline-flex">
        <CoverageStatContainer>
          <Typography className="value">{totalEvidenceCount}</Typography>
          <Typography className="title">Total Evidence</Typography>
        </CoverageStatContainer>
        <Gap size={15} />
        <CoverageStatContainer>
          <Typography className="value">{averageScore}/5</Typography>
          <Typography className="title">AVERAGE SCORE</Typography>
        </CoverageStatContainer>
      </Box>
    </StretchBreakdownContainer>
  );
};

interface EvidenceCardProps {
  fileCount: number;
  evidenceName: string;
  description?: string;
  sensitivity: EvidenceSensitivity;
  strength: EvidenceStrength;
  numberOfAssociatedCauseBoxes: number;
}

const EvidenceCardContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  background: 'white',
  borderRadius: 12,
  padding: 32,

  '.icon-with-badge': {
    position: 'relative', // for the badge to be positioned absolutely
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',

    '.badge': {
      position: 'absolute',
      top: -12,
      right: -12,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      background: '#00000099',
      borderRadius: '50%',
      width: 18,
      height: 18,
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: '600',
      lineHeight: '18px',
      letterSpacing: '0.15000000596046448px',
      textAlign: 'center',
    },
  },
});

const EvidenceCard = ({
  evidenceName,
  numberOfAssociatedCauseBoxes,
  sensitivity,
  strength,
  fileCount,
  description,
}: EvidenceCardProps) => {
  return (
    <EvidenceCardContainer>
      <Row>
        <Typography>{evidenceName}</Typography>
        <Spacer />
        {fileCount ? (
          <div className="icon-with-badge">
            <FontAwesomeIcon icon={faFolder} />
            <div className="badge">{fileCount}</div>
          </div>
        ) : null}
      </Row>
      <Gap size={4} />
      <Typography>{description}</Typography>
      <Gap size={15} />
      <Row>
        <BadgeInfo label="Severity" value={sensitivity} />
        <Gap size={50} />
        <BadgeInfo label="Strength" value={`${strength}/5`} />
        <Spacer />
        {numberOfAssociatedCauseBoxes ? (
          <Box alignSelf="flex-end">
            <AssociatedCauseBoxBadge count={numberOfAssociatedCauseBoxes} />
          </Box>
        ) : null}
      </Row>
    </EvidenceCardContainer>
  );
};

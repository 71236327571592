import Uploady, {
  useItemErrorListener,
  useItemFinishListener,
  useItemProgressListener,
  useItemStartListener,
} from '@rpldy/uploady';
import UploadDropZone from '@rpldy/upload-drop-zone';
import UploadButton from '@rpldy/upload-button';
import { FormHelperText, LinearProgress, styled } from '@mui/material';
import { BaseFile } from '@api/types/file-types';
import { useEffect, useRef, useState } from 'react';
import { isNullOrEmpty } from '@util/string-util';
import { FormatServerResponseMethod } from '@rpldy/shared';
import Column from '@components/layout-util-components/column';
import FileItem from '@components/input/files-field/file-item';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  value: Array<BaseFile>;
  onChange: (value: Array<BaseFile>) => void;
  uploadUrl: string;
  error?: string;
  formDataPropertyName?: string;
  requestMethod?: string;
  uploadMessage?: string;
}

const StyledDropZone = styled(UploadDropZone)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 10,
  padding: 10,
  border: '1px dashed #00000040',
  borderRadius: 5,
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '20px',
  letterSpacing: '0.17000000178813934px',
  textAlign: 'center',

  '&.drag-over': {
    border: `4px dashed ${theme.palette.primary.main}`,
    padding: 7,
  },
}));

const StyledUploadButton = styled(UploadButton)(({ theme: { palette } }) => ({
  border: `1px solid ${palette.common.grey90}`,
  background: 'transparent',
  padding: '4px 10px',
  borderRadius: 4,
  color: palette.common.grey90,
  fontFamily: 'Roboto',
  fontSize: '13px',
  fontWeight: '500',
  lineHeight: '22px',
  letterSpacing: '0.46000000834465027px',
  textAlign: 'left',
}));

const ItemsContainer = styled('div', { target: 'files-field-items-container' })(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: 8,

    '.item': {
      padding: 10,
      background: '#BABABA40',
      color: '#00000099',
      border: '1px solid #52525280',
      borderRadius: 4,
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '21px',
      letterSpacing: '0.15000000596046448px',
      textAlign: 'left',
      '&.complete': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  }
);

interface Uploads {
  [key: string]: {
    name: string;
    progress: number;
    size: string;
    error?: string;
  };
}

const UploadProgress = () => {
  const [uploads, setUploads] = useState<Uploads>({});

  useItemStartListener((item) => {
    // get file size in MB
    const fileSize = item.file.size / (1024 * 1024);
    setUploads((v) => ({
      ...v,
      [item.id]: {
        name: item.file.name,
        progress: 0,
        size: `${fileSize.toFixed(1)}mb`,
      },
    }));
  });

  useItemProgressListener((item) => {
    setUploads((v) => ({
      ...v,
      [item.id]: {
        ...v[item.id],
        progress: item.completed,
      },
    }));
  });

  useItemErrorListener((item) => {
    const errors = item.uploadResponse?.data?.errors;
    if (errors == null) {
      return;
    }

    const errorArray: Array<string> = Object.values(errors);
    if (Array.isArray(errorArray) && errorArray.length > 0) {
      const error = errorArray.join(', ');
      setUploads((v) => ({
        ...v,
        [item.id]: {
          ...v[item.id],
          error: error,
        },
      }));
    }
  });

  useItemFinishListener((item) => {
    setUploads((v) => {
      const newUploads = { ...v };
      delete newUploads[item.id];
      return newUploads;
    });
  });

  const entries = Object.entries(uploads);

  return (
    <ItemsContainer>
      {entries.map(([id, { name, progress, size, error }]) => (
        <div key={id} className="item">
          <span>{`${name} (${size})`}</span>
          <LinearProgress variant="determinate" value={progress} />
          {!isNullOrEmpty(error) && (
            <FormHelperText error>{error}</FormHelperText>
          )}
        </div>
      ))}
    </ItemsContainer>
  );
};

interface UploadedFilesListProps {
  files: Array<BaseFile>;
  onRemoveFile: (fileId: number) => void;
  onUpdateFile: (file: BaseFile) => void;
}

const UploadedFilesList = ({
  files,
  onRemoveFile,
  onUpdateFile,
}: UploadedFilesListProps) => {
  if (files.length === 0) {
    return <> </>;
  }

  return (
    <>
      <Gap size={8} />
      <ItemsContainer>
        {files.map((file) => (
          <FileItem
            key={file.fileMetadataId}
            file={file}
            onRemove={() => onRemoveFile(file.fileMetadataId)}
            onUpdateName={(filename) => {
              onUpdateFile({ ...file, filename });
            }}
          />
        ))}
      </ItemsContainer>
    </>
  );
};

export default function FilesField({
  uploadUrl,
  value,
  onChange,
  error,
  formDataPropertyName = 'files',
  requestMethod = 'POST',
  uploadMessage = 'Drag files to upload',
}: Props) {
  const valueRef = useRef<Array<BaseFile>>(value ?? []);

  const formatServerResponse: FormatServerResponseMethod = (
    response,
    status,
    _
  ) => {
    if (status === 200) {
      const json = JSON.parse(response);
      if (Array.isArray(json) && json.length === 1) {
        valueRef.current.push(json[0]);
        onChange([...valueRef.current]);
      }
    }
  };

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  const updateFile = (file: BaseFile) => {
    onChange(
      valueRef.current.map((f) => {
        if (f.fileMetadataId === file.fileMetadataId) {
          return file;
        }
        return f;
      })
    );
  };

  return (
    <Uploady
      withCredentials
      debug
      multiple
      concurrent
      formatServerResponse={formatServerResponse}
      destination={{
        url: uploadUrl,
        method: requestMethod,
        filesParamName: formDataPropertyName,
      }}
    >
      <UploadProgress />
      <UploadedFilesList
        files={value}
        onUpdateFile={updateFile}
        onRemoveFile={(fileId) => {
          onChange(valueRef.current.filter((f) => f.fileMetadataId !== fileId));
        }}
      />
      <Gap size={12} />
      <StyledDropZone onDragOverClassName="drag-over">
        <span>{uploadMessage}</span>
        <StyledUploadButton>Select file(s)</StyledUploadButton>
      </StyledDropZone>
      <Column>
        {!isNullOrEmpty(error) ? (
          <>
            <Gap size={12} />
            <FormHelperText error>{error}</FormHelperText>
          </>
        ) : null}
      </Column>
    </Uploady>
  );
}

import { StepFormLayout } from '@pages/app/rca/create/components/step-layout';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import { Box, Grid, Typography } from '@mui/material';
import Spacer from '@components/layout-util-components/spacer';
import WCTTextField from '@components/input/text-field';
import Column from '@components/layout-util-components/column';
import { Divider } from '@components/layout-util-components/divider';
import WCTSelectField from '@components/input/select-field';
import RadioSelectGroup from '@components/input/radio-select-group';
import WCTDateTimeField from '@components/input/date-time-field';
import BinarySelectField from '@components/input/binary-select-field';
import useOverviewStep from '@pages/app/rca/create/steps/overview-step-hook';
import { useAppSelector } from '@store/store';
import { selectStepTitle } from '@store/create-analysis-process/selectors';
import {
  ButtonSkeleton,
  DatePickerSkeleton,
  InputSkeleton,
} from '@components/skeletons';
import ListContainerSkeleton from '@components/skeletons/list-container-skeleton';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import FunctionalLocationForm, {
  FunctionalLocationFormProps,
} from '@pages/app/company-profile/forms/functional-location-form';
import { CompanyLocationResource } from '@api/types/company/company-location/company-location.resource';
import { useCreateLocationForCompanyMutation } from '@api/endpoints/company/company-location.api';
import { useCreateIncidentLocationMutation } from '@api/endpoints/case.api';
import { RcaStepProps } from '@pages/app/rca/create/rca-creation-steps-page';
import useDirtyGuard from '@hooks/use-dirty-guard';
import WCTLocationTypeaheadField from '@components/input/location-typeahead-field';
import { Gap } from '@components/layout-util-components/gap';

export default function OverviewStep({
  onComplete,
  onGoBack,
  caseId,
}: RcaStepProps) {
  const { confirmDirty } = useDirtyGuard();
  const { showPopup } = useUiPopup();

  const title = useAppSelector((state) => selectStepTitle(state, 'Overview'));

  const state = useOverviewStep(caseId);
  const {
    incidentAtReportingLocation,
    reportingLocation,
    companyLocationOptions,
    binaryOptions,
    frequencyTypeOptions,
    duration,
    priorityOptions,
    priority,
    severityOptions,
    incidentLocation,
    severity,
    caseTypeOptions,
    rcaType,
    endDate,
    startDate,
    investigationName,
    frequency,
    frequencySummary,
    focalPoint,
    description,
    isLoading,
    isSubmitting,
    isEdit,
    canSubmit,
    systemText,
    onSubmit,
  } = state;

  const onSubmitClicked = async () => {
    const shouldComplete = await onSubmit();
    if (shouldComplete) {
      onComplete();
    }
  };

  const addNewCompanyLocation = () => {
    return showPopup<FunctionalLocationFormProps, CompanyLocationResource>(
      FunctionalLocationForm,
      {
        creationMutation: useCreateLocationForCompanyMutation,
      }
    );
  };

  const addNewIncidentLocation = () => {
    return showPopup<FunctionalLocationFormProps, CompanyLocationResource>(
      FunctionalLocationForm,
      {
        allowPinOnMap: true,
        creationMutation: useCreateIncidentLocationMutation,
      }
    );
  };

  return (
    <StepFormLayout
      onSubmit={onSubmitClicked}
      actionsRow={
        <ActionsRow>
          {
            <OutlinedButton onClick={confirmDirty(onGoBack)}>
              Back
            </OutlinedButton>
          }
          <Spacer />
          <PrimaryButton
            disabled={!canSubmit}
            isBusy={isSubmitting}
            type="submit"
          >
            {isEdit ? 'Update' : 'Continue'}
          </PrimaryButton>
        </ActionsRow>
      }
    >
      <Typography variant="h5">{title}</Typography>
      <Gap size={10} />
      <Typography variant="body2">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lectus
        lorem, sollicitudin consequat felis egestas, pretium viverra sapien.
        Vestibulum eu sodales magna.
      </Typography>
      <Gap size={32} />
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">Overview *</Typography>
          <Typography variant="body2">Name your RCA</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Column>
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <WCTTextField
                label="Investigation Name"
                name="investigationName"
                value={investigationName.value}
                error={investigationName.error}
                onChange={investigationName.set}
                required
              />
            )}
            <Gap size={24} />
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <WCTTextField
                label="Description"
                name="description"
                value={description.value}
                error={description.error}
                onChange={description.set}
                required
                multiline
              />
            )}
          </Column>
        </Grid>
      </Grid>
      <Divider addMargin />
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">
            {systemText['RCA.Overview.FocalPoint']} *
          </Typography>
          <Typography variant="body2">
            What is the exact issue being analysed
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTTextField
              label={systemText['RCA.Overview.FocalPoint']}
              name="focalPoint"
              value={focalPoint.value}
              error={focalPoint.error}
              onChange={focalPoint.set}
              required
            />
          )}
        </Grid>
      </Grid>
      <Divider addMargin />
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">
            {systemText['RCA.Overview.BusinessFunctions']} *
          </Typography>
          <Typography variant="body2">
            Which {systemText['RCA.Overview.BusinessFunctions']} were involved
            in this issue
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTSelectField
              id="type"
              name="type"
              label="Select"
              options={caseTypeOptions!}
              value={rcaType.value}
              error={rcaType.error}
              onChange={rcaType.set}
              required
              multiple
            />
          )}
        </Grid>
      </Grid>
      <Divider addMargin />
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">Priority *</Typography>
          <Typography variant="body2">
            How important is this issue to your organisation
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {isLoading ? (
            <ListContainerSkeleton />
          ) : (
            <RadioSelectGroup
              options={priorityOptions!}
              value={priority.value}
              error={priority.error}
              onChange={priority.set}
            />
          )}
        </Grid>
      </Grid>
      <Divider addMargin />
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">Severity *</Typography>
          <Typography variant="body2">
            What is the severity rating of this issue
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {isLoading ? (
            <ListContainerSkeleton />
          ) : (
            <RadioSelectGroup
              options={severityOptions!}
              value={severity.value}
              error={severity.error}
              onChange={severity.set}
            />
          )}
        </Grid>
      </Grid>
      <Divider addMargin />
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">When *</Typography>
          <Typography variant="body2">When did this issue occur</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Column>
            {isLoading ? (
              <DatePickerSkeleton />
            ) : (
              <WCTDateTimeField
                name="startDate"
                label="Start Date / Time"
                value={startDate.value}
                error={startDate.error}
                onChange={startDate.set}
                disableFuture
                required
              />
            )}
            <Gap size={32} />
            {isLoading ? (
              <DatePickerSkeleton />
            ) : (
              <WCTDateTimeField
                name="endDate"
                label="End Date / Time"
                value={endDate.value}
                error={endDate.error}
                onChange={endDate.set}
                disableFuture
              />
            )}
          </Column>
        </Grid>
      </Grid>
      <Divider addMargin />
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">
            {systemText['RCA.Overview.Recurrence']} *
          </Typography>
          <Typography variant="body2">
            {`What is the ${systemText['RCA.Overview.Recurrence']} rate of this issue`}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Column>
            <Grid container wrap="nowrap">
              <Box maxWidth={110}>
                {isLoading ? (
                  <InputSkeleton />
                ) : (
                  <WCTTextField
                    name="frequency"
                    label="Frequency"
                    value={frequency.value}
                    error={frequency.error}
                    onChange={frequency.set}
                    required
                  />
                )}
              </Box>
              <Gap size={32} />
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <WCTSelectField
                  id="duration"
                  name="duration"
                  label="Per Period"
                  value={duration.value}
                  error={duration.error}
                  onChange={duration.set}
                  options={frequencyTypeOptions!}
                  required
                />
              )}
            </Grid>
            <Gap size={32} />
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <WCTTextField
                name="frequencySummary"
                label="Additional Notes"
                value={frequencySummary.value}
                error={frequencySummary.error}
                onChange={frequencySummary.set}
              />
            )}
          </Column>
        </Grid>
      </Grid>
      <Divider addMargin />
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">Reporting Location *</Typography>
          <Typography variant="body2">
            Which office or site is conducting this RCA
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTLocationTypeaheadField
              id="reportingLocation"
              name="reportingLocation"
              placeholder="Type to search"
              label="Reporting Location"
              value={reportingLocation.value}
              error={reportingLocation.error}
              onChange={reportingLocation.set}
              options={companyLocationOptions!}
              groupBy={(_) => 'Locations'}
              required
            />
          )}
          <Gap size={20} />
          <Box maxWidth={200}>
            {isLoading ? (
              <ButtonSkeleton />
            ) : (
              <OutlinedButton
                onClick={() => {
                  addNewCompanyLocation().then((location) => {
                    if (location != null) {
                      reportingLocation.set(location);
                    }
                  });
                }}
              >
                Add New Address
              </OutlinedButton>
            )}
          </Box>
        </Grid>
      </Grid>
      <Divider addMargin />
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">
            Did the issue occur at this reporting location? *
          </Typography>
          <Typography variant="body2">
            Where did the issue being analysed take place
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <BinarySelectField
              name="incidentAtReportingLocation"
              value={incidentAtReportingLocation.value}
              error={incidentAtReportingLocation.error}
              onChange={incidentAtReportingLocation.set}
              options={binaryOptions}
            />
          )}
        </Grid>
      </Grid>
      {incidentAtReportingLocation.value != null &&
      !incidentAtReportingLocation.value ? (
        <>
          <Gap size={32} />
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">Incident Location *</Typography>
              <Typography variant="body2">
                Where did the incident happen?
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <WCTLocationTypeaheadField
                  id="incidentLocation"
                  name="incidentLocation"
                  placeholder="Type to search"
                  label="Incident Location"
                  value={incidentLocation.value}
                  error={incidentLocation.error}
                  onChange={incidentLocation.set}
                  options={companyLocationOptions!}
                  required
                />
              )}
              <Gap size={20} />
              <Box maxWidth={200}>
                {isLoading ? (
                  <ButtonSkeleton />
                ) : (
                  <OutlinedButton
                    onClick={() => {
                      addNewIncidentLocation().then((location) => {
                        if (location != null) {
                          incidentLocation.set(location);
                        }
                      });
                    }}
                  >
                    Add New Address
                  </OutlinedButton>
                )}
              </Box>
            </Grid>
          </Grid>
        </>
      ) : null}
    </StepFormLayout>
  );
}

// This was added after a lot of the state was implemented in the node view
// will move across the rest of the state as it is implemented
import {
  NodeCollapseState,
  NodeEndStateType,
  RcaNode,
  RcaNodeType,
} from '@store/rca-editor/types';
import { useMemo } from 'react';
import {
  makeSelectIsNodeBusy,
  makeSelectNodeCollapsedState,
  makeSelectParentNodeType,
  selectChainId,
  selectRcaChartMode,
} from '@store/rca-editor/selectors';
import { useAppSelector } from '@store/store';
import { RcaChartMode } from '@store/rca-editor/rca-editor-slice';

export default function useNode(node: RcaNode) {
  const { id, type, data, dragging } = node;
  const { endState } = data;

  const selectParentNodeType = useMemo(
    () => makeSelectParentNodeType(id),
    [id]
  );
  const selectIsNodeBusy = useMemo(() => makeSelectIsNodeBusy(id), [id]);
  const selectCollapsedState = useMemo(
    () => makeSelectNodeCollapsedState(id),
    [id]
  );

  const chainId = useAppSelector(selectChainId);
  const mode = useAppSelector(selectRcaChartMode);
  const isBusy = useAppSelector(selectIsNodeBusy);
  const collapsedState = useAppSelector(selectCollapsedState);
  const parentNodeType = useAppSelector(selectParentNodeType);

  const isReadOnly = mode === RcaChartMode.present;
  const isPresenterMode = mode === RcaChartMode.present;
  const isEndState = type === RcaNodeType.endState;
  const isLinkNode = endState === NodeEndStateType.linkToChainItem;
  const isConnection = type === RcaNodeType.connection;
  const isEndStateOrConnection = isEndState || isConnection;

  const canShowActions =
    collapsedState === NodeCollapseState.default &&
    (!isEndStateOrConnection || isLinkNode) && // We can still add above below connection nodes
    !isReadOnly &&
    !dragging;

  return {
    id,
    data,
    chainId,
    isBusy,
    isEndState,
    isEndStateOrConnection,
    canShowActions,
    collapsedState,
    isLinkNode,
    isConnection,
    parentNodeType,
    isPresenterMode,
  };
}

export type NodeState = ReturnType<typeof useNode>;

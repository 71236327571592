import { Box, Chip, styled, Typography } from '@mui/material';
import { ElipsisButton } from '@components/buttons';
import ContentFooterCard from '@components/cards/content-footer-card';
import Row from '@components/layout-util-components/row';
import Spacer from '@components/layout-util-components/spacer';
import { timespanFormatter } from '@i18n/formatters';
import { ReactComponent as PinIcon } from '@assets/svgs/location-dot.svg';
import GroupedAvatars from '@components/containers/grouped-avatars';
import { CaseItemRowResponse } from '@api/types/case/case-item-row.response';
import { getAddressString } from '@util/string-util';
import { useMemo } from 'react';
import { useCaseElipsisOptions } from '@pages/app/analysis/components/case-elipsis-options';
import { useNavigate } from 'react-router-dom';
import { getInitials } from '@util/name-util';
import { getColorForId } from '@util/colour-identifiers';
import { FlexColumn, FlexRow } from '@components/layout-util-components/flex';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  data: CaseItemRowResponse;
}

const HeaderBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  p: {
    fontSize: 12,
    fontWeight: 400,
    color: '#808080',
  },
  '>.MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    gap: 7,
  },
});

const StyledChip = styled(Chip)<{ bg?: string }>(
  ({ bg, theme: { palette } }) => ({
    borderRadius: 76,
    background: bg || '#F8F8FA',
    display: 'flex',
    height: 'auto',
    padding: '3px 4px 3px 1px',
    justifyContent: 'center',
    alignItems: 'center',
    '> span > .MuiBox-root': {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      p: {
        fontSize: 12,
        color: '#525B5E',
      },
    },
  })
);

export default function RcaCard({ data }: Props) {
  const navigate = useNavigate();
  const elipsisOptions = useCaseElipsisOptions(data);

  const incidentLocation = useMemo(() => {
    const location = data.incidentLocation;
    if (location == null) {
      return;
    }

    return getAddressString({ ...location }) || location.name;
  }, [data.incidentLocation]);

  const severityColor = getColorForId(Number(data.caseSeverityId));

  return (
    <ContentFooterCard
      onClick={() => navigate(`/rca/tabs/${data.caseId}`)}
      content={
        <>
          <HeaderBox>
            <Typography>{data.caseAnalysisTypeShortName}</Typography>
            <Box>
              <Box
                sx={{
                  width: '8px',
                  height: '8px',
                  borderRadius: 8,
                  background: severityColor,
                }}
              />
              <Typography color={severityColor}>
                Health: {Math.round(data.healthScore)}%
              </Typography>
            </Box>
          </HeaderBox>
          <Gap size={12} />
          <Typography fontSize={18} fontWeight={400} lineHeight="28px">
            {data.name}
          </Typography>
          <Gap size={6} />
          <Typography color="#00000080" fontSize={12} variant="caption">
            Created {timespanFormatter(data.created)}
          </Typography>
          <Gap size={8} />
          <Row>
            <PinIcon />
            <Gap size={10} />
            <Typography fontSize={14} fontWeight={400} lineHeight="18px">
              {incidentLocation ? incidentLocation : '-'}
            </Typography>
          </Row>
          <Gap size={20} />
          <FlexRow>
            <FlexColumn gap={5}>
              <Typography fontSize={12} fontWeight={400} color="#00000080">
                Status
              </Typography>
              <StyledChip variant="filled" label={data.caseStatusName} />
            </FlexColumn>

            <FlexColumn gap={5}>
              <Typography fontSize={12} fontWeight={400} color="#00000080">
                Severity
              </Typography>
              <StyledChip
                variant="filled"
                bg={`${severityColor}25`}
                label={
                  <Box>
                    <Box
                      sx={{
                        width: '8px',
                        height: '8px',
                        borderRadius: 8,
                        background: severityColor,
                      }}
                    />
                    <Typography>{data.caseSeverityName}</Typography>
                  </Box>
                }
              />
            </FlexColumn>
          </FlexRow>
        </>
      }
      footer={
        <>
          <GroupedAvatars
            items={data.companyUsers.map(({ companyUserId, name }) => ({
              id: companyUserId,
              label: getInitials(name),
            }))}
          />
          <Spacer />
          <ElipsisButton actions={elipsisOptions} />
        </>
      }
    />
  );
}

export enum ReportType {
  coverage = 'Coverage',
  impact = 'Impact',
}

export interface ReportTypeOption {
  reportTypeId: number;
  name: string;
  reportType: ReportType;
}

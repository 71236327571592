import { PropsWithChildren } from 'react';
import { styled } from '@mui/material';
import { PageAlertDisplay } from '@components/alerts';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
});

const StyledContainer = styled('div')({
  display: 'flex',
  flex: '0 0 auto',
  justifyContent: 'flex-end',
  gap: 20,
  alignItems: 'center',
  padding: 25,
});

export default function SmallFormFooter({ children }: PropsWithChildren<{}>) {
  return (
    <Container>
      <PageAlertDisplay ignoreSuccess />
      <StyledContainer>{children}</StyledContainer>
    </Container>
  );
}

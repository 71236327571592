import { createTheme, LinkProps } from '@mui/material';
import LinkBehavior from './behaviours/link-behaviour';
import { palette } from './palette';
import { typography } from './typography';

const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          display: 'none',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
  palette,
  typography,
});

export default theme;

import useField from '@hooks/use-field-hook';
import { required } from '@util/validators';
import { useMemo } from 'react';
import { EvidenceSensitivity } from '@api/types/case/case-evidence/evidence-sensitivity';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { Option } from '@api/types/option';
import { CaseFileListItemResponse } from '@api/types/case/case-file/case-file-list-item.response';
import {
  useCreateCaseEvidenceMutation,
  useUpdateCaseEvidenceMutation,
} from '@api/endpoints/case.api';
import { usePageAlertVariants } from '@components/alerts';
import { useAppSelector } from '@store/store';
import {
  selectCurrentRcaCaseId,
  selectFocusedNode,
} from '@store/rca-editor/selectors';
import { CreateCaseEvidenceRequest } from '@api/types/case/case-evidence/create-case-evidence.reqest';
import { ApiError } from '@api/types/api-error';
import { CaseEvidenceResource } from '@api/types/case/case-evidence/case-evidence.resource';
import { CaseFileResource } from '@api/types/case/case-file/case-file.resource';
import useEvidenceHelper from '@pages/app/evidence/hooks/evidence-helper-hook';

export default function useUpsertEvidenceFormView(
  evidence?: CaseEvidenceResource
) {
  const evidenceHelper = useEvidenceHelper();

  const isEdit = evidence != null;
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const caseId = useAppSelector(selectCurrentRcaCaseId);
  const focusedNode = useAppSelector(selectFocusedNode);
  const { chainItemId, label } = focusedNode?.data ?? {};

  const strengthOptions: Array<Option<number>> = [
    { id: 1, label: '1' },
    { id: 2, label: '2' },
    { id: 3, label: '3' },
    { id: 4, label: '4' },
    { id: 5, label: '5' },
  ];

  const sensitivityOptions: Array<Option<EvidenceSensitivity>> = [
    { id: EvidenceSensitivity.high, label: 'High' },
    { id: EvidenceSensitivity.medium, label: 'Medium' },
    { id: EvidenceSensitivity.low, label: 'Low' },
  ];

  const [createEvidence, { isLoading: isSubmittingCreate }] =
    useCreateCaseEvidenceMutation();

  const [updateEvidence, { isLoading: isSubmittingUpdate }] =
    useUpdateCaseEvidenceMutation();

  const isSubmitting = isSubmittingCreate || isSubmittingUpdate;

  const name = useField<string>([required()], evidence?.name);
  const description = useField<string>([], evidence?.description);
  const files = useField<Array<CaseFileListItemResponse>>(
    [required({}, 'At least one file is required')],
    useMemo(
      () =>
        evidence?.caseFiles
          ? evidence?.caseFiles?.map(CaseFileResource.toListItemResponse)
          : [],
      [evidence?.caseFiles]
    )
  );
  const sensitivity = useField<EvidenceSensitivity>(
    [required()],
    evidence?.sensitivity
  );
  const strength = useField<number>([required()], evidence?.quality);

  const { isValid, isDirty, validateAll } = useFieldsWatcher([
    name,
    description,
    files,
    sensitivity,
    strength,
  ]);

  const canSubmit = isValid && isDirty;

  const submitEdit = async (): Promise<boolean> => {
    if (!validateAll() || !evidence) {
      return false;
    }

    return updateEvidence({
      evidenceId: evidence.evidenceId,
      caseId,
      chainItemId: chainItemId!,
      name: name.value,
      description: description.value ?? '',
      sensitivity: sensitivity.value,
      quality: strength.value,
      caseFileIds: files.value.map((file) => file.caseFileId),
    })
      .unwrap()
      .then(() => {
        showSuccessMessage('You have successfully updated evidence details');
        return true;
      })
      .catch(({ message, errors }: ApiError<CreateCaseEvidenceRequest>) => {
        showErrorMessage(message);

        description.setError(errors?.description);
        return false;
      });
  };

  const submitCreate = async () => {
    if (!validateAll()) {
      return false;
    }

    try {
      await createEvidence({
        caseId,
        chainItemId: chainItemId!,
        name: name.value,
        description: description.value,
        sensitivity: sensitivity.value,
        quality: strength.value,
        caseFileIds: files.value.map((file) => file.caseFileId),
      }).unwrap();

      showSuccessMessage(
        `You have successfully added evidence to Cause Box “${label}”`
      );

      return true;
    } catch (error) {
      const { message, errors } = error as ApiError<CreateCaseEvidenceRequest>;
      showErrorMessage(errors?.caseId ?? errors?.chainItemId ?? message);

      name.setError(errors?.name);
      description.setError(errors?.description);
      sensitivity.setError(errors?.sensitivity);
      strength.setError(errors?.quality);
      files.setError(errors?.caseFileIds);

      return false;
    }
  };

  return {
    isEdit,
    name,
    description,
    files,
    sensitivity,
    strength,
    canSubmit,
    submit: isEdit ? submitEdit : submitCreate,
    sensitivityOptions,
    strengthOptions,
    isSubmitting,
    isDirty,
    ...evidenceHelper,
  };
}

import { TaskDetailListItemResponse } from '@api/types/task-detail/task-detail-list-item.response';
import { styled, Typography } from '@mui/material';
import Row from '@components/layout-util-components/row';
import TaskCompleteIndicator from '@pages/app/tasks/components/task-complete-indicator';
import { TaskStatus } from '@api/types/task-detail/task-status';
import Spacer from '@components/layout-util-components/spacer';
import PriorityIndicator from '@pages/app/tasks/components/priority-indicator';
import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import { ReactComponent as DueDateIndicator } from '@assets/svgs/due-date-indicator.svg';
import { dateOnlyShortFormatter } from '@i18n/formatters';
import { PopoverMenuAction } from '@components/popover-menu/use-popover-menu';
import { ElipsisButton } from '@components/buttons';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  task: TaskDetailListItemResponse;
  onComplete: () => void;
  onOpen: () => void;
  onEdit?: () => void;
}

const Container = styled('div')({
  padding: '12px 10px',
  border: '1px solid #00000040',
  borderRadius: 5,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  marginBottom: 15,
});

export default function TaskPanelTaskRow({
  task,
  onComplete,
  onOpen,
  onEdit,
}: Props) {
  const menuActions: Array<PopoverMenuAction> = onEdit
    ? [{ label: 'Edit', onClick: onEdit }]
    : [];

  return (
    <Container>
      <Row>
        <TaskCompleteIndicator
          isComplete={task.taskStatus === TaskStatus.complete}
          onComplete={onComplete}
          onOpen={onOpen}
        />
        <Gap size={10} />
        <Typography>{task.title}</Typography>
        <Spacer />
        <PriorityIndicator priority={task.priority} />
        {menuActions.length > 0 && <Gap size={10} />}
        <ElipsisButton actions={menuActions} />
      </Row>
      <Gap size={4} />
      <Row contentAtEnd>
        <InitialsContainerWithData fullName={task.assignedToCompanyUser} />
        <Gap size={15} />
        <DueDateIndicator />
        <Gap size={10} />
        <Typography>{dateOnlyShortFormatter(task.dueDate)}</Typography>
      </Row>
    </Container>
  );
}

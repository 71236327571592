import { ChartStoragePanelState } from '@pages/app/rca/tabs/components/storage/chart-storage-hook';
import Column from '@components/layout-util-components/column';
import { InputAdornment, styled } from '@mui/material';
import { StyledSearchField } from '@components/table/table-filters';
import { ReactComponent as SearchIcon } from '@assets/svgs/search.svg';
import { Gap } from '@components/layout-util-components/gap';
import ChartStorageNode from '@pages/app/rca/tabs/components/storage/chart-storage-node';
import { FlatButton } from '@components/buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { RcaNode, StorageNode } from '@store/rca-editor/types';
import DeleteStorageNodeModal from '@pages/app/rca/tabs/components/modals/delete-storage-node-modal';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { DragEvent, useMemo, useRef, useState } from 'react';
import { RcaUtil } from '@util/rca-util';
import {
  createStorageDragNode,
  stopDraggingNode,
} from '@store/rca-editor/rca-editor-actions';
import { useAppDispatch } from '@store/store';

interface Props {
  state: ChartStoragePanelState;
}

const Container = styled(Column)(() => ({
  flex: '1 0 50%',
  backgroundColor: '#ECEDF0',
  padding: '18px',
  overflowY: 'auto',
}));

const SearchField = styled(StyledSearchField)({
  '& .MuiInputBase-root': {
    background: '#D8DBE3',
    borderRadius: 4,
    boxShadow: '4px 0 20px rgba(0,0,0,0.05)',
  },
});

export default function ChartStorageListView({ state }: Props) {
  const emptyDivRef = useRef<HTMLDivElement>(null);
  const dragNodeRef = useRef<RcaNode>();
  const dispatch = useAppDispatch();
  const { showPopup } = useUiPopup();
  const { nodes } = state;
  const [search, setSearch] = useState<string>();

  const onDeleteNode = (node: StorageNode) => {
    showPopup(DeleteStorageNodeModal, {
      node,
    });
  };

  const onDragStart = (e: DragEvent, node: StorageNode) => {
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setDragImage(emptyDivRef.current!, 10, 10);

    const nodePos = RcaUtil.getNodePosFromCursor(e);
    dragNodeRef.current = dispatch(
      createStorageDragNode(node, nodePos.x, nodePos.y)
    );
  };

  const onDragEnd = () => {
    if (dragNodeRef.current != null) {
      dispatch(stopDraggingNode(dragNodeRef.current));
    }
  };

  const filteredNodes = useMemo(() => {
    return nodes.filter(
      (x) =>
        (x.description ?? '')
          .toLowerCase()
          .indexOf((search ?? '').toLowerCase()) !== -1
    );
  }, [nodes, search]);

  return (
    <>
      <div ref={emptyDivRef} />
      <Container>
        <SearchField
          variant="filled"
          type="search"
          value={search || ''}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search"
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Gap size={15} />
        {filteredNodes.map((node) => (
          <>
            <ChartStorageNode
              key={node.clientUuid}
              description={node.description}
              healthScore={node.healthScore}
              onDragStart={(e) => {
                onDragStart(e, node);
              }}
              onDragEnd={onDragEnd}
              actionButton={
                <FlatButton onClick={() => onDeleteNode(node)}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </FlatButton>
              }
            />
            <Gap size={8} />
          </>
        ))}
      </Container>
    </>
  );
}

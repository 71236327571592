import {
  Box,
  Chip,
  ChipProps,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Typography,
} from '@mui/material';
import { DefaultTFuncReturn } from 'i18next';
import { optionalLabel } from '@util/string-util';
import { useState } from 'react';

type SelectFieldValueType = string | number | readonly string[];

interface SelectFieldItem {
  label: string;
  id?: SelectFieldValueType;
}

interface SelectFieldProps {
  id: string;
  name?: string;
  label?: string | DefaultTFuncReturn;
  options: Array<SelectFieldItem>;
  value?: SelectFieldValueType | Array<SelectFieldValueType>;
  error?: string;
  helperText?: string | DefaultTFuncReturn;
  placeholder?: string | DefaultTFuncReturn;
  required?: boolean;
  onChange: (value: SelectFieldValueType | Array<SelectFieldValueType>) => void;
  onBlur?: () => void;
  multiple?: boolean;
  readonly?: boolean;
  allowTypeSearch?: boolean;
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: 0,
  padding: 0,
  '& .MuiInputBase-root': {
    borderRadius: 4,
    boxSizing: 'border-box',
    border: `2px solid ${theme.palette.common.grey50}`,
    height: 56,
    '&::before': {
      display: 'none',
    },
    '&:hover::before': {
      display: 'none',
    },
    '&.Mui-focused': {
      border: `2px solid ${theme.palette.primary.main}`,
      '&::after': {
        display: 'none',
      },
    },
  },
}));

const StyledChip = styled(Chip)<ChipProps>(({ theme }) => ({
  marginRight: 5,
  marginBottom: 3,
}));

export default function WCTSelectField({
  label,
  id,
  name,
  options,
  value,
  error,
  helperText,
  placeholder,
  required,
  onChange,
  onBlur,
  multiple,
  readonly,
  allowTypeSearch,
}: SelectFieldProps) {
  const hasLabel = !!label;
  const hasError = !!error;
  const hasHelper = !!helperText;

  const [initValue] = useState<
    SelectFieldValueType | Array<SelectFieldValueType> | undefined
  >(value);

  if (
    options.length < 2 &&
    initValue != null &&
    required &&
    (!Array.isArray(initValue) || initValue.length !== 0)
  ) {
    readonly = true;
  }

  const defaultValue = multiple ? [] : '';

  const renderPlaceholder = () => {
    if (!placeholder) {
      return;
    }

    return (
      <Typography
        sx={{
          color: 'rgba(0, 0, 0, 0.38)',
        }}
      >
        {placeholder}
      </Typography>
    );
  };
  const renderChips = () => {
    if (!value || !Array.isArray(value) || value.length === 0) {
      return renderPlaceholder();
    }

    return (
      <Box display="flex" flexWrap="wrap">
        {value.map((v) => (
          <StyledChip
            disabled={readonly}
            key={`${v}`}
            label={options.find((x) => x.id === v)?.label}
            size="small"
            clickable
            onMouseDown={(e) => e.stopPropagation()}
            onDelete={() => {
              onChange(value.filter((x) => x !== v));
            }}
          />
        ))}
      </Box>
    );
  };

  return (
    <StyledFormControl
      error={!!error}
      hiddenLabel={!hasLabel}
      variant="filled"
      className="wct-select"
      required={required}
      fullWidth
    >
      {hasLabel ? (
        <InputLabel
          id={id}
          shrink={value == null ? !!label && !!placeholder : true}
        >
          {optionalLabel(label, required)}
        </InputLabel>
      ) : null}
      <Select
        labelId={hasLabel ? id : undefined}
        id={id}
        name={name}
        value={value || defaultValue}
        label={hasLabel ? optionalLabel(label, required) : undefined}
        error={!!error}
        readOnly={readonly}
        disabled={readonly}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        multiple={multiple}
        renderValue={
          multiple ? renderChips : !value ? renderPlaceholder : undefined
        }
        displayEmpty
      >
        {options.map(({ label, id }) => (
          <MenuItem key={`${id}`} value={id}>
            {label}
          </MenuItem>
        ))}
      </Select>
      {hasError || hasHelper ? (
        <FormHelperText>{error ?? helperText}</FormHelperText>
      ) : null}
    </StyledFormControl>
  );
}

import { useAppSelector } from '@store/store';
import { selectDatetimeShortFormat } from '@store/locale/locale-selector';
import { useMemo } from 'react';
import { i18nDayJs } from '@i18n/index';
import { DefaultTFuncReturn } from 'i18next';
import { DateTimePicker } from '@mui/x-date-pickers';
import { styled } from '@mui/material';
import { DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker/DateTimePicker.types';
import { Dayjs } from 'dayjs';
import { useDebouncedCallback } from 'use-debounce';
import { optionalLabel } from '@util/string-util';

const StyledDateTimePicker = styled(DateTimePicker)<DateTimePickerProps<Dayjs>>(
  ({ theme }) => ({
    maxWidth: 300,
    '& .MuiInputBase-root': {
      borderRadius: '4px',
      boxSizing: 'border-box',
      border: `2px solid ${theme.palette.common.grey50}`,
      minHeight: '56px',
      '&::before': {
        display: 'none',
      },
      '&:hover::before': {
        display: 'none',
      },
      '&.Mui-focused': {
        border: `2px solid ${theme.palette.primary.main}`,
        '&::after': {
          display: 'none',
        },
      },
      '& input:-webkit-autofill': {
        WebkitBackgroundClip: 'text',
      },
      '& input:-webkit-autofill:hover': {
        WebkitBackgroundClip: 'text',
      },
      '& input:-webkit-autofill:focus': {
        WebkitBackgroundClip: 'text',
      },
      '& input:-webkit-autofill:active': {
        WebkitBackgroundClip: 'text',
      },
    },
    '.MuiInputAdornment-positionEnd': {
      position: 'absolute',
      left: '100%',
      button: {
        border: '1px solid #B3BABE',
        borderRadius: 4,
        background: '#B3BABE',
        color: 'white',
      },
    },
  })
);

interface Props {
  label?: string | DefaultTFuncReturn;
  name?: string;
  required?: boolean;
  value?: string;
  error?: string;
  placeholder?: string | DefaultTFuncReturn;
  onChange: (value?: string) => void;
  onBlur?: () => void;
  disablePast?: boolean;
  disableFuture?: boolean;
  readonly?: boolean;
}

export default function WCTDateTimeField({
  name,
  label,
  value,
  required,
  onChange,
  error,
  placeholder,
  onBlur,
  disablePast,
  disableFuture,
  readonly,
}: Props) {
  const format = useAppSelector(selectDatetimeShortFormat);
  const hasError = !!error;

  const onBlurDebounce = useDebouncedCallback(() => onBlur?.call(undefined));

  const valueAsDate = useMemo(() => {
    if (value == null) {
      return null;
    }

    try {
      const date = i18nDayJs(value).utc();
      if (!date.isValid()) {
        return null;
      }

      return date;
    } catch {
      return null;
    }
  }, [value]);

  return (
    <StyledDateTimePicker
      label={optionalLabel(label, required)}
      value={valueAsDate}
      views={['year', 'month', 'day', 'hours', 'minutes']}
      onChange={(v) => {
        const value = i18nDayJs(v);
        if (v == null) {
          onChange(undefined);
        } else if (value.isValid()) {
          onChange(value.startOf('day').toISOString() || undefined);
        }

        onBlurDebounce();
      }}
      format={format}
      disablePast={disablePast}
      disableFuture={disableFuture}
      readOnly={readonly}
      disabled={readonly}
      slotProps={{
        textField: {
          name: name,
          hiddenLabel: !label,
          error: hasError,
          variant: 'filled',
          helperText: error,
          placeholder: placeholder as string | undefined,
          required: required,
          onBlur: onBlur,
          fullWidth: true,
          sx: {
            maxWidth: 225,
          },
        },
      }}
    />
  );
}

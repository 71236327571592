import { faUsers } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';

interface Props {
  onCreateClicked: () => void;
}

export default function NoGroupsInfo({ onCreateClicked }: Props) {
  return (
    <OnboardingEntryContainer
      icon={<FontAwesomeIcon icon={faUsers} size="3x" />}
      title="You don’t currently have any groups"
      message="There aren’t currently any groups within the platform, you can create one below."
      createMessage="Create Group"
      onCreateClicked={onCreateClicked}
    />
  );
}

import { api } from '@api/api';
import { CaseAnalysisType } from '@api/types/case-analysis-type/case-analysis-type';

const companyCaseAnalysisTypeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompanyCaseAnalysisType: build.query<CaseAnalysisType[], void>({
      query: () => ({
        url: 'company/CaseAnalysisType',
        method: 'GET',
      }),
    }),
    companyEnableCaseAnalysisType: build.mutation<void, number>({
      query: (id) => ({
        url: `company/CaseAnalysisType/${id}/enable`,
        method: 'PUT',
      }),
    }),
    companyDisableCaseAnalysisType: build.mutation<void, number>({
      query: (id) => ({
        url: `company/CaseAnalysisType/${id}/disable`,
        method: 'PUT',
      }),
    }),
  }),
});

export const {
  useGetCompanyCaseAnalysisTypeQuery,
  useCompanyEnableCaseAnalysisTypeMutation,
  useCompanyDisableCaseAnalysisTypeMutation,
} = companyCaseAnalysisTypeApi;

import { ImpactsPanelState } from '@pages/app/rca/tabs/components/editor-panels/impacts-panel-hook';
import Column from '@components/layout-util-components/column';
import { Typography } from '@mui/material';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import ImpactValueOption from '@pages/app/rca/tabs/components/editor-panels/components/impacts-panel/impact-value-option';
import { Fragment } from 'react';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  state: ImpactsPanelState;
}

export default function EnterImpactValues({ state }: Props) {
  const {
    actualImpacts,
    potentialImpacts,
    onCancelSelect,
    isSubmitting,
    onSubmitValues,
    hasEnteredAllValues,
    currentValues,
  } = state;

  const filteredActualImpacts = actualImpacts.filter(
    (x) => x.caseImpactId in currentValues
  );
  const filteredPotentialImpacts = potentialImpacts.filter(
    (x) => x.caseImpactId in currentValues
  );

  return (
    <Column>
      {filteredActualImpacts.length > 0 ? (
        <>
          <Typography variant="body1">Actual Effects</Typography>
          <Gap size={15} />
          {filteredActualImpacts.map((impact) => (
            <ImpactValueOption
              key={impact.caseImpactId}
              impact={impact}
              state={state}
              value={currentValues[impact.caseImpactId]}
            />
          ))}
        </>
      ) : null}
      {filteredPotentialImpacts.length > 0 ? (
        <>
          <Typography>Potential Impacts</Typography>
          <Gap size={15} />
          {filteredPotentialImpacts.map((impact) => (
            <ImpactValueOption
              key={impact.caseImpactId}
              impact={impact}
              state={state}
              value={currentValues[impact.caseImpactId]}
            />
          ))}
        </>
      ) : null}
      <ActionsRow>
        <OutlinedButton onClick={onCancelSelect}>Cancel</OutlinedButton>
        <PrimaryButton
          onClick={onSubmitValues}
          disabled={isSubmitting || !hasEnteredAllValues}
          isBusy={isSubmitting}
        >
          Update Impacts
        </PrimaryButton>
      </ActionsRow>
    </Column>
  );
}

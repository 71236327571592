import { isNullOrEmpty } from '@util/string-util';

export function getFullName(firstName?: string, surname?: string) {
  let str = '';
  if (!isNullOrEmpty(firstName)) {
    str += firstName!.trim();
    if (!isNullOrEmpty(surname)) {
      str += ' ';
    }
  }

  if (!isNullOrEmpty(surname)) {
    str += surname!.trim();
  }

  return str;
}

export function getFirstName(fullName?: string) {
  if (isNullOrEmpty(fullName)) {
    return '';
  }

  return fullName!.trim().split(' ')[0];
}

export function getLastName(fullName?: string) {
  if (isNullOrEmpty(fullName)) {
    return '';
  }

  return fullName!.trim().split(' ').slice(1).join(' ');
}

export function getInitials(fullName?: string) {
  if (isNullOrEmpty(fullName)) {
    return '';
  }

  return fullName!
    .split(' ')
    .map((part) => part[0])
    .join('')
    .toUpperCase();
}

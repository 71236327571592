import requireAuth from '@components/hoc/with-required-auth-hoc';
import withEditorScaffold from '@pages/app/rca/tabs/components/rca-editor-scaffold';
import TasksIndexPage from '@pages/app/tasks/tasks-index-page';
import ContentContainer from '@components/containers/content-container';
import { useAppSelector } from '@store/store';
import { selectCurrentRcaCaseId } from '@store/rca-editor/selectors';

function RcaEditorTasks() {
  const caseId = useAppSelector(selectCurrentRcaCaseId);

  return (
    <ContentContainer>
      {/* Simply embed the task page here */}
      <TasksIndexPage caseId={caseId} canAddTask />
    </ContentContainer>
  );
}

export default requireAuth(withEditorScaffold(RcaEditorTasks));

import { Box, Icon, styled, Typography } from '@mui/material';
import Column from '@components/layout-util-components/column';
import FilledBadge from '@components/badges/filled-badge';
import { ReactComponent as GraphIcon } from '@assets/svgs/nodes.svg';
import { CaseSolutionChainItemResource } from '@api/types/case/case-solution/case-solution.resource';
import { BorderedContainer } from '@components/containers/bordered-container';
import { OutlinedButton } from '@components/buttons';
import { useNavigate } from 'react-router-dom';
import Row from '@components/layout-util-components/row';
import HealthBadge from '@components/badges/health-badge';
import { currencyFormatter, dateOnlyShortFormatter } from '@i18n/formatters';
import { useAppSelector } from '@store/store';
import { selectCurrentRcaCurrency } from '@store/rca-editor/selectors';

interface Props {
  chainItem: CaseSolutionChainItemResource;
  caseId: number;
  showHealth?: boolean;
}

const Container = styled(BorderedContainer, {
  target: 'chain-item-solution-item-container',
})({
  '.name': {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.15000000596046448px',
    textAlign: 'left',
  },

  '.cost': {
    '& > b': {
      fontWeight: '500',
    },
  },
});

export default function RelatedCauseBoxCard({
  chainItem,
  caseId,
  showHealth = true,
}: Props) {
  const navigate = useNavigate();
  const firstItem = chainItem?.chainItemSolution?.[0];
  const currency = useAppSelector(selectCurrentRcaCurrency);

  return (
    <Container>
      <Column sx={{ flexGrow: 1, flexShrink: 0, gap: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="body2">{`${
            chainItem.description
          } (${dateOnlyShortFormatter(chainItem.created)})`}</Typography>
          <OutlinedButton
            onClick={() =>
              navigate(
                `/rca/tabs/${caseId}/chart/?chainItem=${chainItem.chainItemId}`
              )
            }
          >
            <Row>
              <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                <GraphIcon />
              </Icon>
              View Analysis
            </Row>
          </OutlinedButton>
        </Box>
        {showHealth && (
          <Box>
            <HealthBadge small health={chainItem?.healthScore} />
          </Box>
        )}
        <Box>
          {!!firstItem?.solutionTypeName && (
            <FilledBadge>{firstItem.solutionTypeName}</FilledBadge>
          )}
          {!!firstItem?.efficacy && (
            <FilledBadge>{`${firstItem.efficacy} Effectiveness`}</FilledBadge>
          )}
          {!!firstItem?.solutionTerm && (
            <FilledBadge>{`${firstItem.solutionTerm} Term`}</FilledBadge>
          )}
          {!!firstItem?.additionalCost && firstItem?.additionalCost !== 0 ? (
            <FilledBadge className="cost">
              +
              {currencyFormatter(firstItem.additionalCost, {
                decimalPlaces: 2,
                currency: currency || '',
              })}
            </FilledBadge>
          ) : null}
        </Box>
      </Column>
    </Container>
  );
}

import { Box, Chip, IconButton, styled, Typography } from '@mui/material';
import ContentFooterCard from '@components/cards/content-footer-card';
import Row from '@components/layout-util-components/row';
import Spacer from '@components/layout-util-components/spacer';
import HealthBadge from '@components/badges/health-badge';
import { timespanFormatter } from '@i18n/formatters';
import { ReactComponent as PinIcon } from '@assets/svgs/location-dot.svg';
import LabelValueDisplay from '@components/data/label-value-display';
import FilledBadge from '@components/badges/filled-badge';
import { CaseItemRowResponse } from '@api/types/case/case-item-row.response';
import { getAddressString } from '@util/string-util';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import GroupedAvatars from '@components/containers/grouped-avatars';
import { Gap } from '@components/layout-util-components/gap';

const ContentButtonRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

const FillColumn = styled('div')({
  flex: 1,
});

interface Props {
  data: CaseItemRowResponse;
  onClick?: () => void;
}

export default function RcaWideCard({ data, onClick }: Props) {
  const navigate = useNavigate();

  const incidentLocation = useMemo(() => {
    const location = data.incidentLocation;
    if (location == null) {
      return;
    }

    return getAddressString({ ...location }) || location.name;
  }, [data.incidentLocation]);

  const onClickRca = () => {
    onClick && onClick();
    navigate(`/rca/tabs/${data.caseId}`);
  };

  return (
    <ContentFooterCard
      widthOverride="100%"
      content={
        <>
          <ContentButtonRow>
            <FillColumn>
              <Row>
                <Chip variant="filled" label={data.caseAnalysisTypeShortName} />
                <Spacer />
                <HealthBadge health={Math.trunc(data.healthScore)} />
              </Row>
              <Gap size={10} />
              <Typography fontSize={20} fontWeight={400} lineHeight="28px">
                {data.name}
              </Typography>
              <Typography variant="caption">
                {`Created ${timespanFormatter(data.created)}`}
              </Typography>
              <Gap size={20} />
              <Typography variant="caption">Incident Location</Typography>
              <Gap size={8} />
              <Row>
                <PinIcon />
                <Gap size={10} />
                <Typography fontSize={14} fontWeight={400} lineHeight="18px">
                  {incidentLocation ? incidentLocation : '-'}
                </Typography>
              </Row>
              <Gap size={20} />
              <Row>
                <LabelValueDisplay
                  label="Status"
                  value={
                    <FilledBadge>
                      {data.caseStatusName ? data.caseStatusName : '-'}
                    </FilledBadge>
                  }
                />
                <Spacer />
                <LabelValueDisplay
                  label="Severity"
                  value={
                    <FilledBadge>
                      {data.caseSeverityName ? data.caseSeverityName : '-'}
                    </FilledBadge>
                  }
                />
              </Row>
              <Box>
                <Gap size={20} />
                <Row spaceBetween>
                  <GroupedAvatars
                    items={data.companyUsers.map(({ companyUserId, name }) => ({
                      id: companyUserId,
                      label: name,
                    }))}
                  />
                  <IconButton onClick={onClickRca} aria-label="View">
                    <FontAwesomeIcon icon={faChevronRight} />
                  </IconButton>
                </Row>
              </Box>
            </FillColumn>
          </ContentButtonRow>
        </>
      }
    />
  );
}

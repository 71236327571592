import { useGetCaseFileDetailQuery } from '@api/endpoints/case-file.api';
import { CaseFileType } from '@api/types/case/case-file/case-file-type';
import { selectCurrentRcaCurrency } from '@store/rca-editor/selectors';
import { useAppSelector } from '@store/store';

export default function useCaseFileViewModal(
  caseId: number,
  caseFileId: number
) {
  const { data: caseFileDetail, isLoading: loadingDetail } =
    useGetCaseFileDetailQuery({ caseId, caseFileId });
  const currency = useAppSelector(selectCurrentRcaCurrency);

  const isFile = caseFileDetail?.caseFileType === CaseFileType.file;
  const isLoading = loadingDetail;

  return {
    caseFileDetail,
    isFile,
    isLoading,
    evidence: caseFileDetail?.evidence ?? [],
    solutions: caseFileDetail?.solutions ?? [],
    chainItems: caseFileDetail?.chainItems ?? [],
    currency,
  };
}

import withModal, { ModalProps } from '@components/ui-popup/modals/modal-hoc';
import { Box, styled, Typography } from '@mui/material';
import { HeaderComponent } from './complete-rca-components.styled';
import Column from '@components/layout-util-components/column';
import Spacer from '@components/layout-util-components/spacer';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import useCompleteRcaModal, {
  CompleteRcaModalPage,
} from '@pages/app/rca/tabs/components/complete-rca/complete-rca-modal-hook';
import RatingPage from '@pages/app/rca/tabs/components/complete-rca/rating-page';
import CollaboratorKudos from '@pages/app/rca/tabs/components/complete-rca/collaborator-kudos';
import FlatBadge from '@components/badges/flat-badge';
import { Gap } from '@components/layout-util-components/gap';

export interface CompleteRcaModalProps extends ModalProps<never> {}

const Container = styled('div')({
  width: 658,
  minHeight: 538,
});

const CompleteRcaModal = ({ onClose }: CompleteRcaModalProps) => {
  const state = useCompleteRcaModal();
  const { page, setPage, isCaseOwner, setRatingAndMoveNext, currentQuestion } =
    state;

  switch (page) {
    case CompleteRcaModalPage.initial:
      return (
        <Container>
          <Column>
            <HeaderComponent />
            <Column
              sx={{ padding: '43px 91px 31px 91px', textAlign: 'center' }}
              noStretch
            >
              <Typography variant="h5">
                Completing RCA problem analysis
              </Typography>
              <Gap size={15} />
              <Typography variant="body2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                consectetur nisi sit amet risus luctus, at ornare odio aliquet.
                Integer orci ante, porttitor eu ipsum sed, finibus ultricies
                odio.
              </Typography>
              <Spacer />
              <ActionsRow center anchorBottom addPadding>
                <OutlinedButton onClick={() => onClose()}>
                  Cancel
                </OutlinedButton>
                <PrimaryButton
                  onClick={() => setPage(CompleteRcaModalPage.questions)}
                >
                  Continue
                </PrimaryButton>
              </ActionsRow>
            </Column>
          </Column>
        </Container>
      );
    case CompleteRcaModalPage.questions:
      return (
        <Container>
          <RatingPage
            state={state}
            onCancel={() => onClose()}
            onNext={setRatingAndMoveNext}
          />
        </Container>
      );
    case CompleteRcaModalPage.teamPerformance:
      return (
        <Container>
          <CollaboratorKudos state={state} onClose={() => onClose()} />
        </Container>
      );
    case CompleteRcaModalPage.complete:
      return (
        <Container>
          <Column>
            <HeaderComponent />
            <Column
              sx={{ padding: '43px 91px 31px 91px', textAlign: 'center' }}
              noStretch
            >
              <Typography variant="h5">
                Thanks for completing your RCA
              </Typography>
              <Gap size={15} />
              <Typography variant="body2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                consectetur nisi sit amet risus luctus, at ornare odio aliquet.
                Integer orci ante, porttitor eu ipsum sed, finibus ultricies
                odio.
              </Typography>
              {isCaseOwner ? (
                <>
                  <Gap size={15} />
                  <Box>
                    <FlatBadge rounded>
                      You have earned 50pts as the RCA owner
                    </FlatBadge>
                  </Box>
                </>
              ) : null}
              <Spacer />
              <ActionsRow center anchorBottom addPadding>
                <OutlinedButton onClick={() => onClose()}>
                  Back to Overview
                </OutlinedButton>
              </ActionsRow>
            </Column>
          </Column>
        </Container>
      );
  }
};

export default withModal(CompleteRcaModal, {
  blurBackdrop: true,
  noPadding: true,
});

import { Button, ButtonProps as MuiButtonProps, styled } from '@mui/material';
import { PropsWithChildren } from 'react';
import { ButtonProps } from './button-props';

const StyledButton = styled(Button, { target: 'link-button' })<MuiButtonProps>({
  minHeight: 24,
  flexShrink: 0,
  textDecoration: 'underline',
});

const LinkButton = ({
  children,
  onClick,
  disabled,
  type,
  href,
}: PropsWithChildren<ButtonProps>) => {
  return (
    <StyledButton
      type={type ?? 'button'}
      variant="text"
      onClick={onClick}
      disabled={disabled}
      href={href}
    >
      {children}
    </StyledButton>
  );
};

export default LinkButton;

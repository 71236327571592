import { useAppSelector } from '@store/store';
import {
  selectChainId,
  selectCurrentRcaCaseId,
  selectFocusedNode,
  selectNodePanelEditorShouldDefaultToCreate,
} from '@store/rca-editor/selectors';
import {
  useGetChainItemCaseImpactsQuery,
  useUpdateChainItemCaseImpactsMutation,
} from '@api/endpoints/chain/chain-item-case-impact.api';
import { useGetCaseImpactsQuery } from '@api/endpoints/case.api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CaseImpactResource } from '@api/types/case/case-impact/case-impact.resource';
import { isApiError } from '@api/types/api-error';
import {
  CaseImpactUpdateItem,
  UpdateChainItemCaseImpactRequest,
} from '@api/types/chain/chain-item-case-impact/update-chain-item-case-impact.request';
import { usePageAlertVariants } from '@components/alerts';

interface ImpactValueHolder {
  [id: number]: number | null;
}

export enum ImpactsPanelView {
  initial,
  selectImpacts,
  enterValues,
}

export default function useImpactsPanel() {
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();
  const defaultToCreate = useAppSelector(
    selectNodePanelEditorShouldDefaultToCreate
  );
  const [view, setView] = useState(
    defaultToCreate ? ImpactsPanelView.selectImpacts : ImpactsPanelView.initial
  );
  const [selectedImpacts, setSelectedImpacts] = useState<
    Array<CaseImpactResource>
  >([]);
  const [currentValues, setCurrentValues] = useState<ImpactValueHolder>({});

  const caseId = useAppSelector(selectCurrentRcaCaseId);
  const chainId = useAppSelector(selectChainId);
  const focusedNode = useAppSelector(selectFocusedNode);
  const { chainItemId } = focusedNode.data;

  const [updateImpacts, { isLoading: isSubmitting }] =
    useUpdateChainItemCaseImpactsMutation();

  const { data: caseImpacts, isLoading: loadingCaseImpacts } =
    useGetCaseImpactsQuery(caseId);

  const { data: impacts, isLoading: loadingImpacts } =
    useGetChainItemCaseImpactsQuery(
      { chainId: chainId ?? -1, chainItemId: chainItemId ?? -1 },
      { skip: chainId == null || chainItemId == null }
    );

  const isLoading = loadingImpacts || loadingCaseImpacts;

  const hasEnteredAllValues = useMemo(() => {
    for (const currentValuesKey in currentValues) {
      if (currentValues[currentValuesKey] == null) {
        return false;
      }
    }

    return true;
  }, [currentValues]);

  const resetSelectedImpacts = useCallback(() => {
    if (caseImpacts == null || impacts == null) {
      setSelectedImpacts([]);
      return;
    }

    setSelectedImpacts(
      caseImpacts.filter((x) =>
        impacts.some((y) => x.caseImpactId === y.caseImpactId)
      )
    );
  }, [caseImpacts, impacts]);

  useEffect(() => {
    resetSelectedImpacts();
  }, [resetSelectedImpacts]);

  const actualImpacts = useMemo(() => {
    if (caseImpacts == null) {
      return [];
    }

    return caseImpacts.filter((x) => x.actualValue === true);
  }, [caseImpacts]);

  const potentialImpacts = useMemo(() => {
    if (caseImpacts == null) {
      return [];
    }

    return caseImpacts.filter((x) => x.actualValue === false);
  }, [caseImpacts]);

  const onBeginAddingImpacts = () => {
    setView(ImpactsPanelView.selectImpacts);
  };

  const onCancelSelect = () => {
    resetSelectedImpacts();
    setView(ImpactsPanelView.initial);
  };

  const isSelected = (impact: CaseImpactResource) => {
    return selectedImpacts.indexOf(impact) !== -1;
  };

  const toggleImpactSelection = (impact: CaseImpactResource) => {
    const idx = selectedImpacts.indexOf(impact);
    if (idx === -1) {
      setSelectedImpacts([...selectedImpacts, impact]);
    } else {
      setSelectedImpacts(
        selectedImpacts.filter((x) => x.caseImpactId !== impact.caseImpactId)
      );
    }
  };

  const submit = async (caseImpacts: Array<CaseImpactUpdateItem>) => {
    try {
      await updateImpacts({
        chainId: chainId!,
        chainItemId: chainItemId!,
        caseImpacts,
      }).unwrap();

      if (caseImpacts.length === 0) {
        showSuccessMessage(
          `You have successfully updated impacts for Cause Box #${chainItemId}`
        );
      } else {
        showSuccessMessage(
          `You have successfully added impacts to Cause Box #${chainItemId}`
        );
      }

      return true;
    } catch (error) {
      if (isApiError<UpdateChainItemCaseImpactRequest>(error)) {
        const { message, errors } = error;
        showErrorMessage(
          errors?.chainId ??
            errors?.chainItemId ??
            errors?.caseImpacts ??
            message
        );
      }

      return false;
    }
  };

  const onBeginEnterValues = async (): Promise<boolean> => {
    if (selectedImpacts.length === 0) {
      const didSubmit = await submit([]);
      if (didSubmit) {
        setView(ImpactsPanelView.initial);
      }

      return didSubmit;
    }

    const holder: ImpactValueHolder = {};
    for (const caseImpact of selectedImpacts) {
      const chosenValue = impacts!.find(
        (x) => x.caseImpactId === caseImpact.caseImpactId
      );
      holder[caseImpact.caseImpactId] = chosenValue?.impactValue ?? null;
    }

    setCurrentValues(holder);
    setView(ImpactsPanelView.enterValues);
    return true;
  };

  const onSubmitValues = async (): Promise<boolean> => {
    const arr: Array<CaseImpactUpdateItem> = [];
    for (const currentValuesKey in currentValues) {
      const val = currentValues[currentValuesKey];
      arr.push({ caseImpactId: +currentValuesKey, impactValue: val! });
    }

    const didSubmit = await submit(arr);
    if (didSubmit) {
      setView(ImpactsPanelView.initial);
    }
    return true;
  };

  const updateValue = (impactId: number, value?: number) => {
    setCurrentValues((v) => ({ ...v, [impactId]: value ?? null }));
  };

  return {
    caseImpacts,
    actualImpacts,
    potentialImpacts,
    impacts,
    isLoading,
    onBeginAddingImpacts,
    view,
    isSelected,
    onCancelSelect,
    toggleImpactSelection,
    onBeginEnterValues,
    isSubmitting,
    updateValue,
    onSubmitValues,
    currentValues,
    hasEnteredAllValues,
  };
}

export type ImpactsPanelState = ReturnType<typeof useImpactsPanel>;

import { forwardRef } from 'react';
import { styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faEllipsisH } from '@fortawesome/pro-light-svg-icons';
import { CircleButton } from '@components/buttons';

interface Props {
  onElipsisClick?: () => void;
}

const Container = styled('div')((theme) => ({
  zIndex: 100,
  position: 'absolute',
  top: '50%',
  left: '100%',
  transform: 'translate(0, -50%)',
  backgroundColor: '#45C596',
  maxHeight: 42,
  color: 'white',
  padding: 6,
  margin: 0,
  marginLeft: 21,
  borderRadius: 72,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '12px',

  '& > svg': {
    width: 30,
    height: 30,
  },

  '& > button': {
    width: 25,
    height: 25,
    backgroundColor: '#00000033',
    color: 'white',
    border: 'none',
  },

  '.fake-edge': {
    position: 'absolute',
    left: '-24px',
    top: '50%',
    transform: 'translate(0, -50%)',
    width: 25,
    height: 3,
    margin: 0,
    padding: 0,
    backgroundColor: 'inherit',
  },
}));

export default forwardRef<HTMLButtonElement, Props>(function (
  { onElipsisClick },
  buttonRef
) {
  return (
    <Container>
      <div className="fake-edge" />
      <FontAwesomeIcon icon={faCircleCheck} />
      {onElipsisClick ? (
        <CircleButton ref={buttonRef} onClick={onElipsisClick}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </CircleButton>
      ) : null}
    </Container>
  );
});

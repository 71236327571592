import { LensesPanelState } from '@pages/app/rca/tabs/lenses/lenses-panel-hook';
import { DrawerContent, DrawerHeader } from '@components/ui-popup/drawers';
import { Divider } from '@components/layout-util-components/divider';
import RecentLenseOption from '@pages/app/rca/tabs/lenses/components/recent-lense-option';
import { LoadingIndicator } from '@components/loading-indicator';
import { Fragment } from 'react';
import { datetimeShortFormatter } from '@i18n/formatters';
import { useAppDispatch } from '@store/store';
import { showReportById } from '@store/reports/reports-actions';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  onClose: () => void;
  state: LensesPanelState;
}

export default function AllRecentLensesView({ onClose, state }: Props) {
  const dispatch = useAppDispatch();
  const { backToOptionsView, allReports, isLoading } = state;

  return (
    <>
      <DrawerHeader onBack={backToOptionsView} onClose={onClose}>
        Recent Lenses
      </DrawerHeader>
      <Divider addMargin compact negativeMargin={-24} />
      <DrawerContent>
        {isLoading ? (
          <LoadingIndicator show />
        ) : (
          allReports!.model.map((report) => (
            <Fragment key={report.reportId}>
              <RecentLenseOption
                title={report.reportTypeName}
                tag={`${report.reportSubTypeName} report`}
                createdBy={report.createdByCompanyUser}
                createdAt={report.created}
                onClick={() => {
                  onClose();
                  dispatch(showReportById(report.reportId));
                }}
              />
              <Gap size={15} />
            </Fragment>
          ))
        )}
      </DrawerContent>
    </>
  );
}

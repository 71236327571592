import { api } from '@api/api';
import { ReportTypeOption } from '@api/types/report-type/report-type.option';
import { ReportSubtypeOption } from '@api/types/report-type/report-subtype.option';
import { ReportRankByOption } from '@api/types/report-type/report-rank-by.option';
import { ReportRankBySearchRequest } from '@api/types/report-type/report-rank-by-search.request';

const reportTypeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getReportTypeOptions: build.query<ReportTypeOption[], void>({
      query: () => ({
        url: 'reportType',
      }),
    }),
    getReportSubtypeOptions: build.query<ReportSubtypeOption[], number>({
      query: (reportTypeId) => ({
        url: 'reportSubType',
        params: {
          reportTypeId,
        },
      }),
    }),
    getReportRankByOptions: build.query<ReportRankByOption[], number>({
      query: (reportSubTypeId) => ({
        url: 'reportRankBy',
        params: {
          reportSubTypeId,
        },
      }),
    }),
    searchReportRankByOption: build.query<
      ReportRankByOption[],
      ReportRankBySearchRequest
    >({
      query: (params) => ({
        url: 'reportRankBy/search',
        params,
      }),
    }),
  }),
});

export const {
  useGetReportTypeOptionsQuery,
  useGetReportSubtypeOptionsQuery,
  useGetReportRankByOptionsQuery,
  useSearchReportRankByOptionQuery,
} = reportTypeApi;

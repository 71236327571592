import { api } from '@api/api';
import { CaseImpactTypeMetricsResource } from '@api/types/case-impact-type-metric/case-impact-type-metric.resource';
import { Option } from '@api/types/option';

const caseImpactTypeMetricApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMetricDetail: build.query<CaseImpactTypeMetricsResource, number>({
      query: (metricId) => ({
        url: `caseImpactTypeMetric/${metricId}`,
      }),
    }),
    getMetricOptions: build.query<Option[], number>({
      query: (metricId) => ({
        url: `caseImpactTypeMetric/${metricId}/options`,
      }),
    }),
  }),
});

export const { useGetMetricDetailQuery, useGetMetricOptionsQuery } =
  caseImpactTypeMetricApi;

export default caseImpactTypeMetricApi;

import SectionScaffold from '@components/outcome-report/components/section-scaffold';
import { Box, styled } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { OutlinedButton } from '@components/buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import Row from '@components/layout-util-components/row';
import Spacer from '@components/layout-util-components/spacer';
import { OutcomeSolutionOverviewResponse } from '@api/types/case/case-outcome/outcome-report-types/outcome-solution-overview.response';
import { RunReportResponse } from '@api/types/case/case-report/run-report.response';
import DonutChart from 'react-donut-chart';
import { getColorForId } from '@util/colour-identifiers';
import { Circle } from '@mui/icons-material';
import FilledBadge from '@components/badges/filled-badge';
import AssociatedCauseBoxBadge from '@components/badges/associated-cause-box-badge';
import BadgeInfo from '@components/outcome-report/components/badge-info';
import { Gap } from '@components/layout-util-components/gap';
import { currencyFormatter } from '@i18n/formatters';

interface CoverageMetaData {
  solutionId: number;
  chainItems: Record<string, string>;
  rank: number;
}

interface CoverageTotals {
  chainItemCount: number;
  coveragePercent: number;
  coverageShare: Array<number>;
}

interface Props {
  solutionCount: number;
  solutions: Array<OutcomeSolutionOverviewResponse>;
  coverage: RunReportResponse<CoverageMetaData, CoverageTotals>;
  effectivnessReport: RunReportResponse<
    {
      solutionId: number;
      solutionTermName?: string;
      chainItems: Record<string, string>;
      rank: number;
    },
    {
      chainItemCount: number;
      coveragePercent: number;
      coverageShare: Array<number>;
    }
  >;
  termReport: RunReportResponse<
    {
      solutionId?: number;
      solutionTermId?: number;
      solutionTermName: string;
      chainItems: Record<string, string>;
      rank: number;
    },
    {
      chainItemCount: number;
      coveragePercent: number;
      coverageShare: Array<number>;
    }
  >;
  currency?: string;
}

// Grid layout split into 2 columns,
// each column takes up 1/2 of the space and has 1 row
const Container = styled('div')(({ theme: { palette } }) => ({
  padding: '80px 8%',
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'stretch',

  '.section-title': {
    color: '#0000004D',
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: '400',
    lineHeight: '30px',
    letterSpacing: '0.15000000596046448px',
    textAlign: 'left',
  },

  '.overview-title': {
    maxWidth: '50%',
    fontFamily: 'Roboto',
    fontSize: '40px',
    fontWeight: '400',
    lineHeight: '53px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: palette.common.black,

    '.highlight': {
      color: '#848484',
    },
  },

  '.solutions-grid': {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '24px',
    alignItems: 'stretch',
  },
}));

export default function SolutionsSection({
  solutions,
  solutionCount,
  coverage,
  effectivnessReport,
  termReport,
  currency = 'GBP',
}: Props) {
  const { caseId } = useParams();
  const navigate = useNavigate();

  var removeCount: number = 0;
  const termStats: Array<RadialOverviewStat> = termReport.metaData.map(
    (term, index) => {
      const coverage = termReport.totals.coverageShare?.[index] ?? 0;
      if (!term.solutionTermName) {
        removeCount++;
      }
      return {
        id: index - removeCount,
        name: term.solutionTermName,
        percentage: coverage,
        rank: term.rank,
      };
    }
  );

  removeCount = 0;
  const effectivnessStats: Array<RadialOverviewStat> =
    effectivnessReport.metaData.map((term, index) => {
      const coverage = termReport.totals.coverageShare?.[index] ?? 0;
      if (!term.solutionId) {
        removeCount++;
      }

      return {
        id: index - removeCount,
        name: term.solutionTermName ?? 'No Name',
        percentage: coverage,
        rank: term.rank,
      };
    });

  const solutionCards: Array<SolutionCardProps> = solutions.map((solution) => ({
    name: solution.name,
    cost: solution.cost,
    description: solution.description,
    associatedCauseBoxCount: solution.chainItems?.length ?? 0,
    currency,
  }));

  return (
    <SectionScaffold id="closing-statement" backgroundColor="#FFFFFF">
      <Container>
        <div className="section-title">Solutions</div>
        <Gap size={20} />
        <Row>
          <div className="overview-title">
            There have been <span className="highlight">{solutionCount}</span>{' '}
            solutions generated and theres{' '}
            <span className="highlight">
              {coverage.totals.coveragePercent}%
            </span>{' '}
            coverage
          </div>
          <Spacer />
          <Box alignSelf="flex-start">
            <OutlinedButton
              onClick={() => navigate(`/rca/tabs/${caseId}/solutions`)}
            >
              View Solutions <FontAwesomeIcon icon={faArrowRight} />
            </OutlinedButton>
          </Box>
        </Row>
        <Gap size={64} />
        <Row>
          <ReportRadialOverview name="Term" stats={termStats} />
          <Spacer />
          <ReportRadialOverview
            name="Effectiveness"
            stats={effectivnessStats}
          />
        </Row>
        <Gap size={64} />
        <div className="solutions-grid">
          {solutionCards.map((solution, index) => (
            <SolutionCard key={index} {...solution} />
          ))}
        </div>
        <Gap size={64} />
        <Box alignSelf="center">
          <OutlinedButton
            onClick={() => navigate(`/rca/tabs/${caseId}/solutions`)}
          >
            View More Solutions <FontAwesomeIcon icon={faArrowRight} />
          </OutlinedButton>
        </Box>
      </Container>
    </SectionScaffold>
  );
}

interface RadialOverviewStat {
  id: number;
  name: string;
  percentage: number;
  rank: number;
}

interface ReportRadialOverviewProps {
  name: string;
  stats: Array<RadialOverviewStat>;
}

const DONUT_SIZE = 279;
const ReportRadialOverviewContainer = styled('div')(
  ({ theme: { palette } }) => ({
    '.donut-container': {
      position: 'relative',

      '.donut-chart': {
        maxHeight: DONUT_SIZE,
      },

      '.chart-title': {
        position: 'absolute',
        textAlign: 'center',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontFamily: 'Roboto',
        fontSize: '19px',
        fontWeight: '500',
        lineHeight: '25px',
        letterSpacing: '0px',
      },
    },

    '.stat-container': {
      gap: 24,
      display: 'flex',
      flexDirection: 'column',

      '.item': {
        border: '1px solid #E7E7E7',
        boxShadow: '0px 5px 13px 0px #00000030',
        background: palette.common.white,
        borderRadius: '25px',
        padding: 12,

        '.stat-name': {
          fontFamily: 'Roboto',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '20px',
          letterSpacing: '0.17000000178813934px',
          textAlign: 'left',
          color: '#1F1F1F',
        },

        '.stat-value': {
          fontFamily: 'Roboto',
          fontSize: '14px',
          fontWeight: '700',
          lineHeight: '20px',
          letterSpacing: '0.17000000178813934px',
          textAlign: 'left',
          color: '#1F1F1F',
        },
      },
    },
  })
);

const ReportRadialOverview = ({
  name,
  stats: nonFilteredStats,
}: ReportRadialOverviewProps) => {
  const stats = nonFilteredStats.filter((x) => x.id !== -1);

  return (
    <ReportRadialOverviewContainer>
      <Row>
        <div className="donut-container">
          <DonutChart
            width={DONUT_SIZE}
            height={DONUT_SIZE}
            legend={false}
            interactive={false}
            strokeColor="transparent"
            className="donut-chart"
            colorFunction={(_, index) => getColorForId(index)}
            data={stats.map((stat) => ({
              label: stat.name,
              value: stat.percentage,
            }))}
          />
          <div className="chart-title">{name}</div>
        </div>

        <Gap size={50} />
        <div className="stat-container">
          {stats.map((stat) => (
            <Row key={stat.id} className="item">
              <Circle
                sx={{ color: getColorForId(stat.id), width: 17, height: 17 }}
              />
              <Gap size={12} />
              <div className="stat-name">{stat.name}</div>
              <Gap size={12} />
              <div className="stat-value">
                {stat.percentage}% ({stat.rank})
              </div>
            </Row>
          ))}
        </div>
      </Row>
    </ReportRadialOverviewContainer>
  );
};

interface SolutionCardProps {
  type?: string;
  name: string;
  description?: string;
  associatedCauseBoxCount: number;
  effectivness?: string;
  term?: string;
  cost?: number;
  currency?: string;
}

const SolutionCardContainer = styled('div')(({ theme: { palette } }) => ({
  background: palette.common.grey15,
  borderRadius: 12,
  padding: 32,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',

  '.name': {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '26px',
    letterSpacing: '0.17000000178813934px',
    textAlign: 'left',
    color: palette.common.black,
  },

  '.description': {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '23px',
    letterSpacing: '0.17000000178813934px',
    textAlign: 'left',
    color: palette.common.grey60,
  },
}));

const SolutionCard = ({
  associatedCauseBoxCount,
  cost,
  description,
  effectivness,
  name,
  term,
  type,
  currency = 'GBP',
}: SolutionCardProps) => {
  return (
    <SolutionCardContainer>
      {type && (
        <>
          <FilledBadge>{type}</FilledBadge>
          <Gap size={15} />
        </>
      )}
      <div className="name">{name}</div>
      {description && (
        <>
          <Gap size={4} />
          <div className="description">{description}</div>
        </>
      )}
      {!!associatedCauseBoxCount && (
        <>
          <Gap size={15} />
          <AssociatedCauseBoxBadge count={associatedCauseBoxCount} />
        </>
      )}
      <Gap size={50} />
      <Box alignSelf="stretch">
        <Row>
          {effectivness && (
            <>
              <BadgeInfo label="Effectiveness" value={effectivness} />
              <Spacer />
            </>
          )}
          {term && (
            <>
              <BadgeInfo label="Term" value={term} />
              <Spacer />
            </>
          )}
          {cost != null && (
            <>
              <BadgeInfo
                label="Cost"
                value={currencyFormatter(cost.toString(), {
                  decimalPlaces: 2,
                  currency,
                })}
              />
            </>
          )}
        </Row>
      </Box>
    </SolutionCardContainer>
  );
};

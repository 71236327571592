import usePermission from '@store/user/permission-hook';
import { Permission } from '@api/types/permission';
import { useState } from 'react';

export default function useGroups() {
  const [seeMyGroups, setSeeMyGroups] = useState<boolean>(false);
  const canManageGroups = usePermission(Permission.manageCompanyGroups);

  return {
    seeMyGroups,
    setSeeMyGroups,
    canManageGroups,
  };
}

import { InlineBoxSkeleton } from '@components/skeletons';
import WCTSwitchField, {
  SwitchContainer,
} from '@components/input/switch-field';
import { Box, Chip, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import { dateOnlyShortFormatter } from '@i18n/formatters';
import { ConfirmationModal } from '@components/ui-popup/modals';
import { useState } from 'react';
import { Gap } from '@components/layout-util-components/gap';

export interface ReferenceDataDisableListOption {
  id: number;
  label: string;
  enabled: boolean;
  hasWarning: boolean;
  disabledDateTime?: Date;
}

interface Props {
  isLoading: boolean;
  options: ReferenceDataDisableListOption[];
  onEnable: (id: number) => void;
  onDisable: (id: number) => void;
  type: string;
}

export default function ReferenceDataDisableList({
  isLoading,
  options,
  onEnable,
  onDisable,
  type,
}: Props) {
  const [selected, setSelected] = useState<number>();

  return (
    <>
      {options.map((o) => (
        <>
          <SwitchContainer variant="light">
            {isLoading ? (
              <InlineBoxSkeleton />
            ) : (
              <>
                <Typography sx={{ flex: 1 }}>{o.label}</Typography>
                <WCTSwitchField
                  name={`theme-toggle-${o.id}`}
                  label={o.enabled ? 'Active' : 'Disabled'}
                  value={o.enabled}
                  onChange={(enabled: boolean) => {
                    if (!enabled) {
                      setSelected(o.id);
                    } else {
                      onEnable(o.id);
                    }
                  }}
                />
                <Box sx={{ flex: 1, textAlign: 'right' }}>
                  {o.disabledDateTime && (
                    <Chip
                      icon={<FontAwesomeIcon icon={faPen} />}
                      label={`Edited ${dateOnlyShortFormatter(
                        o.disabledDateTime
                      )}`}
                    />
                  )}
                </Box>
              </>
            )}
          </SwitchContainer>
          <Gap size={15} />
        </>
      ))}
      {selected && (
        <ConfirmationModal
          show={true}
          title={`Are you sure you want to disable this ${type}?`}
          message={
            options.find((x) => x.id === selected)?.hasWarning
              ? `Disabling this ${type} will make it unavailable to select when creating a new RCA, but will remain active where previously selected on all existing RCA investigations.`
              : undefined
          }
          yesButtonAsyncAction={async () => {
            await onDisable(selected);
            return true;
          }}
          onClose={() => setSelected(undefined)}
        />
      )}
    </>
  );
}

import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Typography,
  IconButton,
  SxProps,
  Theme,
  styled,
} from '@mui/material';
import { IHelpCentreContent } from '@api/types/help-center/help-center-content';
import { FaqsList } from './faqs-list';
import { useNavigate } from 'react-router-dom';

const StyledFAQHeaderWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

interface Props {
  sx?: SxProps<Theme>;
  faqs: IHelpCentreContent[];
  label?: string;
  showAll?: boolean;
  defaultId?: number;
}

export const HelpCenterFAQs = ({
  sx,
  faqs,
  label = 'Frequently Asked Questions',
  showAll = true,
  defaultId,
}: Props) => {
  const navigate = useNavigate();

  if (!faqs.length) {
    return null;
  }

  return (
    <Box sx={sx}>
      <StyledFAQHeaderWrapper>
        <h2>{label}</h2>
        {showAll && (
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <Typography fontWeight={500}>View All</Typography>
            <IconButton onClick={() => navigate('faq')} aria-label="View all">
              <FontAwesomeIcon icon={faChevronRight} />
            </IconButton>
          </Box>
        )}
      </StyledFAQHeaderWrapper>
      <FaqsList faqs={faqs} defaultId={defaultId} />
    </Box>
  );
};

import { StepperStep } from '@components/stepper/use-stepper-hook';
import {
  Step as MuiStep,
  StepLabel as MuiStepLabel,
  Stepper as MuiStepper,
} from '@mui/material';
import { v4 as uuid } from 'uuid';

interface Props {
  activeStep: number;
  steps: Array<StepperStep<any>>;
}

export default function Stepper({ activeStep, steps }: Props) {
  return (
    <MuiStepper activeStep={activeStep}>
      {steps.map((step) => (
        <MuiStep key={uuid()}>
          <MuiStepLabel>{step.title}</MuiStepLabel>
        </MuiStep>
      ))}
    </MuiStepper>
  );
}

import { api } from '@api/api';
import { PaginatedResult } from '@api/types/paginated-result';
import { CompanyRowResponse } from '@api/types/admin/company-row.response';
import { CompanyTableFilters } from '@api/types/admin/company-table.filters';
import { Option } from '@api/types/option';

const adminCompanyApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAdminCompanies: build.query<
      PaginatedResult<CompanyRowResponse>,
      CompanyTableFilters
    >({
      query: (filters) => ({
        url: 'admin/company',
        params: filters,
      }),
    }),
    getAdminCompanyOptions: build.query<Option[], void>({
      query: () => ({
        url: 'admin/company/options',
      }),
    }),
  }),
});

export const { useGetAdminCompaniesQuery, useGetAdminCompanyOptionsQuery } =
  adminCompanyApi;

import { useState } from 'react';
import { useGetCompleteCaseQuestionsQuery } from '@api/endpoints/complete-case-question.api';
import {
  useCloseCaseMutation,
  useGetCaseDetailQuery,
} from '@api/endpoints/case.api';
import { ApiError } from '@api/types/api-error';
import {
  CaseKudos,
  CaseQuestionAnswer,
  CloseCaseRequest,
} from '@api/types/case/close-case.request';
import { useAppSelector } from '@store/store';
import { selectCurrentRcaCaseId } from '@store/rca-editor/selectors';
import { usePageAlertVariants } from '@components/alerts';
import useUser from '@store/user/user-hook';

export enum CompleteRcaModalPage {
  initial,
  questions,
  teamPerformance,
  complete,
}

export default function useCompleteRcaModal() {
  const { companyUserId } = useUser();
  const { showErrorMessage } = usePageAlertVariants();
  const caseId = useAppSelector(selectCurrentRcaCaseId);

  const { data: caseDetail, isLoading: loadingCaseDetail } =
    useGetCaseDetailQuery(caseId);

  const { data: questions, isLoading: loadingQuestions } =
    useGetCompleteCaseQuestionsQuery();

  const [closeCase, { isLoading: isSubmitting }] = useCloseCaseMutation();

  const [page, setPage] = useState<CompleteRcaModalPage>(
    CompleteRcaModalPage.initial
  );

  // Dictionary that holds the ratings for each question
  const [ratings, setRatings] = useState<Record<number, number>>({});
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);

  const [pointsAllocated, setPointsAllocated] = useState<
    Record<number, number>
  >({});

  const [additionalPointsAvailable, setAdditionalPointsAvailable] =
    useState(40);

  const isCaseOwner = caseDetail?.createdByCompanyUserId === companyUserId;
  const canGiveMorePoints = additionalPointsAvailable > 0;

  const isLoading = loadingQuestions || loadingCaseDetail;
  const canSubmit = !isSubmitting && !isLoading;

  const setRatingAndMoveNext = (rating: number) => {
    setRatings((prev) => ({
      ...prev,
      [currentQuestion]: rating,
    }));

    if (currentQuestion + 1 < questions!.length) {
      setCurrentQuestion((prev) => prev + 1);
    } else {
      setPage(CompleteRcaModalPage.teamPerformance);
    }
  };

  const complete = () => {
    const kudos: Array<CaseKudos> = Object.keys(pointsAllocated).map((key) => ({
      companyUserId: parseInt(key),
      // TODO(Nick): This should accept a number, not a boolean?
      // points: pointsAllocated[parseInt(key)],
      kudos: true,
    }));

    const questionAnswers: Array<CaseQuestionAnswer> = Object.keys(ratings).map(
      (key) => ({
        questionId: questions![parseInt(key)].questionId,
        optionId: ratings[parseInt(key)],
      })
    );

    return closeCase({
      caseId,
      kudos,
      questionAnswers,
    })
      .unwrap()
      .then(() => {
        setPage(CompleteRcaModalPage.complete);
        return true;
      })
      .catch(({ errors, message }: ApiError<CloseCaseRequest>) => {
        showErrorMessage(
          errors?.caseId ?? errors?.kudos ?? errors?.questionAnswers ?? message
        );
        return false;
      });
  };

  const givePointsTo = (id: number) => {
    const pointsToGive = 5;
    setPointsAllocated((prev) => ({
      ...prev,
      [id]: prev[id] ? prev[id] + pointsToGive : pointsToGive,
    }));

    setAdditionalPointsAvailable((prev) => prev - pointsToGive);
  };

  const getPointsFor = (id: number) => (pointsAllocated[id] || 0) + 20;

  return {
    page,
    setPage,
    setRatingAndMoveNext,
    complete,
    givePointsTo,
    totalAdditionalPointsAward: 40 - additionalPointsAvailable,
    canGiveMorePoints,
    additionalPointsAvailable,
    getPointsFor,
    questions,
    isLoading,
    currentQuestion: questions?.[currentQuestion],
    canSubmit,
    isSubmitting,
    isCaseOwner,
  };
}

export type CompleteRcaModalState = ReturnType<typeof useCompleteRcaModal>;

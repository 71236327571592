import useField from '@hooks/use-field-hook';
import { onlyNumbers, required } from '@util/validators';
import { useBinaryOptions } from '@util/constant-options';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { useGetCasePriorityOptionsQuery } from '@api/endpoints/case-priority.api';
import { useGetCaseSeverityOptionsQuery } from '@api/endpoints/case-severity.api';
import { useGetFrequencyTypeOptionsQuery } from '@api/endpoints/frequency-type.api';
import { useGetCompanyLocationDetailOptionsQuery } from '@api/endpoints/company-location.api';
import { useMemo } from 'react';
import { useGetCaseTypeOptionsQuery } from '@api/endpoints/case-type.api';
import {
  useGetCaseDetailQuery,
  useUpsertCaseOverviewMutation,
} from '@api/endpoints/case.api';
import { UpsertCaseOverviewRequest } from '@api/types/case/upsert-case-overview.request';
import { ApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';
import { useAppDispatch, useAppSelector } from '@store/store';
import { selectCreateAnalysisType } from '@store/create-analysis-process/selectors';
import { setCurrentCase } from '@store/create-analysis-process/create-analysis-slice';
import { CompanyLocationResource } from '@api/types/company/company-location/company-location.resource';
import { useIsPath } from '@util/path-util';
import useSystemText from '@hooks/use-system-text';

export default function useOverviewStep(caseId?: number) {
  const isEdit = useIsPath('/rca/edit', { startsWith: true });
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();
  const dispatch = useAppDispatch();
  const { systemText } = useSystemText();

  const { data: caseTypeOptions, isLoading: loadingCaseTypeOptions } =
    useGetCaseTypeOptionsQuery();
  const { data: priorityOptions, isLoading: loadingPriorityOptions } =
    useGetCasePriorityOptionsQuery();
  const { data: severityOptions, isLoading: loadingSeverityOptions } =
    useGetCaseSeverityOptionsQuery();
  const { data: frequencyTypeOptions, isLoading: loadingFrequencyTypeOptions } =
    useGetFrequencyTypeOptionsQuery();
  const {
    data: companyLocationOptions,
    isLoading: loadingCompanyLocationOptions,
  } = useGetCompanyLocationDetailOptionsQuery();

  const { data } = useGetCaseDetailQuery(caseId ?? -1, {
    skip: caseId == null,
  });

  const [upsert, { isLoading: isSubmitting }] = useUpsertCaseOverviewMutation();

  const caseAnalysisType =
    useAppSelector(selectCreateAnalysisType) ?? data?.caseAnalysisTypeId;
  const investigationName = useField<string>([required()], data?.name);
  const description = useField<string>([required()], data?.description);
  const focalPoint = useField<string>([required()], data?.focalPoint);
  const caseType = useField<Array<number>>(
    [required()],
    useMemo(() => data?.caseTypes.map(({ id }) => id) ?? [], [data?.caseTypes])
  );
  const priority = useField<number>([required()], data?.casePriorityId);
  const severity = useField<number>([required()], data?.caseSeverityId);
  const startDate = useField<string>([required()], data?.startDate);
  const endDate = useField<string>([], data?.endDate);
  const frequency = useField<string>(
    [onlyNumbers()],
    data?.frequencyValue.toString()
  );
  const duration = useField<number>([], data?.frequencyTypeId);
  const frequencySummary = useField<string>([], data?.frequencyNotes);
  const reportingLocation = useField<CompanyLocationResource>(
    [required()],
    useMemo(() => {
      return data?.reportingLocation as CompanyLocationResource;
    }, [data?.reportingLocation])
  );
  const incidentAtReportingLocation = useField<boolean>(
    [required()],
    useMemo(() => {
      if (data == null) {
        return undefined;
      }

      return (
        data.reportingLocation.companyLocationId ===
        data.incidentLocation?.companyLocationId
      );
    }, [data])
  );
  const incidentLocation = useField<CompanyLocationResource>(
    [
      required({
        when: () =>
          incidentAtReportingLocation.value != null &&
          !incidentAtReportingLocation.value,
      }),
    ],
    useMemo(() => {
      return data?.incidentLocation as CompanyLocationResource;
    }, [data?.incidentLocation])
  );

  const { isValid, isDirty, validateAll } = useFieldsWatcher([
    investigationName,
    description,
    focalPoint,
    caseType,
    priority,
    severity,
    startDate,
    endDate,
    frequency,
    duration,
    frequencySummary,
    reportingLocation,
    incidentAtReportingLocation,
    incidentLocation,
  ]);

  const isLoading =
    loadingPriorityOptions ||
    loadingSeverityOptions ||
    loadingFrequencyTypeOptions ||
    loadingCompanyLocationOptions ||
    loadingCaseTypeOptions;

  const canSubmit =
    !isSubmitting && !isLoading && isValid && (!isEdit || isDirty);

  const binaryOptions = useBinaryOptions();

  const onSubmit = () => {
    if (!validateAll()) {
      return false;
    }

    return upsert({
      caseId,
      caseAnalysisTypeId: caseAnalysisType! as number,
      name: investigationName.value,
      description: description.value,
      focalPoint: focalPoint.value,
      caseTypes: caseType.value,
      casePriorityId: priority.value,
      caseSeverityId: severity.value,
      startDate: startDate.value,
      endDate: endDate.value,
      frequencyValue: +frequency.value,
      frequencyTypeId: duration.value,
      frequencyNotes: frequencySummary.value,
      reportingLocationId: reportingLocation.value.companyLocationId,
      sameLocationForIncident: incidentAtReportingLocation.value,
      incidentLocationId: incidentLocation.value?.companyLocationId,
    })
      .unwrap()
      .then((resource) => {
        if (isEdit) {
          showSuccessMessage('Updated overview');

          // Hard reload so chain is fresh
          window.location.reload();
        }
        dispatch(setCurrentCase(resource));

        return true;
      })
      .catch(({ message, errors }: ApiError<UpsertCaseOverviewRequest>) => {
        showErrorMessage(errors?.caseId ?? errors?.chainItemId ?? message);

        investigationName.setError(errors?.name);
        description.setError(errors?.description);
        focalPoint.setError(errors?.focalPoint);
        caseType.setError(errors?.caseTypes);
        priority.setError(errors?.casePriorityId);
        severity.setError(errors?.caseSeverityId);
        startDate.setError(errors?.startDate);
        endDate.setError(errors?.endDate);
        frequency.setError(errors?.frequencyValue);
        duration.setError(errors?.frequencyTypeId);
        frequencySummary.setError(errors?.frequencyNotes);
        reportingLocation.setError(errors?.reportingLocationId);
        incidentAtReportingLocation.setError(errors?.sameLocationForIncident);
        incidentLocation.setError(errors?.incidentLocationId);

        return false;
      });
  };

  return {
    investigationName,
    description,
    focalPoint,
    rcaType: caseType,
    priority,
    severity,
    startDate,
    endDate,
    frequency,
    duration,
    frequencySummary,
    reportingLocation,
    incidentAtReportingLocation,
    incidentLocation,
    binaryOptions,
    caseTypeOptions,
    frequencyTypeOptions,
    companyLocationOptions,
    priorityOptions,
    severityOptions,
    isValid,
    isLoading,
    isSubmitting,
    isEdit,
    canSubmit,
    systemText,
    onSubmit,
  };
}

export type OverviewStepState = ReturnType<typeof useOverviewStep>;

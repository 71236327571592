import { styled } from '@mui/material';
import { ReactComponent as PlusIcon } from '@assets/svgs/plus.svg';
import { ReactComponent as MinusIcon } from '@assets/svgs/minus.svg';
import { ReactComponent as FocalPointFocusIcon } from '@assets/svgs/focal-point-focus.svg';
import { ReactComponent as PresentModeIcon } from '@assets/svgs/presenter-mode.svg';
import { useAppDispatch, useAppSelector } from '@store/store';
import {
  RcaChartMode,
  setRcaChartMode,
} from '@store/rca-editor/rca-editor-slice';
import { useReactFlow, useViewport } from 'reactflow';
import {
  selectRcaChartMode,
  selectRootNode,
} from '@store/rca-editor/selectors';
import { RcaUtil } from '@util/rca-util';
import { Gap } from '@components/layout-util-components/gap';

const Container = styled('div', { target: 'chart-controls' })(
  ({ theme: { palette } }) => ({
    position: 'absolute',
    left: 20,
    bottom: 20,
    display: 'flex',
    gap: 15,

    '.control-section': {
      height: 56,
      padding: 12,
      borderRadius: 4,
      display: 'inline-flex',
      gap: 12,
      background: palette.common.white,
      color: 'black',
      fill: 'black',
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '26px',
      letterSpacing: '0.46000000834465027px',
      zIndex: 900,

      '.control-item': {
        display: 'inline-flex',
        alignItems: 'center',
        background: palette.common.grey15,
        height: 32,
        padding: 8,
        borderRadius: 2,
      },

      'button.control-item': {
        border: 'none',
        width: 32,
        height: 32,
        flex: '0 0 auto',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999,
        cursor: 'default',

        '&.toggle-choice': {
          borderRadius: 8,
          width: 'unset',
          background: palette.common.grey15,
          color: palette.button.circle.color,

          '&.selected': {
            background: '#494949',
            color: 'unset',
            stroke: 'white',
          },
        },
      },
    },
  })
);

export default function ChartControls() {
  const dispatch = useAppDispatch();

  const rootNode = useAppSelector(selectRootNode);
  const mode = useAppSelector(selectRcaChartMode);

  const rf = useReactFlow();
  const { zoom } = useViewport();

  return (
    <Container>
      <div className="control-section">
        <div className="control-item">
          <span style={{ minWidth: 36 }}>{`${(zoom * 100).toFixed(0)}%`}</span>
          <Gap size={15} />
          <span>-</span>
        </div>
        <button
          type="button"
          className="control-item"
          onClick={() => rf.zoomIn()}
        >
          <PlusIcon fill="inherit" />
        </button>
        <button
          type="button"
          className="control-item"
          onClick={() => rf.zoomOut()}
        >
          <MinusIcon fill="inherit" />
        </button>
      </div>
      <div className="control-section">
        <button
          type="button"
          className="control-item"
          onClick={() => RcaUtil.focusNode(rootNode)}
        >
          <FocalPointFocusIcon fill="inherit" />
        </button>
        <button
          type="button"
          onClick={() =>
            dispatch(
              setRcaChartMode(
                mode === RcaChartMode.build
                  ? RcaChartMode.present
                  : RcaChartMode.build
              )
            )
          }
          className={`control-item toggle-choice ${
            mode === RcaChartMode.present ? 'selected' : ''
          }`}
        >
          <PresentModeIcon />
        </button>
      </div>
    </Container>
  );
}

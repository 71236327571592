import { Button } from '@mui/material';
import { SnackbarKey, useSnackbar } from 'notistack';
import { useCommonTr } from '@i18n/use-common-tr';
import { DefaultTFuncReturn } from 'i18next';
import { useMemo } from 'react';

const CloseAction = (id: SnackbarKey) => {
  const { closeSnackbar } = useSnackbar();
  const { t } = useCommonTr('buttons');

  return (
    <Button
      variant="text"
      onClick={() => closeSnackbar(id)}
      sx={{ color: 'white', textTransform: 'uppercase' }}
    >
      {t('close')}
    </Button>
  );
};

export default function useSnackbarVariants() {
  const { enqueueSnackbar } = useSnackbar();
  const { t: errorsTr } = useCommonTr('errors');

  return useMemo(
    () => ({
      showSuccess: (message: string | DefaultTFuncReturn) => {
        enqueueSnackbar(message, { variant: 'success', action: CloseAction });
      },
      showError: (message?: string | DefaultTFuncReturn) => {
        enqueueSnackbar(message || errorsTr('generic'), {
          variant: 'error',
          action: CloseAction,
        });
      },
    }),
    [enqueueSnackbar, errorsTr]
  );
}

import { api } from '@api/api';
import { SubscriptionDetailsResource } from '@api/types/subscription/subscription-details.resource';

const subscriptionApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSubscriptionDetails: build.query<SubscriptionDetailsResource, void>({
      query: () => 'subscription/details',
    }),
  }),
});

export const { useGetSubscriptionDetailsQuery } = subscriptionApi;

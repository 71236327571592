import requireAuth from '@components/hoc/with-required-auth-hoc';
import { Typography } from '@mui/material';
import { useAppTr } from '@i18n/use-app-tr';
import Column from '@components/layout-util-components/column';
import SettingsOptionContainer from '@components/containers/settings-option-container';
import { Permission } from '@api/types/permission';
import { useNavigate } from 'react-router-dom';
import { Gap } from '@components/layout-util-components/gap';

function CompanySettingsIndexPage() {
  const { t } = useAppTr('companySettingsLanding');
  const navigate = useNavigate();

  return (
    <Column>
      <Typography variant="h3">{t('title')}</Typography>
      <Gap size={20} />
      <SettingsOptionContainer
        title={t('info.title')}
        message={t('info.message')}
        onClick={() => navigate('information')}
      />
      <SettingsOptionContainer
        title={t('mfa.title')}
        message={t('mfa.message')}
        onClick={() => navigate('mfa')}
      />
      <SettingsOptionContainer
        title={t('settings.title')}
        message={t('settings.message')}
        onClick={() => navigate('settings')}
      />
    </Column>
  );
}

export default requireAuth(CompanySettingsIndexPage, {
  permissions: [Permission.manageCompany],
});

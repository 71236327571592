import requireAuth from '@components/hoc/with-required-auth-hoc';
import Column from '@components/layout-util-components/column';
import ContentContainer from '@components/containers/content-container';
import { Typography } from '@mui/material';

import Row from '@components/layout-util-components/row';
import { LinkButton, OutlinedButton } from '@components/buttons';
import {
  DropDownFilter,
  SortByFilter,
  WCTTable,
  WCTTableFilters,
} from '@components/table';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { PageAlertDisplay } from '@components/alerts';
import Spacer from '@components/layout-util-components/spacer';
import NoEvidenceInfo from './components/no-evidence-info';
import EvidenceRow from './components/evidence-row';
import useEvidencePage from '../rca/tabs/components/editor-panels/evidence-page-hook';
import { selectCurrentRcaCaseId } from '@store/rca-editor/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import EvidenceForm from './forms/evidence-form';
import { CaseEvidenceResource } from '@api/types/case/case-evidence/case-evidence.resource';
import { EvidenceSensitivity } from '@api/types/case/case-evidence/evidence-sensitivity';
import { EvidenceStrength } from '@api/types/case/case-evidence/evidence-strength';
import { useCallback, useEffect, useRef } from 'react';
import useSearchParamsTyped from '@hooks/router-dom-helper-hooks';
import { numberFromString } from '@util/string-util';
import caseApi from '@api/endpoints/case.api';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  caseId?: number;
  canAddEvidence?: boolean;
}

type QueryParams = {
  evidenceId?: string;
};

function EvidenceIndexPage({ canAddEvidence }: Props) {
  const dispatch = useAppDispatch();
  const caseId = useAppSelector(selectCurrentRcaCaseId);
  const { evidenceId: evidenceIdQuery } = useSearchParamsTyped<QueryParams>();
  const { showPopup, showSingle } = useUiPopup();

  const state = useEvidencePage();
  const {
    evidence,
    isLoading,
    isFetching,
    canLoadMore,
    loadMore,
    search,
    sort,
    sortByOptions,
    sensitivityFilter,
    qualityFilter,
    deleteEvidence,
  } = state;

  const onAddEvidence = () => {
    showSingle(() => showPopup(EvidenceForm));
  };

  const onEditEvidence = (evidence: CaseEvidenceResource) => {
    showSingle(() => showPopup(EvidenceForm, { evidence, caseId }));
  };

  const onViewEvidence = useCallback(
    async (id: number) => {
      const evidence = await dispatch(
        caseApi.endpoints.getCaseEvidenceDetail.initiate({
          caseId: caseId!,
          evidenceId: id,
        })
      ).unwrap();

      showSingle(() =>
        showPopup(EvidenceForm, {
          caseId: caseId!,
          evidence: evidence,
          viewMode: true,
        })
      );
    },
    [caseId, dispatch, showPopup, showSingle]
  );

  const hasOpenedInitial = useRef(false);
  useEffect(() => {
    if (hasOpenedInitial.current) {
      return;
    }

    const id = numberFromString(evidenceIdQuery);
    if (id != null) {
      onViewEvidence(id);
      hasOpenedInitial.current = true;
    }
  }, [onViewEvidence, evidenceIdQuery]);

  return (
    <Column>
      {caseId == null ? <PageAlertDisplay /> : null}
      <ContentContainer>
        {evidence?.totalCount === 0 ? (
          <Typography variant="h1">Evidence</Typography>
        ) : (
          <Row>
            <Typography variant="h1">
              Evidence ({evidence?.totalCount ?? 0})
            </Typography>
            {canAddEvidence && (
              <>
                <Spacer />
                <OutlinedButton onClick={onAddEvidence}>
                  Add Evidence
                </OutlinedButton>
              </>
            )}
          </Row>
        )}
      </ContentContainer>
      <ContentContainer>
        {evidence?.totalCount === 0 ? (
          <NoEvidenceInfo
            isCaseEvidence={caseId != null}
            onCreateClicked={onAddEvidence}
          />
        ) : (
          <>
            <WCTTable
              count={evidence?.totalCount ?? 0}
              isLoading={isLoading}
              headers={[
                { title: 'Evidence Name / Description' },
                { title: 'Files' },
                { title: 'Cause boxes' },
                { title: 'Sensitivity' },
                { title: 'Strength' },
                { title: '' },
              ]}
              filters={
                <WCTTableFilters
                  search={search.value}
                  onSearch={search.set}
                  isLoading={isLoading}
                  rightOfSearchContent={
                    <SortByFilter
                      value={sort.value}
                      onChange={sort.set}
                      options={sortByOptions}
                    />
                  }
                >
                  <DropDownFilter
                    value={sensitivityFilter.value}
                    onChange={sensitivityFilter.set}
                    options={[
                      { id: EvidenceSensitivity.low, label: 'Low' },
                      { id: EvidenceSensitivity.medium, label: 'Medium' },
                      { id: EvidenceSensitivity.high, label: 'High' },
                    ]}
                    multiple
                  >
                    Sensitivity
                  </DropDownFilter>
                  <DropDownFilter
                    value={qualityFilter.value}
                    onChange={qualityFilter.set}
                    options={[
                      { id: EvidenceStrength.one, label: '1' },
                      { id: EvidenceStrength.two, label: '2' },
                      { id: EvidenceStrength.three, label: '3' },
                      { id: EvidenceStrength.four, label: '4' },
                      { id: EvidenceStrength.five, label: '5' },
                    ]}
                    multiple
                  >
                    Strength
                  </DropDownFilter>
                </WCTTableFilters>
              }
            >
              {!isLoading
                ? evidence?.model.map((evidence) => (
                    <EvidenceRow
                      key={evidence.evidenceId}
                      evidence={evidence}
                      onView={() => onViewEvidence(evidence.evidenceId)}
                      onEdit={() => onEditEvidence(evidence)}
                      onDelete={() => deleteEvidence(evidence.evidenceId)}
                    />
                  ))
                : null}
            </WCTTable>
            <Gap size={20} />
            <Row centerContent>
              {canLoadMore() ? (
                <LinkButton disabled={isFetching} onClick={loadMore}>
                  Load More
                </LinkButton>
              ) : null}
            </Row>
          </>
        )}
      </ContentContainer>
    </Column>
  );
}

export default requireAuth(EvidenceIndexPage);

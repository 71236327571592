import Row from '@components/layout-util-components/row';
import { ReactComponent as SolutionIcon } from '@assets/svgs/solution.svg';
import { Box, Grid, Icon, Skeleton, styled, Typography } from '@mui/material';
import {
  currencyFormatter,
  formatDateCustom,
  timespanFormatter,
} from '@i18n/formatters';
import RelatedCauseBoxCard from '@components/cards/related-cause-box-card';
import useSolutionsPopupView from './view-solution-popup-hook';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import deleteConfirmationModal from '@components/ui-popup/modals/delete-confirmation-modal';
import { ListContainer } from '@components/containers/list-container';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import React, { useState } from 'react';
import WCTTextField from '@components/input/text-field';
import { ErrorData } from '@api/types/api-error';
import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  SideDrawerFormProps,
  withSideDrawer,
} from '@components/ui-popup/drawers';
import ActionsRow from '@components/layout-util-components/actions-row';
import Spacer from '@components/layout-util-components/spacer';
import CaseFileManagerInput from '@pages/app/rca/tabs/components/modals/case-file-manager/case-file-manager-input';
import WCTSelectField from '@components/input/select-field';
import WCTCurrencyField from '@components/input/currency-field';
import useCaseFileMenuActions from '@pages/app/rca/tabs/hooks/case-file-menu-actions-hook';
import { Divider } from '@components/layout-util-components/divider';
import OutlinedBadge from '@components/badges/outlined-badge';
import { Gap } from '@components/layout-util-components/gap';
import Column from '@components/layout-util-components/column';

const PanelTitle = styled(Typography)({
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: 'small',
});

export interface SolutionPopoupViewProps extends SideDrawerFormProps<never> {
  caseId: number;
  solutionId?: number;
  isViewMode?: boolean;
}

function SolutionPopoupView({
  caseId,
  solutionId,
  isViewMode = false,
  onClose,
}: SolutionPopoupViewProps) {
  const { showPopup } = useUiPopup();
  const { getCaseFileMenuActions } = useCaseFileMenuActions();

  const {
    onDeleteSolution,
    onSubmit,
    solution,
    isLoading,
    name,
    description,
    isSubmitting,
    canSubmit,
    themes,
    files,
    cost,
    themeOptions,
    canDelete,
    currency,
  } = useSolutionsPopupView(caseId, solutionId);

  const isCreate = solutionId == null;
  const [showForm, setShowForm] = useState(!isViewMode);

  const onDeleteSolutionClicked = async () => {
    const didDelete = await showPopup(deleteConfirmationModal, {
      itemName: 'Solution',
      title: 'Are you sure you want to delete this solution?',
      message:
        'Are you sure you want to delete this solution?\n\nPlease note this will remove this solution from all associated cause boxes.',
      deleteButtonMessage: 'Delete Solution',
      onDelete: onDeleteSolution,
    });
    if (didDelete) {
      onClose();
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ p: 4 }}>
        {[...Array(6)].map(() => (
          <Skeleton height="4em" />
        ))}
      </Box>
    );
  }

  return (
    <>
      <DrawerHeader onClose={onClose}>
        <Row>
          <span>{`${
            !showForm ? 'View' : isCreate ? 'Create' : 'Edit'
          } Solution`}</span>
        </Row>
      </DrawerHeader>
      <DrawerContent>
        {showForm ? (
          <>
            {!isCreate && (
              <Grid container mb={2} gap={1} columns={12}>
                <Grid item xs={5} flexDirection="column">
                  <PanelTitle>Added By</PanelTitle>
                  <Typography>{solution?.createdByName}</Typography>
                </Grid>
                <Grid item xs={5} flexDirection="column">
                  <PanelTitle>Date Created</PanelTitle>
                  <Typography>
                    {`${formatDateCustom(
                      solution?.created,
                      'D MMM YYYY'
                    )} (${timespanFormatter(solution?.created)})`}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Box sx={{ mb: 2 }}>
              <WCTTextField
                name={'name'}
                label={'Name'}
                value={name?.value ?? ''}
                error={(name?.errors ?? '') as ErrorData}
                onChange={(v) => name.set(v)}
                required={true}
              />
            </Box>
            <Box sx={{ mb: 4 }}>
              <WCTTextField
                name={'description'}
                label={'Description'}
                value={description?.value ?? ''}
                error={(description?.errors ?? '') as ErrorData}
                onChange={(v) => description.set(v)}
                required={true}
                multiline
              />
            </Box>
            <Box sx={{ mb: 4 }}>
              <CaseFileManagerInput
                value={files.value}
                error={files.error}
                onChange={files.set}
              />
            </Box>
            <Box sx={{ mb: 4 }}>
              <WCTSelectField
                id="themes"
                name="themes"
                label="Themes"
                options={themeOptions!}
                value={themes.value}
                error={themes.error}
                onChange={themes.set}
                placeholder="Select Theme(s)"
                required
                multiple
              />
            </Box>

            <Box sx={{ mb: 4 }}>
              <WCTCurrencyField
                name="cost"
                label="Cost"
                value={cost.value}
                error={cost.error}
                onChange={cost.set}
                required
                currency={currency}
              />
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                mb: 2,
              }}
            >
              <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                <SolutionIcon height={35} width={35} />
              </Icon>
              <Typography>{solution?.name}</Typography>
            </Box>
            <Grid container mb={2} gap={1} columns={12}>
              <Grid item xs={5} flexDirection="column">
                <PanelTitle>Description</PanelTitle>
                <Typography>{solution?.description}</Typography>
              </Grid>
              <Grid item xs={5} flexDirection="column">
                <PanelTitle>Added By</PanelTitle>
                <Typography>{solution?.createdByName}</Typography>
              </Grid>
              <Grid item xs={5} flexDirection="column">
                <PanelTitle>Cost</PanelTitle>
                <Typography>
                  {currencyFormatter(solution?.cost, {
                    decimalPlaces: 2,
                    currency,
                  })}
                </Typography>
              </Grid>
              <Grid item xs={5} flexDirection="column">
                <PanelTitle>Date Created</PanelTitle>
                <Typography>
                  {`${formatDateCustom(
                    solution?.created,
                    'D MMM YYYY'
                  )} (${timespanFormatter(solution?.created)})`}
                </Typography>
              </Grid>
              {(solution?.themes?.length ?? 0) > 0 && (
                <Grid item xs={5} flexDirection="column">
                  <PanelTitle>Theme</PanelTitle>
                  <Box>
                    {solution?.themes.map((theme) => (
                      <OutlinedBadge key={theme.id}>
                        {theme.label}
                      </OutlinedBadge>
                    ))}
                  </Box>
                </Grid>
              )}
            </Grid>
            {files.value.length > 0 ? (
              <>
                <Gap size={32} />
                <Typography>{`Attached Files (${files.value.length})`}</Typography>
                <CaseFileManagerInput
                  value={files.value}
                  onChange={files.set}
                  readonly
                  elipsisActions={getCaseFileMenuActions}
                />
              </>
            ) : null}
            <Divider addMargin compact />
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}
            >
              <Typography>{`Related Cause Boxes (${
                solution?.chainItems.length ?? 0
              })`}</Typography>
              <ListContainer isLight>
                {solution?.chainItems.map((chainItem) => (
                  <Column>
                    <RelatedCauseBoxCard
                      key={chainItem.chainItemId}
                      chainItem={chainItem}
                      caseId={caseId}
                      showHealth={false}
                    />
                    <Gap size={8} />
                  </Column>
                ))}
              </ListContainer>
            </Box>
          </>
        )}
      </DrawerContent>
      <DrawerFooter>
        {showForm ? (
          <ActionsRow>
            {canDelete && (
              <>
                <OutlinedButton onClick={() => onDeleteSolutionClicked()}>
                  Delete
                </OutlinedButton>
                <Spacer />
              </>
            )}
            <OutlinedButton
              onClick={() => (isViewMode ? setShowForm(false) : onClose())}
            >
              Cancel
            </OutlinedButton>
            <PrimaryButton
              isBusy={isSubmitting}
              disabled={!canSubmit}
              onClick={() =>
                onSubmit().then((didSubmit) => {
                  if (didSubmit) {
                    onClose();
                  }
                })
              }
            >
              {isCreate ? 'Create Solution' : 'Update Solution'}
            </PrimaryButton>
          </ActionsRow>
        ) : (
          <ActionsRow>
            <OutlinedButton onClick={() => setShowForm(true)}>
              Edit Solution
            </OutlinedButton>
            {canDelete && (
              <OutlinedButton onClick={() => onDeleteSolutionClicked()}>
                Delete Solution
              </OutlinedButton>
            )}
          </ActionsRow>
        )}
      </DrawerFooter>
    </>
  );
}

export default withSideDrawer(SolutionPopoupView);

import { api } from '@api/api';
import { CompanyLocationResource } from '@api/types/company/company-location/company-location.resource';
import { Option } from '@api/types/option';

const companyLocationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompanyLocationOptions: build.query<Option[], void>({
      query: () => ({
        url: 'companyLocation/options',
      }),
      providesTags: (response) =>
        response
          ? [
              ...response.map((location) => ({
                id: location.id,
                type: 'CompanyLocation' as const,
              })),
              'CompanyLocation',
            ]
          : ['CompanyLocation'],
    }),
    getCompanyLocationDetailOptions: build.query<
      CompanyLocationResource[],
      void
    >({
      query: () => ({
        url: 'companyLocation/DetailOptions',
      }),
    }),
  }),
});

export const {
  useGetCompanyLocationOptionsQuery,
  useGetCompanyLocationDetailOptionsQuery,
} = companyLocationApi;

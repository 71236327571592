import { api } from '@api/api';
import { CaseOutcomeResource } from '@api/types/case/case-outcome/case-outcome.resource';
import { CreateCaseOutcomeRequest } from '@api/types/case/case-outcome/create-case-outcome.request';
import { makeError } from '@api/types/api-error';
import { UpdateCaseOutcomeRequest } from '@api/types/case/case-outcome/update-case-outcome.request';
import { SetCaseOutcomeDetailsRequest } from '@api/types/case/case-outcome/set-case-outcome-details.request';
import { CaseOutcomeResultResponse } from '@api/types/case/case-outcome/case-outcome-result.response';
import { parseChain } from '@util/node-parsing-util';
import chainApi from '@api/endpoints/chain/chain.api';
import { ShareOutcomeRequest } from '@api/types/case/case-outcome/share-outcome.request';
import { CaseOutcomeListItemResponse } from '@api/types/case/case-outcome/case-outcome-list-item.response';
import { PaginatedResult } from '@api/types/paginated-result';
import { RunReportResponse } from '@api/types/case/case-report/run-report.response';

export type CasePathParams = {
  caseId: number;
};

export type CaseOutcomePathParams = {
  caseId: number;
  outcomeId: number;
};

const caseOutcomeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCaseOutcomes: build.query<
      PaginatedResult<CaseOutcomeListItemResponse>,
      number
    >({
      query: (caseId) => ({
        url: `case/${caseId}/outcome`,
      }),
      providesTags: ['Outcome'],
    }),
    getCaseOutcomeDetail: build.query<
      CaseOutcomeResource,
      CaseOutcomePathParams
    >({
      query: ({ caseId, outcomeId }) => ({
        url: `case/${caseId}/outcome/${outcomeId}`,
      }),
      providesTags: ['Outcome'],
    }),
    getCaseOutcomeResult: build.query<
      CaseOutcomeResultResponse,
      CaseOutcomePathParams
    >({
      query: ({ caseId, outcomeId }) => ({
        url: `case/${caseId}/outcome/${outcomeId}/result`,
      }),
      transformResponse: (response: CaseOutcomeResultResponse) => {
        return {
          ...response,
          evidenceCoverageReport: response.evidenceCoverageReport
            ? RunReportResponse.fromJson(response.evidenceCoverageReport)
            : undefined,
          solutionCoverageReport: response.solutionCoverageReport
            ? RunReportResponse.fromJson(response.solutionCoverageReport)
            : undefined,
          solutionTermReport: response.solutionTermReport
            ? RunReportResponse.fromJson(response.solutionTermReport)
            : undefined,
          solutionEffectivenessReport: response.solutionEffectivenessReport
            ? RunReportResponse.fromJson(response.solutionEffectivenessReport)
            : undefined,
          themeCoverageReport: response.themeCoverageReport
            ? RunReportResponse.fromJson(response.themeCoverageReport)
            : undefined,
          selectedReports: response.selectedReports?.map(
            RunReportResponse.fromJson
          ),
          chain:
            response.chain != null ? parseChain(response.chain!) : undefined,
        };
      },
      async onCacheEntryAdded(arg, { cacheDataLoaded, dispatch }) {
        const {
          data: { chain },
        } = await cacheDataLoaded;
        if (chain != null) {
          dispatch(
            chainApi.util.updateQueryData(
              'getChainDetail',
              chain.chainId,
              (draft) => {
                Object.assign(draft, chain);
              }
            )
          );
        }
      },
      providesTags: ['Outcome'],
    }),
    createCaseOutcome: build.mutation<
      CaseOutcomeResource,
      CreateCaseOutcomeRequest
    >({
      query: ({ caseId, ...body }) => ({
        url: `case/${caseId}/outcome`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Outcome'],
      transformErrorResponse: (response) =>
        makeError<CreateCaseOutcomeRequest>(response),
    }),
    updateCaseOutcome: build.mutation<void, UpdateCaseOutcomeRequest>({
      query: ({ caseId, ...body }) => ({
        url: `case/${caseId}/outcome/configuration`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Outcome'],
      transformErrorResponse: (response) =>
        makeError<UpdateCaseOutcomeRequest>(response),
    }),
    setCaseOutcomeDetails: build.mutation<void, SetCaseOutcomeDetailsRequest>({
      query: ({ caseId, ...body }) => ({
        url: `case/${caseId}/outcome/details`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Outcome'],
      transformErrorResponse: (response) =>
        makeError<SetCaseOutcomeDetailsRequest>(response),
    }),
    shareCaseOutcome: build.mutation<void, ShareOutcomeRequest>({
      query: ({ caseId, ...body }) => ({
        url: `case/${caseId}/outcome/companyUsers`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Outcome'],
      transformErrorResponse: (response) =>
        makeError<ShareOutcomeRequest>(response),
    }),
    deleteCaseOutcome: build.mutation<void, CaseOutcomePathParams>({
      query: ({ caseId, outcomeId }) => ({
        url: `case/${caseId}/outcome/${outcomeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Outcome'],
      transformErrorResponse: (response) =>
        makeError<CaseOutcomePathParams>(response),
    }),
  }),
});

export const {
  useGetCaseOutcomeDetailQuery,
  useCreateCaseOutcomeMutation,
  useUpdateCaseOutcomeMutation,
  useSetCaseOutcomeDetailsMutation,
  useDeleteCaseOutcomeMutation,
  useLazyGetCaseOutcomeResultQuery,
  useShareCaseOutcomeMutation,
  useGetCaseOutcomeResultQuery,
  useGetCaseOutcomesQuery,
} = caseOutcomeApi;

export default caseOutcomeApi;

import { useFilter, usePagination } from '@components/table';
import { SortDirection } from '@api/types/sort-direction';
import useSortableHeader, {
  SortOption,
} from '@components/table/hooks/use-sortable-header-hook';
import {
  useGetCaseSolutionsQuery,
  useRemoveCaseSolutionMutation,
} from '@api/endpoints/case.api';
import { useGetEfficacyOptionsQuery } from '@api/endpoints/efficacy.api';
import { useGetSolutionTypeOptionsQuery } from '@api/endpoints/solution-type.api';
import { useGetSolutionTermOptionsQuery } from '@api/endpoints/solution-term.api';
import useUser from '@store/user/user-hook';
import { CaseSolutionItemResponse } from '@api/types/case/case-solution/case-solution-item.response';

export default function useSolutions(caseId?: number) {
  const { isAdminUser, companyUserId } = useUser();
  const typeFilter = useFilter<Array<number>>([]);
  const effectivenessFilter = useFilter<Array<number>>([]);
  const termFilter = useFilter<Array<number>>([]);

  const sortByOptions: Array<SortOption> = [
    { id: 'name', label: 'Name A-Z', direction: SortDirection.asc },
    { id: 'name', label: 'Name Z-A', direction: SortDirection.desc },
    { id: 'cost', label: 'Cost Asc', direction: SortDirection.asc },
    { id: 'cost', label: 'Cost Desc', direction: SortDirection.desc },
  ];

  const search = useFilter<string>();
  const pagination = usePagination();

  const sort = useSortableHeader({
    initialProperty: 'name',
    allowNoSort: false,
  });

  const {
    data: effectivenessFilterOptions,
    isLoading: effectivenessFilterOptionsLoading,
    isFetching: effectivenessFilterOptionsFetching,
  } = useGetEfficacyOptionsQuery();

  const {
    data: termFilterOptions,
    isLoading: termFilterOptionsLoading,
    isFetching: termFilterOptionsFetching,
  } = useGetSolutionTermOptionsQuery();

  const {
    data: typeFilterOptions,
    isLoading: typeFilterOptionsLoading,
    isFetching: typeFilterOptionsFetching,
  } = useGetSolutionTypeOptionsQuery();

  const { data: solutions, isLoading: loadingSolutions } =
    useGetCaseSolutionsQuery(
      {
        searchText: search.value,
        sortBy: sort.property,
        sortDirection: sort.direction,
        skip: pagination.skip,
        take: pagination.take,
        caseId: caseId as number,
        solutionTypes: typeFilter.arrayValue,
        solutionTerms: termFilter.arrayValue,
        efficacy: effectivenessFilter.arrayValue,
      },
      { skip: caseId == null }
    );

  const [deleteSolution, { isLoading: isDeletingSolution }] =
    useRemoveCaseSolutionMutation();

  const isLoading =
    loadingSolutions ||
    isDeletingSolution ||
    effectivenessFilterOptionsLoading ||
    effectivenessFilterOptionsFetching ||
    typeFilterOptionsLoading ||
    typeFilterOptionsFetching ||
    termFilterOptionsLoading ||
    termFilterOptionsFetching;

  const canDelete = (solution: CaseSolutionItemResponse) => {
    return isAdminUser || solution.createdByCompanyUserId === companyUserId;
  };

  return {
    search,
    sort,
    sortByOptions,
    isLoading,
    solutions,
    typeFilter,
    typeFilterOptions,
    effectivenessFilter,
    effectivenessFilterOptions,
    termFilter,
    termFilterOptions,
    hasNoSolutions: !isLoading && (solutions?.totalCount ?? 0) === 0,
    pagination,
    deleteSolution,
    canDelete,
  };
}

import { ReactComponent as NoEvidenceIcon } from '@assets/svgs/no-tasks.svg';
import OnboardingEntryContainer, {
  OnboardingEntryContainerProps,
} from '@components/containers/onboarding-entry-container';

type Props = {
  isCaseEvidence: boolean;
  onCreateClicked: () => void;
} & Partial<OnboardingEntryContainerProps>;

export default function NoEvidenceInfo({
  onCreateClicked,
  isCaseEvidence,
  ...rest
}: Props) {
  return (
    <OnboardingEntryContainer
      {...rest}
      icon={<NoEvidenceIcon />}
      title="You don't currently have any evidence"
      message={
        isCaseEvidence
          ? // eslint-disable-next-line quotes
            "There aren't currently any evidence for this case, you can add one below"
          : // eslint-disable-next-line quotes
            'There are currently no evidence within the platform.'
      }
      createMessage={isCaseEvidence ? 'Add Evidence' : undefined}
      onCreateClicked={isCaseEvidence ? onCreateClicked : undefined}
    />
  );
}

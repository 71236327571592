import { PropsWithChildren } from 'react';
import { styled } from '@mui/material';

const StyledBadge = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid #000000',
  borderRadius: 3,
  padding: '0 8px',
  textTransform: 'uppercase',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '143%',
  letterSpacing: '0.17px',
});

export default function OutlinedBadge({ children }: PropsWithChildren<{}>) {
  return <StyledBadge>{children}</StyledBadge>;
}

import { api } from '@api/api';
import { CaseImpactType } from '@api/types/case-impact-type/case-impact-type';

const companyCaseImpactTypeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompanyCaseImpactType: build.query<CaseImpactType[], void>({
      query: () => ({
        url: 'company/CaseImpactType',
        method: 'GET',
      }),
    }),
    companyEnableCaseImpactType: build.mutation<void, number>({
      query: (id) => ({
        url: `company/CaseImpactType/${id}/enable`,
        method: 'PUT',
      }),
    }),
    companyDisableCaseImpactType: build.mutation<void, number>({
      query: (id) => ({
        url: `company/CaseImpactType/${id}/disable`,
        method: 'PUT',
      }),
    }),
  }),
});

export const {
  useGetCompanyCaseImpactTypeQuery,
  useCompanyEnableCaseImpactTypeMutation,
  useCompanyDisableCaseImpactTypeMutation,
} = companyCaseImpactTypeApi;

import { ImpactsPanelState } from '@pages/app/rca/tabs/components/editor-panels/impacts-panel-hook';
import Column from '@components/layout-util-components/column';
import { Typography } from '@mui/material';
import { Fragment } from 'react';
import SelectedImpactItemView from '@pages/app/rca/tabs/components/editor-panels/components/impacts-panel/selected-impact-item-view';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  state: ImpactsPanelState;
}

export default function SelectedImpactsView({ state }: Props) {
  const { actualImpacts, potentialImpacts, impacts } = state;

  const filteredActualImpacts = impacts!.filter((x) =>
    actualImpacts.some((y) => y.caseImpactId === x.caseImpactId)
  );
  const filteredPotentialImpacts = impacts!.filter((x) =>
    potentialImpacts.some((y) => y.caseImpactId === x.caseImpactId)
  );

  return (
    <Column>
      <Typography variant="body1">
        Below are your select impacts, you can add further impacts or edit the
        existing ones below
      </Typography>
      <Gap size={10} />
      {filteredActualImpacts.length > 0 ? (
        <>
          <Typography variant="body1">ACTUAL IMPACTS (SELECTED)</Typography>
          <Gap size={10} />
          {filteredActualImpacts.map((impact) => (
            <SelectedImpactItemView key={impact.caseImpactId} item={impact} />
          ))}
        </>
      ) : null}
      {filteredPotentialImpacts.length > 0 ? (
        <>
          <Typography>POTENTIAL IMPACTS (SELECTED)</Typography>
          <Gap size={10} />
          {filteredPotentialImpacts.map((impact) => (
            <SelectedImpactItemView key={impact.caseImpactId} item={impact} />
          ))}
        </>
      ) : null}
    </Column>
  );
}

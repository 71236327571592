import { styled } from '@mui/material';
import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import FlatBadge from '@components/badges/flat-badge';
import { OutlinedButton } from '@components/buttons';

interface Props {
  name: string;
  kudosCount: number;
  onAllocateMore?: () => void;
}

// item of a grid of 2 columns that wrap every 2 items
const Container = styled('div')({
  border: '1px solid #00000014',
  borderRadius: 4,
  padding: '8px 10px 8px 10px',
  display: 'inline-flex',
  flex: '1 1 auto',
  gap: 10,
  alignItems: 'center',
});

export default function KudosPersonItem({
  name,
  kudosCount,
  onAllocateMore,
}: Props) {
  return (
    <Container>
      <InitialsContainerWithData fullName={name} />
      <FlatBadge>{`${kudosCount}pts`}</FlatBadge>
      <OutlinedButton
        onClick={onAllocateMore}
        disabled={onAllocateMore == null}
      >
        + 5pts
      </OutlinedButton>
    </Container>
  );
}

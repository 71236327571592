import { TaskDetailListItemResponse } from '@api/types/task-detail/task-detail-list-item.response';
import { ListItemRow } from '@components/containers/list-container';
import { Typography } from '@mui/material';
import Row from '@components/layout-util-components/row';
import { ReactComponent as RcaIndicatorIcon } from '@assets/svgs/rca-icon.svg';
import { ReactComponent as DueDateIndicatorIcon } from '@assets/svgs/due-date-indicator.svg';
import { ReactComponent as AttachmentIndicatorIcon } from '@assets/svgs/attachment-indicator.svg';
import LabelValueDisplay from '@components/data/label-value-display';
import CircleContainer from '@components/containers/circle-container';
import { getInitials } from '@util/name-util';
import { ElipsisButton, LinkButton } from '@components/buttons';
import { useAppTr } from '@i18n/use-app-tr';
import TaskCompleteIndicator from '@pages/app/tasks/components/task-complete-indicator';
import { TaskStatus } from '@api/types/task-detail/task-status';
import { NodePanelEditorTab } from '@store/rca-editor/rca-editor-slice';
import { Gap } from '@components/layout-util-components/gap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/pro-solid-svg-icons';
import { TaskPriority } from '@api/types/task-detail/task-priority';

interface Props {
  task: TaskDetailListItemResponse;
  onEdit: () => void;
  onComplete: () => void;
  onOpen: () => void;
}

const PRIORITY_TO_COLOR: Record<TaskPriority, string> = {
  High: '#D60000',
  Low: '#B7B7B7', // TODO: missing design for this, add color when design updated
  Standard: '#B7B7B7',
};

export default function TaskRow({ task, onEdit, onComplete, onOpen }: Props) {
  const { t } = useAppTr('tasks');
  const {
    title,
    caseId,
    caseName,
    chainItemId,
    chainItemName,
    dueDate,
    assignedToCompanyUser,
    priority,
    numberOfAttachments,
    taskStatus,
  } = task;

  const isComplete = taskStatus === TaskStatus.complete;
  const isOverdue = taskStatus === TaskStatus.overdue;
  return (
    <ListItemRow highlight={isComplete} onClick={onEdit}>
      <TaskCompleteIndicator
        isComplete={isComplete}
        onComplete={onComplete}
        onOpen={onOpen}
      />
      <div>
        <Typography fontSize={14} fontWeight={400} maxWidth={175}>
          {title}
        </Typography>
      </div>
      {chainItemId ? (
        <Row>
          <RcaIndicatorIcon width={32} height="auto" />
          <Gap size={10} />
          <LabelValueDisplay
            label="CAUSE BOX"
            value={
              <LinkButton
                href={`/rca/tabs/${caseId}/chart/?chainItem=${chainItemId}&panelTab=${NodePanelEditorTab.tasks}`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {chainItemName}
              </LinkButton>
            }
          />
        </Row>
      ) : caseId ? (
        <Row>
          <RcaIndicatorIcon width={32} height="auto" />
          <Gap size={10} />
          <LabelValueDisplay label="ANALYSIS" value={caseName} />
        </Row>
      ) : (
        <div />
      )}
      {dueDate ? (
        <Row>
          <DueDateIndicatorIcon />
          <Gap size={10} />
          <LabelValueDisplay
            label={t(
              isOverdue ? 'row.dueDate.overdue.label' : 'row.dueDate.due.label'
            )}
            value={t(
              isOverdue ? 'row.dueDate.overdue.value' : 'row.dueDate.due.value',
              { value: dueDate }
            )}
          />
        </Row>
      ) : (
        <div />
      )}
      <Row>
        {assignedToCompanyUser ? (
          <>
            <CircleContainer size={32}>
              {getInitials(assignedToCompanyUser)}
            </CircleContainer>
            <Gap size={15} />
          </>
        ) : (
          <div />
        )}
        {priority ? (
          <>
            <CircleContainer size={32}>
              <FontAwesomeIcon
                icon={faFlag}
                color={PRIORITY_TO_COLOR[priority]}
              />
            </CircleContainer>
            <Gap size={15} />
          </>
        ) : (
          <div />
        )}
        {numberOfAttachments ? <AttachmentIndicatorIcon /> : <></>}
      </Row>
      <ElipsisButton
        elipsesVariant="two"
        actions={[
          {
            label: t('options.edit'),
            onClick: onEdit,
          },
        ]}
      />
    </ListItemRow>
  );
}

import { Grid, Typography } from '@mui/material';
import WCTGenericTypeaheadField, {
  WCTGenericTypeaheadFieldProps,
} from './generic-typeahead-field';
import { CompanyLocationResource } from '@api/types/company/company-location/company-location.resource';
import { getAddressString } from '@util/string-util';

export default function WCTLocationTypeaheadField(
  props: WCTGenericTypeaheadFieldProps<CompanyLocationResource>
) {
  return (
    <WCTGenericTypeaheadField
      {...props}
      getOptionLabel={(option: CompanyLocationResource) => option.name}
      isOptionEqualToValue={(
        option: CompanyLocationResource,
        value: CompanyLocationResource
      ) => option.companyLocationId === value.companyLocationId}
      renderOption={(props, option: CompanyLocationResource) => {
        const address = option.postcode
          ? getAddressString(option)
          : 'No address available';
        return (
          <li {...props}>
            <Grid container direction="column">
              <Grid item>
                <Typography fontWeight="fontWeightMedium">
                  {option.name}
                </Typography>
              </Grid>
              <Grid item>
                <Typography fontStyle={option.postcode ? undefined : 'italic'}>
                  {address}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

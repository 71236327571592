import { useNavigate } from 'react-router-dom';
import { PopoverMenuAction } from '@components/popover-menu/use-popover-menu';
import { CaseItemRowResponse } from '@api/types/case/case-item-row.response';
import useUser from '@store/user/user-hook';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import DeleteConfirmationModal from '@components/ui-popup/modals/delete-confirmation-modal';
import { useDeleteCaseMutation } from '@api/endpoints/case.api';
import { ApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';

export function useCaseElipsisOptions(caseItem: CaseItemRowResponse) {
  const { showPopup } = useUiPopup();
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();

  const [deleteCase] = useDeleteCaseMutation();

  const { caseId } = caseItem;
  const navigate = useNavigate();

  const { isAdminUser, companyUserId } = useUser();
  const canDelete =
    isAdminUser || caseItem.createdByCompanyUserId === companyUserId;

  const onDeleteClick = () => {
    showPopup(DeleteConfirmationModal, {
      itemName: 'RCA',
      onDelete: async () => {
        try {
          await deleteCase(caseId).unwrap();
          showSuccessMessage('Case deleted successfully');
          return true;
        } catch (error) {
          const { message, errors } = error as ApiError<{ caseId: number }>;
          showErrorMessage(errors?.caseId ?? message);
          return false;
        }
      },
    });
  };

  const CaseElipsisOptions: Array<PopoverMenuAction> = [
    {
      label: 'Open Analysis',
      onClick: () => {
        navigate(`/rca/tabs/${caseId}/chart`);
      },
    },
    {
      label: 'Delete',
      hasPermission: canDelete,
      onClick: onDeleteClick,
    },
  ];

  return CaseElipsisOptions;
}

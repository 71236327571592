export enum ReportCellType {
  empty = 'Empty',
  identifier = 'Identifier',
  badge = 'Badge',
  percent = 'Percent',
  string = 'String',
}

export interface ReportCell {
  type: ReportCellType;
  value: string;
}

export interface ReportRow {
  clientGeneratedId: number;
  cells: ReportCell[];
}

export class RunReportResponse<MetaDataType, TotalsType = never> {
  columns!: string[];
  data!: ReportRow[];
  metaData!: MetaDataType[];
  totals!: TotalsType;

  static fromJson<MetaDataType, TotalsType = never>(
    json: any
  ): RunReportResponse<MetaDataType, TotalsType> {
    return {
      columns: json.columns,
      data: json.data.map((row: ReportCell[], index: number) => {
        return {
          clientGeneratedId: index,
          cells: row.map((cell) => {
            return {
              type: cell.type,
              value: cell.value,
            };
          }),
        };
      }),
      metaData: json.metaData,
      totals: json.totals,
    };
  }
}

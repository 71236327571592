import { api } from '@api/api';
import { Theme } from '@api/types/theme/theme';

const companyThemeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getThemes: build.query<Theme[], void>({
      query: () => ({
        url: 'company/theme',
        method: 'GET',
      }),
    }),
    companyEnableTheme: build.mutation<void, number>({
      query: (id) => ({
        url: `company/theme/${id}/enable`,
        method: 'PUT',
      }),
    }),
    companyDisableTheme: build.mutation<void, number>({
      query: (id) => ({
        url: `company/theme/${id}/disable`,
        method: 'PUT',
      }),
    }),
  }),
});

export const {
  useGetThemesQuery,
  useCompanyDisableThemeMutation,
  useCompanyEnableThemeMutation,
} = companyThemeApi;

import requireAuth from '@components/hoc/with-required-auth-hoc';
import withEditorScaffold from '@pages/app/rca/tabs/components/rca-editor-scaffold';
import { NodeDragHandler } from 'reactflow';
import { useAppDispatch, useAppSelector } from '@store/store';
import {
  selectChainId,
  selectEdges,
  selectHasFocusedNode,
  selectIsEditingAnyNode,
  selectIsGraphBusy,
  selectNodes,
  selectProximityEdge,
  selectRcaChartMode,
  selectStorageGraphNode,
} from '@store/rca-editor/selectors';
import {
  onConnectNode,
  onEdgesChange,
  onNodesChange,
  RcaChartMode,
  resetFocus,
} from '@store/rca-editor/rca-editor-slice';
import {
  DragEventHandler,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  beginDraggingNode,
  devOnlyResetGraph,
  devOnlyToggleDevMode,
  focusNodeAndBringIntoView,
  stopDraggingNode,
  updateProximityEdge,
  upgradeStorageGraphNodePosition,
} from '@store/rca-editor/rca-editor-actions';
import FocusedNodePanelEditor from '@pages/app/rca/tabs/components/editor-panels/focused-node-panel-editor';
import ChartControls from '@pages/app/rca/tabs/components/chart-controls';
import ChartStorage from '@pages/app/rca/tabs/components/storage/chart-storage';
import chainApi, {
  useGetChainDetailQuery,
} from '@api/endpoints/chain/chain.api';
import ChartBusyIndicator from '@pages/app/rca/tabs/components/chart-busy-indicator';
import { RcaNode } from '@store/rca-editor/types';
import { RcaUtil } from '@util/rca-util';
import { useDebouncedCallback } from 'use-debounce';
import ChartCancelAction from '@pages/app/rca/tabs/components/chart-cancel-action';
import { captureInitialEditorSnapshot } from '@store/rca-editor-snapshot/rca-editor-snapshot-actions';
import RenderGraph from '@pages/app/rca/tabs/components/graph/render-graph';
import useSearchParamsTyped from '@hooks/router-dom-helper-hooks';
import { numberFromString } from '@util/string-util';

type QueryParams = {
  chainItem?: string;
  panelTab?: string;
};

function RcaEditorChart() {
  const [isReactFlowReady, setIsReactFlowReady] = useState(false);
  const dispatch = useAppDispatch();
  const { chainItem: chainItemQuery, panelTab: panelTabQuery } =
    useSearchParamsTyped<QueryParams>();

  const hasFocusedNode = useAppSelector(selectHasFocusedNode);
  const isBusy = useAppSelector(selectIsGraphBusy);
  const chainId = useAppSelector(selectChainId)!;
  const mode = useAppSelector(selectRcaChartMode);

  const { isFetching, isSuccess: hasLoadedChartDetail } =
    useGetChainDetailQuery(chainId);

  const isReadOnly = mode === RcaChartMode.present;

  const hasDispatchedDragBegin = useRef(false);

  const nodes = useAppSelector(selectNodes);
  const edges = useAppSelector(selectEdges);
  const storageGraphNode = useAppSelector(selectStorageGraphNode);
  const proximityEdge = useAppSelector(selectProximityEdge);

  const canZoomAndPan = !useAppSelector(selectIsEditingAnyNode);

  const onReactFlowClick: MouseEventHandler = (e) => {
    const className = (e.target as HTMLDivElement)?.className;
    if (
      !isReadOnly &&
      className &&
      className.includes != null &&
      className.includes('react-flow__pane')
    ) {
      dispatch(resetFocus());
    }
  };

  const onDragOver = useDebouncedCallback<DragEventHandler>((e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';

    const nodePos = RcaUtil.getNodePosFromCursor(e);
    dispatch(upgradeStorageGraphNodePosition(nodePos.x, nodePos.y));
  }, 1);

  const onDragNode: NodeDragHandler = (e, node) => {
    if (RcaUtil.isDraggable(node as RcaNode)) {
      if (!hasDispatchedDragBegin.current) {
        hasDispatchedDragBegin.current = true;
        dispatch(beginDraggingNode(node as RcaNode));
      } else {
        dispatch(
          updateProximityEdge(node as RcaNode, { x: e.clientX, y: e.clientY })
        );
      }
    } else {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const onDragNodeStop: NodeDragHandler = (_, node) => {
    if (RcaUtil.isDraggable(node as RcaNode)) {
      if (hasDispatchedDragBegin.current) {
        hasDispatchedDragBegin.current = false;
        dispatch(stopDraggingNode(node as RcaNode));
      }
    }
  };

  const hasAutoFocusedOnMount = useRef(false);
  useEffect(() => {
    if (hasAutoFocusedOnMount.current || !isReactFlowReady) {
      return;
    }

    const chainItem = numberFromString(chainItemQuery);
    if (chainItem != null) {
      const node = nodes?.find((n) => n.data.chainItemId === chainItem);
      if (node != null) {
        const panelTab = numberFromString(panelTabQuery);
        dispatch(focusNodeAndBringIntoView(node, panelTab));
        hasAutoFocusedOnMount.current = true;
      }
    }
  }, [isReactFlowReady, chainItemQuery, dispatch, nodes, panelTabQuery]);

  useEffect(() => {
    if (hasLoadedChartDetail) {
      dispatch(captureInitialEditorSnapshot());
    }
  }, [dispatch, hasLoadedChartDetail]);

  useEffect(() => {
    const r = dispatch(
      chainApi.endpoints.getChainHealthScores.initiate(chainId, {
        subscribe: true,
        forceRefetch: true,
      })
    );
    return () => r.unsubscribe();
  }, [chainId, dispatch]);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.code === 'Delete' || event.code === 'F1') {
        dispatch(devOnlyResetGraph());
      } else if (event.code === 'End' || event.code === 'F2') {
        dispatch(devOnlyToggleDevMode());
      }
    };

    document.addEventListener('keydown', keyDownHandler);
    return () => document.removeEventListener('keydown', keyDownHandler);
  }, [dispatch]);

  return (
    <>
      <RenderGraph
        onInit={(reactFlow) => {
          RcaUtil.setReactFlowInstance(reactFlow);
          setIsReactFlowReady(true);
          if (chainItemQuery != null) {
            return;
          }
          const node = nodes?.[0];
          if (node != null) {
            RcaUtil.focusNode(node, true);
          }
        }}
        nodes={storageGraphNode ? [...nodes, storageGraphNode] : nodes}
        edges={proximityEdge ? [...edges, proximityEdge] : edges}
        onNodesChange={(changes) => dispatch(onNodesChange(changes))}
        onEdgesChange={(changes) => dispatch(onEdgesChange(changes))}
        onConnect={(connect) => dispatch(onConnectNode(connect))}
        panOnDrag={canZoomAndPan}
        zoomOnPinch={canZoomAndPan}
        zoomOnDoubleClick={canZoomAndPan}
        zoomOnScroll={canZoomAndPan}
        onClick={onReactFlowClick}
        onNodeDrag={onDragNode}
        onNodeDragStop={onDragNodeStop}
        onDragOver={onDragOver}
      >
        <ChartCancelAction />

        <ChartControls />
        {!isReadOnly ? <ChartStorage /> : null}
        {isFetching || isBusy ? <ChartBusyIndicator /> : null}
      </RenderGraph>
      {hasFocusedNode ? <FocusedNodePanelEditor /> : null}
    </>
  );
}

export default requireAuth(withEditorScaffold(RcaEditorChart));

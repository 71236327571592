import { EvidencePanelState } from '@pages/app/rca/tabs/components/editor-panels/evidence-panel-hook';
import useEvidenceListView from '@pages/app/rca/tabs/components/editor-panels/components/evidence-panel/evidence-list-view-hook';
import Column from '@components/layout-util-components/column';
import CaseEvidenceCard from '@components/cards/case-evidence-card';
import { ElipsisButton } from '@components/buttons';

interface Props {
  state: EvidencePanelState;
}
export default function EvidenceListView({ state }: Props) {
  const { evidenceList, editEvidence, onUnlinkEvidence, isUnlinking } =
    useEvidenceListView(state);
  return (
    <Column sx={{ gap: 1 }}>
      {evidenceList.map((evidence) => (
        <CaseEvidenceCard
          key={evidence.evidenceId}
          evidence={evidence}
          trailing={
            <ElipsisButton
              disabled={isUnlinking}
              actions={[
                { label: 'Edit', onClick: () => editEvidence(evidence) },
                { label: 'Unlink', onClick: () => onUnlinkEvidence(evidence) },
              ]}
            />
          }
        />
      ))}
    </Column>
  );
}

import { styled, Typography } from '@mui/material';
import Column from '@components/layout-util-components/column';
import Spacer from '@components/layout-util-components/spacer';
import { ReactNode } from 'react';
import HealthBadge from '@components/badges/health-badge';
import useSelectChainItemHealthScore from '@hooks/use-select-chain-item-health-score';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  title: string;
  isSelected?: boolean;
  trailing?: ReactNode;
  chainItemId?: number;
}

const Container = styled('div')<{ isSelected: boolean }>(({ isSelected }) => ({
  display: 'flex',
  alignItems: 'center',

  borderRadius: 5,
  border: isSelected ? '2px solid #000000BF' : '1px solid #00000040',
  padding: 10,
  marginBottom: 10,

  '.description': {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0.17000000178813934px',
    textAlign: 'left',
  },
}));

export default function ConnectionOption({
  isSelected,
  trailing,
  title,
  chainItemId,
}: Props) {
  const healthScore = useSelectChainItemHealthScore(chainItemId);
  return (
    <Container isSelected={isSelected ?? false}>
      <Column>
        <Typography variant="body2">{title}</Typography>
        {healthScore != null ? (
          <>
            <Gap size={8} />
            <HealthBadge health={healthScore} small />
          </>
        ) : null}
      </Column>
      <Spacer />
      {trailing != null ? trailing : null}
    </Container>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { Field } from './use-field-hook';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useAppDispatch } from '@store/store';
import { setCurrentFormDirty } from '@store/ui/ui-slice';

type FieldsWatcherArgs = Array<Field<any>>;
export default function useFieldsWatcher<T extends FieldsWatcherArgs>(
  fields: T
) {
  const dispatch = useAppDispatch();
  const shouldAutoValidate = useRef(false);

  const isValid = useMemo(
    () => {
      if (!shouldAutoValidate.current) {
        return true;
      }

      for (const field of fields) {
        if (!field.validate()) {
          return false;
        }
      }
      return true;
    },
    fields.map((field) => field.validate)
  );

  const { dirtyCount, isDirty } = useMemo(
    () => {
      let dirtyCount = 0;
      for (const field of fields) {
        if (field.isDirty) {
          dirtyCount += 1;
        }
      }
      return { dirtyCount, isDirty: dirtyCount > 0 };
    },
    fields.map((field) => field.isDirty)
  );

  const resetAll = useCallback(() => {
    shouldAutoValidate.current = false;
    for (const field of fields) {
      field.reset();
    }
  }, []);

  const validateAll = useCallback(
    () => {
      shouldAutoValidate.current = true;
      let isValid = true;
      for (const field of fields) {
        if (!field.validate()) {
          isValid = false;
        }
      }
      return isValid;
    },
    fields.map((field) => field.validate)
  );

  useEffect(() => {
    dispatch(setCurrentFormDirty(isDirty));
    return () => {
      dispatch(setCurrentFormDirty(false));
    };
  }, [isDirty]);

  return useMemo(
    () => ({
      isValid,
      isDirty,
      dirtyCount,
      resetAll,
      validateAll,
    }),
    [isDirty, isValid, dirtyCount, validateAll]
  );
}

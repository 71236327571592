import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  SideDrawerFormProps,
  withSideDrawer,
} from '@components/ui-popup/drawers';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import { Skeleton, styled, Typography } from '@mui/material';
import { useAdminTr } from '@i18n/use-admin-tr';
import WCTTextField from '@components/input/text-field';
import WCTSelectField from '@components/input/select-field';
import useCreateUser from '@pages/admin/users/forms/create-user-hook';
import { useCommonTr } from '@i18n/use-common-tr';
import { InputSkeleton } from '@components/skeletons';
import { Gap } from '@components/layout-util-components/gap';

export interface CreateUserFormProps extends SideDrawerFormProps<void> {}

const SplitFieldContainer = styled('div')({
  display: 'flex',
  justifyContent: 'stretch',
  gap: 20,
});

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
});

function CreateUserForm({ onClose }: CreateUserFormProps) {
  const {
    firstName,
    surname,
    email,
    companyRole,
    sector,
    jobTitle,
    company,
    canSubmit,
    sectorOptions,
    companyOptions,
    roleOptions,
    isLoading,
    isSubmitting,
    submit,
  } = useCreateUser();

  const { t } = useAdminTr('createUserForm');
  const { t: commonTr } = useCommonTr('buttons');

  const onSubmit = async () => {
    if (await submit()) {
      onClose();
    }
  };

  return (
    <Form
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <DrawerHeader onClose={onClose}>{t('title')}</DrawerHeader>
      <DrawerContent>
        <Typography variant="body1">
          {isLoading ? <Skeleton /> : t('sections.personalDetails')}
        </Typography>
        <Gap size={20} />
        <SplitFieldContainer>
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTTextField
              label={t('fields.firstName')}
              value={firstName.value}
              error={firstName.error}
              onChange={firstName.set}
              required
            />
          )}
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTTextField
              label={t('fields.surname')}
              value={surname.value}
              error={surname.error}
              onChange={surname.set}
              required
            />
          )}
        </SplitFieldContainer>
        <Gap size={20} />
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <WCTTextField
            label={t('fields.email')}
            value={email.value}
            error={email.error}
            onChange={email.set}
            required
          />
        )}
        <Gap size={20} />
        <Typography variant="body1">
          {isLoading ? <Skeleton /> : t('sections.companyRole')}
        </Typography>
        <Gap size={20} />
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <WCTSelectField
            id="companyRole"
            label={t('fields.role')}
            options={roleOptions || []}
            value={companyRole.value}
            error={companyRole.error}
            onChange={(v) => companyRole.set(v as string)}
            required
          />
        )}
        <Gap size={20} />
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <WCTTextField
            label={t('fields.jobTitle')}
            value={jobTitle.value}
            error={jobTitle.error}
            onChange={jobTitle.set}
          />
        )}
        <Gap size={20} />
        <Typography variant="body1">
          {isLoading ? <Skeleton /> : t('sections.accountManagement')}
        </Typography>
        <Gap size={20} />
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <WCTSelectField
            id="sector"
            label={t('fields.sector')}
            options={sectorOptions!}
            value={sector.value}
            error={sector.error}
            onChange={(v) => sector.set(v as Array<number>)}
            required
            multiple
          />
        )}
        <Gap size={20} />
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <WCTSelectField
            id="company"
            label={t('fields.company')}
            options={companyOptions!}
            value={company.value}
            error={company.error}
            onChange={(v) => company.set(v as Array<number>)}
            required
            multiple
          />
        )}
        <Gap size={20} />
      </DrawerContent>
      <DrawerFooter>
        <OutlinedButton onClick={() => onClose()}>
          {commonTr('cancel')}
        </OutlinedButton>
        <PrimaryButton
          type="submit"
          disabled={!canSubmit}
          isBusy={isSubmitting}
        >
          {t('buttonLabel')}
        </PrimaryButton>
      </DrawerFooter>
    </Form>
  );
}

export default withSideDrawer(CreateUserForm);

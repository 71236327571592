import { useAppSelector } from '@store/store';
import {
  selectChainId,
  selectFocusedNode,
  selectNodePanelEditorShouldDefaultToCreate,
} from '@store/rca-editor/selectors';
import { useGetTasksForChainItemQuery } from '@api/endpoints/chain/chain.api';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { useState } from 'react';
import useField from '@hooks/use-field-hook';
import { required } from '@util/validators';
import { TaskPriority } from '@api/types/task-detail/task-priority';
import { useGetCompanyUsersOptionsQuery } from '@api/endpoints/company-user.api';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import {
  useCreateTaskMutation,
  useUpdateTaskForCompanyMutation,
} from '@api/endpoints/company/company-task-detail.api';
import { ApiError } from '@api/types/api-error';
import { CreateTaskDetailRequest } from '@api/types/task-detail/create-task-detail.request';
import { usePageAlertVariants } from '@components/alerts';
import { TaskDetailListItemResponse } from '@api/types/task-detail/task-detail-list-item.response';
import { UpdateTaskDetailRequest } from '@api/types/task-detail/update-task-detail.request';
import { useFilter, useSortableHeader } from '@components/table';
import { SortDirection } from '@api/types/sort-direction';
import {
  allTaskStatus,
  nonCompleteTaskStatus,
} from '@api/types/task-detail/task-status';
import useCompleteTask from '@pages/app/tasks/hooks/complete-task-took';
import { SortOption } from '@components/table/hooks/use-sortable-header-hook';

export default function useTasksPanel() {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const defaultToCreate = useAppSelector(
    selectNodePanelEditorShouldDefaultToCreate
  );
  const [showForm, setShowForm] = useState(defaultToCreate);
  const chainId = useAppSelector(selectChainId)!;
  const focusedNode = useAppSelector(selectFocusedNode);
  const { chainItemId } = focusedNode.data;

  const [createTask, { isLoading: isSubmittingCreate }] =
    useCreateTaskMutation();

  const [updateTask, { isLoading: isSubmittingUpdate }] =
    useUpdateTaskForCompanyMutation();

  const {
    completeTask,
    openTask,
    isLoading: isChangingTaskStatus,
  } = useCompleteTask();

  const { data: userOptions, isLoading: loadingUsers } =
    useGetCompanyUsersOptionsQuery({});

  const [edittingId, setEdittingId] = useState<number>();
  const name = useField<string>([required()]);
  const description = useField<string>([required()]);
  const assignedUser = useField<number>([required()]);
  const dueDate = useField<string | undefined>([required()]);
  const priority = useField<TaskPriority>([required()], TaskPriority.low);

  const showCompleted = useFilter<boolean>(false);
  const sort = useSortableHeader({
    initialProperty: 'dueDate',
    initialDirection: SortDirection.desc,
  });

  const sortOptions: Array<SortOption> = [
    {
      id: 'dueDate',
      label: 'Due Date (latest first)',
      direction: SortDirection.desc,
    },
    {
      id: 'dueDate',
      label: 'Due Date (earliest first)',
      direction: SortDirection.asc,
    },
  ];

  const {
    data: tasks,
    isLoading: loadingTasks,
    status,
  } = useGetTasksForChainItemQuery(
    {
      chainId,
      chainItemId: chainItemId ?? -1,
      sortBy: sort.property,
      sortDirection: sort.direction,
      status: showCompleted.value ? allTaskStatus : nonCompleteTaskStatus,
    },
    { skip: chainItemId == null }
  );

  const { isValid, isDirty, resetAll, validateAll } = useFieldsWatcher([
    name,
    description,
    assignedUser,
    dueDate,
    priority,
  ]);

  const isLoading =
    loadingTasks || status === QueryStatus.uninitialized || loadingUsers;
  const isSubmitting = isSubmittingCreate || isSubmittingUpdate;
  const canSubmit = isDirty && isValid && !isLoading && !isSubmitting;

  const beginAddingTask = () => {
    setEdittingId(undefined);
    resetAll();
    setShowForm(true);
  };

  const beginEditting = (task: TaskDetailListItemResponse) => {
    setEdittingId(task.taskDetailId);
    name.reset(task.title);
    description.reset(task.description);
    assignedUser.reset(task.assignedToCompanyUserId);
    dueDate.reset(task.dueDate);
    priority.reset(task.priority);
    setShowForm(true);
  };

  const submit = async (): Promise<boolean> => {
    if (!validateAll()) {
      return false;
    }

    if (edittingId == null) {
      return createTask({
        chainItemId: chainItemId,
        title: name.value,
        description: description.value,
        assignedToCompanyUserId: assignedUser.value,
        dueDate: dueDate.value!,
        priority: priority.value,
      })
        .unwrap()
        .then(() => {
          showSuccessMessage(
            `You have successfully created new investigation from Cause Box #${chainItemId}`
          );
          setShowForm(false);
          return true;
        })
        .catch(({ message, errors }: ApiError<CreateTaskDetailRequest>) => {
          showErrorMessage(errors?.chainItemId || message);

          name.setError(errors?.title);
          description.setError(errors?.description);
          assignedUser.setError(errors?.assignedToCompanyUserId);
          dueDate.setError(errors?.dueDate);
          priority.setError(errors?.priority);

          return false;
        });
    } else {
      return updateTask({
        taskDetailID: edittingId!,
        description: description.value,
        assignedToCompanyUserId: assignedUser.value,
        dueDate: dueDate.value!,
        priority: priority.value,
      })
        .unwrap()
        .then(() => {
          showSuccessMessage('You have successfully updated the task');
          setShowForm(false);
          setEdittingId(undefined);
          return true;
        })
        .catch(({ message, errors }: ApiError<UpdateTaskDetailRequest>) => {
          showErrorMessage(errors?.taskDetailID || message);

          // name.setError(errors?.title);
          description.setError(errors?.description);
          assignedUser.setError(errors?.assignedToCompanyUserId);
          dueDate.setError(errors?.dueDate);
          priority.setError(errors?.priority);

          return false;
        });
    }
  };

  return {
    tasks,
    isLoading,
    canSubmit,
    isSubmitting,
    isEditting: edittingId != null,
    showForm,
    beginAddingTask,
    name,
    description,
    assignedUser,
    dueDate,
    userOptions,
    priority,
    submit,
    beginEditting,
    showCompleted,
    sort,
    completeTask,
    openTask,
    isChangingTaskStatus,
    setShowForm,
    sortOptions,
  };
}

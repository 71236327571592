import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const useProxy = !!process.env.REACT_APP_PROXY;
export const API_URL = useProxy ? '' : process.env.REACT_APP_API_URL;

const POSTMAN_HEADERS = !!process.env.REACT_APP_POSTMAN_API_KEY
  ? { 'x-api-key': process.env.REACT_APP_POSTMAN_API_KEY as string }
  : {};

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/`,
    credentials: 'include',
    headers: {
      ...(POSTMAN_HEADERS as {}),
    },
    paramsSerializer: (params) => {
      let str = '';
      for (const paramsKey in params) {
        const val = params[paramsKey];
        if (val == null) {
          continue;
        }

        if (Array.isArray(val)) {
          for (const itemVal of val) {
            str += `${paramsKey}=${itemVal}&`;
          }
        } else {
          str += `${paramsKey}=${val}&`;
        }
      }
      if (str.length > 0) {
        str = str.substring(0, str.length - 1);
      }
      return str;
    },
  }),
  endpoints: () => ({}),
  tagTypes: [
    'User',
    'CompanyUser',
    'CompanyGroup',
    'Subscription',
    'TaskDetail',
    'CompanyLocation',
    'Case',
    'CaseImpact',
    'CaseGroup',
    'CaseUser',
    'ChainItem',
    'ChainItemCaseImpact',
    'ChainItemNote',
    'CaseFile',
    'Evidence',
    'Solution',
    'ChainItemEvent',
    'CaseTotals',
    'AllCaseFiles',
    'Skills',
    'SkillLinked',
    'CompanySkills',
    'CaseReport',
    'Outcome',
  ],
});

import Column from '@components/layout-util-components/column';
import EditPanelHeader from '@pages/app/rca/tabs/components/editor-panels/edit-panel-header';
import useEvidencePanel, {
  EvidencePanelViewState,
} from '@pages/app/rca/tabs/components/editor-panels/evidence-panel-hook';
import { LoadingIndicator } from '@components/loading-indicator';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';
import { ReactComponent as EmptyIcon } from '@assets/svgs/no-tasks.svg';
import AddEvidenceOptionView from '@pages/app/rca/tabs/components/editor-panels/components/evidence-panel/add-evidence-option-view';
import UpsertEvidenceFormView from '@pages/app/rca/tabs/components/editor-panels/components/evidence-panel/upsert-evidence-form-view';
import { OutlinedButton } from '@components/buttons';
import EvidenceListView from '@pages/app/rca/tabs/components/editor-panels/components/evidence-panel/evidence-list-view';
import { Gap } from '@components/layout-util-components/gap';

export default function EvidencePanel() {
  const state = useEvidencePanel();
  const {
    evidence,
    isLoading,
    viewState,
    startCreateFlow,
    edittingEvidenceDetail,
  } = state;

  return (
    <Column>
      <EditPanelHeader
        title="Evidence"
        count={evidence?.totalCount}
        end={
          viewState === EvidencePanelViewState.view ? (
            <OutlinedButton onClick={startCreateFlow}>
              Add Evidence
            </OutlinedButton>
          ) : null
        }
      />
      <Gap size={20} />
      {isLoading ? (
        <LoadingIndicator show />
      ) : evidence!.totalCount === 0 &&
        viewState === EvidencePanelViewState.empty ? (
        <OnboardingEntryContainer
          icon={<EmptyIcon />}
          title="There is no evidence currently assigned to this chain item"
          message="You haven’t currently linked any evidence to this cause box, please click below to add evidence"
          createMessage="Add Evidence"
          onCreateClicked={startCreateFlow}
          compact
          dark
        />
      ) : viewState === EvidencePanelViewState.createOptions ? (
        <AddEvidenceOptionView state={state} />
      ) : viewState === EvidencePanelViewState.create ||
        viewState === EvidencePanelViewState.edit ? (
        <UpsertEvidenceFormView
          handleClose={state.cancelCreate}
          evidence={edittingEvidenceDetail}
        />
      ) : (
        <EvidenceListView state={state} />
      )}
    </Column>
  );
}

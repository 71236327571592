import { OutcomeReportState } from '@pages/app/outcome/outcome-report-hook';
import { styled } from '@mui/material';
import OutcomeReportScaffold from '@components/outcome-report/outcome-report-scaffold';

interface Props {
  state: OutcomeReportState;
}

const PageContainer = styled('div')({
  width: '100vw',
  height: '100vh',
  display: 'flex',
});

export default function ReportView({ state }: Props) {
  const { caseId, outcomeId } = state;

  return (
    <PageContainer>
      <OutcomeReportScaffold caseId={caseId} outcomeId={outcomeId} />
    </PageContainer>
  );
}

import { styled, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { CaseEvidenceResource } from '@api/types/case/case-evidence/case-evidence.resource';
import Column from '@components/layout-util-components/column';
import { isNullOrEmpty } from '@util/string-util';
import Row from '@components/layout-util-components/row';
import FlatBadge from '@components/badges/flat-badge';
import AssociatedCauseBoxBadge from '@components/badges/associated-cause-box-badge';
import { BorderedContainer } from '@components/containers/bordered-container';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  evidence: CaseEvidenceResource;
  trailing?: ReactNode;
}

const Container = styled(BorderedContainer)(({ theme: { palette } }) => ({
  '.name': {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.15000000596046448px',
    textAlign: 'left',
  },

  '.associated-chain-items': {
    borderRadius: 4,
    background: palette.common.grey20,
    color: palette.common.black,
    padding: 4,
    fontFamily: 'Kyn, Roboto',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '14px',
    letterSpacing: '0em',
    textAlign: 'center',
    alignSelf: 'flex-start',
  },
}));

export default function CaseEvidenceCard({ evidence, trailing }: Props) {
  return (
    <Container>
      <Column sx={{ flexGrow: 1, flexShrink: 0 }}>
        <Typography className="name">{evidence.name}</Typography>
        {!isNullOrEmpty(evidence.description) ? (
          <>
            <Gap size={8} />
            <Typography variant="caption">{evidence.description}</Typography>
          </>
        ) : null}
        <Gap size={8} />
        <Row>
          <FlatBadge>Strength: {evidence.quality}/5</FlatBadge>
          <Gap size={8} />
          <FlatBadge>{evidence.sensitivity} Sensitivity</FlatBadge>
        </Row>
        {evidence.chainItemIds != null && evidence.chainItemIds.length > 0 ? (
          <>
            <Gap size={8} />
            <AssociatedCauseBoxBadge count={evidence.chainItemIds.length} />
          </>
        ) : null}
      </Column>
      {trailing != null ? trailing : null}
    </Container>
  );
}

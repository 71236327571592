import { Option } from '@api/types/option';
import { Box, Typography, SxProps, Theme } from '@mui/material';
import { styled } from '@mui/system';

const StyledLabelWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected?: boolean }>(({ theme, isSelected }) => ({
  borderRadius: theme.spacing(0.75),
  flexBasis: '25%',
  backgroundColor: isSelected ? '#A7A7A7' : '#E7E7E7',
  cursor: 'pointer',
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'stretch',
  '& p': {
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '18px' /* 138.462% */,
    letterSpacing: '0.16px',
    padding: '3px 6px',
  },
}));

const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  alignSelf: 'stretch',
  margin: `${theme.spacing(7)} 0`,
}));

interface Props {
  sx?: SxProps<Theme>;
  categories?: Option<number>[];
  selected?: number[];
  onClick?: (id: number) => void;
}

export const Categories = ({ sx, categories, selected, onClick }: Props) => {
  if (!categories?.length) {
    return <Box mb={6} />;
  }

  return (
    <StyledContainer>
      {categories?.map((option) => (
        <StyledLabelWrapper
          key={option.id}
          onClick={() => {
            onClick && onClick(option.id);
          }}
          isSelected={selected?.includes(option.id)}
        >
          <Typography>{option.label}</Typography>
        </StyledLabelWrapper>
      ))}
    </StyledContainer>
  );
};

import requireAuth from '@components/hoc/with-required-auth-hoc';
import useGroups from './groups-hook';
import ManageGroupsPage from './manage-groups-page';
import MyGroupsPage from './my-groups-page';
import ContentContainer from '@components/containers/content-container';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import { Grid } from '@mui/material';

function GroupsPage() {
  const { seeMyGroups, setSeeMyGroups, canManageGroups } = useGroups();
  return (
    <>
      {canManageGroups && (
        <ContentContainer>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            gap={1}
          >
            {seeMyGroups ? (
              <OutlinedButton onClick={() => setSeeMyGroups(false)}>
                All Groups
              </OutlinedButton>
            ) : (
              <PrimaryButton>All Groups</PrimaryButton>
            )}
            {!seeMyGroups ? (
              <OutlinedButton onClick={() => setSeeMyGroups(true)}>
                My Groups
              </OutlinedButton>
            ) : (
              <PrimaryButton>My Groups</PrimaryButton>
            )}
          </Grid>
        </ContentContainer>
      )}
      {canManageGroups && !seeMyGroups ? (
        <ManageGroupsPage />
      ) : (
        <MyGroupsPage />
      )}
    </>
  );
}

export default requireAuth(GroupsPage);

import { Address } from '@components/input/address-field';
import { DefaultTFuncReturn } from 'i18next';

export function isNullOrEmpty(str?: string) {
  return str == null || str.length === 0;
}

export function numberFromString(str?: string | number) {
  let num = str;

  if (num == null) {
    return undefined;
  } else {
    // noinspection SuspiciousTypeOfGuard
    if (typeof num === 'number') {
      return num;
    } else if (typeof num === 'string') {
      const match = num.match(/^-?\d+(?:\.\d+)?$/);
      return match ? parseFloat(match[0]) : undefined;
    }
  }

  try {
    return parseInt(num, 10);
  } catch (_) {
    return undefined;
  }
}

export function maskPhoneNumber(dialCode: string, phone: string) {
  const mask = '********';
  return `${dialCode}${mask}${phone.substring(mask.length)}`;
}

export function getAddressString(address?: Address) {
  if (address == null) {
    return '';
  }

  return [
    address.addressLineOne,
    address.addressLineTwo,
    address.town,
    address.county,
    address.country,
    address.postcode,
  ]
    .filter((x) => !isNullOrEmpty(x))
    .join(', ');
}

export function optionalLabel(
  label?: string | DefaultTFuncReturn,
  required?: boolean
) {
  if (label == null) {
    return;
  }

  return required ? label : `${label} (optional)`;
}

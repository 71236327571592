import { Skeleton, styled, Typography } from '@mui/material';

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
});

export default function SwitchFieldSkeleton() {
  return (
    <Container>
      <Skeleton variant="rounded" height={25} width={38} />
      <Typography variant="body1">
        <Skeleton width={140} />
      </Typography>
    </Container>
  );
}

import { useAppDispatch, useAppSelector } from '@store/store';
import { useCallback, useMemo } from 'react';
import { getFullName, getInitials } from '@util/name-util';
import { isApp } from '@util/env';
import { useLogoutMutation } from '@api/endpoints/auth.api';
import { ApiError } from '@api/types/api-error';
import { useNavigate } from 'react-router-dom';
import { usePageAlertVariants } from '@components/alerts';
import { api } from '@api/api';

export default function useUser() {
  const dispatch = useAppDispatch();

  const {
    userId,
    companyId,
    companyUserId,
    is2FAAuthenticated,
    requires2FA,
    firstName,
    lastName,
    permissions,
    companyRole,
    createdCompany,
  } = useAppSelector((state) => state.user);

  const isLoggedIn = !!userId;

  const { showErrorMessage } = usePageAlertVariants();
  const navigate = useNavigate();
  const [logoutMutation] = useLogoutMutation();

  const fullName = useMemo(() => {
    return getFullName(firstName, lastName);
  }, [firstName, lastName]);

  const initials = useMemo(() => {
    return getInitials(fullName);
  }, [fullName]);

  const hasPermission = useCallback(
    (permission: string) => {
      if (permissions == null || !Array.isArray(permissions)) {
        return false;
      }

      return permissions.indexOf(permission) > -1;
    },
    [permissions]
  );

  const logout = useCallback(() => {
    navigate('/auth/login', {
      state: { prompt: 'logout-success', returnTo: '/', noRedirect: true },
    });

    return logoutMutation()
      .unwrap()
      .then(() => {
        dispatch(api.util.resetApiState());

        return true;
      })
      .catch(({ message }: ApiError<never>) => {
        showErrorMessage(message);
        return false;
      });
  }, [dispatch, logoutMutation, navigate, showErrorMessage]);

  return useMemo(
    () => ({
      isLoggedIn,
      isLoggedInAndFullyAuthenticated:
        isLoggedIn && (!requires2FA || is2FAAuthenticated),
      userId,
      companyUserId,
      companyId,
      userRequires2fa: requires2FA && !is2FAAuthenticated,
      firstName,
      lastName,
      fullName,
      initials,
      hasPermission,
      isAdminUser: isApp && companyRole === 'Admin',
      logout,
      createdCompany,
    }),
    [
      isLoggedIn,
      requires2FA,
      is2FAAuthenticated,
      userId,
      companyUserId,
      companyId,
      firstName,
      lastName,
      fullName,
      initials,
      hasPermission,
      companyRole,
      logout,
      createdCompany,
    ]
  );
}

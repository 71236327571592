import { ReactComponent as NoTasksIcon } from '@assets/svgs/no-tasks.svg';
import OnboardingEntryContainer, {
  OnboardingEntryContainerProps,
} from '@components/containers/onboarding-entry-container';

type Props = {
  isCaseTasks?: boolean;
  onCreateClicked: () => void;
} & Partial<OnboardingEntryContainerProps>;

export default function NoTasksInfo({
  onCreateClicked,
  isCaseTasks,
  ...rest
}: Props) {
  return (
    <OnboardingEntryContainer
      {...rest}
      icon={<NoTasksIcon />}
      title={
        isCaseTasks
          ? // eslint-disable-next-line quotes
            "You don't currently have any tasks"
          : 'There are no tasks currently assigned to this chain item'
      }
      message={
        isCaseTasks
          ? // eslint-disable-next-line quotes
            "There aren't currently any tasks for this case, you can add one below"
          : // eslint-disable-next-line quotes
            "There aren't currently any tasks within the platform, you can add one below."
      }
      createMessage={'Add Task'}
      onCreateClicked={onCreateClicked}
    />
  );
}

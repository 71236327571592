import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import { ElipsisButton } from '@components/buttons';
import WCTSelectField from '@components/input/select-field';
import { Option } from '@api/types/option';
import { CaseUserResource } from '@api/types/case/case-collaboration/case-user.resource';
import FilledBadge from '@components/badges/filled-badge';

interface Props {
  user: CaseUserResource;
  onRoleChange: (newRoleId: number) => void;
  onRemove: () => void;
  roleOptions: Option[];
}

export default function CollaborateInvitedUserRow({
  user,
  onRoleChange,
  onRemove,
  roleOptions,
}: Props) {
  const { companyUserId, name, caseRoleId, caseRoleName, skills } = user;

  return (
    <tr>
      <td style={{ minWidth: 200 }}>
        <InitialsContainerWithData label={name} fullName={name} />
      </td>
      <td>{caseRoleName}</td>
      <td>
        {skills && skills.length > 0 && (
          <FilledBadge>{skills[0].label}</FilledBadge>
        )}
        {skills && skills.length > 1 && (
          <FilledBadge>+{skills.length - 1}</FilledBadge>
        )}
      </td>
      {/*<td>{rcaCount}</td>*/}
      <td width={269}>
        <WCTSelectField
          name={`user-${companyUserId}-invite-role`}
          id={`user-${companyUserId}-invite-role`}
          options={roleOptions}
          value={caseRoleId}
          onChange={(v) => onRoleChange(v as number)}
          placeholder="Invite Role"
        />
      </td>
      <td style={{ width: 100 }}>
        <ElipsisButton actions={[{ label: 'Remove', onClick: onRemove }]} />
      </td>
    </tr>
  );
}

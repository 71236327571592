import WCTTextField from '@components/input/text-field';
import { styled } from '@mui/material';
import { NotePanelState } from '@pages/app/rca/tabs/components/editor-panels/notes-panel-hook';
import { LoadingIndicator } from '@components/loading-indicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faComment, faTimes } from '@fortawesome/pro-light-svg-icons';

interface Props {
  state: NotePanelState;
}

const Container = styled('div', { target: 'note-input-container' })(
  ({ theme: { palette } }) => ({
    position: 'relative',

    '.note-actions': {
      display: 'flex',
      position: 'absolute',
      right: 8,
      bottom: 8,
      gap: 1,

      '.action-button': {
        outline: 'none',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: palette.common.black,
        flex: '0 0 auto',
        width: 36,
        height: 36,
        border: 'none',
        borderRadius: '50%',
        color: 'white !important',

        '&[disabled]': {
          background: '#11111144',
        },

        '&.transparent': {
          background: 'transparent',
          color: 'black !important',
        },
      },
    },
  })
);

export default function NoteInput({ state }: Props) {
  const {
    note,
    canSubmit,
    isSubmitting,
    onSubmit,
    isEditing,
    cancelEditingNote,
  } = state;

  return (
    <Container>
      <WCTTextField
        name="noteEntry"
        label={isEditing ? 'Edit note' : 'Add note'}
        value={note.value}
        onChange={note.set}
        multiline
        addRightPadding
        required
      />
      <div className="note-actions">
        {isEditing && !isSubmitting ? (
          <button
            className="action-button transparent"
            onClick={cancelEditingNote}
          >
            {isSubmitting ? (
              <LoadingIndicator show />
            ) : (
              <FontAwesomeIcon icon={faTimes} />
            )}
          </button>
        ) : null}
        <button
          className="action-button"
          disabled={!canSubmit}
          onClick={onSubmit}
        >
          {isSubmitting ? (
            <LoadingIndicator show />
          ) : isEditing ? (
            <FontAwesomeIcon icon={faCheck} />
          ) : (
            <FontAwesomeIcon icon={faComment} />
          )}
        </button>
      </div>
    </Container>
  );
}

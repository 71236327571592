import requireAuth from '@components/hoc/with-required-auth-hoc';
import { Grid, Typography } from '@mui/material';
import WCTTextField from '@components/input/text-field';
import { PrimaryButton } from '@components/buttons';
import { InputSkeleton } from '@components/skeletons';
import { useAppTr } from '@i18n/use-app-tr';
import WCTSelectField from '@components/input/select-field';
import { Divider } from '@components/layout-util-components/divider';
import useCompanyInformation from '@pages/app/company-profile/company-information-hook';
import WCTAddressField from '@components/input/address-field';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import SubpageTitle from '@components/menu-items/subpage-title';
import RefreshCancelButton from '@components/buttons/refresh-cancel-button';
import { Gap } from '@components/layout-util-components/gap';

function CompanyInformationPage() {
  const { t } = useAppTr('companyInformation');

  const {
    name,
    sector,
    businessArea,
    address,
    countryOptions,
    sectorOptions,
    businessAreaOptions,
    currencyOptions,
    defaultCurrency,
    canSubmit,
    submit,
    isLoading,
    isSubmitting,
  } = useCompanyInformation();

  const { showConfirmationModal } = useUiPopup();

  const onSubmit = async () => {
    const dirtyCount = [
      name.isDirty,
      sector.isDirty,
      businessArea.isDirty,
      address.isDirty,
    ].filter((isDirty) => isDirty).length;

    let shouldContinue: boolean | undefined;
    if (dirtyCount > 1) {
      shouldContinue = await showConfirmationModal({
        title: t('updateModals.generic.title'),
        message: t('updateModals.generic.message'),
        yesButtonLabel: t('updateModals.generic.yesButtonLabel'),
        noButtonLabel: t('updateModals.generic.noButtonLabel'),
      });
    } else if (name.isDirty) {
      shouldContinue = await showConfirmationModal({
        title: t('updateModals.name.title'),
        message: t('updateModals.name.message'),
        yesButtonLabel: t('updateModals.name.yesButtonLabel'),
        noButtonLabel: t('updateModals.name.noButtonLabel'),
      });
    } else if (sector.isDirty) {
      shouldContinue = await showConfirmationModal({
        title: t('updateModals.sector.title'),
        message: t('updateModals.sector.message'),
        yesButtonLabel: t('updateModals.sector.yesButtonLabel'),
        noButtonLabel: t('updateModals.sector.noButtonLabel'),
      });
    } else if (businessArea.isDirty) {
      shouldContinue = await showConfirmationModal({
        title: t('updateModals.businessArea.title'),
        message: t('updateModals.businessArea.message'),
        yesButtonLabel: t('updateModals.businessArea.yesButtonLabel'),
        noButtonLabel: t('updateModals.businessArea.noButtonLabel'),
      });
    } else if (address.isDirty) {
      shouldContinue = await showConfirmationModal({
        title: t('updateModals.address.title'),
        message: t('updateModals.address.message'),
        yesButtonLabel: t('updateModals.address.yesButtonLabel'),
        noButtonLabel: t('updateModals.address.noButtonLabel'),
      });
    }

    if (shouldContinue) {
      await submit();
    }
  };

  return (
    <form
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <SubpageTitle
        breadCrumb="Company Settings / Company information"
        title={t('title')}
      />
      <Gap size={32} />
      <Grid container>
        <Grid item xs={6} flexDirection="column">
          <Typography variant="body1">{t('name.title')}</Typography>
          <Typography variant="body2">{t('name.message')}</Typography>
        </Grid>
        <Grid item xs={6} flexDirection="column">
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTTextField
              name="name"
              label={t('name.label')}
              value={name.value}
              error={name.error}
              onChange={name.set}
              required
            />
          )}
        </Grid>
      </Grid>
      <Divider addMargin />
      <Grid container>
        <Grid item xs={6} flexDirection="column">
          <Typography variant="body1">{t('type.title')}</Typography>
          <Typography variant="body2">{t('type.message')}</Typography>
        </Grid>
        <Grid item xs={6} flexDirection="column">
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTSelectField
              id="sector"
              name="sector"
              label={t('type.sectorLabel')}
              options={sectorOptions!}
              value={sector.value}
              error={sector.error}
              onChange={sector.set}
              required
            />
          )}
          <Gap size={32} />
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTSelectField
              name="businessArea"
              id="business-area"
              label={t('type.areaLabel')}
              options={businessAreaOptions!}
              value={businessArea.value}
              error={businessArea.error}
              onChange={businessArea.set}
              required
            />
          )}
        </Grid>
      </Grid>
      <Divider addMargin />
      <Grid container>
        <Grid item xs={6} flexDirection="column">
          <Typography variant="body1">{t('currency.title')}</Typography>
          <Typography variant="body2">{t('currency.message')}</Typography>
        </Grid>
        <Grid item xs={6} flexDirection="column">
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTSelectField
              name="currency"
              id="currency"
              label={t('currency.currencyLabel')}
              options={currencyOptions!}
              value={defaultCurrency.value}
              error={defaultCurrency.error}
              onChange={defaultCurrency.set}
              required
            />
          )}
        </Grid>
      </Grid>
      <Divider addMargin />
      <Grid container>
        <Grid item xs={6} flexDirection="column">
          <Typography variant="body1">{t('address.title')}</Typography>
          <Typography variant="body2">{t('address.message')}</Typography>
        </Grid>
        <Grid item xs={6} flexDirection="column">
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTAddressField
              id="address"
              countryOptions={countryOptions!}
              value={address.value}
              errors={address.errors}
              onChange={address.set}
            />
          )}
        </Grid>
      </Grid>
      <Divider addMargin />
      <Grid item container justifyContent="flex-end" alignItems="center">
        <RefreshCancelButton />
        <Gap size={20} />
        <PrimaryButton
          disabled={!canSubmit}
          isBusy={isSubmitting}
          type="submit"
        >
          {t('updateButtonLabel')}
        </PrimaryButton>
      </Grid>
    </form>
  );
}

export default requireAuth(CompanyInformationPage);

import { styled } from '@mui/material';
import { useRef } from 'react';

interface Props<T> {
  name: string;
  value: T[];
  onChange: (items: T[]) => void;
  RenderItem: (item: T) => JSX.Element;
  itemIdKey: keyof T;
}

const Container = styled('div', { target: 'wct-sortable-list-field' })({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: 8,
});

export default function WCTSortableListField<T = any>({
  value,
  onChange,
  RenderItem,
  itemIdKey,
}: Props<T>) {
  const draggingPos = useRef(null);
  const dragOverPos = useRef(null);

  const handleDragStart = (position) => {
    draggingPos.current = position;
  };

  const handleDragEnter = (position) => {
    dragOverPos.current = position;
    const newItems = [...value];
    const draggingItem = newItems[draggingPos.current!];
    if (!draggingItem) return;

    newItems.splice(draggingPos.current!, 1);
    newItems.splice(dragOverPos.current!, 0, draggingItem);

    const reorderedItems = newItems.map((item, index) => ({
      ...item,
      order: index,
    }));

    draggingPos.current = position;
    dragOverPos.current = null;

    onChange(reorderedItems);
  };

  return (
    <Container>
      {value.map((item, index) => (
        <div
          key={item[itemIdKey]!.toString()}
          className="item"
          draggable
          onDragStart={() => handleDragStart(index)}
          onDragEnter={() => handleDragEnter(index)}
          onDragOver={(e) => e.preventDefault()}
        >
          <RenderItem {...(item as any)} />
        </div>
      ))}
    </Container>
  );
}

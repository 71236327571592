import { faChevronDown, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  styled,
  Typography,
} from '@mui/material';
import { IHelpCentreContent } from '@api/types/help-center/help-center-content';
import { useState } from 'react';

const StyledAccordianSummaryContent = styled(Box)({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'row',
  justifyContent: 'space-between',
});

interface Props {
  faqs: IHelpCentreContent[];
  defaultId?: number;
}

export const FaqsList = ({ faqs, defaultId }: Props) => {
  const [expanded, setExpanded] = useState<number | false>(defaultId || false);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  if (!faqs.length) {
    return null;
  }

  return (
    <Box>
      {faqs.map((faq) => (
        <Accordion
          key={`faq-${faq.helpCentreContentId}`}
          expanded={expanded === faq.helpCentreContentId}
          onChange={handleChange(faq.helpCentreContentId)}
        >
          <AccordionSummary
            expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <StyledAccordianSummaryContent>
              <Box sx={{ display: 'flex' }}>
                <FontAwesomeIcon icon={faPlus} />
                <Typography sx={{ ml: 2 }}>{faq.title}</Typography>
              </Box>
              <Box mr={2}>
                {faq.categories.map((category) => (
                  <Chip
                    size="small"
                    sx={{ ml: 2 }}
                    key={`faq-category-${category.id}`}
                    label={category.label}
                  />
                ))}
              </Box>
            </StyledAccordianSummaryContent>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.content}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

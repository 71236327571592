import { Box, styled } from '@mui/material';

export const FlexColumn = styled(Box)<{ gap?: number }>(({ gap }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: gap || 0,
}));

export const FlexRow = styled(Box)<{ justify?: string }>(({ justify }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: justify || 'space-between',
}));

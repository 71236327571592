import { configureStore } from '@reduxjs/toolkit';
import { api } from '@api/api';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import userReducer from './user/user-slice';
import localeReducer from './locale/locale-slice';
import reportReducer from './reports/reports-slice';
import rcaEditorReducer from './rca-editor/rca-editor-slice';
import createAnalysisReducer from '@store/create-analysis-process/create-analysis-slice';
import createSystemTextReducer from '@store/system-text/system-text-slice';
import uiReducer from './ui/ui-slice';
import rcaEditorSnapshotReducer from '@store/rca-editor-snapshot/rca-editor-snapshot-slice';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    user: userReducer,
    locale: localeReducer,
    rcaEditor: rcaEditorReducer,
    rcaEditorSnapshot: rcaEditorSnapshotReducer,
    createAnalysisProcess: createAnalysisReducer,
    reports: reportReducer,
    ui: uiReducer,
    systemText: createSystemTextReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(api.middleware);
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

import { Button, styled } from '@mui/material';

const FlatButton = styled(Button, { target: 'flat-button' })<{
  dark?: boolean;
  outline?: boolean;
  shape?: 'circle' | 'square';
}>(({ dark, shape = 'square', outline, theme: { palette } }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: 32,
  minHeight: 32,
  flex: '0 0 auto',
  backgroundColor: dark ? palette.common.black : `${palette.common.grey45}80`,
  borderRadius: shape === 'square' ? 4 : 16,
  color: dark ? palette.common.white : palette.common.black,
  fontSize: '16px',
  fontWeight: '300',
  lineHeight: '20px',
  letterSpacing: '0.1599999964237213px',
  textAlign: 'center',

  '&:hover': {
    backgroundColor: dark ? palette.common.black : `${palette.common.grey45}80`,
  },

  svg: {
    height: 16,
    width: 'auto',
    color: dark ? palette.common.white : palette.common.black,
    stroke: dark ? palette.common.white : palette.common.black,
  },

  ...(outline && {
    backgroundColor: 'transparent',
    border: '1px solid #AEAEAE',
    padding: '6px 16px',
  }),
}));

export default FlatButton;

import { ColumnForm } from '@components/layout-util-components/column';
import WCTTextField from '@components/input/text-field';
import useUpsertEvidenceFormView from '@pages/app/rca/tabs/components/editor-panels/components/evidence-panel/upsert-evidence-form-view-hook';
import { Typography } from '@mui/material';
import { Divider } from '@components/layout-util-components/divider';
import CaseFileManagerInput from '@pages/app/rca/tabs/components/modals/case-file-manager/case-file-manager-input';
import InlineRadioSelectGroup from '@components/input/inline-radio-select-group';
import Spacer from '@components/layout-util-components/spacer';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import { CaseEvidenceResource } from '@api/types/case/case-evidence/case-evidence.resource';
import { EvidenceSensitivity } from '@api/types/case/case-evidence/evidence-sensitivity';
import RelatedCauseBoxCard from '@components/cards/related-cause-box-card';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  handleClose: () => void;
  handleSubmit?: () => void;
  evidence?: CaseEvidenceResource;
  caseId?: number;
  showExtra?: boolean;
}
export default function UpsertEvidenceFormView({
  handleClose,
  handleSubmit = handleClose,
  evidence,
  showExtra,
  caseId,
}: Props) {
  const {
    isEdit,
    sensitivity,
    sensitivityOptions,
    strengthOptions,
    canSubmit,
    submit,
    files,
    strength,
    name,
    description,
    isSubmitting,
    deleteEvidence,
  } = useUpsertEvidenceFormView(evidence);
  const { chainItems } = evidence ?? {};
  const chainItemsLength = chainItems?.length ?? 0;

  const onSubmit = async () => {
    if (await submit()) {
      // On complete takes precedence over default close action.
      handleSubmit();
    }
  };

  return (
    <ColumnForm
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <WCTTextField
        name="name"
        label="Evidence Name"
        value={name.value}
        error={name.error}
        onChange={name.set}
        required
      />
      <Gap size={15} />
      <WCTTextField
        name="description"
        label="Evidence Description"
        value={description.value}
        error={description.error}
        onChange={description.set}
        multiline
      />
      {showExtra ? (
        <>
          {chainItemsLength > 0 ? (
            <>
              <Gap size={32} />
              <Typography>{`Related cause boxes (${chainItemsLength})`}</Typography>
              <Gap size={20} />
              {evidence!.chainItems!.map((chainItem) => (
                <>
                  <RelatedCauseBoxCard
                    chainItem={chainItem}
                    caseId={caseId!}
                    key={chainItem.chainItemId}
                  />
                  <Gap size={8} />
                </>
              ))}
            </>
          ) : null}
          <Gap size={24} />
        </>
      ) : (
        <Divider compact addMargin negativeMargin={-24} />
      )}
      <CaseFileManagerInput
        value={files.value}
        onChange={files.set}
        error={files.error}
        readonly={showExtra}
        showLabel
        allowRemove
        allowAdd
        label="Attached Files"
      />
      {showExtra ? (
        <Gap size={24} />
      ) : (
        <Divider compact addMargin negativeMargin={-24} />
      )}
      <Typography variant="body1">Evidence Rating</Typography>
      <Gap size={15} />
      <InlineRadioSelectGroup
        name="sensitivity"
        label="Sensitivity"
        options={sensitivityOptions
          .filter((x) => x.id !== EvidenceSensitivity.unknown)
          .reverse()}
        value={sensitivity.value}
        onChange={sensitivity.set}
        error={sensitivity.error}
      />
      <Gap size={15} />
      <InlineRadioSelectGroup
        name="strength"
        label="Evidence Strength"
        options={strengthOptions}
        value={strength.value}
        onChange={strength.set}
        error={strength.error}
        helperIndicators={{
          left: 'Weak',
          right: 'Strong',
        }}
      />
      <Spacer />
      <Divider compact addMargin negativeMargin={-24} />
      <ActionsRow>
        {showExtra && evidence != null ? (
          <OutlinedButton
            onClick={async () => {
              const didDelete = await deleteEvidence(evidence?.evidenceId!);
              if (didDelete) {
                handleClose();
              }
            }}
          >
            Delete
          </OutlinedButton>
        ) : null}
        <Spacer />
        <OutlinedButton onClick={() => handleClose()}>Cancel</OutlinedButton>
        <PrimaryButton
          disabled={!canSubmit}
          onClick={onSubmit}
          isBusy={isSubmitting}
        >
          {isEdit ? 'Update evidence' : 'Save'}
        </PrimaryButton>
      </ActionsRow>
    </ColumnForm>
  );
}

import {
  useCreateChainItemNoteMutation,
  useDeleteChainItemNoteMutation,
  useGetChainItemNotesQuery,
  useUpdateChainItemNoteMutation,
} from '@api/endpoints/chain/chain-item-notes.api';
import { useAppSelector } from '@store/store';
import { selectChainId, selectFocusedNode } from '@store/rca-editor/selectors';
import useField from '@hooks/use-field-hook';
import { required } from '@util/validators';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { ApiError } from '@api/types/api-error';
import { CreateChainItemNoteRequest } from '@api/types/chain/chain-item-notes/create-chain-item-note.request';
import { usePageAlertVariants } from '@components/alerts';
import { ChainItemNoteResource } from '@api/types/chain/chain-item-notes/chain-item-note.resource';
import useUser from '@store/user/user-hook';
import { useEffect, useState } from 'react';
import { ChainItemNoteUpdateRequest } from '@api/types/chain/chain-item-notes/chain-item-note-update.request';

export default function useNotesPanel() {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const { companyUserId } = useUser();
  const chainId = useAppSelector(selectChainId);
  const focusedNode = useAppSelector(selectFocusedNode);
  const { chainItemId } = focusedNode.data;

  const { data: notes, isLoading: loadingNotes } = useGetChainItemNotesQuery(
    { chainId: chainId ?? -1, chainItemId: chainItemId ?? -1 },
    { skip: chainId == null || chainItemId == null }
  );

  const [createNote, { isLoading: isCreating }] =
    useCreateChainItemNoteMutation();
  const [updateNote, { isLoading: isUpdating }] =
    useUpdateChainItemNoteMutation();
  const [deleteNote, { isLoading: isDeleting }] =
    useDeleteChainItemNoteMutation();

  const [editingNote, setEditingNote] = useState<ChainItemNoteResource>();
  const isEditing = editingNote != null;

  const note = useField<string>([required()]);

  const { isValid, isDirty } = useFieldsWatcher([note]);

  const isSubmitting = isCreating || isUpdating || isDeleting;
  const isLoading = loadingNotes;
  const canSubmit = !isLoading && !isSubmitting && isValid && isDirty;

  const resetNote = note.reset;
  useEffect(() => {
    resetNote(editingNote?.note ?? '');
  }, [editingNote?.note, resetNote]);

  const onSubmit = () => {
    if (isEditing) {
      return updateNote({
        chainId: chainId!,
        chainItemId: chainItemId!,
        chainItemNoteId: editingNote!.chainItemNoteId,
        note: note.value,
      })
        .unwrap()
        .then(() => {
          showSuccessMessage(
            `You have successfully updated a note to cause box #${chainItemId!}`
          );
          note.reset();
          setEditingNote(undefined);
          return true;
        })
        .catch(({ message, errors }: ApiError<ChainItemNoteUpdateRequest>) => {
          showErrorMessage(
            errors?.chainId ??
              errors?.chainItemId ??
              errors?.chainItemNoteId ??
              errors?.note ??
              message
          );
          note.setError(errors?.note);

          return false;
        });
    } else {
      return createNote({
        chainId: chainId!,
        chainItemId: chainItemId!,
        note: note.value,
      })
        .unwrap()
        .then(() => {
          showSuccessMessage(
            `You have successfully added a note to cause box #${chainItemId!}`
          );
          note.reset();
          return true;
        })
        .catch(({ message, errors }: ApiError<CreateChainItemNoteRequest>) => {
          showErrorMessage(
            errors?.chainId ?? errors?.chainItemId ?? errors?.note ?? message
          );
          note.setError(errors?.note);

          return false;
        });
    }
  };

  const onDelete = (note: ChainItemNoteResource) => {
    return deleteNote({
      chainId: chainId!,
      chainItemId: chainItemId!,
      chainItemNoteId: note.chainItemNoteId,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage(
          `You have successfully deleted a note from cause box #${chainItemId!}`
        );
        return true;
      })
      .catch(({ message, errors }: ApiError<ChainItemNoteUpdateRequest>) => {
        showErrorMessage(
          errors?.chainId ??
            errors?.chainItemId ??
            errors?.chainItemNoteId ??
            errors?.note ??
            message
        );
        return false;
      });
  };

  const canEditNote = (note: ChainItemNoteResource) => {
    return companyUserId === note.companyUserId;
  };

  const beginEditingNote = (note: ChainItemNoteResource) => {
    setEditingNote(note);
  };

  const cancelEditingNote = () => {
    setEditingNote(undefined);
  };

  return {
    isLoading,
    isSubmitting,
    canSubmit,
    notes,
    note,
    onSubmit,
    onDelete,
    canEditNote,
    beginEditingNote,
    cancelEditingNote,
    isEditing,
  };
}

export type NotePanelState = ReturnType<typeof useNotesPanel>;

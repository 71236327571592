import { CompleteRcaModalState } from '@pages/app/rca/tabs/components/complete-rca/complete-rca-modal-hook';
import Column from '@components/layout-util-components/column';
import { Box, styled, Typography } from '@mui/material';
import CircleContainer from '@components/containers/circle-container';
import OverviewIcon from '@assets/images/kudos-icon.png';
import Row from '@components/layout-util-components/row';
import KudosPersonItem from '@pages/app/rca/tabs/components/complete-rca/kudos-person-item';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  state: CompleteRcaModalState;
  onClose: () => void;
}

const Container = styled(Column)({
  '.kudos-overview-container': {
    backgroundColor: '#B6B6B6',
    padding: '5px 15px 5px 10px',
    borderRadius: 5,
    display: 'flex',
    gap: 12,
  },

  // A grid of 2 columns that wrap every 2 items
  '.people-container': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 10,
    overflowY: 'auto',
  },
});

export default function CollaboratorKudos({ state, onClose }: Props) {
  const {
    canSubmit,
    isSubmitting,
    complete,
    givePointsTo,
    canGiveMorePoints,
    totalAdditionalPointsAward,
    additionalPointsAvailable,
    getPointsFor,
  } = state;
  const onComplete = () => {
    complete();
  };

  return (
    <Container style={{ padding: '35px 30px' }}>
      <Box textAlign="center">
        <Typography variant="h5">Team performance</Typography>
      </Box>
      <Gap size={10} />
      <Box textAlign="center">
        <Typography>
          Each team member is automatically awarded 20 pts. You have another{' '}
          {additionalPointsAvailable}
          points available to divide between your team
        </Typography>
      </Box>
      <Gap size={12} />
      <Row className="kudos-overview-container">
        <CircleContainer size={42}>
          <img src={OverviewIcon} alt="kudos icon" />
        </CircleContainer>
        <Typography>
          {`${totalAdditionalPointsAward} / 40 Additional kudos points awards`}
        </Typography>
      </Row>
      <Gap size={15} />
      <div className="people-container">
        <KudosPersonItem
          name="Richard Mounty"
          kudosCount={getPointsFor(1)}
          onAllocateMore={canGiveMorePoints ? () => givePointsTo(1) : undefined}
        />
        <KudosPersonItem
          name="Richard Mounty"
          kudosCount={getPointsFor(1)}
          onAllocateMore={canGiveMorePoints ? () => givePointsTo(1) : undefined}
        />
        <KudosPersonItem
          name="Richard Mounty"
          kudosCount={getPointsFor(1)}
          onAllocateMore={canGiveMorePoints ? () => givePointsTo(1) : undefined}
        />
        <KudosPersonItem
          name="Richard Mounty"
          kudosCount={getPointsFor(1)}
          onAllocateMore={canGiveMorePoints ? () => givePointsTo(1) : undefined}
        />
        <KudosPersonItem
          name="Richard Mounty"
          kudosCount={getPointsFor(1)}
          onAllocateMore={canGiveMorePoints ? () => givePointsTo(1) : undefined}
        />
        <KudosPersonItem
          name="Richard Mounty"
          kudosCount={getPointsFor(1)}
          onAllocateMore={canGiveMorePoints ? () => givePointsTo(1) : undefined}
        />
        <KudosPersonItem
          name="Richard Mounty"
          kudosCount={getPointsFor(1)}
          onAllocateMore={canGiveMorePoints ? () => givePointsTo(5) : undefined}
        />
      </div>
      <ActionsRow center anchorBottom addPadding>
        <OutlinedButton onClick={() => onClose()}>Cancel</OutlinedButton>
        <PrimaryButton
          disabled={!canSubmit}
          isBusy={isSubmitting}
          onClick={onComplete}
        >
          Complete RCA
        </PrimaryButton>
      </ActionsRow>
    </Container>
  );
}

import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import Row from '@components/layout-util-components/row';
import { ElipsisButton, OutlinedButton } from '@components/buttons';
import { CaseUserResource } from '@api/types/case/case-collaboration/case-user.resource';
import FilledBadge from '@components/badges/filled-badge';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  user: CaseUserResource;
  onInvite: () => void;
}

export default function CollaborateRecomendUserRow({ user, onInvite }: Props) {
  const {
    kudosLevel,
    kudosPoints,
    name,
    companyRoleName,
    jobTitle,
    rcaCount,
    skills,
  } = user;

  return (
    <tr>
      <td style={{ minWidth: 200 }}>
        <InitialsContainerWithData
          label={name}
          fullName={name}
          subLabel={jobTitle}
        />
      </td>
      <td>{companyRoleName}</td>
      <td>
        {skills && skills.length > 0 && (
          <FilledBadge>{skills[0].label}</FilledBadge>
        )}
        {skills && skills.length > 1 && (
          <FilledBadge>+{skills.length - 1}</FilledBadge>
        )}
      </td>
      <td>{rcaCount}</td>
      <td>{`Level ${
        kudosLevel != null ? kudosLevel : ''
      } (${kudosPoints}xp)`}</td>
      <td style={{ width: 100 }}>
        <Row contentAtEnd>
          <OutlinedButton onClick={onInvite}>Invite</OutlinedButton>
          <Gap size={20} />
          <ElipsisButton actions={[]} />
        </Row>
      </td>
    </tr>
  );
}

import requireAuth from '@components/hoc/with-required-auth-hoc';
import { Grid, Skeleton, Typography } from '@mui/material';
import { useAppTr } from '@i18n/use-app-tr';
import { ColumnForm } from '@components/layout-util-components/column';
import WCTSelectField from '@components/input/select-field';
import { Divider } from '@components/layout-util-components/divider';
import RadioSelectGroup from '@components/input/radio-select-group';
import ActionsRow from '@components/layout-util-components/actions-row';
import { PrimaryButton } from '@components/buttons';
import useProfilePreferences from '@pages/app/profile/profile-preferences-hook';
import { InputSkeleton } from '@components/skeletons';
import RefreshCancelButton from '@components/buttons/refresh-cancel-button';
import { Gap } from '@components/layout-util-components/gap';

function ProfilePreferencesPage() {
  const { t } = useAppTr('preferences');

  const {
    language,
    languageOptions,
    timezoneOptions,
    timezone,
    dateFormatOptions,
    dateFormat,
    themeOptions,
    theme,
    submitPreferences,
    isSubmitting,
    timeDisplayOptions,
    canSubmit,
    isLoading,
    twentyFourHourClock,
    dateExample,
  } = useProfilePreferences();

  return (
    <ColumnForm
      onSubmit={(e) => {
        e.preventDefault();
        submitPreferences();
      }}
    >
      <Typography variant="h6">{t('title')}</Typography>
      <Gap size={32} />
      <Grid container>
        <Grid item xs={6} flexDirection="column">
          <Typography variant="body1">{t('language.title')}</Typography>
          <Typography variant="body2">{t('language.message')}</Typography>
        </Grid>
        <Grid item xs={6} flexDirection="column">
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTSelectField
              id="language"
              options={languageOptions!}
              value={language.value}
              error={language.error}
              onChange={(v) => language.set(v as number)}
            />
          )}
        </Grid>
      </Grid>
      <Divider addMargin />
      <Grid container>
        <Grid item xs={6} flexDirection="column">
          <Typography variant="body1">{t('dateDisplay.title')}</Typography>
          <Typography variant="body2">{t('dateDisplay.message')}</Typography>
        </Grid>
        <Grid item xs={6} flexDirection="column">
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTSelectField
              id="dateDisplay"
              options={dateFormatOptions!}
              value={dateFormat.value}
              error={dateFormat.error}
              onChange={(v) => dateFormat.set(v as number)}
              helperText={t('dateDisplay.helper', {
                formattedDate: dateExample,
              })}
            />
          )}
        </Grid>
      </Grid>
      <Gap size={32} />
      <Grid container>
        <Grid item xs={6} flexDirection="column">
          <Typography variant="body1">{t('timezone.title')}</Typography>
          <Typography variant="body2">{t('timezone.message')}</Typography>
        </Grid>
        <Grid item xs={6} flexDirection="column">
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTSelectField
              id="timezone"
              options={timezoneOptions!}
              value={timezone.value}
              error={timezone.error}
              onChange={(v) => timezone.set(v as number)}
            />
          )}
        </Grid>
      </Grid>
      <Gap size={32} />
      <Grid container>
        <Grid item xs={6} flexDirection="column">
          <Typography variant="body1">{t('timeFormat.title')}</Typography>
          <Typography variant="body2">{t('timeFormat.message')}</Typography>
        </Grid>
        <Grid item xs={6} flexDirection="column">
          {isLoading ? (
            <Skeleton />
          ) : (
            <RadioSelectGroup
              options={timeDisplayOptions}
              value={twentyFourHourClock.value}
              onChange={twentyFourHourClock.set}
              error={twentyFourHourClock.error}
            />
          )}
        </Grid>
      </Grid>
      <Divider addMargin />
      <Grid container>
        <Grid item xs={6} flexDirection="column">
          <Typography variant="body1">{t('theme.title')}</Typography>
          <Typography variant="body2">{t('theme.message')}</Typography>
        </Grid>
        <Grid item xs={6} flexDirection="column">
          {isLoading ? (
            <Skeleton />
          ) : (
            <RadioSelectGroup
              options={themeOptions!}
              value={theme.value}
              onChange={theme.set}
              error={theme.error}
            />
          )}
        </Grid>
      </Grid>
      <Gap size={32} />
      <ActionsRow noAlert>
        <RefreshCancelButton />
        <PrimaryButton
          type="submit"
          disabled={!canSubmit}
          isBusy={isSubmitting}
        >
          {t('updateButtonLabel')}
        </PrimaryButton>
      </ActionsRow>
    </ColumnForm>
  );
}

export default requireAuth(ProfilePreferencesPage);

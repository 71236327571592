import Column, { ColumnForm } from '@components/layout-util-components/column';
import { Chip, styled } from '@mui/material';
import EditPanelHeader from '@pages/app/rca/tabs/components/editor-panels/edit-panel-header';
import WCTTextField from '@components/input/text-field';
import useOverviewPanel from '@pages/app/rca/tabs/components/editor-panels/overview-panel-hook';
import WCTSelectField from '@components/input/select-field';
import { InputSkeleton } from '@components/skeletons';
import { Divider } from '@components/layout-util-components/divider';
import { ReactComponent as FilesIcon } from '@assets/svgs/file.svg';
import { ReactComponent as TaskIcon } from '@assets/svgs/tasks.svg';
import { ReactComponent as NoteIcon } from '@assets/svgs/users.svg';
import { ReactComponent as SolutionIcon } from '@assets/svgs/circle-tick.svg';
import BinarySelectField from '@components/input/binary-select-field';
import WarningAlert from '@components/alerts/warning-alert';
import ActionsRow from '@components/layout-util-components/actions-row';
import { PrimaryButton } from '@components/buttons';
import { useAppDispatch } from '@store/store';
import {
  NodePanelEditorTab,
  selectNodePanelEditorForCreateTab,
} from '@store/rca-editor/rca-editor-slice';
import useDirtyGuard from '@hooks/use-dirty-guard';
import { Gap } from '@components/layout-util-components/gap';

const Container = styled(Column)(({ theme: { palette } }) => ({
  '.quick-actions-title': {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '22px',
    letterSpacing: '0.10000000149011612px',
    textAlign: 'left',
    color: '#00000080',
  },

  '.quick-action': {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    padding: '12px 16px',
    border: 'none',
    borderRadius: 8,
    background: '#F9F9F9',
    marginTop: 8,
    cursor: 'pointer',

    svg: {
      color: palette.common.black,
      stroke: palette.common.black,
      height: 16,
      width: 'auto',
    },

    span: {
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '18px',
      letterSpacing: '0px',
      textAlign: 'left',
      color: '#383838',
    },
  },
}));

export default function OverviewPanel() {
  const { confirmDirty } = useDirtyGuard();
  const dispatch = useAppDispatch();
  const {
    description,
    transitory,
    nodeTypeOptions,
    theme,
    themeOptions,
    isLoading,
    canEdit,
    canSubmit,
    onSubmit,
    isBusy,
    disproved,
    removeDisproved,
  } = useOverviewPanel();

  return (
    <Container>
      <ColumnForm
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <EditPanelHeader
          title="Overview"
          end={
            disproved ? (
              <Chip
                size="small"
                label="Marked as Disproved"
                color="error"
                onDelete={removeDisproved}
              />
            ) : null
          }
        />
        <Gap size={20} />
        {!isLoading && !canEdit ? (
          <>
            <WarningAlert message="Wait for graph to save before editing this cause box" />
            <Gap size={10} />
          </>
        ) : null}
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <WCTTextField
            name="description"
            label="Description"
            value={description.value}
            error={description.error}
            onChange={description.set}
            onBlur={description.validate}
            multiline
            readonly={!canEdit}
            required
          />
        )}
        <Gap size={24} />
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <WCTSelectField
            id="theme"
            name="theme"
            label="Theme"
            placeholder="Select Theme(s)"
            options={themeOptions!}
            value={theme.value}
            error={theme.error}
            onChange={theme.set}
            onBlur={theme.validate}
            multiple
            readonly={!canEdit}
          />
        )}
        <Gap size={24} />
        {isLoading ? (
          <InputSkeleton />
        ) : (
          <BinarySelectField
            name="type"
            label="Type"
            options={nodeTypeOptions!}
            value={transitory.value}
            error={transitory.error}
            onChange={transitory.set}
            compact
          />
        )}
        <Gap size={20} />
        {canEdit ? (
          <ActionsRow>
            <PrimaryButton type="submit" isBusy={isBusy} disabled={!canSubmit}>
              Save
            </PrimaryButton>
          </ActionsRow>
        ) : null}
      </ColumnForm>
      <Divider addMargin compact negativeMargin={-24} />
      <div className="quick-actions-title">QUICK ACTIONS</div>
      <button
        type="button"
        className="quick-action"
        onClick={confirmDirty(() =>
          dispatch(
            selectNodePanelEditorForCreateTab(NodePanelEditorTab.evidence)
          )
        )}
      >
        <FilesIcon />
        <Gap size={12} />
        <span>Add Evidence</span>
      </button>
      <button
        type="button"
        className="quick-action"
        onClick={confirmDirty(() =>
          dispatch(selectNodePanelEditorForCreateTab(NodePanelEditorTab.tasks))
        )}
      >
        <TaskIcon />
        <Gap size={12} />
        <span>Add Task</span>
      </button>
      <button
        type="button"
        className="quick-action"
        onClick={confirmDirty(() =>
          dispatch(
            selectNodePanelEditorForCreateTab(NodePanelEditorTab.impacts)
          )
        )}
      >
        <TaskIcon />
        <Gap size={12} />
        <span>Add Impact</span>
      </button>
      <button
        type="button"
        className="quick-action"
        onClick={confirmDirty(() =>
          dispatch(selectNodePanelEditorForCreateTab(NodePanelEditorTab.notes))
        )}
      >
        <NoteIcon />
        <Gap size={12} />
        <span>Add Note</span>
      </button>
      <button
        type="button"
        className="quick-action"
        onClick={confirmDirty(() =>
          dispatch(
            selectNodePanelEditorForCreateTab(NodePanelEditorTab.solutions)
          )
        )}
      >
        <SolutionIcon />
        <Gap size={12} />
        <span>Add Solution</span>
      </button>
    </Container>
  );
}

import { CaseResource } from '@api/types/case/case.resource';
import SectionScaffold from '@components/outcome-report/components/section-scaffold';
import { styled } from '@mui/material';
import Column from '@components/layout-util-components/column';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faBuildings,
  faLocationDot,
} from '@fortawesome/pro-light-svg-icons';
import { getAddressString, isNullOrEmpty } from '@util/string-util';
import Row from '@components/layout-util-components/row';
import {
  dateOnlyShortFormatter,
  timeOnlyShortFormatter,
} from '@i18n/formatters';
import Spacer from '@components/layout-util-components/spacer';
import { GOOGLE_MAP_KEY } from '@util/env';
import { useEffect, useRef, useState } from 'react';
import { BlackButton } from '@components/outcome-report/components/black-button';
import useSystemText from '@hooks/use-system-text';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  caseDetails: CaseResource;
}

// Grid layout split into 2 columns,
// the first column takes up 1/3 of the space and contains 2 rows (overview and dates)
// the second column up 2/3 of the space and has 1 row (map)
const Container = styled('div')(({ theme: { palette } }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1.5fr',
  gridTemplateRows: '1fr 1fr',
  gridTemplateAreas: `
    'overview map'
    'dates map'
  `,
  columnGap: 0,
  rowGap: 0,
  height: '100%',
  width: '100%',
  backgroundColor: palette.common.grey15,

  '.overview': {
    gridArea: 'overview',
    backgroundColor: palette.common.grey15,
    padding: 64,
  },

  '.dates': {
    gridArea: 'dates',
    backgroundColor: palette.common.white,
    padding: 64,
  },

  '.map': {
    position: 'relative',
    gridArea: 'map',
    backgroundColor: 'teal',
    overflow: 'hidden',

    '& > img': {
      width: '100%',
      height: '100%',

      objectFit: 'cover',
      objectPosition: 'center',
    },

    '.open-map-link': {
      position: 'absolute',
      right: 50,
      bottom: 50,
    },
  },

  '.title': {
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: '400',
    lineHeight: '30px',
    letterSpacing: '0.15000000596046448px',
    textAlign: 'left',
  },

  '.data': {
    display: 'inline-flex',
    flexDirection: 'column',
    gap: 14,

    '.label': {
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '19px',
      letterSpacing: '0px',
      textAlign: 'left',
      color: '#00000066',
    },

    '.value': {
      display: 'inline-flex',
      alignItems: 'center',
      gap: 10,
      fontFamily: 'Roboto',
      fontSize: '20px',
      fontWeight: '400',
      lineHeight: '27px',
      letterSpacing: '0px',
      textAlign: 'left',
      color: palette.common.black,
    },

    '&.large': {
      gap: 17,

      '.label': {
        fontSize: '16px',
        lineHeight: '21px',
        textTransform: 'uppercase',
        color: palette.common.grey60,
      },

      '.value': {
        fontSize: '28px',
        lineHeight: '37px',
      },
    },
  },
}));

export default function OverviewSection({ caseDetails }: Props) {
  const mapRef = useRef<HTMLDivElement>(null);
  const [mapImageUrl, setMapImageUrl] = useState<string>();
  const [mapUrl, setMapUrl] = useState<string>();
  const { systemText } = useSystemText();

  const {
    focalPoint,
    incidentLocation,
    reportingLocation,
    startDate,
    endDate,
    frequencyTypeName,
    frequencyValue,
  } = caseDetails;

  useEffect(() => {
    const location = incidentLocation ?? reportingLocation;
    const { latitude, longitude } = location;

    const width = mapRef.current!.clientWidth;
    const height = mapRef.current!.clientHeight;

    const marker = `&markers=color:red%7Clabel:%7C${latitude},${longitude}`;
    const googleMapsImageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=11&size=${width}x${height}&maptype=roadmapp&markers=${marker}&key=${GOOGLE_MAP_KEY}`;
    setMapImageUrl(googleMapsImageUrl);

    const mapUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    setMapUrl(mapUrl);
  }, [incidentLocation, reportingLocation]);

  return (
    <SectionScaffold id="overview">
      <Container>
        <div className="overview">
          <Column>
            <div className="title">Overview</div>
            <Gap size={50} />
            <div className="data large">
              <div className="label">
                {systemText['RCA.Overview.FocalPoint']}
              </div>
              <div className="value">{focalPoint}</div>
            </div>
            {incidentLocation != null ? (
              <>
                <Gap size={32} />
                <div className="data">
                  <div className="label">Incident Location</div>
                  <div className="value">
                    <FontAwesomeIcon icon={faLocationDot} />
                    <span>{getAddressString(incidentLocation!)}</span>
                  </div>
                </div>
              </>
            ) : null}
            {reportingLocation != null ? (
              <>
                <Gap size={32} />
                <div className="data">
                  <div className="label">Reporting Location</div>
                  <div className="value">
                    <FontAwesomeIcon icon={faBuildings} />
                    <span>{getAddressString(reportingLocation!)}</span>
                  </div>
                </div>
              </>
            ) : null}
          </Column>
        </div>
        <div className="dates">
          <Column>
            <Row>
              <div className="data">
                <div className="label">START DATE</div>
                <div className="value">{dateOnlyShortFormatter(startDate)}</div>
              </div>
              {!isNullOrEmpty(endDate) ? (
                <>
                  <Spacer />
                  <div className="data">
                    <div className="label">END DATE</div>
                    <div className="value">
                      {dateOnlyShortFormatter(endDate)}
                    </div>
                  </div>
                </>
              ) : null}
            </Row>
            <Gap size={50} />
            <Row>
              <div className="data">
                <div className="label">START TIME</div>
                <div className="value">{timeOnlyShortFormatter(startDate)}</div>
              </div>
              {!isNullOrEmpty(endDate) ? (
                <>
                  <Spacer />
                  <div className="data">
                    <div className="label">END TIME</div>
                    <div className="value">
                      {timeOnlyShortFormatter(endDate)}
                    </div>
                  </div>
                </>
              ) : null}
            </Row>
            {frequencyValue != null && !isNullOrEmpty(frequencyTypeName) ? (
              <>
                <Gap size={50} />
                <div className="data">
                  <div className="label">Frequency</div>
                  <div className="value">{`${frequencyValue} times ${frequencyTypeName}`}</div>
                </div>
              </>
            ) : null}
          </Column>
        </div>
        <div ref={mapRef} className="map">
          {mapImageUrl ? (
            <>
              <img src={mapImageUrl} alt="map" />
              <BlackButton
                className="open-map-link"
                target="_blank"
                href={mapUrl}
                rel="noreferrer"
              >
                <span>View Map </span>
                <FontAwesomeIcon icon={faArrowRight} />
              </BlackButton>
            </>
          ) : null}
        </div>
      </Container>
    </SectionScaffold>
  );
}

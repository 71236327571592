import useSolutionsListView from '@pages/app/rca/tabs/components/editor-panels/components/solutions-panel/solutions-list-view-hook';
import { SolutionsPanelState } from '@pages/app/rca/tabs/components/editor-panels/solutions-panel-hook';
import Column from '@components/layout-util-components/column';
import ChainItemSolutionItem from '@components/cards/chain-item-solution-item';
import { ElipsisButton } from '@components/buttons';
import { ChainItemSolutionResource } from '@api/types/chain/chain-item-solution/chain-item-solution.resource';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import DeleteConfirmationModal from '@components/ui-popup/modals/delete-confirmation-modal';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  state: SolutionsPanelState;
}
export default function SolutionsListView({ state }: Props) {
  const { showPopup } = useUiPopup();
  const { editSolution, onDelete } = state;
  const { solutions, currency } = useSolutionsListView(state);

  const deleteSolution = async (solution: ChainItemSolutionResource) => {
    return showPopup(DeleteConfirmationModal, {
      itemName: 'Solution',
      title: 'Are you sure you want to delete this solution?',
      message:
        'Are you sure you want to delete this solution?\n\nPlease note this will remove this solution from all associated cause boxes.',
      deleteButtonMessage: 'Delete Solution',
      onDelete: () => onDelete(solution),
    });
  };

  return (
    <Column>
      {solutions.map((solution) => (
        <>
          <ChainItemSolutionItem
            key={solution.solutionId}
            solution={solution}
            currency={currency}
            trailing={
              <ElipsisButton
                actions={[
                  {
                    label: 'Edit Solution',
                    onClick: () => editSolution(solution),
                  },
                  {
                    label: 'Remove Solution',
                    onClick: () => deleteSolution(solution),
                  },
                ]}
              />
            }
          />
          <Gap size={8} />
        </>
      ))}
    </Column>
  );
}

import { useEffect, useState } from 'react';
import { useLazyGetCaseOutcomeResultQuery } from '@api/endpoints/case-outcome.api';

export enum OutcomeReportViewState {
  checkingPermission,
  enterPassword,
  loading,
  locked,
  view,
}

export default function useOutcomeReport(caseId: number, outcomeId: number) {
  const [getOutcomeResult] = useLazyGetCaseOutcomeResultQuery();
  const [viewState, setViewState] = useState(
    OutcomeReportViewState.checkingPermission
  );

  useEffect(() => {
    async function checkPermissionAndProgress() {
      getOutcomeResult({ caseId, outcomeId })
        .unwrap()
        .then(() => {
          setViewState(OutcomeReportViewState.view);
        })
        .catch(() => {
          setViewState(OutcomeReportViewState.locked);
        });
    }

    checkPermissionAndProgress();
  }, []);

  const loadReport = () => {
    setViewState(OutcomeReportViewState.view);
  };

  return {
    caseId,
    outcomeId,
    viewState,
    loadReport,
  };
}

export type OutcomeReportState = ReturnType<typeof useOutcomeReport>;

import { OutcomeReportState } from '@pages/app/outcome/outcome-report-hook';
import useEnterPasswordView from '@pages/app/outcome/views/enter-password-hook';
import { StatusPanel } from '@pages/app/outcome/components/status-panel';
import { ColumnForm } from '@components/layout-util-components/column';
import { ReactComponent as Logo } from '@assets/svgs/logo.svg';
import { Box, Typography } from '@mui/material';
import WCTTextField from '@components/input/text-field';
import { PrimaryButton } from '@components/buttons';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  state: OutcomeReportState;
}

export default function EnterPasswordView({ state }: Props) {
  const { submit, canSubmit, password, isBusy } = useEnterPasswordView(state);

  return (
    <StatusPanel>
      <ColumnForm
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          submit();
        }}
      >
        <Box alignSelf="center">
          <Logo width={51} height="auto" fill="black" />
        </Box>
        <Gap size={50} />
        <Typography variant="h5">Password Protected</Typography>
        <Gap size={12} />
        <Typography>Please enter the password below</Typography>
        <Gap size={50} />
        <Box minWidth={400}>
          <WCTTextField
            name="password"
            label="Password"
            error={password.error}
            value={password.value}
            onChange={password.set}
            obscure
            required
          />
        </Box>
        <Gap size={50} />
        <Box alignSelf="center">
          <PrimaryButton type="submit" disabled={!canSubmit} isBusy={isBusy}>
            View outcome report
          </PrimaryButton>
        </Box>
      </ColumnForm>
    </StatusPanel>
  );
}

import { styled } from '@mui/material';
import { PropsWithChildren } from 'react';

interface Props {
  color?: string;
  small?: boolean;
}

const StyledBadge = styled('div')<Props>(
  ({ color, small, theme: { palette } }) => ({
    display: 'inline-flex',
    marginRight: 5,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: color ?? palette.badge.green,
    borderRadius: 16,
    padding: '3px 10px',
    textTransform: 'capitalize',
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '18px',
    letterSpacing: '0.16px',

    ...(small && {
      fontSize: '12px',
      lineHeight: '15px',
      letterSpacing: '0.15000000596046448px',
      textAlign: 'left',
      padding: '1px 5px',
    }),
  })
);

export default function ColorBadge({
  children,
  ...props
}: PropsWithChildren<Props>) {
  return <StyledBadge {...props}>{children}</StyledBadge>;
}

import { Option } from '@api/types/option';
import { Box, FormHelperText, styled, Typography } from '@mui/material';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useCommonTr } from '@i18n/use-common-tr';
import { ErrorType } from '@api/types/api-error';
import WCTTextField from '@components/input/text-field';
import WCTSelectField from '@components/input/select-field';
import { getAddressString, isNullOrEmpty } from '@util/string-util';
import Row from '@components/layout-util-components/row';
import { LinkButton, OutlinedButton } from '@components/buttons';
import { useLazySearchPostcodeQuery } from '@api/endpoints/postcodeLookup.api';
import { LoadingIndicator } from '@components/loading-indicator';
import { v4 as uuid } from 'uuid';
import { Gap } from '@components/layout-util-components/gap';

export interface Address {
  addressLineOne?: string;
  addressLineTwo?: string;
  town?: string;
  county?: string;
  countryId?: number;
  country?: string;
  postcode?: string;
  latitude?: number;
  longitude?: number;
}

interface Props {
  id: string;
  value?: Address;
  errors?: ErrorType<Address>;
  onChange: SetStateAction<Dispatch<Address>>;
  onBlur?: () => void;
  countryOptions: Array<Option>;
  readonly?: boolean;
  compact?: boolean;
  required?: boolean;
  forceReadOnly?: boolean;
  onEditAddressClick?: () => void;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',

  '& .postcode': {
    maxWidth: 224,
  },
});

const EmptySearchContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'rgba(0, 0, 0, 0.05)',
  border: '1px solid #B1B1B1',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.12)',
  padding: '10px 0px',
});

const SearchResultContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '5px 0',
  border: '1px solid #B1B1B1',
  filter: 'drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.12))',

  '& ul': {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    overflowY: 'auto',
    maxHeight: 220,
    width: '100%',

    '& li': {
      cursor: 'pointer',
      padding: '3px 15px',

      '&:hover': {
        backgroundColor: '#5E5E5E',
        color: 'white',
      },
    },
  },
});

export default function WCTAddressField({
  id,
  value,
  errors,
  onChange,
  onBlur,
  countryOptions,
  readonly,
  compact,
  required,
  forceReadOnly,
  onEditAddressClick,
}: Props) {
  const spacing = compact ? 24 : 50;
  const { t } = useCommonTr('input');

  const [search, { isFetching: isSearching }] = useLazySearchPostcodeQuery();

  const [isManualEntry, setManualEntry] = useState(false);
  const [isSearchResults, setIsSearchResults] = useState(false);
  const [searchResults, setSearchResults] = useState<Array<Address>>([]);
  const [isReadonly, setReadonly] = useState(() => {
    if (readonly) {
      return true;
    }

    if (value == null) {
      return false;
    }

    const { addressLineOne, countryId, postcode, town } = value;
    return (
      countryId != null &&
      countryId > 0 &&
      !isNullOrEmpty(addressLineOne) &&
      !isNullOrEmpty(postcode) &&
      !isNullOrEmpty(town)
    );
  });

  const updateAddress = useCallback(
    (partial: Partial<Address>) => {
      onChange((v) => ({
        ...v,
        ...partial,
      }));
    },
    [onChange]
  );

  const concatErrorMessage = useMemo(() => {
    const validErrors = [
      errors?.addressLineOne,
      errors?.addressLineTwo,
      errors?.town,
      errors?.country || errors?.countryId,
      errors?.county,
      errors?.postcode,
    ].filter((err) => !isNullOrEmpty(err as string));

    if (validErrors.length === 0) {
      return null;
    }

    return validErrors.join('\n');
  }, [errors]);

  const onSearch = () => {
    const postcode = value?.postcode;
    if (isNullOrEmpty(postcode)) {
      return;
    }

    search(postcode!)
      .unwrap()
      .then((results) => {
        setSearchResults(results);
        setIsSearchResults(true);
      })
      .catch(() => {
        console.log('error searching');
      });
  };

  const pickAddress = (address: Address) => {
    onChange(() => ({
      ...address,
      countryId: value?.countryId,
    }));
    setIsSearchResults(false);
    setSearchResults([]);
    setReadonly(true);
  };

  const { country, countryId } = value ?? {};
  useEffect(() => {
    setManualEntry(
      !!countryId &&
        countryOptions.find((x) => x.id === countryId)?.label !==
          'United Kingdom'
    );

    if (isNullOrEmpty(country)) {
      return;
    }
    const selectedOption = countryOptions.find((x) => x.label === country);
    if (selectedOption != null && selectedOption.id !== countryId) {
      updateAddress({ countryId: selectedOption.id });
    }
  }, [country, countryId, countryOptions, updateAddress]);

  const renderSearchResults = () => {
    if (setSearchResults == null || searchResults.length === 0) {
      return (
        <EmptySearchContainer>
          <Typography variant="subtitle1">
            {t('address.picker.notFound.title')}
          </Typography>
          <Typography variant="body2">
            {t('address.picker.notFound.message')}
          </Typography>
        </EmptySearchContainer>
      );
    } else {
      return (
        <SearchResultContainer>
          <Typography px={'15px'}>{t('address.picker.label')}</Typography>
          <Gap size={8} />
          <ul>
            {searchResults.map((result) => (
              <li key={uuid()} onClick={() => pickAddress(result)}>
                {getAddressString(result)}
              </li>
            ))}
          </ul>
        </SearchResultContainer>
      );
    }
  };

  const renderState = () => {
    if (isReadonly || forceReadOnly) {
      return (
        <Row noWrap>
          <Typography>{getAddressString(value)}</Typography>
          <Gap size={12} />
          {!readonly ? (
            <OutlinedButton
              onClick={() => {
                setReadonly(false);
                onEditAddressClick && onEditAddressClick();
              }}
            >
              {t('address.editButtonLabel')}
            </OutlinedButton>
          ) : null}
        </Row>
      );
    } else if (isManualEntry) {
      return (
        <>
          <WCTSelectField
            id={`${id}-country`}
            name={`${id}-country`}
            label={t('address.fields.country')}
            value={value?.countryId}
            error={errors?.countryId as string}
            onChange={(v) => updateAddress({ countryId: v as number })}
            onBlur={onBlur}
            options={countryOptions}
            required={required}
          />
          <Gap size={spacing} />
          <WCTTextField
            name={`${id}-line1`}
            label={t('address.fields.line1')}
            value={value?.addressLineOne ?? ''}
            error={errors?.addressLineOne as string}
            onChange={(v) => updateAddress({ addressLineOne: v })}
            onBlur={onBlur}
            required={required}
          />
          <Gap size={10} />
          <WCTTextField
            name={`${id}-line2`}
            label={t('address.fields.line2')}
            value={value?.addressLineTwo ?? ''}
            error={errors?.addressLineTwo as string}
            onChange={(v) => updateAddress({ addressLineTwo: v })}
            onBlur={onBlur}
          />
          <Gap size={10} />
          <WCTTextField
            name={`${id}-city`}
            label={t('address.fields.city')}
            value={value?.town ?? ''}
            error={errors?.town as string}
            onChange={(v) => updateAddress({ town: v })}
            onBlur={onBlur}
            required={required}
          />
          <Gap size={10} />
          <WCTTextField
            name={`${id}-county`}
            label={t('address.fields.county')}
            value={value?.county ?? ''}
            error={errors?.county as string}
            onChange={(v) => updateAddress({ county: v })}
            onBlur={onBlur}
          />
          <Gap size={10} />
          <WCTTextField
            name={`${id}-postcode`}
            className="postcode"
            label={t('address.fields.postcode')}
            value={value?.postcode ?? ''}
            error={errors?.postcode as string}
            onChange={(v) => updateAddress({ postcode: v })}
            onBlur={onBlur}
            required={required}
          />
        </>
      );
    } else {
      return (
        <>
          <WCTSelectField
            id={`${id}-country`}
            name={`${id}-country`}
            label={t('address.fields.country')}
            value={value?.countryId}
            error={errors?.countryId as string}
            onChange={(v) => updateAddress({ countryId: v as number })}
            onBlur={onBlur}
            options={countryOptions}
            required={required}
          />
          {countryId && (
            <>
              <Gap size={spacing} />
              <Row>
                <WCTTextField
                  name={`${id}-postcode`}
                  className="postcode"
                  label={t('address.fields.postcode')}
                  value={value?.postcode ?? ''}
                  error={errors?.postcode as string}
                  onChange={(v) => updateAddress({ postcode: v })}
                  onBlur={onBlur}
                  readonly={isSearching}
                  required={required}
                />
                <Gap size={12} />
                <OutlinedButton
                  disabled={isNullOrEmpty(value?.postcode) || isSearching}
                  onClick={onSearch}
                >
                  {t('address.searchButtonLabel')}
                  <LoadingIndicator show={isSearching} />
                </OutlinedButton>
              </Row>
              {concatErrorMessage ? (
                <>
                  <Gap size={10} />
                  <FormHelperText error>{concatErrorMessage}</FormHelperText>
                </>
              ) : null}
              {isSearchResults ? (
                <>
                  <Gap size={10} />
                  {renderSearchResults()}
                </>
              ) : null}

              <Gap size={10} />
              <Box alignSelf="flex-start">
                <LinkButton onClick={() => setManualEntry(true)}>
                  {t('address.manualEntryButtonLabel')}
                </LinkButton>
              </Box>
            </>
          )}
        </>
      );
    }
  };

  return <Container>{renderState()}</Container>;
}

import { Chip, Typography } from '@mui/material';
import { NodeEndStateType, RcaNode } from '@store/rca-editor/types';
import { Handle, Position } from 'reactflow';
import NodeActions from '@pages/app/rca/tabs/components/node-action';
import { store } from '@store/store';
import { makeSelectNodeFromChainItemId } from '@store/rca-editor/selectors';
import { isNullOrEmpty } from '@util/string-util';
import {
  ChangeEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import HealthBadge from '@components/badges/health-badge';
import MetaConnectionButton from '@pages/app/rca/tabs/components/meta-connections/meta-connection-button';
import { MetaConnectionPosition } from '@pages/app/rca/tabs/components/meta-connections/types';
import { RcaUtil } from '@util/rca-util';
import CollapsedNodeIndicator from '@pages/app/rca/tabs/components/collapsed-node-indicator';
import usePopoverMenu from '@components/popover-menu/use-popover-menu';
import PopoverMenu from '@components/popover-menu/popover-menu';
import { ReactComponent as ConnectionIcon } from '@assets/svgs/connection.svg';
import NodeLinkIcon from '@pages/app/rca/tabs/components/node-link-icon';
import { LoadingIndicator } from '@components/loading-indicator';
import useDefaultNode from '@pages/app/rca/tabs/components/node-types/default-node-hook';
import Row from '@components/layout-util-components/row';
import ColorBadge from '@components/badges/color-badge';
import { v4 as uuid } from 'uuid';
import { StyledNode } from '@pages/app/rca/tabs/components/node-types/styled-default-node';
import AnalysisCompleteInfo from '@pages/app/rca/tabs/components/end-state-content/analysis-complete-info';
import FurtherAnalysisInfo from '@pages/app/rca/tabs/components/end-state-content/further-analysis-info';
import { Circle } from '@mui/icons-material';

export function RcaDefaultNode(node: RcaNode) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const menu = usePopoverMenu();
  const elipsisMenu = usePopoverMenu();

  const state = useDefaultNode(node);
  const {
    id,
    data: { isRoot, sortOrder, endState, chainItemId },
    isBusy,
    canShowActions,
    label,
    collapsedState,
    childCountIncludingMetaNodes,
    isConnection,
    shouldDisplayConnectionIndicator,
    healthScore,
    shouldDisplayEndStateInfo,
    shouldDisplayCollapsedIndicator,
    shouldDisplayEndStateElipsis,
    canShowLeftMetaAction,
    canShowRightMetaAction,
    canConnectEdgeToThisNode,
    canConnectEdgeFromThisNode,
    isEditing,
    isHighlightMode,
    isDevMode,
    canHandleClick,
    displayProperties,
    maybeCommitCreate,
    cancelCreate,
    setNodeHighlight,
    selectNode,
    canShowBorder,
    visualType,
    removeEndState,
    canDrag,
    shouldFadeOut,
  } = state;

  const [content, setContent] = useState<string>(label || '');

  useEffect(() => {
    if (isEditing) {
      const textArea = textAreaRef.current;
      if (textArea != null) {
        textArea.focus();

        const valLen = textArea.value?.length ?? 0;
        if (valLen > 0) {
          textArea.setSelectionRange(0, valLen);
        }
      }
    }
  }, [isEditing, node]);

  const onToggleHighlight = () => {
    if (childCountIncludingMetaNodes === 0) {
      setNodeHighlight();
    } else {
      menu.open([
        {
          label: 'Highlight Cause Box',
          onClick: () => setNodeHighlight(),
        },
        {
          label: 'Highlight Chain',
          onClick: () => setNodeHighlight(true),
        },
      ]);
    }
  };

  const onKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    const value = textAreaRef.current?.value?.trim();
    if (e.key === 'Enter') {
      if (value != null && value.length > 0) {
        e.preventDefault();
        maybeCommitCreate(value);
      } else {
        cancelCreate();
      }
    } else if (e.key === 'Escape') {
      cancelCreate();
    }
  };

  const onNodeContentChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    const inputString = e.target.value;
    setContent(inputString);
  };

  const onMouseDownCapture: MouseEventHandler = (e) => {
    if (!canDrag) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
  };

  const onClick: MouseEventHandler = (e) => {
    if (!canHandleClick) {
      e.stopPropagation();
      e.preventDefault();
      return;
    }

    const clickCount = e.detail;
    if (e.target !== textAreaRef.current) {
      const className = (e.target as HTMLDivElement)?.className;
      const requiredClickCount = isHighlightMode ? 1 : 2;
      if (
        clickCount === requiredClickCount &&
        className != null &&
        className.includes != null &&
        (className.includes('node-actions-container') ||
          className.includes('node-content'))
      ) {
        if (isConnection) {
          // The 'data' for a connection node is actually the data for the node it's connected to
          // therefore, we can use the chain item in the data to get the actual node.
          const connectedNode = makeSelectNodeFromChainItemId(
            node.data.chainItemId!
          )(store.getState());
          if (connectedNode) {
            RcaUtil.focusNode(connectedNode);
            e.stopPropagation();
            e.preventDefault();
          }
        } else if (isHighlightMode) {
          onToggleHighlight();
        } else {
          selectNode();
        }
      }
    }
  };

  const onEndStateElipsisClick = () => {
    elipsisMenu.open([
      {
        label: 'Remove end state',
        onClick: removeEndState,
      },
    ]);
  };

  const renderDisplayProperties = () => {
    switch (displayProperties.type) {
      case 'none':
        return <></>;
      case 'disproved':
        return (
          <Chip
            size="small"
            variant="outlined"
            label="Disproved"
            color="error"
          />
        );
      case 'health-score':
      case 'default':
        return <HealthBadge health={healthScore} small />;
      case 'coverage':
        return (
          <Row>
            {displayProperties.badges!.map((badge) => (
              <ColorBadge
                color={badge.baseColor}
                key={badge.text ?? uuid()}
                small
              >
                <Row gap={6}>
                  {!!badge.indicatorColor && (
                    <Circle
                      sx={{
                        fontSize: 8,
                        color: badge.indicatorColor,
                      }}
                    />
                  )}
                  <span>{badge.text ?? ''}</span>
                </Row>
              </ColorBadge>
            ))}
          </Row>
        );
    }
  };

  let containerClassName: string;
  containerClassName = useMemo(() => {
    const classNames = ['node-container', collapsedState];
    if (isEditing) {
      classNames.push('editing');
    }

    if (isNullOrEmpty(label)) {
      classNames.push('no-content');
    }

    if (isRoot) {
      classNames.push('root');
    }

    if (shouldFadeOut) {
      classNames.push('faded');
    }

    return classNames.join(' ');
  }, [collapsedState, isEditing, isRoot, label, shouldFadeOut]);

  return (
    <>
      <StyledNode
        ref={menu.ref}
        className={containerClassName}
        onMouseDownCapture={onMouseDownCapture}
        onClickCapture={onClick}
        highlightColor={displayProperties.highlightColor}
        outlineColor={displayProperties.outlineColor}
        nodeType={visualType}
        isSelected={canShowBorder}
        draggable={canDrag}
        isConnection={isConnection}
      >
        <div className="node-background" />
        <div className="node-border" />

        {canConnectEdgeToThisNode ? (
          <Handle position={Position.Left} type="target" />
        ) : null}

        {canShowLeftMetaAction ? (
          <MetaConnectionButton
            nodeId={id}
            position={MetaConnectionPosition.left}
            isParentNode={false}
          />
        ) : null}

        {canShowActions ? <NodeActions {...state} /> : null}

        <div className="node-content">
          {isEditing ? (
            <textarea
              ref={textAreaRef}
              className="content-input"
              autoFocus
              value={content}
              onChange={onNodeContentChange}
              onClick={onClick}
              onBlur={() => maybeCommitCreate(textAreaRef.current?.value)}
              onKeyDown={onKeyDown}
              onDrag={(e) => e.stopPropagation()}
              placeholder="Caused By"
            />
          ) : (
            <Typography className="content-text">
              {isDevMode
                ? `sortOrder: ${sortOrder}, chainItemId: ${chainItemId},\n\n collapsed:${collapsedState}`
                : label || 'Caused By'}
            </Typography>
          )}

          {renderDisplayProperties()}

          <LoadingIndicator show={isBusy} />
        </div>

        {shouldDisplayConnectionIndicator ? (
          <div className="connection-highlight">
            <ConnectionIcon />
          </div>
        ) : null}

        {canConnectEdgeFromThisNode ? (
          <Handle position={Position.Right} type="source" />
        ) : null}

        {shouldDisplayCollapsedIndicator ? (
          <CollapsedNodeIndicator node={node} />
        ) : isConnection ? (
          <NodeLinkIcon />
        ) : shouldDisplayEndStateInfo ? (
          endState === NodeEndStateType.complete ? (
            <AnalysisCompleteInfo
              ref={elipsisMenu.ref}
              onElipsisClick={
                shouldDisplayEndStateElipsis
                  ? onEndStateElipsisClick
                  : undefined
              }
            />
          ) : endState === NodeEndStateType.furtherAnalysis ? (
            <FurtherAnalysisInfo
              ref={elipsisMenu.ref}
              onElipsisClick={
                shouldDisplayEndStateElipsis
                  ? onEndStateElipsisClick
                  : undefined
              }
            />
          ) : null
        ) : canShowRightMetaAction ? (
          <MetaConnectionButton
            nodeId={id}
            position={MetaConnectionPosition.right}
            isParentNode
          />
        ) : null}
      </StyledNode>

      <PopoverMenu {...menu} />
      <PopoverMenu {...elipsisMenu} />
    </>
  );
}

import { PropsWithChildren } from 'react';
import { styled, TableBody } from '@mui/material';

const StyledTableBody = styled(TableBody)({
  borderWidth: '1px 1px 0px 1px',
  borderStyle: 'solid',
  borderColor: '#CACACA',
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08))',
  '&>tr': {
    backgroundColor: 'white',
  },
});

export default function WCTTableBody({ children }: PropsWithChildren<{}>) {
  return <StyledTableBody>{children}</StyledTableBody>;
}

import { Avatar } from '@mui/material';

export const ActivityAvatar = ({ type }: { type: string }) => {
  // TODO: Replace me with meaningful icons
  switch (type) {
    case 'Task':
      return <Avatar sx={{ zIndex: 2 }}>T</Avatar>;
    case 'Evidence':
      return <Avatar sx={{ zIndex: 2 }}>E</Avatar>;
    case 'Kudos':
      return <Avatar sx={{ zIndex: 2 }}>K</Avatar>;
    case 'Solution':
      return <Avatar sx={{ zIndex: 2 }}>S</Avatar>;
    default:
      return <Avatar />;
  }
};

import { useAppDispatch, useAppSelector } from '@store/store';
import {
  selectChainId,
  selectCurrentRcaCaseId,
} from '@store/rca-editor/selectors';
import { useGetHealthScoreActionsQuery } from '@api/endpoints/chain/chain-item-health-score-actions.api';
import useLoadMore from '@hooks/use-load-more-hook';
import { useCallback } from 'react';
import { setDisplayHealthState } from '@store/rca-editor/rca-editor-slice';
import { ChainItemActionType } from '@api/types/chain/chain-item-health-score/chain-item-action.response';
import { useFilter } from '@components/table';
import { useGetChainItemActionTypeOptionsQuery } from '@api/endpoints/chain/chain-item-action-type';
import { useGetCaseTotalsQuery } from '@api/endpoints/case.api';

export default function useHealthScoreSidePanel() {
  const dispatch = useAppDispatch();
  const caseId = useAppSelector(selectCurrentRcaCaseId);
  const chainId = useAppSelector(selectChainId);

  const { data: totals, isLoading: loadingTotals } = useGetCaseTotalsQuery(
    caseId ?? -1,
    {
      skip: caseId == null,
    }
  );

  const { data: actionTypeOptions, isLoading: loadingActionTypeOptions } =
    useGetChainItemActionTypeOptionsQuery(chainId ?? -1, {
      skip: chainId == null,
    });

  const actionType = useFilter<Array<ChainItemActionType>>();
  const { skip, take, loadMore, canLoadMore } = useLoadMore();

  const { data: actions, isLoading: loadingActions } =
    useGetHealthScoreActionsQuery(
      {
        chainId: chainId ?? -1,
        skip,
        take,
        actionTypes: actionType.arrayValue,
      },
      { skip: chainId == null, refetchOnMountOrArgChange: true }
    );

  const totalActions = actions?.totalCount ?? 0;
  const displayRemainingActionsTitle =
    totalActions > 0 && !!actionType.arrayValue?.length;

  const isLoading =
    loadingActions || loadingTotals == null || loadingActionTypeOptions;

  const highlightNodes = useCallback(() => {
    dispatch(setDisplayHealthState(true));
  }, [dispatch]);

  const unhighlightNodes = useCallback(() => {
    dispatch(setDisplayHealthState(false));
  }, [dispatch]);

  return {
    displayRemainingActionsTitle,
    healthScore: totals?.healthScore ?? 0,
    actions,
    isLoading,
    loadMore,
    canLoadMore: canLoadMore(actions?.totalCount ?? 0),
    highlightNodes,
    unhighlightNodes,
    actionTypeOptions,
    actionType,
  };
}

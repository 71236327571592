import { ImpactInfoModel } from '@pages/app/rca/create/steps/impact-step-models';
import { IconButton, styled, Typography } from '@mui/material';
import { ReactComponent as CircleTickIcon } from '@assets/svgs/circle-tick.svg';
import { ReactComponent as BinIcon } from '@assets/svgs/bin.svg';
import { ReactComponent as NotTrackedIcon } from '@assets/svgs/not-tracked.svg';
import FilledBadge from '@components/badges/filled-badge';
import { OutlinedButton } from '@components/buttons';
import Row from '@components/layout-util-components/row';
import { useGetMetricDetailQuery } from '@api/endpoints/case-impact-type-metric.api';
import { InlineBoxSkeleton } from '@components/skeletons';
import { CaseImpactType } from '@api/types/case-impact-type-metric/case-impact-type-metric.resource';
import { CaseImpactUtil } from '@util/case-impact-util';
import { useGetCaseImpactTypesQuery } from '@api/endpoints/case-impact-type.api';
import { useMemo } from 'react';
import { Gap } from '@components/layout-util-components/gap';
import { useAppSelector } from '@store/store';
import { selectCurrentRcaCurrency } from '@store/rca-editor/selectors';

interface Props {
  info: ImpactInfoModel;
  caseId: number;
  onEditClick: () => void;
  onDeleteClick: () => void;
}

const Container = styled('tr')({
  '.tick': {
    width: 30,
    height: 'auto',
  },
});

export default function ImpactInfoRow({
  info: {
    description,
    metricId,
    track,
    impactValue,
    actualValue,
    severityName,
  },
  caseId,
  onEditClick,
  onDeleteClick,
}: Props) {
  const currency = useAppSelector(selectCurrentRcaCurrency);
  const { data: impactTypes, isLoading: loadingImpactTypes } =
    useGetCaseImpactTypesQuery(caseId);

  const selectedImpactType = useMemo(() => {
    return impactTypes?.find((x) => x.caseImpactTypeId === metricId);
  }, [impactTypes, metricId]);

  const { data: metricResource, isLoading: loadingMetricOptions } =
    useGetMetricDetailQuery(selectedImpactType?.caseImpactTypeMetricId ?? -1, {
      skip: selectedImpactType == null,
    });

  const isLoading = loadingImpactTypes || loadingMetricOptions;

  const metric = useMemo(() => {
    return impactTypes?.find((x) => x.caseImpactTypeId === metricId);
  }, [impactTypes, metricId]);

  return (
    <Container>
      <td>
        {track ? (
          <CircleTickIcon className="tick" />
        ) : (
          <NotTrackedIcon className="tick" />
        )}
      </td>
      <td>
        <Typography>
          {isLoading ? <InlineBoxSkeleton /> : metric?.name}
        </Typography>
      </td>
      <td>
        <FilledBadge>
          {actualValue === CaseImpactType.actual ? 'ACTUAL' : 'POTENTIAL'}
        </FilledBadge>
      </td>
      <td>
        <Typography>{description}</Typography>
      </td>
      <td>
        <Typography>{severityName}</Typography>
      </td>
      <td>
        <Typography>
          {isLoading ? (
            <InlineBoxSkeleton />
          ) : (
            CaseImpactUtil.getImpactValue(
              impactValue,
              metricResource!.caseImpactTypeMetric,
              metricResource!.options,
              currency
            )
          )}
        </Typography>
      </td>
      <td>
        <Row contentAtEnd>
          <OutlinedButton onClick={onEditClick}>Edit</OutlinedButton>
          <Gap size={20} />
          <IconButton onClick={onDeleteClick}>
            <BinIcon />
          </IconButton>
        </Row>
      </td>
    </Container>
  );
}

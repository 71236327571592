import {
  IconButton,
  InputAdornment,
  styled,
  SvgIcon,
  TextField,
} from '@mui/material';
import { useState } from 'react';

import { ReactComponent as VisibleIcon } from '@assets/svgs/visible.svg';
import { ReactComponent as HiddenIcon } from '@assets/svgs/hidden.svg';
import { DefaultTFuncReturn } from 'i18next';
import { ErrorData } from '@api/types/api-error';
import { optionalLabel } from '@util/string-util';

export enum TextFieldMaxSize {
  small = 269,
  medium = 350,
  Large = 500,
}

interface Props {
  label?: string | DefaultTFuncReturn;
  name?: string;
  required?: boolean;
  multiline?: boolean;
  obscure?: boolean;
  value?: string;
  placeholder?: string | DefaultTFuncReturn;
  error?: ErrorData;
  onChange: (value: string) => void;
  onBlur?: () => void;
  readonly?: boolean;
  className?: string;
  maxWidth?: TextFieldMaxSize;
  maxLength?: number;
  addRightPadding?: boolean;
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  margin: 0,
  padding: 0,
  '& .MuiInputBase-root': {
    borderRadius: 4,
    boxSizing: 'border-box',
    border: `2px solid ${theme.palette.common.grey50}`,
    minHeight: 56,
    '&::before': {
      display: 'none',
    },
    '&:hover::before': {
      display: 'none',
    },
    '&.Mui-focused': {
      border: `2px solid ${theme.palette.primary.main}`,
      '&::after': {
        display: 'none',
      },
    },
    '& input:-webkit-autofill': {
      WebkitBackgroundClip: 'text',
    },
    '& input:-webkit-autofill:hover': {
      WebkitBackgroundClip: 'text',
    },
    '& input:-webkit-autofill:focus': {
      WebkitBackgroundClip: 'text',
    },
    '& input:-webkit-autofill:active': {
      WebkitBackgroundClip: 'text',
    },
  },
}));

export default function WCTTextField({
  label,
  name,
  required,
  multiline,
  obscure,
  value,
  error,
  placeholder,
  onChange,
  onBlur,
  readonly,
  className,
  maxWidth,
  maxLength,
  addRightPadding,
}: Props) {
  const [hidden, setHidden] = useState(obscure);

  return (
    <StyledTextField
      sx={{
        maxWidth: maxWidth,
      }}
      variant="filled"
      label={optionalLabel(label, required)}
      name={name}
      className={className}
      helperText={error}
      placeholder={placeholder as string | undefined}
      required={required}
      fullWidth
      value={value || ''}
      error={!!error}
      disabled={readonly}
      multiline={multiline}
      minRows={multiline ? 3 : 1}
      maxRows={multiline ? 5 : 1}
      onChange={(e: any) => {
        if (e == null) {
          onChange('');
        } else if (typeof e === 'string') {
          onChange(e);
        } else {
          onChange(e.target.value);
        }
      }}
      onBlur={onBlur}
      type={obscure && hidden ? 'password' : 'text'}
      hiddenLabel={!label}
      inputProps={{
        maxLength: maxLength,
        sx: {
          paddingRight: addRightPadding ? '42px' : undefined,
        },
      }}
      InputProps={{
        readOnly: readonly,
        endAdornment: obscure ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setHidden((v) => !v)}
              //onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {hidden ? (
                <SvgIcon>
                  <HiddenIcon />
                </SvgIcon>
              ) : (
                <SvgIcon>
                  <VisibleIcon />
                </SvgIcon>
              )}
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
    />
  );
}

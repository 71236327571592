import useField from '@hooks/use-field-hook';
import { required, validCurrency } from '@util/validators';
import { useMemo } from 'react';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { CaseFileListItemResponse } from '@api/types/case/case-file/case-file-list-item.response';
import {
  useCreateCaseSolutionMutation,
  useLazyGetCaseSolutionByIdQuery,
  useUpdateCaseSolutionMutation,
} from '@api/endpoints/case.api';
import { ApiError } from '@api/types/api-error';
import { CreateCaseSolutionRequest } from '@api/types/case/case-solution/create-case-solution.request';
import { SolutionsPanelState } from '@pages/app/rca/tabs/components/editor-panels/solutions-panel-hook';
import { numberFromString } from '@util/string-util';
import { usePageAlertVariants } from '@components/alerts';
import { useGetThemeOptionsQuery } from '@api/endpoints/theme.api';
import { UpdateCaseSolutionRequest } from '@api/types/case/case-solution/update-case-solution.request';
import { selectCurrentRcaCurrency } from '@store/rca-editor/selectors';
import { useAppSelector } from '@store/store';

export default function useCreateSolutionForm({
  caseId,
  chainItemId,
  selectedCaseSolution,
}: SolutionsPanelState) {
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();
  const currency = useAppSelector(selectCurrentRcaCurrency);
  const isEdit = selectedCaseSolution != null;

  const [getSolutionById, { isLoading: loadingSolutionById }] =
    useLazyGetCaseSolutionByIdQuery();
  const [createSolution, { isLoading: isSubmittingCreate }] =
    useCreateCaseSolutionMutation();
  const [updateSolution, { isLoading: isSubmittingUpdate }] =
    useUpdateCaseSolutionMutation();

  const { data: themeOptions, isLoading: loadingThemes } =
    useGetThemeOptionsQuery(caseId);

  const name = useField<string>([required()], selectedCaseSolution?.name);
  const description = useField<string>([], selectedCaseSolution?.description);
  const files = useField<Array<CaseFileListItemResponse>>(
    [],
    useMemo(
      () => selectedCaseSolution?.caseFiles ?? [],
      [selectedCaseSolution?.caseFiles]
    )
  );
  const themes = useField<Array<number>>(
    [],
    useMemo(
      () => selectedCaseSolution?.themes.map(({ id }) => id),
      [selectedCaseSolution?.themes]
    )
  );
  const cost = useField<string>(
    [required(), validCurrency()],
    selectedCaseSolution?.cost?.toString()
  );

  const { isValid, isDirty, validateAll } = useFieldsWatcher([
    name,
    description,
    files,
    themes,
    cost,
  ]);

  const isSubmitting =
    isSubmittingCreate || isSubmittingUpdate || loadingSolutionById;
  const canSubmit = isValid && isDirty && !isSubmitting;
  const isLoading = loadingThemes;

  const submit = async () => {
    if (!validateAll()) {
      return false;
    }

    if (isEdit) {
      try {
        await updateSolution({
          solutionId: selectedCaseSolution!.solutionId,
          caseId: caseId,
          name: name.value,
          description: description.value,
          themes: themes.value,
          caseFileIds: files.value.map((file) => file.caseFileId),
          cost: numberFromString(cost.value)!,
        }).unwrap();

        showSuccessMessage(
          `You have successfully updated the evidence '${name.value}'`
        );
        return await getSolutionById({
          caseId,
          solutionId: selectedCaseSolution!.solutionId,
        }).unwrap();
      } catch (error) {
        const { message, errors } =
          error as ApiError<UpdateCaseSolutionRequest>;

        showErrorMessage(errors?.caseId ?? errors?.solutionId ?? message);

        name.setError(errors?.name);
        description.setError(errors?.description);
        themes.setError(errors?.themes);
        files.setError(errors?.caseFileIds);
        cost.setError(errors?.cost);

        return false;
      }
    } else {
      try {
        const result = await createSolution({
          caseId: caseId,
          name: name.value,
          description: description.value,
          themes: themes.value,
          caseFileIds: files.value.map((file) => file.caseFileId),
          cost: numberFromString(cost.value)!,
        }).unwrap();

        showSuccessMessage('You have successfully added evidence');
        return await getSolutionById({
          caseId,
          solutionId: result.solutionId,
        }).unwrap();
      } catch (error) {
        const { message, errors } =
          error as ApiError<CreateCaseSolutionRequest>;

        showErrorMessage(errors?.caseId ?? errors?.chainItemId ?? message);

        name.setError(errors?.name);
        description.setError(errors?.description);
        themes.setError(errors?.themes);
        files.setError(errors?.caseFileIds);
        cost.setError(errors?.cost);

        return false;
      }
    }
  };

  return {
    themeOptions,
    name,
    description,
    files,
    themes,
    cost,
    isLoading,
    canSubmit,
    isSubmitting,
    submit,
    chainItemId,
    currency,
  };
}

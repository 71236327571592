import SectionScaffold from '@components/outcome-report/components/section-scaffold';
import { Box, styled } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { CaseImpactResource } from '@api/types/case/case-impact/case-impact.resource';
import { OutlinedButton } from '@components/buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import Row from '@components/layout-util-components/row';
import Spacer from '@components/layout-util-components/spacer';
import { ReactComponent as TrackedIcon } from '@assets/svgs/circle-tick.svg';
import { ReactComponent as NotTrackedIcon } from '@assets/svgs/not-tracked.svg';
import BadgeInfo from '@components/outcome-report/components/badge-info';
import { CaseImpactUtil } from '@util/case-impact-util';
import { OutcomeReportScaleOptionsLookup } from '@components/outcome-report/outcome-report-hook';
import { Gap } from '@components/layout-util-components/gap';
import getImpactValue = CaseImpactUtil.getImpactValue;
import { useAppSelector } from '@store/store';
import { selectCurrentRcaCurrency } from '@store/rca-editor/selectors';

interface Props {
  impacts: Array<CaseImpactResource>;
  optionsLookup: OutcomeReportScaleOptionsLookup;
}

// Grid layout split into 2 columns,
// each column takes up 1/2 of the space and has 1 row
const Container = styled('div')({
  padding: '12px 75px',
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'stretch',

  '.section-title': {
    fontFamily: 'Roboto',
    fontSize: '32px',
    fontWeight: '400',
    lineHeight: '43px',
    letterSpacing: '0px',
    textAlign: 'center',
    color: 'black',
  },

  '.impacts-grid': {
    '.title': {
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '21px',
      letterSpacing: '2px',
      textAlign: 'left',
      color: '#7E7E7E',
      textTransform: 'uppercase',
    },

    '.card-container': {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridGap: '24px',
      alignItems: 'stretch',
    },
  },
});

export default function ImpactsSection({ impacts, optionsLookup }: Props) {
  const { caseId } = useParams();
  const navigate = useNavigate();
  const currency = useAppSelector(selectCurrentRcaCurrency);

  const actualImpacts = impacts.filter((impact) => impact.actualValue);
  const potentialImpacts = impacts.filter((impact) => !impact.actualValue);

  return (
    <SectionScaffold id="impacts" backgroundColor="#ECECEC">
      <Container>
        <div className="section-title">Impacts</div>
        {actualImpacts.length > 0 ? (
          <>
            <Gap size={50} />
            <div className="impacts-grid">
              <div className="title">Actual</div>
              <Gap size={15} />
              <div className="card-container">
                {actualImpacts.map((impact) => {
                  const opt = optionsLookup[impact.caseImpactTypeMetricId];
                  if (!opt) {
                    return null;
                  }
                  return (
                    <ImpactCard
                      key={impact.caseImpactId}
                      name={impact.caseImpactTypeName}
                      tracked={impact.tracked}
                      description={impact.description}
                      type={impact.actualValue ? 'Actual' : 'Potential'}
                      severity={impact.caseImpactSeverityName}
                      value={getImpactValue(
                        impact.impactValue,
                        opt.caseImpactTypeMetric,
                        opt.options,
                        currency
                      )?.toString()}
                    />
                  );
                })}
              </div>
            </div>
          </>
        ) : null}
        {potentialImpacts.length > 0 ? (
          <>
            <Gap size={50} />
            <div className="impacts-grid">
              <div className="title">Potential</div>
              <Gap size={15} />
              <div className="card-container">
                {potentialImpacts.map((impact) => {
                  const opt = optionsLookup[impact.caseImpactTypeMetricId];
                  if (!opt) {
                    return null;
                  }
                  return (
                    <ImpactCard
                      key={impact.caseImpactId}
                      name={impact.caseImpactTypeName}
                      tracked={impact.tracked}
                      description={impact.description}
                      type={impact.actualValue ? 'Actual' : 'Potential'}
                      severity={impact.caseImpactSeverityName}
                      value={getImpactValue(
                        impact.impactValue,
                        opt.caseImpactTypeMetric,
                        opt.options
                      )?.toString()}
                    />
                  );
                })}
              </div>
            </div>
          </>
        ) : null}
        <Gap size={50} />
        <Box alignSelf="center">
          <OutlinedButton onClick={() => navigate(`/rca/tabs/${caseId}/chart`)}>
            View Impacts <FontAwesomeIcon icon={faArrowRight} />
          </OutlinedButton>
        </Box>
      </Container>
    </SectionScaffold>
  );
}

interface ImpactCardProps {
  name: string;
  tracked: boolean;
  description?: string;
  type?: string;
  severity?: string;
  value?: string;
}

const ImpactCardContainer = styled('div')(({ theme: { palette } }) => ({
  background: palette.common.white,
  borderRadius: 12,
  padding: 32,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',

  '.tracked': {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '23px',
    letterSpacing: '0.17000000178813934px',
    textAlign: 'left',
    color: palette.common.black,

    '&.not-tracked': {
      color: '#777777',
    },
  },

  '.description': {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '23px',
    letterSpacing: '0.17000000178813934px',
    textAlign: 'left',
  },
}));

const ImpactCard = ({
  tracked,
  severity,
  name,
  description,
  value,
  type,
}: ImpactCardProps) => {
  return (
    <ImpactCardContainer>
      <Row>
        <div className="name">{name}</div>
        <Spacer />
        <Row>
          {tracked ? <TrackedIcon /> : <NotTrackedIcon />}
          <Gap size={12} />
          <div className={`tracked ${!tracked ? 'not-tracked' : ''}`}>
            {tracked ? 'Tracked' : 'Not Tracked'}
          </div>
        </Row>
      </Row>
      {description && (
        <>
          <Gap size={12} />
          <div className="description">{description}</div>
        </>
      )}
      <Gap size={32} />
      <Row>
        {type && (
          <>
            <BadgeInfo label="Type" value={type} />
            <Gap size={50} />
          </>
        )}
        {severity && (
          <>
            <BadgeInfo label="Severity" value={severity} />
            <Gap size={50} />
          </>
        )}
        {type && value != null ? (
          <BadgeInfo label={`${type} Impact`} value={value} />
        ) : null}
      </Row>
    </ImpactCardContainer>
  );
};

import { ReactComponent as FlagHigh } from '@assets/svgs/flag-high.svg';
import { ReactComponent as FlagMedium } from '@assets/svgs/flag-medium.svg';
import { ReactComponent as FlagLow } from '@assets/svgs/flag-low.svg';
import { TaskPriority } from '@api/types/task-detail/task-priority';

interface Props {
  priority: TaskPriority;
}

export default function PriorityIndicator({ priority }: Props) {
  switch (priority) {
    case 'High':
      return <FlagHigh />;
    case 'Standard':
      return <FlagMedium />;
    case 'Low':
      return <FlagLow />;
    default:
      return <></>;
  }
}

import { styled } from '@mui/material';

export const BorderedContainer = styled('div')({
  padding: 10,
  borderRadius: 5,
  border: '1px solid black',
  backgroundColor: 'white',
  display: 'flex',
  alignItems: 'flex-start',
});

import { CaseImpactTypeMetric } from '@api/types/case-impact-type-metric/case-impact-type-metric.resource';
import { Option } from '@api/types/option';
import WCTTextField from '@components/input/text-field';
import { numberFromString } from '@util/string-util';
import WCTCurrencyField from '@components/input/currency-field';
import { useMemo } from 'react';
import InlineRadioSelectGroup from '@components/input/inline-radio-select-group';
import WCTSelectField from '@components/input/select-field';
import { useAppSelector } from '@store/store';
import { selectCurrentRcaCurrency } from '@store/rca-editor/selectors';

export interface Props {
  name: string;
  type: CaseImpactTypeMetric;
  label?: string;
  value?: number;
  error?: string;
  onChange: (val?: number) => void;
  onBlur?: () => void;
  options?: Array<Option>;
  required?: boolean;
  readonly?: boolean;
  optionsAsRadioButtons?: boolean;
}

export default function MetricInputField({
  label,
  onBlur,
  error,
  onChange,
  value,
  options,
  type,
  name,
  required,
  readonly,
  optionsAsRadioButtons,
}: Props) {
  const valueAsStr = useMemo(() => {
    if (value == null || isNaN(value)) {
      return undefined;
    }

    return value.toString();
  }, [value]);

  const onChangeInternal = (val: any) => {
    if (val == null) {
      onChange(undefined);
    } else if (typeof val === 'number') {
      onChange(val);
    } else if (typeof val === 'string') {
      onChange(numberFromString(val));
    }
  };

  const currency = useAppSelector(selectCurrentRcaCurrency);

  if (type === CaseImpactTypeMetric.numeric) {
    return (
      <WCTTextField
        name={name}
        label={label}
        error={error}
        value={valueAsStr}
        onBlur={onBlur}
        required={required}
        readonly={readonly}
        onChange={onChangeInternal}
      />
    );
  } else if (type === CaseImpactTypeMetric.currency) {
    return (
      <WCTCurrencyField
        name={name}
        label={label}
        value={valueAsStr}
        error={error}
        onChange={onChangeInternal}
        onBlur={onBlur}
        required={required}
        readonly={readonly}
        currency={currency}
      />
    );
  } else if (type === CaseImpactTypeMetric.scale) {
    if (optionsAsRadioButtons) {
      return (
        <InlineRadioSelectGroup
          name={name}
          label={label}
          error={error}
          value={value}
          onChange={onChangeInternal}
          options={options!}
        />
      );
    } else {
      return (
        <WCTSelectField
          id={name}
          name={name}
          label={label}
          error={error}
          value={value}
          options={options!}
          onBlur={onBlur}
          onChange={onChangeInternal}
          readonly={readonly}
          required={required}
        />
      );
    }
  }

  return <></>;
}

import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  SideDrawerFormProps,
  withSideDrawer,
} from '@components/ui-popup/drawers';
import { useAppTr } from '@i18n/use-app-tr';
import { Grid } from '@mui/material';
import useGroupDetail, {
  ActiveTab,
} from '@pages/app/profile/components/group-detail-hook';
import LabelValueDisplay from '@components/data/label-value-display';
import { Divider } from '@components/layout-util-components/divider';
import { ListContainer } from '@components/containers/list-container';
import GroupMemberRow from '@pages/app/profile/components/group-member-row';
import LabelValueSkeleton from '@components/skeletons/label-value-skeleton';
import ListContainerSkeleton from '@components/skeletons/list-container-skeleton';
import {
  NavTabContainer,
  NavTabHeader,
} from '@components/menu-items/app/nav-tab-header';
import { useMemo } from 'react';
import NoRcaForGroupInfo from './no-rca-for-group-info';
import RcaWideCard from '@components/cards/rca-wide-card';
import { Gap } from '@components/layout-util-components/gap';
import { DropDownFilter, WCTTableFilters } from '@components/table';
import { OutlinedButton } from '@components/buttons';

export interface GroupDetailViewProps extends SideDrawerFormProps<void> {
  id: number;
}

function GroupDetailView({ onClose, id }: GroupDetailViewProps) {
  const { t } = useAppTr('groups');

  const {
    isLoading,
    group,
    isLoadingCases,
    caseData,
    activeTab,
    setActiveTab,
    search,
    status,
    statusOptions,
    type,
    caseTypeOptions,
    severity,
    severityOptions,
    leaveGroup,
  } = useGroupDetail(id);

  const tabs = useMemo(
    () => [
      {
        label: `RCA ${!isLoadingCases && `(${caseData?.totalCount ?? 0})`}`,
        isActive: activeTab === ActiveTab.RCA,
        onClick: () => setActiveTab(ActiveTab.RCA),
      },
      {
        label: `Team Members ${
          !isLoading && `(${group!.groupMembers.length})`
        }`,
        isActive: activeTab === ActiveTab.TeamMember,
        onClick: () => setActiveTab(ActiveTab.TeamMember),
      },
    ],
    [activeTab, setActiveTab, isLoading, isLoadingCases, caseData, group]
  );

  return (
    <>
      <DrawerHeader onClose={() => onClose()}>
        {t('detail.title', { name: group?.name ?? '' })}
      </DrawerHeader>
      <DrawerContent>
        <Grid container flexDirection="column" alignItems="stretch">
          <Grid item container>
            <Grid item xs={6}>
              {isLoading ? (
                <LabelValueSkeleton />
              ) : (
                <LabelValueDisplay
                  label={t('detail.sections.createdByLabel')}
                  value={group?.createdByUserName ?? ''}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              {isLoading ? (
                <LabelValueSkeleton />
              ) : (
                <LabelValueDisplay
                  label={t('detail.sections.dateCreatedLabel')}
                  value={
                    group
                      ? t('detail.sections.dateCreated', {
                          created: group!.created,
                        })
                      : null
                  }
                />
              )}
            </Grid>
          </Grid>
          <Gap size={20} />
          <Grid item>
            {isLoading ? (
              <LabelValueSkeleton />
            ) : (
              <LabelValueDisplay
                label={t('detail.sections.descriptionLabel')}
                value={group!.description ?? ''}
              />
            )}
          </Grid>
          <Divider addMargin compact />
          <Gap size={20} />
          <NavTabContainer>
            {tabs.map((t) => (
              <NavTabHeader
                key={t.label}
                onClick={t.onClick}
                isSelected={t.isActive}
              >
                {t.label}
              </NavTabHeader>
            ))}
          </NavTabContainer>
          <Gap size={20} />
          {activeTab === ActiveTab.RCA && (
            <>
              {!isLoadingCases && (
                <WCTTableFilters
                  isLoading={isLoading}
                  search={search.value}
                  onSearch={search.set}
                >
                  <DropDownFilter
                    value={type.value}
                    onChange={type.set}
                    options={caseTypeOptions ?? []}
                  >
                    Type
                  </DropDownFilter>
                  <DropDownFilter
                    value={status.value}
                    onChange={status.set}
                    options={statusOptions ?? []}
                  >
                    Status
                  </DropDownFilter>
                  <DropDownFilter
                    value={severity.value}
                    onChange={severity.set}
                    options={severityOptions ?? []}
                  >
                    Severity
                  </DropDownFilter>
                </WCTTableFilters>
              )}
              {isLoadingCases ? (
                <ListContainerSkeleton />
              ) : caseData!.totalCount > 0 ? (
                <>
                  {caseData!.count > 0 && (
                    <ListContainer>
                      {caseData!.model.map((rca) => (
                        <RcaWideCard
                          key={rca.caseId}
                          data={rca}
                          onClick={() => onClose()}
                        />
                      ))}
                    </ListContainer>
                  )}
                </>
              ) : (
                <NoRcaForGroupInfo />
              )}
            </>
          )}
          {activeTab === ActiveTab.TeamMember && (
            <>
              {isLoading ? (
                <ListContainerSkeleton />
              ) : (
                <ListContainer>
                  {group!.groupMembers.map((member) => (
                    <GroupMemberRow
                      key={member.companyUserId}
                      member={member}
                      isAdmin={member.isAdmin}
                    />
                  ))}
                </ListContainer>
              )}
            </>
          )}
        </Grid>
      </DrawerContent>
      <DrawerFooter>
        <OutlinedButton onClick={leaveGroup}>Leave group</OutlinedButton>
      </DrawerFooter>
    </>
  );
}

export default withSideDrawer(GroupDetailView);

import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import useCreateContextHook from '@util/create-context-hook-util';
import { DefaultTFuncReturn } from 'i18next';
import { v4 as uuid } from 'uuid';
import { ErrorData } from '@api/types/api-error';
import { useAppDispatch, useAppSelector } from '@store/store';
import { selectGlobalAlert } from '@store/ui/selectors';
import { resetAlert } from '@store/ui/ui-slice';

export interface AlertInfo {
  type: 'success' | 'error' | 'info';
  title?: string | DefaultTFuncReturn | ErrorData;
  message?: string | DefaultTFuncReturn | ErrorData;
}

export interface AlertRegisterOptions {
  ignoreError?: boolean;
  ignoreSuccess?: boolean;
  ignoreInfo?: boolean;
}

export interface AlertInfoDisplay {
  showAlert: (info: AlertInfo) => void;
  hideAlert: () => void;
  id: string;
  options?: AlertRegisterOptions;
}

type PageAlertState = {
  registerDisplay: (
    display: Omit<AlertInfoDisplay, 'id' | 'options'>,
    options?: AlertRegisterOptions
  ) => string;
  unregisterDisplay: (id: string) => void;
} & Omit<AlertInfoDisplay, 'id' | 'options'>;

const PageAlertContext = createContext<PageAlertState | undefined>(undefined);

function PageAlertProvider({ children }: PropsWithChildren<{}>) {
  const dispatch = useAppDispatch();
  const displayStackRef = useRef<Array<AlertInfoDisplay>>();

  const globalAlert = useAppSelector(selectGlobalAlert);

  const registerDisplay = useCallback(
    (
      d: Omit<AlertInfoDisplay, 'id' | 'options'>,
      options?: AlertRegisterOptions
    ): string => {
      const id = uuid();

      const array = [...(displayStackRef.current ?? [])];
      displayStackRef.current = [...array, { ...d, options, id }];

      return id;
    },
    []
  );

  const unregisterDisplay = useCallback((id: string) => {
    const index = displayStackRef.current!.findIndex((x) => x.id === id);
    if (index !== -1) {
      displayStackRef.current!.splice(index, 1);
    }
  }, []);

  const showAlert = useCallback((info: AlertInfo) => {
    const array = displayStackRef.current!;
    for (let i = array.length - 1; i >= 0; i--) {
      const alertInfoDisplay = array[i];
      switch (info.type) {
        case 'success':
          if (!alertInfoDisplay.options?.ignoreSuccess) {
            alertInfoDisplay.showAlert(info);
            return;
          }
          break;
        case 'error':
          if (!alertInfoDisplay.options?.ignoreError) {
            alertInfoDisplay.showAlert(info);
            return;
          }
          break;
        case 'info':
          if (!alertInfoDisplay.options?.ignoreInfo) {
            alertInfoDisplay.showAlert(info);
            return;
          }
          break;
      }
    }
  }, []);

  useEffect(() => {
    if (globalAlert != null) {
      showAlert(globalAlert);
      dispatch(resetAlert());
    }
  }, [dispatch, globalAlert, showAlert]);

  const hideAlert = useCallback(() => {
    if (displayStackRef.current == null) {
      return;
    }

    displayStackRef.current!.forEach((element) => element.hideAlert());
  }, []);

  return (
    <PageAlertContext.Provider
      value={{
        showAlert,
        hideAlert,
        registerDisplay,
        unregisterDisplay,
      }}
    >
      {children}
    </PageAlertContext.Provider>
  );
}

const usePageAlert = () => useCreateContextHook(PageAlertContext);

export { usePageAlert, PageAlertProvider };

import { useGetCompanyUserDetailQuery } from '@api/endpoints/company/company-company-user.api';
import { useGetKudosHistoryQuery } from '@api/endpoints/user.api';

export default function useKudosPage(userId?: number) {
  const { data: userDetail, isLoading: loadingUserDetail } =
    useGetCompanyUserDetailQuery(userId as number, {
      skip: userId == null,
    });

  const { data: kudos, isLoading: loadingKudos } = useGetKudosHistoryQuery();

  const isLoading = loadingKudos || loadingUserDetail;

  return {
    kudos,
    userDetail,
    isLoading,
  };
}

import { api } from '@api/api';
import { ChainItemCaseImpactResource } from '@api/types/chain/chain-item-case-impact/chain-item-case-impact.resource';
import { UpdateChainItemCaseImpactRequest } from '@api/types/chain/chain-item-case-impact/update-chain-item-case-impact.request';
import { makeError } from '@api/types/api-error';

export interface ChainItemCaseImpactPathParams {
  chainId: number;
  chainItemId: number;
}

const chainItemCaseImpactApi = api.injectEndpoints({
  endpoints: (build) => ({
    getChainItemCaseImpacts: build.query<
      Array<ChainItemCaseImpactResource>,
      ChainItemCaseImpactPathParams
    >({
      query: ({ chainId, chainItemId }) => ({
        url: `chain/${chainId}/chainItemCaseImpact/${chainItemId}`,
        method: 'GET',
      }),
      providesTags: ['ChainItemCaseImpact'],
    }),
    updateChainItemCaseImpacts: build.mutation<
      void,
      UpdateChainItemCaseImpactRequest
    >({
      query: ({ chainId, chainItemId, ...body }) => ({
        url: `chain/${chainId}/chainItemCaseImpact/${chainItemId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, error, __) =>
        !error ? ['ChainItemCaseImpact'] : [],
      transformErrorResponse: (error) =>
        makeError<UpdateChainItemCaseImpactRequest>(error),
    }),
  }),
});

export const {
  useGetChainItemCaseImpactsQuery,
  useUpdateChainItemCaseImpactsMutation,
} = chainItemCaseImpactApi;

export default chainItemCaseImpactApi;

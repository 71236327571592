import { api } from '@api/api';
import { Option } from '@api/types/option';

const currencyApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCurrencyOptions: build.query<Option[], void>({
      query: () => 'currency/options',
    }),
  }),
});

export const { useGetCurrencyOptionsQuery } = currencyApi;
